/*
 * @Description: 首页数据源列表
 */

import {
  POST
} from "@/axios/axios.js";
import API from "../api";

// 获取纱线列表
export const getstoreList = (param) => {
  return POST(API.api_index_storeList, param);
};

// 获取提花织物结果
export const getresult = (param) => {
  return POST(API.api_result, param);
};

// 提花,一体机生成织物结果
export const resSave = (param) => {
  return POST(API.api_res, param);
};

// 生成织物结果
export const resultSave = (param) => {
  return POST(API.api_save, param);
};



// 一体机上传最小循环数据
export const clothesSmallSave = (param) => {
  return POST(API.api_clothesSmallSave, param);
};

// 获取一体机上传最小循环图片
export const clothesArray = (param) => {
  return POST(API.api_clothesArray, param);
};

// 获取一体机上衣id
export const clothesSave = (param) => {
  return POST(API.api_clothesSave, param);
};

// 保存一体机上衣
export const clothesAdd = (param) => {
  return POST(API.api_clothesAdd, param);
};



// 生成提花织物结果
export const resultThsave = (param) => {
  return POST(API.api_thsave, param);
};

// 保存织物结果
export const saveCloth = (param) => {
  return POST(API.api_saveCloth, param);
};
// 保存提花织物结果
export const saveThCloth = (param) => {
  return POST(API.api_saveThCloth, param);
};
// 获取算法结果
export const backRes = (param) => {
  return POST(API.api_backRes, param);
};

// 上传渲染截图
export const upRenderImg = (param) => {
  return POST(API.api_upRenderImg, param);
};

// 上传渲染截图地址
export const upRenderUrl = (param) => {
  return POST(API.api_upRenderUrl, param);
};

// 上传file图片
export const loadImg = (param) => {
  return POST(API.api_loadImg, param);
};

//上传多组图片
export const uplaodImage = (param) => {
  return POST(API.api_UploadIamge, param);
};