<template>
  <div class="newSavePup">
    <div class="con">
      <div class="top">
        <span></span>
        <span>{{ $t("historyList.txt31") }}</span>
        <img src="@/newImg/hidden.png" alt="" @click="sendData(false)" />
      </div>
      <div class="conInputBox">
        <!-- 基本数据 -->
        <div class="basicItem">
          <div class="box">
            <div class="item">
              <div :style="errType1 ? 'color:#FF0000' : ''" class="inputTitle">
                {{ errType1 ? $t("historyList.txt91") : $t("historyList.txt9")
                }}<span v-show="!errType1">*</span>
              </div>
              <input :style="errType1 ? 'border-color:#FF0000' : ''" type="text" v-model="saveData.productNumber"
                :placeholder="$t('historyList.txt2')" @focus="clearErr" />
            </div>
            <div class="item">
              <div :style="errType2 ? 'color:#FF0000' : ''" class="inputTitle">
                {{
                  errType1 ? $t("historyList.txt92") : $t("historyList.txt10")
                }}
                <span v-show="!errType1">*</span>
              </div>
              <input :style="errType2 ? 'border-color:#FF0000' : ''" type="text" v-model="saveData.productName"
                :placeholder="$t('historyList.txt2')" @focus="clearErr" />
            </div>
          </div>
          <div class="box">
            <div class="item">
              <div class="inputTitle">{{ $t("historyList.txt32") }}</div>
              <input type="text" v-model="saveData.techRequirements" :placeholder="$t('historyList.txt2')"
                @focus="clearErr" />
            </div>
            <div class="item"></div>
          </div>
        </div>
        <!-- 洗涤标准 -->
        <div class="wash">
          <div class="washTitle">{{ $t("historyList.txt34") }}</div>
          <div class="washList">
            <div class="box">
              <div class="item">
                <div class="inputTitle">{{ $t("historyList.txt40") }}</div>
                <input type="text" v-model="saveData.ribCuffWidth" :placeholder="$t('historyList.txt2')"
                  @focus="clearErr" />
                <div class="weight">cm</div>
              </div>
              <div class="item">
                <div class="inputTitle">{{ $t("historyList.txt41") }}</div>
                <input type="text" v-model="saveData.ribCuffHeight" :placeholder="$t('historyList.txt2')"
                  @focus="clearErr" />
                <div class="weight">cm</div>
              </div>
            </div>
            <div class="box">
              <div class="item">
                <div class="inputTitle">{{ $t("historyList.txt42") }}</div>
                <input type="text" v-model="saveData.UpperCylinderLength" :placeholder="$t('historyList.txt2')"
                  @focus="clearErr" />
                <div class="weight">cm</div>
              </div>
              <div class="item">
                <div class="inputTitle">{{ $t("historyList.txt43") }}</div>
                <input type="text" v-model="saveData.UpperCylinderWidth" :placeholder="$t('historyList.txt2')"
                  @focus="clearErr" />
                <div class="weight">cm</div>
              </div>
            </div>
            <div class="box">
              <div class="item">
                <div class="inputTitle">{{ $t("historyList.txt44") }}</div>
                <input type="text" v-model="saveData.LowerCylinderLength" :placeholder="$t('historyList.txt2')"
                  @focus="clearErr" />
                <div class="weight">cm</div>
              </div>
              <div class="item">
                <div class="inputTitle">{{ $t("historyList.txt45") }}</div>
                <input type="text" v-model="saveData.LowerCylinderWidth" :placeholder="$t('historyList.txt2')"
                  @focus="clearErr" />
                <div class="weight">cm</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 按钮模块 -->
        <div class="btnBox">
          <span></span>
          <div class="btn">
            <div @click="sendData(false)">{{ $t("historyList.txt26") }}</div>
            <div class="rigthBtn" @click="sendData(true)">
              {{ $t("historyList.txt25") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { washing } from "@/utils/data.js";
export default {
  props: ["currentColumnsData"],
  data() {
    return {
      saveData: {
        productNumber: "",
        productName: "",
        techRequirements: "",
        ribCuffWidth: "",
        ribCuffHeight: "",
        UpperCylinderLength: "",
        UpperCylinderWidth: "",
        LowerCylinderLength: "",
        LowerCylinderWidth: "",
      },
      errType1: false,
      errType2: false,
      draperyId: "",
    };
  },
  watch: {
    //品号限制
    "saveData.productNumber": {
      handler(newVal, oldVal) {
        var str;
        if (newVal.length >= 50) {
          str = newVal.slice(0, 51);
        } else {
          str = newVal;
        }
        this.$set(
          this.saveData,
          "productNumber",
          str.replace(/[^0-9a-zA-Z+\-*/~!@#$%&*()_=￥！（）——]/gi, "")
        );
      },
      immediate: false,
    },
    //品名限制
    "saveData.productName": {
      handler(newVal, oldVal) {
        var str;
        if (newVal.length > 30) {
          str = newVal.slice(0, 31);
        } else {
          str = newVal;
        }
        this.$set(
          this.saveData,
          "productName",
          str.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/gi, "")
        );
      },
      immediate: false,
    },
    //工艺要求
    "saveData.techRequirements": {
      handler(newVal, oldVal) {
        var str;
        if (newVal.length >= 19) {
          str = newVal.slice(0, 20);
        } else {
          str = newVal;
        }
        this.$set(
          this.saveData,
          "techRequirements",
          str.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/gi, "")
        );
      },
      immediate: false,
    },
    //罗口宽
    "saveData.ribCuffWidth": {
      handler(newVal, oldVal) {
        var str;
        if (newVal.length >= 3) {
          str = newVal.slice(0, 4);
        } else {
          str = newVal;
        }
        this.$set(
          this.saveData,
          "ribCuffWidth",
          str.replace(/[^0-9]/gi, "")
        );
      },
      immediate: false,
    },
    //罗口高
    "saveData.ribCuffHeight": {
      handler(newVal, oldVal) {
        var str;
        if (newVal.length >= 3) {
          str = newVal.slice(0, 4);
        } else {
          str = newVal;
        }
        this.$set(
          this.saveData,
          "ribCuffHeight",
          str.replace(/[^0-9]/gi, "")
        );
      },
      immediate: false,
    },
    //上筒长
    "saveData.UpperCylinderLength": {
      handler(newVal, oldVal) {
        var str;
        if (newVal.length >= 3) {
          str = newVal.slice(0, 4);
        } else {
          str = newVal;
        }
        this.$set(
          this.saveData,
          "UpperCylinderLength",
          str.replace(/[^0-9]/gi, "")
        );
      },
      immediate: false,
    },
    //上筒长 横拉宽
    "saveData.UpperCylinderWidth": {
      handler(newVal, oldVal) {
        var str;
        if (newVal.length >= 3) {
          str = newVal.slice(0, 4);
        } else {
          str = newVal;
        }
        this.$set(
          this.saveData,
          "UpperCylinderWidth",
          str.replace(/[^0-9]/gi, "")
        );
      },
      immediate: false,
    },
    //下筒长
    "saveData.LowerCylinderLength": {
      handler(newVal, oldVal) {
        var str;
        if (newVal.length >= 3) {
          str = newVal.slice(0, 4);
        } else {
          str = newVal;
        }
        this.$set(
          this.saveData,
          "LowerCylinderLength",
          str.replace(/[^0-9]/gi, "")
        );
      },
      immediate: false,
    },
    //下筒长 横拉宽
    "saveData.LowerCylinderWidth": {
      handler(newVal, oldVal) {
        var str;
        if (newVal.length >= 3) {
          str = newVal.slice(0, 4);
        } else {
          str = newVal;
        }
        this.$set(
          this.saveData,
          "LowerCylinderWidth",
          str.replace(/[^0-9]/gi, "")
        );
      },
      immediate: false,
    },
    currentColumnsData: {
      handler(newVal) { },
      immediate: false,
    },
  },
  mounted() {
    if (this.currentColumnsData != undefined) {
      this.$set(
        this.saveData,
        "productNumber",
        this.currentColumnsData.productCode
      );
      this.$set(
        this.saveData,
        "productName",
        this.currentColumnsData.productName
      );
      this.$set(
        this.saveData,
        "techRequirements",
        this.currentColumnsData.techRequirements
      );
      this.$set(
        this.saveData,
        "ribCuffWidth",
        this.currentColumnsData.ribCuffWidth
      );
      this.$set(
        this.saveData,
        "ribCuffHeight",
        this.currentColumnsData.ribCuffHeight
      );
      this.$set(
        this.saveData,
        "UpperCylinderLength",
        this.currentColumnsData.UpperCylinderLength
      );
      this.$set(
        this.saveData,
        "UpperCylinderWidth",
        this.currentColumnsData.UpperCylinderWidth
      );
      this.$set(
        this.saveData,
        "LowerCylinderLength",
        this.currentColumnsData.LowerCylinderLength
      );
      this.$set(
        this.saveData,
        "LowerCylinderWidth",
        this.currentColumnsData.LowerCylinderWidth
      );

      this.draperyId = this.currentColumnsData.draperyId;
    } else {
      this.$set(this.saveData, "productNumber", "");
      this.$set(this.saveData, "productName", "");
      this.$set(this.saveData, "techRequirements", "");
      this.$set(this.saveData, "ribCuffWidth", "");
      this.$set(this.saveData, "ribCuffHeight", "");
      this.$set(this.saveData, "UpperCylinderLength", "");
      this.$set(this.saveData, "UpperCylinderWidth", "");
      this.$set(this.saveData, "LowerCylinderLength", "");
      this.$set(this.saveData, "LowerCylinderWidth", "");
    }
  },
  methods: {
    clearErr() {
      this.errType1 = false;
      this.errType2 = false;
    },
    sendData(type) {
      let data = {};
      if (type) {
        let errType = false;
        if (this.saveData.productNumber == "") {
          this.errType1 = true;
        }

        if (this.saveData.productName == "") {
          this.errType2 = true;
        }

        if (this.errType1 || this.errType2) {
          return;
        }
        let {
          productNumber,
          productName,
          techRequirements,
          ribCuffWidth,
          ribCuffHeight,
          UpperCylinderLength,
          UpperCylinderWidth,
          LowerCylinderLength,
          LowerCylinderWidth,
        } = this.saveData;

        data = {
          productCode: productNumber,
          productName: productName,
          craftRequire: techRequirements, //工艺要求
          ribWidth: ribCuffWidth,          // 罗口宽
          ribHeight: ribCuffHeight,        // 罗口高
          coamingLength: UpperCylinderLength,    //  上筒长
          coamingCrossWidth: UpperCylinderWidth,        //  上筒长 横拉宽
          doffingLength: LowerCylinderLength,      //  下筒长
          doffingCrossWidth: LowerCylinderWidth,        //  下筒长 横拉宽
        };
        if (this.draperyId != "") {
          data.draperyId = this.draperyId;
        }
      }
      this.$emit("saveSend", {
        type,
        data,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./newSavePup.less");

.el-select-dropdown__item {
  font-size: 18px !important;
  color: #333;
  height: 47px;
  display: flex;
  align-items: center;
  padding: 0 14px;
}

.el-select-dropdown__item span {
  font-size: 18px !important;
}
</style>