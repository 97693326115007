<template>
  <div class="getImgcolor" :style="getImgType ? '' : 'opacity: 0;'">
    <div class="canvasBox">
      <canvas id="canvasSrc"></canvas>
      <canvas id="canvasReduce"></canvas>
    </div>
    <div class="getImgBox" v-show="step == 1">
      <div class="conHead">
        <span v-show="imgPupType == 2 ? false : true">{{
          upDataType ? $t("pup.txt59") : $t("pup.txt60")
        }}</span>
        <span v-show="imgPupType == 2 ? true : false">{{
          $t("pup.txt85")
        }}</span>

        <img src="@/newImg/hidden.png" alt="" @click="hiddenPup" />
      </div>
      <div :class="step == 1 ? 'conCent' : 'conCent paddingCon'" v-show="upDataType">
        <div class="imgData">
          <span class="leftSpan">{{
            imgPupType == 2 ? "" : $t("pup.txt61")
          }}</span>
          <span class="rigthSpan">
            <span>{{ $t("pup.txt62") }}{{ ImgSize.w }}</span>
            <span>{{ $t("pup.txt63") }}{{ ImgSize.h }}</span>
          </span>
        </div>
        <div class="imgBox" v-show="step == 1 ? true : false">
          <input type="file" accept="image/*" ref="fileInput" class="fileInput" @change="getImg" />
          <!-- image-rendering: pixelated; -->
          <el-image :src="base64String" fit="contain">
            <div slot="error" class="image-slot"></div>
          </el-image>
        </div>
        <div class="Btn" v-show="imgPupType == 2 ? false : true">
          <span class="leftSpan"></span>
          <span class="rigthSpan">
            <span @click="setupDataType">{{ $t("pup.txt64") }}</span>
            <span class="nextStep" @click="nextStep">{{
              $t("pup.txt65")
            }}</span>
          </span>
        </div>
        <div class="colorList">
          <div v-for="(item, index) in colorList" :key="index" class="item" :style="`background-color:rgba(${item.rgb})`">
          </div>
        </div>
        <div class="StructureBtn" v-show="imgPupType == 2 ? true : false">
          <div class="leftSpan">
            <span>{{ $t("pup.txt72") }}</span>
            <el-input-number v-model="scolorNum" controls-position="right" @keyup.enter.native="setColorNum" :min="1"
              :max="12" ref="numberInput"></el-input-number>
            <div class="btn" @click="setColorNum">
              {{ transformType ? $t("pup.txt74") : $t("pup.txt14") }}
            </div>
          </div>
          <span class="rigthSpan">
            <span class="nextStep" @click="applicationStructure">{{
              $t("pup.txt57")
            }}</span>
          </span>
        </div>
      </div>
      <div class="upDataconCent" v-show="!upDataType">
        <div class="sizeNum">
          <!-- <div class="canvasSize">
            <div class="title">{{ $t("pup.txt66") }}</div>
            <div class="sizeNumtxt">
              <span>{{ $t("pup.txt62") }}{{ hk }}</span>
              <span>{{ $t("pup.txt63") }}{{ hg }}</span>
            </div>
          </div> -->
          <div class="imgSize">
            <div class="title">{{ $t("pup.txt67") }}</div>
            <div class="sizeNumtxt">
              <span>{{ $t("pup.txt62") }}{{ importImgData.w }}</span>
              <span>{{ $t("pup.txt63") }}{{ importImgData.h }}</span>
            </div>
          </div>
        </div>
        <div class="upDataimgSize">
          <div class="title">{{ $t("pup.txt68") }}</div>
          <div class="sizeNumtxt">
            <div>
              {{ $t("pup.txt62")
              }}<input type="text" class="input" v-model="upDataImgSize.w" />
            </div>
            <div>
              {{ $t("pup.txt63") }}
              <input type="text" :disabled="checkImgType" class="input" v-model="upDataImgSize.h" />
            </div>
          </div>
        </div>
        <div class="proportion">
          <img v-show="checkImgType" src="@/newImg/checkImg.png" alt="" class="checkBox" style="border: none"
            @click="checkImgType = false" />
          <div @click="setcheckImgType(true)" v-show="!checkImgType" class="checkBox"></div>
          <span>{{ $t("pup.txt69") }}</span>
        </div>
        <div class="btnBox">
          <span></span>
          <div class="btn">
            <div @click="upDataType = true">{{ $t("pup.txt13") }}</div>
            <div class="rigthBtn" @click="setImgsize">
              {{ $t("pup.txt14") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contrast" v-show="step == 2">
      <div class="conHead">
        <span>{{ $t("pup.txt70") }}</span>
        <img src="@/newImg/hidden.png" alt="" @click="hiddenPup" />
      </div>
      <div class="imgContrast">
        <div class="conCent">
          <div class="imgData">
            <span class="leftSpan">{{ $t("pup.txt61") }}</span>
            <span class="rigthSpan">
              <span>{{ $t("pup.txt62") }}{{ ImgSize.w }} </span>
              <span>{{ $t("pup.txt63") }}{{ ImgSize.h }} </span>
            </span>
          </div>
          <div class="imgBox">
            <el-image :src="base64String" fit="contain">
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
        </div>
        <div class="conCent" style="margin-right: 0">
          <div class="imgData">
            <span class="leftSpan">{{ $t("pup.txt71") }}</span>
            <span class="rigthSpan">
              <span>{{ $t("pup.txt62") }}{{ ImgSize.w }} </span>
              <span>{{ $t("pup.txt63") }}{{ ImgSize.h }} </span>
            </span>
          </div>
          <div class="imgBox">
            <el-image :src="ptbase64String" fit="contain">
              <div slot="error" class="image-slot"></div>
            </el-image>
          </div>
          <div class="color">
            <div v-for="(item, index) in colorList" :key="index" class="item" :style="`background-color: rgba(${item.r}, ${item.g}, ${item.b},${item.a
              });${index == colorList.length - 1 ? 'margin-right:0' : ''}`"></div>
          </div>
        </div>
      </div>

      <div class="Btn">
        <div class="leftSpan">
          <span>{{ $t("pup.txt72") }}</span>
          <el-input-number v-model="colorNum" controls-position="right" @keyup.enter.native="setColorNum" :min="1"
            :max="8" ref="numberInput"></el-input-number>
          <div class="btn" @click="setColorNum">
            {{ transformType ? $t("pup.txt74") : $t("pup.txt14") }}
          </div>
        </div>
        <span class="rigthSpan">
          <span @click="step = 1">{{ $t("pup.txt73") }}</span>
          <span class="nextStep" @click="application">{{
            $t("pup.txt57")
          }}</span>
        </span>
      </div>
    </div>
    <div class="colorMask" v-show="loading"></div>
  </div>
</template>
<script>
var opts = {
  method: 2, // 质量分布图方法, 2: 子区域内的最小阈值; 1: 全局最大阈值
  colors: 256, // 颜色数量, 默认256色。
  initColors: 4096, // 从顶部出现的颜色开始 (if method = 1)
  boxSize: [64, 64], // 次区域变暗 (if method = 2)
  boxPxls: 2, // min-population阈值 (if method = 2)
  minHueCols: 0, // 为了保留低计数的色调，每个色调组的颜色无论计数如何都要评估
  dithSerp: false, // 图案抖动，关闭时，色块连续；开启则不连续，但更接近原图。
  dithKern: null, // 抖动算法的名称："FalseFloydSteinberg"(或"", 默认算法)、"FloydSteinberg"、"Stucki"、"Atkinson"、"Jarvis"、"Burkes"、"Sierra"、"TwoSierra"、"SierraLite"、"FalseFloyd"
  dithDelta: 0, // 抖动阈值(0-1)，例如:0.05，将不会抖动差异小于等于5%的颜色
  palette: [], // 以r,g,b元组格式开始的预定义调色板:[[r,g,b]，[r,g,b]…]
  reIndex: false, // 仅影响预定义的调色板。如果为true，则允许在达到目标调色板大小时压缩稀疏的调色板。还支持面板排序。
  useCache: true, // 启用缓存(通常开启)以提高性能，但在某些情况下可能会降低性能，例如预定义调色板
  cacheFreq: 8, // 符合缓存条件所需的最小颜色出现计数
  colorDist: "euclidean", // 确定颜色距离的方法(默认欧几里得), 也可使用曼哈顿距离"manhattan"
};
var reduceImgData;
var palette;
var pantonePalette;
import Worker from "./lib/rgbquant.worker.js";
import { ptColorIndexed, ptColor } from "./lib/PantoneColor.js";
import RgbQuant from "./lib/rgbquant.js";
export default {
  props: ["hk", "hg", "machineNumber", "yarnListLength", "imgPupType", "sockbodyPosition"],
  data() {
    return {
      upDataType: true, //修改尺寸
      checkImgType: false, //是否保持比例
      base64String: "", //导入图片地址
      importImgData: {
        //花型图默认尺寸
        w: 0,
        h: 0,
      },
      upDataImgSize: {
        //花型图修改尺寸
        w: 0,
        h: 0,
      },
      ImgSize: {
        //花型图当前修改尺寸
        w: 0,
        h: 0,
      },
      img: "", //生成出的img标签储存图片
      step: 1, //当前弹框步数
      colorNum: 8, //当前要转化几种颜色
      colorList: [], //当前潘通色颜色
      pantongColorCode: [], //转换的潘通色
      ptbase64String: "", //使用潘通色生成的图片
      setSizeType: false, //是否修改大小
      transformType: false, //转化中状态
      loading: false, //loading状态
      getImgType: false, //是否导入图片
      scolorNum: 12, //当前结构图要转化几种颜色
      importStructureImgData: [],//结构图片初始化imgData
    };
  },
  watch: {
    upDataImgSize: {
      handler(newVal) {
        let newData = {
          w: newVal.w.toString().replace(/[^0-9_]/g, ""),
          h: newVal.h.toString().replace(/[^0-9_]/g, ""),
        };
        newVal = newData;
        if (
          this.upDataImgSize.w == "" ||
          this.upDataImgSize.w == 0 ||
          this.upDataImgSize.w == "" ||
          this.upDataImgSize.w == 0
        ) {
          return;
        }
        let maxW = this.sockbodyPosition.rightBottom.x - this.sockbodyPosition.leftTop.x + 1
        let maxH = this.sockbodyPosition.rightBottom.y - this.sockbodyPosition.leftTop.y + 1
        if (this.checkImgType) {
          if (this.importImgData.h > this.importImgData.w) {
            let bs = maxH / this.importImgData.h;
            if (newVal.w > Math.floor(bs * this.importImgData.w)) {
              this.$set(
                this.upDataImgSize,
                "w",
                Math.floor(bs * this.importImgData.w)
              );
            } else {
              let bi = newVal.w / this.importImgData.w;
              this.$set(
                this.upDataImgSize,
                "h",
                Math.floor(this.importImgData.h * bi)
              );
            }
          } else {
            var w = newVal.w;
            if (w * 1 > maxW * 1) {
              this.$set(this.upDataImgSize, "w", maxW);
              w = maxW;
            }
            let bi = w / this.importImgData.w;
            this.$set(
              this.upDataImgSize,
              "h",
              Math.floor(this.importImgData.h * bi)
            );
          }
        } else {

          if (newVal.w * 1 > maxW * 1) {
            this.$set(this.upDataImgSize, "w", maxW);
          }
          if (newVal.h * 1 > maxH * 1) {
            this.$set(this.upDataImgSize, "h", maxH);
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener("focus", this.handleFocusCallBack);
    this.$nextTick(() => {
      this.$refs.fileInput.click();
    });
  }, // 解除窗口关闭[监听]事件
  destroyed() {
    window.removeEventListener("focus", this.handleFocusCallBack);
  },
  methods: {
    //关闭选取文件弹框
    handleFocusCallBack() {
      setTimeout(() => {
        if (!this.getImgType) {
          this.$emit("hiddenPup");
        }
      }, 400);
    },

    //关闭导入图片弹框
    hiddenPup() {
      this.$emit("hiddenPup");
    },

    //清空导入数据
    clearData() {
      this.colorNum = 8;
      this.colorList = [];
      this.pantongColorCode = [];
      this.ptbase64String = "";
      reduceImgData = "";
      palette = "";
      pantonePalette = "";
    },

    //获取导入的图片
    getImg(e) {
      this.getImgType = true;
      let file = e.target.files[0];
      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.checkImgType = false;
          this.clearData();
          let img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            if (this.imgPupType != 2) {
              let w = this.sockbodyPosition.rightBottom.x - this.sockbodyPosition.leftTop.x + 1
              let h = this.sockbodyPosition.rightBottom.y - this.sockbodyPosition.leftTop.y + 1
              const [newWidth, newHeight] = this.calculateSize(
                img,
                w,
                h
              );
              let canvas1 = document.createElement("canvas");
              canvas1.width = newWidth;
              canvas1.height = newHeight;
              const ctx = canvas1.getContext("2d", {
                willReadFrequently: true,
              });
              ctx.fillStyle = "rgba(255,255,255,1)";
              ctx.fillRect(0, 0, canvas1.width, canvas1.height);
              this.upDataImgSize.w = newWidth;
              this.upDataImgSize.h = newHeight;
              this.importImgData.w = newWidth;
              this.importImgData.h = newHeight;
              this.ImgSize.w = newWidth;
              this.ImgSize.h = newHeight;
              ctx.drawImage(img, 0, 0, newWidth, newHeight);
              this.base64String = canvas1.toDataURL("image/png");
              this.img = canvas1.toDataURL("image/png");
              document.querySelector(".fileInput").value = "";
            } else {
              console.log(this.sockbodyPosition)
              let w = this.sockbodyPosition.rightBottom.x - this.sockbodyPosition.leftTop.x + 1
              let h = this.sockbodyPosition.rightBottom.y - this.sockbodyPosition.leftTop.y + 1
              if (w == img.width && h == img.height) {
                this.ImgSize.w = img.width;
                this.ImgSize.h = img.height;
                var canvas1 = document.getElementById("canvasSrc");
                canvas1.width = img.width;
                canvas1.height = img.height;
                const ctx = canvas1.getContext("2d");
                ctx.fillStyle = "rgba(255,255,255,1)";
                ctx.fillRect(0, 0, canvas1.width, canvas1.height);
                ctx.drawImage(img, 0, 0, img.width, img.height);
                this.base64String = canvas1.toDataURL("image/png");
                this.img = canvas1.toDataURL("image/png");
                document.querySelector(".fileInput").value = "";
                this.importStructureImgData = ctx.getImageData(
                  0,
                  0,
                  canvasSrc.width,
                  canvasSrc.height
                )
                this.Recolor();
              } else {
                this.hiddenPup()
                document.querySelector(".fileInput").value = "";
              }
            }
          };
        };
      }
    },

    //导入修改默认尺寸
    calculateSize(img, maxWidth, maxHeight) {
      let w = img.width;
      let h = img.height;
      if (w >= h) {
        if (w > maxWidth) {
          if ((h * maxWidth) / w > maxHeight) {
            h = maxHeight;
          } else {
            h = (h * maxWidth) / w;
          }
          w = maxWidth;
        }
      } else {
        if (h > maxHeight) {
          if ((w * maxHeight) / h > maxWidth) {
            w = maxWidth;
          } else {
            w = (w * maxHeight) / h;
          }
          h = maxHeight;
        }
      }
      w = Math.floor(w);
      h = Math.floor(h);
      return [w, h];
    },

    //打开修改尺寸模块
    setupDataType() {
      if (this.base64String != "") {
        this.upDataType = false;
      } else {
        this.$message({
          showClose: true,
          message: this.$t("pup.txt77"),
          type: "warning",
        });
      }
    },

    //保持比例
    setcheckImgType(type) {
      if (this.importImgData.h > this.importImgData.w) {
        let bs = this.hg / this.importImgData.h;
        if (this.upDataImgSize.w > Math.floor(bs * this.importImgData.w)) {
          this.$set(
            this.upDataImgSize,
            "w",
            Math.floor(bs * this.importImgData.w)
          );
        } else {
          let bi = this.upDataImgSize.w / this.importImgData.w;
          this.$set(
            this.upDataImgSize,
            "h",
            Math.floor(this.importImgData.h * bi)
          );
        }
      } else {
        let bi = this.upDataImgSize.w / this.importImgData.w;
        this.$set(
          this.upDataImgSize,
          "h",
          Math.floor(this.importImgData.h * bi)
        );
      }
      this.checkImgType = type;
    },

    //确认修改尺寸
    setImgsize() {
      let canvasSrc = document.createElement("canvas");
      let w = this.upDataImgSize.w;
      let h = this.upDataImgSize.h;
      this.$set(this.ImgSize, "w", this.upDataImgSize.w);
      this.$set(this.ImgSize, "h", this.upDataImgSize.h);
      // this.$set(this.importImgData, "w", this.upDataImgSize.w);
      // this.$set(this.importImgData, "h", this.upDataImgSize.h);
      canvasSrc.width = w;
      canvasSrc.height = h;
      const ctx = canvasSrc.getContext("2d", {
        willReadFrequently: true,
      });
      let img = new Image();
      img.src = this.img;
      img.onload = () => {
        ctx.drawImage(img, 0, 0, w, h);
        this.base64String = canvasSrc.toDataURL("image/png");
        this.upDataType = true;
        this.setSizeType = true;
      };
    },

    //打开下一步转化颜色弹框
    nextStep() {
      if (this.base64String != "") {
        this.step = 2;
        this.transformType = true;
        this.setInitcanvas(1);
      } else {
        this.$message({
          showClose: true,
          message: this.$t("pup.txt77"),
          type: "warning",
        });
      }
    },

    setInitcanvas() {
      let type = 0;
      if (this.ptbase64String == "") {
        type = 1;
      } else {
        if (this.setSizeType) {
          type = 2;
        }
      }
      if (type != 0) {
        let w = this.ImgSize.w;
        let h = this.ImgSize.h;
        var canvasSrc = document.getElementById("canvasSrc");
        canvasSrc.width = w;
        canvasSrc.height = h;
        const ctx1 = canvasSrc.getContext("2d", {
          willReadFrequently: true,
        });
        let img = new Image();
        img.src = this.img;
        img.onload = () => {
          ctx1.drawImage(img, 0, 0, w, h);
          if (type == 2) {
            if (this.ptbase64String != "") {
              this.clearData();
            }
            this.setSizeType = false;
          }
          setTimeout(() => {
            this.Recolor();
          }, 100);
        };
      } else {
        this.transformType = false;
      }
    },

    // 转换图片
    //判断支不支持 Web worker
    Recolor() {
      if (this.imgPupType == 2) {
        this.loading = true;
      }
      opts.colors = this.imgPupType == 2 ? this.scolorNum : this.colorNum;
      opts.dithKern = "Atkinson";
      var canvasSrc = document.getElementById("canvasSrc");
      var canvasReduce = document.getElementById("canvasReduce");
      canvasReduce.width = canvasSrc.width;
      canvasReduce.height = canvasSrc.height;
      if (typeof Worker !== "undefined") {
        // Web worker 支持!
        this.WorkerReduce(opts, canvasSrc, canvasReduce);
      } else {
        //Web Worker 不支持!
        this.NonWorkerReduce(opts, canvasSrc, canvasReduce);
      }
    },

    //支持Web worker 流程
    WorkerReduce(opts, canvasSrc, canvasReduce) {
      const ctx = canvasSrc.getContext("2d");
      let imageData = []
      if (this.imgPupType != 2) {
        imageData = ctx.getImageData(
          0,
          0,
          canvasSrc.width,
          canvasSrc.height
        );
      } else {
        imageData = this.importStructureImgData
      }
      var worker = new Worker();
      worker.postMessage([opts, imageData]);
      worker.onmessage = (e) => {
        if (this.imgPupType != 2) {
          reduceImgData = e.data[0];
          palette = e.data[1];
          pantonePalette = this.GenerateSimlarPalette(e.data[1]);
          this.GeneratePalette(palette, "ReducePalette");
          //要先生成palette，才能获取相近色，再去绘制量化后的图像
          // this.DrawReduceIMG(reduceImgData, canvasReduce, palette, palette);
          setTimeout(() => {
            this.ReplaceColor();
          }, 100);
        } else {
          reduceImgData = e.data[0];
          palette = e.data[1];
          let canvas1 = document.createElement("canvas");
          canvas1.width = canvasSrc.width;
          canvas1.height = canvasSrc.height;
          const ctx = canvas1.getContext("2d", {
            willReadFrequently: true,
          });
          const ctx2 = canvasSrc.getContext("2d", {
            willReadFrequently: true,
          });
          let data = ctx.getImageData(0, 0, canvasSrc.width, canvasSrc.height);
          data.data.forEach((item, i) => {
            data.data[i] = reduceImgData[i];
          });
          for (var i = 0; i < palette.length; i += 4) {
            this.colorList.push({
              rgb: `${palette[i]},${palette[i + 1]},${palette[i + 2]}`,
            });
          }
          this.scolorNum = this.colorList.length;
          pantonePalette = this.GenerateSimlarPalette(e.data[1]);
          ctx.putImageData(data, 0, 0);
          ctx2.putImageData(data, 0, 0);
          this.base64String = canvas1.toDataURL("image/png");
          this.transformType = false;
          this.loading = false;
        }
        /* do something */
        worker.terminate();
        worker = undefined;
      };
    },

    //不支持Web worker 流程
    NonWorkerReduce(opts, canvasSrc, canvasReduce) {
      if (this.imgPupType == 2) {
        const ctx2 = canvasSrc.getContext("2d", {
          willReadFrequently: true,
        });
        ctx2.putImageData(this.importStructureImgData, 0, 0);
      }
      var quant = new RgbQuant(opts); //也可无参，使用默认配置
      quant.sample(canvasSrc); // 分析直方图(又称质量分布图),参数类型：<img>, <canvas>, Context2D, ImageData, Typed Array, Array.
      reduceImgData = quant.reduce(canvasSrc); // 减小图片颜色数量,参数类型：同上
      palette = quant.palette(); // 构建画板，第一次使用时调用。
      if (this.imgPupType != 2) {
        pantonePalette = this.GenerateSimlarPalette(palette);
        this.GeneratePalette(palette, "ReducePalette");
        //要先生成palette，才能获取相近色，再去绘制量化后的图像
        // this.DrawReduceIMG(reduceImgData, canvasReduce, palette, palette);
        setTimeout(() => {
          this.ReplaceColor();
        }, 100);
      } else {
        // reduceImgData=
        let canvas1 = document.createElement("canvas");
        canvas1.width = canvasSrc.width;
        canvas1.height = canvasSrc.height;
        const ctx = canvas1.getContext("2d", {
          willReadFrequently: true,
        });
        const ctx2 = canvasSrc.getContext("2d", {
          willReadFrequently: true,
        });
        let data = ctx.getImageData(0, 0, canvasSrc.width, canvasSrc.height);
        data.data.forEach((item, i) => {
          data.data[i] = reduceImgData[i];
        });
        for (var i = 0; i < palette.length; i += 4) {
          this.colorList.push({
            rgb: `${palette[i]},${palette[i + 1]},${palette[i + 2]}`,
          });
        }
        this.scolorNum = this.colorList.length;
        pantonePalette = this.GenerateSimlarPalette(palette);
        ctx.putImageData(data, 0, 0);
        ctx2.putImageData(data, 0, 0);
        this.base64String = canvas1.toDataURL("image/png");
        this.transformType = false;
        this.loading = false;
      }
    },

    //检索相对应的潘通色,重现渲染以潘通色为基础的canvas
    ReplaceColor() {
      var canvasSrc = document.getElementById("canvasSrc");
      var canvasPantone = document.getElementById("canvasReduce");
      if (!canvasPantone) {
        canvasPantone = document.createElement("canvas");
        canvasPantone.setAttribute("id", "canvasReduce");
        canvasPantone.style.margin = "5px";
        document.getElementById("root").append(canvasPantone);
      }
      canvasPantone.width = canvasSrc.width;
      canvasPantone.height = canvasSrc.height;

      pantonePalette = this.GenerateSimlarPalette(palette);
      this.GeneratePalette(pantonePalette, "PantonePalette");
      //要先生成palette，才能获取相近色，再去绘制量化后的图像
      this.colorNum = this.colorList.length;
      this.DrawReduceIMG(reduceImgData, canvasPantone, palette, pantonePalette);
    },

    //检索canvas上的颜色
    GenerateSimlarPalette(palette) {
      var simlarPalette = new Array(palette.length);
      var findColor = new Array(4);
      var simlarColor;
      for (let i = 0; i < simlarPalette.length; i += 4) {
        findColor[0] = palette[i + 0];
        findColor[1] = palette[i + 1];
        findColor[2] = palette[i + 2];
        findColor[3] = palette[i + 3];
        simlarColor = this.closestIndexedColor(findColor);
        simlarPalette[i + 0] = simlarColor[0];
        simlarPalette[i + 1] = simlarColor[1];
        simlarPalette[i + 2] = simlarColor[2];
        simlarPalette[i + 3] = findColor[3];
      }
      return simlarPalette;
    },

    //找出原图检索出的颜色所对应的潘通色
    closestIndexedColor(color) {
      var closest = {};
      var dist;
      var closestIndex;
      for (var i = 0; i < ptColorIndexed.length; i++) {
        dist = Math.pow(ptColorIndexed[i][0] - color[0], 2);
        dist += Math.pow(ptColorIndexed[i][1] - color[1], 2);
        dist += Math.pow(ptColorIndexed[i][2] - color[2], 2);
        dist = Math.sqrt(dist);

        if (!closest.dist || closest.dist > dist) {
          closest.dist = dist;
          closest.color = ptColorIndexed[i];
          closestIndex = i;
        }
      }
      this.pantongColorCode.push(ptColor[closestIndex]);
      return closest.color;
    },

    //合成检索出的颜色数据rgba显示到页面上
    GeneratePalette(palette, paletteName) {
      for (let i = 0; i < palette.length; i += 4) {
        let r = palette[i + 0];
        let g = palette[i + 1];
        let b = palette[i + 2];
        let a = palette[i + 3];
        if (paletteName == "PantonePalette") {
          let type = true;
          this.colorList.forEach((item, index) => {
            if (item.r == r && item.g == g && item.b == item.b) {
              type = false;
            }
          });
          if (type) {
            this.colorList.push({
              r,
              g,
              b,
              a: 255,
            });
          }
        }
      }
    },

    //渲染canvas生成图片
    DrawReduceIMG(reduceImgData, canvas, palette, simlarPalette) {
      const ctx = canvas.getContext("2d");
      var newImgData = ctx.createImageData(canvas.width, canvas.height);

      // 每四个元素为一个像素数据 r,g,b,alpha
      for (var i = 0; i < newImgData.data.length; i += 4) {
        //替换原画板颜色为相近潘通色画板上的颜色。
        for (let j = 0; j < palette.length; j += 4) {
          if (
            palette[j + 0] == reduceImgData[i + 0] &&
            palette[j + 1] == reduceImgData[i + 1] &&
            palette[j + 2] == reduceImgData[i + 2]
          ) {
            newImgData.data[i + 0] = simlarPalette[j + 0];
            newImgData.data[i + 1] = simlarPalette[j + 1];
            newImgData.data[i + 2] = simlarPalette[j + 2];
            newImgData.data[i + 3] = 255;
            break;
          }
        }
      }
      ctx.putImageData(newImgData, 0, 0);
      this.ptbase64String = canvas.toDataURL("image/png");
      this.transformType = false;
    },

    //颜色数量转换
    setColorNum() {
      if (!this.transformType) {
        this.colorList = []; //当前潘通色颜色
        this.pantongColorCode = []; //转换的潘通色
        this.ptbase64String = "";
        reduceImgData = "";
        palette = "";
        pantonePalette = "";
        this.transformType = true;
        this.Recolor();
      }
    },

    //点击应用使图片渲染到结构画板
    applicationStructure() {
      if (this.transformType) {
        return;
      }
      let canvasReduce = document.getElementById("canvasSrc");
      const ctx = canvasReduce.getContext("2d");
      const imageData = ctx.getImageData(
        0,
        0,
        canvasReduce.width,
        canvasReduce.height
      );
      this.loading = true;
      if (typeof Worker !== "undefined") {
        // Web worker 支持!
        var worker = new Worker();
        worker.postMessage({
          type: 1,
          arr: imageData,
        });
        worker.onmessage = (e) => {
          let data = {
            data: e.data,
            img: canvasReduce.toDataURL("image/png"),
            colorList: this.colorList,
          };
          this.$emit("sendStructureImgData", data);
          worker.terminate();
          worker = undefined;
        };
      } else {
        //Web Worker 不支持!
        let arr = [];
        for (var i = 0; i < imageData.height; i++) {
          arr.push([]);
          for (
            var j = i * imageData.width * 4;
            j < imageData.width * 4 + i * imageData.width * 4;
            j += 4
          ) {
            arr[i].push({
              r: imageData.imageData[j],
              g: imageData.imageData[j + 1],
              b: imageData.imageData[j + 2],
              // a: imageData.imageData[j + 3],
            });
          }
        }
        let data = {
          data: arr,
          img: canvasReduce.toDataURL("image/png"),
          colorList: this.colorList,
        };
        this.$emit("sendStructureImgData", data);
        this.clearData();
      }
    },

    //点击应用使图片渲染到画板
    application() {
      if (this.transformType) {
        return;
      }
      this.loading = true;
      let canvasReduce =
        this.imgPupType == 2
          ? document.getElementById("canvasSrc")
          : document.querySelector("#canvasReduce");
      const ctx = canvasReduce.getContext("2d");
      const imageData = ctx.getImageData(
        0,
        0,
        canvasReduce.width,
        canvasReduce.height
      );
      if (typeof Worker !== "undefined") {
        // Web worker 支持!
        var worker = new Worker();
        worker.postMessage({
          type: 1,
          arr: imageData,
        });
        worker.onmessage = (e) => {
          let data = {
            size: this.upDataImgSize,
            data: e.data,
            color: [],
            img: canvasReduce.toDataURL("image/png"),
          };
          this.colorList.forEach((item, index) => {
            let str = `${item.r},${item.g},${item.b}`;
            for (var i = 0; i < ptColor.length; i++) {
              if (ptColor[i].rgb == str) {
                data.color.push(ptColor[i]);
                return;
              }
            }
          });
          if (this.yarnListLength + data.color.length > 8) {
            this.$message({
              message: `${this.$t("pup.txt75")} ${8 - this.yarnListLength
                } ${this.$t("pup.txt76")}`,
              type: "warning",
            });
            this.loading = false;
            return;
          }
          this.$emit("sendImgData", data);
          // this.clearData();
          worker.terminate();
          worker = undefined;
        };
      } else {
        //Web Worker 不支持!
        let arr = [];
        for (var i = 0; i < imageData.height; i++) {
          arr.push([]);
          for (
            var j = i * imageData.width * 4;
            j < imageData.width * 4 + i * imageData.width * 4;
            j += 4
          ) {
            arr[i].push({
              r: imageData.imageData[j],
              g: imageData.imageData[j + 1],
              b: imageData.imageData[j + 2],
              // a: imageData.imageData[j + 3],
            });
          }
        }
        let data = {
          size: this.upDataImgSize,
          data: arr,
          color: [],
          img: canvasReduce.toDataURL("image/png"),
        };
        this.colorList.forEach((item, index) => {
          let str = `${item.r},${item.g},${item.b}`;
          for (var i = 0; i < ptColor.length; i++) {
            if (ptColor[i].rgb == str) {
              data.color.push(ptColor[i]);
              return;
            }
          }
        });
        if (this.yarnListLength + data.color.length > 8) {
          this.$message({
            message: `${this.$t("pup.txt75")} ${8 - this.yarnListLength
              } ${this.$t("pup.txt76")}`,
            type: "warning",
          });
          this.loading = false;
          return;
        }
        this.$emit("sendImgData", data);
        this.clearData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./getImgcolor.less");
</style>