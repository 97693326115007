<template>
  <div class="Pup">
    <div class="PupCon" :style="pupData.pupConType == 1 ? 'padding-bottom:0' : ''" v-if="pupData.pupType == 1">
      <div class="pupHead">
        <span>{{ pupData.title }}</span>
        <img src="@/newImg/hidden.png" alt="" @click="sendRes(false)" />
      </div>
      <!-- 新建 -->
      <div :class="$store.state.index.languageType == 'zh' ? 'txt' : 'txten'" v-show="pupData.pupConType == 1">
        {{ $t("pup.txt12") }}
      </div>
      <!-- 提示颜色超出不能导入图片 -->
      <div :class="$store.state.index.languageType == 'zh' ? 'tipsTxt' : 'tipsTxten'
        " v-show="pupData.pupConType == 2">
        <div>{{ $t("pup.txt15") }}</div>
        <div>{{ $t("pup.txt16") }}</div>
        <div class="BottomBox">{{ $t("pup.txt17") }}</div>
      </div>
      <div :class="$store.state.index.languageType == 'zh'
        ? 'tipsTxt3'
        : ' tipsTxt3 tipsTxten3'
        " v-show="pupData.pupConType == 3">
        <div>{{ $t("pup.txt78") }}</div>
      </div>
      <div class="bottomBox">
        <span></span>
        <div class="btnBox">
          <div @click="sendRes(false)" class="btn">{{ $t("pup.txt13") }}</div>
          <div @click="sendRes(true)" class="btn rigthBtn">
            {{ $t("pup.txt14") }}
          </div>
        </div>
      </div>
    </div>

    <!-- 纱线列表弹框 -->
    <div class="PupCon2" v-show="yarnPuptype != 0 ? false : true" v-if="pupData.pupType == 2">
      <div class="pupHead">
        <span>{{ pupData.title }}</span>
        <img src="@/newImg/hidden.png" @click="sendRes(false)" alt="" />
      </div>
      <div class="yarnListTable">
        <div class="tableTilte">
          <div :class="`item item${index + 1}`" v-for="(item, index) in tableData.HeadList" :key="index"
            :style="index == tableData.HeadList.length - 1 ? 'border-right:none' : ''">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="tableList">
          <div class="SocksleftBox">
            <span>{{ $t("machine.txt91") }}</span>
          </div>
          <!-- 袜身颜色 -->
          <div>
            <div v-for="(item, index) in tableData.data" :key="index" class="tableCon">
              <div class="items item2">
                <span :style="`background:${item.hexCode};`"></span>
              </div>
              <div class="items item3">
                <span>{{ item.nr }}</span>
              </div>
              <div @click="opensetYarnPup(1, index, 1)" class="items item4" style="cursor: pointer">
                <span v-for="(items, indexs) in item.component.data" :key="indexs + 1000"
                  v-show="item.component.data.length == 0 ? false : true">
                  {{ items.proportion }}%{{
                    materialQuality[items.componentNum].label
                  }}
                </span>
                <span style="color: #cccccc" v-show="item.component.data.length != 0 ? false : true">—</span>
              </div>
              <div @click="opensetYarnPup(2, index, 1)" class="items item5" style="cursor: pointer">
                <span v-show="item.YarnCount.num == '' ? false : true">{{ item.YarnCount.num }}
                  {{
                    item.YarnCount.num != ""
                    ? item.YarnCount.unit == 1
                      ? "D"
                      : "S"
                    : ""
                  }}</span>
                <span style="color: #cccccc" v-show="item.YarnCount.num != '' ? false : true">—</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 袜子特殊部位 -->
        <div>
          <div v-for="(item, index) in tableData.positionColorList" :key="index" class="tableCon">
            <div class="items item1 leftBorder">
              {{ $t(`machine.txt${92 + index}`) }}
            </div>
            <div class="items item2">
              <span :style="`background:${item.hexCode};`"></span>
            </div>
            <div class="items item3">
              <span>{{ item.nr }}</span>
            </div>
            <div @click="opensetYarnPup(1, index, 2)" class="items item4" style="cursor: pointer">
              <span v-for="(items, indexs) in item.component.data" :key="indexs + 1000"
                v-show="item.component.data.length == 0 ? false : true">
                {{ items.proportion }}%{{
                  materialQuality[items.componentNum].label
                }}
              </span>
              <span style="color: #cccccc" v-show="item.component.data.length != 0 ? false : true">—</span>
            </div>
            <div @click="opensetYarnPup(2, index, 2)" class="items item5" style="cursor: pointer">
              <span v-show="item.YarnCount.num == '' ? false : true">{{ item.YarnCount.num }}
                {{
                  item.YarnCount.num != ""
                  ? item.YarnCount.unit == 1
                    ? "D"
                    : "S"
                  : ""
                }}</span>
              <span style="color: #cccccc" v-show="item.YarnCount.num != '' ? false : true">—</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBox">
        <span></span>
        <div class="btnBox">
          <div @click="sendRes(false)" class="btn">{{ $t("pup.txt13") }}</div>
          <div @click="sendRes(true)" class="btn rigthBtn">
            {{ $t("pup.txt14") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 修改纱线成分和纱支 -->
    <div :class="yarnPuptype == 1 ? 'yarnPup' : 'yarnPup szYarnPup'" v-show="yarnPuptype != 0 ? true : false">
      <div class="pupHead">
        <span>
          {{ yarnPuptype == 1 ? $t("pup.txt39") : $t("pup.txt22") }}
          <span class="tips" v-show="yarnPuptype == 1">{{
            $t("pup.txt40")
          }}</span></span>
        <img src="@/newImg/hidden.png" alt="" @click="yarnPuptype = 0" />
      </div>
      <div class="componentTxt" v-show="yarnPuptype == 1">
        <div class="con">
          <div class="item">
            <div :class="$store.state.index.languageType == 'zh'
              ? 'itemTitle'
              : 'itemTitleen1'
              ">
              {{ $t("pup.txt42") }}
            </div>
            <div class="check">
              <div class="checkItem">
                <span @click="setyarnTypeCheckIndex(1)">
                  <div v-show="yarnTypeCheckIndex == 1"></div>
                </span>
                <div class="txt">{{ $t("pup.txt43") }}</div>
              </div>
              <div class="checkItem">
                <span @click="setyarnTypeCheckIndex(2)">
                  <div v-show="yarnTypeCheckIndex == 2"></div>
                </span>
                <div class="txt">{{ $t("pup.txt44") }}</div>
              </div>
            </div>
          </div>
          <div class="item" v-show="yarnTypeCheckIndex == 1">
            <div :class="$store.state.index.languageType == 'zh'
              ? 'itemTitle'
              : 'itemTitleen2'
              ">
              {{ $t("pup.txt45") }}
            </div>
            <div class="selectBox">
              <div class="errTips" v-show="errTipstype">
                {{ $t("pup.txt47") }}
              </div>
              <el-select :class="errTipstype ? 'select errSelect' : 'select'" v-model="pureYarnNum"
                :placeholder="$t('pup.txt46')" @change="getNum">
                <el-option v-for="item in materialQuality" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="item" v-show="yarnTypeCheckIndex == 2">
            <div :class="$store.state.index.languageType == 'zh'
              ? 'itemTitle'
              : 'itemTitleen3'
              ">
              {{ $t("pup.txt48") }}
            </div>
            <div class="selectBox">
              <div class="errTips" v-show="errTipstype">
                {{ $t("pup.txt49") }}
              </div>
              <el-select :class="errTipstype ? 'select errSelect' : 'select'" @change="getNum" v-model="checkNum"
                :placeholder="$t('pup.txt46')">
                <el-option v-for="item in componentNum" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="componentTable" v-show="componentTable.length != 0 && yarnTypeCheckIndex == 2">
            <div class="tableTitle">
              <div class="tableitem">{{ $t("pup.txt50") }}</div>
              <div class="tableitem">{{ $t("pup.txt51") }}</div>
              <div class="tableitem">{{ $t("pup.txt52") }}</div>
            </div>
            <div class="list" v-for="(item, index) in componentTable" :key="index">
              <div class="tableitem">{{ index + 1 }}</div>
              <div class="tableitem">
                <el-select class="tableselect" @change="getCheckmaterialQuality" v-model="item.componentNum"
                  :placeholder="$t('pup.txt46')">
                  <el-option v-for="item in materialQuality" :key="item.value" :disabled="item.type" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="tableitem">
                <input type="text" v-model="item.proportion" class="proportion" /><span>%</span>
              </div>
            </div>
          </div>
          <div class="btnBox">
            <div @click="setYarn(false)" class="btn">{{ $t("pup.txt13") }}</div>
            <div @click="setYarn(true)" class="btn rigthBtn">
              {{ $t("pup.txt14") }}
            </div>
          </div>
        </div>
      </div>
      <div class="YarnCount" v-show="yarnPuptype == 2">
        <div class="item">
          <div class="itemTitle">{{ $t("pup.txt53") }}</div>
          <div class="selectBox">
            <div class="errTips" v-show="errTipstype">
              {{ $t("pup.txt55") }}
            </div>
            <input type="text" v-model="YarnCountNum" :class="errTipstype ? 'numInput errSelect' : 'numInput'"
              :placeholder="$t('pup.txt55')" @focus="errTipstype = false" />
          </div>
        </div>
        <div class="item">
          <div class="itemTitle">{{ $t("pup.txt54") }}</div>
          <div class="check">
            <div class="checkItem">
              <span @click="YarnCountCheck = 1">
                <div v-show="YarnCountCheck == 1"></div>
              </span>
              <div class="txt">D</div>
            </div>
            <div class="checkItem">
              <span @click="YarnCountCheck = 2">
                <div v-show="YarnCountCheck == 2"></div>
              </span>
              <div class="txt">S</div>
            </div>
          </div>
        </div>
        <div class="btnBox">
          <div @click="setYarnCount(false)" class="btn">
            {{ $t("pup.txt13") }}
          </div>
          <div @click="setYarnCount(true)" class="btn rigthBtn">
            {{ $t("pup.txt14") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 生成结构模块弹框 -->
    <div class="Structure" v-show="pupData.pupType == 3">
      <div class="pupHead">
        <span>{{ pupData.title }}</span>
        <img src="@/newImg/hidden.png" alt="" @click="sendRes(false)" />
      </div>
      <!-- 自定义 -->
      <div class="Con" v-show="pupData.pupConType == 1">
        <div class="left">
          <div class="leftTop">
            <div :class="$store.state.index.languageType == 'zh'
              ? 'sizeBox leftBox'
              : 'leftBoxen sizeBoxen'
              ">
              <span>{{ $t("pup.txt1") }}</span>
              <el-input-number controls-position="right" :min="2" :max="160" ref="numberInput"
                v-model="StructureW"></el-input-number>
            </div>
            <div :class="$store.state.index.languageType == 'zh'
              ? 'sizeBox '
              : '  sizeBoxen'
              ">
              <span>{{ $t("pup.txt2") }}</span>
              <el-input-number v-model="StructureH" controls-position="right" :min="2" :max="160"
                ref="numberInput"></el-input-number>
            </div>
            <div :class="$store.state.index.languageType == 'zh'
              ? 'Renew'
              : ' Renew Renewen'
              " @click="setStructureCanvas">
              <span>{{ $t("pup.txt3") }}</span>
              <!-- <img src="@/newImg/Renew.png" alt="" /> -->
            </div>
          </div>
          <div class="leftCon">
            <div class="Structurecanvas" :style="`${StructureStyleW ? 'align-items: start;' : ''}${StructureStyleH ? ' justify-content: start;' : ''
              }`">
              <!-- ${FullDataType ? 'overflow: hidden;' : ''} -->
              <div :class="i == 0 ? 'fristItem fristBorder' : 'fristItem'" v-for="(item, i) in checkStructureModel"
                :key="i">
                <div class="items" @click="setTableStructure(i, j)" v-for="(items, j) in item" :key="j" :style="FullDataType
                  ? ` width: ${FullDataStructureW}px;height: ${FullDataStructureH}px;`
                  : ''
                  ">
                  <img :src="StructureKey[items - 1]" alt="" />
                </div>
              </div>
            </div>
            <div class="StructureImg">
              <div class="img">
                <img src="@/newImg/cq.png" alt="" />
                <span>{{ $t("pup.txt7") }}</span>
              </div>
              <div class="img">
                <img src="@/newImg/jq.png" alt="" />
                <span>{{ $t("pup.txt8") }}</span>
              </div>
              <div class="img">
                <img src="@/newImg/fx.png" alt="" />
                <span>{{ $t("pup.txt9") }}</span>
              </div>
            </div>
          </div>
          <div class="btnClear">
            <span></span>
            <div class="boxbtn">
              <span @click="FullData" style="display: none">{{
                $t("pup.txt5")
              }}</span>
              <span @click="clearData" style="display: none">{{
                $t("pup.txt4")
              }}</span>
            </div>
          </div>
          <div class="bottomBox">
            <span></span>
            <div class="btnBox">
              <div @click="sendRes(false)" class="btn">
                {{ $t("pup.txt13") }}
              </div>
              <div @click="renderCanvas" class="btn rigthBtn">
                {{ pupData.type == 1 ? $t("pup.txt14") : $t("pup.txt82") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Structurelibrary" v-show="pupData.pupConType == 2">
        <div class="left">
          <img :src="StructurelibraryImg" alt="" class="img" />
          <div class="sizeBox">
            <span>{{ $t("pup.txt1") }}</span>
            <el-input-number controls-position="right" :min="2" :max="160" ref="numberInput" v-model="StructureW"
              @change="setStructureSize"></el-input-number>
          </div>
          <div class="sizeBox">
            <span>{{ $t("pup.txt2") }}</span>
            <el-input-number v-model="StructureH" controls-position="right" :min="2" :max="160" ref="numberInput"
              @change="setStructureSize"></el-input-number>
          </div>
        </div>
        <div class="right">
          <div class="rightCon">
            <div class="Structurecanvas libraryStructurecanvas" :style="`${StructureStyleW ? 'align-items: start;' : ''}${StructureStyleH ? ' justify-content: start;' : ''
              }`">
              <!-- ${FullDataType ? 'overflow: hidden;' : ''} -->
              <div :class="i == 0 ? 'fristItem fristBorder' : 'fristItem'" v-for="(item, i) in checkStructureModel"
                :key="i">
                <div class="items StructureItem" @click="setTableStructure(i, j)" v-for="(items, j) in item" :key="j"
                  :style="FullDataType
                    ? ` width: ${FullDataStructureW}px;height: ${FullDataStructureH}px;`
                    : ''
                    ">
                  <img :src="StructureKey[items - 1]" alt="" />
                </div>
              </div>
            </div>
            <div class="StructureImg">
              <div class="img">
                <img src="@/newImg/cq.png" alt="" />
                <span>{{ $t("pup.txt7") }}</span>
              </div>
              <div class="img">
                <img src="@/newImg/jq.png" alt="" />
                <span>{{ $t("pup.txt8") }}</span>
              </div>
              <div class="img">
                <img src="@/newImg/fx.png" alt="" />
                <span>{{ $t("pup.txt9") }}</span>
              </div>
            </div>
          </div>
          <div class="btns">
            <span></span>
            <div class="boxbtn">
              <span @click="FullData" style="display: none">{{
                $t("pup.txt5")
              }}</span>
              <span @click="clearData" style="display: none">{{
                $t("pup.txt4")
              }}</span>
            </div>
          </div>

          <div class="bottomBox">
            <span></span>
            <div class="btnBox">
              <div @click="sendRes(false)" class="btn">
                {{ $t("pup.txt13") }}
              </div>
              <div @click="renderCanvas" class="btn rigthBtn">
                {{ $t("pup.txt82") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonDialog :dialogVisible="isShowDialog" :content="setContent" :confirmContent="setConfirmContent"
      @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></CommonDialog>
  </div>
</template>

<script>
//公共弹框组件
import CommonDialog from "@/components/commonDialog/CommonDialog.vue";
export default {
  props: ["pupData"],
  components: {
    CommonDialog,
  },
  data() {
    return {
      tableData: {
        // 纱线列表数据
        HeadList: [
          '',
          this.$t("pup.txt19"),
          this.$t("pup.txt20"),
          this.$t("pup.txt21"),
          this.$t("pup.txt22"),
        ],
        data: [],
        positionColorList: []
      },
      yarnPuptype: 0, //控制修改纱线成分和纱支弹框显示或隐藏
      yarnTypeCheckIndex: 1, //选择纱线类型
      componentNum: [
        {
          value: "1",
          label: "请选择",
        },
        {
          value: "2",
          label: "1",
        },
        {
          value: "3",
          label: "2",
        },
        {
          value: "4",
          label: "3",
        },
      ],
      checkNum: "", //混纱数量
      componentTable: [], //混纱网格数据
      materialQuality: [
        {
          value: 0,
          label: this.$t("pup.txt24"),
          type: false
        },
        {
          value: 1,
          label: this.$t("pup.txt25"),
          type: false
        },
        {
          value: 2,
          label: this.$t("pup.txt26"),
        },
        {
          value: 3,
          label: this.$t("pup.txt27"),
          type: false
        },
        {
          value: 4,
          label: this.$t("pup.txt28"),
          type: false
        },
        {
          value: 5,
          label: this.$t("pup.txt29"),
          type: false
        },
        {
          value: 6,
          label: this.$t("pup.txt30"),
          type: false
        },
        {
          value: 7,
          label: this.$t("pup.txt31"),
          type: false
        },
        {
          value: 8,
          label: this.$t("pup.txt32"),
          type: false
        },
        {
          value: 9,
          label: this.$t("pup.txt33"),
          type: false
        },
        {
          value: 10,
          label: this.$t("pup.txt34"),
          type: false
        },
        {
          value: 11,
          label: this.$t("pup.txt35"),
          type: false
        },
        {
          value: 12,
          label: this.$t("pup.txt36"),
          type: false
        },
        {
          value: 13,
          label: this.$t("pup.txt37"),
          type: false
        },
        {
          value: 14,
          label: this.$t("pup.txt38"),
          type: false
        },
      ],
      pureYarnNum: "", //纯纱选择的成分
      errTipstype: false, //错误提示控制
      checkYarnListindex: 0, //选中的纱线索引
      YarnCountNum: "", //纱支数值
      listType: 3,//纱线成分更改
      YarnCountCheck: 1, //纱支d或s
      StructureW: 2, //结构模块宽度
      StructureH: 2, //结构模块高度
      StructureStyleW: false, //结构模块宽度样式判断
      StructureStyleH: false, //结构模块高度样式判断
      FullDataType: false, //是否为满屏状态
      FullDataStructureW: 0, //满屏状态结构模块宽度
      FullDataStructureH: 0, //满屏状态结构模块高度
      StructureKey: [
        require("../../../../newImg/cq.png"),
        require("../../../../newImg/jq.png"),
        require("../../../../newImg/fx.png"),
      ], //结构图片img
      checkStructureModelIndex: 0, //选中当前结构
      checkStructureModel: [], //当前选中
      checkStructuredataModel: ["", "", ""], //结构标识的imgData
      createStructureImg: "", //生成结构模块图片
      StructurelibraryImg: "", //结构库展示图
      defaultStructure: [
        {
          id: 0,
          arr: [
            [1, 1],
            [1, 1],
          ],
        },
      ], //默认结构模块
      isShowDialog: false,
      setContent: this.$t("left.txt12"),
      setConfirmContent: this.$t("left.txt13"),
    };
  },
  watch: {
    componentTable: {
      deep: true,
      handler(newVal) {
        newVal.forEach((item, index) => {
          if (item.proportion != "") {
            let num = item.proportion.replace(/[^0-9_]/g, "");
            if (num > 100) {
              num = "100";
            }
            this.$set(this.componentTable[index], "proportion", num);
          }
        });
      },
    },
    YarnCountNum(newVal) {
      let num = newVal.toString().replace(/[^0-9_]/g, "");
      if (num > 1000) {
        num = "1000";
      }
      this.YarnCountNum = num;
    },
    //监听主页面数据打开对应弹框
    pupData(newVal) {
      if (newVal.pupType == 2) {
        this.tableData.data = newVal.data;
        let positionColorList = JSON.parse(JSON.stringify(newVal.positionColorList))
        positionColorList.splice(3, 1)
        this.tableData.positionColorList = positionColorList;
        this.tableData.HeadList = [
          '',
          this.$t("pup.txt19"),
          this.$t("pup.txt20"),
          this.$t("pup.txt21"),
          this.$t("pup.txt22"),
        ];
        this.materialQuality.forEach((item, index) => {
          this.$set(
            this.materialQuality[index],
            "label",
            this.$t(`pup.txt${index + 24}`)
          );
        });
        this.$set(this.componentNum[0], "label", this.$t(`pup.txt46`));

      } else if (newVal.pupType == 3) {
        if (newVal.pupConType == 1) {
          if (newVal.StructureList != undefined) {
            this.checkStructureModel = newVal.StructureList.Structure;
            this.StructureW = newVal.StructureList.Structure[0].length;
            this.StructureH = newVal.StructureList.Structure.length;
          } else {
            this.checkStructureModel = [
              [1, 1],
              [1, 1],
            ];
          }
          // this.checkStructureModelIndex = newVal.checkStructureIndex;
          this.getDocStyle();
        } else {
          this.checkStructureModel = JSON.parse(
            JSON.stringify(newVal.data.arr)
          );
          this.StructurelibraryImg = require(`@/newImg/StructureLibrary/JG${newVal.data.img}.png`);
          this.StructureW = newVal.data.arr[0].length;
          this.StructureH = newVal.data.arr.length;
          this.getDocStyle();
        }
        for (let i = 0; i < 3; i++) {
          let url = "";
          if (i == 0) {
            url = require("../../../../newImg/cq.png");
          } else if (i == 1) {
            url = require("../../../../newImg/jq.png");
          } else {
            url = require("../../../../newImg/fx.png");
          }
          this.createStructureKey(i, url);
        }
      }
    },
    "$store.state.index.languageType"() {
      this.HeadList = [
        this.$t("pup.txt18"),
        this.$t("pup.txt19"),
        this.$t("pup.txt20"),
        this.$t("pup.txt21"),
        this.$t("pup.txt22"),
      ];
    },
  },
  methods: {
    //打开纱支或成分弹框
    opensetYarnPup(num, index, type) {
      this.yarnPuptype = num;
      this.checkYarnListindex = index;
      this.listType = type
      let arr = []
      if (type == 1) {
        arr = this.tableData.data
      } else {
        arr = this.tableData.positionColorList
      }
      if (num == 1) {
        this.yarnTypeCheckIndex = arr[index].component.type;
        if (arr[index].component.data.length == 0) {
          return;
        }
        if (arr[index].component.type == 1) {
          this.pureYarnNum =
            arr[index].component.data[0].componentNum;
        } else {
          arr[index].component.data.forEach((item, index) => {
            this.$set(this.materialQuality[item.componentNum], 'type', true)
          })
          this.componentTable = arr[index].component.data;
          this.checkNum = arr[index].component.data.length;
        }
      } else {
        this.YarnCountNum = arr[index].YarnCount.num;
        this.YarnCountCheck = arr[index].YarnCount.unit;
      }
    },

    // 纱线成分

    //切换成分是混纱还是纯纱
    setyarnTypeCheckIndex(type) {
      if (type != this.yarnTypeCheckIndex) {
        if (this.yarnTypeCheckIndex == 2) {
          this.checkNum = "";
          this.componentTable = [];
        } else {
          this.pureYarnNum = "";
        }
      }
      this.yarnTypeCheckIndex = type;
      this.materialQuality.forEach((item, i) => {
        this.$set(this.materialQuality[i], 'type', false)
      })
    },

    //选择混纱数量
    getNum(value) {
      this.errTipstype = false;
      if (this.yarnTypeCheckIndex == 2) {
        this.materialQuality.forEach((item, i) => {
          this.$set(this.materialQuality[i], 'type', false)
        })
        this.componentTable = [];
        if (value > 1) {
          for (var i = 0; i < this.componentNum[this.checkNum - 1].label; i++) {
            this.componentTable.push({
              componentNum: "",
              proportion: "",
            });
          }
        }
      }
    },

    //不可选中成分
    getCheckmaterialQuality() {
      this.materialQuality.forEach((item, i) => {
        this.$set(this.materialQuality[i], 'type', false)
      })
      this.componentTable.forEach((item) => {
        if (this.materialQuality[item.componentNum] != undefined) {
          this.$set(this.materialQuality[item.componentNum], 'type', true)
        }
      })
    },

    //点击纱线按钮 修改当前纱线
    setYarn(type) {
      if (type) {
        let setType = true;
        if (this.yarnTypeCheckIndex == 1) {
          if (this.pureYarnNum.toString() == "") {
            this.errTipstype = true;
            setType = false;
          }
          if (setType) {
            this.$set(
              this.listType == 1 ? this.tableData.data[this.checkYarnListindex] : this.tableData.positionColorList[this.checkYarnListindex],
              "component",
              {
                type: 1,
                data: [
                  {
                    componentNum: this.pureYarnNum,
                    proportion: 100,
                  },
                ],
              }
            );
            this.yarnPuptype = 0;
            this.pureYarnNum = "";
          }
        } else {
          if (this.checkNum == "") {
            this.errTipstype = true;
            setType = false;
            return;
          }
          let num = 0;
          let labelType = true;
          this.componentTable.forEach((item, index) => {
            num += item.proportion * 1;
            if (item.componentNum != '0') {
              if (item.componentNum == "") {
                labelType = false;
              }
            }
          });
          if (num != 100) {
            this.$message({
              showClose: true,
              message: this.$t("pup.txt56"),
              type: "warning",
            });
            setType = false;
          }

          if (!labelType) {
            this.$message({
              showClose: true,
              message: this.$t("pup.txt47"),
              type: "warning",
            });
            setType = false;
          }
          if (setType) {
            this.$set(
              this.listType == 1 ? this.tableData.data[this.checkYarnListindex] : this.tableData.positionColorList[this.checkYarnListindex],
              "component",
              {
                type: 2,
                data: this.componentTable,
              }
            );
            this.yarnPuptype = 0;
            this.checkNum = "";
            this.componentTable = [];
          }
        }
      } else {
        this.checkNum = "";
        this.componentTable = [];
        this.pureYarnNum = "";
        this.yarnPuptype = 0;
        this.errTipstype = false;
      }
      this.materialQuality.forEach((item, i) => {
        this.$set(this.materialQuality[i], 'type', false)
      })
    },


    // 纱支

    //修改纱支
    setYarnCount(type) {
      if (type) {
        if (this.YarnCountNum == "") {
          this.errTipstype = true;
          return;
        }
        this.$set(this.listType == 1 ? this.tableData.data[this.checkYarnListindex] : this.tableData.positionColorList[this.checkYarnListindex], "YarnCount", {
          num: this.YarnCountNum,
          unit: this.YarnCountCheck,
        });
        this.yarnPuptype = 0;
      } else {
        this.errTipstype = false;
        this.yarnPuptype = 0;
      }
    },

    //结构

    //生成结构标识
    createStructureKey(index, url) {
      let canvas1 = document.createElement("canvas");
      canvas1.width = 22;
      canvas1.height = 22;
      const ctx = canvas1.getContext("2d", {
        willReadFrequently: true,
      });
      let img = new Image();
      img.src = url;
      img.style = "image-rendering: pixelated;";
      img.onload = () => {
        ctx.beginPath();
        ctx.fillStyle = "#fff";
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#000";
        ctx.strokeRect(1, 1, 20, 20);
        ctx.drawImage(img, 1, 1, 20, 20);
        // checkStructuredataModel
        // ctx.getImageData();
        this.$set(
          this.checkStructuredataModel,
          index,
          ctx.getImageData(0, 0, 22, 22)
        );
        if (index == 2) {
          this.createkeyStructureImg();
        }
      };
    },

    //生成结构模块缩略图
    createkeyStructureImg() {
      this.defaultStructure.forEach((item, i) => {
        let canvas1 = document.createElement("canvas");
        canvas1.width = item.arr[0].length * 20 + 2;
        canvas1.height = item.arr.length * 20 + 2;
        const ctx = canvas1.getContext("2d", {
          willReadFrequently: true,
        });
        item.arr.forEach((items, j) => {
          items.forEach((el, p) => {
            ctx.beginPath();
            ctx.fillStyle = "#fff";
            ctx.lineWidth = 2;
            ctx.strokeStyle = "#000";
            ctx.strokeRect(1, 1, 20, 20);
            ctx.putImageData(
              this.checkStructuredataModel[el - 1],
              p * 20,
              j * 20
            );
          });
        });
        this.$set(
          this.defaultStructure[i],
          "img",
          canvas1.toDataURL("image/png")
        );
      });
    },

    //获取点击当前结构
    getStructureModel(index) {
      this.FullDataType = false;
      this.checkStructureModel = this.defaultStructure[index].arr;
      this.checkStructureModelIndex = index;
      this.StructureW = this.defaultStructure[index].arr[0].length;
      this.StructureH = this.defaultStructure[index].arr.length;
      this.getDocStyle();
    },

    //根据数据判断是剧中还是滚动条
    getDocStyle() {
      this.$nextTick(() => {
        let h;
        let w;
        let oneStructureSize;
        if (this.pupData.pupConType == 1) {
          h = document.querySelector(".Structurecanvas ").offsetHeight;
          w = document.querySelector(".Structurecanvas ").offsetWidth;
          oneStructureSize =
            document.querySelectorAll(".items ")[0].offsetWidth;
        } else {
          h = document.querySelector(".libraryStructurecanvas ").offsetHeight;
          w = document.querySelector(".libraryStructurecanvas ").offsetWidth;
          oneStructureSize =
            document.querySelectorAll(".StructureItem ")[0].offsetWidth;
        }
        if (oneStructureSize * this.checkStructureModel[0].length > w) {
          this.StructureStyleW = true;
        } else {
          this.StructureStyleW = false;
        }
        if (oneStructureSize * this.checkStructureModel.length > h) {
          this.StructureStyleH = true;
        } else {
          this.StructureStyleH = false;
        }
      });
    },

    //修改当前结构
    setTableStructure(i, j) {
      let active = this.checkStructureModel[i][j];
      if (active != 3) {
        this.$set(this.checkStructureModel[i], j, active + 1);
      } else {
        this.$set(this.checkStructureModel[i], j, 1);
      }
    },

    //清屏
    clearData() {
      if (this.pupData.pupConType == 1) {
        this.FullDataType = false;
        this.checkStructureModel.forEach((item, i) => {
          item.forEach((items, j) => {
            this.$set(this.checkStructureModel[i], j, 1);
          });
        });
      } else {
        this.FullDataType = false;
        this.checkStructureModel = this.pupData.data.arr;
        this.StructureW = this.pupData.data.arr[0].length;
        this.StructureH = this.pupData.data.arr.length;
      }
      this.getDocStyle();
    },

    //满屏
    FullData() {
      this.FullDataType = true;
      this.$nextTick(() => {
        let Hbfb = 100 / this.checkStructureModel.length;
        let Wbfb = 100 / this.checkStructureModel[0].length;
        let h = document.querySelector(".Structurecanvas ").offsetHeight;
        let w = document.querySelector(".Structurecanvas ").offsetWidth;
        if (w >= h) {
          this.FullDataStructureH = (w * Wbfb) / 100;
          this.FullDataStructureW = (w * Wbfb) / 100;
        } else {
          this.FullDataStructureH = (h * Hbfb) / 100;
          this.FullDataStructureW = (h * Hbfb) / 100;
        }
        this.getDocStyle();
      });
    },

    //生成结构
    setStructureCanvas() {
      let arr = JSON.parse(JSON.stringify(this.checkStructureModel));
      if (arr.length > this.StructureH) {
        arr = arr.slice(0, this.StructureH);
      }
      if (arr[0].length > this.StructureW) {
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].slice(0, this.StructureW);
        }
      }
      for (var i = 0; i < this.StructureH; i++) {
        if (this.StructureH > arr.length) {
          if (i > arr.length - 1) {
            arr.push([]);
          }
        }
        for (var j = 0; j < this.StructureW; j++) {
          if (this.StructureW > arr[i].length) {
            if (j > arr[i].length - 1) {
              arr[i].push(1);
            }
          }
        }
      }
      this.checkStructureModel = arr;
      this.getDocStyle();
    },

    //修改结构库宽高
    setStructureSize() {
      this.setStructureCanvas();
    },

    //点击结构应用
    renderCanvas() {
      if (this.pupData.type == 1) {
        if (this.pupData.StructureList.Structure.type) {
          this.isShowDialog = true;
          this.setContent = this.$t("pup.txt83");
          this.setConfirmContent = this.$t("pup.txt84");
        } else {
          this.sendRes(true);
        }
      } else {
        this.sendRes(true);
      }
    },
    //取消按钮回调
    cancelDialog() {
      this.isShowDialog = false;
    },
    //确认按钮回调
    confirmDialog() {
      this.defaultStructure.push({
        id: this.defaultStructure.length,
        arr: this.checkStructureModel,
      });
      // this.checkStructureModel = [];
      this.isShowDialog = false;
      this.sendRes(true);
    },
    //返回主页面的参数
    sendRes(type) {
      let data = {
        btnType: type,
        pupData: this.pupData,
      };
      if (this.pupData.pupType == 2) {
        data.yarnList = this.tableData.data;
        data.positionColorList = this.tableData.positionColorList;
      }
      if (this.pupData.pupType == 3) {
        data.Structure = JSON.parse(JSON.stringify(this.checkStructureModel));
        this.StructureW = 2; //结构模块宽度
        this.StructureH = 2; //结构模块高度
        this.checkStructureModel = [];
      }
      this.$emit("sendRes", data);
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./Pup.less");

.el-select-dropdown__item {
  font-size: 18px !important;
  color: #333;
  height: 47px;
  display: flex;
  align-items: center;
  padding: 0 14px;
}

.el-select-dropdown__item span {
  font-size: 18px !important;
}
</style>