<template>
  <div>
    <!-- 选择版单页面 -->
    <div class="jacquardWeaveinlet">
      <div class="leftBack">
        <img src="@/newImg/backImg.png" alt="" @click="backPage">
      </div>
      <div class="con">
        <div class="leftImg">
          <img src="@/newImg/zuojt@2x.png" alt="" class="jt" @click="switchIndex(1)">
          <img :src="require(`@/newImg/socks_${sheetList[checkSheetIndex].img}.png`)" alt="">
          <img src="@/newImg/youjt@2x.png" alt="" class="jt" @click="switchIndex(2)">
        </div>
        <div class=" rightIpnutBox">
          <div class="Boxtitle">{{ $t("machine.txt79") }}</div>
          <div class="drawPupCon">
            <div class="inputItem">
              <div class="selectTitle">
                {{
                  $t("machine.txt80")
                }}
              </div>
              <div class="input">
                <span>{{ sheetList[checkSheetIndex].w }}</span>
              </div>
            </div>
            <div class="inputItem">
              <div class="selectTitle">
                {{
                  $t("machine.txt81")
                }}
              </div>
              <div class="input">
                <span>{{ sheetList[checkSheetIndex].h }}</span>
              </div>
            </div>
          </div>
          <div class="tips">{{ $t("machine.txt82") }}</div>
          <div class="btn" @click="sheetPreviewType = true">
            <div>{{ $t("machine.txt75") }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 选择版单预览弹框 -->
    <div class="sheetPreview" v-show="sheetPreviewType">
      <div class="sheetPreviewMask"></div>
      <div class="sheetPreviewPup">
        <div class="PupHead">
          <div>{{ $t("machine.txt83") }}</div>
          <img src="@/newImg/hidden.png" alt="" @click="sheetPreviewType = false" />
        </div>
        <div class="sheetImg">
          <div class="sizeBox">
            <span></span>
            <div class="sizeNum">
              <div class="item">
                {{ $t("machine.txt24") }}：{{ sheetList[checkSheetIndex].w }}
              </div>
              <div class="item">
                {{ $t("machine.txt25") }}：{{ sheetList[checkSheetIndex].h }}
              </div>
            </div>
          </div>
          <div class="Img">
            <el-image style="width:98%;height:98%" :src="require(`@/newImg/socks${checkSheetIndex + 1}.png`)"
              fit="contain"></el-image>
          </div>
          <div class="pupFooter">
            <div class="pupFooterleft">
              <span>{{ $t("machine.txt84") }}</span>
              <div class="backcolor">
                {{ $t("machine.txt85") }}
              </div>
              <div class="colorBlock"></div>
            </div>
            <div class="rightBtn" @click="renderSheetData">
              {{ $t("pup.txt65") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sheetList: [//版单数据弹框
        { w: 200, h: 325, img: '01' },
        { w: 200, h: 411, img: '02' },
        { w: 200, h: 683, img: '03' },
        { w: 200, h: 251, img: '04' },
      ],
      checkSheetIndex: 0,//选择版单索引
      sheetPreviewType: false,//版单预览弹框显示或隐藏

    };
  },
  watch: {
    //花高花宽只能输入数字且最大数值为100
    inputItem: {
      deep: true,
      handler(newVal) {
        this.$set(
          this.inputItem[0],
          "value",
          newVal[0].value.replace(/\D/g, "")
        );
        this.$set(
          this.inputItem[1],
          "value",
          newVal[1].value.replace(/\D/g, "")
        );

        if (newVal[0].value > 1300) {
          this.$set(this.inputItem[0], "value", "1300");
        }
        if (newVal[1].value > 1300) {
          this.$set(this.inputItem[1], "value", "1300");
        }
      },
    },
    //切换复选框内容中英文
    "$store.state.index.languageType"(newVal, Val) {
      this.inputList = [
        {
          title: this.$t(`machine.txt58`),
          errTitle: this.$t(`machine.txt6`),
          value: "",
          type: true,
        },
        {
          title: this.$t(`machine.txt3`),
          errTitle: this.$t(`machine.txt7`),
          value: "",
          type: true,
        },
        {
          title: this.$t(`machine.txt62`),
          errTitle: this.$t(`machine.txt8`),
          value: "",
          type: true,
        },
      ];
      this.inputItem = [
        {
          title: this.$t(`machine.txt60`),
          errTitle: this.$t(`machine.txt64`),
          value: "",
          type: true,
        },
        {
          title: this.$t(`machine.txt61`),
          errTitle: this.$t(`machine.txt65`),
          value: "",
          type: true,
        },
      ];

      this.needleBed = [
        {
          value: "1",
          label: this.$t(`machine.txt9`),
        },
        {
          value: "2",
          label: this.$t(`machine.txt10`),
        },
      ];
      this.modelNum = [
        {
          value: "1",
          label: this.$t(`machine.txt9`),
        },
        {
          value: "2",
          label: "E16",
        },
        {
          value: "3",
          label: "E20",
        },
        {
          value: "4",
          label: "E24",
        },
        {
          value: "5",
          label: "E28",
        },
      ];
      this.options = [
        {
          value: "1",
          label: this.$t(`machine.txt9`),
        },
        {
          value: "2",
          label: this.$t(`machine.txt11`),
        },
        {
          value: "3",
          label: this.$t(`machine.txt12`),
        },
      ];
    },
  },
  mounted() {
    let canvasSrc = document.createElement("canvas");
    const ctx = canvasSrc.getContext("2d", {
      willReadFrequently: true,
    });
    let img = new Image();
    img.src = require('@/newImg/socks3.png');
    img.onload = () => {
      canvasSrc.width = img.width;
      canvasSrc.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      const imageData = ctx.getImageData(
        0,
        0,
        canvasSrc.width,
        canvasSrc.height
      );
      // 颜色部位
      // 1.袜圈:e2e2e2 rgb:226,226,226 
      // 2.袜身:BDBDBD rgb:189,189,189
      // 3.袜跟:9E9E9E rgb:158,158,158
      // 3.袜头:797979 rgb:121,121,121
      let colorarr = [
        { rgb: '189,189,189', clolor: 'BDBDBD' },
        { rgb: '226,226,226', clolor: 'e2e2e2' },
        { rgb: '121,121,121', clolor: '797979' },
        { rgb: '158,158,158', clolor: '9E9E9E' },
      ]
      let arr = []
      for (var i = 0; i < imageData.height; i++) {
        //把图层imageData转为rgb数据
        arr.push([]);
        for (
          var j = i * imageData.width * 4;
          j < imageData.width * 4 + i * imageData.width * 4;
          j += 4
        ) {
          var a = `${imageData.data[j]},${imageData.data[j + 1]},${imageData.data[j + 2]}`
          let type = true
          colorarr.forEach((item, index) => {
            if (item.rgb == a) {
              type = false
              arr[i].push({
                color: item.clolor,
                positionType: index + 1
              });
            }
          })

          if (type) {
            arr[i].push({
              color: '#ffffff',
              positionType: 0
            });
          }
        }
      }
    };
  },
  methods: {
    //切换选中版单
    switchIndex(type) {
      if (type == 1) {
        if (this.checkSheetIndex != 0) {
          this.checkSheetIndex--
        } else {
          this.checkSheetIndex = 3
        }
      } else {
        if (this.checkSheetIndex == 3) {
          this.checkSheetIndex = 0
        } else {
          this.checkSheetIndex++
        }
      }
    },
    //生成选中版单数据
    renderSheetData() {
      this.$emit('jacquardWeaveinletSend', this.checkSheetIndex)
    },

    //返回机型选择页面 
    backPage() {
      localStorage.setItem("leftNavtype", 1)
      this.$router.push({
        path: '/modelSwitching'
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./jacquardWeaveinlet.less");

.el-select-dropdown__item {
  font-size: 18px !important;
  color: #333;
  height: 47px;
  display: flex;
  align-items: center;
  padding: 0 14px;
}

.el-select-dropdown__item span {
  font-size: 18px !important;
}
</style>