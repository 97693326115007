export default {
    warm: [
        [
            {
                "nr": "PANTONE 11-4001 TPG",
                "name": "Brilliant White",
                "book": 1,
                "page": 1,
                "row": 1,
                "hexCode": "#EFF0F1",
                "rgb": "239,240,241"
            },
            {
                "nr": "PANTONE 11-0601 TPG",
                "name": "Bright White",
                "book": 1,
                "page": 1,
                "row": 2,
                "hexCode": "#F0F0ED",
                "rgb": "240,240,237"
            },
            {
                "nr": "PANTONE 11-0700 TPG",
                "name": "Lucent White",
                "book": 1,
                "page": 1,
                "row": 3,
                "hexCode": "#F3F4F5",
                "rgb": "243,244,245"
            },
            {
                "nr": "PANTONE 11-4800 TPG",
                "name": "Blanc De Blanc",
                "book": 1,
                "page": 1,
                "row": 4,
                "hexCode": "#E9E8E7",
                "rgb": "233,232,231"
            },
            {
                "nr": "PANTONE 11-4201 TPG",
                "name": "Cloud Dancer",
                "book": 1,
                "page": 1,
                "row": 5,
                "hexCode": "#F1EFEC",
                "rgb": "241,239,236"
            },
            {
                "nr": "PANTONE 11-0608 TPG",
                "name": "Coconut Milk",
                "book": 1,
                "page": 1,
                "row": 6,
                "hexCode": "#F0EDE5",
                "rgb": "239,237,229"
            },
            {
                "nr": "PANTONE 11-4801 TPG",
                "name": "Tofu",
                "book": 1,
                "page": 1,
                "row": 7,
                "hexCode": "#EAE6DA",
                "rgb": "233,229,217"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0607 TPG",
                "name": "Sugar Swizzle",
                "book": 1,
                "page": 2,
                "row": 1,
                "hexCode": "#F3EFE7",
                "rgb": "243,238,231"
            },
            {
                "nr": "PANTONE 11-1001 TPG",
                "name": "White Alyssum",
                "book": 1,
                "page": 2,
                "row": 2,
                "hexCode": "#EBE7E3",
                "rgb": "235,231,227"
            },
            {
                "nr": "PANTONE 11-0602 TPG",
                "name": "Snow White",
                "book": 1,
                "page": 2,
                "row": 3,
                "hexCode": "#EFEDE6",
                "rgb": "239,237,230"
            },
            {
                "nr": "PANTONE 11-4202 TPG",
                "name": "Star White",
                "book": 1,
                "page": 2,
                "row": 4,
                "hexCode": "#ECEBE6",
                "rgb": "236,235,230"
            },
            {
                "nr": "PANTONE 11-4300 TPG",
                "name": "Marshmallow",
                "book": 1,
                "page": 2,
                "row": 5,
                "hexCode": "#EEEDE2",
                "rgb": "238,237,226"
            },
            {
                "nr": "PANTONE 11-4302 TPG",
                "name": "Cannoli Cream",
                "book": 1,
                "page": 2,
                "row": 6,
                "hexCode": "#F2F0E3",
                "rgb": "241,240,226"
            },
            {
                "nr": "PANTONE 11-0103 TPG",
                "name": "Egret",
                "book": 1,
                "page": 2,
                "row": 7,
                "hexCode": "#EFECE1",
                "rgb": "239,236,225"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0104 TPG",
                "name": "Vanilla Ice",
                "book": 1,
                "page": 3,
                "row": 1,
                "hexCode": "#EEECDB",
                "rgb": "238,236,219"
            },
            {
                "nr": "PANTONE 11-0606 TPG",
                "name": "Pristine",
                "book": 1,
                "page": 3,
                "row": 2,
                "hexCode": "#EEE9DB",
                "rgb": "238,233,219"
            },
            {
                "nr": "PANTONE 11-0701 TPG",
                "name": "Whisper White",
                "book": 1,
                "page": 3,
                "row": 3,
                "hexCode": "#EFEBDE",
                "rgb": "239,235,222"
            },
            {
                "nr": "PANTONE 12-0601 TPG",
                "name": "Eggnog",
                "book": 1,
                "page": 3,
                "row": 4,
                "hexCode": "#EAE0D1",
                "rgb": "234,224,209"
            },
            {
                "nr": "PANTONE 11-0604 TPG",
                "name": "Gardenia",
                "book": 1,
                "page": 3,
                "row": 5,
                "hexCode": "#F0EAE0",
                "rgb": "240,234,224"
            },
            {
                "nr": "PANTONE 11-0605 TPG",
                "name": "Jet Stream",
                "book": 1,
                "page": 3,
                "row": 6,
                "hexCode": "#EAE3DB",
                "rgb": "234,227,219"
            },
            {
                "nr": "PANTONE 13-0905 TPG",
                "name": "Birch",
                "book": 1,
                "page": 3,
                "row": 7,
                "hexCode": "#DDD4C5",
                "rgb": "221,212,197"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0105 TPG",
                "name": "Antique White",
                "book": 1,
                "page": 4,
                "row": 1,
                "hexCode": "#EDE7D3",
                "rgb": "237,231,211"
            },
            {
                "nr": "PANTONE 12-0703 TPG",
                "name": "Seedpearl",
                "book": 1,
                "page": 4,
                "row": 2,
                "hexCode": "#E2DAC2",
                "rgb": "226,218,194"
            },
            {
                "nr": "PANTONE 12-0000 TPG",
                "name": "White Swan",
                "book": 1,
                "page": 4,
                "row": 3,
                "hexCode": "#E2D7C5",
                "rgb": "226,215,197"
            },
            {
                "nr": "PANTONE 12-0304 TPG",
                "name": "Whitecap Gray",
                "book": 1,
                "page": 4,
                "row": 4,
                "hexCode": "#E0D7C6",
                "rgb": "224,215,198"
            },
            {
                "nr": "PANTONE 13-0908 TPG",
                "name": "Parchment",
                "book": 1,
                "page": 4,
                "row": 5,
                "hexCode": "#DDD1BB",
                "rgb": "221,209,187"
            },
            {
                "nr": "PANTONE 13-1006 TPG",
                "name": "Crème Br?lée",
                "book": 1,
                "page": 4,
                "row": 6,
                "hexCode": "#D9CBB1",
                "rgb": "217,203,177"
            },
            {
                "nr": "PANTONE 12-0709 TPG",
                "name": "Macadamia",
                "book": 1,
                "page": 4,
                "row": 7,
                "hexCode": "#E4CFB6",
                "rgb": "228,207,182"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0110 TPG",
                "name": "Buttercream",
                "book": 1,
                "page": 5,
                "row": 1,
                "hexCode": "#F0E3CE",
                "rgb": "240,227,206"
            },
            {
                "nr": "PANTONE 11-0907 TPG",
                "name": "Pearled Ivory",
                "book": 1,
                "page": 5,
                "row": 2,
                "hexCode": "#EEE1CE",
                "rgb": "238,225,206"
            },
            {
                "nr": "PANTONE 12-0704 TPG",
                "name": "White Smoke",
                "book": 1,
                "page": 5,
                "row": 3,
                "hexCode": "#ECDDC8",
                "rgb": "236,221,200"
            },
            {
                "nr": "PANTONE 11-0809 TPG",
                "name": "Ecru",
                "book": 1,
                "page": 5,
                "row": 4,
                "hexCode": "#F1E1CC",
                "rgb": "241,225,204"
            },
            {
                "nr": "PANTONE 12-0710 TPG",
                "name": "Shortbread",
                "book": 1,
                "page": 5,
                "row": 5,
                "hexCode": "#EFDEC3",
                "rgb": "239,222,195"
            },
            {
                "nr": "PANTONE 12-0605 TPG",
                "name": "Angora",
                "book": 1,
                "page": 5,
                "row": 6,
                "hexCode": "#DED3BB",
                "rgb": "222,211,187"
            },
            {
                "nr": "PANTONE 13-1008 TPG",
                "name": "Bleached Sand",
                "book": 1,
                "page": 5,
                "row": 7,
                "hexCode": "#DECDB5",
                "rgb": "222,205,181"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1104 TPG",
                "name": "Sheer Bliss",
                "book": 1,
                "page": 6,
                "row": 1,
                "hexCode": "#DFCDBC",
                "rgb": "223,205,188"
            },
            {
                "nr": "PANTONE 12-1404 TPG",
                "name": "Pink Tint",
                "book": 1,
                "page": 6,
                "row": 2,
                "hexCode": "#DACABC",
                "rgb": "218,202,188"
            },
            {
                "nr": "PANTONE 13-1107 TPG",
                "name": "Whisper Pink",
                "book": 1,
                "page": 6,
                "row": 3,
                "hexCode": "#DDCCBE",
                "rgb": "221,204,190"
            },
            {
                "nr": "PANTONE 13-1106 TPG",
                "name": "Sand Dollar",
                "book": 1,
                "page": 6,
                "row": 4,
                "hexCode": "#DECEBB",
                "rgb": "222,206,187"
            },
            {
                "nr": "PANTONE 13-1308 TPG",
                "name": "Brazilian Sand",
                "book": 1,
                "page": 6,
                "row": 5,
                "hexCode": "#DDCCB7",
                "rgb": "220,203,182"
            },
            {
                "nr": "PANTONE 12-1403 TPG",
                "name": "Tapioca",
                "book": 1,
                "page": 6,
                "row": 6,
                "hexCode": "#DDCDB9",
                "rgb": "221,205,185"
            },
            {
                "nr": "PANTONE 13-0907 TPG",
                "name": "Sandshell",
                "book": 1,
                "page": 6,
                "row": 7,
                "hexCode": "#DACEBA",
                "rgb": "218,206,186"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0404 TPG",
                "name": "Ancient Scroll",
                "book": 1,
                "page": 7,
                "row": 1,
                "hexCode": "#D1C7B9",
                "rgb": "209,199,185"
            },
            {
                "nr": "PANTONE 13-0401 TPG",
                "name": "Oatmeal",
                "book": 1,
                "page": 7,
                "row": 2,
                "hexCode": "#CBC4B3",
                "rgb": "203,196,179"
            },
            {
                "nr": "PANTONE 13-0003 TPG",
                "name": "Perfectly Pale",
                "book": 1,
                "page": 7,
                "row": 3,
                "hexCode": "#D6CCC1",
                "rgb": "214,204,193"
            },
            {
                "nr": "PANTONE 13-0400 TPG",
                "name": "French Oak",
                "book": 1,
                "page": 7,
                "row": 4,
                "hexCode": "#CCC1B3",
                "rgb": "204,193,179"
            },
            {
                "nr": "PANTONE 14-0002 TPG",
                "name": "Pumice Stone",
                "book": 1,
                "page": 7,
                "row": 5,
                "hexCode": "#C4BDB3",
                "rgb": "196,189,179"
            },
            {
                "nr": "PANTONE 13-5304 TPG",
                "name": "Rainy Day",
                "book": 1,
                "page": 7,
                "row": 6,
                "hexCode": "#CCC4B8",
                "rgb": "204,196,184"
            },
            {
                "nr": "PANTONE 13-0000 TPG",
                "name": "Moonbeam",
                "book": 1,
                "page": 7,
                "row": 7,
                "hexCode": "#D6CFC6",
                "rgb": "214,207,198"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0105 TPG",
                "name": "Bone White",
                "book": 1,
                "page": 8,
                "row": 1,
                "hexCode": "#D4D1BF",
                "rgb": "212,209,191"
            },
            {
                "nr": "PANTONE 13-0607 TPG",
                "name": "Fog",
                "book": 1,
                "page": 8,
                "row": 2,
                "hexCode": "#D4C9B6",
                "rgb": "212,201,182"
            },
            {
                "nr": "PANTONE 14-1107 TPG",
                "name": "Oyster Gray",
                "book": 1,
                "page": 8,
                "row": 3,
                "hexCode": "#C9C1AE",
                "rgb": "201,193,174"
            },
            {
                "nr": "PANTONE 14-1106 TPG",
                "name": "Peyote",
                "book": 1,
                "page": 8,
                "row": 4,
                "hexCode": "#C3BCAD",
                "rgb": "195,188,173"
            },
            {
                "nr": "PANTONE 15-1305 TPG",
                "name": "Feather Gray",
                "book": 1,
                "page": 8,
                "row": 5,
                "hexCode": "#B8AFA0",
                "rgb": "184,175,160"
            },
            {
                "nr": "PANTONE 15-1306 TPG",
                "name": "Oxford Tan",
                "book": 1,
                "page": 8,
                "row": 6,
                "hexCode": "#B4A995",
                "rgb": "180,169,149"
            },
            {
                "nr": "PANTONE 15-1304 TPG",
                "name": "Humus",
                "book": 1,
                "page": 8,
                "row": 7,
                "hexCode": "#B6A896",
                "rgb": "182,168,150"
            }
        ],
        [
            {
                "nr": "PANTONE 12-4302 TPG",
                "name": "Vaporous Gray",
                "book": 1,
                "page": 9,
                "row": 1,
                "hexCode": "#E1E1DB",
                "rgb": "225,225,219"
            },
            {
                "nr": "PANTONE 12-4300 TPG",
                "name": "White Onyx",
                "book": 1,
                "page": 9,
                "row": 2,
                "hexCode": "#D9D8D1",
                "rgb": "217,216,209"
            },
            {
                "nr": "PANTONE 13-4403 TPG",
                "name": "Silver Birch",
                "book": 1,
                "page": 9,
                "row": 3,
                "hexCode": "#CECDC1",
                "rgb": "206,204,193"
            },
            {
                "nr": "PANTONE 12-4301 TPG",
                "name": "Almond Milk",
                "book": 1,
                "page": 9,
                "row": 4,
                "hexCode": "#D9D0BE",
                "rgb": "216,208,189"
            },
            {
                "nr": "PANTONE 14-0105 TPG",
                "name": "Overcast",
                "book": 1,
                "page": 9,
                "row": 5,
                "hexCode": "#C3BFA9",
                "rgb": "195,191,169"
            },
            {
                "nr": "PANTONE 14-6305 TPG",
                "name": "Pelican",
                "book": 1,
                "page": 9,
                "row": 6,
                "hexCode": "#BDBAA8",
                "rgb": "189,186,168"
            },
            {
                "nr": "PANTONE 16-1105 TPG",
                "name": "Plaza Taupe",
                "book": 1,
                "page": 9,
                "row": 7,
                "hexCode": "#A59D8C",
                "rgb": "165,157,140"
            }
        ],
        [
            {
                "nr": "PANTONE 12-5201 TPG",
                "name": "Icicle",
                "book": 1,
                "page": 10,
                "row": 1,
                "hexCode": "#DFE0D3",
                "rgb": "223,224,211"
            },
            {
                "nr": "PANTONE 12-0404 TPG",
                "name": "Light Gray",
                "book": 1,
                "page": 10,
                "row": 2,
                "hexCode": "#D9D6CA",
                "rgb": "217,214,202"
            },
            {
                "nr": "PANTONE 12-6204 TPG",
                "name": "Silver Green",
                "book": 1,
                "page": 10,
                "row": 3,
                "hexCode": "#D6D4C5",
                "rgb": "214,212,197"
            },
            {
                "nr": "PANTONE 14-6308 TPG",
                "name": "Alfalfa",
                "book": 1,
                "page": 10,
                "row": 4,
                "hexCode": "#B8B8A4",
                "rgb": "184,184,164"
            },
            {
                "nr": "PANTONE 15-6410 TPG",
                "name": "Moss Gray",
                "book": 1,
                "page": 10,
                "row": 5,
                "hexCode": "#AAA994",
                "rgb": "169,169,147"
            },
            {
                "nr": "PANTONE 15-6307 TPG",
                "name": "Agate Gray",
                "book": 1,
                "page": 10,
                "row": 6,
                "hexCode": "#B3B3A4",
                "rgb": "179,179,164"
            },
            {
                "nr": "PANTONE 14-6408 TPG",
                "name": "Abbey Stone",
                "book": 1,
                "page": 10,
                "row": 7,
                "hexCode": "#A5A696",
                "rgb": "165,165,150"
            }
        ],
        [
            {
                "nr": "PANTONE 12-5202 TPG",
                "name": "Turtledove",
                "book": 1,
                "page": 11,
                "row": 1,
                "hexCode": "#DED7C6",
                "rgb": "222,215,198"
            },
            {
                "nr": "PANTONE 12-0104 TPG",
                "name": "White Asparagus",
                "book": 1,
                "page": 11,
                "row": 2,
                "hexCode": "#E3DEC9",
                "rgb": "227,222,201"
            },
            {
                "nr": "PANTONE 12-0110 TPG",
                "name": "Pistachio Shell",
                "book": 1,
                "page": 11,
                "row": 3,
                "hexCode": "#DAD1BB",
                "rgb": "218,208,187"
            },
            {
                "nr": "PANTONE 14-0108 TPG",
                "name": "Castle Wall",
                "book": 1,
                "page": 11,
                "row": 4,
                "hexCode": "#C8C3AC",
                "rgb": "200,195,172"
            },
            {
                "nr": "PANTONE 14-0210 TPG",
                "name": "Tidal Foam",
                "book": 1,
                "page": 11,
                "row": 5,
                "hexCode": "#BBB4A0",
                "rgb": "187,180,160"
            },
            {
                "nr": "PANTONE 15-0513 TPG",
                "name": "Eucalyptus",
                "book": 1,
                "page": 11,
                "row": 6,
                "hexCode": "#ADA78F",
                "rgb": "173,167,143"
            },
            {
                "nr": "PANTONE 15-0309 TPG",
                "name": "Spray Green",
                "book": 1,
                "page": 11,
                "row": 7,
                "hexCode": "#ADA693",
                "rgb": "173,166,147"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0311 TPG",
                "name": "Asparagus Green",
                "book": 1,
                "page": 12,
                "row": 1,
                "hexCode": "#D6D4B8",
                "rgb": "214,212,184"
            },
            {
                "nr": "PANTONE 13-1007 TPG",
                "name": "Oyster White",
                "book": 1,
                "page": 12,
                "row": 2,
                "hexCode": "#D6CDB1",
                "rgb": "214,205,177"
            },
            {
                "nr": "PANTONE 13-0611 TPG",
                "name": "Moth",
                "book": 1,
                "page": 12,
                "row": 3,
                "hexCode": "#D2CEAF",
                "rgb": "210,206,175"
            },
            {
                "nr": "PANTONE 14-0615 TPG",
                "name": "Green Haze",
                "book": 1,
                "page": 12,
                "row": 4,
                "hexCode": "#CCC8A5",
                "rgb": "204,200,165"
            },
            {
                "nr": "PANTONE 15-1115 TPG",
                "name": "Fields Of Rye",
                "book": 1,
                "page": 12,
                "row": 5,
                "hexCode": "#B9AC94",
                "rgb": "185,172,148"
            },
            {
                "nr": "PANTONE 17-1107 TPG",
                "name": "Seneca Rock",
                "book": 1,
                "page": 12,
                "row": 6,
                "hexCode": "#948E7B",
                "rgb": "148,142,123"
            },
            {
                "nr": "PANTONE 17-0510 TPG",
                "name": "Silver Sage",
                "book": 1,
                "page": 12,
                "row": 7,
                "hexCode": "#979280",
                "rgb": "150,146,128"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0513 TPG",
                "name": "Frozen Dew",
                "book": 1,
                "page": 13,
                "row": 1,
                "hexCode": "#DBD3B8",
                "rgb": "219,211,184"
            },
            {
                "nr": "PANTONE 13-0711 TPG",
                "name": "Putty",
                "book": 1,
                "page": 13,
                "row": 2,
                "hexCode": "#D3CCB0",
                "rgb": "211,204,176"
            },
            {
                "nr": "PANTONE 13-1105 TPG",
                "name": "Brown Rice",
                "book": 1,
                "page": 13,
                "row": 3,
                "hexCode": "#C8BCA4",
                "rgb": "200,187,163"
            },
            {
                "nr": "PANTONE 16-1119 TPG",
                "name": "Lemon Pepper",
                "book": 1,
                "page": 13,
                "row": 4,
                "hexCode": "#A29780",
                "rgb": "162,151,128"
            },
            {
                "nr": "PANTONE 17-1108 TPG",
                "name": "Winter Twig",
                "book": 1,
                "page": 13,
                "row": 5,
                "hexCode": "#998F7F",
                "rgb": "152,142,126"
            },
            {
                "nr": "PANTONE 17-1113 TPG",
                "name": "Coriander",
                "book": 1,
                "page": 13,
                "row": 6,
                "hexCode": "#8D836D",
                "rgb": "141,131,109"
            },
            {
                "nr": "PANTONE 17-1118 TPG",
                "name": "Lead Gray",
                "book": 1,
                "page": 13,
                "row": 7,
                "hexCode": "#857760",
                "rgb": "133,119,96"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1116 TPG",
                "name": "Safari",
                "book": 1,
                "page": 14,
                "row": 1,
                "hexCode": "#B6A88E",
                "rgb": "182,168,142"
            },
            {
                "nr": "PANTONE 16-1010 TPG",
                "name": "Incense",
                "book": 1,
                "page": 14,
                "row": 2,
                "hexCode": "#AB987B",
                "rgb": "171,152,123"
            },
            {
                "nr": "PANTONE 15-1114 TPG",
                "name": "Travertine",
                "book": 1,
                "page": 14,
                "row": 3,
                "hexCode": "#AF9C82",
                "rgb": "175,156,130"
            },
            {
                "nr": "PANTONE 16-1315 TPG",
                "name": "Cornstalk",
                "book": 1,
                "page": 14,
                "row": 4,
                "hexCode": "#A59577",
                "rgb": "165,149,119"
            },
            {
                "nr": "PANTONE 17-1022 TPG",
                "name": "Kelp",
                "book": 1,
                "page": 14,
                "row": 5,
                "hexCode": "#988B6D",
                "rgb": "152,139,109"
            },
            {
                "nr": "PANTONE 17-1115 TPG",
                "name": "Petrified Oak",
                "book": 1,
                "page": 14,
                "row": 6,
                "hexCode": "#8F7C64",
                "rgb": "143,124,100"
            },
            {
                "nr": "PANTONE 17-1314 TPG",
                "name": "Sepia Tint",
                "book": 1,
                "page": 14,
                "row": 7,
                "hexCode": "#8E7A66",
                "rgb": "141,122,102"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1108 TPG",
                "name": "Wood Ash",
                "book": 1,
                "page": 15,
                "row": 1,
                "hexCode": "#D3C7AA",
                "rgb": "211,199,170"
            },
            {
                "nr": "PANTONE 15-1218 TPG",
                "name": "Semolina",
                "book": 1,
                "page": 15,
                "row": 2,
                "hexCode": "#D1BB9D",
                "rgb": "209,187,157"
            },
            {
                "nr": "PANTONE 14-1112 TPG",
                "name": "Pebble",
                "book": 1,
                "page": 15,
                "row": 3,
                "hexCode": "#CBBB99",
                "rgb": "203,187,153"
            },
            {
                "nr": "PANTONE 14-1208 TPG",
                "name": "Irish Cream",
                "book": 1,
                "page": 15,
                "row": 4,
                "hexCode": "#C2AD92",
                "rgb": "194,172,146"
            },
            {
                "nr": "PANTONE 16-1213 TPG",
                "name": "Savannah Tan",
                "book": 1,
                "page": 15,
                "row": 5,
                "hexCode": "#A89583",
                "rgb": "168,149,131"
            },
            {
                "nr": "PANTONE 17-1112 TPG",
                "name": "Weathered Teak",
                "book": 1,
                "page": 15,
                "row": 6,
                "hexCode": "#9F9283",
                "rgb": "159,146,131"
            },
            {
                "nr": "PANTONE 17-1312 TPG",
                "name": "Silver Mink",
                "book": 1,
                "page": 15,
                "row": 7,
                "hexCode": "#988B78",
                "rgb": "152,139,120"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1014 TPG",
                "name": "Gravel",
                "book": 1,
                "page": 16,
                "row": 1,
                "hexCode": "#C8C09F",
                "rgb": "200,192,159"
            },
            {
                "nr": "PANTONE 15-1217 TPG",
                "name": "Mojave Desert",
                "book": 1,
                "page": 16,
                "row": 2,
                "hexCode": "#C6B694",
                "rgb": "198,182,148"
            },
            {
                "nr": "PANTONE 15-1216 TPG",
                "name": "Pale Khaki",
                "book": 1,
                "page": 16,
                "row": 3,
                "hexCode": "#BEB290",
                "rgb": "190,178,144"
            },
            {
                "nr": "PANTONE 15-1307 TPG",
                "name": "White Pepper",
                "book": 1,
                "page": 16,
                "row": 4,
                "hexCode": "#BAA994",
                "rgb": "186,169,148"
            },
            {
                "nr": "PANTONE 16-1101 TPG",
                "name": "Trench Coat",
                "book": 1,
                "page": 16,
                "row": 5,
                "hexCode": "#AC9F8B",
                "rgb": "172,159,139"
            },
            {
                "nr": "PANTONE 16-1104 TPG",
                "name": "Crockery",
                "book": 1,
                "page": 16,
                "row": 6,
                "hexCode": "#A29888",
                "rgb": "162,152,136"
            },
            {
                "nr": "PANTONE 17-1109 TPG",
                "name": "Chinchilla",
                "book": 1,
                "page": 16,
                "row": 7,
                "hexCode": "#9B917B",
                "rgb": "155,145,123"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1110 TPG",
                "name": "Boulder",
                "book": 1,
                "page": 17,
                "row": 1,
                "hexCode": "#D0BE9A",
                "rgb": "208,190,154"
            },
            {
                "nr": "PANTONE 14-1116 TPG",
                "name": "Almond Buff",
                "book": 1,
                "page": 17,
                "row": 2,
                "hexCode": "#D0B894",
                "rgb": "208,184,148"
            },
            {
                "nr": "PANTONE 15-1119 TPG",
                "name": "Taos Taupe",
                "book": 1,
                "page": 17,
                "row": 3,
                "hexCode": "#BEAA82",
                "rgb": "190,170,130"
            },
            {
                "nr": "PANTONE 16-0924 TPG",
                "name": "Croissant",
                "book": 1,
                "page": 17,
                "row": 4,
                "hexCode": "#BFA581",
                "rgb": "191,165,129"
            },
            {
                "nr": "PANTONE 16-0920 TPG",
                "name": "Curds & Whey",
                "book": 1,
                "page": 17,
                "row": 5,
                "hexCode": "#BEA688",
                "rgb": "190,166,136"
            },
            {
                "nr": "PANTONE 16-1120 TPG",
                "name": "Starfish",
                "book": 1,
                "page": 17,
                "row": 6,
                "hexCode": "#B39C7A",
                "rgb": "179,156,122"
            },
            {
                "nr": "PANTONE 16-1324 TPG",
                "name": "Lark",
                "book": 1,
                "page": 17,
                "row": 7,
                "hexCode": "#B49B72",
                "rgb": "180,155,114"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1009 TPG",
                "name": "Biscotti",
                "book": 1,
                "page": 18,
                "row": 1,
                "hexCode": "#DBCAA9",
                "rgb": "219,202,169"
            },
            {
                "nr": "PANTONE 13-1016 TPG",
                "name": "Wheat",
                "book": 1,
                "page": 18,
                "row": 2,
                "hexCode": "#E1C5A4",
                "rgb": "225,197,164"
            },
            {
                "nr": "PANTONE 13-1011 TPG",
                "name": "Ivory Cream",
                "book": 1,
                "page": 18,
                "row": 3,
                "hexCode": "#DEC5AB",
                "rgb": "222,197,171"
            },
            {
                "nr": "PANTONE 14-1118 TPG",
                "name": "Beige",
                "book": 1,
                "page": 18,
                "row": 4,
                "hexCode": "#D4B896",
                "rgb": "212,184,150"
            },
            {
                "nr": "PANTONE 15-1214 TPG",
                "name": "Warm Sand",
                "book": 1,
                "page": 18,
                "row": 5,
                "hexCode": "#C0AB8E",
                "rgb": "192,171,142"
            },
            {
                "nr": "PANTONE 15-1020 TPG",
                "name": "Ginger Root",
                "book": 1,
                "page": 18,
                "row": 6,
                "hexCode": "#C1A68B",
                "rgb": "193,165,139"
            },
            {
                "nr": "PANTONE 17-1320 TPG",
                "name": "Tannin",
                "book": 1,
                "page": 18,
                "row": 7,
                "hexCode": "#A68B6F",
                "rgb": "166,139,111"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0811 TPG",
                "name": "Dawn",
                "book": 1,
                "page": 19,
                "row": 1,
                "hexCode": "#F1D7B9",
                "rgb": "241,215,185"
            },
            {
                "nr": "PANTONE 13-1010 TPG",
                "name": "Gray Sand",
                "book": 1,
                "page": 19,
                "row": 2,
                "hexCode": "#EBD4B5",
                "rgb": "235,212,181"
            },
            {
                "nr": "PANTONE 14-1120 TPG",
                "name": "Apricot Illusion",
                "book": 1,
                "page": 19,
                "row": 3,
                "hexCode": "#E8C6A3",
                "rgb": "232,198,163"
            },
            {
                "nr": "PANTONE 13-1013 TPG",
                "name": "Appleblossom",
                "book": 1,
                "page": 19,
                "row": 4,
                "hexCode": "#E3C2A4",
                "rgb": "227,194,164"
            },
            {
                "nr": "PANTONE 14-1119 TPG",
                "name": "Winter Wheat",
                "book": 1,
                "page": 19,
                "row": 5,
                "hexCode": "#E3C39D",
                "rgb": "227,195,157"
            },
            {
                "nr": "PANTONE 13-1015 TPG",
                "name": "Honey Peach",
                "book": 1,
                "page": 19,
                "row": 6,
                "hexCode": "#DDC09C",
                "rgb": "221,192,156"
            },
            {
                "nr": "PANTONE 13-1014 TPG",
                "name": "Mellow Buff",
                "book": 1,
                "page": 19,
                "row": 7,
                "hexCode": "#DFC29F",
                "rgb": "223,194,159"
            }
        ],
        [
            {
                "nr": "PANTONE 12-1009 TPG",
                "name": "Vanilla Cream",
                "book": 1,
                "page": 20,
                "row": 1,
                "hexCode": "#F8DCC9",
                "rgb": "248,220,201"
            },
            {
                "nr": "PANTONE 12-1006 TPG",
                "name": "Mother Of Pearl",
                "book": 1,
                "page": 20,
                "row": 2,
                "hexCode": "#EBD9C5",
                "rgb": "235,217,197"
            },
            {
                "nr": "PANTONE 12-1008 TPG",
                "name": "Linen",
                "book": 1,
                "page": 20,
                "row": 3,
                "hexCode": "#EFD5C0",
                "rgb": "239,213,192"
            },
            {
                "nr": "PANTONE 12-0912 TPG",
                "name": "Tender Peach",
                "book": 1,
                "page": 20,
                "row": 4,
                "hexCode": "#F7D3B4",
                "rgb": "247,211,180"
            },
            {
                "nr": "PANTONE 12-0911 TPG",
                "name": "Nude",
                "book": 1,
                "page": 20,
                "row": 5,
                "hexCode": "#F2D3B7",
                "rgb": "242,211,183"
            },
            {
                "nr": "PANTONE 12-1011 TPG",
                "name": "Peach Purée",
                "book": 1,
                "page": 20,
                "row": 6,
                "hexCode": "#F3D0B6",
                "rgb": "243,208,182"
            },
            {
                "nr": "PANTONE 12-0913 TPG",
                "name": "Alesan",
                "book": 1,
                "page": 20,
                "row": 7,
                "hexCode": "#F3CEB2",
                "rgb": "243,206,178"
            }
        ],
        [
            {
                "nr": "PANTONE 12-1007 TPG",
                "name": "Pastel Rose Tan",
                "book": 1,
                "page": 21,
                "row": 1,
                "hexCode": "#E7D0BB",
                "rgb": "231,208,187"
            },
            {
                "nr": "PANTONE 12-0807 TPG",
                "name": "Sun Kiss",
                "book": 1,
                "page": 21,
                "row": 2,
                "hexCode": "#ECD2BC",
                "rgb": "236,210,188"
            },
            {
                "nr": "PANTONE 12-1005 TPG",
                "name": "Novelle Peach",
                "book": 1,
                "page": 21,
                "row": 3,
                "hexCode": "#E7D2BB",
                "rgb": "231,210,187"
            },
            {
                "nr": "PANTONE 14-1210 TPG",
                "name": "Shifting Sand",
                "book": 1,
                "page": 21,
                "row": 4,
                "hexCode": "#D8C3AC",
                "rgb": "216,195,172"
            },
            {
                "nr": "PANTONE 14-1217 TPG",
                "name": "Amberlight",
                "book": 1,
                "page": 21,
                "row": 5,
                "hexCode": "#DDB89C",
                "rgb": "221,184,156"
            },
            {
                "nr": "PANTONE 14-1213 TPG",
                "name": "Toasted Almond",
                "book": 1,
                "page": 21,
                "row": 6,
                "hexCode": "#D0B098",
                "rgb": "208,176,152"
            },
            {
                "nr": "PANTONE 14-1315 TPG",
                "name": "Hazelnut",
                "book": 1,
                "page": 21,
                "row": 7,
                "hexCode": "#D2B296",
                "rgb": "210,178,149"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1209 TPG",
                "name": "Smoke Gray",
                "book": 1,
                "page": 22,
                "row": 1,
                "hexCode": "#CAB6A1",
                "rgb": "202,182,161"
            },
            {
                "nr": "PANTONE 14-0708 TPG",
                "name": "Cement",
                "book": 1,
                "page": 22,
                "row": 2,
                "hexCode": "#C0B8A4",
                "rgb": "192,184,164"
            },
            {
                "nr": "PANTONE 14-1212 TPG",
                "name": "Frappé",
                "book": 1,
                "page": 22,
                "row": 3,
                "hexCode": "#D2B7A1",
                "rgb": "210,183,161"
            },
            {
                "nr": "PANTONE 15-1215 TPG",
                "name": "Sesame",
                "book": 1,
                "page": 22,
                "row": 4,
                "hexCode": "#B9A58D",
                "rgb": "185,165,141"
            },
            {
                "nr": "PANTONE 16-1212 TPG",
                "name": "Nomad",
                "book": 1,
                "page": 22,
                "row": 5,
                "hexCode": "#B1A089",
                "rgb": "177,160,137"
            },
            {
                "nr": "PANTONE 15-1314 TPG",
                "name": "Cuban Sand",
                "book": 1,
                "page": 22,
                "row": 6,
                "hexCode": "#C3A98F",
                "rgb": "195,169,143"
            },
            {
                "nr": "PANTONE 15-1213 TPG",
                "name": "Candied Ginger",
                "book": 1,
                "page": 22,
                "row": 7,
                "hexCode": "#BBA787",
                "rgb": "187,167,135"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1309 TPG",
                "name": "Moonlight",
                "book": 1,
                "page": 23,
                "row": 1,
                "hexCode": "#C1AE9D",
                "rgb": "193,174,157"
            },
            {
                "nr": "PANTONE 16-1210 TPG",
                "name": "Light Taupe",
                "book": 1,
                "page": 23,
                "row": 2,
                "hexCode": "#AD9D89",
                "rgb": "173,157,137"
            },
            {
                "nr": "PANTONE 15-1308 TPG",
                "name": "Doeskin",
                "book": 1,
                "page": 23,
                "row": 3,
                "hexCode": "#BDAD9A",
                "rgb": "189,173,154"
            },
            {
                "nr": "PANTONE 16-1310 TPG",
                "name": "Natural",
                "book": 1,
                "page": 23,
                "row": 4,
                "hexCode": "#AD947E",
                "rgb": "173,148,126"
            },
            {
                "nr": "PANTONE 16-1318 TPG",
                "name": "Warm Taupe",
                "book": 1,
                "page": 23,
                "row": 5,
                "hexCode": "#B09886",
                "rgb": "176,152,134"
            },
            {
                "nr": "PANTONE 17-1319 TPG",
                "name": "Amphora",
                "book": 1,
                "page": 23,
                "row": 6,
                "hexCode": "#9B8470",
                "rgb": "155,132,112"
            },
            {
                "nr": "PANTONE 17-1316 TPG",
                "name": "Portabella",
                "book": 1,
                "page": 23,
                "row": 7,
                "hexCode": "#998476",
                "rgb": "153,132,118"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1412 TPG",
                "name": "Stucco",
                "book": 1,
                "page": 24,
                "row": 1,
                "hexCode": "#A28D80",
                "rgb": "162,141,128"
            },
            {
                "nr": "PANTONE 16-1415 TPG",
                "name": "Almondine",
                "book": 1,
                "page": 24,
                "row": 2,
                "hexCode": "#A78F7E",
                "rgb": "167,143,126"
            },
            {
                "nr": "PANTONE 16-1414 TPG",
                "name": "Chanterelle",
                "book": 1,
                "page": 24,
                "row": 3,
                "hexCode": "#A38B79",
                "rgb": "163,139,121"
            },
            {
                "nr": "PANTONE 17-1418 TPG",
                "name": "Ginger Snap",
                "book": 1,
                "page": 24,
                "row": 4,
                "hexCode": "#958072",
                "rgb": "149,128,114"
            },
            {
                "nr": "PANTONE 17-1321 TPG",
                "name": "Woodsmoke",
                "book": 1,
                "page": 24,
                "row": 5,
                "hexCode": "#917967",
                "rgb": "145,121,103"
            },
            {
                "nr": "PANTONE 17-1417 TPG",
                "name": "Beaver Fur",
                "book": 1,
                "page": 24,
                "row": 6,
                "hexCode": "#947767",
                "rgb": "148,119,103"
            },
            {
                "nr": "PANTONE 18-1321 TPG",
                "name": "Brownie",
                "book": 1,
                "page": 24,
                "row": 7,
                "hexCode": "#8E7466",
                "rgb": "142,116,102"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0403 TPG",
                "name": "Gray Morn",
                "book": 1,
                "page": 25,
                "row": 1,
                "hexCode": "#C6BFB3",
                "rgb": "198,191,179"
            },
            {
                "nr": "PANTONE 14-1305 TPG",
                "name": "Mushroom",
                "book": 1,
                "page": 25,
                "row": 2,
                "hexCode": "#BBA99F",
                "rgb": "186,169,159"
            },
            {
                "nr": "PANTONE 16-0806 TPG",
                "name": "Goat",
                "book": 1,
                "page": 25,
                "row": 3,
                "hexCode": "#A39C8F",
                "rgb": "163,156,143"
            },
            {
                "nr": "PANTONE 15-1506 TPG",
                "name": "Etherea",
                "book": 1,
                "page": 25,
                "row": 4,
                "hexCode": "#A89791",
                "rgb": "168,150,145"
            },
            {
                "nr": "PANTONE 17-0807 TPG",
                "name": "Mountain Trail",
                "book": 1,
                "page": 25,
                "row": 5,
                "hexCode": "#8D7C70",
                "rgb": "141,124,112"
            },
            {
                "nr": "PANTONE 17-1412 TPG",
                "name": "Leafless Tree",
                "book": 1,
                "page": 25,
                "row": 6,
                "hexCode": "#87766E",
                "rgb": "135,118,110"
            },
            {
                "nr": "PANTONE 18-1312 TPG",
                "name": "Deep Taupe",
                "book": 1,
                "page": 25,
                "row": 7,
                "hexCode": "#72605B",
                "rgb": "114,96,91"
            }
        ],
        [
            {
                "nr": "PANTONE 12-1406 TPG",
                "name": "Almond Peach",
                "book": 1,
                "page": 26,
                "row": 1,
                "hexCode": "#DBCABF",
                "rgb": "219,202,191"
            },
            {
                "nr": "PANTONE 13-3801 TPG",
                "name": "Crystal Gray",
                "book": 1,
                "page": 26,
                "row": 2,
                "hexCode": "#DACDC5",
                "rgb": "218,205,196"
            },
            {
                "nr": "PANTONE 16-1211 TPG",
                "name": "Mocha Meringue",
                "book": 1,
                "page": 26,
                "row": 3,
                "hexCode": "#9F8E84",
                "rgb": "159,142,132"
            },
            {
                "nr": "PANTONE 17-0808 TPG",
                "name": "Taupe Gray",
                "book": 1,
                "page": 26,
                "row": 4,
                "hexCode": "#887A6D",
                "rgb": "136,122,109"
            },
            {
                "nr": "PANTONE 17-1506 TPG",
                "name": "Cinder",
                "book": 1,
                "page": 26,
                "row": 5,
                "hexCode": "#887D7B",
                "rgb": "136,125,123"
            },
            {
                "nr": "PANTONE 17-1212 TPG",
                "name": "Fungi",
                "book": 1,
                "page": 26,
                "row": 6,
                "hexCode": "#8C8177",
                "rgb": "140,129,119"
            },
            {
                "nr": "PANTONE 17-1409 TPG",
                "name": "Brown Lentil",
                "book": 1,
                "page": 26,
                "row": 7,
                "hexCode": "#816F65",
                "rgb": "129,111,101"
            }
        ],
        [
            {
                "nr": "PANTONE 15-4504 TPG",
                "name": "Nacreous Clouds",
                "book": 1,
                "page": 27,
                "row": 1,
                "hexCode": "#BBB1AB",
                "rgb": "187,177,171"
            },
            {
                "nr": "PANTONE 16-1406 TPG",
                "name": "Atmosphere",
                "book": 1,
                "page": 27,
                "row": 2,
                "hexCode": "#A1978F",
                "rgb": "161,151,143"
            },
            {
                "nr": "PANTONE 16-3800 TPG",
                "name": "Satellite",
                "book": 1,
                "page": 27,
                "row": 3,
                "hexCode": "#988F8C",
                "rgb": "152,143,140"
            },
            {
                "nr": "PANTONE 17-1210 TPG",
                "name": "Moon Rock",
                "book": 1,
                "page": 27,
                "row": 4,
                "hexCode": "#968E84",
                "rgb": "150,142,132"
            },
            {
                "nr": "PANTONE 18-1210 TPG",
                "name": "Driftwood",
                "book": 1,
                "page": 27,
                "row": 5,
                "hexCode": "#877E7A",
                "rgb": "135,126,122"
            },
            {
                "nr": "PANTONE 17-1410 TPG",
                "name": "Pine Bark",
                "book": 1,
                "page": 27,
                "row": 6,
                "hexCode": "#857569",
                "rgb": "133,117,105"
            },
            {
                "nr": "PANTONE 18-1306 TPG",
                "name": "Iron",
                "book": 1,
                "page": 27,
                "row": 7,
                "hexCode": "#726663",
                "rgb": "114,102,99"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1102 TPG",
                "name": "Island Fossil",
                "book": 1,
                "page": 28,
                "row": 1,
                "hexCode": "#B3AB9E",
                "rgb": "179,171,158"
            },
            {
                "nr": "PANTONE 16-1106 TPG",
                "name": "Tuffet",
                "book": 1,
                "page": 28,
                "row": 2,
                "hexCode": "#A09885",
                "rgb": "160,152,133"
            },
            {
                "nr": "PANTONE 17-1009 TPG",
                "name": "Dune",
                "book": 1,
                "page": 28,
                "row": 3,
                "hexCode": "#958C79",
                "rgb": "149,140,121"
            },
            {
                "nr": "PANTONE 17-1311 TPG",
                "name": "Desert Taupe",
                "book": 1,
                "page": 28,
                "row": 4,
                "hexCode": "#918579",
                "rgb": "145,133,121"
            },
            {
                "nr": "PANTONE 18-1017 TPG",
                "name": "Caribou",
                "book": 1,
                "page": 28,
                "row": 5,
                "hexCode": "#877568",
                "rgb": "135,117,104"
            },
            {
                "nr": "PANTONE 18-1015 TPG",
                "name": "Shitake",
                "book": 1,
                "page": 28,
                "row": 6,
                "hexCode": "#746658",
                "rgb": "116,102,88"
            },
            {
                "nr": "PANTONE 19-0916 TPG",
                "name": "Rain Drum",
                "book": 1,
                "page": 28,
                "row": 7,
                "hexCode": "#63544B",
                "rgb": "99,84,75"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1407 TPG",
                "name": "Cobblestone",
                "book": 1,
                "page": 29,
                "row": 1,
                "hexCode": "#A49A8B",
                "rgb": "164,154,139"
            },
            {
                "nr": "PANTONE 16-1109 TPG",
                "name": "Greige",
                "book": 1,
                "page": 29,
                "row": 2,
                "hexCode": "#968879",
                "rgb": "150,136,121"
            },
            {
                "nr": "PANTONE 17-0909 TPG",
                "name": "Fossil",
                "book": 1,
                "page": 29,
                "row": 3,
                "hexCode": "#807365",
                "rgb": "128,115,101"
            },
            {
                "nr": "PANTONE 18-1016 TPG",
                "name": "Cub",
                "book": 1,
                "page": 29,
                "row": 4,
                "hexCode": "#6B6051",
                "rgb": "107,96,81"
            },
            {
                "nr": "PANTONE 19-0809 TPG",
                "name": "Chocolate Chip",
                "book": 1,
                "page": 29,
                "row": 5,
                "hexCode": "#6A5F53",
                "rgb": "106,95,83"
            },
            {
                "nr": "PANTONE 19-0808 TPG",
                "name": "Morel",
                "book": 1,
                "page": 29,
                "row": 6,
                "hexCode": "#6C645E",
                "rgb": "108,100,94"
            },
            {
                "nr": "PANTONE 18-1304 TPG",
                "name": "Falcon",
                "book": 1,
                "page": 29,
                "row": 7,
                "hexCode": "#6E6560",
                "rgb": "110,101,96"
            }
        ],
        [
            {
                "nr": "PANTONE 16-0906 TPG",
                "name": "Simply Taupe",
                "book": 1,
                "page": 30,
                "row": 1,
                "hexCode": "#AB9F92",
                "rgb": "171,159,146"
            },
            {
                "nr": "PANTONE 17-1105 TPG",
                "name": "Roasted Cashew",
                "book": 1,
                "page": 30,
                "row": 2,
                "hexCode": "#95897E",
                "rgb": "148,137,125"
            },
            {
                "nr": "PANTONE 17-1310 TPG",
                "name": "Timber Wolf",
                "book": 1,
                "page": 30,
                "row": 3,
                "hexCode": "#8A806F",
                "rgb": "138,128,111"
            },
            {
                "nr": "PANTONE 18-0513 TPG",
                "name": "Bungee Cord",
                "book": 1,
                "page": 30,
                "row": 4,
                "hexCode": "#68625B",
                "rgb": "104,98,91"
            },
            {
                "nr": "PANTONE 19-0822 TPG",
                "name": "Tarmac",
                "book": 1,
                "page": 30,
                "row": 5,
                "hexCode": "#59564D",
                "rgb": "89,86,77"
            },
            {
                "nr": "PANTONE 19-0810 TPG",
                "name": "Major Brown",
                "book": 1,
                "page": 30,
                "row": 6,
                "hexCode": "#605A52",
                "rgb": "96,90,82"
            },
            {
                "nr": "PANTONE 19-0823 TPG",
                "name": "Crocodile",
                "book": 1,
                "page": 30,
                "row": 7,
                "hexCode": "#645A51",
                "rgb": "99,89,81"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1103 TPG",
                "name": "Pure Cashmere",
                "book": 1,
                "page": 31,
                "row": 1,
                "hexCode": "#B1A699",
                "rgb": "176,166,153"
            },
            {
                "nr": "PANTONE 16-0205 TPG",
                "name": "Vintage Khaki",
                "book": 1,
                "page": 31,
                "row": 2,
                "hexCode": "#9E948A",
                "rgb": "158,148,138"
            },
            {
                "nr": "PANTONE 18-1110 TPG",
                "name": "Brindle",
                "book": 1,
                "page": 31,
                "row": 3,
                "hexCode": "#7F786C",
                "rgb": "127,120,108"
            },
            {
                "nr": "PANTONE 18-1108 TPG",
                "name": "Fallen Rock",
                "book": 1,
                "page": 31,
                "row": 4,
                "hexCode": "#867E73",
                "rgb": "134,126,115"
            },
            {
                "nr": "PANTONE 19-0820 TPG",
                "name": "Canteen",
                "book": 1,
                "page": 31,
                "row": 5,
                "hexCode": "#61594E",
                "rgb": "97,89,78"
            },
            {
                "nr": "PANTONE 18-1112 TPG",
                "name": "Walnut",
                "book": 1,
                "page": 31,
                "row": 6,
                "hexCode": "#786D60",
                "rgb": "120,109,96"
            },
            {
                "nr": "PANTONE 18-0615 TPG",
                "name": "Stone Gray",
                "book": 1,
                "page": 31,
                "row": 7,
                "hexCode": "#686655",
                "rgb": "104,102,85"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4500 TPG",
                "name": "Moonstruck",
                "book": 1,
                "page": 32,
                "row": 1,
                "hexCode": "#C3C1B8",
                "rgb": "195,193,184"
            },
            {
                "nr": "PANTONE 15-4502 TPG",
                "name": "Silver Cloud",
                "book": 1,
                "page": 32,
                "row": 2,
                "hexCode": "#B7B1AB",
                "rgb": "183,176,170"
            },
            {
                "nr": "PANTONE 14-4501 TPG",
                "name": "Silver Lining",
                "book": 1,
                "page": 32,
                "row": 3,
                "hexCode": "#BFB9AD",
                "rgb": "191,185,173"
            },
            {
                "nr": "PANTONE 14-0000 TPG",
                "name": "Silver Gray",
                "book": 1,
                "page": 32,
                "row": 4,
                "hexCode": "#C5BDB6",
                "rgb": "197,189,182"
            },
            {
                "nr": "PANTONE 15-4503 TPG",
                "name": "Chateau Gray",
                "book": 1,
                "page": 32,
                "row": 5,
                "hexCode": "#B5ADA4",
                "rgb": "181,173,164"
            },
            {
                "nr": "PANTONE 16-1305 TPG",
                "name": "String",
                "book": 1,
                "page": 32,
                "row": 6,
                "hexCode": "#ABA39A",
                "rgb": "171,163,154"
            },
            {
                "nr": "PANTONE 16-1107 TPG",
                "name": "Aluminum",
                "book": 1,
                "page": 32,
                "row": 7,
                "hexCode": "#9B9688",
                "rgb": "155,150,136"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0002 TPG",
                "name": "White Sand",
                "book": 1,
                "page": 33,
                "row": 1,
                "hexCode": "#DCD5D0",
                "rgb": "220,213,208"
            },
            {
                "nr": "PANTONE 14-4002 TPG",
                "name": "Wind Chime",
                "book": 1,
                "page": 33,
                "row": 2,
                "hexCode": "#CAC5C4",
                "rgb": "202,197,196"
            },
            {
                "nr": "PANTONE 15-0703 TPG",
                "name": "Ashes Of Roses",
                "book": 1,
                "page": 33,
                "row": 3,
                "hexCode": "#B2ABAC",
                "rgb": "178,170,171"
            },
            {
                "nr": "PANTONE 15-3800 TPG",
                "name": "Porpoise",
                "book": 1,
                "page": 33,
                "row": 4,
                "hexCode": "#ACA4A1",
                "rgb": "172,164,161"
            },
            {
                "nr": "PANTONE 16-3801 TPG",
                "name": "Opal Gray",
                "book": 1,
                "page": 33,
                "row": 5,
                "hexCode": "#A39C9C",
                "rgb": "163,156,156"
            },
            {
                "nr": "PANTONE 16-3802 TPG",
                "name": "Ash",
                "book": 1,
                "page": 33,
                "row": 6,
                "hexCode": "#9F9C9B",
                "rgb": "159,156,155"
            },
            {
                "nr": "PANTONE 17-1508 TPG",
                "name": "Silent Storm",
                "book": 1,
                "page": 33,
                "row": 7,
                "hexCode": "#7F7C7A",
                "rgb": "127,124,122"
            }
        ],
        [
            {
                "nr": "PANTONE 15-0000 TPG",
                "name": "Dove",
                "book": 1,
                "page": 34,
                "row": 1,
                "hexCode": "#ACA8A4",
                "rgb": "172,168,164"
            },
            {
                "nr": "PANTONE 16-5803 TPG",
                "name": "Flint Gray",
                "book": 1,
                "page": 34,
                "row": 2,
                "hexCode": "#9D9C98",
                "rgb": "157,156,152"
            },
            {
                "nr": "PANTONE 17-0000 TPG",
                "name": "Frost Gray",
                "book": 1,
                "page": 34,
                "row": 3,
                "hexCode": "#848487",
                "rgb": "132,132,135"
            },
            {
                "nr": "PANTONE 17-1502 TPG",
                "name": "Cloudburst",
                "book": 1,
                "page": 34,
                "row": 4,
                "hexCode": "#858485",
                "rgb": "133,132,133"
            },
            {
                "nr": "PANTONE 17-1500 TPG",
                "name": "Steeple Gray",
                "book": 1,
                "page": 34,
                "row": 5,
                "hexCode": "#827F7D",
                "rgb": "130,127,125"
            },
            {
                "nr": "PANTONE 18-0403 TPG",
                "name": "Dark Gull Gray",
                "book": 1,
                "page": 34,
                "row": 6,
                "hexCode": "#625F5E",
                "rgb": "98,95,94"
            },
            {
                "nr": "PANTONE 18-0601 TPG",
                "name": "Charcoal Gray",
                "book": 1,
                "page": 34,
                "row": 7,
                "hexCode": "#6D6D6D",
                "rgb": "109,109,109"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4203 TPG",
                "name": "Vapor Blue",
                "book": 1,
                "page": 35,
                "row": 1,
                "hexCode": "#BCBCBD",
                "rgb": "188,188,189"
            },
            {
                "nr": "PANTONE 16-4703 TPG",
                "name": "Ghost Gray",
                "book": 1,
                "page": 35,
                "row": 2,
                "hexCode": "#989A97",
                "rgb": "152,154,151"
            },
            {
                "nr": "PANTONE 16-4402 TPG",
                "name": "Drizzle",
                "book": 1,
                "page": 35,
                "row": 3,
                "hexCode": "#989997",
                "rgb": "152,153,151"
            },
            {
                "nr": "PANTONE 16-4400 TPG",
                "name": "Mourning Dove",
                "book": 1,
                "page": 35,
                "row": 4,
                "hexCode": "#9A9490",
                "rgb": "154,148,144"
            },
            {
                "nr": "PANTONE 17-0207 TPG",
                "name": "Rock Ridge",
                "book": 1,
                "page": 35,
                "row": 5,
                "hexCode": "#8B8C82",
                "rgb": "139,140,130"
            },
            {
                "nr": "PANTONE 17-0205 TPG",
                "name": "Elephant Skin",
                "book": 1,
                "page": 35,
                "row": 6,
                "hexCode": "#8E8C87",
                "rgb": "142,140,135"
            },
            {
                "nr": "PANTONE 17-5102 TPG",
                "name": "Griffin",
                "book": 1,
                "page": 35,
                "row": 7,
                "hexCode": "#8C9092",
                "rgb": "139,144,145"
            }
        ],
        [
            {
                "nr": "PANTONE 12-4306 TPG",
                "name": "Barely Blue",
                "book": 1,
                "page": 36,
                "row": 1,
                "hexCode": "#DCDFDC",
                "rgb": "220,223,220"
            },
            {
                "nr": "PANTONE 13-4303 TPG",
                "name": "Dawn Blue",
                "book": 1,
                "page": 36,
                "row": 2,
                "hexCode": "#C8CBCA",
                "rgb": "200,203,202"
            },
            {
                "nr": "PANTONE 15-4703 TPG",
                "name": "Mirage Gray",
                "book": 1,
                "page": 36,
                "row": 3,
                "hexCode": "#A3ADAB",
                "rgb": "163,173,171"
            },
            {
                "nr": "PANTONE 16-4702 TPG",
                "name": "Limestone",
                "book": 1,
                "page": 36,
                "row": 4,
                "hexCode": "#959C9A",
                "rgb": "149,156,154"
            },
            {
                "nr": "PANTONE 18-4105 TPG",
                "name": "Moon Mist",
                "book": 1,
                "page": 36,
                "row": 5,
                "hexCode": "#7B7F7B",
                "rgb": "123,127,123"
            },
            {
                "nr": "PANTONE 17-1501 TPG",
                "name": "Wild Dove",
                "book": 1,
                "page": 36,
                "row": 6,
                "hexCode": "#8A8D8D",
                "rgb": "138,141,141"
            },
            {
                "nr": "PANTONE 18-0510 TPG",
                "name": "Castor Gray",
                "book": 1,
                "page": 36,
                "row": 7,
                "hexCode": "#696F6A",
                "rgb": "105,111,106"
            }
        ],
        [
            {
                "nr": "PANTONE 12-4705 TPG",
                "name": "Blue Blush",
                "book": 1,
                "page": 37,
                "row": 1,
                "hexCode": "#D5DED9",
                "rgb": "213,222,217"
            },
            {
                "nr": "PANTONE 13-4305 TPG",
                "name": "Foggy Dew",
                "book": 1,
                "page": 37,
                "row": 2,
                "hexCode": "#D1D4CD",
                "rgb": "208,211,204"
            },
            {
                "nr": "PANTONE 14-4804 TPG",
                "name": "Blue Fox",
                "book": 1,
                "page": 37,
                "row": 3,
                "hexCode": "#B5BEB8",
                "rgb": "181,190,184"
            },
            {
                "nr": "PANTONE 15-4003 TPG",
                "name": "Storm Gray",
                "book": 1,
                "page": 37,
                "row": 4,
                "hexCode": "#AFB8B3",
                "rgb": "175,184,179"
            },
            {
                "nr": "PANTONE 17-4402 TPG",
                "name": "Neutral Gray",
                "book": 1,
                "page": 37,
                "row": 5,
                "hexCode": "#898E8D",
                "rgb": "137,142,141"
            },
            {
                "nr": "PANTONE 18-5105 TPG",
                "name": "Sedona Sage",
                "book": 1,
                "page": 37,
                "row": 6,
                "hexCode": "#687171",
                "rgb": "104,113,113"
            },
            {
                "nr": "PANTONE 18-0306 TPG",
                "name": "Gunmetal",
                "book": 1,
                "page": 37,
                "row": 7,
                "hexCode": "#5F6260",
                "rgb": "95,98,96"
            }
        ],
        [
            {
                "nr": "PANTONE 11-4601 TPG",
                "name": "Bit Of Blue",
                "book": 1,
                "page": 38,
                "row": 1,
                "hexCode": "#E4E9E8",
                "rgb": "228,233,232"
            },
            {
                "nr": "PANTONE 13-4201 TPG",
                "name": "Oyster Mushroom",
                "book": 1,
                "page": 38,
                "row": 2,
                "hexCode": "#C8C9CB",
                "rgb": "199,201,202"
            },
            {
                "nr": "PANTONE 15-4101 TPG",
                "name": "High-rise",
                "book": 1,
                "page": 38,
                "row": 3,
                "hexCode": "#A9B0B2",
                "rgb": "169,176,178"
            },
            {
                "nr": "PANTONE 15-4305 TPG",
                "name": "Quarry",
                "book": 1,
                "page": 38,
                "row": 4,
                "hexCode": "#9AA4A9",
                "rgb": "153,163,169"
            },
            {
                "nr": "PANTONE 17-4405 TPG",
                "name": "Monument",
                "book": 1,
                "page": 38,
                "row": 5,
                "hexCode": "#7E868A",
                "rgb": "125,134,137"
            },
            {
                "nr": "PANTONE 17-3914 TPG",
                "name": "Sharkskin",
                "book": 1,
                "page": 38,
                "row": 6,
                "hexCode": "#8A8A8D",
                "rgb": "138,138,140"
            },
            {
                "nr": "PANTONE 18-0202 TPG",
                "name": "Lava Smoke",
                "book": 1,
                "page": 38,
                "row": 7,
                "hexCode": "#64676A",
                "rgb": "100,103,106"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4108 TPG",
                "name": "Nimbus Cloud",
                "book": 1,
                "page": 39,
                "row": 1,
                "hexCode": "#D9D8DA",
                "rgb": "217,216,218"
            },
            {
                "nr": "PANTONE 14-4104 TPG",
                "name": "Northern Droplet",
                "book": 1,
                "page": 39,
                "row": 2,
                "hexCode": "#BDC1C0",
                "rgb": "189,193,192"
            },
            {
                "nr": "PANTONE 17-5104 TPG",
                "name": "Ultimate Gray",
                "book": 1,
                "page": 39,
                "row": 3,
                "hexCode": "#96999C",
                "rgb": "150,153,156"
            },
            {
                "nr": "PANTONE 16-3917 TPG",
                "name": "Chiseled Stone",
                "book": 1,
                "page": 39,
                "row": 4,
                "hexCode": "#8F8F93",
                "rgb": "143,143,147"
            },
            {
                "nr": "PANTONE 15-4304 TPG",
                "name": "Weathervane",
                "book": 1,
                "page": 39,
                "row": 5,
                "hexCode": "#8E939B",
                "rgb": "142,147,155"
            },
            {
                "nr": "PANTONE 18-4017 TPG",
                "name": "Night Owl",
                "book": 1,
                "page": 39,
                "row": 6,
                "hexCode": "#7E7E83",
                "rgb": "126,126,131"
            },
            {
                "nr": "PANTONE 18-4004 TPG",
                "name": "Poppy Seed",
                "book": 1,
                "page": 39,
                "row": 7,
                "hexCode": "#66686C",
                "rgb": "102,104,108"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4201 TPG",
                "name": "Lunar Rock",
                "book": 1,
                "page": 40,
                "row": 1,
                "hexCode": "#C6C8C9",
                "rgb": "198,200,201"
            },
            {
                "nr": "PANTONE 14-4102 TPG",
                "name": "Glacier Gray",
                "book": 1,
                "page": 40,
                "row": 2,
                "hexCode": "#C4C8C9",
                "rgb": "196,200,201"
            },
            {
                "nr": "PANTONE 13-4104 TPG",
                "name": "Antarctica",
                "book": 1,
                "page": 40,
                "row": 3,
                "hexCode": "#C9C9C8",
                "rgb": "201,200,200"
            },
            {
                "nr": "PANTONE 14-4103 TPG",
                "name": "Gray Violet",
                "book": 1,
                "page": 40,
                "row": 4,
                "hexCode": "#BEC1BF",
                "rgb": "190,193,191"
            },
            {
                "nr": "PANTONE 14-4107 TPG",
                "name": "Quiet Gray",
                "book": 1,
                "page": 40,
                "row": 5,
                "hexCode": "#BCBCBE",
                "rgb": "187,188,189"
            },
            {
                "nr": "PANTONE 14-4105 TPG",
                "name": "Micro Chip",
                "book": 1,
                "page": 40,
                "row": 6,
                "hexCode": "#BFBFC2",
                "rgb": "191,191,194"
            },
            {
                "nr": "PANTONE 14-4202 TPG",
                "name": "Harbor Mist",
                "book": 1,
                "page": 40,
                "row": 7,
                "hexCode": "#B4B7BA",
                "rgb": "179,182,185"
            }
        ],
        [
            {
                "nr": "PANTONE 16-3850 TPG",
                "name": "Silver Sconce",
                "book": 1,
                "page": 41,
                "row": 1,
                "hexCode": "#9E9EA3",
                "rgb": "158,157,162"
            },
            {
                "nr": "PANTONE 16-5102 TPG",
                "name": "Formal Gray",
                "book": 1,
                "page": 41,
                "row": 2,
                "hexCode": "#9C9B9D",
                "rgb": "156,155,157"
            },
            {
                "nr": "PANTONE 16-3915 TPG",
                "name": "Alloy",
                "book": 1,
                "page": 41,
                "row": 3,
                "hexCode": "#96979B",
                "rgb": "150,151,155"
            },
            {
                "nr": "PANTONE 17-3913 TPG",
                "name": "Skyrocket",
                "book": 1,
                "page": 41,
                "row": 4,
                "hexCode": "#87888F",
                "rgb": "135,136,143"
            },
            {
                "nr": "PANTONE 17-3911 TPG",
                "name": "Silver Filigree",
                "book": 1,
                "page": 41,
                "row": 5,
                "hexCode": "#858286",
                "rgb": "133,130,134"
            },
            {
                "nr": "PANTONE 18-4006 TPG",
                "name": "Quiet Shade",
                "book": 1,
                "page": 41,
                "row": 6,
                "hexCode": "#6C6E72",
                "rgb": "108,110,114"
            },
            {
                "nr": "PANTONE 18-3907 TPG",
                "name": "Tornado",
                "book": 1,
                "page": 41,
                "row": 7,
                "hexCode": "#676469",
                "rgb": "103,100,105"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5101 TPG",
                "name": "Wet Weather",
                "book": 1,
                "page": 42,
                "row": 1,
                "hexCode": "#959392",
                "rgb": "149,147,146"
            },
            {
                "nr": "PANTONE 17-4014 TPG",
                "name": "Titanium",
                "book": 1,
                "page": 42,
                "row": 2,
                "hexCode": "#868486",
                "rgb": "134,132,134"
            },
            {
                "nr": "PANTONE 18-5102 TPG",
                "name": "Brushed Nickel",
                "book": 1,
                "page": 42,
                "row": 3,
                "hexCode": "#7A7674",
                "rgb": "122,118,116"
            },
            {
                "nr": "PANTONE 17-4013 TPG",
                "name": "Gray Quill",
                "book": 1,
                "page": 42,
                "row": 4,
                "hexCode": "#7E7C7E",
                "rgb": "126,124,126"
            },
            {
                "nr": "PANTONE 18-4005 TPG",
                "name": "Steel Gray",
                "book": 1,
                "page": 42,
                "row": 5,
                "hexCode": "#6D7073",
                "rgb": "109,112,115"
            },
            {
                "nr": "PANTONE 18-4016 TPG",
                "name": "December Sky",
                "book": 1,
                "page": 42,
                "row": 6,
                "hexCode": "#7E7B7B",
                "rgb": "126,123,123"
            },
            {
                "nr": "PANTONE 18-4010 TPG",
                "name": "City Skyline",
                "book": 1,
                "page": 42,
                "row": 7,
                "hexCode": "#747376",
                "rgb": "116,115,118"
            }
        ],
        [
            {
                "nr": "PANTONE 16-3803 TPG",
                "name": "Gull Gray",
                "book": 1,
                "page": 43,
                "row": 1,
                "hexCode": "#A6A0A6",
                "rgb": "166,160,166"
            },
            {
                "nr": "PANTONE 17-2601 TPG",
                "name": "Zinc",
                "book": 1,
                "page": 43,
                "row": 2,
                "hexCode": "#91898C",
                "rgb": "145,136,140"
            },
            {
                "nr": "PANTONE 17-3802 TPG",
                "name": "Gull",
                "book": 1,
                "page": 43,
                "row": 3,
                "hexCode": "#908D92",
                "rgb": "144,140,146"
            },
            {
                "nr": "PANTONE 17-1503 TPG",
                "name": "Storm Front",
                "book": 1,
                "page": 43,
                "row": 4,
                "hexCode": "#7C777B",
                "rgb": "124,119,123"
            },
            {
                "nr": "PANTONE 18-3908 TPG",
                "name": "Volcanic Glass",
                "book": 1,
                "page": 43,
                "row": 5,
                "hexCode": "#686368",
                "rgb": "104,98,104"
            },
            {
                "nr": "PANTONE 19-3803 TPG",
                "name": "Plum Kitten",
                "book": 1,
                "page": 43,
                "row": 6,
                "hexCode": "#605E60",
                "rgb": "96,94,96"
            },
            {
                "nr": "PANTONE 18-3906 TPG",
                "name": "Boulevard",
                "book": 1,
                "page": 43,
                "row": 7,
                "hexCode": "#5D585F",
                "rgb": "93,88,95"
            }
        ],
        [
            {
                "nr": "PANTONE 16-0000 TPG",
                "name": "Paloma",
                "book": 1,
                "page": 44,
                "row": 1,
                "hexCode": "#9D9D9B",
                "rgb": "157,157,155"
            },
            {
                "nr": "PANTONE 17-4016 TPG",
                "name": "Gray Flannel",
                "book": 1,
                "page": 44,
                "row": 2,
                "hexCode": "#898586",
                "rgb": "136,133,133"
            },
            {
                "nr": "PANTONE 18-4015 TPG",
                "name": "Mockingbird",
                "book": 1,
                "page": 44,
                "row": 3,
                "hexCode": "#7E7D80",
                "rgb": "126,125,128"
            },
            {
                "nr": "PANTONE 18-0503 TPG",
                "name": "Gargoyle",
                "book": 1,
                "page": 44,
                "row": 4,
                "hexCode": "#6A6B6A",
                "rgb": "106,107,106"
            },
            {
                "nr": "PANTONE 18-5203 TPG",
                "name": "Pewter",
                "book": 1,
                "page": 44,
                "row": 5,
                "hexCode": "#656667",
                "rgb": "101,102,103"
            },
            {
                "nr": "PANTONE 18-5206 TPG",
                "name": "Thunderstorm",
                "book": 1,
                "page": 44,
                "row": 6,
                "hexCode": "#605E60",
                "rgb": "96,94,96"
            },
            {
                "nr": "PANTONE 19-3906 TPG",
                "name": "Dark Shadow",
                "book": 1,
                "page": 44,
                "row": 7,
                "hexCode": "#505557",
                "rgb": "79,84,86"
            }
        ],
        [
            {
                "nr": "PANTONE 19-4405 TPG",
                "name": "Forest River",
                "book": 1,
                "page": 45,
                "row": 1,
                "hexCode": "#3B4142",
                "rgb": "59,65,66"
            },
            {
                "nr": "PANTONE 19-5002 TPG",
                "name": "Woodland Gray",
                "book": 1,
                "page": 45,
                "row": 2,
                "hexCode": "#43484B",
                "rgb": "67,72,75"
            },
            {
                "nr": "PANTONE 19-5005 TPG",
                "name": "Unexplored",
                "book": 1,
                "page": 45,
                "row": 3,
                "hexCode": "#484A49",
                "rgb": "72,74,73"
            },
            {
                "nr": "PANTONE 19-4406 TPG",
                "name": "Black Sand",
                "book": 1,
                "page": 45,
                "row": 4,
                "hexCode": "#48494C",
                "rgb": "72,73,76"
            },
            {
                "nr": "PANTONE 19-1100 TPG",
                "name": "Bristol Black",
                "book": 1,
                "page": 45,
                "row": 5,
                "hexCode": "#474445",
                "rgb": "71,68,69"
            },
            {
                "nr": "PANTONE 19-3912 TPG",
                "name": "Volcanic Ash",
                "book": 1,
                "page": 45,
                "row": 6,
                "hexCode": "#4F5152",
                "rgb": "79,81,82"
            },
            {
                "nr": "PANTONE 19-0205 TPG",
                "name": "Black Oyster",
                "book": 1,
                "page": 45,
                "row": 7,
                "hexCode": "#494A4D",
                "rgb": "73,74,77"
            }
        ],
        [
            {
                "nr": "PANTONE 18-0000 TPG",
                "name": "Smoked Pearl",
                "book": 1,
                "page": 46,
                "row": 1,
                "hexCode": "#6A6C6E",
                "rgb": "106,108,110"
            },
            {
                "nr": "PANTONE 18-0201 TPG",
                "name": "Castlerock",
                "book": 1,
                "page": 46,
                "row": 2,
                "hexCode": "#5E6366",
                "rgb": "94,99,102"
            },
            {
                "nr": "PANTONE 18-5204 TPG",
                "name": "Granite Gray",
                "book": 1,
                "page": 46,
                "row": 3,
                "hexCode": "#696667",
                "rgb": "104,102,102"
            },
            {
                "nr": "PANTONE 18-5210 TPG",
                "name": "Eiffel Tower",
                "book": 1,
                "page": 46,
                "row": 4,
                "hexCode": "#655F61",
                "rgb": "101,95,97"
            },
            {
                "nr": "PANTONE 19-3910 TPG",
                "name": "Iron Gate",
                "book": 1,
                "page": 46,
                "row": 5,
                "hexCode": "#575B5F",
                "rgb": "86,90,95"
            },
            {
                "nr": "PANTONE 19-0201 TPG",
                "name": "Asphalt",
                "book": 1,
                "page": 46,
                "row": 6,
                "hexCode": "#515256",
                "rgb": "81,82,86"
            },
            {
                "nr": "PANTONE 19-3901 TPG",
                "name": "Magnet",
                "book": 1,
                "page": 46,
                "row": 7,
                "hexCode": "#535257",
                "rgb": "83,82,87"
            }
        ],
        [
            {
                "nr": "PANTONE 19-3905 TPG",
                "name": "Rabbit",
                "book": 1,
                "page": 47,
                "row": 1,
                "hexCode": "#5E5A5E",
                "rgb": "94,90,94"
            },
            {
                "nr": "PANTONE 18-3905 TPG",
                "name": "Excalibur",
                "book": 1,
                "page": 47,
                "row": 2,
                "hexCode": "#65626A",
                "rgb": "101,98,106"
            },
            {
                "nr": "PANTONE 19-3903 TPG",
                "name": "Shale",
                "book": 1,
                "page": 47,
                "row": 3,
                "hexCode": "#4C474A",
                "rgb": "76,71,74"
            },
            {
                "nr": "PANTONE 19-3900 TPG",
                "name": "Pavement",
                "book": 1,
                "page": 47,
                "row": 4,
                "hexCode": "#5A5558",
                "rgb": "90,85,88"
            },
            {
                "nr": "PANTONE 19-3908 TPG",
                "name": "Nine Iron",
                "book": 1,
                "page": 47,
                "row": 5,
                "hexCode": "#49484E",
                "rgb": "73,72,78"
            },
            {
                "nr": "PANTONE 19-4104 TPG",
                "name": "Ebony",
                "book": 1,
                "page": 47,
                "row": 6,
                "hexCode": "#494D53",
                "rgb": "72,76,82"
            },
            {
                "nr": "PANTONE 19-3918 TPG",
                "name": "Periscope",
                "book": 1,
                "page": 47,
                "row": 7,
                "hexCode": "#52525A",
                "rgb": "82,82,90"
            }
        ],
        [
            {
                "nr": "PANTONE 19-0203 TPG",
                "name": "Gray Pinstripe",
                "book": 1,
                "page": 48,
                "row": 1,
                "hexCode": "#555459",
                "rgb": "84,84,88"
            },
            {
                "nr": "PANTONE 19-3907 TPG",
                "name": "Forged Iron",
                "book": 1,
                "page": 48,
                "row": 2,
                "hexCode": "#535255",
                "rgb": "83,82,85"
            },
            {
                "nr": "PANTONE 19-3917 TPG",
                "name": "Blackened Pearl",
                "book": 1,
                "page": 48,
                "row": 3,
                "hexCode": "#58565B",
                "rgb": "87,86,91"
            },
            {
                "nr": "PANTONE 19-3902 TPG",
                "name": "Obsidian",
                "book": 1,
                "page": 48,
                "row": 4,
                "hexCode": "#4B484D",
                "rgb": "74,71,76"
            },
            {
                "nr": "PANTONE 19-4205 TPG",
                "name": "Phantom",
                "book": 1,
                "page": 48,
                "row": 5,
                "hexCode": "#3F4144",
                "rgb": "63,65,68"
            },
            {
                "nr": "PANTONE 19-4007 TPG",
                "name": "Anthracite",
                "book": 1,
                "page": 48,
                "row": 6,
                "hexCode": "#3C3E42",
                "rgb": "60,62,66"
            },
            {
                "nr": "PANTONE 19-0303 TPG",
                "name": "Jet Black",
                "book": 1,
                "page": 48,
                "row": 7,
                "hexCode": "#424142",
                "rgb": "66,65,66"
            }
        ],
        [
            {
                "nr": "PANTONE 19-3911 TPG",
                "name": "Black Beauty",
                "book": 1,
                "page": 49,
                "row": 1,
                "hexCode": "#3B3C40",
                "rgb": "58,60,63"
            },
            {
                "nr": "PANTONE 19-4003 TPG",
                "name": "Black Onyx",
                "book": 1,
                "page": 49,
                "row": 2,
                "hexCode": "#3F3D41",
                "rgb": "62,60,65"
            },
            {
                "nr": "PANTONE 19-4004 TPG",
                "name": "Tap Shoe",
                "book": 1,
                "page": 49,
                "row": 3,
                "hexCode": "#3C3C3E",
                "rgb": "60,60,62"
            },
            {
                "nr": "PANTONE 19-4005 TPG",
                "name": "Stretch Limo",
                "book": 1,
                "page": 49,
                "row": 4,
                "hexCode": "#3B3F41",
                "rgb": "59,63,65"
            },
            {
                "nr": "PANTONE 19-4006 TPG",
                "name": "Caviar",
                "book": 1,
                "page": 49,
                "row": 5,
                "hexCode": "#3B3C3E",
                "rgb": "59,60,62"
            },
            {
                "nr": "PANTONE 19-4008 TPG",
                "name": "Meteorite",
                "book": 1,
                "page": 49,
                "row": 6,
                "hexCode": "#403D3F",
                "rgb": "64,61,63"
            },
            {
                "nr": "PANTONE 19-4203 TPG",
                "name": "Moonless Night",
                "book": 1,
                "page": 49,
                "row": 7,
                "hexCode": "#3C3E40",
                "rgb": "60,62,64"
            }
        ],
        [
            {
                "nr": "PANTONE 19-0405 TPG",
                "name": "Beluga",
                "book": 1,
                "page": 50,
                "row": 1,
                "hexCode": "#4C4C49",
                "rgb": "76,76,73"
            },
            {
                "nr": "PANTONE 19-0608 TPG",
                "name": "Black Olive",
                "book": 1,
                "page": 50,
                "row": 2,
                "hexCode": "#4D4A46",
                "rgb": "77,74,70"
            },
            {
                "nr": "PANTONE 19-0000 TPG",
                "name": "Raven",
                "book": 1,
                "page": 50,
                "row": 3,
                "hexCode": "#444545",
                "rgb": "68,69,69"
            },
            {
                "nr": "PANTONE 19-0506 TPG",
                "name": "Black Ink",
                "book": 1,
                "page": 50,
                "row": 4,
                "hexCode": "#484A46",
                "rgb": "72,74,70"
            },
            {
                "nr": "PANTONE 19-0508 TPG",
                "name": "Peat",
                "book": 1,
                "page": 50,
                "row": 5,
                "hexCode": "#494948",
                "rgb": "73,73,72"
            },
            {
                "nr": "PANTONE 19-4305 TPG",
                "name": "Pirate Black",
                "book": 1,
                "page": 50,
                "row": 6,
                "hexCode": "#3D4040",
                "rgb": "61,64,64"
            },
            {
                "nr": "PANTONE 19-5708 TPG",
                "name": "Jet Set",
                "book": 1,
                "page": 50,
                "row": 7,
                "hexCode": "#3C4242",
                "rgb": "60,66,66"
            }
        ],
        [
            {
                "nr": "PANTONE 19-0812 TPG",
                "name": "Turkish Coffee",
                "book": 1,
                "page": 51,
                "row": 1,
                "hexCode": "#4C4743",
                "rgb": "76,71,67"
            },
            {
                "nr": "PANTONE 19-0814 TPG",
                "name": "Slate Black",
                "book": 1,
                "page": 51,
                "row": 2,
                "hexCode": "#524840",
                "rgb": "82,72,64"
            },
            {
                "nr": "PANTONE 19-0614 TPG",
                "name": "Wren",
                "book": 1,
                "page": 51,
                "row": 3,
                "hexCode": "#4E4740",
                "rgb": "78,71,64"
            },
            {
                "nr": "PANTONE 19-0712 TPG",
                "name": "Demitasse",
                "book": 1,
                "page": 51,
                "row": 4,
                "hexCode": "#4C443F",
                "rgb": "76,68,63"
            },
            {
                "nr": "PANTONE 19-0910 TPG",
                "name": "Mulch",
                "book": 1,
                "page": 51,
                "row": 5,
                "hexCode": "#4F4745",
                "rgb": "79,71,69"
            },
            {
                "nr": "PANTONE 19-1102 TPG",
                "name": "Licorice",
                "book": 1,
                "page": 51,
                "row": 6,
                "hexCode": "#403E3E",
                "rgb": "64,62,62"
            },
            {
                "nr": "PANTONE 19-1103 TPG",
                "name": "Espresso",
                "book": 1,
                "page": 51,
                "row": 7,
                "hexCode": "#433E40",
                "rgb": "67,62,64"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1015 TPG",
                "name": "Bracken",
                "book": 1,
                "page": 52,
                "row": 1,
                "hexCode": "#544A46",
                "rgb": "84,74,70"
            },
            {
                "nr": "PANTONE 19-0912 TPG",
                "name": "Chocolate Brown",
                "book": 1,
                "page": 52,
                "row": 2,
                "hexCode": "#4D433E",
                "rgb": "77,67,62"
            },
            {
                "nr": "PANTONE 19-0840 TPG",
                "name": "Delicioso",
                "book": 1,
                "page": 52,
                "row": 3,
                "hexCode": "#4B4340",
                "rgb": "75,67,64"
            },
            {
                "nr": "PANTONE 19-1016 TPG",
                "name": "Java",
                "book": 1,
                "page": 52,
                "row": 4,
                "hexCode": "#4B413D",
                "rgb": "75,65,61"
            },
            {
                "nr": "PANTONE 19-0915 TPG",
                "name": "Coffee Bean",
                "book": 1,
                "page": 52,
                "row": 5,
                "hexCode": "#463F3D",
                "rgb": "70,63,61"
            },
            {
                "nr": "PANTONE 19-1106 TPG",
                "name": "Mole'",
                "book": 1,
                "page": 52,
                "row": 6,
                "hexCode": "#483C3B",
                "rgb": "72,60,59"
            },
            {
                "nr": "PANTONE 19-1101 TPG",
                "name": "After Dark",
                "book": 1,
                "page": 52,
                "row": 7,
                "hexCode": "#423E3E",
                "rgb": "66,62,62"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1213 TPG",
                "name": "Shopping Bag",
                "book": 1,
                "page": 53,
                "row": 1,
                "hexCode": "#5A4A47",
                "rgb": "90,74,71"
            },
            {
                "nr": "PANTONE 19-1012 TPG",
                "name": "French Roast",
                "book": 1,
                "page": 53,
                "row": 2,
                "hexCode": "#5E4D4A",
                "rgb": "94,77,74"
            },
            {
                "nr": "PANTONE 19-1314 TPG",
                "name": "Seal Brown",
                "book": 1,
                "page": 53,
                "row": 3,
                "hexCode": "#4B4140",
                "rgb": "75,65,64"
            },
            {
                "nr": "PANTONE 19-1109 TPG",
                "name": "Chocolate Torte",
                "book": 1,
                "page": 53,
                "row": 4,
                "hexCode": "#463E3D",
                "rgb": "70,62,61"
            },
            {
                "nr": "PANTONE 19-1111 TPG",
                "name": "Black Coffee",
                "book": 1,
                "page": 53,
                "row": 5,
                "hexCode": "#473F3F",
                "rgb": "71,63,62"
            },
            {
                "nr": "PANTONE 19-1018 TPG",
                "name": "Ganache",
                "book": 1,
                "page": 53,
                "row": 6,
                "hexCode": "#463D3D",
                "rgb": "70,61,61"
            },
            {
                "nr": "PANTONE 19-3909 TPG",
                "name": "Black Bean",
                "book": 1,
                "page": 53,
                "row": 7,
                "hexCode": "#433D41",
                "rgb": "67,61,65"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1118 TPG",
                "name": "Chestnut",
                "book": 1,
                "page": 54,
                "row": 1,
                "hexCode": "#5B4C45",
                "rgb": "91,76,69"
            },
            {
                "nr": "PANTONE 19-1234 TPG",
                "name": "Rocky Road",
                "book": 1,
                "page": 54,
                "row": 2,
                "hexCode": "#624A44",
                "rgb": "98,74,67"
            },
            {
                "nr": "PANTONE 19-1420 TPG",
                "name": "Deep Mahogany",
                "book": 1,
                "page": 54,
                "row": 3,
                "hexCode": "#5D4947",
                "rgb": "93,73,71"
            },
            {
                "nr": "PANTONE 19-1518 TPG",
                "name": "Puce",
                "book": 1,
                "page": 54,
                "row": 4,
                "hexCode": "#584747",
                "rgb": "88,70,70"
            },
            {
                "nr": "PANTONE 19-1215 TPG",
                "name": "Shaved Chocolate",
                "book": 1,
                "page": 54,
                "row": 5,
                "hexCode": "#5D4944",
                "rgb": "92,72,68"
            },
            {
                "nr": "PANTONE 19-1419 TPG",
                "name": "Chicory Coffee",
                "book": 1,
                "page": 54,
                "row": 6,
                "hexCode": "#574542",
                "rgb": "86,69,65"
            },
            {
                "nr": "PANTONE 19-1110 TPG",
                "name": "Chocolate Plum",
                "book": 1,
                "page": 54,
                "row": 7,
                "hexCode": "#4B3F40",
                "rgb": "75,62,63"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1224 TPG",
                "name": "Fondue Fudge",
                "book": 1,
                "page": 55,
                "row": 1,
                "hexCode": "#665149",
                "rgb": "101,81,73"
            },
            {
                "nr": "PANTONE 19-1233 TPG",
                "name": "Tiramisu",
                "book": 1,
                "page": 55,
                "row": 2,
                "hexCode": "#6C5046",
                "rgb": "107,79,70"
            },
            {
                "nr": "PANTONE 19-1214 TPG",
                "name": "Chocolate Lab",
                "book": 1,
                "page": 55,
                "row": 3,
                "hexCode": "#644B44",
                "rgb": "99,74,68"
            },
            {
                "nr": "PANTONE 19-1235 TPG",
                "name": "Brunette",
                "book": 1,
                "page": 55,
                "row": 4,
                "hexCode": "#674C46",
                "rgb": "103,76,70"
            },
            {
                "nr": "PANTONE 19-1218 TPG",
                "name": "Potting Soil",
                "book": 1,
                "page": 55,
                "row": 5,
                "hexCode": "#5F4D46",
                "rgb": "95,77,70"
            },
            {
                "nr": "PANTONE 19-1432 TPG",
                "name": "Chocolate Fondant",
                "book": 1,
                "page": 55,
                "row": 6,
                "hexCode": "#604944",
                "rgb": "96,73,67"
            },
            {
                "nr": "PANTONE 19-1322 TPG",
                "name": "Brown Stone",
                "book": 1,
                "page": 55,
                "row": 7,
                "hexCode": "#604A4A",
                "rgb": "96,74,74"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1217 TPG",
                "name": "Mustang",
                "book": 1,
                "page": 56,
                "row": 1,
                "hexCode": "#6E564D",
                "rgb": "110,86,77"
            },
            {
                "nr": "PANTONE 19-1430 TPG",
                "name": "Mink",
                "book": 1,
                "page": 56,
                "row": 2,
                "hexCode": "#75534D",
                "rgb": "117,83,77"
            },
            {
                "nr": "PANTONE 19-1228 TPG",
                "name": "Root Beer",
                "book": 1,
                "page": 56,
                "row": 3,
                "hexCode": "#75544D",
                "rgb": "117,84,77"
            },
            {
                "nr": "PANTONE 19-1220 TPG",
                "name": "Cappuccino",
                "book": 1,
                "page": 56,
                "row": 4,
                "hexCode": "#6A4D46",
                "rgb": "106,77,70"
            },
            {
                "nr": "PANTONE 19-1431 TPG",
                "name": "Fudgesickle",
                "book": 1,
                "page": 56,
                "row": 5,
                "hexCode": "#694C47",
                "rgb": "105,76,71"
            },
            {
                "nr": "PANTONE 19-1321 TPG",
                "name": "Rum Raisin",
                "book": 1,
                "page": 56,
                "row": 6,
                "hexCode": "#5E4141",
                "rgb": "94,65,65"
            },
            {
                "nr": "PANTONE 19-1317 TPG",
                "name": "Bitter Chocolate",
                "book": 1,
                "page": 56,
                "row": 7,
                "hexCode": "#594243",
                "rgb": "89,66,67"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1436 TPG",
                "name": "Cinnamon",
                "book": 1,
                "page": 57,
                "row": 1,
                "hexCode": "#6F4C46",
                "rgb": "111,76,70"
            },
            {
                "nr": "PANTONE 19-1320 TPG",
                "name": "Sable",
                "book": 1,
                "page": 57,
                "row": 2,
                "hexCode": "#754D4A",
                "rgb": "117,77,74"
            },
            {
                "nr": "PANTONE 19-1334 TPG",
                "name": "Henna",
                "book": 1,
                "page": 57,
                "row": 3,
                "hexCode": "#824F49",
                "rgb": "130,79,73"
            },
            {
                "nr": "PANTONE 19-1429 TPG",
                "name": "Smoked Paprika",
                "book": 1,
                "page": 57,
                "row": 4,
                "hexCode": "#72423B",
                "rgb": "113,65,58"
            },
            {
                "nr": "PANTONE 19-1337 TPG",
                "name": "Fired Brick",
                "book": 1,
                "page": 57,
                "row": 5,
                "hexCode": "#743D3B",
                "rgb": "116,61,59"
            },
            {
                "nr": "PANTONE 19-1331 TPG",
                "name": "Madder Brown",
                "book": 1,
                "page": 57,
                "row": 6,
                "hexCode": "#704344",
                "rgb": "112,67,68"
            },
            {
                "nr": "PANTONE 19-1325 TPG",
                "name": "Hot Chocolate",
                "book": 1,
                "page": 57,
                "row": 7,
                "hexCode": "#6F4A48",
                "rgb": "111,74,72"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1336 TPG",
                "name": "Copper Brown",
                "book": 1,
                "page": 58,
                "row": 1,
                "hexCode": "#966358",
                "rgb": "150,99,88"
            },
            {
                "nr": "PANTONE 18-1433 TPG",
                "name": "Chutney",
                "book": 1,
                "page": 58,
                "row": 2,
                "hexCode": "#955E52",
                "rgb": "149,94,82"
            },
            {
                "nr": "PANTONE 18-1425 TPG",
                "name": "Mahogany",
                "book": 1,
                "page": 58,
                "row": 3,
                "hexCode": "#855450",
                "rgb": "133,84,80"
            },
            {
                "nr": "PANTONE 18-1541 TPG",
                "name": "Brandy Brown",
                "book": 1,
                "page": 58,
                "row": 4,
                "hexCode": "#78463E",
                "rgb": "119,69,61"
            },
            {
                "nr": "PANTONE 19-1338 TPG",
                "name": "Russet Brown",
                "book": 1,
                "page": 58,
                "row": 5,
                "hexCode": "#7B4747",
                "rgb": "123,71,71"
            },
            {
                "nr": "PANTONE 18-1325 TPG",
                "name": "Spiced Apple",
                "book": 1,
                "page": 58,
                "row": 6,
                "hexCode": "#7E4847",
                "rgb": "125,71,71"
            },
            {
                "nr": "PANTONE 19-1435 TPG",
                "name": "Cherry Mahogany",
                "book": 1,
                "page": 58,
                "row": 7,
                "hexCode": "#714640",
                "rgb": "113,70,64"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1432 TPG",
                "name": "Redwood Burl",
                "book": 1,
                "page": 59,
                "row": 1,
                "hexCode": "#9F5E55",
                "rgb": "159,94,85"
            },
            {
                "nr": "PANTONE 18-1243 TPG",
                "name": "Imperial Topaz",
                "book": 1,
                "page": 59,
                "row": 2,
                "hexCode": "#8C5A4B",
                "rgb": "140,90,75"
            },
            {
                "nr": "PANTONE 18-1341 TPG",
                "name": "Maple Syrup",
                "book": 1,
                "page": 59,
                "row": 3,
                "hexCode": "#8D574B",
                "rgb": "141,87,75"
            },
            {
                "nr": "PANTONE 18-1323 TPG",
                "name": "Brandy Snifter",
                "book": 1,
                "page": 59,
                "row": 4,
                "hexCode": "#874C4A",
                "rgb": "135,76,74"
            },
            {
                "nr": "PANTONE 19-1541 TPG",
                "name": "Roasted Russet",
                "book": 1,
                "page": 59,
                "row": 5,
                "hexCode": "#834A42",
                "rgb": "131,74,66"
            },
            {
                "nr": "PANTONE 19-1428 TPG",
                "name": "Brownout",
                "book": 1,
                "page": 59,
                "row": 6,
                "hexCode": "#784F45",
                "rgb": "120,79,69"
            },
            {
                "nr": "PANTONE 19-1242 TPG",
                "name": "Cambridge Brown",
                "book": 1,
                "page": 59,
                "row": 7,
                "hexCode": "#774F3F",
                "rgb": "119,79,63"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1346 TPG",
                "name": "Bruschetta",
                "book": 1,
                "page": 60,
                "row": 1,
                "hexCode": "#AE5F54",
                "rgb": "174,95,84"
            },
            {
                "nr": "PANTONE 18-1350 TPG",
                "name": "Burnt Brick",
                "book": 1,
                "page": 60,
                "row": 2,
                "hexCode": "#A2574C",
                "rgb": "162,87,76"
            },
            {
                "nr": "PANTONE 18-1438 TPG",
                "name": "Marsala",
                "book": 1,
                "page": 60,
                "row": 3,
                "hexCode": "#975958",
                "rgb": "151,89,88"
            },
            {
                "nr": "PANTONE 18-1531 TPG",
                "name": "Barn Red",
                "book": 1,
                "page": 60,
                "row": 4,
                "hexCode": "#8F4E4C",
                "rgb": "143,78,76"
            },
            {
                "nr": "PANTONE 18-1442 TPG",
                "name": "Red Ochre",
                "book": 1,
                "page": 60,
                "row": 5,
                "hexCode": "#914C48",
                "rgb": "145,76,72"
            },
            {
                "nr": "PANTONE 18-1440 TPG",
                "name": "Chili Oil",
                "book": 1,
                "page": 60,
                "row": 6,
                "hexCode": "#944743",
                "rgb": "147,71,66"
            },
            {
                "nr": "PANTONE 19-1540 TPG",
                "name": "Burnt Henna",
                "book": 1,
                "page": 60,
                "row": 7,
                "hexCode": "#824944",
                "rgb": "130,73,68"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1443 TPG",
                "name": "Redwood",
                "book": 1,
                "page": 61,
                "row": 1,
                "hexCode": "#A45F56",
                "rgb": "164,95,86"
            },
            {
                "nr": "PANTONE 18-1434 TPG",
                "name": "Etruscan Red",
                "book": 1,
                "page": 61,
                "row": 2,
                "hexCode": "#A15E57",
                "rgb": "161,94,87"
            },
            {
                "nr": "PANTONE 18-1441 TPG",
                "name": "Baked Clay",
                "book": 1,
                "page": 61,
                "row": 3,
                "hexCode": "#A15E4F",
                "rgb": "161,94,79"
            },
            {
                "nr": "PANTONE 18-1343 TPG",
                "name": "Auburn",
                "book": 1,
                "page": 61,
                "row": 4,
                "hexCode": "#9D6054",
                "rgb": "157,96,84"
            },
            {
                "nr": "PANTONE 19-1245 TPG",
                "name": "Arabian Spice",
                "book": 1,
                "page": 61,
                "row": 5,
                "hexCode": "#8B5147",
                "rgb": "139,81,71"
            },
            {
                "nr": "PANTONE 19-1333 TPG",
                "name": "Sequoia",
                "book": 1,
                "page": 61,
                "row": 6,
                "hexCode": "#825246",
                "rgb": "130,82,70"
            },
            {
                "nr": "PANTONE 19-1230 TPG",
                "name": "Friar Brown",
                "book": 1,
                "page": 61,
                "row": 7,
                "hexCode": "#725146",
                "rgb": "114,81,70"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1239 TPG",
                "name": "Sierra",
                "book": 1,
                "page": 62,
                "row": 1,
                "hexCode": "#9D654E",
                "rgb": "157,101,78"
            },
            {
                "nr": "PANTONE 18-1140 TPG",
                "name": "Mocha Bisque",
                "book": 1,
                "page": 62,
                "row": 2,
                "hexCode": "#8D5D4A",
                "rgb": "141,93,74"
            },
            {
                "nr": "PANTONE 17-1142 TPG",
                "name": "Argan Oil",
                "book": 1,
                "page": 62,
                "row": 3,
                "hexCode": "#91624D",
                "rgb": "145,98,77"
            },
            {
                "nr": "PANTONE 18-1242 TPG",
                "name": "Brown Patina",
                "book": 1,
                "page": 62,
                "row": 4,
                "hexCode": "#8A5B4B",
                "rgb": "138,91,75"
            },
            {
                "nr": "PANTONE 18-1230 TPG",
                "name": "Coconut Shell",
                "book": 1,
                "page": 62,
                "row": 5,
                "hexCode": "#865445",
                "rgb": "134,84,69"
            },
            {
                "nr": "PANTONE 18-1238 TPG",
                "name": "Rustic Brown",
                "book": 1,
                "page": 62,
                "row": 6,
                "hexCode": "#885A4D",
                "rgb": "136,90,77"
            },
            {
                "nr": "PANTONE 19-1241 TPG",
                "name": "Tortoise Shell",
                "book": 1,
                "page": 62,
                "row": 7,
                "hexCode": "#7C5446",
                "rgb": "124,84,70"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1422 TPG",
                "name": "Raw Umber",
                "book": 1,
                "page": 63,
                "row": 1,
                "hexCode": "#907263",
                "rgb": "144,114,99"
            },
            {
                "nr": "PANTONE 18-1320 TPG",
                "name": "Clove",
                "book": 1,
                "page": 63,
                "row": 2,
                "hexCode": "#866459",
                "rgb": "134,100,89"
            },
            {
                "nr": "PANTONE 18-1229 TPG",
                "name": "Carob Brown",
                "book": 1,
                "page": 63,
                "row": 3,
                "hexCode": "#866355",
                "rgb": "134,99,85"
            },
            {
                "nr": "PANTONE 18-1235 TPG",
                "name": "Russet",
                "book": 1,
                "page": 63,
                "row": 4,
                "hexCode": "#8E6457",
                "rgb": "142,100,87"
            },
            {
                "nr": "PANTONE 18-1421 TPG",
                "name": "Cognac",
                "book": 1,
                "page": 63,
                "row": 5,
                "hexCode": "#88665E",
                "rgb": "136,102,94"
            },
            {
                "nr": "PANTONE 18-1314 TPG",
                "name": "Acorn",
                "book": 1,
                "page": 63,
                "row": 6,
                "hexCode": "#7F665B",
                "rgb": "127,102,91"
            },
            {
                "nr": "PANTONE 18-1326 TPG",
                "name": "Nutmeg",
                "book": 1,
                "page": 63,
                "row": 7,
                "hexCode": "#7F615A",
                "rgb": "127,97,90"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1124 TPG",
                "name": "Partridge",
                "book": 1,
                "page": 64,
                "row": 1,
                "hexCode": "#735A4C",
                "rgb": "115,90,76"
            },
            {
                "nr": "PANTONE 18-1033 TPG",
                "name": "Dachshund",
                "book": 1,
                "page": 64,
                "row": 2,
                "hexCode": "#785C46",
                "rgb": "120,92,70"
            },
            {
                "nr": "PANTONE 18-1031 TPG",
                "name": "Toffee",
                "book": 1,
                "page": 64,
                "row": 3,
                "hexCode": "#755741",
                "rgb": "117,87,65"
            },
            {
                "nr": "PANTONE 18-1027 TPG",
                "name": "Bison",
                "book": 1,
                "page": 64,
                "row": 4,
                "hexCode": "#6B5445",
                "rgb": "107,84,69"
            },
            {
                "nr": "PANTONE 18-1222 TPG",
                "name": "Cocoa Brown",
                "book": 1,
                "page": 64,
                "row": 5,
                "hexCode": "#70584C",
                "rgb": "112,88,76"
            },
            {
                "nr": "PANTONE 19-1121 TPG",
                "name": "Pinecone",
                "book": 1,
                "page": 64,
                "row": 6,
                "hexCode": "#645148",
                "rgb": "100,81,72"
            },
            {
                "nr": "PANTONE 19-1116 TPG",
                "name": "Carafe",
                "book": 1,
                "page": 64,
                "row": 7,
                "hexCode": "#5C4D43",
                "rgb": "92,77,67"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1019 TPG",
                "name": "Coca Mocha",
                "book": 1,
                "page": 65,
                "row": 1,
                "hexCode": "#8C725F",
                "rgb": "140,114,95"
            },
            {
                "nr": "PANTONE 18-1028 TPG",
                "name": "Emperador",
                "book": 1,
                "page": 65,
                "row": 2,
                "hexCode": "#6C4F3D",
                "rgb": "108,79,60"
            },
            {
                "nr": "PANTONE 18-1307 TPG",
                "name": "Coffee Quartz",
                "book": 1,
                "page": 65,
                "row": 3,
                "hexCode": "#6D5D58",
                "rgb": "109,93,88"
            },
            {
                "nr": "PANTONE 19-1119 TPG",
                "name": "Cocoa",
                "book": 1,
                "page": 65,
                "row": 4,
                "hexCode": "#614F43",
                "rgb": "97,79,67"
            },
            {
                "nr": "PANTONE 19-1223 TPG",
                "name": "Downtown Brown",
                "book": 1,
                "page": 65,
                "row": 5,
                "hexCode": "#684B41",
                "rgb": "104,75,65"
            },
            {
                "nr": "PANTONE 19-1216 TPG",
                "name": "Chocolate Martini",
                "book": 1,
                "page": 65,
                "row": 6,
                "hexCode": "#5D4E4B",
                "rgb": "93,78,75"
            },
            {
                "nr": "PANTONE 19-0913 TPG",
                "name": "Hot Fudge",
                "book": 1,
                "page": 65,
                "row": 7,
                "hexCode": "#544743",
                "rgb": "84,71,67"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1322 TPG",
                "name": "Burro",
                "book": 1,
                "page": 66,
                "row": 1,
                "hexCode": "#9F816B",
                "rgb": "159,129,107"
            },
            {
                "nr": "PANTONE 17-1044 TPG",
                "name": "Chipmunk",
                "book": 1,
                "page": 66,
                "row": 2,
                "hexCode": "#967151",
                "rgb": "150,113,81"
            },
            {
                "nr": "PANTONE 17-1327 TPG",
                "name": "Tobacco Brown",
                "book": 1,
                "page": 66,
                "row": 3,
                "hexCode": "#967659",
                "rgb": "150,118,89"
            },
            {
                "nr": "PANTONE 18-1030 TPG",
                "name": "Thrush",
                "book": 1,
                "page": 66,
                "row": 4,
                "hexCode": "#906F5A",
                "rgb": "144,111,90"
            },
            {
                "nr": "PANTONE 18-1024 TPG",
                "name": "Nuthatch",
                "book": 1,
                "page": 66,
                "row": 5,
                "hexCode": "#88644B",
                "rgb": "136,100,75"
            },
            {
                "nr": "PANTONE 18-1137 TPG",
                "name": "Rawhide",
                "book": 1,
                "page": 66,
                "row": 6,
                "hexCode": "#866351",
                "rgb": "134,99,81"
            },
            {
                "nr": "PANTONE 19-1227 TPG",
                "name": "Sorrel Horse",
                "book": 1,
                "page": 66,
                "row": 7,
                "hexCode": "#704D3E",
                "rgb": "112,77,62"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1324 TPG",
                "name": "Cinnamon Swirl",
                "book": 1,
                "page": 67,
                "row": 1,
                "hexCode": "#A08068",
                "rgb": "160,128,104"
            },
            {
                "nr": "PANTONE 17-1318 TPG",
                "name": "Affogat",
                "book": 1,
                "page": 67,
                "row": 2,
                "hexCode": "#A78C79",
                "rgb": "167,140,121"
            },
            {
                "nr": "PANTONE 17-1317 TPG",
                "name": "Sinopia Fresco",
                "book": 1,
                "page": 67,
                "row": 3,
                "hexCode": "#96796C",
                "rgb": "150,121,108"
            },
            {
                "nr": "PANTONE 18-1324 TPG",
                "name": "Chocolate Malt",
                "book": 1,
                "page": 67,
                "row": 4,
                "hexCode": "#927567",
                "rgb": "146,117,103"
            },
            {
                "nr": "PANTONE 18-1125 TPG",
                "name": "Pecan Pie",
                "book": 1,
                "page": 67,
                "row": 5,
                "hexCode": "#7F5F50",
                "rgb": "127,95,80"
            },
            {
                "nr": "PANTONE 18-1130 TPG",
                "name": "Aztec",
                "book": 1,
                "page": 67,
                "row": 6,
                "hexCode": "#785B4F",
                "rgb": "120,91,79"
            },
            {
                "nr": "PANTONE 18-1232 TPG",
                "name": "Soft Silt",
                "book": 1,
                "page": 67,
                "row": 7,
                "hexCode": "#6F5346",
                "rgb": "111,83,70"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1223 TPG",
                "name": "Praline",
                "book": 1,
                "page": 68,
                "row": 1,
                "hexCode": "#A98A74",
                "rgb": "169,138,116"
            },
            {
                "nr": "PANTONE 16-1323 TPG",
                "name": "Macaroon",
                "book": 1,
                "page": 68,
                "row": 2,
                "hexCode": "#B08A73",
                "rgb": "176,138,115"
            },
            {
                "nr": "PANTONE 17-1225 TPG",
                "name": "Tawny Birch",
                "book": 1,
                "page": 68,
                "row": 3,
                "hexCode": "#AF8870",
                "rgb": "175,136,112"
            },
            {
                "nr": "PANTONE 17-1226 TPG",
                "name": "Tawny Brown",
                "book": 1,
                "page": 68,
                "row": 4,
                "hexCode": "#A8846E",
                "rgb": "168,132,110"
            },
            {
                "nr": "PANTONE 17-1328 TPG",
                "name": "Indian Tan",
                "book": 1,
                "page": 68,
                "row": 5,
                "hexCode": "#AD8765",
                "rgb": "173,135,101"
            },
            {
                "nr": "PANTONE 17-1221 TPG",
                "name": "Macchiato",
                "book": 1,
                "page": 68,
                "row": 6,
                "hexCode": "#AF876D",
                "rgb": "175,135,109"
            },
            {
                "nr": "PANTONE 17-1330 TPG",
                "name": "Lion",
                "book": 1,
                "page": 68,
                "row": 7,
                "hexCode": "#A07759",
                "rgb": "160,119,89"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1317 TPG",
                "name": "Brush",
                "book": 1,
                "page": 69,
                "row": 1,
                "hexCode": "#B79B86",
                "rgb": "183,155,134"
            },
            {
                "nr": "PANTONE 16-1320 TPG",
                "name": "Nougat",
                "book": 1,
                "page": 69,
                "row": 2,
                "hexCode": "#B7A088",
                "rgb": "183,160,136"
            },
            {
                "nr": "PANTONE 16-1221 TPG",
                "name": "Roebuck",
                "book": 1,
                "page": 69,
                "row": 3,
                "hexCode": "#B39480",
                "rgb": "179,148,128"
            },
            {
                "nr": "PANTONE 17-1224 TPG",
                "name": "Camel",
                "book": 1,
                "page": 69,
                "row": 4,
                "hexCode": "#AD836A",
                "rgb": "173,131,106"
            },
            {
                "nr": "PANTONE 17-1227 TPG",
                "name": "Café Au Lait",
                "book": 1,
                "page": 69,
                "row": 5,
                "hexCode": "#A9836E",
                "rgb": "169,131,110"
            },
            {
                "nr": "PANTONE 17-1230 TPG",
                "name": "Mocha Mousse",
                "book": 1,
                "page": 69,
                "row": 6,
                "hexCode": "#A37A67",
                "rgb": "163,122,103"
            },
            {
                "nr": "PANTONE 18-1422 TPG",
                "name": "Myristica",
                "book": 1,
                "page": 69,
                "row": 7,
                "hexCode": "#89685F",
                "rgb": "137,104,95"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1108 TPG",
                "name": "Cream Tan",
                "book": 1,
                "page": 70,
                "row": 1,
                "hexCode": "#E2C6B3",
                "rgb": "226,198,179"
            },
            {
                "nr": "PANTONE 14-1314 TPG",
                "name": "Spanish Villa",
                "book": 1,
                "page": 70,
                "row": 2,
                "hexCode": "#E3BDA9",
                "rgb": "227,189,169"
            },
            {
                "nr": "PANTONE 15-1317 TPG",
                "name": "Sirocco",
                "book": 1,
                "page": 70,
                "row": 3,
                "hexCode": "#C19E86",
                "rgb": "193,158,134"
            },
            {
                "nr": "PANTONE 15-1316 TPG",
                "name": "Maple Sugar",
                "book": 1,
                "page": 70,
                "row": 4,
                "hexCode": "#C9A58F",
                "rgb": "201,165,143"
            },
            {
                "nr": "PANTONE 16-1219 TPG",
                "name": "Tuscany",
                "book": 1,
                "page": 70,
                "row": 5,
                "hexCode": "#C09A87",
                "rgb": "192,154,135"
            },
            {
                "nr": "PANTONE 16-1220 TPG",
                "name": "Café Crème",
                "book": 1,
                "page": 70,
                "row": 6,
                "hexCode": "#C39782",
                "rgb": "195,151,130"
            },
            {
                "nr": "PANTONE 16-1422 TPG",
                "name": "Cork",
                "book": 1,
                "page": 70,
                "row": 7,
                "hexCode": "#B78975",
                "rgb": "183,137,117"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1405 TPG",
                "name": "Shell",
                "book": 1,
                "page": 71,
                "row": 1,
                "hexCode": "#E6D4C8",
                "rgb": "230,212,200"
            },
            {
                "nr": "PANTONE 12-1206 TPG",
                "name": "Silver Peony",
                "book": 1,
                "page": 71,
                "row": 2,
                "hexCode": "#E9D2C6",
                "rgb": "233,210,198"
            },
            {
                "nr": "PANTONE 14-1309 TPG",
                "name": "Peach Whip",
                "book": 1,
                "page": 71,
                "row": 3,
                "hexCode": "#DDBFB9",
                "rgb": "221,191,184"
            },
            {
                "nr": "PANTONE 14-1310 TPG",
                "name": "Cameo Rose",
                "book": 1,
                "page": 71,
                "row": 4,
                "hexCode": "#D6B6A8",
                "rgb": "214,182,168"
            },
            {
                "nr": "PANTONE 14-1307 TPG",
                "name": "Rose Dust",
                "book": 1,
                "page": 71,
                "row": 5,
                "hexCode": "#CEB3A5",
                "rgb": "206,179,165"
            },
            {
                "nr": "PANTONE 15-1315 TPG",
                "name": "Rugby Tan",
                "book": 1,
                "page": 71,
                "row": 6,
                "hexCode": "#C6A893",
                "rgb": "198,168,147"
            },
            {
                "nr": "PANTONE 15-1511 TPG",
                "name": "Mahogany Rose",
                "book": 1,
                "page": 71,
                "row": 7,
                "hexCode": "#C3A292",
                "rgb": "194,162,146"
            }
        ],
        [
            {
                "nr": "PANTONE 11-1006 TPG",
                "name": "Slightly Pink",
                "book": 1,
                "page": 72,
                "row": 1,
                "hexCode": "#EDE6E2",
                "rgb": "237,230,226"
            },
            {
                "nr": "PANTONE 11-1007 TPG",
                "name": "Coconut Cream",
                "book": 1,
                "page": 72,
                "row": 2,
                "hexCode": "#EFE3DF",
                "rgb": "239,227,223"
            },
            {
                "nr": "PANTONE 11-2309 TPG",
                "name": "Petal Pink",
                "book": 1,
                "page": 72,
                "row": 3,
                "hexCode": "#F4E5E2",
                "rgb": "244,229,226"
            },
            {
                "nr": "PANTONE 12-2103 TPG",
                "name": "Almost Mauve",
                "book": 1,
                "page": 72,
                "row": 4,
                "hexCode": "#EADEDB",
                "rgb": "234,222,219"
            },
            {
                "nr": "PANTONE 11-0603 TPG",
                "name": "Pastel Parchment",
                "book": 1,
                "page": 72,
                "row": 5,
                "hexCode": "#E4DBD5",
                "rgb": "228,219,213"
            },
            {
                "nr": "PANTONE 12-1105 TPG",
                "name": "Salsa Rosa",
                "book": 1,
                "page": 72,
                "row": 6,
                "hexCode": "#F1DDD3",
                "rgb": "241,221,211"
            },
            {
                "nr": "PANTONE 11-1305 TPG",
                "name": "Angel Wing",
                "book": 1,
                "page": 72,
                "row": 7,
                "hexCode": "#F2E0D7",
                "rgb": "242,224,215"
            }
        ],
        [
            {
                "nr": "PANTONE 11-1302 TPG",
                "name": "Sea Salt",
                "book": 1,
                "page": 73,
                "row": 1,
                "hexCode": "#F1E9E0",
                "rgb": "240,232,224"
            },
            {
                "nr": "PANTONE 12-1106 TPG",
                "name": "Sheer Pink",
                "book": 1,
                "page": 73,
                "row": 2,
                "hexCode": "#F2E7DB",
                "rgb": "242,231,219"
            },
            {
                "nr": "PANTONE 11-1306 TPG",
                "name": "Cream Pink",
                "book": 1,
                "page": 73,
                "row": 3,
                "hexCode": "#F1E1D8",
                "rgb": "241,225,216"
            },
            {
                "nr": "PANTONE 11-1005 TPG",
                "name": "Bridal Blush",
                "book": 1,
                "page": 73,
                "row": 4,
                "hexCode": "#EDE4DE",
                "rgb": "237,228,222"
            },
            {
                "nr": "PANTONE 12-1108 TPG",
                "name": "Dew",
                "book": 1,
                "page": 73,
                "row": 5,
                "hexCode": "#EFE1D3",
                "rgb": "239,225,211"
            },
            {
                "nr": "PANTONE 12-2901 TPG",
                "name": "Morganite",
                "book": 1,
                "page": 73,
                "row": 6,
                "hexCode": "#E6D2C9",
                "rgb": "230,210,201"
            },
            {
                "nr": "PANTONE 13-1504 TPG",
                "name": "Peach Blush",
                "book": 1,
                "page": 73,
                "row": 7,
                "hexCode": "#E6CDC5",
                "rgb": "230,205,197"
            }
        ],
        [
            {
                "nr": "PANTONE 11-1404 TPG",
                "name": "Powder Puff",
                "book": 1,
                "page": 74,
                "row": 1,
                "hexCode": "#F2E6DC",
                "rgb": "242,230,220"
            },
            {
                "nr": "PANTONE 12-1107 TPG",
                "name": "Peach Dust",
                "book": 1,
                "page": 74,
                "row": 2,
                "hexCode": "#F1DACC",
                "rgb": "241,218,204"
            },
            {
                "nr": "PANTONE 12-1209 TPG",
                "name": "Soft Pink",
                "book": 1,
                "page": 74,
                "row": 3,
                "hexCode": "#F1D9CC",
                "rgb": "241,217,204"
            },
            {
                "nr": "PANTONE 12-1110 TPG",
                "name": "Crème De Pêche",
                "book": 1,
                "page": 74,
                "row": 4,
                "hexCode": "#F8DBC9",
                "rgb": "248,219,201"
            },
            {
                "nr": "PANTONE 12-1010 TPG",
                "name": "Scallop Shell",
                "book": 1,
                "page": 74,
                "row": 5,
                "hexCode": "#F9D5C5",
                "rgb": "249,213,197"
            },
            {
                "nr": "PANTONE 13-1404 TPG",
                "name": "Pale Dogwood",
                "book": 1,
                "page": 74,
                "row": 6,
                "hexCode": "#EFCFC2",
                "rgb": "239,207,194"
            },
            {
                "nr": "PANTONE 12-1207 TPG",
                "name": "Pearl Blush",
                "book": 1,
                "page": 74,
                "row": 7,
                "hexCode": "#F2CEC5",
                "rgb": "241,206,196"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1406 TPG",
                "name": "Cloud Pink",
                "book": 1,
                "page": 75,
                "row": 1,
                "hexCode": "#EED1C7",
                "rgb": "238,208,198"
            },
            {
                "nr": "PANTONE 13-1407 TPG",
                "name": "Creole Pink",
                "book": 1,
                "page": 75,
                "row": 2,
                "hexCode": "#F5D5CD",
                "rgb": "244,212,204"
            },
            {
                "nr": "PANTONE 13-1208 TPG",
                "name": "Peachy Keen",
                "book": 1,
                "page": 75,
                "row": 3,
                "hexCode": "#E7BFB4",
                "rgb": "230,191,180"
            },
            {
                "nr": "PANTONE 14-1312 TPG",
                "name": "Pale Blush",
                "book": 1,
                "page": 75,
                "row": 4,
                "hexCode": "#E4BCB0",
                "rgb": "228,188,176"
            },
            {
                "nr": "PANTONE 14-1311 TPG",
                "name": "Evening Sand",
                "book": 1,
                "page": 75,
                "row": 5,
                "hexCode": "#DEB7AA",
                "rgb": "222,183,170"
            },
            {
                "nr": "PANTONE 14-1313 TPG",
                "name": "Rose Cloud",
                "book": 1,
                "page": 75,
                "row": 6,
                "hexCode": "#DDB3A2",
                "rgb": "221,179,162"
            },
            {
                "nr": "PANTONE 14-1316 TPG",
                "name": "Dusty Pink",
                "book": 1,
                "page": 75,
                "row": 7,
                "hexCode": "#E0AD9D",
                "rgb": "224,173,157"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1109 TPG",
                "name": "Bisque",
                "book": 1,
                "page": 76,
                "row": 1,
                "hexCode": "#EAC6AF",
                "rgb": "234,198,175"
            },
            {
                "nr": "PANTONE 13-1114 TPG",
                "name": "Bellini",
                "book": 1,
                "page": 76,
                "row": 2,
                "hexCode": "#F4C7AB",
                "rgb": "244,199,171"
            },
            {
                "nr": "PANTONE 15-1319 TPG",
                "name": "Almost Apricot",
                "book": 1,
                "page": 76,
                "row": 3,
                "hexCode": "#E5B49A",
                "rgb": "229,180,154"
            },
            {
                "nr": "PANTONE 14-1220 TPG",
                "name": "Peach Nougat",
                "book": 1,
                "page": 76,
                "row": 4,
                "hexCode": "#E6AF8F",
                "rgb": "230,175,143"
            },
            {
                "nr": "PANTONE 15-1318 TPG",
                "name": "Pink Sand",
                "book": 1,
                "page": 76,
                "row": 5,
                "hexCode": "#DDAD94",
                "rgb": "221,173,148"
            },
            {
                "nr": "PANTONE 15-1322 TPG",
                "name": "Dusty Coral",
                "book": 1,
                "page": 76,
                "row": 6,
                "hexCode": "#D09C83",
                "rgb": "208,156,131"
            },
            {
                "nr": "PANTONE 16-1330 TPG",
                "name": "Muted Clay",
                "book": 1,
                "page": 76,
                "row": 7,
                "hexCode": "#D09686",
                "rgb": "208,150,134"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0813 TPG",
                "name": "Autumn Blonde",
                "book": 1,
                "page": 77,
                "row": 1,
                "hexCode": "#F2D5B3",
                "rgb": "242,213,179"
            },
            {
                "nr": "PANTONE 13-1024 TPG",
                "name": "Buff",
                "book": 1,
                "page": 77,
                "row": 2,
                "hexCode": "#EBC494",
                "rgb": "235,196,148"
            },
            {
                "nr": "PANTONE 13-1018 TPG",
                "name": "Desert Dust",
                "book": 1,
                "page": 77,
                "row": 3,
                "hexCode": "#E2BC8F",
                "rgb": "226,188,143"
            },
            {
                "nr": "PANTONE 12-0921 TPG",
                "name": "Golden Straw",
                "book": 1,
                "page": 77,
                "row": 4,
                "hexCode": "#E5BC8E",
                "rgb": "229,188,142"
            },
            {
                "nr": "PANTONE 14-1127 TPG",
                "name": "Desert Mist",
                "book": 1,
                "page": 77,
                "row": 5,
                "hexCode": "#E0B488",
                "rgb": "224,180,136"
            },
            {
                "nr": "PANTONE 14-1122 TPG",
                "name": "Sheepskin",
                "book": 1,
                "page": 77,
                "row": 6,
                "hexCode": "#DBB68F",
                "rgb": "219,182,143"
            },
            {
                "nr": "PANTONE 16-1333 TPG",
                "name": "Doe",
                "book": 1,
                "page": 77,
                "row": 7,
                "hexCode": "#B9926B",
                "rgb": "185,146,107"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1125 TPG",
                "name": "Porcini",
                "book": 1,
                "page": 78,
                "row": 1,
                "hexCode": "#D0A783",
                "rgb": "207,167,131"
            },
            {
                "nr": "PANTONE 15-1220 TPG",
                "name": "Lattè",
                "book": 1,
                "page": 78,
                "row": 2,
                "hexCode": "#C4A881",
                "rgb": "196,168,129"
            },
            {
                "nr": "PANTONE 15-1231 TPG",
                "name": "Clay",
                "book": 1,
                "page": 78,
                "row": 3,
                "hexCode": "#D5A475",
                "rgb": "213,164,117"
            },
            {
                "nr": "PANTONE 16-1341 TPG",
                "name": "Butterum",
                "book": 1,
                "page": 78,
                "row": 4,
                "hexCode": "#C39069",
                "rgb": "195,144,105"
            },
            {
                "nr": "PANTONE 15-1425 TPG",
                "name": "Fenugreek",
                "book": 1,
                "page": 78,
                "row": 5,
                "hexCode": "#C4936F",
                "rgb": "195,147,110"
            },
            {
                "nr": "PANTONE 15-1430 TPG",
                "name": "Pastry Shell",
                "book": 1,
                "page": 78,
                "row": 6,
                "hexCode": "#C08E68",
                "rgb": "191,142,103"
            },
            {
                "nr": "PANTONE 16-1438 TPG",
                "name": "Meerkat",
                "book": 1,
                "page": 78,
                "row": 7,
                "hexCode": "#A9754F",
                "rgb": "169,116,79"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1225 TPG",
                "name": "Sand",
                "book": 1,
                "page": 79,
                "row": 1,
                "hexCode": "#CCAA81",
                "rgb": "204,170,129"
            },
            {
                "nr": "PANTONE 16-1331 TPG",
                "name": "Toast",
                "book": 1,
                "page": 79,
                "row": 2,
                "hexCode": "#CA9B79",
                "rgb": "202,155,121"
            },
            {
                "nr": "PANTONE 16-1336 TPG",
                "name": "Biscuit",
                "book": 1,
                "page": 79,
                "row": 3,
                "hexCode": "#B4865F",
                "rgb": "180,134,95"
            },
            {
                "nr": "PANTONE 16-1235 TPG",
                "name": "Sandstorm",
                "book": 1,
                "page": 79,
                "row": 4,
                "hexCode": "#BB8B69",
                "rgb": "187,139,105"
            },
            {
                "nr": "PANTONE 16-1432 TPG",
                "name": "Almond",
                "book": 1,
                "page": 79,
                "row": 5,
                "hexCode": "#A97954",
                "rgb": "169,121,84"
            },
            {
                "nr": "PANTONE 17-1134 TPG",
                "name": "Brown Sugar",
                "book": 1,
                "page": 79,
                "row": 6,
                "hexCode": "#9F7855",
                "rgb": "159,120,85"
            },
            {
                "nr": "PANTONE 17-1137 TPG",
                "name": "Cashew",
                "book": 1,
                "page": 79,
                "row": 7,
                "hexCode": "#A67650",
                "rgb": "166,118,80"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1336 TPG",
                "name": "Bran",
                "book": 1,
                "page": 80,
                "row": 1,
                "hexCode": "#A87351",
                "rgb": "168,115,81"
            },
            {
                "nr": "PANTONE 17-1340 TPG",
                "name": "Adobe",
                "book": 1,
                "page": 80,
                "row": 2,
                "hexCode": "#A66847",
                "rgb": "166,104,71"
            },
            {
                "nr": "PANTONE 18-1154 TPG",
                "name": "Glazed Ginger",
                "book": 1,
                "page": 80,
                "row": 3,
                "hexCode": "#935F3D",
                "rgb": "147,95,61"
            },
            {
                "nr": "PANTONE 17-1052 TPG",
                "name": "Roasted Pecan",
                "book": 1,
                "page": 80,
                "row": 4,
                "hexCode": "#99623A",
                "rgb": "153,97,57"
            },
            {
                "nr": "PANTONE 18-1155 TPG",
                "name": "Sugar Almond",
                "book": 1,
                "page": 80,
                "row": 5,
                "hexCode": "#935D3B",
                "rgb": "147,92,59"
            },
            {
                "nr": "PANTONE 18-1142 TPG",
                "name": "Leather Brown",
                "book": 1,
                "page": 80,
                "row": 6,
                "hexCode": "#965D3E",
                "rgb": "150,93,62"
            },
            {
                "nr": "PANTONE 18-1148 TPG",
                "name": "Caramel Café",
                "book": 1,
                "page": 80,
                "row": 7,
                "hexCode": "#8A593D",
                "rgb": "138,89,61"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1327 TPG",
                "name": "Peach Bloom",
                "book": 1,
                "page": 81,
                "row": 1,
                "hexCode": "#DC9C7C",
                "rgb": "220,156,124"
            },
            {
                "nr": "PANTONE 16-1327 TPG",
                "name": "Toasted Nut",
                "book": 1,
                "page": 81,
                "row": 2,
                "hexCode": "#BA8569",
                "rgb": "186,133,105"
            },
            {
                "nr": "PANTONE 16-1332 TPG",
                "name": "Pheasant",
                "book": 1,
                "page": 81,
                "row": 3,
                "hexCode": "#C68567",
                "rgb": "198,133,103"
            },
            {
                "nr": "PANTONE 17-1430 TPG",
                "name": "Pecan Brown",
                "book": 1,
                "page": 81,
                "row": 4,
                "hexCode": "#A57459",
                "rgb": "165,116,89"
            },
            {
                "nr": "PANTONE 16-1429 TPG",
                "name": "Sunburn",
                "book": 1,
                "page": 81,
                "row": 5,
                "hexCode": "#B27359",
                "rgb": "178,115,89"
            },
            {
                "nr": "PANTONE 17-1147 TPG",
                "name": "Amber Brown",
                "book": 1,
                "page": 81,
                "row": 6,
                "hexCode": "#A66C53",
                "rgb": "166,108,83"
            },
            {
                "nr": "PANTONE 17-1143 TPG",
                "name": "Hazel",
                "book": 1,
                "page": 81,
                "row": 7,
                "hexCode": "#AE7756",
                "rgb": "174,119,86"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1328 TPG",
                "name": "Sandstone",
                "book": 1,
                "page": 82,
                "row": 1,
                "hexCode": "#C78E6F",
                "rgb": "199,142,111"
            },
            {
                "nr": "PANTONE 16-1439 TPG",
                "name": "Caramel",
                "book": 1,
                "page": 82,
                "row": 2,
                "hexCode": "#BD7A58",
                "rgb": "189,122,88"
            },
            {
                "nr": "PANTONE 17-1436 TPG",
                "name": "Raw Sienna",
                "book": 1,
                "page": 82,
                "row": 3,
                "hexCode": "#B9735A",
                "rgb": "185,115,90"
            },
            {
                "nr": "PANTONE 17-1347 TPG",
                "name": "Autumn Leaf",
                "book": 1,
                "page": 82,
                "row": 4,
                "hexCode": "#B16A53",
                "rgb": "177,106,83"
            },
            {
                "nr": "PANTONE 18-1250 TPG",
                "name": "Bombay Brown",
                "book": 1,
                "page": 82,
                "row": 5,
                "hexCode": "#A1593F",
                "rgb": "161,89,63"
            },
            {
                "nr": "PANTONE 17-1342 TPG",
                "name": "Autumnal",
                "book": 1,
                "page": 82,
                "row": 6,
                "hexCode": "#A45B3A",
                "rgb": "164,91,58"
            },
            {
                "nr": "PANTONE 18-1246 TPG",
                "name": "Umber",
                "book": 1,
                "page": 82,
                "row": 7,
                "hexCode": "#9B5839",
                "rgb": "155,88,57"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1450 TPG",
                "name": "Mecca Orange",
                "book": 1,
                "page": 83,
                "row": 1,
                "hexCode": "#BC624F",
                "rgb": "188,98,79"
            },
            {
                "nr": "PANTONE 18-1451 TPG",
                "name": "Autumn Glaze",
                "book": 1,
                "page": 83,
                "row": 2,
                "hexCode": "#B16050",
                "rgb": "177,96,80"
            },
            {
                "nr": "PANTONE 18-1340 TPG",
                "name": "Potter's Clay",
                "book": 1,
                "page": 83,
                "row": 3,
                "hexCode": "#A55239",
                "rgb": "165,82,57"
            },
            {
                "nr": "PANTONE 18-1345 TPG",
                "name": "Cinnamon Stick",
                "book": 1,
                "page": 83,
                "row": 4,
                "hexCode": "#A2553A",
                "rgb": "162,85,58"
            },
            {
                "nr": "PANTONE 18-1355 TPG",
                "name": "Rooibos Tea",
                "book": 1,
                "page": 83,
                "row": 5,
                "hexCode": "#AA4C39",
                "rgb": "170,76,57"
            },
            {
                "nr": "PANTONE 19-1250 TPG",
                "name": "Picante",
                "book": 1,
                "page": 83,
                "row": 6,
                "hexCode": "#914A3D",
                "rgb": "145,74,61"
            },
            {
                "nr": "PANTONE 18-1244 TPG",
                "name": "Ginger Bread",
                "book": 1,
                "page": 83,
                "row": 7,
                "hexCode": "#8C5640",
                "rgb": "140,86,64"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1540 TPG",
                "name": "Apricot Brandy",
                "book": 1,
                "page": 84,
                "row": 1,
                "hexCode": "#C16D5D",
                "rgb": "193,109,93"
            },
            {
                "nr": "PANTONE 17-1532 TPG",
                "name": "Aragon",
                "book": 1,
                "page": 84,
                "row": 2,
                "hexCode": "#AE6C5F",
                "rgb": "174,108,95"
            },
            {
                "nr": "PANTONE 18-1535 TPG",
                "name": "Ginger Spice",
                "book": 1,
                "page": 84,
                "row": 3,
                "hexCode": "#B86758",
                "rgb": "184,103,88"
            },
            {
                "nr": "PANTONE 18-1444 TPG",
                "name": "Tandoori Spice",
                "book": 1,
                "page": 84,
                "row": 4,
                "hexCode": "#9F524E",
                "rgb": "159,82,78"
            },
            {
                "nr": "PANTONE 18-1540 TPG",
                "name": "Cinnabar",
                "book": 1,
                "page": 84,
                "row": 5,
                "hexCode": "#9C504C",
                "rgb": "156,80,76"
            },
            {
                "nr": "PANTONE 18-1547 TPG",
                "name": "Bossa Nova",
                "book": 1,
                "page": 84,
                "row": 6,
                "hexCode": "#983F3A",
                "rgb": "152,63,58"
            },
            {
                "nr": "PANTONE 18-1449 TPG",
                "name": "Ketchup",
                "book": 1,
                "page": 84,
                "row": 7,
                "hexCode": "#974A45",
                "rgb": "151,74,69"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1520 TPG",
                "name": "Lobster Bisque",
                "book": 1,
                "page": 85,
                "row": 1,
                "hexCode": "#DD938E",
                "rgb": "221,147,142"
            },
            {
                "nr": "PANTONE 16-1329 TPG",
                "name": "Coral Haze",
                "book": 1,
                "page": 85,
                "row": 2,
                "hexCode": "#E29281",
                "rgb": "226,146,129"
            },
            {
                "nr": "PANTONE 16-1431 TPG",
                "name": "Canyon Clay",
                "book": 1,
                "page": 85,
                "row": 3,
                "hexCode": "#CD8478",
                "rgb": "205,132,120"
            },
            {
                "nr": "PANTONE 16-1624 TPG",
                "name": "Lantana",
                "book": 1,
                "page": 85,
                "row": 4,
                "hexCode": "#D87D7A",
                "rgb": "216,125,122"
            },
            {
                "nr": "PANTONE 18-1629 TPG",
                "name": "Faded Rose",
                "book": 1,
                "page": 85,
                "row": 5,
                "hexCode": "#BB6869",
                "rgb": "187,104,105"
            },
            {
                "nr": "PANTONE 18-1630 TPG",
                "name": "Dusty Cedar",
                "book": 1,
                "page": 85,
                "row": 6,
                "hexCode": "#AC6364",
                "rgb": "172,99,100"
            },
            {
                "nr": "PANTONE 18-1648 TPG",
                "name": "Baked Apple",
                "book": 1,
                "page": 85,
                "row": 7,
                "hexCode": "#B04D51",
                "rgb": "176,77,81"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1318 TPG",
                "name": "Coral Pink",
                "book": 1,
                "page": 86,
                "row": 1,
                "hexCode": "#E9AC9D",
                "rgb": "233,172,157"
            },
            {
                "nr": "PANTONE 15-1523 TPG",
                "name": "Shrimp",
                "book": 1,
                "page": 86,
                "row": 2,
                "hexCode": "#DF9B8A",
                "rgb": "223,155,138"
            },
            {
                "nr": "PANTONE 17-1341 TPG",
                "name": "Tawny Orange",
                "book": 1,
                "page": 86,
                "row": 3,
                "hexCode": "#CD8471",
                "rgb": "205,132,113"
            },
            {
                "nr": "PANTONE 16-1532 TPG",
                "name": "Crabapple",
                "book": 1,
                "page": 86,
                "row": 4,
                "hexCode": "#D57B6F",
                "rgb": "213,123,111"
            },
            {
                "nr": "PANTONE 16-1526 TPG",
                "name": "Terra Cotta",
                "book": 1,
                "page": 86,
                "row": 5,
                "hexCode": "#CE8279",
                "rgb": "206,130,121"
            },
            {
                "nr": "PANTONE 17-1544 TPG",
                "name": "Burnt Sienna",
                "book": 1,
                "page": 86,
                "row": 6,
                "hexCode": "#C36157",
                "rgb": "195,97,87"
            },
            {
                "nr": "PANTONE 18-1536 TPG",
                "name": "Hot Sauce",
                "book": 1,
                "page": 86,
                "row": 7,
                "hexCode": "#AC5B53",
                "rgb": "172,91,83"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1333 TPG",
                "name": "Canyon Sunset",
                "book": 1,
                "page": 87,
                "row": 1,
                "hexCode": "#E2947A",
                "rgb": "226,148,122"
            },
            {
                "nr": "PANTONE 16-1340 TPG",
                "name": "Brandied Melon",
                "book": 1,
                "page": 87,
                "row": 2,
                "hexCode": "#D08364",
                "rgb": "208,131,100"
            },
            {
                "nr": "PANTONE 16-1435 TPG",
                "name": "Carnelian",
                "book": 1,
                "page": 87,
                "row": 3,
                "hexCode": "#D07C62",
                "rgb": "208,124,98"
            },
            {
                "nr": "PANTONE 15-1429 TPG",
                "name": "Dusted Clay",
                "book": 1,
                "page": 87,
                "row": 4,
                "hexCode": "#D07B61",
                "rgb": "207,122,97"
            },
            {
                "nr": "PANTONE 16-1441 TPG",
                "name": "Arabesque",
                "book": 1,
                "page": 87,
                "row": 5,
                "hexCode": "#D07057",
                "rgb": "208,112,87"
            },
            {
                "nr": "PANTONE 16-1440 TPG",
                "name": "Langoustino",
                "book": 1,
                "page": 87,
                "row": 6,
                "hexCode": "#C66B59",
                "rgb": "198,107,89"
            },
            {
                "nr": "PANTONE 17-1446 TPG",
                "name": "Mango",
                "book": 1,
                "page": 87,
                "row": 7,
                "hexCode": "#B66351",
                "rgb": "182,99,81"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1520 TPG",
                "name": "Blooming Dahlia",
                "book": 1,
                "page": 88,
                "row": 1,
                "hexCode": "#EC9787",
                "rgb": "235,150,135"
            },
            {
                "nr": "PANTONE 16-1529 TPG",
                "name": "Burnt Coral",
                "book": 1,
                "page": 88,
                "row": 2,
                "hexCode": "#EC8A82",
                "rgb": "236,138,130"
            },
            {
                "nr": "PANTONE 16-1345 TPG",
                "name": "Sun Baked",
                "book": 1,
                "page": 88,
                "row": 3,
                "hexCode": "#D6866A",
                "rgb": "214,133,106"
            },
            {
                "nr": "PANTONE 17-1345 TPG",
                "name": "Spice Route",
                "book": 1,
                "page": 88,
                "row": 4,
                "hexCode": "#BE624C",
                "rgb": "190,98,75"
            },
            {
                "nr": "PANTONE 17-1444 TPG",
                "name": "Ginger",
                "book": 1,
                "page": 88,
                "row": 5,
                "hexCode": "#C76555",
                "rgb": "199,101,85"
            },
            {
                "nr": "PANTONE 16-1450 TPG",
                "name": "Flamingo",
                "book": 1,
                "page": 88,
                "row": 6,
                "hexCode": "#DF7158",
                "rgb": "223,113,88"
            },
            {
                "nr": "PANTONE 18-1354 TPG",
                "name": "Burnt Ochre",
                "book": 1,
                "page": 88,
                "row": 7,
                "hexCode": "#B55747",
                "rgb": "181,87,71"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1433 TPG",
                "name": "Papaya Punch",
                "book": 1,
                "page": 89,
                "row": 1,
                "hexCode": "#FDA087",
                "rgb": "253,160,135"
            },
            {
                "nr": "PANTONE 14-1228 TPG",
                "name": "Peach Nectar",
                "book": 1,
                "page": 89,
                "row": 2,
                "hexCode": "#FEBAA0",
                "rgb": "254,186,160"
            },
            {
                "nr": "PANTONE 15-1331 TPG",
                "name": "Coral Reef",
                "book": 1,
                "page": 89,
                "row": 3,
                "hexCode": "#F8A381",
                "rgb": "248,163,129"
            },
            {
                "nr": "PANTONE 15-1530 TPG",
                "name": "Peach Pink",
                "book": 1,
                "page": 89,
                "row": 4,
                "hexCode": "#F79685",
                "rgb": "247,150,133"
            },
            {
                "nr": "PANTONE 15-1340 TPG",
                "name": "Cadmium Orange",
                "book": 1,
                "page": 89,
                "row": 5,
                "hexCode": "#F3936F",
                "rgb": "243,147,111"
            },
            {
                "nr": "PANTONE 15-1239 TPG",
                "name": "Cantaloupe",
                "book": 1,
                "page": 89,
                "row": 6,
                "hexCode": "#FEA37F",
                "rgb": "254,163,127"
            },
            {
                "nr": "PANTONE 16-1442 TPG",
                "name": "Melon",
                "book": 1,
                "page": 89,
                "row": 7,
                "hexCode": "#F5896A",
                "rgb": "245,137,106"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0915 TPG",
                "name": "Pale Peach",
                "book": 1,
                "page": 90,
                "row": 1,
                "hexCode": "#FBCFB8",
                "rgb": "251,207,184"
            },
            {
                "nr": "PANTONE 12-0917 TPG",
                "name": "Bleached Apricot",
                "book": 1,
                "page": 90,
                "row": 2,
                "hexCode": "#FBCFAD",
                "rgb": "251,207,173"
            },
            {
                "nr": "PANTONE 14-1219 TPG",
                "name": "Peach Parfait",
                "book": 1,
                "page": 90,
                "row": 3,
                "hexCode": "#F9BFA9",
                "rgb": "249,191,169"
            },
            {
                "nr": "PANTONE 13-1021 TPG",
                "name": "Prairie Sunset",
                "book": 1,
                "page": 90,
                "row": 4,
                "hexCode": "#FBBFA2",
                "rgb": "251,191,162"
            },
            {
                "nr": "PANTONE 14-1323 TPG",
                "name": "Salmon",
                "book": 1,
                "page": 90,
                "row": 5,
                "hexCode": "#F5AC98",
                "rgb": "245,172,152"
            },
            {
                "nr": "PANTONE 14-1227 TPG",
                "name": "Peach",
                "book": 1,
                "page": 90,
                "row": 6,
                "hexCode": "#F0AC87",
                "rgb": "240,172,135"
            },
            {
                "nr": "PANTONE 15-1334 TPG",
                "name": "Shell Coral",
                "book": 1,
                "page": 90,
                "row": 7,
                "hexCode": "#EB9978",
                "rgb": "235,153,120"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1023 TPG",
                "name": "Peach Fuzz",
                "book": 1,
                "page": 91,
                "row": 1,
                "hexCode": "#FEC196",
                "rgb": "254,193,150"
            },
            {
                "nr": "PANTONE 14-1225 TPG",
                "name": "Beach Sand",
                "book": 1,
                "page": 91,
                "row": 2,
                "hexCode": "#F7BF99",
                "rgb": "247,191,153"
            },
            {
                "nr": "PANTONE 14-1230 TPG",
                "name": "Apricot Wash",
                "book": 1,
                "page": 91,
                "row": 3,
                "hexCode": "#F6B087",
                "rgb": "246,176,135"
            },
            {
                "nr": "PANTONE 14-1231 TPG",
                "name": "Peach Cobbler",
                "book": 1,
                "page": 91,
                "row": 4,
                "hexCode": "#FEB481",
                "rgb": "254,180,129"
            },
            {
                "nr": "PANTONE 14-1241 TPG",
                "name": "Orange Chiffon",
                "book": 1,
                "page": 91,
                "row": 5,
                "hexCode": "#FAAC7E",
                "rgb": "249,171,126"
            },
            {
                "nr": "PANTONE 14-1135 TPG",
                "name": "Salmon Buff",
                "book": 1,
                "page": 91,
                "row": 6,
                "hexCode": "#FEB07E",
                "rgb": "254,176,126"
            },
            {
                "nr": "PANTONE 14-1139 TPG",
                "name": "Pumpkin",
                "book": 1,
                "page": 91,
                "row": 7,
                "hexCode": "#F8A76C",
                "rgb": "248,167,108"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1026 TPG",
                "name": "Creampuff",
                "book": 1,
                "page": 92,
                "row": 1,
                "hexCode": "#FED3B0",
                "rgb": "254,211,176"
            },
            {
                "nr": "PANTONE 13-1020 TPG",
                "name": "Apricot Ice",
                "book": 1,
                "page": 92,
                "row": 2,
                "hexCode": "#FBC099",
                "rgb": "251,192,153"
            },
            {
                "nr": "PANTONE 13-1022 TPG",
                "name": "Caramel Cream",
                "book": 1,
                "page": 92,
                "row": 3,
                "hexCode": "#F3C29A",
                "rgb": "243,194,154"
            },
            {
                "nr": "PANTONE 13-1125 TPG",
                "name": "Peach Quartz",
                "book": 1,
                "page": 92,
                "row": 4,
                "hexCode": "#F5B995",
                "rgb": "245,185,148"
            },
            {
                "nr": "PANTONE 14-1224 TPG",
                "name": "Coral Sands",
                "book": 1,
                "page": 92,
                "row": 5,
                "hexCode": "#EEAB89",
                "rgb": "238,171,137"
            },
            {
                "nr": "PANTONE 16-1338 TPG",
                "name": "Copper Tan",
                "book": 1,
                "page": 92,
                "row": 6,
                "hexCode": "#E39065",
                "rgb": "227,144,101"
            },
            {
                "nr": "PANTONE 16-1337 TPG",
                "name": "Coral Gold",
                "book": 1,
                "page": 92,
                "row": 7,
                "hexCode": "#D8865D",
                "rgb": "216,134,93"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1017 TPG",
                "name": "Almond Cream",
                "book": 1,
                "page": 93,
                "row": 1,
                "hexCode": "#F3CAA8",
                "rgb": "243,202,168"
            },
            {
                "nr": "PANTONE 13-1019 TPG",
                "name": "Cream Blush",
                "book": 1,
                "page": 93,
                "row": 2,
                "hexCode": "#FAC69C",
                "rgb": "250,198,156"
            },
            {
                "nr": "PANTONE 14-1133 TPG",
                "name": "Apricot Nectar",
                "book": 1,
                "page": 93,
                "row": 3,
                "hexCode": "#EDA974",
                "rgb": "237,169,116"
            },
            {
                "nr": "PANTONE 15-1234 TPG",
                "name": "Gold Earth",
                "book": 1,
                "page": 93,
                "row": 4,
                "hexCode": "#DE9C69",
                "rgb": "222,156,105"
            },
            {
                "nr": "PANTONE 15-1237 TPG",
                "name": "Apricot Tan",
                "book": 1,
                "page": 93,
                "row": 5,
                "hexCode": "#DC965F",
                "rgb": "220,150,95"
            },
            {
                "nr": "PANTONE 16-1346 TPG",
                "name": "Golden Ochre",
                "book": 1,
                "page": 93,
                "row": 6,
                "hexCode": "#C97D49",
                "rgb": "201,125,73"
            },
            {
                "nr": "PANTONE 16-1443 TPG",
                "name": "Apricot Buff",
                "book": 1,
                "page": 93,
                "row": 7,
                "hexCode": "#CF7F4F",
                "rgb": "207,127,79"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1347 TPG",
                "name": "Peach Caramel",
                "book": 1,
                "page": 94,
                "row": 1,
                "hexCode": "#C77748",
                "rgb": "199,119,71"
            },
            {
                "nr": "PANTONE 16-1348 TPG",
                "name": "Tomato Cream",
                "book": 1,
                "page": 94,
                "row": 2,
                "hexCode": "#C6784C",
                "rgb": "198,120,75"
            },
            {
                "nr": "PANTONE 18-1248 TPG",
                "name": "Rust",
                "book": 1,
                "page": 94,
                "row": 3,
                "hexCode": "#B25F43",
                "rgb": "178,95,67"
            },
            {
                "nr": "PANTONE 16-1448 TPG",
                "name": "Burnt Orange",
                "book": 1,
                "page": 94,
                "row": 4,
                "hexCode": "#C86838",
                "rgb": "200,104,56"
            },
            {
                "nr": "PANTONE 16-1449 TPG",
                "name": "Gold Flame",
                "book": 1,
                "page": 94,
                "row": 5,
                "hexCode": "#BC6039",
                "rgb": "188,96,57"
            },
            {
                "nr": "PANTONE 17-1353 TPG",
                "name": "Apricot Orange",
                "book": 1,
                "page": 94,
                "row": 6,
                "hexCode": "#C36B45",
                "rgb": "195,107,69"
            },
            {
                "nr": "PANTONE 16-1260 TPG",
                "name": "Harvest Pumpkin",
                "book": 1,
                "page": 94,
                "row": 7,
                "hexCode": "#D26A3E",
                "rgb": "210,106,62"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1242 TPG",
                "name": "Muskmelon",
                "book": 1,
                "page": 95,
                "row": 1,
                "hexCode": "#F19763",
                "rgb": "241,151,99"
            },
            {
                "nr": "PANTONE 16-1350 TPG",
                "name": "Amberglow",
                "book": 1,
                "page": 95,
                "row": 2,
                "hexCode": "#E07E46",
                "rgb": "224,126,70"
            },
            {
                "nr": "PANTONE 16-1253 TPG",
                "name": "Orange Ochre",
                "book": 1,
                "page": 95,
                "row": 3,
                "hexCode": "#DE7F44",
                "rgb": "222,127,68"
            },
            {
                "nr": "PANTONE 16-1454 TPG",
                "name": "Jaffa Orange",
                "book": 1,
                "page": 95,
                "row": 4,
                "hexCode": "#DA7243",
                "rgb": "218,114,67"
            },
            {
                "nr": "PANTONE 17-1145 TPG",
                "name": "Autumn Maple",
                "book": 1,
                "page": 95,
                "row": 5,
                "hexCode": "#C86C32",
                "rgb": "200,107,49"
            },
            {
                "nr": "PANTONE 17-1140 TPG",
                "name": "Marmalade",
                "book": 1,
                "page": 95,
                "row": 6,
                "hexCode": "#C66E32",
                "rgb": "198,110,50"
            },
            {
                "nr": "PANTONE 18-1249 TPG",
                "name": "Hawaiian Sunset",
                "book": 1,
                "page": 95,
                "row": 7,
                "hexCode": "#BB6536",
                "rgb": "187,101,54"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1245 TPG",
                "name": "Mock Orange",
                "book": 1,
                "page": 96,
                "row": 1,
                "hexCode": "#FEA76B",
                "rgb": "254,167,107"
            },
            {
                "nr": "PANTONE 15-1243 TPG",
                "name": "Papaya",
                "book": 1,
                "page": 96,
                "row": 2,
                "hexCode": "#FFA269",
                "rgb": "254,161,105"
            },
            {
                "nr": "PANTONE 15-1247 TPG",
                "name": "Tangerine",
                "book": 1,
                "page": 96,
                "row": 3,
                "hexCode": "#F7945C",
                "rgb": "247,148,92"
            },
            {
                "nr": "PANTONE 16-1257 TPG",
                "name": "Sun Orange",
                "book": 1,
                "page": 96,
                "row": 4,
                "hexCode": "#F08642",
                "rgb": "240,134,66"
            },
            {
                "nr": "PANTONE 15-1150 TPG",
                "name": "Dark Cheddar",
                "book": 1,
                "page": 96,
                "row": 5,
                "hexCode": "#E3882E",
                "rgb": "227,136,46"
            },
            {
                "nr": "PANTONE 16-1164 TPG",
                "name": "Orange Pepper",
                "book": 1,
                "page": 96,
                "row": 6,
                "hexCode": "#E47E2E",
                "rgb": "228,126,46"
            },
            {
                "nr": "PANTONE 16-1255 TPG",
                "name": "Russet Orange",
                "book": 1,
                "page": 96,
                "row": 7,
                "hexCode": "#E4792E",
                "rgb": "228,121,46"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1160 TPG",
                "name": "Blazing Orange",
                "book": 1,
                "page": 97,
                "row": 1,
                "hexCode": "#FEA351",
                "rgb": "254,163,81"
            },
            {
                "nr": "PANTONE 15-1153 TPG",
                "name": "Apricot",
                "book": 1,
                "page": 97,
                "row": 2,
                "hexCode": "#EF963C",
                "rgb": "239,150,60"
            },
            {
                "nr": "PANTONE 15-1151 TPG",
                "name": "Iceland Poppy",
                "book": 1,
                "page": 97,
                "row": 3,
                "hexCode": "#F59D4D",
                "rgb": "245,156,76"
            },
            {
                "nr": "PANTONE 15-1157 TPG",
                "name": "Flame Orange",
                "book": 1,
                "page": 97,
                "row": 4,
                "hexCode": "#F88D2F",
                "rgb": "248,141,47"
            },
            {
                "nr": "PANTONE 15-1164 TPG",
                "name": "Bright Marigold",
                "book": 1,
                "page": 97,
                "row": 5,
                "hexCode": "#FE901E",
                "rgb": "254,144,30"
            },
            {
                "nr": "PANTONE 15-1262 TPG",
                "name": "Carrot Curl",
                "book": 1,
                "page": 97,
                "row": 6,
                "hexCode": "#FF8B27",
                "rgb": "254,139,39"
            },
            {
                "nr": "PANTONE 15-1263 TPG",
                "name": "Autumn Glory",
                "book": 1,
                "page": 97,
                "row": 7,
                "hexCode": "#FE8826",
                "rgb": "254,136,38"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1264 TPG",
                "name": "Turmeric",
                "book": 1,
                "page": 98,
                "row": 1,
                "hexCode": "#FF842A",
                "rgb": "254,132,42"
            },
            {
                "nr": "PANTONE 15-1335 TPG",
                "name": "Tangelo",
                "book": 1,
                "page": 98,
                "row": 2,
                "hexCode": "#FF7F28",
                "rgb": "254,127,40"
            },
            {
                "nr": "PANTONE 17-1350 TPG",
                "name": "Oriole",
                "book": 1,
                "page": 98,
                "row": 3,
                "hexCode": "#FE7B25",
                "rgb": "254,123,37"
            },
            {
                "nr": "PANTONE 16-1364 TPG",
                "name": "Vibrant Orange",
                "book": 1,
                "page": 98,
                "row": 4,
                "hexCode": "#FE7237",
                "rgb": "254,114,55"
            },
            {
                "nr": "PANTONE 16-1356 TPG",
                "name": "Persimmon Orange",
                "book": 1,
                "page": 98,
                "row": 5,
                "hexCode": "#F57B35",
                "rgb": "245,123,53"
            },
            {
                "nr": "PANTONE 16-1358 TPG",
                "name": "Orange Tiger",
                "book": 1,
                "page": 98,
                "row": 6,
                "hexCode": "#FD7133",
                "rgb": "252,112,51"
            },
            {
                "nr": "PANTONE 17-1349 TPG",
                "name": "Exuberance",
                "book": 1,
                "page": 98,
                "row": 7,
                "hexCode": "#E77133",
                "rgb": "231,113,51"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1343 TPG",
                "name": "Autumn Sunset",
                "book": 1,
                "page": 99,
                "row": 1,
                "hexCode": "#F48C57",
                "rgb": "244,140,87"
            },
            {
                "nr": "PANTONE 16-1344 TPG",
                "name": "Dusty Orange",
                "book": 1,
                "page": 99,
                "row": 2,
                "hexCode": "#E58059",
                "rgb": "229,128,89"
            },
            {
                "nr": "PANTONE 16-1359 TPG",
                "name": "Orange Peel",
                "book": 1,
                "page": 99,
                "row": 3,
                "hexCode": "#FC823E",
                "rgb": "252,130,62"
            },
            {
                "nr": "PANTONE 17-1360 TPG",
                "name": "Celosia Orange",
                "book": 1,
                "page": 99,
                "row": 4,
                "hexCode": "#E87443",
                "rgb": "232,116,67"
            },
            {
                "nr": "PANTONE 16-1459 TPG",
                "name": "Mandarin Orange",
                "book": 1,
                "page": 99,
                "row": 5,
                "hexCode": "#EA6F46",
                "rgb": "233,111,69"
            },
            {
                "nr": "PANTONE 16-1462 TPG",
                "name": "Golden Poppy",
                "book": 1,
                "page": 99,
                "row": 6,
                "hexCode": "#F26D3B",
                "rgb": "242,109,59"
            },
            {
                "nr": "PANTONE 16-1453 TPG",
                "name": "Exotic Orange",
                "book": 1,
                "page": 99,
                "row": 7,
                "hexCode": "#FB6E45",
                "rgb": "251,109,68"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1357 TPG",
                "name": "Bird Of Paradise",
                "book": 1,
                "page": 100,
                "row": 1,
                "hexCode": "#FE8C53",
                "rgb": "254,140,83"
            },
            {
                "nr": "PANTONE 16-1360 TPG",
                "name": "Nectarine",
                "book": 1,
                "page": 100,
                "row": 2,
                "hexCode": "#FE8859",
                "rgb": "254,136,89"
            },
            {
                "nr": "PANTONE 16-1349 TPG",
                "name": "Coral Rose",
                "book": 1,
                "page": 100,
                "row": 3,
                "hexCode": "#F2794E",
                "rgb": "242,121,78"
            },
            {
                "nr": "PANTONE 16-1361 TPG",
                "name": "Carrot",
                "book": 1,
                "page": 100,
                "row": 4,
                "hexCode": "#F76F42",
                "rgb": "247,111,66"
            },
            {
                "nr": "PANTONE 17-1361 TPG",
                "name": "Scarlet Ibis",
                "book": 1,
                "page": 100,
                "row": 5,
                "hexCode": "#F7613A",
                "rgb": "246,97,57"
            },
            {
                "nr": "PANTONE 16-1363 TPG",
                "name": "Puffin's Bill",
                "book": 1,
                "page": 100,
                "row": 6,
                "hexCode": "#E56031",
                "rgb": "229,96,49"
            },
            {
                "nr": "PANTONE 16-1460 TPG",
                "name": "Dragon Fire",
                "book": 1,
                "page": 100,
                "row": 7,
                "hexCode": "#FB6D44",
                "rgb": "251,109,67"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1362 TPG",
                "name": "Vermillion Orange",
                "book": 1,
                "page": 101,
                "row": 1,
                "hexCode": "#FB683F",
                "rgb": "251,104,63"
            },
            {
                "nr": "PANTONE 16-1452 TPG",
                "name": "Firecracker",
                "book": 1,
                "page": 101,
                "row": 2,
                "hexCode": "#F1714D",
                "rgb": "241,113,77"
            },
            {
                "nr": "PANTONE 17-1452 TPG",
                "name": "Koi",
                "book": 1,
                "page": 101,
                "row": 3,
                "hexCode": "#CF5E44",
                "rgb": "207,94,68"
            },
            {
                "nr": "PANTONE 17-1462 TPG",
                "name": "Flame",
                "book": 1,
                "page": 101,
                "row": 4,
                "hexCode": "#F75B3B",
                "rgb": "247,91,59"
            },
            {
                "nr": "PANTONE 17-1461 TPG",
                "name": "Orangeade",
                "book": 1,
                "page": 101,
                "row": 5,
                "hexCode": "#E45C3B",
                "rgb": "228,92,59"
            },
            {
                "nr": "PANTONE 17-1464 TPG",
                "name": "Red Orange",
                "book": 1,
                "page": 101,
                "row": 6,
                "hexCode": "#F15D34",
                "rgb": "241,93,52"
            },
            {
                "nr": "PANTONE 17-1463 TPG",
                "name": "Tangerine Tango",
                "book": 1,
                "page": 101,
                "row": 7,
                "hexCode": "#E34F32",
                "rgb": "227,79,50"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1456 TPG",
                "name": "Tigerlily",
                "book": 1,
                "page": 102,
                "row": 1,
                "hexCode": "#E2654D",
                "rgb": "226,101,77"
            },
            {
                "nr": "PANTONE 17-1450 TPG",
                "name": "Summer Fig",
                "book": 1,
                "page": 102,
                "row": 2,
                "hexCode": "#C25448",
                "rgb": "194,83,71"
            },
            {
                "nr": "PANTONE 18-1448 TPG",
                "name": "Chili",
                "book": 1,
                "page": 102,
                "row": 3,
                "hexCode": "#BE5A4D",
                "rgb": "190,90,77"
            },
            {
                "nr": "PANTONE 18-1447 TPG",
                "name": "Orange Rust",
                "book": 1,
                "page": 102,
                "row": 4,
                "hexCode": "#C2624E",
                "rgb": "194,98,78"
            },
            {
                "nr": "PANTONE 17-1553 TPG",
                "name": "Paprika",
                "book": 1,
                "page": 102,
                "row": 5,
                "hexCode": "#CC5852",
                "rgb": "204,88,82"
            },
            {
                "nr": "PANTONE 18-1454 TPG",
                "name": "Red Clay",
                "book": 1,
                "page": 102,
                "row": 6,
                "hexCode": "#C05043",
                "rgb": "192,80,67"
            },
            {
                "nr": "PANTONE 17-1449 TPG",
                "name": "Pureed Pumpkin",
                "book": 1,
                "page": 102,
                "row": 7,
                "hexCode": "#C04E38",
                "rgb": "192,78,56"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1445 TPG",
                "name": "Spicy Orange",
                "book": 1,
                "page": 103,
                "row": 1,
                "hexCode": "#DC523F",
                "rgb": "220,82,63"
            },
            {
                "nr": "PANTONE 17-1558 TPG",
                "name": "Grenadine",
                "book": 1,
                "page": 103,
                "row": 2,
                "hexCode": "#DC4C46",
                "rgb": "220,76,70"
            },
            {
                "nr": "PANTONE 18-1561 TPG",
                "name": "Orange.com",
                "book": 1,
                "page": 103,
                "row": 3,
                "hexCode": "#D74538",
                "rgb": "215,69,56"
            },
            {
                "nr": "PANTONE 17-1563 TPG",
                "name": "Cherry Tomato",
                "book": 1,
                "page": 103,
                "row": 4,
                "hexCode": "#E84A3B",
                "rgb": "232,74,59"
            },
            {
                "nr": "PANTONE 17-1564 TPG",
                "name": "Fiesta",
                "book": 1,
                "page": 103,
                "row": 5,
                "hexCode": "#DD4D43",
                "rgb": "221,77,67"
            },
            {
                "nr": "PANTONE 18-1564 TPG",
                "name": "Poinciana",
                "book": 1,
                "page": 103,
                "row": 6,
                "hexCode": "#C84234",
                "rgb": "200,66,52"
            },
            {
                "nr": "PANTONE 18-1550 TPG",
                "name": "Aurora Red",
                "book": 1,
                "page": 103,
                "row": 7,
                "hexCode": "#BD4747",
                "rgb": "189,71,71"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1544 TPG",
                "name": "Persimmon",
                "book": 1,
                "page": 104,
                "row": 1,
                "hexCode": "#F47A6D",
                "rgb": "244,122,109"
            },
            {
                "nr": "PANTONE 16-1541 TPG",
                "name": "Camellia",
                "book": 1,
                "page": 104,
                "row": 2,
                "hexCode": "#F77665",
                "rgb": "247,118,101"
            },
            {
                "nr": "PANTONE 17-1547 TPG",
                "name": "Emberglow",
                "book": 1,
                "page": 104,
                "row": 3,
                "hexCode": "#E66B62",
                "rgb": "230,107,98"
            },
            {
                "nr": "PANTONE 16-1451 TPG",
                "name": "Nasturtium",
                "book": 1,
                "page": 104,
                "row": 4,
                "hexCode": "#F96951",
                "rgb": "249,105,81"
            },
            {
                "nr": "PANTONE 17-1562 TPG",
                "name": "Mandarin Red",
                "book": 1,
                "page": 104,
                "row": 5,
                "hexCode": "#E95643",
                "rgb": "233,86,67"
            },
            {
                "nr": "PANTONE 18-1664 TPG",
                "name": "Fiery Red",
                "book": 1,
                "page": 104,
                "row": 6,
                "hexCode": "#D2383D",
                "rgb": "210,56,61"
            },
            {
                "nr": "PANTONE 18-1549 TPG",
                "name": "Valiant Poppy",
                "book": 1,
                "page": 104,
                "row": 7,
                "hexCode": "#BD3D3A",
                "rgb": "189,60,58"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1543 TPG",
                "name": "Fusion Coral",
                "book": 1,
                "page": 105,
                "row": 1,
                "hexCode": "#FE8472",
                "rgb": "254,132,114"
            },
            {
                "nr": "PANTONE 16-1548 TPG",
                "name": "Peach Echo",
                "book": 1,
                "page": 105,
                "row": 2,
                "hexCode": "#F77D73",
                "rgb": "246,125,114"
            },
            {
                "nr": "PANTONE 16-1539 TPG",
                "name": "Coral",
                "book": 1,
                "page": 105,
                "row": 3,
                "hexCode": "#EC766A",
                "rgb": "236,118,106"
            },
            {
                "nr": "PANTONE 16-1542 TPG",
                "name": "Fresh Salmon",
                "book": 1,
                "page": 105,
                "row": 4,
                "hexCode": "#FE8673",
                "rgb": "254,134,115"
            },
            {
                "nr": "PANTONE 16-1545 TPG",
                "name": "Coral Quartz",
                "book": 1,
                "page": 105,
                "row": 5,
                "hexCode": "#F7796D",
                "rgb": "247,121,108"
            },
            {
                "nr": "PANTONE 16-1546 TPG",
                "name": "Living Coral",
                "book": 1,
                "page": 105,
                "row": 6,
                "hexCode": "#FC766A",
                "rgb": "252,118,106"
            },
            {
                "nr": "PANTONE 17-1656 TPG",
                "name": "Hot Coral",
                "book": 1,
                "page": 105,
                "row": 7,
                "hexCode": "#EF5F5C",
                "rgb": "239,95,92"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1435 TPG",
                "name": "Desert Flower",
                "book": 1,
                "page": 106,
                "row": 1,
                "hexCode": "#FE9A8D",
                "rgb": "254,154,141"
            },
            {
                "nr": "PANTONE 16-1641 TPG",
                "name": "Georgia Peach",
                "book": 1,
                "page": 106,
                "row": 2,
                "hexCode": "#F27678",
                "rgb": "242,118,120"
            },
            {
                "nr": "PANTONE 16-1640 TPG",
                "name": "Sugar Coral",
                "book": 1,
                "page": 106,
                "row": 3,
                "hexCode": "#ED727B",
                "rgb": "237,114,123"
            },
            {
                "nr": "PANTONE 17-1647 TPG",
                "name": "Dubarry",
                "book": 1,
                "page": 106,
                "row": 4,
                "hexCode": "#EC696E",
                "rgb": "236,105,110"
            },
            {
                "nr": "PANTONE 17-1643 TPG",
                "name": "Porcelain Rose",
                "book": 1,
                "page": 106,
                "row": 5,
                "hexCode": "#E56F72",
                "rgb": "229,111,114"
            },
            {
                "nr": "PANTONE 17-1644 TPG",
                "name": "Spiced Coral",
                "book": 1,
                "page": 106,
                "row": 6,
                "hexCode": "#D7696B",
                "rgb": "215,105,107"
            },
            {
                "nr": "PANTONE 17-1545 TPG",
                "name": "Cranberry",
                "book": 1,
                "page": 106,
                "row": 7,
                "hexCode": "#B85257",
                "rgb": "184,82,87"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1632 TPG",
                "name": "Shell Pink",
                "book": 1,
                "page": 107,
                "row": 1,
                "hexCode": "#F38284",
                "rgb": "243,130,132"
            },
            {
                "nr": "PANTONE 18-1649 TPG",
                "name": "Deep Sea Coral",
                "book": 1,
                "page": 107,
                "row": 2,
                "hexCode": "#DA6265",
                "rgb": "218,98,101"
            },
            {
                "nr": "PANTONE 17-1635 TPG",
                "name": "Rose Of Sharon",
                "book": 1,
                "page": 107,
                "row": 3,
                "hexCode": "#D85E67",
                "rgb": "216,94,103"
            },
            {
                "nr": "PANTONE 18-1651 TPG",
                "name": "Cayenne",
                "book": 1,
                "page": 107,
                "row": 4,
                "hexCode": "#DB545A",
                "rgb": "219,84,90"
            },
            {
                "nr": "PANTONE 17-1654 TPG",
                "name": "Poinsettia",
                "book": 1,
                "page": 107,
                "row": 5,
                "hexCode": "#CC4350",
                "rgb": "204,67,80"
            },
            {
                "nr": "PANTONE 17-1641 TPG",
                "name": "Chrysanthemum",
                "book": 1,
                "page": 107,
                "row": 6,
                "hexCode": "#BA515A",
                "rgb": "186,81,90"
            },
            {
                "nr": "PANTONE 18-1643 TPG",
                "name": "Cardinal",
                "book": 1,
                "page": 107,
                "row": 7,
                "hexCode": "#A74A53",
                "rgb": "167,74,83"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1661 TPG",
                "name": "Tomato Puree",
                "book": 1,
                "page": 108,
                "row": 1,
                "hexCode": "#C24553",
                "rgb": "194,69,83"
            },
            {
                "nr": "PANTONE 17-1663 TPG",
                "name": "Bittersweet",
                "book": 1,
                "page": 108,
                "row": 2,
                "hexCode": "#D74652",
                "rgb": "215,70,82"
            },
            {
                "nr": "PANTONE 18-1652 TPG",
                "name": "Rococco Red",
                "book": 1,
                "page": 108,
                "row": 3,
                "hexCode": "#BF4450",
                "rgb": "191,68,80"
            },
            {
                "nr": "PANTONE 18-1660 TPG",
                "name": "Tomato",
                "book": 1,
                "page": 108,
                "row": 4,
                "hexCode": "#CE3845",
                "rgb": "206,56,69"
            },
            {
                "nr": "PANTONE 18-1762 TPG",
                "name": "Hibiscus",
                "book": 1,
                "page": 108,
                "row": 5,
                "hexCode": "#D84551",
                "rgb": "216,69,81"
            },
            {
                "nr": "PANTONE 19-1760 TPG",
                "name": "Scarlet",
                "book": 1,
                "page": 108,
                "row": 6,
                "hexCode": "#B33A4B",
                "rgb": "179,58,75"
            },
            {
                "nr": "PANTONE 18-1764 TPG",
                "name": "Lollipop",
                "book": 1,
                "page": 108,
                "row": 7,
                "hexCode": "#C63A4E",
                "rgb": "198,58,78"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1763 TPG",
                "name": "High Risk Red",
                "book": 1,
                "page": 109,
                "row": 1,
                "hexCode": "#CB323C",
                "rgb": "203,50,60"
            },
            {
                "nr": "PANTONE 18-1555 TPG",
                "name": "Molten Lava",
                "book": 1,
                "page": 109,
                "row": 2,
                "hexCode": "#BB3D39",
                "rgb": "187,61,57"
            },
            {
                "nr": "PANTONE 18-1657 TPG",
                "name": "Salsa",
                "book": 1,
                "page": 109,
                "row": 3,
                "hexCode": "#AC2F3D",
                "rgb": "172,47,61"
            },
            {
                "nr": "PANTONE 18-1551 TPG",
                "name": "Aura Orange",
                "book": 1,
                "page": 109,
                "row": 4,
                "hexCode": "#B8373B",
                "rgb": "184,54,58"
            },
            {
                "nr": "PANTONE 18-1659 TPG",
                "name": "Goji Berry",
                "book": 1,
                "page": 109,
                "row": 5,
                "hexCode": "#B8333E",
                "rgb": "184,50,61"
            },
            {
                "nr": "PANTONE 18-1658 TPG",
                "name": "Pompeian Red",
                "book": 1,
                "page": 109,
                "row": 6,
                "hexCode": "#A64145",
                "rgb": "166,65,69"
            },
            {
                "nr": "PANTONE 19-1758 TPG",
                "name": "Haute Red",
                "book": 1,
                "page": 109,
                "row": 7,
                "hexCode": "#A42E3A",
                "rgb": "164,46,58"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1559 TPG",
                "name": "Red Alert",
                "book": 1,
                "page": 110,
                "row": 1,
                "hexCode": "#D34038",
                "rgb": "211,64,56"
            },
            {
                "nr": "PANTONE 18-1563 TPG",
                "name": "Rubocondo",
                "book": 1,
                "page": 110,
                "row": 2,
                "hexCode": "#C33C41",
                "rgb": "195,60,65"
            },
            {
                "nr": "PANTONE 18-1453 TPG",
                "name": "Fire Whirl",
                "book": 1,
                "page": 110,
                "row": 3,
                "hexCode": "#AC423D",
                "rgb": "172,66,61"
            },
            {
                "nr": "PANTONE 18-1553 TPG",
                "name": "Adrenaline Rush",
                "book": 1,
                "page": 110,
                "row": 4,
                "hexCode": "#B73438",
                "rgb": "183,52,56"
            },
            {
                "nr": "PANTONE 19-1756 TPG",
                "name": "Urban Red",
                "book": 1,
                "page": 110,
                "row": 5,
                "hexCode": "#B53743",
                "rgb": "181,55,67"
            },
            {
                "nr": "PANTONE 18-1552 TPG",
                "name": "Lava Falls",
                "book": 1,
                "page": 110,
                "row": 6,
                "hexCode": "#9F383A",
                "rgb": "159,56,58"
            },
            {
                "nr": "PANTONE 19-1755 TPG",
                "name": "Equestrian Red",
                "book": 1,
                "page": 110,
                "row": 7,
                "hexCode": "#A2323C",
                "rgb": "162,50,60"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1662 TPG",
                "name": "Flame Scarlet",
                "book": 1,
                "page": 111,
                "row": 1,
                "hexCode": "#D13B3F",
                "rgb": "209,59,63"
            },
            {
                "nr": "PANTONE 17-1664 TPG",
                "name": "Poppy Red",
                "book": 1,
                "page": 111,
                "row": 2,
                "hexCode": "#DE484F",
                "rgb": "222,72,79"
            },
            {
                "nr": "PANTONE 19-1763 TPG",
                "name": "Racing Red",
                "book": 1,
                "page": 111,
                "row": 3,
                "hexCode": "#BB2E3E",
                "rgb": "187,46,62"
            },
            {
                "nr": "PANTONE 18-1663 TPG",
                "name": "Chinese Red",
                "book": 1,
                "page": 111,
                "row": 4,
                "hexCode": "#C03343",
                "rgb": "192,51,67"
            },
            {
                "nr": "PANTONE 19-1664 TPG",
                "name": "True Red",
                "book": 1,
                "page": 111,
                "row": 5,
                "hexCode": "#BD3544",
                "rgb": "189,53,68"
            },
            {
                "nr": "PANTONE 19-1663 TPG",
                "name": "Ribbon Red",
                "book": 1,
                "page": 111,
                "row": 6,
                "hexCode": "#B93744",
                "rgb": "185,55,68"
            },
            {
                "nr": "PANTONE 19-1662 TPG",
                "name": "Samba",
                "book": 1,
                "page": 111,
                "row": 7,
                "hexCode": "#A9353D",
                "rgb": "169,53,61"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1653 TPG",
                "name": "Toreador",
                "book": 1,
                "page": 112,
                "row": 1,
                "hexCode": "#BA2B42",
                "rgb": "185,42,65"
            },
            {
                "nr": "PANTONE 18-1761 TPG",
                "name": "Ski Patrol",
                "book": 1,
                "page": 112,
                "row": 2,
                "hexCode": "#B7314B",
                "rgb": "183,49,75"
            },
            {
                "nr": "PANTONE 19-1764 TPG",
                "name": "Lipstick Red",
                "book": 1,
                "page": 112,
                "row": 3,
                "hexCode": "#B23649",
                "rgb": "178,54,73"
            },
            {
                "nr": "PANTONE 18-1654 TPG",
                "name": "Lychee",
                "book": 1,
                "page": 112,
                "row": 4,
                "hexCode": "#BB2F43",
                "rgb": "186,46,66"
            },
            {
                "nr": "PANTONE 19-1762 TPG",
                "name": "Crimson",
                "book": 1,
                "page": 112,
                "row": 5,
                "hexCode": "#B0344D",
                "rgb": "176,52,77"
            },
            {
                "nr": "PANTONE 18-1759 TPG",
                "name": "Jalape?o Red",
                "book": 1,
                "page": 112,
                "row": 6,
                "hexCode": "#B2334B",
                "rgb": "177,51,74"
            },
            {
                "nr": "PANTONE 19-1862 TPG",
                "name": "Jester Red",
                "book": 1,
                "page": 112,
                "row": 7,
                "hexCode": "#9D3744",
                "rgb": "157,55,68"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1655 TPG",
                "name": "Mars Red",
                "book": 1,
                "page": 113,
                "row": 1,
                "hexCode": "#BE3945",
                "rgb": "190,57,69"
            },
            {
                "nr": "PANTONE 19-1757 TPG",
                "name": "Barbados Cherry",
                "book": 1,
                "page": 113,
                "row": 2,
                "hexCode": "#AF2B3A",
                "rgb": "175,43,58"
            },
            {
                "nr": "PANTONE 19-1559 TPG",
                "name": "Scarlet Sage",
                "book": 1,
                "page": 113,
                "row": 3,
                "hexCode": "#A13841",
                "rgb": "161,56,65"
            },
            {
                "nr": "PANTONE 19-1761 TPG",
                "name": "Tango Red",
                "book": 1,
                "page": 113,
                "row": 4,
                "hexCode": "#B02D41",
                "rgb": "176,45,65"
            },
            {
                "nr": "PANTONE 19-1759 TPG",
                "name": "American Beauty",
                "book": 1,
                "page": 113,
                "row": 5,
                "hexCode": "#A64450",
                "rgb": "166,68,80"
            },
            {
                "nr": "PANTONE 19-1863 TPG",
                "name": "Scooter",
                "book": 1,
                "page": 113,
                "row": 6,
                "hexCode": "#993141",
                "rgb": "153,49,65"
            },
            {
                "nr": "PANTONE 19-1557 TPG",
                "name": "Chili Pepper",
                "book": 1,
                "page": 113,
                "row": 7,
                "hexCode": "#9D363E",
                "rgb": "157,54,62"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1554 TPG",
                "name": "Savvy Red",
                "book": 1,
                "page": 114,
                "row": 1,
                "hexCode": "#9B313C",
                "rgb": "155,49,60"
            },
            {
                "nr": "PANTONE 19-1657 TPG",
                "name": "Karanda Red",
                "book": 1,
                "page": 114,
                "row": 2,
                "hexCode": "#923943",
                "rgb": "146,57,67"
            },
            {
                "nr": "PANTONE 19-1875 TPG",
                "name": "Emboldened",
                "book": 1,
                "page": 114,
                "row": 3,
                "hexCode": "#96353D",
                "rgb": "150,53,61"
            },
            {
                "nr": "PANTONE 19-1558 TPG",
                "name": "Scarlet Smile",
                "book": 1,
                "page": 114,
                "row": 4,
                "hexCode": "#A33745",
                "rgb": "163,55,69"
            },
            {
                "nr": "PANTONE 19-1537 TPG",
                "name": "Winery",
                "book": 1,
                "page": 114,
                "row": 5,
                "hexCode": "#833C3F",
                "rgb": "131,60,63"
            },
            {
                "nr": "PANTONE 19-1653 TPG",
                "name": "Rythmic Red",
                "book": 1,
                "page": 114,
                "row": 6,
                "hexCode": "#93363D",
                "rgb": "147,54,61"
            },
            {
                "nr": "PANTONE 19-1527 TPG",
                "name": "New Maroon",
                "book": 1,
                "page": 114,
                "row": 7,
                "hexCode": "#714145",
                "rgb": "113,65,69"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1650 TPG",
                "name": "Biking Red",
                "book": 1,
                "page": 115,
                "row": 1,
                "hexCode": "#803D45",
                "rgb": "127,61,68"
            },
            {
                "nr": "PANTONE 19-1652 TPG",
                "name": "Rhubarb",
                "book": 1,
                "page": 115,
                "row": 2,
                "hexCode": "#7A3640",
                "rgb": "122,53,63"
            },
            {
                "nr": "PANTONE 19-1534 TPG",
                "name": "Merlot",
                "book": 1,
                "page": 115,
                "row": 3,
                "hexCode": "#7A393E",
                "rgb": "121,57,62"
            },
            {
                "nr": "PANTONE 19-1555 TPG",
                "name": "Red Dahlia",
                "book": 1,
                "page": 115,
                "row": 4,
                "hexCode": "#863339",
                "rgb": "134,51,57"
            },
            {
                "nr": "PANTONE 19-1531 TPG",
                "name": "Sun-Dried Tomato",
                "book": 1,
                "page": 115,
                "row": 5,
                "hexCode": "#80393F",
                "rgb": "128,57,63"
            },
            {
                "nr": "PANTONE 19-1535 TPG",
                "name": "Syrah",
                "book": 1,
                "page": 115,
                "row": 6,
                "hexCode": "#74373A",
                "rgb": "116,55,57"
            },
            {
                "nr": "PANTONE 19-1327 TPG",
                "name": "Andorra",
                "book": 1,
                "page": 115,
                "row": 7,
                "hexCode": "#694446",
                "rgb": "105,68,70"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1629 TPG",
                "name": "Ruby Wine",
                "book": 1,
                "page": 116,
                "row": 1,
                "hexCode": "#794045",
                "rgb": "121,64,69"
            },
            {
                "nr": "PANTONE 19-1536 TPG",
                "name": "Red Pear",
                "book": 1,
                "page": 116,
                "row": 2,
                "hexCode": "#7F4145",
                "rgb": "127,65,68"
            },
            {
                "nr": "PANTONE 19-1934 TPG",
                "name": "Tibetan Red",
                "book": 1,
                "page": 116,
                "row": 3,
                "hexCode": "#81404A",
                "rgb": "128,64,74"
            },
            {
                "nr": "PANTONE 19-1726 TPG",
                "name": "Cordovan",
                "book": 1,
                "page": 116,
                "row": 4,
                "hexCode": "#6F4048",
                "rgb": "111,63,72"
            },
            {
                "nr": "PANTONE 19-1724 TPG",
                "name": "Cabernet",
                "book": 1,
                "page": 116,
                "row": 5,
                "hexCode": "#713B43",
                "rgb": "113,59,66"
            },
            {
                "nr": "PANTONE 19-1525 TPG",
                "name": "Port",
                "book": 1,
                "page": 116,
                "row": 6,
                "hexCode": "#6D4549",
                "rgb": "109,69,72"
            },
            {
                "nr": "PANTONE 19-1521 TPG",
                "name": "Red Mahogany",
                "book": 1,
                "page": 116,
                "row": 7,
                "hexCode": "#674A4E",
                "rgb": "102,74,78"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1533 TPG",
                "name": "Cowhide",
                "book": 1,
                "page": 117,
                "row": 1,
                "hexCode": "#8B5252",
                "rgb": "138,81,82"
            },
            {
                "nr": "PANTONE 19-1530 TPG",
                "name": "Burnt Russet",
                "book": 1,
                "page": 117,
                "row": 2,
                "hexCode": "#874850",
                "rgb": "135,72,80"
            },
            {
                "nr": "PANTONE 19-1524 TPG",
                "name": "Oxblood Red",
                "book": 1,
                "page": 117,
                "row": 3,
                "hexCode": "#754A4E",
                "rgb": "116,74,77"
            },
            {
                "nr": "PANTONE 19-1930 TPG",
                "name": "Pomegranate",
                "book": 1,
                "page": 117,
                "row": 4,
                "hexCode": "#6F3841",
                "rgb": "111,56,65"
            },
            {
                "nr": "PANTONE 19-1522 TPG",
                "name": "Zinfandel",
                "book": 1,
                "page": 117,
                "row": 5,
                "hexCode": "#663F45",
                "rgb": "101,62,68"
            },
            {
                "nr": "PANTONE 19-1725 TPG",
                "name": "Tawny Port",
                "book": 1,
                "page": 117,
                "row": 6,
                "hexCode": "#6B3B44",
                "rgb": "106,59,67"
            },
            {
                "nr": "PANTONE 19-1526 TPG",
                "name": "Chocolate Truffle",
                "book": 1,
                "page": 117,
                "row": 7,
                "hexCode": "#674248",
                "rgb": "102,66,71"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1631 TPG",
                "name": "Earth Red",
                "book": 1,
                "page": 118,
                "row": 1,
                "hexCode": "#9B4F5C",
                "rgb": "155,79,92"
            },
            {
                "nr": "PANTONE 19-1543 TPG",
                "name": "Brick Red",
                "book": 1,
                "page": 118,
                "row": 2,
                "hexCode": "#90484D",
                "rgb": "144,71,76"
            },
            {
                "nr": "PANTONE 19-1532 TPG",
                "name": "Rosewood",
                "book": 1,
                "page": 118,
                "row": 3,
                "hexCode": "#874648",
                "rgb": "134,70,71"
            },
            {
                "nr": "PANTONE 19-1656 TPG",
                "name": "Rio Red",
                "book": 1,
                "page": 118,
                "row": 4,
                "hexCode": "#8E3741",
                "rgb": "142,55,65"
            },
            {
                "nr": "PANTONE 19-1655 TPG",
                "name": "Garnet",
                "book": 1,
                "page": 118,
                "row": 5,
                "hexCode": "#964049",
                "rgb": "150,64,73"
            },
            {
                "nr": "PANTONE 19-1840 TPG",
                "name": "Deep Claret",
                "book": 1,
                "page": 118,
                "row": 6,
                "hexCode": "#9B414F",
                "rgb": "155,65,79"
            },
            {
                "nr": "PANTONE 19-1940 TPG",
                "name": "Rumba Red",
                "book": 1,
                "page": 118,
                "row": 7,
                "hexCode": "#823A47",
                "rgb": "130,58,71"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1617 TPG",
                "name": "Mauveglow",
                "book": 1,
                "page": 119,
                "row": 1,
                "hexCode": "#D0878E",
                "rgb": "208,135,142"
            },
            {
                "nr": "PANTONE 17-1927 TPG",
                "name": "Desert Rose",
                "book": 1,
                "page": 119,
                "row": 2,
                "hexCode": "#D06D7B",
                "rgb": "208,109,123"
            },
            {
                "nr": "PANTONE 17-1929 TPG",
                "name": "Rapture Rose",
                "book": 1,
                "page": 119,
                "row": 3,
                "hexCode": "#CD6880",
                "rgb": "205,104,128"
            },
            {
                "nr": "PANTONE 18-1634 TPG",
                "name": "Baroque Rose",
                "book": 1,
                "page": 119,
                "row": 4,
                "hexCode": "#B05A67",
                "rgb": "176,90,103"
            },
            {
                "nr": "PANTONE 18-1635 TPG",
                "name": "Slate Rose",
                "book": 1,
                "page": 119,
                "row": 5,
                "hexCode": "#B55D6B",
                "rgb": "181,93,107"
            },
            {
                "nr": "PANTONE 17-1633 TPG",
                "name": "Holly Berry",
                "book": 1,
                "page": 119,
                "row": 6,
                "hexCode": "#B45463",
                "rgb": "180,84,99"
            },
            {
                "nr": "PANTONE 18-1633 TPG",
                "name": "Garnet Rose",
                "book": 1,
                "page": 119,
                "row": 7,
                "hexCode": "#B05562",
                "rgb": "176,85,98"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1911 TPG",
                "name": "Candy Pink",
                "book": 1,
                "page": 120,
                "row": 1,
                "hexCode": "#F5B2C0",
                "rgb": "244,177,192"
            },
            {
                "nr": "PANTONE 15-1816 TPG",
                "name": "Peony",
                "book": 1,
                "page": 120,
                "row": 2,
                "hexCode": "#EC9EAC",
                "rgb": "235,158,171"
            },
            {
                "nr": "PANTONE 14-1714 TPG",
                "name": "Quartz Pink",
                "book": 1,
                "page": 120,
                "row": 3,
                "hexCode": "#EFABAE",
                "rgb": "239,171,174"
            },
            {
                "nr": "PANTONE 15-1717 TPG",
                "name": "Pink Icing",
                "book": 1,
                "page": 120,
                "row": 4,
                "hexCode": "#ECA1A6",
                "rgb": "236,161,166"
            },
            {
                "nr": "PANTONE 15-1821 TPG",
                "name": "Flamingo Pink",
                "book": 1,
                "page": 120,
                "row": 5,
                "hexCode": "#F498A0",
                "rgb": "244,152,160"
            },
            {
                "nr": "PANTONE 15-1624 TPG",
                "name": "Conch Shell",
                "book": 1,
                "page": 120,
                "row": 6,
                "hexCode": "#F88D97",
                "rgb": "248,141,151"
            },
            {
                "nr": "PANTONE 15-1626 TPG",
                "name": "Salmon Rose",
                "book": 1,
                "page": 120,
                "row": 7,
                "hexCode": "#FC8A93",
                "rgb": "252,138,147"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1611 TPG",
                "name": "Bridal Rose",
                "book": 1,
                "page": 121,
                "row": 1,
                "hexCode": "#D6A3A4",
                "rgb": "214,163,164"
            },
            {
                "nr": "PANTONE 15-1614 TPG",
                "name": "Blush",
                "book": 1,
                "page": 121,
                "row": 2,
                "hexCode": "#CF969E",
                "rgb": "207,150,158"
            },
            {
                "nr": "PANTONE 16-1610 TPG",
                "name": "Brandied Apricot",
                "book": 1,
                "page": 121,
                "row": 3,
                "hexCode": "#CC8991",
                "rgb": "204,137,145"
            },
            {
                "nr": "PANTONE 16-1720 TPG",
                "name": "Strawberry Ice",
                "book": 1,
                "page": 121,
                "row": 4,
                "hexCode": "#E4888F",
                "rgb": "228,136,143"
            },
            {
                "nr": "PANTONE 16-1626 TPG",
                "name": "Peach Blossom",
                "book": 1,
                "page": 121,
                "row": 5,
                "hexCode": "#E18287",
                "rgb": "225,130,135"
            },
            {
                "nr": "PANTONE 16-1620 TPG",
                "name": "Tea Rose",
                "book": 1,
                "page": 121,
                "row": 6,
                "hexCode": "#DD717D",
                "rgb": "221,113,125"
            },
            {
                "nr": "PANTONE 17-1537 TPG",
                "name": "Mineral Red",
                "book": 1,
                "page": 121,
                "row": 7,
                "hexCode": "#AE5B5F",
                "rgb": "174,91,95"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1415 TPG",
                "name": "Coral Cloud",
                "book": 1,
                "page": 122,
                "row": 1,
                "hexCode": "#E3ACA4",
                "rgb": "226,171,163"
            },
            {
                "nr": "PANTONE 16-1511 TPG",
                "name": "Rose Tan",
                "book": 1,
                "page": 122,
                "row": 2,
                "hexCode": "#CE9694",
                "rgb": "206,150,148"
            },
            {
                "nr": "PANTONE 17-1718 TPG",
                "name": "Dusty Rose",
                "book": 1,
                "page": 122,
                "row": 3,
                "hexCode": "#B77C83",
                "rgb": "183,124,131"
            },
            {
                "nr": "PANTONE 17-1514 TPG",
                "name": "Ash Rose",
                "book": 1,
                "page": 122,
                "row": 4,
                "hexCode": "#B18181",
                "rgb": "177,129,129"
            },
            {
                "nr": "PANTONE 17-1424 TPG",
                "name": "Brick Dust",
                "book": 1,
                "page": 122,
                "row": 5,
                "hexCode": "#B2736D",
                "rgb": "178,115,109"
            },
            {
                "nr": "PANTONE 17-1518 TPG",
                "name": "Old Rose",
                "book": 1,
                "page": 122,
                "row": 6,
                "hexCode": "#B17C7B",
                "rgb": "177,124,123"
            },
            {
                "nr": "PANTONE 17-1520 TPG",
                "name": "Canyon Rose",
                "book": 1,
                "page": 122,
                "row": 7,
                "hexCode": "#B07370",
                "rgb": "176,115,112"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1506 TPG",
                "name": "Rose Smoke",
                "book": 1,
                "page": 123,
                "row": 1,
                "hexCode": "#D6B9B1",
                "rgb": "214,185,177"
            },
            {
                "nr": "PANTONE 14-1803 TPG",
                "name": "Sepia Rose",
                "book": 1,
                "page": 123,
                "row": 2,
                "hexCode": "#DABEBA",
                "rgb": "217,189,185"
            },
            {
                "nr": "PANTONE 15-1512 TPG",
                "name": "Misty Rose",
                "book": 1,
                "page": 123,
                "row": 3,
                "hexCode": "#CDA7A0",
                "rgb": "204,167,159"
            },
            {
                "nr": "PANTONE 15-1516 TPG",
                "name": "Peach Beige",
                "book": 1,
                "page": 123,
                "row": 4,
                "hexCode": "#D4A299",
                "rgb": "211,161,153"
            },
            {
                "nr": "PANTONE 16-1516 TPG",
                "name": "Cameo Brown",
                "book": 1,
                "page": 123,
                "row": 5,
                "hexCode": "#BF8B84",
                "rgb": "190,138,131"
            },
            {
                "nr": "PANTONE 18-1435 TPG",
                "name": "Withered Rose",
                "book": 1,
                "page": 123,
                "row": 6,
                "hexCode": "#A26E6C",
                "rgb": "162,110,108"
            },
            {
                "nr": "PANTONE 18-1426 TPG",
                "name": "Apple Butter",
                "book": 1,
                "page": 123,
                "row": 7,
                "hexCode": "#885558",
                "rgb": "135,84,87"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1515 TPG",
                "name": "Mellow Rose",
                "book": 1,
                "page": 124,
                "row": 1,
                "hexCode": "#DDA9A2",
                "rgb": "220,169,162"
            },
            {
                "nr": "PANTONE 16-1518 TPG",
                "name": "Rosette",
                "book": 1,
                "page": 124,
                "row": 2,
                "hexCode": "#CB8986",
                "rgb": "203,137,133"
            },
            {
                "nr": "PANTONE 16-1522 TPG",
                "name": "Rose Dawn",
                "book": 1,
                "page": 124,
                "row": 3,
                "hexCode": "#C2867D",
                "rgb": "194,134,125"
            },
            {
                "nr": "PANTONE 17-1524 TPG",
                "name": "Desert Sand",
                "book": 1,
                "page": 124,
                "row": 4,
                "hexCode": "#BA7C76",
                "rgb": "186,124,118"
            },
            {
                "nr": "PANTONE 18-1436 TPG",
                "name": "Light Mahogany",
                "book": 1,
                "page": 124,
                "row": 5,
                "hexCode": "#AF736D",
                "rgb": "175,115,109"
            },
            {
                "nr": "PANTONE 17-1525 TPG",
                "name": "Cedar Wood",
                "book": 1,
                "page": 124,
                "row": 6,
                "hexCode": "#A16D64",
                "rgb": "161,109,100"
            },
            {
                "nr": "PANTONE 17-1516 TPG",
                "name": "Burlwood",
                "book": 1,
                "page": 124,
                "row": 7,
                "hexCode": "#9A7673",
                "rgb": "154,118,114"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1418 TPG",
                "name": "Peach Melba",
                "book": 1,
                "page": 125,
                "row": 1,
                "hexCode": "#F9BCB0",
                "rgb": "249,188,176"
            },
            {
                "nr": "PANTONE 13-1318 TPG",
                "name": "Tropical Peach",
                "book": 1,
                "page": 125,
                "row": 2,
                "hexCode": "#FEC4B2",
                "rgb": "254,196,178"
            },
            {
                "nr": "PANTONE 14-1419 TPG",
                "name": "Peach Pearl",
                "book": 1,
                "page": 125,
                "row": 3,
                "hexCode": "#F9B6A8",
                "rgb": "249,182,168"
            },
            {
                "nr": "PANTONE 14-1420 TPG",
                "name": "Apricot Blush",
                "book": 1,
                "page": 125,
                "row": 4,
                "hexCode": "#FAB1A9",
                "rgb": "250,177,169"
            },
            {
                "nr": "PANTONE 14-1324 TPG",
                "name": "Peach Bud",
                "book": 1,
                "page": 125,
                "row": 5,
                "hexCode": "#FBB2A7",
                "rgb": "251,178,167"
            },
            {
                "nr": "PANTONE 15-1423 TPG",
                "name": "Peach Amber",
                "book": 1,
                "page": 125,
                "row": 6,
                "hexCode": "#F99F93",
                "rgb": "249,159,147"
            },
            {
                "nr": "PANTONE 15-1621 TPG",
                "name": "Candlelight Peach",
                "book": 1,
                "page": 125,
                "row": 7,
                "hexCode": "#F4A09F",
                "rgb": "244,160,159"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1513 TPG",
                "name": "Gossamer Pink",
                "book": 1,
                "page": 126,
                "row": 1,
                "hexCode": "#F9C5C2",
                "rgb": "249,197,193"
            },
            {
                "nr": "PANTONE 13-1310 TPG",
                "name": "English Rose",
                "book": 1,
                "page": 126,
                "row": 2,
                "hexCode": "#F4C3C0",
                "rgb": "244,195,191"
            },
            {
                "nr": "PANTONE 13-1510 TPG",
                "name": "Impatiens Pink",
                "book": 1,
                "page": 126,
                "row": 3,
                "hexCode": "#FDC2BA",
                "rgb": "253,194,186"
            },
            {
                "nr": "PANTONE 14-1511 TPG",
                "name": "Powder Pink",
                "book": 1,
                "page": 126,
                "row": 4,
                "hexCode": "#EBB6B7",
                "rgb": "235,182,183"
            },
            {
                "nr": "PANTONE 14-1513 TPG",
                "name": "Blossom",
                "book": 1,
                "page": 126,
                "row": 5,
                "hexCode": "#ECAEAB",
                "rgb": "236,174,171"
            },
            {
                "nr": "PANTONE 14-1521 TPG",
                "name": "Peaches N' Cream",
                "book": 1,
                "page": 126,
                "row": 6,
                "hexCode": "#F4A6A2",
                "rgb": "244,166,162"
            },
            {
                "nr": "PANTONE 16-1434 TPG",
                "name": "Coral Almond",
                "book": 1,
                "page": 126,
                "row": 7,
                "hexCode": "#E29A93",
                "rgb": "226,154,147"
            }
        ],
        [
            {
                "nr": "PANTONE 13-2005 TPG",
                "name": "Strawberry Cream",
                "book": 1,
                "page": 127,
                "row": 1,
                "hexCode": "#F4C3C4",
                "rgb": "243,195,195"
            },
            {
                "nr": "PANTONE 13-2003 TPG",
                "name": "First Blush",
                "book": 1,
                "page": 127,
                "row": 2,
                "hexCode": "#F5C2C2",
                "rgb": "245,194,194"
            },
            {
                "nr": "PANTONE 12-1708 TPG",
                "name": "Crystal Rose",
                "book": 1,
                "page": 127,
                "row": 3,
                "hexCode": "#F8C5C8",
                "rgb": "248,197,199"
            },
            {
                "nr": "PANTONE 14-1910 TPG",
                "name": "Tickled Pink",
                "book": 1,
                "page": 127,
                "row": 4,
                "hexCode": "#F6BCC6",
                "rgb": "246,188,198"
            },
            {
                "nr": "PANTONE 13-1716 TPG",
                "name": "Pale Rosette",
                "book": 1,
                "page": 127,
                "row": 5,
                "hexCode": "#FFBBB9",
                "rgb": "255,187,185"
            },
            {
                "nr": "PANTONE 16-1615 TPG",
                "name": "Flamingo Plume",
                "book": 1,
                "page": 127,
                "row": 6,
                "hexCode": "#E28790",
                "rgb": "226,135,144"
            },
            {
                "nr": "PANTONE 15-1919 TPG",
                "name": "Dianthus",
                "book": 1,
                "page": 127,
                "row": 7,
                "hexCode": "#F898A6",
                "rgb": "248,152,166"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1501 TPG",
                "name": "Magano Calcite",
                "book": 1,
                "page": 128,
                "row": 1,
                "hexCode": "#F3D6D1",
                "rgb": "243,214,209"
            },
            {
                "nr": "PANTONE 12-1212 TPG",
                "name": "Veiled Rose",
                "book": 1,
                "page": 128,
                "row": 2,
                "hexCode": "#F5CEC8",
                "rgb": "245,205,200"
            },
            {
                "nr": "PANTONE 13-1408 TPG",
                "name": "Chintz Rose",
                "book": 1,
                "page": 128,
                "row": 3,
                "hexCode": "#EFC5BE",
                "rgb": "239,196,189"
            },
            {
                "nr": "PANTONE 13-1409 TPG",
                "name": "Seashell Pink",
                "book": 1,
                "page": 128,
                "row": 4,
                "hexCode": "#F6C8C3",
                "rgb": "245,200,194"
            },
            {
                "nr": "PANTONE 13-1511 TPG",
                "name": "Pink Salt",
                "book": 1,
                "page": 128,
                "row": 5,
                "hexCode": "#FAD0C9",
                "rgb": "250,208,201"
            },
            {
                "nr": "PANTONE 13-1520 TPG",
                "name": "Rose Quartz",
                "book": 1,
                "page": 128,
                "row": 6,
                "hexCode": "#F8CDCD",
                "rgb": "247,205,204"
            },
            {
                "nr": "PANTONE 15-1712 TPG",
                "name": "Murex Shell",
                "book": 1,
                "page": 128,
                "row": 7,
                "hexCode": "#FAA3A5",
                "rgb": "250,163,165"
            }
        ],
        [
            {
                "nr": "PANTONE 12-1303 TPG",
                "name": "Carinaria",
                "book": 1,
                "page": 129,
                "row": 1,
                "hexCode": "#F1DFE1",
                "rgb": "241,223,225"
            },
            {
                "nr": "PANTONE 12-1304 TPG",
                "name": "Pearl",
                "book": 1,
                "page": 129,
                "row": 2,
                "hexCode": "#F3DAD5",
                "rgb": "243,218,213"
            },
            {
                "nr": "PANTONE 12-1706 TPG",
                "name": "Pink Dogwood",
                "book": 1,
                "page": 129,
                "row": 3,
                "hexCode": "#F5D3D4",
                "rgb": "244,211,211"
            },
            {
                "nr": "PANTONE 13-2004 TPG",
                "name": "Potpourri",
                "book": 1,
                "page": 129,
                "row": 4,
                "hexCode": "#E9C8CA",
                "rgb": "232,200,201"
            },
            {
                "nr": "PANTONE 14-1907 TPG",
                "name": "Peachskin",
                "book": 1,
                "page": 129,
                "row": 5,
                "hexCode": "#E1B8B9",
                "rgb": "225,183,185"
            },
            {
                "nr": "PANTONE 14-1508 TPG",
                "name": "Silver Pink",
                "book": 1,
                "page": 129,
                "row": 6,
                "hexCode": "#DCB4B2",
                "rgb": "220,180,178"
            },
            {
                "nr": "PANTONE 14-1904 TPG",
                "name": "Pink Dolphin",
                "book": 1,
                "page": 129,
                "row": 7,
                "hexCode": "#EBB1B9",
                "rgb": "235,177,185"
            }
        ],
        [
            {
                "nr": "PANTONE 11-2409 TPG",
                "name": "Delicacy",
                "book": 1,
                "page": 130,
                "row": 1,
                "hexCode": "#F0E2E0",
                "rgb": "240,226,224"
            },
            {
                "nr": "PANTONE 12-1305 TPG",
                "name": "Heavenly Pink",
                "book": 1,
                "page": 130,
                "row": 2,
                "hexCode": "#F3DEDF",
                "rgb": "242,222,223"
            },
            {
                "nr": "PANTONE 11-1408 TPG",
                "name": "Rosewater",
                "book": 1,
                "page": 130,
                "row": 3,
                "hexCode": "#F2DBD7",
                "rgb": "242,218,215"
            },
            {
                "nr": "PANTONE 12-2102 TPG",
                "name": "Mauve Morn",
                "book": 1,
                "page": 130,
                "row": 4,
                "hexCode": "#EAD9D8",
                "rgb": "234,217,216"
            },
            {
                "nr": "PANTONE 12-1605 TPG",
                "name": "Crystal Pink",
                "book": 1,
                "page": 130,
                "row": 5,
                "hexCode": "#EED4D1",
                "rgb": "237,211,208"
            },
            {
                "nr": "PANTONE 14-1905 TPG",
                "name": "Lotus",
                "book": 1,
                "page": 130,
                "row": 6,
                "hexCode": "#E3C1C1",
                "rgb": "227,193,192"
            },
            {
                "nr": "PANTONE 13-2007 TPG",
                "name": "Barely There",
                "book": 1,
                "page": 130,
                "row": 7,
                "hexCode": "#E7C6C8",
                "rgb": "231,198,200"
            }
        ],
        [
            {
                "nr": "PANTONE 12-1310 TPG",
                "name": "Blushing Bride",
                "book": 1,
                "page": 131,
                "row": 1,
                "hexCode": "#F5D3DB",
                "rgb": "245,210,218"
            },
            {
                "nr": "PANTONE 12-1813 TPG",
                "name": "Mary's Rose",
                "book": 1,
                "page": 131,
                "row": 2,
                "hexCode": "#F8D3D5",
                "rgb": "248,211,213"
            },
            {
                "nr": "PANTONE 12-2902 TPG",
                "name": "Mauve Chalk",
                "book": 1,
                "page": 131,
                "row": 3,
                "hexCode": "#E3D2CF",
                "rgb": "227,210,207"
            },
            {
                "nr": "PANTONE 12-2904 TPG",
                "name": "Primrose Pink",
                "book": 1,
                "page": 131,
                "row": 4,
                "hexCode": "#EDD6D9",
                "rgb": "237,214,217"
            },
            {
                "nr": "PANTONE 13-2803 TPG",
                "name": "Pale Lilac",
                "book": 1,
                "page": 131,
                "row": 5,
                "hexCode": "#E3C9CE",
                "rgb": "226,201,205"
            },
            {
                "nr": "PANTONE 13-1904 TPG",
                "name": "Chalk Pink",
                "book": 1,
                "page": 131,
                "row": 6,
                "hexCode": "#E8C7CE",
                "rgb": "231,199,205"
            },
            {
                "nr": "PANTONE 12-1808 TPG",
                "name": "Pinkesque",
                "book": 1,
                "page": 131,
                "row": 7,
                "hexCode": "#F5CCD1",
                "rgb": "245,204,209"
            }
        ],
        [
            {
                "nr": "PANTONE 12-2807 TPG",
                "name": "Tender Touch",
                "book": 1,
                "page": 132,
                "row": 1,
                "hexCode": "#F2D6E1",
                "rgb": "242,214,225"
            },
            {
                "nr": "PANTONE 13-2808 TPG",
                "name": "Ballet Slipper",
                "book": 1,
                "page": 132,
                "row": 2,
                "hexCode": "#F0D1D8",
                "rgb": "239,209,216"
            },
            {
                "nr": "PANTONE 13-2802 TPG",
                "name": "Fairy Tale",
                "book": 1,
                "page": 132,
                "row": 3,
                "hexCode": "#EFC2D1",
                "rgb": "238,193,209"
            },
            {
                "nr": "PANTONE 13-1906 TPG",
                "name": "Rose Shadow",
                "book": 1,
                "page": 132,
                "row": 4,
                "hexCode": "#F7C2D0",
                "rgb": "246,194,207"
            },
            {
                "nr": "PANTONE 13-2006 TPG",
                "name": "Almond Blossom",
                "book": 1,
                "page": 132,
                "row": 5,
                "hexCode": "#F5BDC8",
                "rgb": "245,189,200"
            },
            {
                "nr": "PANTONE 13-2010 TPG",
                "name": "Orchid Pink",
                "book": 1,
                "page": 132,
                "row": 6,
                "hexCode": "#F1BCCA",
                "rgb": "240,188,202"
            },
            {
                "nr": "PANTONE 14-1909 TPG",
                "name": "Coral Blush",
                "book": 1,
                "page": 132,
                "row": 7,
                "hexCode": "#E7B6BE",
                "rgb": "231,181,189"
            }
        ],
        [
            {
                "nr": "PANTONE 11-2511 TPG",
                "name": "Shrinking Violet",
                "book": 1,
                "page": 133,
                "row": 1,
                "hexCode": "#F0DDE0",
                "rgb": "240,221,224"
            },
            {
                "nr": "PANTONE 12-2906 TPG",
                "name": "Barely Pink",
                "book": 1,
                "page": 133,
                "row": 2,
                "hexCode": "#F1D7DD",
                "rgb": "241,215,220"
            },
            {
                "nr": "PANTONE 13-2807 TPG",
                "name": "Ballerina",
                "book": 1,
                "page": 133,
                "row": 3,
                "hexCode": "#ECCEDA",
                "rgb": "236,206,218"
            },
            {
                "nr": "PANTONE 13-2801 TPG",
                "name": "Pink-a-boo",
                "book": 1,
                "page": 133,
                "row": 4,
                "hexCode": "#F6CFDD",
                "rgb": "246,207,221"
            },
            {
                "nr": "PANTONE 14-2304 TPG",
                "name": "Bleached Mauve",
                "book": 1,
                "page": 133,
                "row": 5,
                "hexCode": "#D9B0B9",
                "rgb": "217,176,185"
            },
            {
                "nr": "PANTONE 13-2804 TPG",
                "name": "Parfait Pink",
                "book": 1,
                "page": 133,
                "row": 6,
                "hexCode": "#EAC4CF",
                "rgb": "234,195,206"
            },
            {
                "nr": "PANTONE 13-2008 TPG",
                "name": "Romance Rose",
                "book": 1,
                "page": 133,
                "row": 7,
                "hexCode": "#EAB4C4",
                "rgb": "234,180,196"
            }
        ],
        [
            {
                "nr": "PANTONE 12-2907 TPG",
                "name": "Pink Marshmallow",
                "book": 1,
                "page": 134,
                "row": 1,
                "hexCode": "#F8E3E8",
                "rgb": "247,226,232"
            },
            {
                "nr": "PANTONE 11-2509 TPG",
                "name": "Calcite",
                "book": 1,
                "page": 134,
                "row": 2,
                "hexCode": "#EADEE3",
                "rgb": "234,222,227"
            },
            {
                "nr": "PANTONE 12-2905 TPG",
                "name": "Cradle Pink",
                "book": 1,
                "page": 134,
                "row": 3,
                "hexCode": "#E7CDD9",
                "rgb": "231,205,216"
            },
            {
                "nr": "PANTONE 13-3406 TPG",
                "name": "Orchid Ice",
                "book": 1,
                "page": 134,
                "row": 4,
                "hexCode": "#DCCED9",
                "rgb": "219,206,216"
            },
            {
                "nr": "PANTONE 12-2903 TPG",
                "name": "Light Lilac",
                "book": 1,
                "page": 134,
                "row": 5,
                "hexCode": "#E0C7D2",
                "rgb": "223,198,210"
            },
            {
                "nr": "PANTONE 13-3405 TPG",
                "name": "Lilac Snow",
                "book": 1,
                "page": 134,
                "row": 6,
                "hexCode": "#E1C7D9",
                "rgb": "225,198,216"
            },
            {
                "nr": "PANTONE 14-3203 TPG",
                "name": "Corsage",
                "book": 1,
                "page": 134,
                "row": 7,
                "hexCode": "#DDB5C8",
                "rgb": "221,181,200"
            }
        ],
        [
            {
                "nr": "PANTONE 12-2908 TPG",
                "name": "Festival Bloom",
                "book": 1,
                "page": 135,
                "row": 1,
                "hexCode": "#F4DAE9",
                "rgb": "244,218,233"
            },
            {
                "nr": "PANTONE 13-2806 TPG",
                "name": "Pink Lady",
                "book": 1,
                "page": 135,
                "row": 2,
                "hexCode": "#EDC1D7",
                "rgb": "237,193,215"
            },
            {
                "nr": "PANTONE 13-2805 TPG",
                "name": "Roseate Spoonbill",
                "book": 1,
                "page": 135,
                "row": 3,
                "hexCode": "#EAC0D3",
                "rgb": "233,191,211"
            },
            {
                "nr": "PANTONE 13-2809 TPG",
                "name": "Sweet Dreams",
                "book": 1,
                "page": 135,
                "row": 4,
                "hexCode": "#F1C1D8",
                "rgb": "241,193,216"
            },
            {
                "nr": "PANTONE 14-2710 TPG",
                "name": "Lilac Sachet",
                "book": 1,
                "page": 135,
                "row": 5,
                "hexCode": "#E8ADC9",
                "rgb": "232,173,201"
            },
            {
                "nr": "PANTONE 14-2808 TPG",
                "name": "Sweet Lilac",
                "book": 1,
                "page": 135,
                "row": 6,
                "hexCode": "#E7B7CF",
                "rgb": "231,182,207"
            },
            {
                "nr": "PANTONE 14-3205 TPG",
                "name": "Pirouette",
                "book": 1,
                "page": 135,
                "row": 7,
                "hexCode": "#EFC2E1",
                "rgb": "239,194,225"
            }
        ],
        [
            {
                "nr": "PANTONE 13-2800 TPG",
                "name": "Pink Tulle",
                "book": 1,
                "page": 136,
                "row": 1,
                "hexCode": "#F0CDE5",
                "rgb": "240,205,229"
            },
            {
                "nr": "PANTONE 13-3207 TPG",
                "name": "Cherry Blossom",
                "book": 1,
                "page": 136,
                "row": 2,
                "hexCode": "#F5CFE0",
                "rgb": "244,207,224"
            },
            {
                "nr": "PANTONE 14-2806 TPG",
                "name": "Nosegay",
                "book": 1,
                "page": 136,
                "row": 3,
                "hexCode": "#F0C0D8",
                "rgb": "240,192,216"
            },
            {
                "nr": "PANTONE 15-2218 TPG",
                "name": "Pink Frosting",
                "book": 1,
                "page": 136,
                "row": 4,
                "hexCode": "#F1ABCF",
                "rgb": "241,171,207"
            },
            {
                "nr": "PANTONE 15-2213 TPG",
                "name": "Bonbon",
                "book": 1,
                "page": 136,
                "row": 5,
                "hexCode": "#F1A9CF",
                "rgb": "241,169,207"
            },
            {
                "nr": "PANTONE 16-2118 TPG",
                "name": "Pink Power",
                "book": 1,
                "page": 136,
                "row": 6,
                "hexCode": "#D66D97",
                "rgb": "214,109,151"
            },
            {
                "nr": "PANTONE 17-2519 TPG",
                "name": "Gin Fizz",
                "book": 1,
                "page": 136,
                "row": 7,
                "hexCode": "#CF608F",
                "rgb": "207,96,143"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1915 TPG",
                "name": "Flowering Ginger",
                "book": 1,
                "page": 137,
                "row": 1,
                "hexCode": "#E87D98",
                "rgb": "232,125,152"
            },
            {
                "nr": "PANTONE 15-1922 TPG",
                "name": "Geranium Pink",
                "book": 1,
                "page": 137,
                "row": 2,
                "hexCode": "#F391A2",
                "rgb": "243,145,162"
            },
            {
                "nr": "PANTONE 16-1731 TPG",
                "name": "Strawberry Pink",
                "book": 1,
                "page": 137,
                "row": 3,
                "hexCode": "#F78092",
                "rgb": "247,128,146"
            },
            {
                "nr": "PANTONE 17-1928 TPG",
                "name": "Bubblegum",
                "book": 1,
                "page": 137,
                "row": 4,
                "hexCode": "#E67591",
                "rgb": "230,117,145"
            },
            {
                "nr": "PANTONE 17-1930 TPG",
                "name": "Camellia Rose",
                "book": 1,
                "page": 137,
                "row": 5,
                "hexCode": "#F16886",
                "rgb": "241,104,134"
            },
            {
                "nr": "PANTONE 17-1926 TPG",
                "name": "Fruit Dove",
                "book": 1,
                "page": 137,
                "row": 6,
                "hexCode": "#D36480",
                "rgb": "210,100,127"
            },
            {
                "nr": "PANTONE 17-1739 TPG",
                "name": "Rethink Pink",
                "book": 1,
                "page": 137,
                "row": 7,
                "hexCode": "#CA4564",
                "rgb": "202,69,100"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1723 TPG",
                "name": "Confetti",
                "book": 1,
                "page": 138,
                "row": 1,
                "hexCode": "#E87A8F",
                "rgb": "232,122,143"
            },
            {
                "nr": "PANTONE 16-1735 TPG",
                "name": "Pink Lemonade",
                "book": 1,
                "page": 138,
                "row": 2,
                "hexCode": "#F97A94",
                "rgb": "249,122,148"
            },
            {
                "nr": "PANTONE 17-1741 TPG",
                "name": "Party Punch",
                "book": 1,
                "page": 138,
                "row": 3,
                "hexCode": "#C4516C",
                "rgb": "196,81,108"
            },
            {
                "nr": "PANTONE 17-1744 TPG",
                "name": "Calypso Coral",
                "book": 1,
                "page": 138,
                "row": 4,
                "hexCode": "#EE6679",
                "rgb": "238,102,121"
            },
            {
                "nr": "PANTONE 17-1640 TPG",
                "name": "Winterberry",
                "book": 1,
                "page": 138,
                "row": 5,
                "hexCode": "#BE4253",
                "rgb": "190,66,83"
            },
            {
                "nr": "PANTONE 18-1665 TPG",
                "name": "Watermelon",
                "book": 1,
                "page": 138,
                "row": 6,
                "hexCode": "#CC4358",
                "rgb": "204,67,88"
            },
            {
                "nr": "PANTONE 18-1750 TPG",
                "name": "Viva Magenta",
                "book": 1,
                "page": 138,
                "row": 7,
                "hexCode": "#BE3455",
                "rgb": "190,52,85"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1822 TPG",
                "name": "Plumeria",
                "book": 1,
                "page": 139,
                "row": 1,
                "hexCode": "#F891A3",
                "rgb": "248,145,163"
            },
            {
                "nr": "PANTONE 17-1746 TPG",
                "name": "Coral Paradise",
                "book": 1,
                "page": 139,
                "row": 2,
                "hexCode": "#ED6075",
                "rgb": "237,96,117"
            },
            {
                "nr": "PANTONE 17-1736 TPG",
                "name": "Sun Kissed Coral",
                "book": 1,
                "page": 139,
                "row": 3,
                "hexCode": "#E46F7F",
                "rgb": "228,111,127"
            },
            {
                "nr": "PANTONE 17-1755 TPG",
                "name": "Paradise Pink",
                "book": 1,
                "page": 139,
                "row": 4,
                "hexCode": "#E54C66",
                "rgb": "229,76,102"
            },
            {
                "nr": "PANTONE 17-1753 TPG",
                "name": "Geranium",
                "book": 1,
                "page": 139,
                "row": 5,
                "hexCode": "#D74A61",
                "rgb": "215,74,97"
            },
            {
                "nr": "PANTONE 18-1755 TPG",
                "name": "Rouge Red",
                "book": 1,
                "page": 139,
                "row": 6,
                "hexCode": "#E34F6A",
                "rgb": "227,79,106"
            },
            {
                "nr": "PANTONE 18-1756 TPG",
                "name": "Teaberry",
                "book": 1,
                "page": 139,
                "row": 7,
                "hexCode": "#DC445E",
                "rgb": "220,68,94"
            }
        ],
        [
            {
                "nr": "PANTONE 18-2120 TPG",
                "name": "Honeysuckle",
                "book": 1,
                "page": 140,
                "row": 1,
                "hexCode": "#D85A7A",
                "rgb": "216,90,122"
            },
            {
                "nr": "PANTONE 17-1740 TPG",
                "name": "Claret Red",
                "book": 1,
                "page": 140,
                "row": 2,
                "hexCode": "#C35268",
                "rgb": "195,82,104"
            },
            {
                "nr": "PANTONE 18-1741 TPG",
                "name": "Raspberry Wine",
                "book": 1,
                "page": 140,
                "row": 3,
                "hexCode": "#B63E5B",
                "rgb": "182,62,91"
            },
            {
                "nr": "PANTONE 18-1852 TPG",
                "name": "Rose Red",
                "book": 1,
                "page": 140,
                "row": 4,
                "hexCode": "#C3365B",
                "rgb": "195,54,91"
            },
            {
                "nr": "PANTONE 18-1760 TPG",
                "name": "Barberry",
                "book": 1,
                "page": 140,
                "row": 5,
                "hexCode": "#B3334D",
                "rgb": "179,51,77"
            },
            {
                "nr": "PANTONE 17-1842 TPG",
                "name": "Azalea",
                "book": 1,
                "page": 140,
                "row": 6,
                "hexCode": "#D74465",
                "rgb": "215,68,101"
            },
            {
                "nr": "PANTONE 18-1856 TPG",
                "name": "Virtual Pink",
                "book": 1,
                "page": 140,
                "row": 7,
                "hexCode": "#CA335B",
                "rgb": "202,51,91"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1754 TPG",
                "name": "Raspberry",
                "book": 1,
                "page": 141,
                "row": 1,
                "hexCode": "#D64061",
                "rgb": "214,64,97"
            },
            {
                "nr": "PANTONE 18-1945 TPG",
                "name": "Bright Rose",
                "book": 1,
                "page": 141,
                "row": 2,
                "hexCode": "#C0335F",
                "rgb": "192,51,95"
            },
            {
                "nr": "PANTONE 18-1951 TPG",
                "name": "Love Potion",
                "book": 1,
                "page": 141,
                "row": 3,
                "hexCode": "#BF3360",
                "rgb": "190,51,95"
            },
            {
                "nr": "PANTONE 18-1950 TPG",
                "name": "Jazzy",
                "book": 1,
                "page": 141,
                "row": 4,
                "hexCode": "#B43258",
                "rgb": "180,50,88"
            },
            {
                "nr": "PANTONE 19-1955 TPG",
                "name": "Cerise",
                "book": 1,
                "page": 141,
                "row": 5,
                "hexCode": "#9F3452",
                "rgb": "159,52,82"
            },
            {
                "nr": "PANTONE 19-1860 TPG",
                "name": "Persian Red",
                "book": 1,
                "page": 141,
                "row": 6,
                "hexCode": "#A2354F",
                "rgb": "162,53,79"
            },
            {
                "nr": "PANTONE 19-1850 TPG",
                "name": "Red Bud",
                "book": 1,
                "page": 141,
                "row": 7,
                "hexCode": "#9B3D55",
                "rgb": "154,61,85"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1937 TPG",
                "name": "Hot Pink",
                "book": 1,
                "page": 142,
                "row": 1,
                "hexCode": "#E86287",
                "rgb": "232,98,135"
            },
            {
                "nr": "PANTONE 18-2043 TPG",
                "name": "Raspberry Sorbet",
                "book": 1,
                "page": 142,
                "row": 2,
                "hexCode": "#CD4976",
                "rgb": "205,73,118"
            },
            {
                "nr": "PANTONE 19-2039 TPG",
                "name": "Granita",
                "book": 1,
                "page": 142,
                "row": 3,
                "hexCode": "#9F3958",
                "rgb": "159,57,88"
            },
            {
                "nr": "PANTONE 19-2041 TPG",
                "name": "Cherries Jubilee",
                "book": 1,
                "page": 142,
                "row": 4,
                "hexCode": "#9B3355",
                "rgb": "155,51,85"
            },
            {
                "nr": "PANTONE 19-2045 TPG",
                "name": "Vivacious",
                "book": 1,
                "page": 142,
                "row": 5,
                "hexCode": "#A93660",
                "rgb": "169,54,96"
            },
            {
                "nr": "PANTONE 19-2047 TPG",
                "name": "Sangria",
                "book": 1,
                "page": 142,
                "row": 6,
                "hexCode": "#9F3E5D",
                "rgb": "159,61,92"
            },
            {
                "nr": "PANTONE 19-2033 TPG",
                "name": "Anemone",
                "book": 1,
                "page": 142,
                "row": 7,
                "hexCode": "#893A53",
                "rgb": "137,58,83"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1920 TPG",
                "name": "Morning Glory",
                "book": 1,
                "page": 143,
                "row": 1,
                "hexCode": "#E981A0",
                "rgb": "233,129,160"
            },
            {
                "nr": "PANTONE 16-2126 TPG",
                "name": "Azalea Pink",
                "book": 1,
                "page": 143,
                "row": 2,
                "hexCode": "#E46C99",
                "rgb": "228,108,153"
            },
            {
                "nr": "PANTONE 17-2127 TPG",
                "name": "Shocking Pink",
                "book": 1,
                "page": 143,
                "row": 3,
                "hexCode": "#D6638D",
                "rgb": "214,99,141"
            },
            {
                "nr": "PANTONE 17-2230 TPG",
                "name": "Carmine Rose",
                "book": 1,
                "page": 143,
                "row": 4,
                "hexCode": "#DB6292",
                "rgb": "219,98,146"
            },
            {
                "nr": "PANTONE 17-2033 TPG",
                "name": "Fandango Pink",
                "book": 1,
                "page": 143,
                "row": 5,
                "hexCode": "#DC5A85",
                "rgb": "220,90,133"
            },
            {
                "nr": "PANTONE 18-2133 TPG",
                "name": "Pink Flambé",
                "book": 1,
                "page": 143,
                "row": 6,
                "hexCode": "#CD567E",
                "rgb": "205,86,126"
            },
            {
                "nr": "PANTONE 17-1831 TPG",
                "name": "Carmine",
                "book": 1,
                "page": 143,
                "row": 7,
                "hexCode": "#BA4F71",
                "rgb": "186,79,113"
            }
        ],
        [
            {
                "nr": "PANTONE 14-2311 TPG",
                "name": "Prism Pink",
                "book": 1,
                "page": 144,
                "row": 1,
                "hexCode": "#EEA4C5",
                "rgb": "238,164,197"
            },
            {
                "nr": "PANTONE 15-2216 TPG",
                "name": "Sachet Pink",
                "book": 1,
                "page": 144,
                "row": 2,
                "hexCode": "#F38BB0",
                "rgb": "243,139,176"
            },
            {
                "nr": "PANTONE 15-2217 TPG",
                "name": "Aurora Pink",
                "book": 1,
                "page": 144,
                "row": 3,
                "hexCode": "#E583A9",
                "rgb": "229,131,169"
            },
            {
                "nr": "PANTONE 16-2124 TPG",
                "name": "Pink Carnation",
                "book": 1,
                "page": 144,
                "row": 4,
                "hexCode": "#EA7DA4",
                "rgb": "234,125,164"
            },
            {
                "nr": "PANTONE 16-2120 TPG",
                "name": "Wild Orchid",
                "book": 1,
                "page": 144,
                "row": 5,
                "hexCode": "#D573A0",
                "rgb": "213,115,160"
            },
            {
                "nr": "PANTONE 17-2120 TPG",
                "name": "Chateau Rose",
                "book": 1,
                "page": 144,
                "row": 6,
                "hexCode": "#D47897",
                "rgb": "212,120,151"
            },
            {
                "nr": "PANTONE 17-2520 TPG",
                "name": "Ibis Rose",
                "book": 1,
                "page": 144,
                "row": 7,
                "hexCode": "#C66490",
                "rgb": "198,100,144"
            }
        ],
        [
            {
                "nr": "PANTONE 15-2215 TPG",
                "name": "Begonia Pink",
                "book": 1,
                "page": 145,
                "row": 1,
                "hexCode": "#E89ABB",
                "rgb": "232,154,187"
            },
            {
                "nr": "PANTONE 15-2214 TPG",
                "name": "Rosebloom",
                "book": 1,
                "page": 145,
                "row": 2,
                "hexCode": "#E18FB3",
                "rgb": "225,143,179"
            },
            {
                "nr": "PANTONE 15-2718 TPG",
                "name": "Fuchsia Pink",
                "book": 1,
                "page": 145,
                "row": 3,
                "hexCode": "#E08CB8",
                "rgb": "224,140,184"
            },
            {
                "nr": "PANTONE 17-2623 TPG",
                "name": "Fiji Flower",
                "book": 1,
                "page": 145,
                "row": 4,
                "hexCode": "#CF6AA2",
                "rgb": "207,106,162"
            },
            {
                "nr": "PANTONE 17-2227 TPG",
                "name": "Lilac Rose",
                "book": 1,
                "page": 145,
                "row": 5,
                "hexCode": "#BE4C7C",
                "rgb": "190,76,124"
            },
            {
                "nr": "PANTONE 18-2333 TPG",
                "name": "Raspberry Rose",
                "book": 1,
                "page": 145,
                "row": 6,
                "hexCode": "#C7508B",
                "rgb": "199,80,139"
            },
            {
                "nr": "PANTONE 17-2031 TPG",
                "name": "Fuchsia Rose",
                "book": 1,
                "page": 145,
                "row": 7,
                "hexCode": "#C34D7B",
                "rgb": "195,77,123"
            }
        ],
        [
            {
                "nr": "PANTONE 16-2122 TPG",
                "name": "Pink Cosmos",
                "book": 1,
                "page": 146,
                "row": 1,
                "hexCode": "#ED7FA8",
                "rgb": "237,127,168"
            },
            {
                "nr": "PANTONE 17-2235 TPG",
                "name": "Sangria Sunset",
                "book": 1,
                "page": 146,
                "row": 2,
                "hexCode": "#E66694",
                "rgb": "230,102,148"
            },
            {
                "nr": "PANTONE 18-2330 TPG",
                "name": "Fuchsia Fedora",
                "book": 1,
                "page": 146,
                "row": 3,
                "hexCode": "#CC4C8B",
                "rgb": "204,76,139"
            },
            {
                "nr": "PANTONE 17-2034 TPG",
                "name": "Pink Yarrow",
                "book": 1,
                "page": 146,
                "row": 4,
                "hexCode": "#CE4A7E",
                "rgb": "205,74,125"
            },
            {
                "nr": "PANTONE 17-2036 TPG",
                "name": "Magenta",
                "book": 1,
                "page": 146,
                "row": 5,
                "hexCode": "#CE4F7F",
                "rgb": "206,79,127"
            },
            {
                "nr": "PANTONE 18-2143 TPG",
                "name": "Beetroot Purple",
                "book": 1,
                "page": 146,
                "row": 6,
                "hexCode": "#CA4172",
                "rgb": "202,65,114"
            },
            {
                "nr": "PANTONE 18-2042 TPG",
                "name": "Innuendo",
                "book": 1,
                "page": 146,
                "row": 7,
                "hexCode": "#C43F66",
                "rgb": "196,63,102"
            }
        ],
        [
            {
                "nr": "PANTONE 17-2619 TPG",
                "name": "Strawberry Moon",
                "book": 1,
                "page": 147,
                "row": 1,
                "hexCode": "#DD72A8",
                "rgb": "221,114,168"
            },
            {
                "nr": "PANTONE 17-2625 TPG",
                "name": "Super Pink",
                "book": 1,
                "page": 147,
                "row": 2,
                "hexCode": "#CE6EA7",
                "rgb": "206,110,167"
            },
            {
                "nr": "PANTONE 17-2521 TPG",
                "name": "Cone Flower",
                "book": 1,
                "page": 147,
                "row": 3,
                "hexCode": "#C15B89",
                "rgb": "193,91,137"
            },
            {
                "nr": "PANTONE 18-2436 TPG",
                "name": "Fuchsia Purple",
                "book": 1,
                "page": 147,
                "row": 4,
                "hexCode": "#CC497D",
                "rgb": "204,73,125"
            },
            {
                "nr": "PANTONE 18-2140 TPG",
                "name": "Cabaret",
                "book": 1,
                "page": 147,
                "row": 5,
                "hexCode": "#CA3E76",
                "rgb": "202,62,118"
            },
            {
                "nr": "PANTONE 18-2045 TPG",
                "name": "Pink Peacock",
                "book": 1,
                "page": 147,
                "row": 6,
                "hexCode": "#C83E74",
                "rgb": "200,62,115"
            },
            {
                "nr": "PANTONE 18-2336 TPG",
                "name": "Very Berry",
                "book": 1,
                "page": 147,
                "row": 7,
                "hexCode": "#BB457C",
                "rgb": "187,69,124"
            }
        ],
        [
            {
                "nr": "PANTONE 17-2627 TPG",
                "name": "Phlox Pink",
                "book": 1,
                "page": 148,
                "row": 1,
                "hexCode": "#D367A2",
                "rgb": "211,103,162"
            },
            {
                "nr": "PANTONE 17-2624 TPG",
                "name": "Rose Violet",
                "book": 1,
                "page": 148,
                "row": 2,
                "hexCode": "#C15391",
                "rgb": "193,83,145"
            },
            {
                "nr": "PANTONE 18-2615 TPG",
                "name": "Rosé Sorbet",
                "book": 1,
                "page": 148,
                "row": 3,
                "hexCode": "#A65382",
                "rgb": "166,83,130"
            },
            {
                "nr": "PANTONE 18-2328 TPG",
                "name": "Fuchsia Red",
                "book": 1,
                "page": 148,
                "row": 4,
                "hexCode": "#B4447F",
                "rgb": "180,68,127"
            },
            {
                "nr": "PANTONE 18-2326 TPG",
                "name": "Cactus Flower",
                "book": 1,
                "page": 148,
                "row": 5,
                "hexCode": "#AB4876",
                "rgb": "171,72,118"
            },
            {
                "nr": "PANTONE 19-2434 TPG",
                "name": "Festival Fuchsia",
                "book": 1,
                "page": 148,
                "row": 6,
                "hexCode": "#A14275",
                "rgb": "160,66,116"
            },
            {
                "nr": "PANTONE 18-1722 TPG",
                "name": "Raspberry Coulis",
                "book": 1,
                "page": 148,
                "row": 7,
                "hexCode": "#8A4563",
                "rgb": "138,69,99"
            }
        ],
        [
            {
                "nr": "PANTONE 18-2525 TPG",
                "name": "Magenta Haze",
                "book": 1,
                "page": 149,
                "row": 1,
                "hexCode": "#A04D78",
                "rgb": "160,77,120"
            },
            {
                "nr": "PANTONE 18-2527 TPG",
                "name": "Baton Rouge",
                "book": 1,
                "page": 149,
                "row": 2,
                "hexCode": "#9A3F6F",
                "rgb": "153,62,111"
            },
            {
                "nr": "PANTONE 19-2431 TPG",
                "name": "Boysenberry",
                "book": 1,
                "page": 149,
                "row": 3,
                "hexCode": "#893D62",
                "rgb": "136,61,97"
            },
            {
                "nr": "PANTONE 19-2432 TPG",
                "name": "Raspberry Radiance",
                "book": 1,
                "page": 149,
                "row": 4,
                "hexCode": "#864061",
                "rgb": "133,64,96"
            },
            {
                "nr": "PANTONE 19-2430 TPG",
                "name": "Purple Potion",
                "book": 1,
                "page": 149,
                "row": 5,
                "hexCode": "#723E54",
                "rgb": "113,62,83"
            },
            {
                "nr": "PANTONE 19-2428 TPG",
                "name": "Magenta Purple",
                "book": 1,
                "page": 149,
                "row": 6,
                "hexCode": "#753D5A",
                "rgb": "116,60,90"
            },
            {
                "nr": "PANTONE 19-2429 TPG",
                "name": "Plum Caspia",
                "book": 1,
                "page": 149,
                "row": 7,
                "hexCode": "#6D3D5B",
                "rgb": "109,60,90"
            }
        ],
        [
            {
                "nr": "PANTONE 17-2617 TPG",
                "name": "Dahlia Mauve",
                "book": 1,
                "page": 150,
                "row": 1,
                "hexCode": "#A95988",
                "rgb": "168,89,135"
            },
            {
                "nr": "PANTONE 18-3027 TPG",
                "name": "Purple Orchid",
                "book": 1,
                "page": 150,
                "row": 2,
                "hexCode": "#AD5691",
                "rgb": "172,86,145"
            },
            {
                "nr": "PANTONE 18-3339 TPG",
                "name": "Vivid Viola",
                "book": 1,
                "page": 150,
                "row": 3,
                "hexCode": "#9F4C84",
                "rgb": "159,76,131"
            },
            {
                "nr": "PANTONE 19-2630 TPG",
                "name": "Wild Aster",
                "book": 1,
                "page": 150,
                "row": 4,
                "hexCode": "#964577",
                "rgb": "150,69,119"
            },
            {
                "nr": "PANTONE 18-3022 TPG",
                "name": "Deep Orchid",
                "book": 1,
                "page": 150,
                "row": 5,
                "hexCode": "#964A7C",
                "rgb": "149,74,124"
            },
            {
                "nr": "PANTONE 18-2320 TPG",
                "name": "Clover",
                "book": 1,
                "page": 150,
                "row": 6,
                "hexCode": "#944477",
                "rgb": "148,67,119"
            },
            {
                "nr": "PANTONE 18-2929 TPG",
                "name": "Purple Wine",
                "book": 1,
                "page": 150,
                "row": 7,
                "hexCode": "#924678",
                "rgb": "146,69,119"
            }
        ],
        [
            {
                "nr": "PANTONE 18-3224 TPG",
                "name": "Radiant Orchid",
                "book": 1,
                "page": 151,
                "row": 1,
                "hexCode": "#B067A1",
                "rgb": "176,103,160"
            },
            {
                "nr": "PANTONE 18-3331 TPG",
                "name": "Hyacinth Violet",
                "book": 1,
                "page": 151,
                "row": 2,
                "hexCode": "#975591",
                "rgb": "150,84,145"
            },
            {
                "nr": "PANTONE 18-3324 TPG",
                "name": "Dahlia",
                "book": 1,
                "page": 151,
                "row": 3,
                "hexCode": "#8A4E85",
                "rgb": "138,77,132"
            },
            {
                "nr": "PANTONE 19-2924 TPG",
                "name": "Hollyhock",
                "book": 1,
                "page": 151,
                "row": 4,
                "hexCode": "#894778",
                "rgb": "136,70,119"
            },
            {
                "nr": "PANTONE 19-3138 TPG",
                "name": "Byzantium",
                "book": 1,
                "page": 151,
                "row": 5,
                "hexCode": "#8C4C83",
                "rgb": "140,76,130"
            },
            {
                "nr": "PANTONE 19-2820 TPG",
                "name": "Phlox",
                "book": 1,
                "page": 151,
                "row": 6,
                "hexCode": "#75436A",
                "rgb": "116,67,105"
            },
            {
                "nr": "PANTONE 19-3022 TPG",
                "name": "Gloxinia",
                "book": 1,
                "page": 151,
                "row": 7,
                "hexCode": "#684463",
                "rgb": "104,67,98"
            }
        ],
        [
            {
                "nr": "PANTONE 17-3014 TPG",
                "name": "Mulberry",
                "book": 1,
                "page": 152,
                "row": 1,
                "hexCode": "#A9729D",
                "rgb": "168,114,157"
            },
            {
                "nr": "PANTONE 17-3323 TPG",
                "name": "Iris Orchid",
                "book": 1,
                "page": 152,
                "row": 2,
                "hexCode": "#A96BA6",
                "rgb": "168,106,166"
            },
            {
                "nr": "PANTONE 18-3120 TPG",
                "name": "Willowherb",
                "book": 1,
                "page": 152,
                "row": 3,
                "hexCode": "#925188",
                "rgb": "146,81,135"
            },
            {
                "nr": "PANTONE 18-3025 TPG",
                "name": "Striking Purple",
                "book": 1,
                "page": 152,
                "row": 4,
                "hexCode": "#99598F",
                "rgb": "152,88,142"
            },
            {
                "nr": "PANTONE 19-3336 TPG",
                "name": "Sparkling Grape",
                "book": 1,
                "page": 152,
                "row": 5,
                "hexCode": "#814A7E",
                "rgb": "129,73,126"
            },
            {
                "nr": "PANTONE 19-3230 TPG",
                "name": "Grape Juice",
                "book": 1,
                "page": 152,
                "row": 6,
                "hexCode": "#724066",
                "rgb": "113,64,101"
            },
            {
                "nr": "PANTONE 18-3340 TPG",
                "name": "Charisma",
                "book": 1,
                "page": 152,
                "row": 7,
                "hexCode": "#6A4069",
                "rgb": "106,64,105"
            }
        ],
        [
            {
                "nr": "PANTONE 16-3115 TPG",
                "name": "Crocus",
                "book": 1,
                "page": 153,
                "row": 1,
                "hexCode": "#C383B0",
                "rgb": "195,130,175"
            },
            {
                "nr": "PANTONE 17-3020 TPG",
                "name": "Spring Crocus",
                "book": 1,
                "page": 153,
                "row": 2,
                "hexCode": "#BC70A4",
                "rgb": "187,111,164"
            },
            {
                "nr": "PANTONE 17-3240 TPG",
                "name": "Bodacious",
                "book": 1,
                "page": 153,
                "row": 3,
                "hexCode": "#B66CA2",
                "rgb": "182,107,161"
            },
            {
                "nr": "PANTONE 17-3023 TPG",
                "name": "Rosebud",
                "book": 1,
                "page": 153,
                "row": 4,
                "hexCode": "#B5639F",
                "rgb": "180,99,158"
            },
            {
                "nr": "PANTONE 18-3230 TPG",
                "name": "Meadow Mauve",
                "book": 1,
                "page": 153,
                "row": 5,
                "hexCode": "#A55E90",
                "rgb": "164,93,144"
            },
            {
                "nr": "PANTONE 18-3015 TPG",
                "name": "Amethyst",
                "book": 1,
                "page": 153,
                "row": 6,
                "hexCode": "#84547A",
                "rgb": "131,84,122"
            },
            {
                "nr": "PANTONE 18-3014 TPG",
                "name": "Grape Kiss",
                "book": 1,
                "page": 153,
                "row": 7,
                "hexCode": "#805170",
                "rgb": "128,81,111"
            }
        ],
        [
            {
                "nr": "PANTONE 15-3216 TPG",
                "name": "Phalaenopsis",
                "book": 1,
                "page": 154,
                "row": 1,
                "hexCode": "#D5A0CB",
                "rgb": "213,160,203"
            },
            {
                "nr": "PANTONE 16-3320 TPG",
                "name": "Violet",
                "book": 1,
                "page": 154,
                "row": 2,
                "hexCode": "#BE81B6",
                "rgb": "190,128,181"
            },
            {
                "nr": "PANTONE 16-3321 TPG",
                "name": "First Bloom",
                "book": 1,
                "page": 154,
                "row": 3,
                "hexCode": "#BF7DB6",
                "rgb": "191,125,182"
            },
            {
                "nr": "PANTONE 17-3310 TPG",
                "name": "Pale Pansy",
                "book": 1,
                "page": 154,
                "row": 4,
                "hexCode": "#B581AC",
                "rgb": "181,129,172"
            },
            {
                "nr": "PANTONE 17-3320 TPG",
                "name": "Liatris",
                "book": 1,
                "page": 154,
                "row": 5,
                "hexCode": "#AD69A2",
                "rgb": "173,105,162"
            },
            {
                "nr": "PANTONE 18-3223 TPG",
                "name": "Cattleya Orchid",
                "book": 1,
                "page": 154,
                "row": 6,
                "hexCode": "#A15491",
                "rgb": "161,84,145"
            },
            {
                "nr": "PANTONE 18-3024 TPG",
                "name": "Beautyberry",
                "book": 1,
                "page": 154,
                "row": 7,
                "hexCode": "#8C4B81",
                "rgb": "140,75,129"
            }
        ],
        [
            {
                "nr": "PANTONE 15-3412 TPG",
                "name": "Orchid Bouquet",
                "book": 1,
                "page": 155,
                "row": 1,
                "hexCode": "#D0ACD0",
                "rgb": "208,171,207"
            },
            {
                "nr": "PANTONE 16-3416 TPG",
                "name": "Violet Tulle",
                "book": 1,
                "page": 155,
                "row": 2,
                "hexCode": "#C493C3",
                "rgb": "195,147,195"
            },
            {
                "nr": "PANTONE 15-3214 TPG",
                "name": "Orchid",
                "book": 1,
                "page": 155,
                "row": 3,
                "hexCode": "#D29AC7",
                "rgb": "209,153,198"
            },
            {
                "nr": "PANTONE 14-3209 TPG",
                "name": "Pastel Lavender",
                "book": 1,
                "page": 155,
                "row": 4,
                "hexCode": "#DAA4C9",
                "rgb": "217,163,200"
            },
            {
                "nr": "PANTONE 15-2913 TPG",
                "name": "Lilac Chiffon",
                "book": 1,
                "page": 155,
                "row": 5,
                "hexCode": "#DD9CC7",
                "rgb": "221,156,199"
            },
            {
                "nr": "PANTONE 16-3118 TPG",
                "name": "Cyclamen",
                "book": 1,
                "page": 155,
                "row": 6,
                "hexCode": "#D88ABE",
                "rgb": "216,137,190"
            },
            {
                "nr": "PANTONE 16-3116 TPG",
                "name": "Opera Mauve",
                "book": 1,
                "page": 155,
                "row": 7,
                "hexCode": "#CB83B5",
                "rgb": "203,130,180"
            }
        ],
        [
            {
                "nr": "PANTONE 14-3207 TPG",
                "name": "Pink Lavender",
                "book": 1,
                "page": 156,
                "row": 1,
                "hexCode": "#DBB1CD",
                "rgb": "219,176,205"
            },
            {
                "nr": "PANTONE 16-2111 TPG",
                "name": "Mauve Orchid",
                "book": 1,
                "page": 156,
                "row": 2,
                "hexCode": "#B3819A",
                "rgb": "179,128,153"
            },
            {
                "nr": "PANTONE 16-2614 TPG",
                "name": "Moonlite Mauve",
                "book": 1,
                "page": 156,
                "row": 3,
                "hexCode": "#D091B2",
                "rgb": "207,144,178"
            },
            {
                "nr": "PANTONE 15-2210 TPG",
                "name": "Orchid Smoke",
                "book": 1,
                "page": 156,
                "row": 4,
                "hexCode": "#D295AC",
                "rgb": "209,149,172"
            },
            {
                "nr": "PANTONE 16-2215 TPG",
                "name": "Cashmere Rose",
                "book": 1,
                "page": 156,
                "row": 5,
                "hexCode": "#CF87A1",
                "rgb": "206,135,161"
            },
            {
                "nr": "PANTONE 15-1912 TPG",
                "name": "Sea Pink",
                "book": 1,
                "page": 156,
                "row": 6,
                "hexCode": "#E198AD",
                "rgb": "224,152,172"
            },
            {
                "nr": "PANTONE 16-1715 TPG",
                "name": "Wild Rose",
                "book": 1,
                "page": 156,
                "row": 7,
                "hexCode": "#D0879D",
                "rgb": "208,134,156"
            }
        ],
        [
            {
                "nr": "PANTONE 14-2305 TPG",
                "name": "Pink Nectar",
                "book": 1,
                "page": 157,
                "row": 1,
                "hexCode": "#D7A7B4",
                "rgb": "214,167,180"
            },
            {
                "nr": "PANTONE 14-2307 TPG",
                "name": "Cameo Pink",
                "book": 1,
                "page": 157,
                "row": 2,
                "hexCode": "#DCABBA",
                "rgb": "219,171,186"
            },
            {
                "nr": "PANTONE 15-1906 TPG",
                "name": "Zephyr",
                "book": 1,
                "page": 157,
                "row": 3,
                "hexCode": "#CAA1AA",
                "rgb": "202,161,170"
            },
            {
                "nr": "PANTONE 16-1712 TPG",
                "name": "Polignac",
                "book": 1,
                "page": 157,
                "row": 4,
                "hexCode": "#C18A9D",
                "rgb": "193,137,156"
            },
            {
                "nr": "PANTONE 16-1710 TPG",
                "name": "Foxglove",
                "book": 1,
                "page": 157,
                "row": 5,
                "hexCode": "#BD8793",
                "rgb": "188,135,147"
            },
            {
                "nr": "PANTONE 16-1708 TPG",
                "name": "Lilas",
                "book": 1,
                "page": 157,
                "row": 6,
                "hexCode": "#BA8E9C",
                "rgb": "185,141,155"
            },
            {
                "nr": "PANTONE 17-1610 TPG",
                "name": "Dusky Orchid",
                "book": 1,
                "page": 157,
                "row": 7,
                "hexCode": "#9C7988",
                "rgb": "155,120,136"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1609 TPG",
                "name": "Mesa Rose",
                "book": 1,
                "page": 158,
                "row": 1,
                "hexCode": "#AA737D",
                "rgb": "169,114,125"
            },
            {
                "nr": "PANTONE 17-1614 TPG",
                "name": "Deco Rose",
                "book": 1,
                "page": 158,
                "row": 2,
                "hexCode": "#98606B",
                "rgb": "152,95,107"
            },
            {
                "nr": "PANTONE 17-1522 TPG",
                "name": "Mauvewood",
                "book": 1,
                "page": 158,
                "row": 3,
                "hexCode": "#AC6472",
                "rgb": "172,99,114"
            },
            {
                "nr": "PANTONE 18-1718 TPG",
                "name": "Hawthorn Rose",
                "book": 1,
                "page": 158,
                "row": 4,
                "hexCode": "#865666",
                "rgb": "134,86,102"
            },
            {
                "nr": "PANTONE 18-1725 TPG",
                "name": "Dry Rose",
                "book": 1,
                "page": 158,
                "row": 5,
                "hexCode": "#925266",
                "rgb": "145,82,101"
            },
            {
                "nr": "PANTONE 18-1619 TPG",
                "name": "Maroon",
                "book": 1,
                "page": 158,
                "row": 6,
                "hexCode": "#885461",
                "rgb": "135,84,96"
            },
            {
                "nr": "PANTONE 18-1420 TPG",
                "name": "Wild Ginger",
                "book": 1,
                "page": 158,
                "row": 7,
                "hexCode": "#81565D",
                "rgb": "129,86,93"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1623 TPG",
                "name": "Rose Wine",
                "book": 1,
                "page": 159,
                "row": 1,
                "hexCode": "#A76277",
                "rgb": "166,97,118"
            },
            {
                "nr": "PANTONE 17-1608 TPG",
                "name": "Heather Rose",
                "book": 1,
                "page": 159,
                "row": 2,
                "hexCode": "#AB7184",
                "rgb": "171,113,132"
            },
            {
                "nr": "PANTONE 17-1723 TPG",
                "name": "Malaga",
                "book": 1,
                "page": 159,
                "row": 3,
                "hexCode": "#A45872",
                "rgb": "164,88,114"
            },
            {
                "nr": "PANTONE 18-2027 TPG",
                "name": "Beaujolais",
                "book": 1,
                "page": 159,
                "row": 4,
                "hexCode": "#863D55",
                "rgb": "134,61,84"
            },
            {
                "nr": "PANTONE 19-2030 TPG",
                "name": "Beet Red",
                "book": 1,
                "page": 159,
                "row": 5,
                "hexCode": "#84394F",
                "rgb": "132,56,78"
            },
            {
                "nr": "PANTONE 19-2025 TPG",
                "name": "Red Plum",
                "book": 1,
                "page": 159,
                "row": 6,
                "hexCode": "#823F54",
                "rgb": "129,63,84"
            },
            {
                "nr": "PANTONE 19-2024 TPG",
                "name": "Rhododendron",
                "book": 1,
                "page": 159,
                "row": 7,
                "hexCode": "#7A3F4F",
                "rgb": "121,63,78"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1607 TPG",
                "name": "Pale Mauve",
                "book": 1,
                "page": 160,
                "row": 1,
                "hexCode": "#C4A4A3",
                "rgb": "195,163,163"
            },
            {
                "nr": "PANTONE 17-1512 TPG",
                "name": "Nostalgia Rose",
                "book": 1,
                "page": 160,
                "row": 2,
                "hexCode": "#A57D85",
                "rgb": "165,124,132"
            },
            {
                "nr": "PANTONE 17-1511 TPG",
                "name": "Wistful Mauve",
                "book": 1,
                "page": 160,
                "row": 3,
                "hexCode": "#97737E",
                "rgb": "150,114,125"
            },
            {
                "nr": "PANTONE 18-1613 TPG",
                "name": "Renaissance Rose",
                "book": 1,
                "page": 160,
                "row": 4,
                "hexCode": "#865762",
                "rgb": "134,86,98"
            },
            {
                "nr": "PANTONE 18-1418 TPG",
                "name": "Crushed Berry",
                "book": 1,
                "page": 160,
                "row": 5,
                "hexCode": "#815560",
                "rgb": "128,85,96"
            },
            {
                "nr": "PANTONE 18-1616 TPG",
                "name": "Roan Rouge",
                "book": 1,
                "page": 160,
                "row": 6,
                "hexCode": "#89595F",
                "rgb": "137,88,94"
            },
            {
                "nr": "PANTONE 18-1614 TPG",
                "name": "Nocturne",
                "book": 1,
                "page": 160,
                "row": 7,
                "hexCode": "#7F5660",
                "rgb": "127,85,96"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1905 TPG",
                "name": "Burnished Lilac",
                "book": 1,
                "page": 161,
                "row": 1,
                "hexCode": "#C3ADB3",
                "rgb": "195,173,178"
            },
            {
                "nr": "PANTONE 15-2705 TPG",
                "name": "Keepsake Lilac",
                "book": 1,
                "page": 161,
                "row": 2,
                "hexCode": "#C2A9B3",
                "rgb": "193,168,178"
            },
            {
                "nr": "PANTONE 15-2205 TPG",
                "name": "Dawn Pink",
                "book": 1,
                "page": 161,
                "row": 3,
                "hexCode": "#C1A4B2",
                "rgb": "192,163,178"
            },
            {
                "nr": "PANTONE 16-3205 TPG",
                "name": "Mauve Shadows",
                "book": 1,
                "page": 161,
                "row": 4,
                "hexCode": "#B59BA6",
                "rgb": "181,154,166"
            },
            {
                "nr": "PANTONE 17-1605 TPG",
                "name": "Elderberry",
                "book": 1,
                "page": 161,
                "row": 5,
                "hexCode": "#9C8690",
                "rgb": "155,134,144"
            },
            {
                "nr": "PANTONE 17-2411 TPG",
                "name": "Toadstool",
                "book": 1,
                "page": 161,
                "row": 6,
                "hexCode": "#998189",
                "rgb": "152,129,136"
            },
            {
                "nr": "PANTONE 18-2109 TPG",
                "name": "Grape Shake",
                "book": 1,
                "page": 161,
                "row": 7,
                "hexCode": "#8A6F79",
                "rgb": "137,110,121"
            }
        ],
        [
            {
                "nr": "PANTONE 13-3804 TPG",
                "name": "Gray Lilac",
                "book": 1,
                "page": 162,
                "row": 1,
                "hexCode": "#D5CBD2",
                "rgb": "213,202,209"
            },
            {
                "nr": "PANTONE 15-2706 TPG",
                "name": "Violet Ice",
                "book": 1,
                "page": 162,
                "row": 2,
                "hexCode": "#C1AFB2",
                "rgb": "193,175,178"
            },
            {
                "nr": "PANTONE 16-1707 TPG",
                "name": "Deauville Mauve",
                "book": 1,
                "page": 162,
                "row": 3,
                "hexCode": "#AD9294",
                "rgb": "172,145,148"
            },
            {
                "nr": "PANTONE 16-1806 TPG",
                "name": "Woodrose",
                "book": 1,
                "page": 162,
                "row": 4,
                "hexCode": "#AE9092",
                "rgb": "173,144,146"
            },
            {
                "nr": "PANTONE 18-1807 TPG",
                "name": "Twilight Mauve",
                "book": 1,
                "page": 162,
                "row": 5,
                "hexCode": "#8A7377",
                "rgb": "138,115,118"
            },
            {
                "nr": "PANTONE 18-1612 TPG",
                "name": "Rose Taupe",
                "book": 1,
                "page": 162,
                "row": 6,
                "hexCode": "#826567",
                "rgb": "129,100,102"
            },
            {
                "nr": "PANTONE 18-1512 TPG",
                "name": "Rose Brown",
                "book": 1,
                "page": 162,
                "row": 7,
                "hexCode": "#835E63",
                "rgb": "130,94,98"
            }
        ],
        [
            {
                "nr": "PANTONE 14-3803 TPG",
                "name": "Hushed Violet",
                "book": 1,
                "page": 163,
                "row": 1,
                "hexCode": "#CFC2C0",
                "rgb": "207,193,192"
            },
            {
                "nr": "PANTONE 16-1509 TPG",
                "name": "Shadow Gray",
                "book": 1,
                "page": 163,
                "row": 2,
                "hexCode": "#B9A59F",
                "rgb": "184,164,159"
            },
            {
                "nr": "PANTONE 16-1508 TPG",
                "name": "Adobe Rose",
                "book": 1,
                "page": 163,
                "row": 3,
                "hexCode": "#B89C96",
                "rgb": "183,156,149"
            },
            {
                "nr": "PANTONE 16-1510 TPG",
                "name": "Fawn",
                "book": 1,
                "page": 163,
                "row": 4,
                "hexCode": "#B09894",
                "rgb": "175,151,147"
            },
            {
                "nr": "PANTONE 17-1510 TPG",
                "name": "Antler",
                "book": 1,
                "page": 163,
                "row": 5,
                "hexCode": "#927A77",
                "rgb": "145,122,119"
            },
            {
                "nr": "PANTONE 18-1415 TPG",
                "name": "Marron",
                "book": 1,
                "page": 163,
                "row": 6,
                "hexCode": "#735957",
                "rgb": "115,88,87"
            },
            {
                "nr": "PANTONE 18-1409 TPG",
                "name": "Peppercorn",
                "book": 1,
                "page": 163,
                "row": 7,
                "hexCode": "#6E5E5F",
                "rgb": "110,93,94"
            }
        ],
        [
            {
                "nr": "PANTONE 13-3803 TPG",
                "name": "Lilac Ash",
                "book": 1,
                "page": 164,
                "row": 1,
                "hexCode": "#D5CCCC",
                "rgb": "212,204,204"
            },
            {
                "nr": "PANTONE 16-1703 TPG",
                "name": "Sphinx",
                "book": 1,
                "page": 164,
                "row": 2,
                "hexCode": "#AB9995",
                "rgb": "170,152,149"
            },
            {
                "nr": "PANTONE 16-1506 TPG",
                "name": "Bark",
                "book": 1,
                "page": 164,
                "row": 3,
                "hexCode": "#A79592",
                "rgb": "166,148,145"
            },
            {
                "nr": "PANTONE 19-1620 TPG",
                "name": "Huckleberry",
                "book": 1,
                "page": 164,
                "row": 4,
                "hexCode": "#5F4B51",
                "rgb": "94,74,80"
            },
            {
                "nr": "PANTONE 18-1404 TPG",
                "name": "Sparrow",
                "book": 1,
                "page": 164,
                "row": 5,
                "hexCode": "#6E6267",
                "rgb": "109,97,102"
            },
            {
                "nr": "PANTONE 18-1506 TPG",
                "name": "Plum Truffle",
                "book": 1,
                "page": 164,
                "row": 6,
                "hexCode": "#6E5F61",
                "rgb": "110,95,97"
            },
            {
                "nr": "PANTONE 19-1606 TPG",
                "name": "Raisin",
                "book": 1,
                "page": 164,
                "row": 7,
                "hexCode": "#5D4B51",
                "rgb": "93,74,81"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1606 TPG",
                "name": "Purple Dove",
                "book": 1,
                "page": 165,
                "row": 1,
                "hexCode": "#96868B",
                "rgb": "150,134,138"
            },
            {
                "nr": "PANTONE 17-1708 TPG",
                "name": "Moonscape",
                "book": 1,
                "page": 165,
                "row": 2,
                "hexCode": "#776570",
                "rgb": "119,100,112"
            },
            {
                "nr": "PANTONE 18-1705 TPG",
                "name": "Arctic Dusk",
                "book": 1,
                "page": 165,
                "row": 3,
                "hexCode": "#796372",
                "rgb": "120,99,114"
            },
            {
                "nr": "PANTONE 18-1405 TPG",
                "name": "Flint",
                "book": 1,
                "page": 165,
                "row": 4,
                "hexCode": "#725F69",
                "rgb": "114,95,104"
            },
            {
                "nr": "PANTONE 18-1708 TPG",
                "name": "Ephemera",
                "book": 1,
                "page": 165,
                "row": 5,
                "hexCode": "#77636E",
                "rgb": "119,98,109"
            },
            {
                "nr": "PANTONE 18-1706 TPG",
                "name": "Black Plum",
                "book": 1,
                "page": 165,
                "row": 6,
                "hexCode": "#6E5E6C",
                "rgb": "109,94,107"
            },
            {
                "nr": "PANTONE 19-3316 TPG",
                "name": "Plum Perfect",
                "book": 1,
                "page": 165,
                "row": 7,
                "hexCode": "#524550",
                "rgb": "81,69,80"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1411 TPG",
                "name": "Plum Wine",
                "book": 1,
                "page": 166,
                "row": 1,
                "hexCode": "#715360",
                "rgb": "112,83,96"
            },
            {
                "nr": "PANTONE 19-2311 TPG",
                "name": "Eggplant",
                "book": 1,
                "page": 166,
                "row": 2,
                "hexCode": "#654D57",
                "rgb": "100,77,86"
            },
            {
                "nr": "PANTONE 19-1621 TPG",
                "name": "Catawba Grape",
                "book": 1,
                "page": 166,
                "row": 3,
                "hexCode": "#614B50",
                "rgb": "96,75,80"
            },
            {
                "nr": "PANTONE 19-1624 TPG",
                "name": "Sassafras",
                "book": 1,
                "page": 166,
                "row": 4,
                "hexCode": "#5C4A50",
                "rgb": "92,73,79"
            },
            {
                "nr": "PANTONE 19-1625 TPG",
                "name": "Decadent Chocolate",
                "book": 1,
                "page": 166,
                "row": 5,
                "hexCode": "#5E4648",
                "rgb": "94,69,72"
            },
            {
                "nr": "PANTONE 19-1623 TPG",
                "name": "Vineyard Wine",
                "book": 1,
                "page": 166,
                "row": 6,
                "hexCode": "#60474D",
                "rgb": "96,71,76"
            },
            {
                "nr": "PANTONE 19-1619 TPG",
                "name": "Fudge",
                "book": 1,
                "page": 166,
                "row": 7,
                "hexCode": "#534548",
                "rgb": "82,69,72"
            }
        ],
        [
            {
                "nr": "PANTONE 19-1716 TPG",
                "name": "Mauve Wine",
                "book": 1,
                "page": 167,
                "row": 1,
                "hexCode": "#624350",
                "rgb": "97,66,79"
            },
            {
                "nr": "PANTONE 19-1718 TPG",
                "name": "Fig",
                "book": 1,
                "page": 167,
                "row": 2,
                "hexCode": "#58414B",
                "rgb": "88,65,74"
            },
            {
                "nr": "PANTONE 19-1627 TPG",
                "name": "Port Royale",
                "book": 1,
                "page": 167,
                "row": 3,
                "hexCode": "#5F3E45",
                "rgb": "94,62,69"
            },
            {
                "nr": "PANTONE 19-1617 TPG",
                "name": "Burgundy",
                "book": 1,
                "page": 167,
                "row": 4,
                "hexCode": "#6A464F",
                "rgb": "106,69,79"
            },
            {
                "nr": "PANTONE 19-1528 TPG",
                "name": "Windsor Wine",
                "book": 1,
                "page": 167,
                "row": 5,
                "hexCode": "#643E46",
                "rgb": "99,62,69"
            },
            {
                "nr": "PANTONE 19-1529 TPG",
                "name": "Maroon Banner",
                "book": 1,
                "page": 167,
                "row": 6,
                "hexCode": "#643E47",
                "rgb": "100,62,71"
            },
            {
                "nr": "PANTONE 19-2118 TPG",
                "name": "Winetasting",
                "book": 1,
                "page": 167,
                "row": 7,
                "hexCode": "#534249",
                "rgb": "83,66,72"
            }
        ],
        [
            {
                "nr": "PANTONE 18-1709 TPG",
                "name": "Tulipwood",
                "book": 1,
                "page": 168,
                "row": 1,
                "hexCode": "#865F71",
                "rgb": "133,94,112"
            },
            {
                "nr": "PANTONE 18-1710 TPG",
                "name": "Grape Nectar",
                "book": 1,
                "page": 168,
                "row": 2,
                "hexCode": "#8E6678",
                "rgb": "141,101,120"
            },
            {
                "nr": "PANTONE 19-2014 TPG",
                "name": "Prune",
                "book": 1,
                "page": 168,
                "row": 3,
                "hexCode": "#654856",
                "rgb": "101,72,86"
            },
            {
                "nr": "PANTONE 19-2315 TPG",
                "name": "Grape Wine",
                "book": 1,
                "page": 168,
                "row": 4,
                "hexCode": "#604151",
                "rgb": "96,65,80"
            },
            {
                "nr": "PANTONE 19-1608 TPG",
                "name": "Prune Purple",
                "book": 1,
                "page": 168,
                "row": 5,
                "hexCode": "#644D5C",
                "rgb": "100,76,91"
            },
            {
                "nr": "PANTONE 19-2514 TPG",
                "name": "Italian Plum",
                "book": 1,
                "page": 168,
                "row": 6,
                "hexCode": "#5A4252",
                "rgb": "89,65,81"
            },
            {
                "nr": "PANTONE 19-2520 TPG",
                "name": "Potent Purple",
                "book": 1,
                "page": 168,
                "row": 7,
                "hexCode": "#583C4B",
                "rgb": "87,60,74"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1710 TPG",
                "name": "Bordeaux",
                "book": 1,
                "page": 169,
                "row": 1,
                "hexCode": "#9B6C86",
                "rgb": "154,107,133"
            },
            {
                "nr": "PANTONE 17-1612 TPG",
                "name": "Mellow Mauve",
                "book": 1,
                "page": 169,
                "row": 2,
                "hexCode": "#99677E",
                "rgb": "153,103,125"
            },
            {
                "nr": "PANTONE 17-1818 TPG",
                "name": "Red Violet",
                "book": 1,
                "page": 169,
                "row": 3,
                "hexCode": "#A8627F",
                "rgb": "167,98,127"
            },
            {
                "nr": "PANTONE 18-1720 TPG",
                "name": "Violet Quartz",
                "book": 1,
                "page": 169,
                "row": 4,
                "hexCode": "#8D5269",
                "rgb": "140,81,105"
            },
            {
                "nr": "PANTONE 18-1716 TPG",
                "name": "Damson",
                "book": 1,
                "page": 169,
                "row": 5,
                "hexCode": "#815568",
                "rgb": "129,84,103"
            },
            {
                "nr": "PANTONE 19-2410 TPG",
                "name": "Amaranth",
                "book": 1,
                "page": 169,
                "row": 6,
                "hexCode": "#704058",
                "rgb": "112,64,88"
            },
            {
                "nr": "PANTONE 19-2312 TPG",
                "name": "Crushed Violets",
                "book": 1,
                "page": 169,
                "row": 7,
                "hexCode": "#684955",
                "rgb": "104,72,85"
            }
        ],
        [
            {
                "nr": "PANTONE 14-3204 TPG",
                "name": "Fragrant Lilac",
                "book": 1,
                "page": 170,
                "row": 1,
                "hexCode": "#CFAEBF",
                "rgb": "206,173,191"
            },
            {
                "nr": "PANTONE 15-3207 TPG",
                "name": "Mauve Mist",
                "book": 1,
                "page": 170,
                "row": 2,
                "hexCode": "#C49EB7",
                "rgb": "195,157,183"
            },
            {
                "nr": "PANTONE 16-2107 TPG",
                "name": "Orchid Haze",
                "book": 1,
                "page": 170,
                "row": 3,
                "hexCode": "#B2889E",
                "rgb": "178,135,158"
            },
            {
                "nr": "PANTONE 18-3011 TPG",
                "name": "Argyle Purple",
                "book": 1,
                "page": 170,
                "row": 4,
                "hexCode": "#856079",
                "rgb": "132,95,120"
            },
            {
                "nr": "PANTONE 18-3211 TPG",
                "name": "Grapeade",
                "book": 1,
                "page": 170,
                "row": 5,
                "hexCode": "#846B81",
                "rgb": "132,106,129"
            },
            {
                "nr": "PANTONE 18-3012 TPG",
                "name": "Purple Gumdrop",
                "book": 1,
                "page": 170,
                "row": 6,
                "hexCode": "#7D5E77",
                "rgb": "124,94,118"
            },
            {
                "nr": "PANTONE 18-3013 TPG",
                "name": "Berry Conserve",
                "book": 1,
                "page": 170,
                "row": 7,
                "hexCode": "#75536A",
                "rgb": "116,82,105"
            }
        ],
        [
            {
                "nr": "PANTONE 16-3307 TPG",
                "name": "Lavender Mist",
                "book": 1,
                "page": 171,
                "row": 1,
                "hexCode": "#AF91A9",
                "rgb": "175,145,169"
            },
            {
                "nr": "PANTONE 17-3410 TPG",
                "name": "Valerian",
                "book": 1,
                "page": 171,
                "row": 2,
                "hexCode": "#9D7894",
                "rgb": "157,120,147"
            },
            {
                "nr": "PANTONE 18-3220 TPG",
                "name": "Very Grape",
                "book": 1,
                "page": 171,
                "row": 3,
                "hexCode": "#91738B",
                "rgb": "144,114,139"
            },
            {
                "nr": "PANTONE 18-3418 TPG",
                "name": "Chinese Violet",
                "book": 1,
                "page": 171,
                "row": 4,
                "hexCode": "#87678A",
                "rgb": "135,102,138"
            },
            {
                "nr": "PANTONE 18-3218 TPG",
                "name": "Concord Grape",
                "book": 1,
                "page": 171,
                "row": 5,
                "hexCode": "#815C83",
                "rgb": "128,91,130"
            },
            {
                "nr": "PANTONE 19-3424 TPG",
                "name": "Sunset Purple",
                "book": 1,
                "page": 171,
                "row": 6,
                "hexCode": "#765278",
                "rgb": "117,81,120"
            },
            {
                "nr": "PANTONE 18-3415 TPG",
                "name": "Grape Jam",
                "book": 1,
                "page": 171,
                "row": 7,
                "hexCode": "#77617B",
                "rgb": "119,96,122"
            }
        ],
        [
            {
                "nr": "PANTONE 18-3522 TPG",
                "name": "Crushed Grape",
                "book": 1,
                "page": 172,
                "row": 1,
                "hexCode": "#7E5D86",
                "rgb": "126,92,134"
            },
            {
                "nr": "PANTONE 19-3325 TPG",
                "name": "Wood Violet",
                "book": 1,
                "page": 172,
                "row": 2,
                "hexCode": "#7A5076",
                "rgb": "121,80,117"
            },
            {
                "nr": "PANTONE 19-3223 TPG",
                "name": "Purple Passion",
                "book": 1,
                "page": 172,
                "row": 3,
                "hexCode": "#724F6E",
                "rgb": "114,78,110"
            },
            {
                "nr": "PANTONE 19-2524 TPG",
                "name": "Dark Purple",
                "book": 1,
                "page": 172,
                "row": 4,
                "hexCode": "#6D3E5B",
                "rgb": "109,62,91"
            },
            {
                "nr": "PANTONE 19-2420 TPG",
                "name": "Pickled Beet",
                "book": 1,
                "page": 172,
                "row": 5,
                "hexCode": "#58394B",
                "rgb": "88,56,74"
            },
            {
                "nr": "PANTONE 19-2620 TPG",
                "name": "Winter Bloom",
                "book": 1,
                "page": 172,
                "row": 6,
                "hexCode": "#533A4B",
                "rgb": "83,58,74"
            },
            {
                "nr": "PANTONE 19-2816 TPG",
                "name": "Blackberry Wine",
                "book": 1,
                "page": 172,
                "row": 7,
                "hexCode": "#574354",
                "rgb": "87,67,83"
            }
        ],
        [
            {
                "nr": "PANTONE 14-3206 TPG",
                "name": "Winsome Orchid",
                "book": 1,
                "page": 173,
                "row": 1,
                "hexCode": "#D1B6CC",
                "rgb": "209,182,203"
            },
            {
                "nr": "PANTONE 16-3110 TPG",
                "name": "Smoky Grape",
                "book": 1,
                "page": 173,
                "row": 2,
                "hexCode": "#B78BB0",
                "rgb": "183,139,175"
            },
            {
                "nr": "PANTONE 17-3313 TPG",
                "name": "Dusty Lavender",
                "book": 1,
                "page": 173,
                "row": 3,
                "hexCode": "#9D789A",
                "rgb": "156,119,154"
            },
            {
                "nr": "PANTONE 16-3310 TPG",
                "name": "Lavender Herb",
                "book": 1,
                "page": 173,
                "row": 4,
                "hexCode": "#B490AF",
                "rgb": "179,143,175"
            },
            {
                "nr": "PANTONE 17-3520 TPG",
                "name": "Diffused Orchid",
                "book": 1,
                "page": 173,
                "row": 5,
                "hexCode": "#9E7FA6",
                "rgb": "157,126,166"
            },
            {
                "nr": "PANTONE 17-3612 TPG",
                "name": "Orchid Mist",
                "book": 1,
                "page": 173,
                "row": 6,
                "hexCode": "#917A9A",
                "rgb": "145,121,153"
            },
            {
                "nr": "PANTONE 18-3530 TPG",
                "name": "Lavender Crystal",
                "book": 1,
                "page": 173,
                "row": 7,
                "hexCode": "#966E9C",
                "rgb": "150,110,155"
            }
        ],
        [
            {
                "nr": "PANTONE 15-3520 TPG",
                "name": "Crocus Petal",
                "book": 1,
                "page": 174,
                "row": 1,
                "hexCode": "#BE9EC9",
                "rgb": "189,157,200"
            },
            {
                "nr": "PANTONE 16-3521 TPG",
                "name": "Lupine",
                "book": 1,
                "page": 174,
                "row": 2,
                "hexCode": "#BEA0C4",
                "rgb": "189,159,195"
            },
            {
                "nr": "PANTONE 16-3617 TPG",
                "name": "Sheer Lilac",
                "book": 1,
                "page": 174,
                "row": 3,
                "hexCode": "#BB97C6",
                "rgb": "187,150,198"
            },
            {
                "nr": "PANTONE 16-3520 TPG",
                "name": "African Violet",
                "book": 1,
                "page": 174,
                "row": 4,
                "hexCode": "#B185BA",
                "rgb": "176,133,186"
            },
            {
                "nr": "PANTONE 17-3617 TPG",
                "name": "English Lavender",
                "book": 1,
                "page": 174,
                "row": 5,
                "hexCode": "#9D7EB1",
                "rgb": "157,125,177"
            },
            {
                "nr": "PANTONE 17-3640 TPG",
                "name": "Fairy Wren",
                "book": 1,
                "page": 174,
                "row": 6,
                "hexCode": "#997DB4",
                "rgb": "152,125,180"
            },
            {
                "nr": "PANTONE 18-3628 TPG",
                "name": "Bellflower",
                "book": 1,
                "page": 174,
                "row": 7,
                "hexCode": "#966DA5",
                "rgb": "150,108,164"
            }
        ],
        [
            {
                "nr": "PANTONE 13-3802 TPG",
                "name": "Orchid Tint",
                "book": 1,
                "page": 175,
                "row": 1,
                "hexCode": "#DCD4DC",
                "rgb": "219,211,220"
            },
            {
                "nr": "PANTONE 13-3820 TPG",
                "name": "Lavender Fog",
                "book": 1,
                "page": 175,
                "row": 2,
                "hexCode": "#D4C7D9",
                "rgb": "212,198,216"
            },
            {
                "nr": "PANTONE 13-3805 TPG",
                "name": "Orchid Hush",
                "book": 1,
                "page": 175,
                "row": 3,
                "hexCode": "#CDC3D3",
                "rgb": "204,194,211"
            },
            {
                "nr": "PANTONE 15-3507 TPG",
                "name": "Lavender Frost",
                "book": 1,
                "page": 175,
                "row": 4,
                "hexCode": "#B9A8BE",
                "rgb": "185,167,190"
            },
            {
                "nr": "PANTONE 15-3508 TPG",
                "name": "Fair Orchid",
                "book": 1,
                "page": 175,
                "row": 5,
                "hexCode": "#C0ABC2",
                "rgb": "191,171,194"
            },
            {
                "nr": "PANTONE 16-3525 TPG",
                "name": "Regal Orchid",
                "book": 1,
                "page": 175,
                "row": 6,
                "hexCode": "#AA8CB0",
                "rgb": "170,140,175"
            },
            {
                "nr": "PANTONE 16-3817 TPG",
                "name": "Rhapsody",
                "book": 1,
                "page": 175,
                "row": 7,
                "hexCode": "#A28AAC",
                "rgb": "161,138,172"
            }
        ],
        [
            {
                "nr": "PANTONE 14-3612 TPG",
                "name": "Orchid Bloom",
                "book": 1,
                "page": 176,
                "row": 1,
                "hexCode": "#C4AED0",
                "rgb": "196,173,208"
            },
            {
                "nr": "PANTONE 15-3620 TPG",
                "name": "Lavendula",
                "book": 1,
                "page": 176,
                "row": 2,
                "hexCode": "#BCA0CC",
                "rgb": "187,160,203"
            },
            {
                "nr": "PANTONE 15-3720 TPG",
                "name": "Lilac Breeze",
                "book": 1,
                "page": 176,
                "row": 3,
                "hexCode": "#B6A1CD",
                "rgb": "181,160,204"
            },
            {
                "nr": "PANTONE 15-3716 TPG",
                "name": "Purple Rose",
                "book": 1,
                "page": 176,
                "row": 4,
                "hexCode": "#B3A1CD",
                "rgb": "178,161,205"
            },
            {
                "nr": "PANTONE 16-3815 TPG",
                "name": "Viola",
                "book": 1,
                "page": 176,
                "row": 5,
                "hexCode": "#A893BD",
                "rgb": "167,147,188"
            },
            {
                "nr": "PANTONE 16-3720 TPG",
                "name": "Sand Verbena",
                "book": 1,
                "page": 176,
                "row": 6,
                "hexCode": "#A290C1",
                "rgb": "161,144,192"
            },
            {
                "nr": "PANTONE 17-3735 TPG",
                "name": "Sunlit Allium",
                "book": 1,
                "page": 176,
                "row": 7,
                "hexCode": "#9C8BBC",
                "rgb": "155,138,187"
            }
        ],
        [
            {
                "nr": "PANTONE 17-3619 TPG",
                "name": "Hyacinth",
                "book": 1,
                "page": 177,
                "row": 1,
                "hexCode": "#916DA4",
                "rgb": "144,109,163"
            },
            {
                "nr": "PANTONE 17-3628 TPG",
                "name": "Amethyst Orchid",
                "book": 1,
                "page": 177,
                "row": 2,
                "hexCode": "#9369A8",
                "rgb": "146,105,168"
            },
            {
                "nr": "PANTONE 18-3533 TPG",
                "name": "Dewberry",
                "book": 1,
                "page": 177,
                "row": 3,
                "hexCode": "#8D5B99",
                "rgb": "141,91,153"
            },
            {
                "nr": "PANTONE 19-3438 TPG",
                "name": "Bright Violet",
                "book": 1,
                "page": 177,
                "row": 4,
                "hexCode": "#7E5186",
                "rgb": "126,81,134"
            },
            {
                "nr": "PANTONE 18-3634 TPG",
                "name": "Chive Blossom",
                "book": 1,
                "page": 177,
                "row": 5,
                "hexCode": "#7F619B",
                "rgb": "126,97,155"
            },
            {
                "nr": "PANTONE 19-3526 TPG",
                "name": "Meadow Violet",
                "book": 1,
                "page": 177,
                "row": 6,
                "hexCode": "#765686",
                "rgb": "118,85,134"
            },
            {
                "nr": "PANTONE 19-3425 TPG",
                "name": "Spiced Plum",
                "book": 1,
                "page": 177,
                "row": 7,
                "hexCode": "#735079",
                "rgb": "114,80,120"
            }
        ],
        [
            {
                "nr": "PANTONE 18-3633 TPG",
                "name": "Deep Lavender",
                "book": 1,
                "page": 178,
                "row": 1,
                "hexCode": "#795999",
                "rgb": "121,88,153"
            },
            {
                "nr": "PANTONE 18-3531 TPG",
                "name": "Royal Lilac",
                "book": 1,
                "page": 178,
                "row": 2,
                "hexCode": "#7A5491",
                "rgb": "122,84,144"
            },
            {
                "nr": "PANTONE 19-3542 TPG",
                "name": "Pansy",
                "book": 1,
                "page": 178,
                "row": 3,
                "hexCode": "#6E4C82",
                "rgb": "109,76,129"
            },
            {
                "nr": "PANTONE 19-3536 TPG",
                "name": "Amaranth Purple",
                "book": 1,
                "page": 178,
                "row": 4,
                "hexCode": "#764C82",
                "rgb": "117,76,130"
            },
            {
                "nr": "PANTONE 19-3540 TPG",
                "name": "Purple Magic",
                "book": 1,
                "page": 178,
                "row": 5,
                "hexCode": "#6F4774",
                "rgb": "110,71,115"
            },
            {
                "nr": "PANTONE 19-3528 TPG",
                "name": "Imperial Purple",
                "book": 1,
                "page": 178,
                "row": 6,
                "hexCode": "#614468",
                "rgb": "96,67,104"
            },
            {
                "nr": "PANTONE 19-3220 TPG",
                "name": "Plum",
                "book": 1,
                "page": 178,
                "row": 7,
                "hexCode": "#644564",
                "rgb": "99,68,100"
            }
        ],
        [
            {
                "nr": "PANTONE 18-3635 TPG",
                "name": "Picasso Lily",
                "book": 1,
                "page": 179,
                "row": 1,
                "hexCode": "#6D5380",
                "rgb": "109,83,127"
            },
            {
                "nr": "PANTONE 18-3520 TPG",
                "name": "Purple Heart",
                "book": 1,
                "page": 179,
                "row": 2,
                "hexCode": "#775B8C",
                "rgb": "119,91,139"
            },
            {
                "nr": "PANTONE 18-3615 TPG",
                "name": "Imperial Palace",
                "book": 1,
                "page": 179,
                "row": 3,
                "hexCode": "#604E79",
                "rgb": "96,78,121"
            },
            {
                "nr": "PANTONE 18-3518 TPG",
                "name": "Patrician Purple",
                "book": 1,
                "page": 179,
                "row": 4,
                "hexCode": "#6F577E",
                "rgb": "111,86,126"
            },
            {
                "nr": "PANTONE 18-3521 TPG",
                "name": "Plum Jam",
                "book": 1,
                "page": 179,
                "row": 5,
                "hexCode": "#6C4C7D",
                "rgb": "107,76,125"
            },
            {
                "nr": "PANTONE 18-3540 TPG",
                "name": "Purple Sapphire",
                "book": 1,
                "page": 179,
                "row": 6,
                "hexCode": "#76528B",
                "rgb": "118,81,139"
            },
            {
                "nr": "PANTONE 19-3514 TPG",
                "name": "Majesty",
                "book": 1,
                "page": 179,
                "row": 7,
                "hexCode": "#614266",
                "rgb": "96,65,101"
            }
        ],
        [
            {
                "nr": "PANTONE 19-3518 TPG",
                "name": "Grape Royale",
                "book": 1,
                "page": 180,
                "row": 1,
                "hexCode": "#5B4161",
                "rgb": "91,65,97"
            },
            {
                "nr": "PANTONE 19-3632 TPG",
                "name": "Petunia",
                "book": 1,
                "page": 180,
                "row": 2,
                "hexCode": "#5A446D",
                "rgb": "90,68,108"
            },
            {
                "nr": "PANTONE 19-3628 TPG",
                "name": "Acai",
                "book": 1,
                "page": 180,
                "row": 3,
                "hexCode": "#563C65",
                "rgb": "86,60,101"
            },
            {
                "nr": "PANTONE 19-3640 TPG",
                "name": "Crown Jewel",
                "book": 1,
                "page": 180,
                "row": 4,
                "hexCode": "#533F5C",
                "rgb": "83,63,91"
            },
            {
                "nr": "PANTONE 19-3218 TPG",
                "name": "Plum Purple",
                "book": 1,
                "page": 180,
                "row": 5,
                "hexCode": "#584157",
                "rgb": "88,64,87"
            },
            {
                "nr": "PANTONE 18-3410 TPG",
                "name": "Vintage Violet",
                "book": 1,
                "page": 180,
                "row": 6,
                "hexCode": "#625162",
                "rgb": "97,81,97"
            },
            {
                "nr": "PANTONE 19-3217 TPG",
                "name": "Shadow Purple",
                "book": 1,
                "page": 180,
                "row": 7,
                "hexCode": "#573E54",
                "rgb": "87,61,83"
            }
        ],
        [
            {
                "nr": "PANTONE 18-3513 TPG",
                "name": "Grape Compote",
                "book": 1,
                "page": 181,
                "row": 1,
                "hexCode": "#72617D",
                "rgb": "114,97,124"
            },
            {
                "nr": "PANTONE 19-2814 TPG",
                "name": "Wineberry",
                "book": 1,
                "page": 181,
                "row": 2,
                "hexCode": "#654F6A",
                "rgb": "101,78,106"
            },
            {
                "nr": "PANTONE 19-3323 TPG",
                "name": "Deep Purple",
                "book": 1,
                "page": 181,
                "row": 3,
                "hexCode": "#5E465C",
                "rgb": "93,70,91"
            },
            {
                "nr": "PANTONE 19-2009 TPG",
                "name": "Hortensia",
                "book": 1,
                "page": 181,
                "row": 4,
                "hexCode": "#604B5E",
                "rgb": "95,75,94"
            },
            {
                "nr": "PANTONE 19-3714 TPG",
                "name": "Navy Cosmos",
                "book": 1,
                "page": 181,
                "row": 5,
                "hexCode": "#58495D",
                "rgb": "88,73,93"
            },
            {
                "nr": "PANTONE 19-3519 TPG",
                "name": "Purple Pennant",
                "book": 1,
                "page": 181,
                "row": 6,
                "hexCode": "#524255",
                "rgb": "81,65,85"
            },
            {
                "nr": "PANTONE 19-3520 TPG",
                "name": "Blackberry Cordial",
                "book": 1,
                "page": 181,
                "row": 7,
                "hexCode": "#4E3D53",
                "rgb": "77,61,82"
            }
        ],
        [
            {
                "nr": "PANTONE 18-3714 TPG",
                "name": "Mulled Grape",
                "book": 1,
                "page": 182,
                "row": 1,
                "hexCode": "#625871",
                "rgb": "98,88,112"
            },
            {
                "nr": "PANTONE 18-3715 TPG",
                "name": "Montana Grape",
                "book": 1,
                "page": 182,
                "row": 2,
                "hexCode": "#6E6276",
                "rgb": "110,98,118"
            },
            {
                "nr": "PANTONE 18-3620 TPG",
                "name": "Mystical",
                "book": 1,
                "page": 182,
                "row": 3,
                "hexCode": "#67577A",
                "rgb": "102,86,121"
            },
            {
                "nr": "PANTONE 19-3622 TPG",
                "name": "Loganberry",
                "book": 1,
                "page": 182,
                "row": 4,
                "hexCode": "#61526E",
                "rgb": "96,81,110"
            },
            {
                "nr": "PANTONE 19-3620 TPG",
                "name": "Purple Reign",
                "book": 1,
                "page": 182,
                "row": 5,
                "hexCode": "#5C4E71",
                "rgb": "92,78,112"
            },
            {
                "nr": "PANTONE 19-3215 TPG",
                "name": "Indigo",
                "book": 1,
                "page": 182,
                "row": 6,
                "hexCode": "#584962",
                "rgb": "88,73,97"
            },
            {
                "nr": "PANTONE 19-3619 TPG",
                "name": "Sweet Grape",
                "book": 1,
                "page": 182,
                "row": 7,
                "hexCode": "#564C5C",
                "rgb": "86,75,92"
            }
        ],
        [
            {
                "nr": "PANTONE 19-3722 TPG",
                "name": "Mulberry Purple",
                "book": 1,
                "page": 183,
                "row": 1,
                "hexCode": "#544A6D",
                "rgb": "84,74,108"
            },
            {
                "nr": "PANTONE 19-3716 TPG",
                "name": "Purple Plumeria",
                "book": 1,
                "page": 183,
                "row": 2,
                "hexCode": "#54495F",
                "rgb": "83,72,94"
            },
            {
                "nr": "PANTONE 19-3720 TPG",
                "name": "Gothic Grape",
                "book": 1,
                "page": 183,
                "row": 3,
                "hexCode": "#4C3E55",
                "rgb": "75,62,85"
            },
            {
                "nr": "PANTONE 19-3617 TPG",
                "name": "Mysterioso",
                "book": 1,
                "page": 183,
                "row": 4,
                "hexCode": "#493F51",
                "rgb": "73,62,80"
            },
            {
                "nr": "PANTONE 19-3712 TPG",
                "name": "Nightshade",
                "book": 1,
                "page": 183,
                "row": 5,
                "hexCode": "#4C4551",
                "rgb": "75,69,81"
            },
            {
                "nr": "PANTONE 19-3725 TPG",
                "name": "Purple Velvet",
                "book": 1,
                "page": 183,
                "row": 6,
                "hexCode": "#50485B",
                "rgb": "79,71,91"
            },
            {
                "nr": "PANTONE 19-3728 TPG",
                "name": "Grape",
                "book": 1,
                "page": 183,
                "row": 7,
                "hexCode": "#554863",
                "rgb": "84,71,99"
            }
        ],
        [
            {
                "nr": "PANTONE 18-3834 TPG",
                "name": "Veronica",
                "book": 1,
                "page": 184,
                "row": 1,
                "hexCode": "#736C99",
                "rgb": "114,107,152"
            },
            {
                "nr": "PANTONE 18-3840 TPG",
                "name": "Purple Opulence",
                "book": 1,
                "page": 184,
                "row": 2,
                "hexCode": "#695FA2",
                "rgb": "105,95,161"
            },
            {
                "nr": "PANTONE 19-3850 TPG",
                "name": "Liberty",
                "book": 1,
                "page": 184,
                "row": 3,
                "hexCode": "#57508C",
                "rgb": "86,79,139"
            },
            {
                "nr": "PANTONE 19-3847 TPG",
                "name": "Deep Blue",
                "book": 1,
                "page": 184,
                "row": 4,
                "hexCode": "#544B82",
                "rgb": "83,75,129"
            },
            {
                "nr": "PANTONE 19-3737 TPG",
                "name": "Heliotrope",
                "book": 1,
                "page": 184,
                "row": 5,
                "hexCode": "#5B4678",
                "rgb": "90,70,119"
            },
            {
                "nr": "PANTONE 19-3750 TPG",
                "name": "Violet Indigo",
                "book": 1,
                "page": 184,
                "row": 6,
                "hexCode": "#4F3F68",
                "rgb": "79,62,103"
            },
            {
                "nr": "PANTONE 19-3731 TPG",
                "name": "Parachute Purple",
                "book": 1,
                "page": 184,
                "row": 7,
                "hexCode": "#504363",
                "rgb": "80,67,99"
            }
        ],
        [
            {
                "nr": "PANTONE 17-3834 TPG",
                "name": "Dahlia Purple",
                "book": 1,
                "page": 185,
                "row": 1,
                "hexCode": "#8272AD",
                "rgb": "129,113,173"
            },
            {
                "nr": "PANTONE 18-3737 TPG",
                "name": "Passion Flower",
                "book": 1,
                "page": 185,
                "row": 2,
                "hexCode": "#715C99",
                "rgb": "113,91,152"
            },
            {
                "nr": "PANTONE 18-3839 TPG",
                "name": "Purple Corallites",
                "book": 1,
                "page": 185,
                "row": 3,
                "hexCode": "#625593",
                "rgb": "97,84,147"
            },
            {
                "nr": "PANTONE 18-3838 TPG",
                "name": "Ultra Violet",
                "book": 1,
                "page": 185,
                "row": 4,
                "hexCode": "#6B5B95",
                "rgb": "107,90,149"
            },
            {
                "nr": "PANTONE 19-3748 TPG",
                "name": "Prism Violet",
                "book": 1,
                "page": 185,
                "row": 5,
                "hexCode": "#605089",
                "rgb": "96,79,136"
            },
            {
                "nr": "PANTONE 19-3642 TPG",
                "name": "Royal Purple",
                "book": 1,
                "page": 185,
                "row": 6,
                "hexCode": "#634F82",
                "rgb": "98,78,129"
            },
            {
                "nr": "PANTONE 19-3638 TPG",
                "name": "Tillandsia Purple",
                "book": 1,
                "page": 185,
                "row": 7,
                "hexCode": "#64437D",
                "rgb": "99,67,125"
            }
        ],
        [
            {
                "nr": "PANTONE 14-3812 TPG",
                "name": "Pastel Lilac",
                "book": 1,
                "page": 186,
                "row": 1,
                "hexCode": "#BFB1D2",
                "rgb": "190,177,210"
            },
            {
                "nr": "PANTONE 15-3817 TPG",
                "name": "Lavender",
                "book": 1,
                "page": 186,
                "row": 2,
                "hexCode": "#B2A4D0",
                "rgb": "178,163,207"
            },
            {
                "nr": "PANTONE 16-3823 TPG",
                "name": "Violet Tulip",
                "book": 1,
                "page": 186,
                "row": 3,
                "hexCode": "#9C90C4",
                "rgb": "156,144,196"
            },
            {
                "nr": "PANTONE 17-3725 TPG",
                "name": "Bougainvillea",
                "book": 1,
                "page": 186,
                "row": 4,
                "hexCode": "#9D86BD",
                "rgb": "156,134,188"
            },
            {
                "nr": "PANTONE 17-3730 TPG",
                "name": "Paisley Purple",
                "book": 1,
                "page": 186,
                "row": 5,
                "hexCode": "#8B7AB0",
                "rgb": "138,121,176"
            },
            {
                "nr": "PANTONE 17-3826 TPG",
                "name": "Aster Purple",
                "book": 1,
                "page": 186,
                "row": 6,
                "hexCode": "#8278A9",
                "rgb": "129,119,168"
            },
            {
                "nr": "PANTONE 19-3730 TPG",
                "name": "Gentian Violet",
                "book": 1,
                "page": 186,
                "row": 7,
                "hexCode": "#605282",
                "rgb": "96,81,130"
            }
        ],
        [
            {
                "nr": "PANTONE 14-3710 TPG",
                "name": "Orchid Petal",
                "book": 1,
                "page": 187,
                "row": 1,
                "hexCode": "#BDB1CB",
                "rgb": "189,177,203"
            },
            {
                "nr": "PANTONE 16-3810 TPG",
                "name": "Wisteria",
                "book": 1,
                "page": 187,
                "row": 2,
                "hexCode": "#A19AB3",
                "rgb": "161,153,178"
            },
            {
                "nr": "PANTONE 16-3812 TPG",
                "name": "Heirloom Lilac",
                "book": 1,
                "page": 187,
                "row": 3,
                "hexCode": "#9993B1",
                "rgb": "153,147,177"
            },
            {
                "nr": "PANTONE 17-3817 TPG",
                "name": "Daybreak",
                "book": 1,
                "page": 187,
                "row": 4,
                "hexCode": "#8A83A3",
                "rgb": "138,130,163"
            },
            {
                "nr": "PANTONE 17-3812 TPG",
                "name": "Dusk",
                "book": 1,
                "page": 187,
                "row": 5,
                "hexCode": "#8B839D",
                "rgb": "138,130,156"
            },
            {
                "nr": "PANTONE 17-3615 TPG",
                "name": "Chalk Violet",
                "book": 1,
                "page": 187,
                "row": 6,
                "hexCode": "#8E7CA5",
                "rgb": "141,123,165"
            },
            {
                "nr": "PANTONE 18-3718 TPG",
                "name": "Purple Haze",
                "book": 1,
                "page": 187,
                "row": 7,
                "hexCode": "#807398",
                "rgb": "127,114,151"
            }
        ],
        [
            {
                "nr": "PANTONE 15-3807 TPG",
                "name": "Misty Lilac",
                "book": 1,
                "page": 188,
                "row": 1,
                "hexCode": "#BBB6C6",
                "rgb": "186,182,197"
            },
            {
                "nr": "PANTONE 14-3907 TPG",
                "name": "Thistle",
                "book": 1,
                "page": 188,
                "row": 2,
                "hexCode": "#BCB7CA",
                "rgb": "187,183,201"
            },
            {
                "nr": "PANTONE 14-3904 TPG",
                "name": "Evening Haze",
                "book": 1,
                "page": 188,
                "row": 3,
                "hexCode": "#BCB8C8",
                "rgb": "188,184,200"
            },
            {
                "nr": "PANTONE 17-3910 TPG",
                "name": "Lavender Gray",
                "book": 1,
                "page": 188,
                "row": 4,
                "hexCode": "#9690A3",
                "rgb": "149,144,162"
            },
            {
                "nr": "PANTONE 17-3810 TPG",
                "name": "Purple Ash",
                "book": 1,
                "page": 188,
                "row": 5,
                "hexCode": "#8D8598",
                "rgb": "141,133,151"
            },
            {
                "nr": "PANTONE 18-3710 TPG",
                "name": "Gray Ridge",
                "book": 1,
                "page": 188,
                "row": 6,
                "hexCode": "#807785",
                "rgb": "127,118,132"
            },
            {
                "nr": "PANTONE 18-3712 TPG",
                "name": "Purple Sage",
                "book": 1,
                "page": 188,
                "row": 7,
                "hexCode": "#756C7F",
                "rgb": "116,107,127"
            }
        ],
        [
            {
                "nr": "PANTONE 14-3903 TPG",
                "name": "Lilac Marble",
                "book": 1,
                "page": 189,
                "row": 1,
                "hexCode": "#C0B9C2",
                "rgb": "191,185,193"
            },
            {
                "nr": "PANTONE 15-3802 TPG",
                "name": "Cloud Gray",
                "book": 1,
                "page": 189,
                "row": 2,
                "hexCode": "#B4A8AC",
                "rgb": "180,168,172"
            },
            {
                "nr": "PANTONE 14-3906 TPG",
                "name": "Raindrops",
                "book": 1,
                "page": 189,
                "row": 3,
                "hexCode": "#B4ACB3",
                "rgb": "180,172,179"
            },
            {
                "nr": "PANTONE 14-3805 TPG",
                "name": "Iris",
                "book": 1,
                "page": 189,
                "row": 4,
                "hexCode": "#BCB1BE",
                "rgb": "187,177,190"
            },
            {
                "nr": "PANTONE 17-3808 TPG",
                "name": "Nirvana",
                "book": 1,
                "page": 189,
                "row": 5,
                "hexCode": "#9C8D9A",
                "rgb": "155,141,153"
            },
            {
                "nr": "PANTONE 16-3304 TPG",
                "name": "Sea Fog",
                "book": 1,
                "page": 189,
                "row": 6,
                "hexCode": "#A494A1",
                "rgb": "164,148,161"
            },
            {
                "nr": "PANTONE 17-1505 TPG",
                "name": "Quail",
                "book": 1,
                "page": 189,
                "row": 7,
                "hexCode": "#98878F",
                "rgb": "151,135,142"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4105 TPG",
                "name": "Lilac Hint",
                "book": 1,
                "page": 190,
                "row": 1,
                "hexCode": "#D1D0D8",
                "rgb": "209,208,216"
            },
            {
                "nr": "PANTONE 14-3905 TPG",
                "name": "Lavender Blue",
                "book": 1,
                "page": 190,
                "row": 2,
                "hexCode": "#C0BDD1",
                "rgb": "191,189,208"
            },
            {
                "nr": "PANTONE 16-3911 TPG",
                "name": "Lavender Aura",
                "book": 1,
                "page": 190,
                "row": 3,
                "hexCode": "#9F99AC",
                "rgb": "158,152,171"
            },
            {
                "nr": "PANTONE 16-3905 TPG",
                "name": "Lilac Gray",
                "book": 1,
                "page": 190,
                "row": 4,
                "hexCode": "#9E9CA9",
                "rgb": "158,156,169"
            },
            {
                "nr": "PANTONE 16-3907 TPG",
                "name": "Dapple Gray",
                "book": 1,
                "page": 190,
                "row": 5,
                "hexCode": "#9C9CA9",
                "rgb": "156,155,168"
            },
            {
                "nr": "PANTONE 17-3906 TPG",
                "name": "Minimal Gray",
                "book": 1,
                "page": 190,
                "row": 6,
                "hexCode": "#918E9B",
                "rgb": "145,141,154"
            },
            {
                "nr": "PANTONE 18-1703 TPG",
                "name": "Shark",
                "book": 1,
                "page": 190,
                "row": 7,
                "hexCode": "#6D6771",
                "rgb": "108,103,112"
            }
        ]
    ],
    cold: [
        [
            {
                "nr": "PANTONE 15-3910 TPG",
                "name": "Languid Lavender",
                "book": 2,
                "page": 1,
                "row": 1,
                "hexCode": "#A3A3BC",
                "rgb": "162,162,187"
            },
            {
                "nr": "PANTONE 15-3908 TPG",
                "name": "Icelandic Blue",
                "book": 2,
                "page": 1,
                "row": 2,
                "hexCode": "#B0B3C7",
                "rgb": "176,179,198"
            },
            {
                "nr": "PANTONE 15-3912 TPG",
                "name": "Aleutian",
                "book": 2,
                "page": 1,
                "row": 3,
                "hexCode": "#9CA0B5",
                "rgb": "155,159,180"
            },
            {
                "nr": "PANTONE 17-3933 TPG",
                "name": "Silver Bullet",
                "book": 2,
                "page": 1,
                "row": 4,
                "hexCode": "#86879E",
                "rgb": "134,134,157"
            },
            {
                "nr": "PANTONE 17-3907 TPG",
                "name": "Quicksilver",
                "book": 2,
                "page": 1,
                "row": 5,
                "hexCode": "#83838F",
                "rgb": "130,130,143"
            },
            {
                "nr": "PANTONE 18-3933 TPG",
                "name": "Blue Granite",
                "book": 2,
                "page": 1,
                "row": 6,
                "hexCode": "#77798E",
                "rgb": "119,121,141"
            },
            {
                "nr": "PANTONE 18-3812 TPG",
                "name": "Cadet",
                "book": 2,
                "page": 1,
                "row": 7,
                "hexCode": "#6C677D",
                "rgb": "108,102,124"
            }
        ],
        [
            {
                "nr": "PANTONE 14-3911 TPG",
                "name": "Purple Heather",
                "book": 2,
                "page": 2,
                "row": 1,
                "hexCode": "#B9B7D4",
                "rgb": "185,183,211"
            },
            {
                "nr": "PANTONE 15-3909 TPG",
                "name": "Cosmic Sky",
                "book": 2,
                "page": 2,
                "row": 2,
                "hexCode": "#A5A8C4",
                "rgb": "165,168,195"
            },
            {
                "nr": "PANTONE 16-3930 TPG",
                "name": "Thistle Down",
                "book": 2,
                "page": 2,
                "row": 3,
                "hexCode": "#959AC0",
                "rgb": "148,153,191"
            },
            {
                "nr": "PANTONE 17-3925 TPG",
                "name": "Persian Violet",
                "book": 2,
                "page": 2,
                "row": 4,
                "hexCode": "#8B8DB2",
                "rgb": "139,141,178"
            },
            {
                "nr": "PANTONE 18-3820 TPG",
                "name": "Twilight Purple",
                "book": 2,
                "page": 2,
                "row": 5,
                "hexCode": "#6A698F",
                "rgb": "105,105,143"
            },
            {
                "nr": "PANTONE 18-3817 TPG",
                "name": "Heron",
                "book": 2,
                "page": 2,
                "row": 6,
                "hexCode": "#686884",
                "rgb": "103,104,132"
            },
            {
                "nr": "PANTONE 19-3842 TPG",
                "name": "Deep Wisteria",
                "book": 2,
                "page": 2,
                "row": 7,
                "hexCode": "#53507A",
                "rgb": "82,79,121"
            }
        ],
        [
            {
                "nr": "PANTONE 16-3931 TPG",
                "name": "Sweet Lavender",
                "book": 2,
                "page": 3,
                "row": 1,
                "hexCode": "#9C9CC4",
                "rgb": "155,155,196"
            },
            {
                "nr": "PANTONE 17-3924 TPG",
                "name": "Lavender Violet",
                "book": 2,
                "page": 3,
                "row": 2,
                "hexCode": "#7880A9",
                "rgb": "120,128,169"
            },
            {
                "nr": "PANTONE 18-3930 TPG",
                "name": "Bleached Denim",
                "book": 2,
                "page": 3,
                "row": 3,
                "hexCode": "#6D76A1",
                "rgb": "108,118,160"
            },
            {
                "nr": "PANTONE 18-3833 TPG",
                "name": "Dusted Peri",
                "book": 2,
                "page": 3,
                "row": 4,
                "hexCode": "#696A9E",
                "rgb": "104,105,158"
            },
            {
                "nr": "PANTONE 18-3828 TPG",
                "name": "Corsican Blue",
                "book": 2,
                "page": 3,
                "row": 5,
                "hexCode": "#696697",
                "rgb": "105,102,151"
            },
            {
                "nr": "PANTONE 19-3947 TPG",
                "name": "Orient Blue",
                "book": 2,
                "page": 3,
                "row": 6,
                "hexCode": "#555282",
                "rgb": "84,82,129"
            },
            {
                "nr": "PANTONE 18-3963 TPG",
                "name": "Spectrum Blue",
                "book": 2,
                "page": 3,
                "row": 7,
                "hexCode": "#494A83",
                "rgb": "73,74,131"
            }
        ],
        [
            {
                "nr": "PANTONE 17-3929 TPG",
                "name": "Pale Iris",
                "book": 2,
                "page": 4,
                "row": 1,
                "hexCode": "#8B96C9",
                "rgb": "139,150,200"
            },
            {
                "nr": "PANTONE 17-3932 TPG",
                "name": "Deep Periwinkle",
                "book": 2,
                "page": 4,
                "row": 2,
                "hexCode": "#7D84BD",
                "rgb": "124,132,188"
            },
            {
                "nr": "PANTONE 17-3940 TPG",
                "name": "Iolite",
                "book": 2,
                "page": 4,
                "row": 3,
                "hexCode": "#757EB9",
                "rgb": "116,125,184"
            },
            {
                "nr": "PANTONE 18-3944 TPG",
                "name": "Violet Storm",
                "book": 2,
                "page": 4,
                "row": 4,
                "hexCode": "#5F65A2",
                "rgb": "94,101,162"
            },
            {
                "nr": "PANTONE 18-3950 TPG",
                "name": "Iris Bloom",
                "book": 2,
                "page": 4,
                "row": 5,
                "hexCode": "#6266A3",
                "rgb": "98,101,163"
            },
            {
                "nr": "PANTONE 18-3943 TPG",
                "name": "Blue Iris",
                "book": 2,
                "page": 4,
                "row": 6,
                "hexCode": "#6364A5",
                "rgb": "99,99,165"
            },
            {
                "nr": "PANTONE 19-3832 TPG",
                "name": "Navy Blue",
                "book": 2,
                "page": 4,
                "row": 7,
                "hexCode": "#4B4C76",
                "rgb": "75,76,117"
            }
        ],
        [
            {
                "nr": "PANTONE 16-3925 TPG",
                "name": "Easter Egg",
                "book": 2,
                "page": 5,
                "row": 1,
                "hexCode": "#9199CC",
                "rgb": "144,153,203"
            },
            {
                "nr": "PANTONE 17-3930 TPG",
                "name": "Jacaranda",
                "book": 2,
                "page": 5,
                "row": 2,
                "hexCode": "#888FC7",
                "rgb": "135,143,198"
            },
            {
                "nr": "PANTONE 17-3934 TPG",
                "name": "Persian Jewel",
                "book": 2,
                "page": 5,
                "row": 3,
                "hexCode": "#6C80BE",
                "rgb": "108,127,189"
            },
            {
                "nr": "PANTONE 18-3935 TPG",
                "name": "Wedgewood",
                "book": 2,
                "page": 5,
                "row": 4,
                "hexCode": "#697CB5",
                "rgb": "105,124,181"
            },
            {
                "nr": "PANTONE 18-3946 TPG",
                "name": "Baja Blue",
                "book": 2,
                "page": 5,
                "row": 5,
                "hexCode": "#5A69AF",
                "rgb": "89,104,174"
            },
            {
                "nr": "PANTONE 19-3955 TPG",
                "name": "Royal Blue",
                "book": 2,
                "page": 5,
                "row": 6,
                "hexCode": "#4D4E8D",
                "rgb": "76,77,141"
            },
            {
                "nr": "PANTONE 19-3951 TPG",
                "name": "Clematis Blue",
                "book": 2,
                "page": 5,
                "row": 7,
                "hexCode": "#474981",
                "rgb": "71,72,128"
            }
        ],
        [
            {
                "nr": "PANTONE 17-4037 TPG",
                "name": "Ultramarine",
                "book": 2,
                "page": 6,
                "row": 1,
                "hexCode": "#5F7DBE",
                "rgb": "95,125,189"
            },
            {
                "nr": "PANTONE 18-3945 TPG",
                "name": "Amparo Blue",
                "book": 2,
                "page": 6,
                "row": 2,
                "hexCode": "#5065AC",
                "rgb": "80,100,172"
            },
            {
                "nr": "PANTONE 18-3949 TPG",
                "name": "Dazzling Blue",
                "book": 2,
                "page": 6,
                "row": 3,
                "hexCode": "#3E55AB",
                "rgb": "61,84,170"
            },
            {
                "nr": "PANTONE 19-3950 TPG",
                "name": "Deep Ultramarine",
                "book": 2,
                "page": 6,
                "row": 4,
                "hexCode": "#485489",
                "rgb": "71,83,136"
            },
            {
                "nr": "PANTONE 19-3954 TPG",
                "name": "Bluing",
                "book": 2,
                "page": 6,
                "row": 5,
                "hexCode": "#3F488E",
                "rgb": "63,72,142"
            },
            {
                "nr": "PANTONE 19-3952 TPG",
                "name": "Surf The Web",
                "book": 2,
                "page": 6,
                "row": 6,
                "hexCode": "#3E4989",
                "rgb": "62,72,137"
            },
            {
                "nr": "PANTONE 19-3864 TPG",
                "name": "Mazarine Blue",
                "book": 2,
                "page": 6,
                "row": 7,
                "hexCode": "#414A7E",
                "rgb": "65,74,125"
            }
        ],
        [
            {
                "nr": "PANTONE 19-3964 TPG",
                "name": "Blue Quartz",
                "book": 2,
                "page": 7,
                "row": 1,
                "hexCode": "#3A5081",
                "rgb": "58,79,128"
            },
            {
                "nr": "PANTONE 19-4055 TPG",
                "name": "Galaxy Blue",
                "book": 2,
                "page": 7,
                "row": 2,
                "hexCode": "#415786",
                "rgb": "64,86,134"
            },
            {
                "nr": "PANTONE 19-4041 TPG",
                "name": "Déja Vu Blue",
                "book": 2,
                "page": 7,
                "row": 3,
                "hexCode": "#3B5B8A",
                "rgb": "59,91,138"
            },
            {
                "nr": "PANTONE 19-4057 TPG",
                "name": "True Blue",
                "book": 2,
                "page": 7,
                "row": 4,
                "hexCode": "#395181",
                "rgb": "57,80,128"
            },
            {
                "nr": "PANTONE 19-4044 TPG",
                "name": "Limoges",
                "book": 2,
                "page": 7,
                "row": 5,
                "hexCode": "#3D4D7A",
                "rgb": "60,77,121"
            },
            {
                "nr": "PANTONE 19-4029 TPG",
                "name": "Navy Peony",
                "book": 2,
                "page": 7,
                "row": 6,
                "hexCode": "#394868",
                "rgb": "57,71,103"
            },
            {
                "nr": "PANTONE 19-3953 TPG",
                "name": "Sodalite Blue",
                "book": 2,
                "page": 7,
                "row": 7,
                "hexCode": "#384477",
                "rgb": "56,68,119"
            }
        ],
        [
            {
                "nr": "PANTONE 19-4045 TPG",
                "name": "Lapis Blue",
                "book": 2,
                "page": 8,
                "row": 1,
                "hexCode": "#2D5593",
                "rgb": "45,84,147"
            },
            {
                "nr": "PANTONE 19-4150 TPG",
                "name": "Princess Blue",
                "book": 2,
                "page": 8,
                "row": 2,
                "hexCode": "#2E5D9F",
                "rgb": "45,92,158"
            },
            {
                "nr": "PANTONE 18-4148 TPG",
                "name": "Victoria Blue",
                "book": 2,
                "page": 8,
                "row": 3,
                "hexCode": "#3263A4",
                "rgb": "50,99,164"
            },
            {
                "nr": "PANTONE 19-4049 TPG",
                "name": "Snorkel Blue",
                "book": 2,
                "page": 8,
                "row": 4,
                "hexCode": "#1B538F",
                "rgb": "27,83,142"
            },
            {
                "nr": "PANTONE 19-4050 TPG",
                "name": "Nautical Blue",
                "book": 2,
                "page": 8,
                "row": 5,
                "hexCode": "#315592",
                "rgb": "49,84,146"
            },
            {
                "nr": "PANTONE 19-4053 TPG",
                "name": "Turkish Sea",
                "book": 2,
                "page": 8,
                "row": 6,
                "hexCode": "#375998",
                "rgb": "54,89,151"
            },
            {
                "nr": "PANTONE 19-4056 TPG",
                "name": "Blue Lolite",
                "book": 2,
                "page": 8,
                "row": 7,
                "hexCode": "#3D5C97",
                "rgb": "60,91,151"
            }
        ],
        [
            {
                "nr": "PANTONE 18-4039 TPG",
                "name": "Regatta",
                "book": 2,
                "page": 9,
                "row": 1,
                "hexCode": "#547DB9",
                "rgb": "83,125,184"
            },
            {
                "nr": "PANTONE 18-4043 TPG",
                "name": "Palace Blue",
                "book": 2,
                "page": 9,
                "row": 2,
                "hexCode": "#4871B3",
                "rgb": "72,112,178"
            },
            {
                "nr": "PANTONE 18-4143 TPG",
                "name": "Super Sonic",
                "book": 2,
                "page": 9,
                "row": 3,
                "hexCode": "#4178BC",
                "rgb": "65,120,188"
            },
            {
                "nr": "PANTONE 18-4051 TPG",
                "name": "Strong Blue",
                "book": 2,
                "page": 9,
                "row": 4,
                "hexCode": "#3B64A7",
                "rgb": "58,100,166"
            },
            {
                "nr": "PANTONE 18-4048 TPG",
                "name": "Nebulas Blue",
                "book": 2,
                "page": 9,
                "row": 5,
                "hexCode": "#3F69AA",
                "rgb": "63,104,170"
            },
            {
                "nr": "PANTONE 19-4058 TPG",
                "name": "Beaucoup Blue",
                "book": 2,
                "page": 9,
                "row": 6,
                "hexCode": "#415997",
                "rgb": "65,89,151"
            },
            {
                "nr": "PANTONE 19-4054 TPG",
                "name": "Nouvean Navy",
                "book": 2,
                "page": 9,
                "row": 7,
                "hexCode": "#485F91",
                "rgb": "72,95,145"
            }
        ],
        [
            {
                "nr": "PANTONE 18-4141 TPG",
                "name": "Campanula",
                "book": 2,
                "page": 10,
                "row": 1,
                "hexCode": "#4176B0",
                "rgb": "64,117,175"
            },
            {
                "nr": "PANTONE 18-4250 TPG",
                "name": "Indigo Bunting",
                "book": 2,
                "page": 10,
                "row": 2,
                "hexCode": "#006EAE",
                "rgb": "0,110,174"
            },
            {
                "nr": "PANTONE 18-4244 TPG",
                "name": "Directoire Blue",
                "book": 2,
                "page": 10,
                "row": 3,
                "hexCode": "#0369AA",
                "rgb": "3,105,170"
            },
            {
                "nr": "PANTONE 18-4045 TPG",
                "name": "Daphne",
                "book": 2,
                "page": 10,
                "row": 4,
                "hexCode": "#31679F",
                "rgb": "49,102,159"
            },
            {
                "nr": "PANTONE 19-4151 TPG",
                "name": "Skydiver",
                "book": 2,
                "page": 10,
                "row": 5,
                "hexCode": "#1F609E",
                "rgb": "30,95,158"
            },
            {
                "nr": "PANTONE 19-4048 TPG",
                "name": "Baleine Blue",
                "book": 2,
                "page": 10,
                "row": 6,
                "hexCode": "#30598C",
                "rgb": "48,88,139"
            },
            {
                "nr": "PANTONE 19-4052 TPG",
                "name": "Classic Blue",
                "book": 2,
                "page": 10,
                "row": 7,
                "hexCode": "#34558B",
                "rgb": "51,85,139"
            }
        ],
        [
            {
                "nr": "PANTONE 16-4132 TPG",
                "name": "Little Boy Blue",
                "book": 2,
                "page": 11,
                "row": 1,
                "hexCode": "#6F9FD8",
                "rgb": "111,159,216"
            },
            {
                "nr": "PANTONE 17-4139 TPG",
                "name": "Azure Blue",
                "book": 2,
                "page": 11,
                "row": 2,
                "hexCode": "#508BC3",
                "rgb": "79,138,195"
            },
            {
                "nr": "PANTONE 17-4245 TPG",
                "name": "Ibiza Blue",
                "book": 2,
                "page": 11,
                "row": 3,
                "hexCode": "#277FBB",
                "rgb": "39,127,186"
            },
            {
                "nr": "PANTONE 18-4252 TPG",
                "name": "Blue Aster",
                "book": 2,
                "page": 11,
                "row": 4,
                "hexCode": "#007ABA",
                "rgb": "0,122,186"
            },
            {
                "nr": "PANTONE 18-4247 TPG",
                "name": "Brilliant Blue",
                "book": 2,
                "page": 11,
                "row": 5,
                "hexCode": "#0077B9",
                "rgb": "0,119,185"
            },
            {
                "nr": "PANTONE 18-4140 TPG",
                "name": "French Blue",
                "book": 2,
                "page": 11,
                "row": 6,
                "hexCode": "#3078B4",
                "rgb": "48,119,180"
            },
            {
                "nr": "PANTONE 19-4245 TPG",
                "name": "Imperial Blue",
                "book": 2,
                "page": 11,
                "row": 7,
                "hexCode": "#195D99",
                "rgb": "25,93,153"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4320 TPG",
                "name": "Baltic Sea",
                "book": 2,
                "page": 12,
                "row": 1,
                "hexCode": "#7AB7DC",
                "rgb": "121,182,220"
            },
            {
                "nr": "PANTONE 15-4323 TPG",
                "name": "Ethereal Blue",
                "book": 2,
                "page": 12,
                "row": 2,
                "hexCode": "#61A9D2",
                "rgb": "97,168,209"
            },
            {
                "nr": "PANTONE 16-4134 TPG",
                "name": "Bonnie Blue",
                "book": 2,
                "page": 12,
                "row": 3,
                "hexCode": "#59A0D1",
                "rgb": "88,160,209"
            },
            {
                "nr": "PANTONE 17-4435 TPG",
                "name": "Malibu Blue",
                "book": 2,
                "page": 12,
                "row": 4,
                "hexCode": "#2D91C6",
                "rgb": "45,144,198"
            },
            {
                "nr": "PANTONE 17-4129 TPG",
                "name": "Wave Ride",
                "book": 2,
                "page": 12,
                "row": 5,
                "hexCode": "#3785AC",
                "rgb": "55,133,172"
            },
            {
                "nr": "PANTONE 18-4032 TPG",
                "name": "Deep Water",
                "book": 2,
                "page": 12,
                "row": 6,
                "hexCode": "#3B6A90",
                "rgb": "59,106,144"
            },
            {
                "nr": "PANTONE 18-4037 TPG",
                "name": "Sea Of Belize",
                "book": 2,
                "page": 12,
                "row": 7,
                "hexCode": "#35709C",
                "rgb": "53,112,156"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4122 TPG",
                "name": "Airy Blue",
                "book": 2,
                "page": 13,
                "row": 1,
                "hexCode": "#95B8D9",
                "rgb": "148,184,216"
            },
            {
                "nr": "PANTONE 15-4225 TPG",
                "name": "Alaskan Blue",
                "book": 2,
                "page": 13,
                "row": 2,
                "hexCode": "#6FAAD3",
                "rgb": "111,169,210"
            },
            {
                "nr": "PANTONE 17-4032 TPG",
                "name": "Lichen Blue",
                "book": 2,
                "page": 13,
                "row": 3,
                "hexCode": "#638DB7",
                "rgb": "99,140,183"
            },
            {
                "nr": "PANTONE 18-4036 TPG",
                "name": "Parisian Blue",
                "book": 2,
                "page": 13,
                "row": 4,
                "hexCode": "#5581AB",
                "rgb": "85,129,170"
            },
            {
                "nr": "PANTONE 17-4033 TPG",
                "name": "Pacific Coast",
                "book": 2,
                "page": 13,
                "row": 5,
                "hexCode": "#5B84B1",
                "rgb": "91,131,177"
            },
            {
                "nr": "PANTONE 17-4029 TPG",
                "name": "Quiet Harbor",
                "book": 2,
                "page": 13,
                "row": 6,
                "hexCode": "#607DA0",
                "rgb": "96,125,160"
            },
            {
                "nr": "PANTONE 18-4029 TPG",
                "name": "Federal Blue",
                "book": 2,
                "page": 13,
                "row": 7,
                "hexCode": "#44638C",
                "rgb": "68,98,139"
            }
        ],
        [
            {
                "nr": "PANTONE 15-4020 TPG",
                "name": "Cerulean",
                "book": 2,
                "page": 14,
                "row": 1,
                "hexCode": "#98B2D1",
                "rgb": "152,178,209"
            },
            {
                "nr": "PANTONE 16-4121 TPG",
                "name": "Blissful Blue",
                "book": 2,
                "page": 14,
                "row": 2,
                "hexCode": "#7296BB",
                "rgb": "114,150,187"
            },
            {
                "nr": "PANTONE 16-4021 TPG",
                "name": "Allure",
                "book": 2,
                "page": 14,
                "row": 3,
                "hexCode": "#718DB1",
                "rgb": "113,141,177"
            },
            {
                "nr": "PANTONE 18-4035 TPG",
                "name": "Cyaneus",
                "book": 2,
                "page": 14,
                "row": 4,
                "hexCode": "#5C84A7",
                "rgb": "92,132,167"
            },
            {
                "nr": "PANTONE 18-4027 TPG",
                "name": "Moonlight Blue",
                "book": 2,
                "page": 14,
                "row": 5,
                "hexCode": "#586E8B",
                "rgb": "88,110,138"
            },
            {
                "nr": "PANTONE 18-3929 TPG",
                "name": "Blue Horizon",
                "book": 2,
                "page": 14,
                "row": 6,
                "hexCode": "#586C8A",
                "rgb": "87,108,138"
            },
            {
                "nr": "PANTONE 19-4026 TPG",
                "name": "Ensign Blue",
                "book": 2,
                "page": 14,
                "row": 7,
                "hexCode": "#45566F",
                "rgb": "69,86,110"
            }
        ],
        [
            {
                "nr": "PANTONE 16-4018 TPG",
                "name": "Ashleigh Blue",
                "book": 2,
                "page": 15,
                "row": 1,
                "hexCode": "#6F8AAD",
                "rgb": "111,138,173"
            },
            {
                "nr": "PANTONE 17-4027 TPG",
                "name": "Riviera",
                "book": 2,
                "page": 15,
                "row": 2,
                "hexCode": "#5D7AA5",
                "rgb": "92,121,165"
            },
            {
                "nr": "PANTONE 17-4028 TPG",
                "name": "Riverside",
                "book": 2,
                "page": 15,
                "row": 3,
                "hexCode": "#577199",
                "rgb": "87,113,153"
            },
            {
                "nr": "PANTONE 18-4034 TPG",
                "name": "Vallarta Blue",
                "book": 2,
                "page": 15,
                "row": 4,
                "hexCode": "#3E6A93",
                "rgb": "62,106,146"
            },
            {
                "nr": "PANTONE 18-4041 TPG",
                "name": "Star Sapphire",
                "book": 2,
                "page": 15,
                "row": 5,
                "hexCode": "#45689A",
                "rgb": "69,103,153"
            },
            {
                "nr": "PANTONE 19-4039 TPG",
                "name": "Delft",
                "book": 2,
                "page": 15,
                "row": 6,
                "hexCode": "#46618E",
                "rgb": "70,96,142"
            },
            {
                "nr": "PANTONE 19-4037 TPG",
                "name": "Bright Cobalt",
                "book": 2,
                "page": 15,
                "row": 7,
                "hexCode": "#416392",
                "rgb": "65,98,145"
            }
        ],
        [
            {
                "nr": "PANTONE 15-3932 TPG",
                "name": "Bel Air Blue",
                "book": 2,
                "page": 16,
                "row": 1,
                "hexCode": "#859DC5",
                "rgb": "132,156,196"
            },
            {
                "nr": "PANTONE 18-4033 TPG",
                "name": "Blue Jasper",
                "book": 2,
                "page": 16,
                "row": 2,
                "hexCode": "#5B86B2",
                "rgb": "91,134,178"
            },
            {
                "nr": "PANTONE 18-4022 TPG",
                "name": "High Tide",
                "book": 2,
                "page": 16,
                "row": 3,
                "hexCode": "#3E6490",
                "rgb": "62,100,144"
            },
            {
                "nr": "PANTONE 19-4042 TPG",
                "name": "Set Sail",
                "book": 2,
                "page": 16,
                "row": 4,
                "hexCode": "#365176",
                "rgb": "54,81,118"
            },
            {
                "nr": "PANTONE 19-4035 TPG",
                "name": "Dark Blue",
                "book": 2,
                "page": 16,
                "row": 5,
                "hexCode": "#405A7A",
                "rgb": "64,90,121"
            },
            {
                "nr": "PANTONE 19-4033 TPG",
                "name": "Poseidon",
                "book": 2,
                "page": 16,
                "row": 6,
                "hexCode": "#304661",
                "rgb": "47,69,96"
            },
            {
                "nr": "PANTONE 19-4117 TPG",
                "name": "Big Dipper",
                "book": 2,
                "page": 16,
                "row": 7,
                "hexCode": "#3F4B5B",
                "rgb": "63,75,91"
            }
        ],
        [
            {
                "nr": "PANTONE 16-4031 TPG",
                "name": "Cornflower Blue",
                "book": 2,
                "page": 17,
                "row": 1,
                "hexCode": "#7592CA",
                "rgb": "117,145,202"
            },
            {
                "nr": "PANTONE 16-4032 TPG",
                "name": "Provence",
                "book": 2,
                "page": 17,
                "row": 2,
                "hexCode": "#6A8DC3",
                "rgb": "106,141,195"
            },
            {
                "nr": "PANTONE 17-4041 TPG",
                "name": "Marina",
                "book": 2,
                "page": 17,
                "row": 3,
                "hexCode": "#5B89CB",
                "rgb": "91,136,202"
            },
            {
                "nr": "PANTONE 16-4033 TPG",
                "name": "Granada Sky",
                "book": 2,
                "page": 17,
                "row": 4,
                "hexCode": "#6585C1",
                "rgb": "101,132,192"
            },
            {
                "nr": "PANTONE 17-3936 TPG",
                "name": "Blue Bonnet",
                "book": 2,
                "page": 17,
                "row": 5,
                "hexCode": "#6987BC",
                "rgb": "104,134,187"
            },
            {
                "nr": "PANTONE 18-3937 TPG",
                "name": "Blue Yonder",
                "book": 2,
                "page": 17,
                "row": 6,
                "hexCode": "#5876A7",
                "rgb": "87,117,166"
            },
            {
                "nr": "PANTONE 18-3928 TPG",
                "name": "Dutch Blue",
                "book": 2,
                "page": 17,
                "row": 7,
                "hexCode": "#526891",
                "rgb": "82,103,145"
            }
        ],
        [
            {
                "nr": "PANTONE 16-3923 TPG",
                "name": "Baby Lavender",
                "book": 2,
                "page": 18,
                "row": 1,
                "hexCode": "#A4ABD5",
                "rgb": "164,171,213"
            },
            {
                "nr": "PANTONE 16-3921 TPG",
                "name": "Blue Heron",
                "book": 2,
                "page": 18,
                "row": 2,
                "hexCode": "#9BA6C9",
                "rgb": "155,166,201"
            },
            {
                "nr": "PANTONE 15-3919 TPG",
                "name": "Serenity",
                "book": 2,
                "page": 18,
                "row": 3,
                "hexCode": "#95AAD3",
                "rgb": "148,170,211"
            },
            {
                "nr": "PANTONE 15-3930 TPG",
                "name": "Vista Blue",
                "book": 2,
                "page": 18,
                "row": 4,
                "hexCode": "#7E9CD3",
                "rgb": "125,156,211"
            },
            {
                "nr": "PANTONE 16-3920 TPG",
                "name": "Lavender Lustre",
                "book": 2,
                "page": 18,
                "row": 5,
                "hexCode": "#8B9CC0",
                "rgb": "139,155,192"
            },
            {
                "nr": "PANTONE 16-4030 TPG",
                "name": "Hydrangea",
                "book": 2,
                "page": 18,
                "row": 6,
                "hexCode": "#8A9FCC",
                "rgb": "138,158,203"
            },
            {
                "nr": "PANTONE 16-3929 TPG",
                "name": "Grapemist",
                "book": 2,
                "page": 18,
                "row": 7,
                "hexCode": "#8396C9",
                "rgb": "131,150,201"
            }
        ],
        [
            {
                "nr": "PANTONE 15-4031 TPG",
                "name": "Windsurfer",
                "book": 2,
                "page": 19,
                "row": 1,
                "hexCode": "#A6BEE5",
                "rgb": "166,190,229"
            },
            {
                "nr": "PANTONE 15-3917 TPG",
                "name": "Frozen Fjord",
                "book": 2,
                "page": 19,
                "row": 2,
                "hexCode": "#8BA7CE",
                "rgb": "139,167,206"
            },
            {
                "nr": "PANTONE 15-3920 TPG",
                "name": "Placid Blue",
                "book": 2,
                "page": 19,
                "row": 3,
                "hexCode": "#8DACD3",
                "rgb": "140,171,210"
            },
            {
                "nr": "PANTONE 15-3922 TPG",
                "name": "Open Air",
                "book": 2,
                "page": 19,
                "row": 4,
                "hexCode": "#97B4E2",
                "rgb": "151,180,226"
            },
            {
                "nr": "PANTONE 16-4020 TPG",
                "name": "Della Robbia Blue",
                "book": 2,
                "page": 19,
                "row": 5,
                "hexCode": "#7899C8",
                "rgb": "119,152,200"
            },
            {
                "nr": "PANTONE 16-4029 TPG",
                "name": "Azurine",
                "book": 2,
                "page": 19,
                "row": 6,
                "hexCode": "#7592C2",
                "rgb": "117,146,194"
            },
            {
                "nr": "PANTONE 18-3936 TPG",
                "name": "Ebb And Flow",
                "book": 2,
                "page": 19,
                "row": 7,
                "hexCode": "#647EAC",
                "rgb": "100,126,172"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4202 TPG",
                "name": "Ice Water",
                "book": 2,
                "page": 20,
                "row": 1,
                "hexCode": "#C1D6EA",
                "rgb": "193,214,234"
            },
            {
                "nr": "PANTONE 14-4005 TPG",
                "name": "Nantucket Breeze",
                "book": 2,
                "page": 20,
                "row": 2,
                "hexCode": "#BCD5EA",
                "rgb": "188,213,234"
            },
            {
                "nr": "PANTONE 14-4124 TPG",
                "name": "Dutch Canal",
                "book": 2,
                "page": 20,
                "row": 3,
                "hexCode": "#99BDE4",
                "rgb": "153,189,228"
            },
            {
                "nr": "PANTONE 14-4214 TPG",
                "name": "Powder Blue",
                "book": 2,
                "page": 20,
                "row": 4,
                "hexCode": "#95B1CE",
                "rgb": "149,176,206"
            },
            {
                "nr": "PANTONE 14-4121 TPG",
                "name": "Blue Bell",
                "book": 2,
                "page": 20,
                "row": 5,
                "hexCode": "#92B2D5",
                "rgb": "146,178,213"
            },
            {
                "nr": "PANTONE 17-4140 TPG",
                "name": "All Aboard",
                "book": 2,
                "page": 20,
                "row": 6,
                "hexCode": "#5C98CD",
                "rgb": "92,152,205"
            },
            {
                "nr": "PANTONE 17-4030 TPG",
                "name": "Silver Lake Blue",
                "book": 2,
                "page": 20,
                "row": 7,
                "hexCode": "#6489B8",
                "rgb": "100,137,183"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4306 TPG",
                "name": "Ice Melt",
                "book": 2,
                "page": 21,
                "row": 1,
                "hexCode": "#D4E4F0",
                "rgb": "212,228,240"
            },
            {
                "nr": "PANTONE 13-4302 TPG",
                "name": "Skywriting",
                "book": 2,
                "page": 21,
                "row": 2,
                "hexCode": "#C8D3DF",
                "rgb": "200,211,223"
            },
            {
                "nr": "PANTONE 14-4112 TPG",
                "name": "Skyway",
                "book": 2,
                "page": 21,
                "row": 3,
                "hexCode": "#AABDD4",
                "rgb": "170,188,212"
            },
            {
                "nr": "PANTONE 14-4115 TPG",
                "name": "Cashmere Blue",
                "book": 2,
                "page": 21,
                "row": 4,
                "hexCode": "#A1B5D0",
                "rgb": "161,181,207"
            },
            {
                "nr": "PANTONE 15-4030 TPG",
                "name": "Chambray Blue",
                "book": 2,
                "page": 21,
                "row": 5,
                "hexCode": "#9EB4D4",
                "rgb": "158,179,212"
            },
            {
                "nr": "PANTONE 16-3922 TPG",
                "name": "Brunnera Blue",
                "book": 2,
                "page": 21,
                "row": 6,
                "hexCode": "#9AA8C9",
                "rgb": "153,168,201"
            },
            {
                "nr": "PANTONE 16-4022 TPG",
                "name": "Endless Sky",
                "book": 2,
                "page": 21,
                "row": 7,
                "hexCode": "#8097B6",
                "rgb": "128,151,182"
            }
        ],
        [
            {
                "nr": "PANTONE 13-3920 TPG",
                "name": "Halogen Blue",
                "book": 2,
                "page": 22,
                "row": 1,
                "hexCode": "#BDC4D6",
                "rgb": "188,195,214"
            },
            {
                "nr": "PANTONE 14-4110 TPG",
                "name": "Heather",
                "book": 2,
                "page": 22,
                "row": 2,
                "hexCode": "#B6C0D6",
                "rgb": "181,191,214"
            },
            {
                "nr": "PANTONE 14-3949 TPG",
                "name": "Xenon Blue",
                "book": 2,
                "page": 22,
                "row": 3,
                "hexCode": "#BBC3D7",
                "rgb": "186,194,214"
            },
            {
                "nr": "PANTONE 15-3915 TPG",
                "name": "Kentucky Blue",
                "book": 2,
                "page": 22,
                "row": 4,
                "hexCode": "#A0B0D1",
                "rgb": "159,176,209"
            },
            {
                "nr": "PANTONE 14-3912 TPG",
                "name": "Zen Blue",
                "book": 2,
                "page": 22,
                "row": 5,
                "hexCode": "#A6AFC3",
                "rgb": "165,174,194"
            },
            {
                "nr": "PANTONE 16-4016 TPG",
                "name": "Soft Chambray",
                "book": 2,
                "page": 22,
                "row": 6,
                "hexCode": "#93A6BE",
                "rgb": "147,166,190"
            },
            {
                "nr": "PANTONE 15-4009 TPG",
                "name": "Blue Blizzard",
                "book": 2,
                "page": 22,
                "row": 7,
                "hexCode": "#95A1B7",
                "rgb": "149,161,183"
            }
        ],
        [
            {
                "nr": "PANTONE 12-4108 TPG",
                "name": "Ancient Water",
                "book": 2,
                "page": 23,
                "row": 1,
                "hexCode": "#D1DAE7",
                "rgb": "209,218,231"
            },
            {
                "nr": "PANTONE 13-4110 TPG",
                "name": "Arctic Ice",
                "book": 2,
                "page": 23,
                "row": 2,
                "hexCode": "#C2C9D6",
                "rgb": "194,201,213"
            },
            {
                "nr": "PANTONE 14-4211 TPG",
                "name": "Niagara Mist",
                "book": 2,
                "page": 23,
                "row": 3,
                "hexCode": "#ADBDCC",
                "rgb": "173,189,204"
            },
            {
                "nr": "PANTONE 16-4023 TPG",
                "name": "Rain Washed",
                "book": 2,
                "page": 23,
                "row": 4,
                "hexCode": "#8294AC",
                "rgb": "130,148,172"
            },
            {
                "nr": "PANTONE 17-3912 TPG",
                "name": "Wild Wind",
                "book": 2,
                "page": 23,
                "row": 5,
                "hexCode": "#6C7690",
                "rgb": "108,118,144"
            },
            {
                "nr": "PANTONE 17-3926 TPG",
                "name": "Troposphere",
                "book": 2,
                "page": 23,
                "row": 6,
                "hexCode": "#79879F",
                "rgb": "121,135,159"
            },
            {
                "nr": "PANTONE 18-3919 TPG",
                "name": "Bluefin",
                "book": 2,
                "page": 23,
                "row": 7,
                "hexCode": "#576A7E",
                "rgb": "87,106,126"
            }
        ],
        [
            {
                "nr": "PANTONE 16-3919 TPG",
                "name": "Eventide",
                "book": 2,
                "page": 24,
                "row": 1,
                "hexCode": "#909BB5",
                "rgb": "144,155,181"
            },
            {
                "nr": "PANTONE 16-4019 TPG",
                "name": "Forever Blue",
                "book": 2,
                "page": 24,
                "row": 2,
                "hexCode": "#839ABB",
                "rgb": "130,153,186"
            },
            {
                "nr": "PANTONE 17-4015 TPG",
                "name": "Infinity",
                "book": 2,
                "page": 24,
                "row": 3,
                "hexCode": "#6B7D98",
                "rgb": "107,124,151"
            },
            {
                "nr": "PANTONE 17-3915 TPG",
                "name": "Tempest",
                "book": 2,
                "page": 24,
                "row": 4,
                "hexCode": "#76819B",
                "rgb": "117,129,155"
            },
            {
                "nr": "PANTONE 17-3917 TPG",
                "name": "Stonewash",
                "book": 2,
                "page": 24,
                "row": 5,
                "hexCode": "#77839E",
                "rgb": "119,130,157"
            },
            {
                "nr": "PANTONE 17-3918 TPG",
                "name": "Country Blue",
                "book": 2,
                "page": 24,
                "row": 6,
                "hexCode": "#707E9B",
                "rgb": "111,126,154"
            },
            {
                "nr": "PANTONE 19-3929 TPG",
                "name": "Vintage Indigo",
                "book": 2,
                "page": 24,
                "row": 7,
                "hexCode": "#535D71",
                "rgb": "83,93,112"
            }
        ],
        [
            {
                "nr": "PANTONE 18-3916 TPG",
                "name": "Flint Stone",
                "book": 2,
                "page": 25,
                "row": 1,
                "hexCode": "#687585",
                "rgb": "104,116,133"
            },
            {
                "nr": "PANTONE 18-3910 TPG",
                "name": "Folkstone Gray",
                "book": 2,
                "page": 25,
                "row": 2,
                "hexCode": "#61697A",
                "rgb": "97,105,121"
            },
            {
                "nr": "PANTONE 18-3912 TPG",
                "name": "Grisaille",
                "book": 2,
                "page": 25,
                "row": 3,
                "hexCode": "#616676",
                "rgb": "97,101,118"
            },
            {
                "nr": "PANTONE 19-4121 TPG",
                "name": "Blue Wing Teal",
                "book": 2,
                "page": 25,
                "row": 4,
                "hexCode": "#384B60",
                "rgb": "56,75,96"
            },
            {
                "nr": "PANTONE 19-4028 TPG",
                "name": "Insignia Blue",
                "book": 2,
                "page": 25,
                "row": 5,
                "hexCode": "#414C60",
                "rgb": "64,76,96"
            },
            {
                "nr": "PANTONE 19-4118 TPG",
                "name": "Dark Denim",
                "book": 2,
                "page": 25,
                "row": 6,
                "hexCode": "#425267",
                "rgb": "66,81,103"
            },
            {
                "nr": "PANTONE 19-4122 TPG",
                "name": "Moonlit Ocean",
                "book": 2,
                "page": 25,
                "row": 7,
                "hexCode": "#3D4959",
                "rgb": "60,72,89"
            }
        ],
        [
            {
                "nr": "PANTONE 17-3919 TPG",
                "name": "Purple Impression",
                "book": 2,
                "page": 26,
                "row": 1,
                "hexCode": "#8791B5",
                "rgb": "134,144,180"
            },
            {
                "nr": "PANTONE 17-3923 TPG",
                "name": "Colony Blue",
                "book": 2,
                "page": 26,
                "row": 2,
                "hexCode": "#67789C",
                "rgb": "102,120,156"
            },
            {
                "nr": "PANTONE 18-3921 TPG",
                "name": "Bijou Blue",
                "book": 2,
                "page": 26,
                "row": 3,
                "hexCode": "#4D6080",
                "rgb": "76,95,128"
            },
            {
                "nr": "PANTONE 19-4030 TPG",
                "name": "True Navy",
                "book": 2,
                "page": 26,
                "row": 4,
                "hexCode": "#4C5B7D",
                "rgb": "75,90,124"
            },
            {
                "nr": "PANTONE 19-3926 TPG",
                "name": "Crown Blue",
                "book": 2,
                "page": 26,
                "row": 5,
                "hexCode": "#52566C",
                "rgb": "82,86,108"
            },
            {
                "nr": "PANTONE 19-4031 TPG",
                "name": "Sargasso Sea",
                "book": 2,
                "page": 26,
                "row": 6,
                "hexCode": "#485167",
                "rgb": "71,81,103"
            },
            {
                "nr": "PANTONE 19-4024 TPG",
                "name": "Dress Blues",
                "book": 2,
                "page": 26,
                "row": 7,
                "hexCode": "#373D4E",
                "rgb": "54,61,78"
            }
        ],
        [
            {
                "nr": "PANTONE 17-3920 TPG",
                "name": "English Manor",
                "book": 2,
                "page": 27,
                "row": 1,
                "hexCode": "#7181A3",
                "rgb": "112,129,163"
            },
            {
                "nr": "PANTONE 18-3920 TPG",
                "name": "Coastal Fjord",
                "book": 2,
                "page": 27,
                "row": 2,
                "hexCode": "#566284",
                "rgb": "85,98,132"
            },
            {
                "nr": "PANTONE 18-3917 TPG",
                "name": "Gray Blue",
                "book": 2,
                "page": 27,
                "row": 3,
                "hexCode": "#565F7E",
                "rgb": "86,94,126"
            },
            {
                "nr": "PANTONE 19-3928 TPG",
                "name": "Blue Indigo",
                "book": 2,
                "page": 27,
                "row": 4,
                "hexCode": "#515A75",
                "rgb": "80,90,116"
            },
            {
                "nr": "PANTONE 19-3919 TPG",
                "name": "Nightshadow Blue",
                "book": 2,
                "page": 27,
                "row": 5,
                "hexCode": "#595F73",
                "rgb": "89,94,115"
            },
            {
                "nr": "PANTONE 19-3915 TPG",
                "name": "Graystone",
                "book": 2,
                "page": 27,
                "row": 6,
                "hexCode": "#4D4E5F",
                "rgb": "77,77,94"
            },
            {
                "nr": "PANTONE 19-3815 TPG",
                "name": "Evening Blue",
                "book": 2,
                "page": 27,
                "row": 7,
                "hexCode": "#3A384D",
                "rgb": "58,56,77"
            }
        ],
        [
            {
                "nr": "PANTONE 17-3922 TPG",
                "name": "Blue Ice",
                "book": 2,
                "page": 28,
                "row": 1,
                "hexCode": "#747EA0",
                "rgb": "116,126,160"
            },
            {
                "nr": "PANTONE 18-3927 TPG",
                "name": "Velvet Morning",
                "book": 2,
                "page": 28,
                "row": 2,
                "hexCode": "#666E91",
                "rgb": "101,110,144"
            },
            {
                "nr": "PANTONE 18-3932 TPG",
                "name": "Marlin",
                "book": 2,
                "page": 28,
                "row": 3,
                "hexCode": "#5B6487",
                "rgb": "91,99,134"
            },
            {
                "nr": "PANTONE 19-3936 TPG",
                "name": "Skipper Blue",
                "book": 2,
                "page": 28,
                "row": 4,
                "hexCode": "#55567B",
                "rgb": "84,86,123"
            },
            {
                "nr": "PANTONE 19-3839 TPG",
                "name": "Blue Ribbon",
                "book": 2,
                "page": 28,
                "row": 5,
                "hexCode": "#4A4A69",
                "rgb": "74,74,104"
            },
            {
                "nr": "PANTONE 19-3830 TPG",
                "name": "Astral Aura",
                "book": 2,
                "page": 28,
                "row": 6,
                "hexCode": "#3F3C57",
                "rgb": "62,60,86"
            },
            {
                "nr": "PANTONE 19-3810 TPG",
                "name": "Eclipse",
                "book": 2,
                "page": 28,
                "row": 7,
                "hexCode": "#3B3A50",
                "rgb": "59,58,80"
            }
        ],
        [
            {
                "nr": "PANTONE 19-3934 TPG",
                "name": "Oceana",
                "book": 2,
                "page": 29,
                "row": 1,
                "hexCode": "#434D68",
                "rgb": "67,77,104"
            },
            {
                "nr": "PANTONE 19-3932 TPG",
                "name": "Naval Academy",
                "book": 2,
                "page": 29,
                "row": 2,
                "hexCode": "#3D4053",
                "rgb": "61,64,83"
            },
            {
                "nr": "PANTONE 19-3943 TPG",
                "name": "Bellwether Blue",
                "book": 2,
                "page": 29,
                "row": 3,
                "hexCode": "#383F65",
                "rgb": "56,63,101"
            },
            {
                "nr": "PANTONE 19-3851 TPG",
                "name": "Midnight Sail",
                "book": 2,
                "page": 29,
                "row": 4,
                "hexCode": "#3F425D",
                "rgb": "63,66,93"
            },
            {
                "nr": "PANTONE 19-3942 TPG",
                "name": "Beacon Blue",
                "book": 2,
                "page": 29,
                "row": 5,
                "hexCode": "#3D3D5E",
                "rgb": "61,61,94"
            },
            {
                "nr": "PANTONE 19-3937 TPG",
                "name": "Ocean Cavern",
                "book": 2,
                "page": 29,
                "row": 6,
                "hexCode": "#3A3F59",
                "rgb": "58,63,89"
            },
            {
                "nr": "PANTONE 19-3838 TPG",
                "name": "Rhodonite",
                "book": 2,
                "page": 29,
                "row": 7,
                "hexCode": "#3E3B59",
                "rgb": "62,59,89"
            }
        ],
        [
            {
                "nr": "PANTONE 19-3935 TPG",
                "name": "Deep Cobalt",
                "book": 2,
                "page": 30,
                "row": 1,
                "hexCode": "#4B4F6B",
                "rgb": "75,78,106"
            },
            {
                "nr": "PANTONE 19-4027 TPG",
                "name": "Estate Blue",
                "book": 2,
                "page": 30,
                "row": 2,
                "hexCode": "#3A4462",
                "rgb": "57,67,97"
            },
            {
                "nr": "PANTONE 19-3938 TPG",
                "name": "Twilight Blue",
                "book": 2,
                "page": 30,
                "row": 3,
                "hexCode": "#424C6D",
                "rgb": "66,75,109"
            },
            {
                "nr": "PANTONE 19-3939 TPG",
                "name": "Blueprint",
                "book": 2,
                "page": 30,
                "row": 4,
                "hexCode": "#3B4166",
                "rgb": "59,64,102"
            },
            {
                "nr": "PANTONE 19-3940 TPG",
                "name": "Blue Depths",
                "book": 2,
                "page": 30,
                "row": 5,
                "hexCode": "#3D4161",
                "rgb": "60,65,97"
            },
            {
                "nr": "PANTONE 19-3933 TPG",
                "name": "Medieval Blue",
                "book": 2,
                "page": 30,
                "row": 6,
                "hexCode": "#3F425C",
                "rgb": "62,66,91"
            },
            {
                "nr": "PANTONE 19-3925 TPG",
                "name": "Patriot Blue",
                "book": 2,
                "page": 30,
                "row": 7,
                "hexCode": "#42455D",
                "rgb": "66,69,93"
            }
        ],
        [
            {
                "nr": "PANTONE 19-4111 TPG",
                "name": "Pageant Blue",
                "book": 2,
                "page": 31,
                "row": 1,
                "hexCode": "#353E52",
                "rgb": "53,62,82"
            },
            {
                "nr": "PANTONE 19-4109 TPG",
                "name": "After Midnight",
                "book": 2,
                "page": 31,
                "row": 2,
                "hexCode": "#3E424B",
                "rgb": "62,66,75"
            },
            {
                "nr": "PANTONE 19-4018 TPG",
                "name": "Seaborne",
                "book": 2,
                "page": 31,
                "row": 3,
                "hexCode": "#41424E",
                "rgb": "65,66,78"
            },
            {
                "nr": "PANTONE 19-3812 TPG",
                "name": "Baritone Blue",
                "book": 2,
                "page": 31,
                "row": 4,
                "hexCode": "#3A3A46",
                "rgb": "58,58,70"
            },
            {
                "nr": "PANTONE 19-4017 TPG",
                "name": "Spellbound",
                "book": 2,
                "page": 31,
                "row": 5,
                "hexCode": "#444B5A",
                "rgb": "68,75,90"
            },
            {
                "nr": "PANTONE 19-3811 TPG",
                "name": "Inkling",
                "book": 2,
                "page": 31,
                "row": 6,
                "hexCode": "#3E3C49",
                "rgb": "62,60,73"
            },
            {
                "nr": "PANTONE 19-4105 TPG",
                "name": "Polar Night",
                "book": 2,
                "page": 31,
                "row": 7,
                "hexCode": "#43454F",
                "rgb": "67,69,79"
            }
        ],
        [
            {
                "nr": "PANTONE 19-3921 TPG",
                "name": "Black Iris",
                "book": 2,
                "page": 32,
                "row": 1,
                "hexCode": "#383E4E",
                "rgb": "56,61,77"
            },
            {
                "nr": "PANTONE 19-3831 TPG",
                "name": "Maritime Blue",
                "book": 2,
                "page": 32,
                "row": 2,
                "hexCode": "#3E3D4C",
                "rgb": "61,60,75"
            },
            {
                "nr": "PANTONE 19-4025 TPG",
                "name": "Mood Indigo",
                "book": 2,
                "page": 32,
                "row": 3,
                "hexCode": "#434A59",
                "rgb": "67,74,88"
            },
            {
                "nr": "PANTONE 19-3920 TPG",
                "name": "Peacoat",
                "book": 2,
                "page": 32,
                "row": 4,
                "hexCode": "#3F3F50",
                "rgb": "62,63,79"
            },
            {
                "nr": "PANTONE 19-4010 TPG",
                "name": "Total Eclipse",
                "book": 2,
                "page": 32,
                "row": 5,
                "hexCode": "#3B4049",
                "rgb": "59,63,73"
            },
            {
                "nr": "PANTONE 19-4022 TPG",
                "name": "Parisian Night",
                "book": 2,
                "page": 32,
                "row": 6,
                "hexCode": "#40434F",
                "rgb": "64,66,79"
            },
            {
                "nr": "PANTONE 19-4020 TPG",
                "name": "Dark Sapphire",
                "book": 2,
                "page": 32,
                "row": 7,
                "hexCode": "#3A3D49",
                "rgb": "57,61,73"
            }
        ],
        [
            {
                "nr": "PANTONE 19-3930 TPG",
                "name": "Odyssey Gray",
                "book": 2,
                "page": 33,
                "row": 1,
                "hexCode": "#4F4F5C",
                "rgb": "79,79,92"
            },
            {
                "nr": "PANTONE 19-4009 TPG",
                "name": "Outer Space",
                "book": 2,
                "page": 33,
                "row": 2,
                "hexCode": "#3B414F",
                "rgb": "59,65,79"
            },
            {
                "nr": "PANTONE 19-3927 TPG",
                "name": "Graphite",
                "book": 2,
                "page": 33,
                "row": 3,
                "hexCode": "#484754",
                "rgb": "71,70,83"
            },
            {
                "nr": "PANTONE 19-4023 TPG",
                "name": "Blue Nights",
                "book": 2,
                "page": 33,
                "row": 4,
                "hexCode": "#434752",
                "rgb": "66,71,81"
            },
            {
                "nr": "PANTONE 19-3923 TPG",
                "name": "Navy Blazer",
                "book": 2,
                "page": 33,
                "row": 5,
                "hexCode": "#3B3D4B",
                "rgb": "58,61,75"
            },
            {
                "nr": "PANTONE 19-3922 TPG",
                "name": "Sky Captain",
                "book": 2,
                "page": 33,
                "row": 6,
                "hexCode": "#393C47",
                "rgb": "56,59,71"
            },
            {
                "nr": "PANTONE 19-3713 TPG",
                "name": "Deep Well",
                "book": 2,
                "page": 33,
                "row": 7,
                "hexCode": "#3E3C47",
                "rgb": "62,60,71"
            }
        ],
        [
            {
                "nr": "PANTONE 19-4014 TPG",
                "name": "Ombre Blue",
                "book": 2,
                "page": 34,
                "row": 1,
                "hexCode": "#4C525C",
                "rgb": "75,81,92"
            },
            {
                "nr": "PANTONE 19-4019 TPG",
                "name": "India Ink",
                "book": 2,
                "page": 34,
                "row": 2,
                "hexCode": "#494F59",
                "rgb": "73,79,88"
            },
            {
                "nr": "PANTONE 19-4013 TPG",
                "name": "Dark Navy",
                "book": 2,
                "page": 34,
                "row": 3,
                "hexCode": "#414349",
                "rgb": "64,67,72"
            },
            {
                "nr": "PANTONE 19-4015 TPG",
                "name": "Blue Graphite",
                "book": 2,
                "page": 34,
                "row": 4,
                "hexCode": "#404347",
                "rgb": "64,66,70"
            },
            {
                "nr": "PANTONE 19-4011 TPG",
                "name": "Salute",
                "book": 2,
                "page": 34,
                "row": 5,
                "hexCode": "#3B3F49",
                "rgb": "59,62,73"
            },
            {
                "nr": "PANTONE 19-4218 TPG",
                "name": "Vulcan",
                "book": 2,
                "page": 34,
                "row": 6,
                "hexCode": "#3F4147",
                "rgb": "63,65,71"
            },
            {
                "nr": "PANTONE 19-3924 TPG",
                "name": "Night Sky",
                "book": 2,
                "page": 34,
                "row": 7,
                "hexCode": "#3B3C48",
                "rgb": "59,59,72"
            }
        ],
        [
            {
                "nr": "PANTONE 19-4908 TPG",
                "name": "Magical Forest",
                "book": 2,
                "page": 35,
                "row": 1,
                "hexCode": "#394448",
                "rgb": "57,68,72"
            },
            {
                "nr": "PANTONE 19-4112 TPG",
                "name": "Stratified Sea",
                "book": 2,
                "page": 35,
                "row": 2,
                "hexCode": "#3B4853",
                "rgb": "59,72,83"
            },
            {
                "nr": "PANTONE 19-4108 TPG",
                "name": "Sea Storm",
                "book": 2,
                "page": 35,
                "row": 3,
                "hexCode": "#414A53",
                "rgb": "65,74,83"
            },
            {
                "nr": "PANTONE 19-3944 TPG",
                "name": "Overture",
                "book": 2,
                "page": 35,
                "row": 4,
                "hexCode": "#3F4251",
                "rgb": "63,66,81"
            },
            {
                "nr": "PANTONE 19-4016 TPG",
                "name": "Inkwell",
                "book": 2,
                "page": 35,
                "row": 5,
                "hexCode": "#434752",
                "rgb": "67,71,82"
            },
            {
                "nr": "PANTONE 19-4051 TPG",
                "name": "Collegiate Blue",
                "book": 2,
                "page": 35,
                "row": 6,
                "hexCode": "#3F454C",
                "rgb": "63,69,76"
            },
            {
                "nr": "PANTONE 19-3916 TPG",
                "name": "Charcoal Art",
                "book": 2,
                "page": 35,
                "row": 7,
                "hexCode": "#424047",
                "rgb": "66,64,71"
            }
        ],
        [
            {
                "nr": "PANTONE 16-3916 TPG",
                "name": "Sleet",
                "book": 2,
                "page": 36,
                "row": 1,
                "hexCode": "#9C9EA4",
                "rgb": "156,157,164"
            },
            {
                "nr": "PANTONE 15-4307 TPG",
                "name": "Tradewinds",
                "book": 2,
                "page": 36,
                "row": 2,
                "hexCode": "#878F99",
                "rgb": "134,142,152"
            },
            {
                "nr": "PANTONE 18-4214 TPG",
                "name": "Stormy Weather",
                "book": 2,
                "page": 36,
                "row": 3,
                "hexCode": "#616E76",
                "rgb": "97,109,118"
            },
            {
                "nr": "PANTONE 19-4215 TPG",
                "name": "Turbulence",
                "book": 2,
                "page": 36,
                "row": 4,
                "hexCode": "#595F66",
                "rgb": "89,94,101"
            },
            {
                "nr": "PANTONE 19-4110 TPG",
                "name": "Midnight Navy",
                "book": 2,
                "page": 36,
                "row": 5,
                "hexCode": "#444E5A",
                "rgb": "67,78,89"
            },
            {
                "nr": "PANTONE 19-4021 TPG",
                "name": "Blueberry",
                "book": 2,
                "page": 36,
                "row": 6,
                "hexCode": "#3A414A",
                "rgb": "57,64,74"
            },
            {
                "nr": "PANTONE 19-4012 TPG",
                "name": "Carbon",
                "book": 2,
                "page": 36,
                "row": 7,
                "hexCode": "#3C3F48",
                "rgb": "59,63,72"
            }
        ],
        [
            {
                "nr": "PANTONE 17-4020 TPG",
                "name": "Blue Shadow",
                "book": 2,
                "page": 37,
                "row": 1,
                "hexCode": "#6C88A1",
                "rgb": "107,136,160"
            },
            {
                "nr": "PANTONE 19-4227 TPG",
                "name": "Indian Teal",
                "book": 2,
                "page": 37,
                "row": 2,
                "hexCode": "#475F6F",
                "rgb": "71,94,111"
            },
            {
                "nr": "PANTONE 19-4318 TPG",
                "name": "Mallard Blue",
                "book": 2,
                "page": 37,
                "row": 3,
                "hexCode": "#476577",
                "rgb": "71,100,118"
            },
            {
                "nr": "PANTONE 18-3918 TPG",
                "name": "China Blue",
                "book": 2,
                "page": 37,
                "row": 4,
                "hexCode": "#576A7D",
                "rgb": "87,106,125"
            },
            {
                "nr": "PANTONE 19-4229 TPG",
                "name": "Orion Blue",
                "book": 2,
                "page": 37,
                "row": 5,
                "hexCode": "#4C5B67",
                "rgb": "76,91,102"
            },
            {
                "nr": "PANTONE 19-4220 TPG",
                "name": "Dark Slate",
                "book": 2,
                "page": 37,
                "row": 6,
                "hexCode": "#4E5A61",
                "rgb": "78,89,97"
            },
            {
                "nr": "PANTONE 19-4316 TPG",
                "name": "Stargazer",
                "book": 2,
                "page": 37,
                "row": 7,
                "hexCode": "#445A65",
                "rgb": "68,89,100"
            }
        ],
        [
            {
                "nr": "PANTONE 17-4019 TPG",
                "name": "Mountain Spring",
                "book": 2,
                "page": 38,
                "row": 1,
                "hexCode": "#7C95A9",
                "rgb": "124,149,169"
            },
            {
                "nr": "PANTONE 17-4018 TPG",
                "name": "Windward Blue",
                "book": 2,
                "page": 38,
                "row": 2,
                "hexCode": "#738799",
                "rgb": "115,135,153"
            },
            {
                "nr": "PANTONE 18-4221 TPG",
                "name": "Spring Lake",
                "book": 2,
                "page": 38,
                "row": 3,
                "hexCode": "#698295",
                "rgb": "105,130,149"
            },
            {
                "nr": "PANTONE 18-4218 TPG",
                "name": "Blue Fusion",
                "book": 2,
                "page": 38,
                "row": 4,
                "hexCode": "#53687C",
                "rgb": "83,104,124"
            },
            {
                "nr": "PANTONE 18-3923 TPG",
                "name": "Oceanview",
                "book": 2,
                "page": 38,
                "row": 5,
                "hexCode": "#596F80",
                "rgb": "89,111,128"
            },
            {
                "nr": "PANTONE 19-4129 TPG",
                "name": "Key Largo",
                "book": 2,
                "page": 38,
                "row": 6,
                "hexCode": "#3F5165",
                "rgb": "63,81,101"
            },
            {
                "nr": "PANTONE 19-4128 TPG",
                "name": "Titan",
                "book": 2,
                "page": 38,
                "row": 7,
                "hexCode": "#35465A",
                "rgb": "53,70,90"
            }
        ],
        [
            {
                "nr": "PANTONE 18-4220 TPG",
                "name": "Provincial Blue",
                "book": 2,
                "page": 39,
                "row": 1,
                "hexCode": "#5F7E94",
                "rgb": "94,125,147"
            },
            {
                "nr": "PANTONE 18-4217 TPG",
                "name": "Bluestone",
                "book": 2,
                "page": 39,
                "row": 2,
                "hexCode": "#587587",
                "rgb": "88,116,134"
            },
            {
                "nr": "PANTONE 18-4320 TPG",
                "name": "Aegean Blue",
                "book": 2,
                "page": 39,
                "row": 3,
                "hexCode": "#567789",
                "rgb": "86,118,136"
            },
            {
                "nr": "PANTONE 18-3922 TPG",
                "name": "Coronet Blue",
                "book": 2,
                "page": 39,
                "row": 4,
                "hexCode": "#5A7490",
                "rgb": "90,116,144"
            },
            {
                "nr": "PANTONE 18-4020 TPG",
                "name": "Captain's Blue",
                "book": 2,
                "page": 39,
                "row": 5,
                "hexCode": "#5B7691",
                "rgb": "90,117,144"
            },
            {
                "nr": "PANTONE 18-4025 TPG",
                "name": "Copen Blue",
                "book": 2,
                "page": 39,
                "row": 6,
                "hexCode": "#587289",
                "rgb": "88,113,136"
            },
            {
                "nr": "PANTONE 18-4026 TPG",
                "name": "Stellar",
                "book": 2,
                "page": 39,
                "row": 7,
                "hexCode": "#46647D",
                "rgb": "70,99,125"
            }
        ],
        [
            {
                "nr": "PANTONE 18-4215 TPG",
                "name": "Blue Mirage",
                "book": 2,
                "page": 40,
                "row": 1,
                "hexCode": "#5C707E",
                "rgb": "91,111,125"
            },
            {
                "nr": "PANTONE 18-4028 TPG",
                "name": "Bering Sea",
                "book": 2,
                "page": 40,
                "row": 2,
                "hexCode": "#546375",
                "rgb": "84,98,116"
            },
            {
                "nr": "PANTONE 19-4126 TPG",
                "name": "Deep Dive",
                "book": 2,
                "page": 40,
                "row": 3,
                "hexCode": "#385265",
                "rgb": "55,82,100"
            },
            {
                "nr": "PANTONE 19-4038 TPG",
                "name": "Gibraltar Sea",
                "book": 2,
                "page": 40,
                "row": 4,
                "hexCode": "#30475E",
                "rgb": "48,70,94"
            },
            {
                "nr": "PANTONE 19-4120 TPG",
                "name": "Blue Opal",
                "book": 2,
                "page": 40,
                "row": 5,
                "hexCode": "#2E4762",
                "rgb": "45,70,97"
            },
            {
                "nr": "PANTONE 19-4034 TPG",
                "name": "Sailor Blue",
                "book": 2,
                "page": 40,
                "row": 6,
                "hexCode": "#2E4A62",
                "rgb": "46,74,97"
            },
            {
                "nr": "PANTONE 19-4125 TPG",
                "name": "Majolica Blue",
                "book": 2,
                "page": 40,
                "row": 7,
                "hexCode": "#3E4E5F",
                "rgb": "61,77,94"
            }
        ],
        [
            {
                "nr": "PANTONE 17-4023 TPG",
                "name": "Blue Heaven",
                "book": 2,
                "page": 41,
                "row": 1,
                "hexCode": "#5D7E98",
                "rgb": "93,126,151"
            },
            {
                "nr": "PANTONE 18-4222 TPG",
                "name": "Bluesteel",
                "book": 2,
                "page": 41,
                "row": 2,
                "hexCode": "#476C84",
                "rgb": "71,108,132"
            },
            {
                "nr": "PANTONE 18-4018 TPG",
                "name": "Real Teal",
                "book": 2,
                "page": 41,
                "row": 3,
                "hexCode": "#4B6479",
                "rgb": "75,99,120"
            },
            {
                "nr": "PANTONE 19-4127 TPG",
                "name": "Midnight",
                "book": 2,
                "page": 41,
                "row": 4,
                "hexCode": "#40627B",
                "rgb": "64,98,123"
            },
            {
                "nr": "PANTONE 18-4023 TPG",
                "name": "Blue Ashes",
                "book": 2,
                "page": 41,
                "row": 5,
                "hexCode": "#46657D",
                "rgb": "70,100,125"
            },
            {
                "nr": "PANTONE 19-4234 TPG",
                "name": "Ink Blue",
                "book": 2,
                "page": 41,
                "row": 6,
                "hexCode": "#2E5C73",
                "rgb": "46,91,115"
            },
            {
                "nr": "PANTONE 19-4241 TPG",
                "name": "Moroccan Blue",
                "book": 2,
                "page": 41,
                "row": 7,
                "hexCode": "#32566D",
                "rgb": "50,85,109"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4106 TPG",
                "name": "Gray Dawn",
                "book": 2,
                "page": 42,
                "row": 1,
                "hexCode": "#B7BECB",
                "rgb": "183,190,202"
            },
            {
                "nr": "PANTONE 14-4206 TPG",
                "name": "Pearl Blue",
                "book": 2,
                "page": 42,
                "row": 2,
                "hexCode": "#ACB9BF",
                "rgb": "172,185,190"
            },
            {
                "nr": "PANTONE 14-4210 TPG",
                "name": "Celestial Blue",
                "book": 2,
                "page": 42,
                "row": 3,
                "hexCode": "#A4B5C5",
                "rgb": "163,181,197"
            },
            {
                "nr": "PANTONE 15-4008 TPG",
                "name": "Blue Fog",
                "book": 2,
                "page": 42,
                "row": 4,
                "hexCode": "#97ABBD",
                "rgb": "151,171,188"
            },
            {
                "nr": "PANTONE 16-4010 TPG",
                "name": "Dusty Blue",
                "book": 2,
                "page": 42,
                "row": 5,
                "hexCode": "#889BAE",
                "rgb": "136,154,174"
            },
            {
                "nr": "PANTONE 16-4013 TPG",
                "name": "Ashley Blue",
                "book": 2,
                "page": 42,
                "row": 6,
                "hexCode": "#879AAC",
                "rgb": "135,154,171"
            },
            {
                "nr": "PANTONE 17-4021 TPG",
                "name": "Faded Denim",
                "book": 2,
                "page": 42,
                "row": 7,
                "hexCode": "#798FA8",
                "rgb": "121,143,167"
            }
        ],
        [
            {
                "nr": "PANTONE 12-4303 TPG",
                "name": "Country Air",
                "book": 2,
                "page": 43,
                "row": 1,
                "hexCode": "#D1DEEA",
                "rgb": "209,222,234"
            },
            {
                "nr": "PANTONE 12-4202 TPG",
                "name": "Delicate Blue",
                "book": 2,
                "page": 43,
                "row": 2,
                "hexCode": "#C7DFEC",
                "rgb": "199,223,236"
            },
            {
                "nr": "PANTONE 14-4215 TPG",
                "name": "Skyride",
                "book": 2,
                "page": 43,
                "row": 3,
                "hexCode": "#B4C7D7",
                "rgb": "180,199,215"
            },
            {
                "nr": "PANTONE 14-4316 TPG",
                "name": "Summer Song",
                "book": 2,
                "page": 43,
                "row": 4,
                "hexCode": "#A4C6DE",
                "rgb": "164,198,222"
            },
            {
                "nr": "PANTONE 14-4123 TPG",
                "name": "Clear Sky",
                "book": 2,
                "page": 43,
                "row": 5,
                "hexCode": "#A0C0DD",
                "rgb": "160,192,221"
            },
            {
                "nr": "PANTONE 14-4209 TPG",
                "name": "Subdued Blue",
                "book": 2,
                "page": 43,
                "row": 6,
                "hexCode": "#A7B6C8",
                "rgb": "167,182,200"
            },
            {
                "nr": "PANTONE 16-4118 TPG",
                "name": "Glacier Lake",
                "book": 2,
                "page": 43,
                "row": 7,
                "hexCode": "#84A3BC",
                "rgb": "132,163,188"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4111 TPG",
                "name": "Plein Air",
                "book": 2,
                "page": 44,
                "row": 1,
                "hexCode": "#C4CED6",
                "rgb": "196,205,214"
            },
            {
                "nr": "PANTONE 13-4200 TPG",
                "name": "Omphalodes",
                "book": 2,
                "page": 44,
                "row": 2,
                "hexCode": "#B3CBD9",
                "rgb": "179,202,217"
            },
            {
                "nr": "PANTONE 14-4317 TPG",
                "name": "Cool Blue",
                "book": 2,
                "page": 44,
                "row": 3,
                "hexCode": "#A5C7DB",
                "rgb": "165,198,218"
            },
            {
                "nr": "PANTONE 14-4313 TPG",
                "name": "Aquamarine",
                "book": 2,
                "page": 44,
                "row": 4,
                "hexCode": "#9CC3D5",
                "rgb": "155,195,212"
            },
            {
                "nr": "PANTONE 15-4005 TPG",
                "name": "Dream Blue",
                "book": 2,
                "page": 44,
                "row": 5,
                "hexCode": "#9EBDD0",
                "rgb": "158,188,208"
            },
            {
                "nr": "PANTONE 14-4318 TPG",
                "name": "Sky Blue",
                "book": 2,
                "page": 44,
                "row": 6,
                "hexCode": "#8DBCD4",
                "rgb": "140,188,211"
            },
            {
                "nr": "PANTONE 15-4319 TPG",
                "name": "Air Blue",
                "book": 2,
                "page": 44,
                "row": 7,
                "hexCode": "#7CAECA",
                "rgb": "123,174,201"
            }
        ],
        [
            {
                "nr": "PANTONE 15-4105 TPG",
                "name": "Angel Falls",
                "book": 2,
                "page": 45,
                "row": 1,
                "hexCode": "#9CBAD4",
                "rgb": "155,186,211"
            },
            {
                "nr": "PANTONE 16-4120 TPG",
                "name": "Dusk Blue",
                "book": 2,
                "page": 45,
                "row": 2,
                "hexCode": "#7DA0BD",
                "rgb": "125,160,188"
            },
            {
                "nr": "PANTONE 16-4127 TPG",
                "name": "Heritage Blue",
                "book": 2,
                "page": 45,
                "row": 3,
                "hexCode": "#679BBE",
                "rgb": "102,154,190"
            },
            {
                "nr": "PANTONE 17-4123 TPG",
                "name": "Niagara",
                "book": 2,
                "page": 45,
                "row": 4,
                "hexCode": "#5D8DAA",
                "rgb": "93,140,170"
            },
            {
                "nr": "PANTONE 17-4131 TPG",
                "name": "Cendre Blue",
                "book": 2,
                "page": 45,
                "row": 5,
                "hexCode": "#4882AA",
                "rgb": "71,130,170"
            },
            {
                "nr": "PANTONE 18-4232 TPG",
                "name": "Faience",
                "book": 2,
                "page": 45,
                "row": 6,
                "hexCode": "#3A6F90",
                "rgb": "57,110,144"
            },
            {
                "nr": "PANTONE 18-4231 TPG",
                "name": "Blue Sapphire",
                "book": 2,
                "page": 45,
                "row": 7,
                "hexCode": "#2E6784",
                "rgb": "46,102,132"
            }
        ],
        [
            {
                "nr": "PANTONE 17-4336 TPG",
                "name": "Blithe",
                "book": 2,
                "page": 46,
                "row": 1,
                "hexCode": "#2888BE",
                "rgb": "39,136,190"
            },
            {
                "nr": "PANTONE 17-4433 TPG",
                "name": "Dresden Blue",
                "book": 2,
                "page": 46,
                "row": 2,
                "hexCode": "#0089BF",
                "rgb": "0,137,191"
            },
            {
                "nr": "PANTONE 18-4334 TPG",
                "name": "Mediterranian Blue",
                "book": 2,
                "page": 46,
                "row": 3,
                "hexCode": "#2E7BA8",
                "rgb": "45,122,167"
            },
            {
                "nr": "PANTONE 18-4330 TPG",
                "name": "Swedish Blue",
                "book": 2,
                "page": 46,
                "row": 4,
                "hexCode": "#2982B1",
                "rgb": "40,129,176"
            },
            {
                "nr": "PANTONE 18-4440 TPG",
                "name": "Cloisonné",
                "book": 2,
                "page": 46,
                "row": 5,
                "hexCode": "#0080B8",
                "rgb": "0,128,184"
            },
            {
                "nr": "PANTONE 17-4247 TPG",
                "name": "Diva Blue",
                "book": 2,
                "page": 46,
                "row": 6,
                "hexCode": "#0084BF",
                "rgb": "0,132,191"
            },
            {
                "nr": "PANTONE 18-4434 TPG",
                "name": "Mykonos Blue",
                "book": 2,
                "page": 46,
                "row": 7,
                "hexCode": "#045E87",
                "rgb": "4,94,135"
            }
        ],
        [
            {
                "nr": "PANTONE 15-4427 TPG",
                "name": "Norse Blue",
                "book": 2,
                "page": 47,
                "row": 1,
                "hexCode": "#56A8CB",
                "rgb": "85,167,202"
            },
            {
                "nr": "PANTONE 17-4540 TPG",
                "name": "Hawaiian Ocean",
                "book": 2,
                "page": 47,
                "row": 2,
                "hexCode": "#009AC9",
                "rgb": "0,154,201"
            },
            {
                "nr": "PANTONE 17-4432 TPG",
                "name": "Vivid Blue",
                "book": 2,
                "page": 47,
                "row": 3,
                "hexCode": "#008BB5",
                "rgb": "0,139,181"
            },
            {
                "nr": "PANTONE 17-4440 TPG",
                "name": "Blue Danube",
                "book": 2,
                "page": 47,
                "row": 4,
                "hexCode": "#008AB6",
                "rgb": "0,138,182"
            },
            {
                "nr": "PANTONE 18-4535 TPG",
                "name": "Blue Jewel",
                "book": 2,
                "page": 47,
                "row": 5,
                "hexCode": "#0085B5",
                "rgb": "0,133,181"
            },
            {
                "nr": "PANTONE 18-4538 TPG",
                "name": "Hawaiian Surf",
                "book": 2,
                "page": 47,
                "row": 6,
                "hexCode": "#007EAE",
                "rgb": "0,126,174"
            },
            {
                "nr": "PANTONE 18-4537 TPG",
                "name": "Methyl Blue",
                "book": 2,
                "page": 47,
                "row": 7,
                "hexCode": "#007CAD",
                "rgb": "0,124,173"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4514 TPG",
                "name": "Atomizer",
                "book": 2,
                "page": 48,
                "row": 1,
                "hexCode": "#9AD7EC",
                "rgb": "154,215,236"
            },
            {
                "nr": "PANTONE 16-4520 TPG",
                "name": "Splish Splash",
                "book": 2,
                "page": 48,
                "row": 2,
                "hexCode": "#68BEE0",
                "rgb": "104,190,224"
            },
            {
                "nr": "PANTONE 15-4421 TPG",
                "name": "Blue Grotto",
                "book": 2,
                "page": 48,
                "row": 3,
                "hexCode": "#63AED1",
                "rgb": "99,173,208"
            },
            {
                "nr": "PANTONE 15-4428 TPG",
                "name": "Crystal Seas",
                "book": 2,
                "page": 48,
                "row": 4,
                "hexCode": "#60B1D3",
                "rgb": "96,177,210"
            },
            {
                "nr": "PANTONE 16-4526 TPG",
                "name": "Swim Cap",
                "book": 2,
                "page": 48,
                "row": 5,
                "hexCode": "#41A9D4",
                "rgb": "65,169,212"
            },
            {
                "nr": "PANTONE 16-4530 TPG",
                "name": "Aquarius",
                "book": 2,
                "page": 48,
                "row": 6,
                "hexCode": "#42ADD5",
                "rgb": "65,173,212"
            },
            {
                "nr": "PANTONE 16-4529 TPG",
                "name": "Cyan Blue",
                "book": 2,
                "page": 48,
                "row": 7,
                "hexCode": "#23A3C7",
                "rgb": "35,162,199"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4307 TPG",
                "name": "Tropical Breeze",
                "book": 2,
                "page": 49,
                "row": 1,
                "hexCode": "#89CFE7",
                "rgb": "137,207,231"
            },
            {
                "nr": "PANTONE 14-4310 TPG",
                "name": "Blue Topaz",
                "book": 2,
                "page": 49,
                "row": 2,
                "hexCode": "#7BBED4",
                "rgb": "122,189,212"
            },
            {
                "nr": "PANTONE 14-4522 TPG",
                "name": "Bachelor Button",
                "book": 2,
                "page": 49,
                "row": 3,
                "hexCode": "#4CB9D6",
                "rgb": "76,185,214"
            },
            {
                "nr": "PANTONE 15-4720 TPG",
                "name": "River Blue",
                "book": 2,
                "page": 49,
                "row": 4,
                "hexCode": "#44B0CD",
                "rgb": "68,176,205"
            },
            {
                "nr": "PANTONE 16-4535 TPG",
                "name": "Blue Atoll",
                "book": 2,
                "page": 49,
                "row": 5,
                "hexCode": "#00B5D6",
                "rgb": "0,181,214"
            },
            {
                "nr": "PANTONE 16-4427 TPG",
                "name": "Horizon Blue",
                "book": 2,
                "page": 49,
                "row": 6,
                "hexCode": "#37A1C2",
                "rgb": "54,160,193"
            },
            {
                "nr": "PANTONE 17-4427 TPG",
                "name": "Bluejay",
                "book": 2,
                "page": 49,
                "row": 7,
                "hexCode": "#3085A8",
                "rgb": "48,133,167"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4410 TPG",
                "name": "Cooling Spray",
                "book": 2,
                "page": 50,
                "row": 1,
                "hexCode": "#C7E3EE",
                "rgb": "199,227,238"
            },
            {
                "nr": "PANTONE 13-4401 TPG",
                "name": "Quiet Tide",
                "book": 2,
                "page": 50,
                "row": 2,
                "hexCode": "#C3E5F1",
                "rgb": "195,229,241"
            },
            {
                "nr": "PANTONE 12-4401 TPG",
                "name": "Spun Sugar",
                "book": 2,
                "page": 50,
                "row": 3,
                "hexCode": "#B8DFEC",
                "rgb": "184,223,236"
            },
            {
                "nr": "PANTONE 14-4311 TPG",
                "name": "Corydalis Blue",
                "book": 2,
                "page": 50,
                "row": 4,
                "hexCode": "#ADCFDF",
                "rgb": "172,206,222"
            },
            {
                "nr": "PANTONE 13-4411 TPG",
                "name": "Crystal Blue",
                "book": 2,
                "page": 50,
                "row": 5,
                "hexCode": "#A0C9DB",
                "rgb": "159,201,218"
            },
            {
                "nr": "PANTONE 14-4516 TPG",
                "name": "Petit Four",
                "book": 2,
                "page": 50,
                "row": 6,
                "hexCode": "#86C3D5",
                "rgb": "133,195,213"
            },
            {
                "nr": "PANTONE 16-4421 TPG",
                "name": "Blue Mist",
                "book": 2,
                "page": 50,
                "row": 7,
                "hexCode": "#5DADC7",
                "rgb": "92,173,199"
            }
        ],
        [
            {
                "nr": "PANTONE 18-4430 TPG",
                "name": "Fjord Blue",
                "book": 2,
                "page": 51,
                "row": 1,
                "hexCode": "#007393",
                "rgb": "0,115,147"
            },
            {
                "nr": "PANTONE 18-4528 TPG",
                "name": "Mosaic Blue",
                "book": 2,
                "page": 51,
                "row": 2,
                "hexCode": "#117893",
                "rgb": "17,120,147"
            },
            {
                "nr": "PANTONE 18-4432 TPG",
                "name": "Turkish Tile",
                "book": 2,
                "page": 51,
                "row": 3,
                "hexCode": "#046D8E",
                "rgb": "4,109,142"
            },
            {
                "nr": "PANTONE 18-4530 TPG",
                "name": "Celestial",
                "book": 2,
                "page": 51,
                "row": 4,
                "hexCode": "#2B6E8D",
                "rgb": "42,110,140"
            },
            {
                "nr": "PANTONE 18-4225 TPG",
                "name": "Saxony Blue",
                "book": 2,
                "page": 51,
                "row": 5,
                "hexCode": "#3C6B82",
                "rgb": "60,106,129"
            },
            {
                "nr": "PANTONE 19-4342 TPG",
                "name": "Seaport",
                "book": 2,
                "page": 51,
                "row": 6,
                "hexCode": "#276384",
                "rgb": "38,98,131"
            },
            {
                "nr": "PANTONE 19-4329 TPG",
                "name": "Corsair",
                "book": 2,
                "page": 51,
                "row": 7,
                "hexCode": "#2D5E74",
                "rgb": "44,94,115"
            }
        ],
        [
            {
                "nr": "PANTONE 17-4328 TPG",
                "name": "Blue Moon",
                "book": 2,
                "page": 52,
                "row": 1,
                "hexCode": "#4087A6",
                "rgb": "63,135,166"
            },
            {
                "nr": "PANTONE 17-4429 TPG",
                "name": "Navagio Bay",
                "book": 2,
                "page": 52,
                "row": 2,
                "hexCode": "#3F86A3",
                "rgb": "62,133,163"
            },
            {
                "nr": "PANTONE 18-4630 TPG",
                "name": "Tahitian Tide",
                "book": 2,
                "page": 52,
                "row": 3,
                "hexCode": "#007186",
                "rgb": "0,113,134"
            },
            {
                "nr": "PANTONE 19-4536 TPG",
                "name": "Crystal Teal",
                "book": 2,
                "page": 52,
                "row": 4,
                "hexCode": "#006882",
                "rgb": "0,104,130"
            },
            {
                "nr": "PANTONE 19-4540 TPG",
                "name": "Deep Lagoon",
                "book": 2,
                "page": 52,
                "row": 5,
                "hexCode": "#185E6F",
                "rgb": "24,93,110"
            },
            {
                "nr": "PANTONE 19-4535 TPG",
                "name": "Ocean Depths",
                "book": 2,
                "page": 52,
                "row": 6,
                "hexCode": "#06697D",
                "rgb": "6,105,125"
            },
            {
                "nr": "PANTONE 19-4340 TPG",
                "name": "Lyons Blue",
                "book": 2,
                "page": 52,
                "row": 7,
                "hexCode": "#28627C",
                "rgb": "40,98,124"
            }
        ],
        [
            {
                "nr": "PANTONE 16-4525 TPG",
                "name": "Maui Blue",
                "book": 2,
                "page": 53,
                "row": 1,
                "hexCode": "#55A4B9",
                "rgb": "85,163,184"
            },
            {
                "nr": "PANTONE 17-4728 TPG",
                "name": "Algiers Blue",
                "book": 2,
                "page": 53,
                "row": 2,
                "hexCode": "#00869E",
                "rgb": "0,134,158"
            },
            {
                "nr": "PANTONE 17-4530 TPG",
                "name": "Barrier Reef",
                "book": 2,
                "page": 53,
                "row": 3,
                "hexCode": "#0088A5",
                "rgb": "0,136,165"
            },
            {
                "nr": "PANTONE 17-4730 TPG",
                "name": "Caneel Bay",
                "book": 2,
                "page": 53,
                "row": 4,
                "hexCode": "#0086A2",
                "rgb": "0,134,162"
            },
            {
                "nr": "PANTONE 18-4525 TPG",
                "name": "Caribbean Sea",
                "book": 2,
                "page": 53,
                "row": 5,
                "hexCode": "#0085A2",
                "rgb": "0,133,162"
            },
            {
                "nr": "PANTONE 17-4735 TPG",
                "name": "Capri Breeze",
                "book": 2,
                "page": 53,
                "row": 6,
                "hexCode": "#008AA0",
                "rgb": "0,138,160"
            },
            {
                "nr": "PANTONE 18-4733 TPG",
                "name": "Enamel Blue",
                "book": 2,
                "page": 53,
                "row": 7,
                "hexCode": "#007E94",
                "rgb": "0,126,148"
            }
        ],
        [
            {
                "nr": "PANTONE 12-4608 TPG",
                "name": "Clearwater",
                "book": 2,
                "page": 54,
                "row": 1,
                "hexCode": "#ABD6DF",
                "rgb": "170,214,223"
            },
            {
                "nr": "PANTONE 14-4816 TPG",
                "name": "Blue Radiance",
                "book": 2,
                "page": 54,
                "row": 2,
                "hexCode": "#64C8D3",
                "rgb": "99,199,210"
            },
            {
                "nr": "PANTONE 15-4722 TPG",
                "name": "Capri",
                "book": 2,
                "page": 54,
                "row": 3,
                "hexCode": "#49BBCA",
                "rgb": "72,186,201"
            },
            {
                "nr": "PANTONE 15-4825 TPG",
                "name": "Blue Curacao",
                "book": 2,
                "page": 54,
                "row": 4,
                "hexCode": "#36BAC6",
                "rgb": "53,185,198"
            },
            {
                "nr": "PANTONE 16-4725 TPG",
                "name": "Scuba Blue",
                "book": 2,
                "page": 54,
                "row": 5,
                "hexCode": "#00AEC5",
                "rgb": "0,174,197"
            },
            {
                "nr": "PANTONE 16-4834 TPG",
                "name": "Bluebird",
                "book": 2,
                "page": 54,
                "row": 6,
                "hexCode": "#00A1B3",
                "rgb": "0,161,179"
            },
            {
                "nr": "PANTONE 16-4728 TPG",
                "name": "Peacock Blue",
                "book": 2,
                "page": 54,
                "row": 7,
                "hexCode": "#00A3B6",
                "rgb": "0,163,182"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4409 TPG",
                "name": "Blue Glow",
                "book": 2,
                "page": 55,
                "row": 1,
                "hexCode": "#ABD2DC",
                "rgb": "170,210,220"
            },
            {
                "nr": "PANTONE 14-4510 TPG",
                "name": "Aquatic",
                "book": 2,
                "page": 55,
                "row": 2,
                "hexCode": "#97C0CC",
                "rgb": "150,192,204"
            },
            {
                "nr": "PANTONE 14-4315 TPG",
                "name": "Sea Angel",
                "book": 2,
                "page": 55,
                "row": 3,
                "hexCode": "#9AC1CD",
                "rgb": "153,192,205"
            },
            {
                "nr": "PANTONE 13-5410 TPG",
                "name": "Iced Aqua",
                "book": 2,
                "page": 55,
                "row": 4,
                "hexCode": "#AED6E0",
                "rgb": "174,214,223"
            },
            {
                "nr": "PANTONE 14-4508 TPG",
                "name": "Stratosphere",
                "book": 2,
                "page": 55,
                "row": 5,
                "hexCode": "#9ABFCB",
                "rgb": "153,190,202"
            },
            {
                "nr": "PANTONE 14-4512 TPG",
                "name": "Porcelain Blue",
                "book": 2,
                "page": 55,
                "row": 6,
                "hexCode": "#90C0CC",
                "rgb": "143,191,203"
            },
            {
                "nr": "PANTONE 15-4415 TPG",
                "name": "Milky Blue",
                "book": 2,
                "page": 55,
                "row": 7,
                "hexCode": "#74A9BD",
                "rgb": "115,169,189"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4304 TPG",
                "name": "Ballad Blue",
                "book": 2,
                "page": 56,
                "row": 1,
                "hexCode": "#BCCCDA",
                "rgb": "187,204,217"
            },
            {
                "nr": "PANTONE 13-4308 TPG",
                "name": "Baby Blue",
                "book": 2,
                "page": 56,
                "row": 2,
                "hexCode": "#B3C7D6",
                "rgb": "179,199,213"
            },
            {
                "nr": "PANTONE 15-4312 TPG",
                "name": "Forget-Me-Not",
                "book": 2,
                "page": 56,
                "row": 3,
                "hexCode": "#8EADBF",
                "rgb": "142,173,191"
            },
            {
                "nr": "PANTONE 16-4519 TPG",
                "name": "Delphinium Blue",
                "book": 2,
                "page": 56,
                "row": 4,
                "hexCode": "#669DB3",
                "rgb": "101,157,178"
            },
            {
                "nr": "PANTONE 17-4320 TPG",
                "name": "Adriatic Blue",
                "book": 2,
                "page": 56,
                "row": 5,
                "hexCode": "#638D9F",
                "rgb": "98,140,158"
            },
            {
                "nr": "PANTONE 17-4421 TPG",
                "name": "Larkspur",
                "book": 2,
                "page": 56,
                "row": 6,
                "hexCode": "#427C93",
                "rgb": "65,124,146"
            },
            {
                "nr": "PANTONE 17-4716 TPG",
                "name": "Storm Blue",
                "book": 2,
                "page": 56,
                "row": 7,
                "hexCode": "#4C7D8E",
                "rgb": "76,125,142"
            }
        ],
        [
            {
                "nr": "PANTONE 16-4109 TPG",
                "name": "Arona",
                "book": 2,
                "page": 57,
                "row": 1,
                "hexCode": "#859BA6",
                "rgb": "133,154,166"
            },
            {
                "nr": "PANTONE 17-4412 TPG",
                "name": "Smoke Blue",
                "book": 2,
                "page": 57,
                "row": 2,
                "hexCode": "#6A8B95",
                "rgb": "106,139,148"
            },
            {
                "nr": "PANTONE 17-4111 TPG",
                "name": "Citadel",
                "book": 2,
                "page": 57,
                "row": 3,
                "hexCode": "#758A98",
                "rgb": "117,138,152"
            },
            {
                "nr": "PANTONE 18-4417 TPG",
                "name": "Tapestry",
                "book": 2,
                "page": 57,
                "row": 4,
                "hexCode": "#4A6A78",
                "rgb": "73,105,120"
            },
            {
                "nr": "PANTONE 19-4526 TPG",
                "name": "Blue Coral",
                "book": 2,
                "page": 57,
                "row": 5,
                "hexCode": "#2D5C6C",
                "rgb": "45,91,108"
            },
            {
                "nr": "PANTONE 19-4324 TPG",
                "name": "Legion Blue",
                "book": 2,
                "page": 57,
                "row": 6,
                "hexCode": "#385466",
                "rgb": "56,84,101"
            },
            {
                "nr": "PANTONE 19-4326 TPG",
                "name": "Reflecting Pond",
                "book": 2,
                "page": 57,
                "row": 7,
                "hexCode": "#354957",
                "rgb": "52,72,86"
            }
        ],
        [
            {
                "nr": "PANTONE 13-4404 TPG",
                "name": "Ice Flow",
                "book": 2,
                "page": 58,
                "row": 1,
                "hexCode": "#C7D3D4",
                "rgb": "199,210,212"
            },
            {
                "nr": "PANTONE 13-4103 TPG",
                "name": "Illusion Blue",
                "book": 2,
                "page": 58,
                "row": 2,
                "hexCode": "#C9D6DD",
                "rgb": "201,214,220"
            },
            {
                "nr": "PANTONE 14-4306 TPG",
                "name": "Cloud Blue",
                "book": 2,
                "page": 58,
                "row": 3,
                "hexCode": "#9DB5B9",
                "rgb": "157,181,185"
            },
            {
                "nr": "PANTONE 14-4506 TPG",
                "name": "Ether",
                "book": 2,
                "page": 58,
                "row": 4,
                "hexCode": "#A2B9BC",
                "rgb": "161,184,188"
            },
            {
                "nr": "PANTONE 16-4411 TPG",
                "name": "Tourmaline",
                "book": 2,
                "page": 58,
                "row": 5,
                "hexCode": "#86A3AC",
                "rgb": "134,162,172"
            },
            {
                "nr": "PANTONE 16-4114 TPG",
                "name": "Stone Blue",
                "book": 2,
                "page": 58,
                "row": 6,
                "hexCode": "#809CA7",
                "rgb": "127,156,167"
            },
            {
                "nr": "PANTONE 16-4414 TPG",
                "name": "Cameo Blue",
                "book": 2,
                "page": 58,
                "row": 7,
                "hexCode": "#78A0AB",
                "rgb": "120,159,170"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4307 TPG",
                "name": "Winter Sky",
                "book": 2,
                "page": 59,
                "row": 1,
                "hexCode": "#A5BDCA",
                "rgb": "164,188,202"
            },
            {
                "nr": "PANTONE 15-4309 TPG",
                "name": "Sterling Blue",
                "book": 2,
                "page": 59,
                "row": 2,
                "hexCode": "#9DB9C3",
                "rgb": "157,184,195"
            },
            {
                "nr": "PANTONE 16-4612 TPG",
                "name": "Reef Waters",
                "book": 2,
                "page": 59,
                "row": 3,
                "hexCode": "#6C9EAA",
                "rgb": "107,158,170"
            },
            {
                "nr": "PANTONE 16-4610 TPG",
                "name": "Stillwater",
                "book": 2,
                "page": 59,
                "row": 4,
                "hexCode": "#6DA2AF",
                "rgb": "109,162,174"
            },
            {
                "nr": "PANTONE 15-4717 TPG",
                "name": "Aqua",
                "book": 2,
                "page": 59,
                "row": 5,
                "hexCode": "#69A1B0",
                "rgb": "104,161,175"
            },
            {
                "nr": "PANTONE 16-5121 TPG",
                "name": "Meadowbrook",
                "book": 2,
                "page": 59,
                "row": 6,
                "hexCode": "#5A9AA1",
                "rgb": "90,154,160"
            },
            {
                "nr": "PANTONE 16-4719 TPG",
                "name": "Porcelain",
                "book": 2,
                "page": 59,
                "row": 7,
                "hexCode": "#5A9BA3",
                "rgb": "89,154,163"
            }
        ],
        [
            {
                "nr": "PANTONE 12-4609 TPG",
                "name": "Starlight Blue",
                "book": 2,
                "page": 60,
                "row": 1,
                "hexCode": "#B4CFD9",
                "rgb": "179,206,217"
            },
            {
                "nr": "PANTONE 13-4809 TPG",
                "name": "Plume",
                "book": 2,
                "page": 60,
                "row": 2,
                "hexCode": "#A4D1D9",
                "rgb": "163,209,217"
            },
            {
                "nr": "PANTONE 15-4712 TPG",
                "name": "Marine Blue",
                "book": 2,
                "page": 60,
                "row": 3,
                "hexCode": "#6FACB6",
                "rgb": "110,172,181"
            },
            {
                "nr": "PANTONE 15-4714 TPG",
                "name": "Aquarelle",
                "book": 2,
                "page": 60,
                "row": 4,
                "hexCode": "#66ACB5",
                "rgb": "102,172,181"
            },
            {
                "nr": "PANTONE 15-4715 TPG",
                "name": "Aqua Sea",
                "book": 2,
                "page": 60,
                "row": 5,
                "hexCode": "#6CAEB4",
                "rgb": "108,173,179"
            },
            {
                "nr": "PANTONE 15-5209 TPG",
                "name": "Aqua Haze",
                "book": 2,
                "page": 60,
                "row": 6,
                "hexCode": "#84B7BB",
                "rgb": "131,183,186"
            },
            {
                "nr": "PANTONE 15-5210 TPG",
                "name": "Nile Blue",
                "book": 2,
                "page": 60,
                "row": 7,
                "hexCode": "#7AA7AC",
                "rgb": "121,167,172"
            }
        ],
        [
            {
                "nr": "PANTONE 12-4611 TPG",
                "name": "Saltwater Slide",
                "book": 2,
                "page": 61,
                "row": 1,
                "hexCode": "#CEE8EF",
                "rgb": "206,232,239"
            },
            {
                "nr": "PANTONE 13-5411 TPG",
                "name": "Aqua-esque",
                "book": 2,
                "page": 61,
                "row": 2,
                "hexCode": "#A6CFD9",
                "rgb": "166,207,217"
            },
            {
                "nr": "PANTONE 14-4515 TPG",
                "name": "Blue Elixir",
                "book": 2,
                "page": 61,
                "row": 3,
                "hexCode": "#8CCDDF",
                "rgb": "140,205,223"
            },
            {
                "nr": "PANTONE 14-4513 TPG",
                "name": "Cabana Blue",
                "book": 2,
                "page": 61,
                "row": 4,
                "hexCode": "#7BB8CB",
                "rgb": "123,184,203"
            },
            {
                "nr": "PANTONE 14-4511 TPG",
                "name": "Gulf Stream",
                "book": 2,
                "page": 61,
                "row": 5,
                "hexCode": "#8DC6D4",
                "rgb": "140,197,212"
            },
            {
                "nr": "PANTONE 15-4710 TPG",
                "name": "Barbados Beach",
                "book": 2,
                "page": 61,
                "row": 6,
                "hexCode": "#64B6C3",
                "rgb": "100,182,195"
            },
            {
                "nr": "PANTONE 15-4716 TPG",
                "name": "Ipanema",
                "book": 2,
                "page": 61,
                "row": 7,
                "hexCode": "#67BDC5",
                "rgb": "103,189,197"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4620 TPG",
                "name": "Island Paradise",
                "book": 2,
                "page": 62,
                "row": 1,
                "hexCode": "#95DEE2",
                "rgb": "148,221,225"
            },
            {
                "nr": "PANTONE 13-4720 TPG",
                "name": "Tanager Turquoise",
                "book": 2,
                "page": 62,
                "row": 2,
                "hexCode": "#95DBE5",
                "rgb": "149,219,228"
            },
            {
                "nr": "PANTONE 14-4812 TPG",
                "name": "Aqua Splash",
                "book": 2,
                "page": 62,
                "row": 3,
                "hexCode": "#85D0D1",
                "rgb": "133,208,209"
            },
            {
                "nr": "PANTONE 13-4810 TPG",
                "name": "Limpet Shell",
                "book": 2,
                "page": 62,
                "row": 4,
                "hexCode": "#99DEDF",
                "rgb": "153,222,223"
            },
            {
                "nr": "PANTONE 14-4710 TPG",
                "name": "Tibetan Stone",
                "book": 2,
                "page": 62,
                "row": 5,
                "hexCode": "#83C5CA",
                "rgb": "131,197,201"
            },
            {
                "nr": "PANTONE 14-4814 TPG",
                "name": "Angel Blue",
                "book": 2,
                "page": 62,
                "row": 6,
                "hexCode": "#83C6CF",
                "rgb": "130,197,207"
            },
            {
                "nr": "PANTONE 14-4615 TPG",
                "name": "Antigua Sand",
                "book": 2,
                "page": 62,
                "row": 7,
                "hexCode": "#83C4CF",
                "rgb": "130,196,206"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4618 TPG",
                "name": "Waterspout",
                "book": 2,
                "page": 63,
                "row": 1,
                "hexCode": "#94DBE0",
                "rgb": "148,219,224"
            },
            {
                "nr": "PANTONE 14-4818 TPG",
                "name": "Amazonite",
                "book": 2,
                "page": 63,
                "row": 2,
                "hexCode": "#78BBBE",
                "rgb": "120,187,190"
            },
            {
                "nr": "PANTONE 14-4815 TPG",
                "name": "Leisure Time",
                "book": 2,
                "page": 63,
                "row": 3,
                "hexCode": "#80C0C5",
                "rgb": "128,192,197"
            },
            {
                "nr": "PANTONE 15-4711 TPG",
                "name": "Coastal Shade",
                "book": 2,
                "page": 63,
                "row": 4,
                "hexCode": "#6CA9B1",
                "rgb": "108,169,177"
            },
            {
                "nr": "PANTONE 15-4713 TPG",
                "name": "Sea Jet",
                "book": 2,
                "page": 63,
                "row": 5,
                "hexCode": "#56B0BB",
                "rgb": "86,176,187"
            },
            {
                "nr": "PANTONE 17-4725 TPG",
                "name": "Tahitian Teal",
                "book": 2,
                "page": 63,
                "row": 6,
                "hexCode": "#00899C",
                "rgb": "0,137,156"
            },
            {
                "nr": "PANTONE 18-4631 TPG",
                "name": "Exotic Plume",
                "book": 2,
                "page": 63,
                "row": 7,
                "hexCode": "#007384",
                "rgb": "0,115,132"
            }
        ],
        [
            {
                "nr": "PANTONE 17-4928 TPG",
                "name": "Lake Blue",
                "book": 2,
                "page": 64,
                "row": 1,
                "hexCode": "#008D9A",
                "rgb": "0,141,154"
            },
            {
                "nr": "PANTONE 18-4735 TPG",
                "name": "Tile Blue",
                "book": 2,
                "page": 64,
                "row": 2,
                "hexCode": "#008D9A",
                "rgb": "0,141,154"
            },
            {
                "nr": "PANTONE 18-4726 TPG",
                "name": "Biscay Bay",
                "book": 2,
                "page": 64,
                "row": 3,
                "hexCode": "#257A88",
                "rgb": "37,122,136"
            },
            {
                "nr": "PANTONE 17-4724 TPG",
                "name": "Pagoda Blue",
                "book": 2,
                "page": 64,
                "row": 4,
                "hexCode": "#2F8293",
                "rgb": "47,130,146"
            },
            {
                "nr": "PANTONE 18-4728 TPG",
                "name": "Harbor Blue",
                "book": 2,
                "page": 64,
                "row": 5,
                "hexCode": "#16737E",
                "rgb": "22,114,126"
            },
            {
                "nr": "PANTONE 19-4524 TPG",
                "name": "Shaded Spruce",
                "book": 2,
                "page": 64,
                "row": 6,
                "hexCode": "#286168",
                "rgb": "40,96,104"
            },
            {
                "nr": "PANTONE 19-4914 TPG",
                "name": "Deep Teal",
                "book": 2,
                "page": 64,
                "row": 7,
                "hexCode": "#30535A",
                "rgb": "47,82,89"
            }
        ],
        [
            {
                "nr": "PANTONE 18-5610 TPG",
                "name": "Brittany Blue",
                "book": 2,
                "page": 65,
                "row": 1,
                "hexCode": "#55828A",
                "rgb": "84,129,138"
            },
            {
                "nr": "PANTONE 18-4718 TPG",
                "name": "Hydro",
                "book": 2,
                "page": 65,
                "row": 2,
                "hexCode": "#496C77",
                "rgb": "72,108,119"
            },
            {
                "nr": "PANTONE 19-4826 TPG",
                "name": "Dragonfly",
                "book": 2,
                "page": 65,
                "row": 3,
                "hexCode": "#366470",
                "rgb": "54,99,111"
            },
            {
                "nr": "PANTONE 18-4522 TPG",
                "name": "Colonial Blue",
                "book": 2,
                "page": 65,
                "row": 4,
                "hexCode": "#396A78",
                "rgb": "57,105,119"
            },
            {
                "nr": "PANTONE 19-4820 TPG",
                "name": "Balsam",
                "book": 2,
                "page": 65,
                "row": 5,
                "hexCode": "#436066",
                "rgb": "67,95,102"
            },
            {
                "nr": "PANTONE 19-4517 TPG",
                "name": "Mediterranea",
                "book": 2,
                "page": 65,
                "row": 6,
                "hexCode": "#41606D",
                "rgb": "65,96,108"
            },
            {
                "nr": "PANTONE 19-4726 TPG",
                "name": "Atlantic Deep",
                "book": 2,
                "page": 65,
                "row": 7,
                "hexCode": "#3E5A64",
                "rgb": "62,90,100"
            }
        ],
        [
            {
                "nr": "PANTONE 12-4604 TPG",
                "name": "Skylight",
                "book": 2,
                "page": 66,
                "row": 1,
                "hexCode": "#CEE5E3",
                "rgb": "206,228,226"
            },
            {
                "nr": "PANTONE 12-4607 TPG",
                "name": "Pastel Blue",
                "book": 2,
                "page": 66,
                "row": 2,
                "hexCode": "#BDD6D9",
                "rgb": "189,214,216"
            },
            {
                "nr": "PANTONE 14-4810 TPG",
                "name": "Canal Blue",
                "book": 2,
                "page": 66,
                "row": 3,
                "hexCode": "#9AC1C7",
                "rgb": "153,192,199"
            },
            {
                "nr": "PANTONE 16-5114 TPG",
                "name": "Dusty Turquoise",
                "book": 2,
                "page": 66,
                "row": 4,
                "hexCode": "#67A0A3",
                "rgb": "103,160,162"
            },
            {
                "nr": "PANTONE 17-4818 TPG",
                "name": "Bristol Blue",
                "book": 2,
                "page": 66,
                "row": 5,
                "hexCode": "#569195",
                "rgb": "86,144,148"
            },
            {
                "nr": "PANTONE 17-5117 TPG",
                "name": "Green-Blue Slate",
                "book": 2,
                "page": 66,
                "row": 6,
                "hexCode": "#408388",
                "rgb": "64,131,135"
            },
            {
                "nr": "PANTONE 17-4919 TPG",
                "name": "Teal",
                "book": 2,
                "page": 66,
                "row": 7,
                "hexCode": "#4B878B",
                "rgb": "75,134,139"
            }
        ],
        [
            {
                "nr": "PANTONE 13-5306 TPG",
                "name": "Icy Morn",
                "book": 2,
                "page": 67,
                "row": 1,
                "hexCode": "#ADD2D0",
                "rgb": "173,210,208"
            },
            {
                "nr": "PANTONE 13-4909 TPG",
                "name": "Blue Light",
                "book": 2,
                "page": 67,
                "row": 2,
                "hexCode": "#A7DDDB",
                "rgb": "166,220,218"
            },
            {
                "nr": "PANTONE 13-4910 TPG",
                "name": "Blue Tint",
                "book": 2,
                "page": 67,
                "row": 3,
                "hexCode": "#9EDADA",
                "rgb": "158,218,218"
            },
            {
                "nr": "PANTONE 13-5313 TPG",
                "name": "Aruba Blue",
                "book": 2,
                "page": 67,
                "row": 4,
                "hexCode": "#85D7D4",
                "rgb": "132,214,211"
            },
            {
                "nr": "PANTONE 14-4811 TPG",
                "name": "Aqua Sky",
                "book": 2,
                "page": 67,
                "row": 5,
                "hexCode": "#7FC9CB",
                "rgb": "126,201,202"
            },
            {
                "nr": "PANTONE 15-5217 TPG",
                "name": "Blue Turquoise",
                "book": 2,
                "page": 67,
                "row": 6,
                "hexCode": "#5BB3B0",
                "rgb": "90,179,176"
            },
            {
                "nr": "PANTONE 16-5123 TPG",
                "name": "Baltic",
                "book": 2,
                "page": 67,
                "row": 7,
                "hexCode": "#339A9F",
                "rgb": "50,154,158"
            }
        ],
        [
            {
                "nr": "PANTONE 11-4607 TPG",
                "name": "Diaphonous",
                "book": 2,
                "page": 68,
                "row": 1,
                "hexCode": "#E7F0EF",
                "rgb": "231,240,239"
            },
            {
                "nr": "PANTONE 11-4606 TPG",
                "name": "Ice Castle",
                "book": 2,
                "page": 68,
                "row": 2,
                "hexCode": "#DBEEEF",
                "rgb": "219,238,239"
            },
            {
                "nr": "PANTONE 12-5208 TPG",
                "name": "Mint Julep",
                "book": 2,
                "page": 68,
                "row": 3,
                "hexCode": "#D3EEED",
                "rgb": "211,238,237"
            },
            {
                "nr": "PANTONE 12-5207 TPG",
                "name": "Salt Air",
                "book": 2,
                "page": 68,
                "row": 4,
                "hexCode": "#CCEDEE",
                "rgb": "204,237,238"
            },
            {
                "nr": "PANTONE 13-4908 TPG",
                "name": "Spa Retreat",
                "book": 2,
                "page": 68,
                "row": 5,
                "hexCode": "#B6E7EC",
                "rgb": "182,231,236"
            },
            {
                "nr": "PANTONE 14-4715 TPG",
                "name": "Water Ballet",
                "book": 2,
                "page": 68,
                "row": 6,
                "hexCode": "#9EDEE3",
                "rgb": "158,222,227"
            },
            {
                "nr": "PANTONE 12-5302 TPG",
                "name": "Cooling Oasis",
                "book": 2,
                "page": 68,
                "row": 7,
                "hexCode": "#BCDBDC",
                "rgb": "188,219,220"
            }
        ],
        [
            {
                "nr": "PANTONE 12-5206 TPG",
                "name": "Blue Glass",
                "book": 2,
                "page": 69,
                "row": 1,
                "hexCode": "#C5E1E0",
                "rgb": "196,224,224"
            },
            {
                "nr": "PANTONE 12-5209 TPG",
                "name": "Soothing Sea",
                "book": 2,
                "page": 69,
                "row": 2,
                "hexCode": "#C7E9E3",
                "rgb": "199,232,226"
            },
            {
                "nr": "PANTONE 12-5808 TPG",
                "name": "Honeydew",
                "book": 2,
                "page": 69,
                "row": 3,
                "hexCode": "#B8E3D2",
                "rgb": "183,226,210"
            },
            {
                "nr": "PANTONE 12-5409 TPG",
                "name": "Fair Aqua",
                "book": 2,
                "page": 69,
                "row": 4,
                "hexCode": "#BCE5DF",
                "rgb": "188,229,222"
            },
            {
                "nr": "PANTONE 12-5410 TPG",
                "name": "Bleached Aqua",
                "book": 2,
                "page": 69,
                "row": 5,
                "hexCode": "#B2E0DC",
                "rgb": "178,223,220"
            },
            {
                "nr": "PANTONE 14-4809 TPG",
                "name": "Eggshell Blue",
                "book": 2,
                "page": 69,
                "row": 6,
                "hexCode": "#9CC8C6",
                "rgb": "156,200,197"
            },
            {
                "nr": "PANTONE 13-5309 TPG",
                "name": "Pastel Turquoise",
                "book": 2,
                "page": 69,
                "row": 7,
                "hexCode": "#95C5C6",
                "rgb": "149,196,197"
            }
        ],
        [
            {
                "nr": "PANTONE 11-4604 TPG",
                "name": "Billowing Sail",
                "book": 2,
                "page": 70,
                "row": 1,
                "hexCode": "#DEE9E6",
                "rgb": "222,233,230"
            },
            {
                "nr": "PANTONE 12-4305 TPG",
                "name": "Spa Blue",
                "book": 2,
                "page": 70,
                "row": 2,
                "hexCode": "#D4E1DF",
                "rgb": "212,225,223"
            },
            {
                "nr": "PANTONE 12-4805 TPG",
                "name": "Wan Blue",
                "book": 2,
                "page": 70,
                "row": 3,
                "hexCode": "#CBDDDE",
                "rgb": "202,220,221"
            },
            {
                "nr": "PANTONE 12-4610 TPG",
                "name": "Whispering Blue",
                "book": 2,
                "page": 70,
                "row": 4,
                "hexCode": "#C8DDDD",
                "rgb": "199,220,221"
            },
            {
                "nr": "PANTONE 13-4804 TPG",
                "name": "Pale Blue",
                "book": 2,
                "page": 70,
                "row": 5,
                "hexCode": "#C5D8D7",
                "rgb": "197,215,214"
            },
            {
                "nr": "PANTONE 13-4405 TPG",
                "name": "Misty Blue",
                "book": 2,
                "page": 70,
                "row": 6,
                "hexCode": "#BDCFCE",
                "rgb": "188,207,206"
            },
            {
                "nr": "PANTONE 14-4504 TPG",
                "name": "Sky Gray",
                "book": 2,
                "page": 70,
                "row": 7,
                "hexCode": "#B7C7C5",
                "rgb": "183,199,197"
            }
        ],
        [
            {
                "nr": "PANTONE 11-4805 TPG",
                "name": "Hint Of Mint",
                "book": 2,
                "page": 71,
                "row": 1,
                "hexCode": "#DBEAE3",
                "rgb": "219,234,227"
            },
            {
                "nr": "PANTONE 12-5508 TPG",
                "name": "Hushed Green",
                "book": 2,
                "page": 71,
                "row": 2,
                "hexCode": "#D8E9E3",
                "rgb": "216,233,227"
            },
            {
                "nr": "PANTONE 12-5204 TPG",
                "name": "Morning Mist",
                "book": 2,
                "page": 71,
                "row": 3,
                "hexCode": "#CADDD9",
                "rgb": "201,220,217"
            },
            {
                "nr": "PANTONE 12-5303 TPG",
                "name": "Sprout Green",
                "book": 2,
                "page": 71,
                "row": 4,
                "hexCode": "#C9D8D3",
                "rgb": "201,216,211"
            },
            {
                "nr": "PANTONE 12-4806 TPG",
                "name": "Chalk Blue",
                "book": 2,
                "page": 71,
                "row": 5,
                "hexCode": "#C8D8D7",
                "rgb": "199,216,214"
            },
            {
                "nr": "PANTONE 14-4807 TPG",
                "name": "Surf Spray",
                "book": 2,
                "page": 71,
                "row": 6,
                "hexCode": "#ABC4C0",
                "rgb": "171,196,192"
            },
            {
                "nr": "PANTONE 14-4908 TPG",
                "name": "Harbor Gray",
                "book": 2,
                "page": 71,
                "row": 7,
                "hexCode": "#A6C2BB",
                "rgb": "165,193,186"
            }
        ],
        [
            {
                "nr": "PANTONE 15-4707 TPG",
                "name": "Blue Haze",
                "book": 2,
                "page": 72,
                "row": 1,
                "hexCode": "#9DB8B8",
                "rgb": "157,184,183"
            },
            {
                "nr": "PANTONE 15-4706 TPG",
                "name": "Gray Mist",
                "book": 2,
                "page": 72,
                "row": 2,
                "hexCode": "#98ADAE",
                "rgb": "151,172,174"
            },
            {
                "nr": "PANTONE 16-5106 TPG",
                "name": "Blue Surf",
                "book": 2,
                "page": 72,
                "row": 3,
                "hexCode": "#8FA9A5",
                "rgb": "142,168,165"
            },
            {
                "nr": "PANTONE 15-5207 TPG",
                "name": "Aquifer",
                "book": 2,
                "page": 72,
                "row": 4,
                "hexCode": "#83ABAD",
                "rgb": "131,170,172"
            },
            {
                "nr": "PANTONE 16-4712 TPG",
                "name": "Mineral Blue",
                "book": 2,
                "page": 72,
                "row": 5,
                "hexCode": "#6F9293",
                "rgb": "111,145,146"
            },
            {
                "nr": "PANTONE 17-5110 TPG",
                "name": "Trellis",
                "book": 2,
                "page": 72,
                "row": 6,
                "hexCode": "#6D8886",
                "rgb": "109,135,133"
            },
            {
                "nr": "PANTONE 17-4911 TPG",
                "name": "Arctic",
                "book": 2,
                "page": 72,
                "row": 7,
                "hexCode": "#66898F",
                "rgb": "102,136,143"
            }
        ],
        [
            {
                "nr": "PANTONE 16-4408 TPG",
                "name": "Slate",
                "book": 2,
                "page": 73,
                "row": 1,
                "hexCode": "#8B9EA1",
                "rgb": "138,158,160"
            },
            {
                "nr": "PANTONE 16-4706 TPG",
                "name": "Silver Blue",
                "book": 2,
                "page": 73,
                "row": 2,
                "hexCode": "#89999B",
                "rgb": "136,153,154"
            },
            {
                "nr": "PANTONE 16-4404 TPG",
                "name": "Abyss",
                "book": 2,
                "page": 73,
                "row": 3,
                "hexCode": "#8B9D9E",
                "rgb": "138,156,158"
            },
            {
                "nr": "PANTONE 17-4408 TPG",
                "name": "Lead",
                "book": 2,
                "page": 73,
                "row": 4,
                "hexCode": "#77898F",
                "rgb": "118,137,143"
            },
            {
                "nr": "PANTONE 18-4711 TPG",
                "name": "Stormy Sea",
                "book": 2,
                "page": 73,
                "row": 5,
                "hexCode": "#718383",
                "rgb": "113,130,131"
            },
            {
                "nr": "PANTONE 18-4510 TPG",
                "name": "Trooper",
                "book": 2,
                "page": 73,
                "row": 6,
                "hexCode": "#687B7E",
                "rgb": "103,122,126"
            },
            {
                "nr": "PANTONE 18-4011 TPG",
                "name": "Goblin Blue",
                "book": 2,
                "page": 73,
                "row": 7,
                "hexCode": "#607278",
                "rgb": "95,113,120"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5304 TPG",
                "name": "Jadeite",
                "book": 2,
                "page": 74,
                "row": 1,
                "hexCode": "#8DA19B",
                "rgb": "141,160,154"
            },
            {
                "nr": "PANTONE 16-5804 TPG",
                "name": "Slate Gray",
                "book": 2,
                "page": 74,
                "row": 2,
                "hexCode": "#8A9792",
                "rgb": "137,151,146"
            },
            {
                "nr": "PANTONE 17-5107 TPG",
                "name": "Chinois Green",
                "book": 2,
                "page": 74,
                "row": 3,
                "hexCode": "#778A86",
                "rgb": "119,137,133"
            },
            {
                "nr": "PANTONE 16-5806 TPG",
                "name": "Green Milieu",
                "book": 2,
                "page": 74,
                "row": 4,
                "hexCode": "#859690",
                "rgb": "132,149,144"
            },
            {
                "nr": "PANTONE 18-5606 TPG",
                "name": "Balsam Green",
                "book": 2,
                "page": 74,
                "row": 5,
                "hexCode": "#5A686A",
                "rgb": "90,103,106"
            },
            {
                "nr": "PANTONE 18-4612 TPG",
                "name": "North Atlantic",
                "book": 2,
                "page": 74,
                "row": 6,
                "hexCode": "#577477",
                "rgb": "87,116,118"
            },
            {
                "nr": "PANTONE 18-5112 TPG",
                "name": "Sea Pine",
                "book": 2,
                "page": 74,
                "row": 7,
                "hexCode": "#516F71",
                "rgb": "81,110,113"
            }
        ],
        [
            {
                "nr": "PANTONE 18-5612 TPG",
                "name": "Sagebrush Green",
                "book": 2,
                "page": 75,
                "row": 1,
                "hexCode": "#587876",
                "rgb": "88,119,118"
            },
            {
                "nr": "PANTONE 18-5410 TPG",
                "name": "Silver Pine",
                "book": 2,
                "page": 75,
                "row": 2,
                "hexCode": "#59706E",
                "rgb": "89,112,110"
            },
            {
                "nr": "PANTONE 18-5308 TPG",
                "name": "Blue Spruce",
                "book": 2,
                "page": 75,
                "row": 3,
                "hexCode": "#51716E",
                "rgb": "81,112,110"
            },
            {
                "nr": "PANTONE 19-4818 TPG",
                "name": "Mallard Green",
                "book": 2,
                "page": 75,
                "row": 4,
                "hexCode": "#4D6768",
                "rgb": "76,102,104"
            },
            {
                "nr": "PANTONE 19-5408 TPG",
                "name": "Bistro Green",
                "book": 2,
                "page": 75,
                "row": 5,
                "hexCode": "#3B5855",
                "rgb": "59,87,84"
            },
            {
                "nr": "PANTONE 19-5030 TPG",
                "name": "Sea Moss",
                "book": 2,
                "page": 75,
                "row": 6,
                "hexCode": "#394F52",
                "rgb": "57,79,82"
            },
            {
                "nr": "PANTONE 19-5320 TPG",
                "name": "Ponderosa Pine",
                "book": 2,
                "page": 75,
                "row": 7,
                "hexCode": "#324D4D",
                "rgb": "49,77,77"
            }
        ],
        [
            {
                "nr": "PANTONE 19-5226 TPG",
                "name": "Everglade",
                "book": 2,
                "page": 76,
                "row": 1,
                "hexCode": "#1F6768",
                "rgb": "30,102,104"
            },
            {
                "nr": "PANTONE 19-4922 TPG",
                "name": "Teal Green",
                "book": 2,
                "page": 76,
                "row": 2,
                "hexCode": "#276A6C",
                "rgb": "39,106,108"
            },
            {
                "nr": "PANTONE 19-4916 TPG",
                "name": "Pacific",
                "book": 2,
                "page": 76,
                "row": 3,
                "hexCode": "#386268",
                "rgb": "56,97,104"
            },
            {
                "nr": "PANTONE 19-5413 TPG",
                "name": "Jasper",
                "book": 2,
                "page": 76,
                "row": 4,
                "hexCode": "#3F6364",
                "rgb": "62,98,99"
            },
            {
                "nr": "PANTONE 19-5217 TPG",
                "name": "Storm",
                "book": 2,
                "page": 76,
                "row": 5,
                "hexCode": "#2E6464",
                "rgb": "45,100,100"
            },
            {
                "nr": "PANTONE 18-5315 TPG",
                "name": "Bayberry",
                "book": 2,
                "page": 76,
                "row": 6,
                "hexCode": "#34625F",
                "rgb": "52,97,95"
            },
            {
                "nr": "PANTONE 19-5414 TPG",
                "name": "June Bug",
                "book": 2,
                "page": 76,
                "row": 7,
                "hexCode": "#2F5050",
                "rgb": "46,79,80"
            }
        ],
        [
            {
                "nr": "PANTONE 18-4835 TPG",
                "name": "Alexandrite",
                "book": 2,
                "page": 77,
                "row": 1,
                "hexCode": "#166E72",
                "rgb": "22,110,114"
            },
            {
                "nr": "PANTONE 18-4833 TPG",
                "name": "Kayaking",
                "book": 2,
                "page": 77,
                "row": 2,
                "hexCode": "#006D72",
                "rgb": "0,109,114"
            },
            {
                "nr": "PANTONE 19-4918 TPG",
                "name": "Spruced-up",
                "book": 2,
                "page": 77,
                "row": 3,
                "hexCode": "#25595F",
                "rgb": "37,89,95"
            },
            {
                "nr": "PANTONE 19-4534 TPG",
                "name": "Chesapeake Bay",
                "book": 2,
                "page": 77,
                "row": 4,
                "hexCode": "#2A5B64",
                "rgb": "42,91,100"
            },
            {
                "nr": "PANTONE 19-4727 TPG",
                "name": "Green Heron",
                "book": 2,
                "page": 77,
                "row": 5,
                "hexCode": "#325B63",
                "rgb": "50,91,99"
            },
            {
                "nr": "PANTONE 19-4523 TPG",
                "name": "Gulf Coast",
                "book": 2,
                "page": 77,
                "row": 6,
                "hexCode": "#255663",
                "rgb": "37,86,99"
            },
            {
                "nr": "PANTONE 19-5025 TPG",
                "name": "Dark Sea",
                "book": 2,
                "page": 77,
                "row": 7,
                "hexCode": "#304E53",
                "rgb": "48,78,83"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5112 TPG",
                "name": "Canton",
                "book": 2,
                "page": 78,
                "row": 1,
                "hexCode": "#73A2A1",
                "rgb": "115,162,161"
            },
            {
                "nr": "PANTONE 16-5412 TPG",
                "name": "Agate Green",
                "book": 2,
                "page": 78,
                "row": 2,
                "hexCode": "#63A19C",
                "rgb": "98,160,156"
            },
            {
                "nr": "PANTONE 16-5119 TPG",
                "name": "Sea Blue",
                "book": 2,
                "page": 78,
                "row": 3,
                "hexCode": "#589D98",
                "rgb": "87,156,152"
            },
            {
                "nr": "PANTONE 17-5122 TPG",
                "name": "Latigo Bay",
                "book": 2,
                "page": 78,
                "row": 4,
                "hexCode": "#429394",
                "rgb": "65,147,148"
            },
            {
                "nr": "PANTONE 17-5421 TPG",
                "name": "Porcelain Green",
                "book": 2,
                "page": 78,
                "row": 5,
                "hexCode": "#328D87",
                "rgb": "50,141,135"
            },
            {
                "nr": "PANTONE 18-5121 TPG",
                "name": "Bayou",
                "book": 2,
                "page": 78,
                "row": 6,
                "hexCode": "#3A7778",
                "rgb": "57,118,120"
            },
            {
                "nr": "PANTONE 18-5115 TPG",
                "name": "North Sea",
                "book": 2,
                "page": 78,
                "row": 7,
                "hexCode": "#487273",
                "rgb": "71,113,115"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5127 TPG",
                "name": "Ceramic",
                "book": 2,
                "page": 79,
                "row": 1,
                "hexCode": "#00ACAD",
                "rgb": "0,172,173"
            },
            {
                "nr": "PANTONE 17-5126 TPG",
                "name": "Viridian Green",
                "book": 2,
                "page": 79,
                "row": 2,
                "hexCode": "#00969C",
                "rgb": "0,150,156"
            },
            {
                "nr": "PANTONE 18-4930 TPG",
                "name": "Tropical Green",
                "book": 2,
                "page": 79,
                "row": 3,
                "hexCode": "#008B8C",
                "rgb": "0,139,140"
            },
            {
                "nr": "PANTONE 17-5025 TPG",
                "name": "Navigate",
                "book": 2,
                "page": 79,
                "row": 4,
                "hexCode": "#00878A",
                "rgb": "0,135,138"
            },
            {
                "nr": "PANTONE 17-5029 TPG",
                "name": "Deep Peacock Blue",
                "book": 2,
                "page": 79,
                "row": 5,
                "hexCode": "#008788",
                "rgb": "0,135,136"
            },
            {
                "nr": "PANTONE 17-5034 TPG",
                "name": "Lapis",
                "book": 2,
                "page": 79,
                "row": 6,
                "hexCode": "#008D8E",
                "rgb": "0,141,142"
            },
            {
                "nr": "PANTONE 18-4834 TPG",
                "name": "Deep Lake",
                "book": 2,
                "page": 79,
                "row": 7,
                "hexCode": "#106972",
                "rgb": "16,105,113"
            }
        ],
        [
            {
                "nr": "PANTONE 15-5218 TPG",
                "name": "Pool Blue",
                "book": 2,
                "page": 80,
                "row": 1,
                "hexCode": "#6EBEBA",
                "rgb": "109,189,186"
            },
            {
                "nr": "PANTONE 15-5519 TPG",
                "name": "Turquoise",
                "book": 2,
                "page": 80,
                "row": 2,
                "hexCode": "#4DB6AD",
                "rgb": "77,181,173"
            },
            {
                "nr": "PANTONE 15-5516 TPG",
                "name": "Waterfall",
                "book": 2,
                "page": 80,
                "row": 3,
                "hexCode": "#49B2A7",
                "rgb": "72,178,166"
            },
            {
                "nr": "PANTONE 16-5418 TPG",
                "name": "Lagoon",
                "book": 2,
                "page": 80,
                "row": 4,
                "hexCode": "#53A09C",
                "rgb": "83,159,156"
            },
            {
                "nr": "PANTONE 16-5422 TPG",
                "name": "Bright Aqua",
                "book": 2,
                "page": 80,
                "row": 5,
                "hexCode": "#3AA5A1",
                "rgb": "58,165,160"
            },
            {
                "nr": "PANTONE 18-5128 TPG",
                "name": "Blue Grass",
                "book": 2,
                "page": 80,
                "row": 6,
                "hexCode": "#008283",
                "rgb": "0,130,131"
            },
            {
                "nr": "PANTONE 18-4936 TPG",
                "name": "Fanfare",
                "book": 2,
                "page": 80,
                "row": 7,
                "hexCode": "#007479",
                "rgb": "0,116,121"
            }
        ],
        [
            {
                "nr": "PANTONE 15-5425 TPG",
                "name": "Atlantis",
                "book": 2,
                "page": 81,
                "row": 1,
                "hexCode": "#00B2A3",
                "rgb": "0,178,163"
            },
            {
                "nr": "PANTONE 16-5425 TPG",
                "name": "Pool Green",
                "book": 2,
                "page": 81,
                "row": 2,
                "hexCode": "#00B09E",
                "rgb": "0,176,158"
            },
            {
                "nr": "PANTONE 17-5335 TPG",
                "name": "Spectra Green",
                "book": 2,
                "page": 81,
                "row": 3,
                "hexCode": "#009E92",
                "rgb": "0,158,146"
            },
            {
                "nr": "PANTONE 17-5330 TPG",
                "name": "Dynasty Green",
                "book": 2,
                "page": 81,
                "row": 4,
                "hexCode": "#009589",
                "rgb": "0,149,137"
            },
            {
                "nr": "PANTONE 17-5130 TPG",
                "name": "Columbia",
                "book": 2,
                "page": 81,
                "row": 5,
                "hexCode": "#00938C",
                "rgb": "0,147,140"
            },
            {
                "nr": "PANTONE 17-5024 TPG",
                "name": "Teal Blue",
                "book": 2,
                "page": 81,
                "row": 6,
                "hexCode": "#078182",
                "rgb": "7,129,130"
            },
            {
                "nr": "PANTONE 18-5025 TPG",
                "name": "Quetzal Green",
                "book": 2,
                "page": 81,
                "row": 7,
                "hexCode": "#006D6D",
                "rgb": "0,109,109"
            }
        ],
        [
            {
                "nr": "PANTONE 14-5416 TPG",
                "name": "Bermuda",
                "book": 2,
                "page": 82,
                "row": 1,
                "hexCode": "#5BC8B3",
                "rgb": "90,200,179"
            },
            {
                "nr": "PANTONE 14-5721 TPG",
                "name": "Electric Green",
                "book": 2,
                "page": 82,
                "row": 2,
                "hexCode": "#42C0A8",
                "rgb": "65,192,168"
            },
            {
                "nr": "PANTONE 15-5421 TPG",
                "name": "Aqua Green",
                "book": 2,
                "page": 82,
                "row": 3,
                "hexCode": "#1CBAA4",
                "rgb": "28,185,163"
            },
            {
                "nr": "PANTONE 16-5427 TPG",
                "name": "Billiard",
                "book": 2,
                "page": 82,
                "row": 4,
                "hexCode": "#00AE99",
                "rgb": "0,174,153"
            },
            {
                "nr": "PANTONE 16-5431 TPG",
                "name": "Peacock Green",
                "book": 2,
                "page": 82,
                "row": 5,
                "hexCode": "#00A88D",
                "rgb": "0,168,141"
            },
            {
                "nr": "PANTONE 16-5533 TPG",
                "name": "Arcadia",
                "book": 2,
                "page": 82,
                "row": 6,
                "hexCode": "#00A591",
                "rgb": "0,165,145"
            },
            {
                "nr": "PANTONE 18-5020 TPG",
                "name": "Parasailing",
                "book": 2,
                "page": 82,
                "row": 7,
                "hexCode": "#007773",
                "rgb": "0,119,115"
            }
        ],
        [
            {
                "nr": "PANTONE 13-6009 TPG",
                "name": "Brook Green",
                "book": 2,
                "page": 83,
                "row": 1,
                "hexCode": "#A8DECC",
                "rgb": "168,221,204"
            },
            {
                "nr": "PANTONE 13-5714 TPG",
                "name": "Cabbage",
                "book": 2,
                "page": 83,
                "row": 2,
                "hexCode": "#88DCBE",
                "rgb": "136,219,190"
            },
            {
                "nr": "PANTONE 14-5714 TPG",
                "name": "Beveled Glass",
                "book": 2,
                "page": 83,
                "row": 3,
                "hexCode": "#7DCDB6",
                "rgb": "124,204,182"
            },
            {
                "nr": "PANTONE 14-5718 TPG",
                "name": "Opal",
                "book": 2,
                "page": 83,
                "row": 4,
                "hexCode": "#70CEB4",
                "rgb": "112,205,179"
            },
            {
                "nr": "PANTONE 15-5819 TPG",
                "name": "Spearmint",
                "book": 2,
                "page": 83,
                "row": 5,
                "hexCode": "#66C0A4",
                "rgb": "101,192,163"
            },
            {
                "nr": "PANTONE 15-5718 TPG",
                "name": "Biscay Green",
                "book": 2,
                "page": 83,
                "row": 6,
                "hexCode": "#4EC5A5",
                "rgb": "78,196,164"
            },
            {
                "nr": "PANTONE 15-5728 TPG",
                "name": "Mint Leaf",
                "book": 2,
                "page": 83,
                "row": 7,
                "hexCode": "#0BB594",
                "rgb": "11,181,148"
            }
        ],
        [
            {
                "nr": "PANTONE 12-5407 TPG",
                "name": "Aqua Glass",
                "book": 2,
                "page": 84,
                "row": 1,
                "hexCode": "#D3E8DD",
                "rgb": "211,231,221"
            },
            {
                "nr": "PANTONE 12-5507 TPG",
                "name": "Bay",
                "book": 2,
                "page": 84,
                "row": 2,
                "hexCode": "#BDE7D8",
                "rgb": "189,230,215"
            },
            {
                "nr": "PANTONE 13-5409 TPG",
                "name": "Yucca",
                "book": 2,
                "page": 84,
                "row": 3,
                "hexCode": "#9ED9CC",
                "rgb": "158,217,204"
            },
            {
                "nr": "PANTONE 13-5412 TPG",
                "name": "Beach Glass",
                "book": 2,
                "page": 84,
                "row": 4,
                "hexCode": "#97DFCE",
                "rgb": "151,223,205"
            },
            {
                "nr": "PANTONE 13-5414 TPG",
                "name": "Ice Green",
                "book": 2,
                "page": 84,
                "row": 5,
                "hexCode": "#7FD6C1",
                "rgb": "126,213,193"
            },
            {
                "nr": "PANTONE 14-5420 TPG",
                "name": "Cockatoo",
                "book": 2,
                "page": 84,
                "row": 6,
                "hexCode": "#5ECCB9",
                "rgb": "93,204,185"
            },
            {
                "nr": "PANTONE 15-5416 TPG",
                "name": "Florida Keys",
                "book": 2,
                "page": 84,
                "row": 7,
                "hexCode": "#50BFAC",
                "rgb": "79,191,171"
            }
        ],
        [
            {
                "nr": "PANTONE 12-5406 TPG",
                "name": "Opal Blue",
                "book": 2,
                "page": 85,
                "row": 1,
                "hexCode": "#C3DED7",
                "rgb": "195,221,214"
            },
            {
                "nr": "PANTONE 12-5408 TPG",
                "name": "Moonlight Jade",
                "book": 2,
                "page": 85,
                "row": 2,
                "hexCode": "#C2E5DB",
                "rgb": "194,228,219"
            },
            {
                "nr": "PANTONE 12-5505 TPG",
                "name": "Glacier",
                "book": 2,
                "page": 85,
                "row": 3,
                "hexCode": "#C1DAD4",
                "rgb": "193,218,212"
            },
            {
                "nr": "PANTONE 14-5711 TPG",
                "name": "Ocean Wave",
                "book": 2,
                "page": 85,
                "row": 4,
                "hexCode": "#8EC5B7",
                "rgb": "141,197,182"
            },
            {
                "nr": "PANTONE 14-5413 TPG",
                "name": "Holiday",
                "book": 2,
                "page": 85,
                "row": 5,
                "hexCode": "#7BC2B3",
                "rgb": "123,193,179"
            },
            {
                "nr": "PANTONE 14-5713 TPG",
                "name": "Cascade",
                "book": 2,
                "page": 85,
                "row": 6,
                "hexCode": "#77BEB1",
                "rgb": "118,189,176"
            },
            {
                "nr": "PANTONE 15-5711 TPG",
                "name": "Dusty Jade Green",
                "book": 2,
                "page": 85,
                "row": 7,
                "hexCode": "#7CB7A3",
                "rgb": "124,183,163"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5109 TPG",
                "name": "Wasabi",
                "book": 2,
                "page": 86,
                "row": 1,
                "hexCode": "#74A59E",
                "rgb": "116,164,157"
            },
            {
                "nr": "PANTONE 16-5515 TPG",
                "name": "Beryl Green",
                "book": 2,
                "page": 86,
                "row": 2,
                "hexCode": "#68938A",
                "rgb": "104,147,138"
            },
            {
                "nr": "PANTONE 17-5111 TPG",
                "name": "Oil Blue",
                "book": 2,
                "page": 86,
                "row": 3,
                "hexCode": "#678E8D",
                "rgb": "103,141,140"
            },
            {
                "nr": "PANTONE 17-5513 TPG",
                "name": "Deep Sea",
                "book": 2,
                "page": 86,
                "row": 4,
                "hexCode": "#53817B",
                "rgb": "83,128,123"
            },
            {
                "nr": "PANTONE 18-5618 TPG",
                "name": "Deep Jungle",
                "book": 2,
                "page": 86,
                "row": 5,
                "hexCode": "#457876",
                "rgb": "68,120,117"
            },
            {
                "nr": "PANTONE 18-5619 TPG",
                "name": "Tidepool",
                "book": 2,
                "page": 86,
                "row": 6,
                "hexCode": "#27706B",
                "rgb": "38,111,107"
            },
            {
                "nr": "PANTONE 18-5620 TPG",
                "name": "Ivy",
                "book": 2,
                "page": 86,
                "row": 7,
                "hexCode": "#397873",
                "rgb": "57,119,114"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5919 TPG",
                "name": "Crème De Menthe",
                "book": 2,
                "page": 87,
                "row": 1,
                "hexCode": "#73A18D",
                "rgb": "115,160,141"
            },
            {
                "nr": "PANTONE 16-5815 TPG",
                "name": "Feldspar",
                "book": 2,
                "page": 87,
                "row": 2,
                "hexCode": "#749D8C",
                "rgb": "115,156,140"
            },
            {
                "nr": "PANTONE 18-5622 TPG",
                "name": "Frosty Spruce",
                "book": 2,
                "page": 87,
                "row": 3,
                "hexCode": "#578273",
                "rgb": "86,130,115"
            },
            {
                "nr": "PANTONE 17-5722 TPG",
                "name": "Bottle Green",
                "book": 2,
                "page": 87,
                "row": 4,
                "hexCode": "#4A8376",
                "rgb": "74,130,118"
            },
            {
                "nr": "PANTONE 18-5418 TPG",
                "name": "Antique Green",
                "book": 2,
                "page": 87,
                "row": 5,
                "hexCode": "#3B6D69",
                "rgb": "59,108,104"
            },
            {
                "nr": "PANTONE 18-5725 TPG",
                "name": "Galapagos Green",
                "book": 2,
                "page": 87,
                "row": 6,
                "hexCode": "#30675D",
                "rgb": "48,102,92"
            },
            {
                "nr": "PANTONE 19-5230 TPG",
                "name": "Forest Biome",
                "book": 2,
                "page": 87,
                "row": 7,
                "hexCode": "#345654",
                "rgb": "52,86,84"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5917 TPG",
                "name": "Malachite Green",
                "book": 2,
                "page": 88,
                "row": 1,
                "hexCode": "#6C9988",
                "rgb": "107,152,135"
            },
            {
                "nr": "PANTONE 18-5621 TPG",
                "name": "Fir",
                "book": 2,
                "page": 88,
                "row": 2,
                "hexCode": "#467768",
                "rgb": "70,118,104"
            },
            {
                "nr": "PANTONE 19-5420 TPG",
                "name": "Evergreen",
                "book": 2,
                "page": 88,
                "row": 3,
                "hexCode": "#31655A",
                "rgb": "49,100,90"
            },
            {
                "nr": "PANTONE 18-5616 TPG",
                "name": "Posy Green",
                "book": 2,
                "page": 88,
                "row": 4,
                "hexCode": "#3E625B",
                "rgb": "62,97,91"
            },
            {
                "nr": "PANTONE 19-5511 TPG",
                "name": "Hunter Green",
                "book": 2,
                "page": 88,
                "row": 5,
                "hexCode": "#435E55",
                "rgb": "66,94,84"
            },
            {
                "nr": "PANTONE 18-5718 TPG",
                "name": "Smoke Pine",
                "book": 2,
                "page": 88,
                "row": 6,
                "hexCode": "#4E6C64",
                "rgb": "77,107,100"
            },
            {
                "nr": "PANTONE 19-5232 TPG",
                "name": "Rain Forest",
                "book": 2,
                "page": 88,
                "row": 7,
                "hexCode": "#375551",
                "rgb": "55,84,80"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5721 TPG",
                "name": "Marine Green",
                "book": 2,
                "page": 89,
                "row": 1,
                "hexCode": "#499E8C",
                "rgb": "72,158,139"
            },
            {
                "nr": "PANTONE 17-5430 TPG",
                "name": "Alhambra",
                "book": 2,
                "page": 89,
                "row": 2,
                "hexCode": "#288A7C",
                "rgb": "39,137,123"
            },
            {
                "nr": "PANTONE 17-5734 TPG",
                "name": "Viridis",
                "book": 2,
                "page": 89,
                "row": 3,
                "hexCode": "#1A8872",
                "rgb": "25,136,113"
            },
            {
                "nr": "PANTONE 17-5528 TPG",
                "name": "Greenlake",
                "book": 2,
                "page": 89,
                "row": 4,
                "hexCode": "#168172",
                "rgb": "21,129,114"
            },
            {
                "nr": "PANTONE 18-5624 TPG",
                "name": "Shady Glade",
                "book": 2,
                "page": 89,
                "row": 5,
                "hexCode": "#297969",
                "rgb": "41,121,104"
            },
            {
                "nr": "PANTONE 18-5424 TPG",
                "name": "Cadmium Green",
                "book": 2,
                "page": 89,
                "row": 6,
                "hexCode": "#12756A",
                "rgb": "17,117,106"
            },
            {
                "nr": "PANTONE 18-5322 TPG",
                "name": "Alpine Green",
                "book": 2,
                "page": 89,
                "row": 7,
                "hexCode": "#236661",
                "rgb": "34,102,96"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5426 TPG",
                "name": "Water Garden",
                "book": 2,
                "page": 90,
                "row": 1,
                "hexCode": "#29A793",
                "rgb": "41,167,147"
            },
            {
                "nr": "PANTONE 17-5440 TPG",
                "name": "Ocean Floor",
                "book": 2,
                "page": 90,
                "row": 2,
                "hexCode": "#00887D",
                "rgb": "0,136,125"
            },
            {
                "nr": "PANTONE 17-5730 TPG",
                "name": "Slushy",
                "book": 2,
                "page": 90,
                "row": 3,
                "hexCode": "#008D7D",
                "rgb": "0,141,125"
            },
            {
                "nr": "PANTONE 17-5527 TPG",
                "name": "Sporting Green",
                "book": 2,
                "page": 90,
                "row": 4,
                "hexCode": "#007C6D",
                "rgb": "0,124,109"
            },
            {
                "nr": "PANTONE 18-5016 TPG",
                "name": "Proud Peacock",
                "book": 2,
                "page": 90,
                "row": 5,
                "hexCode": "#00746D",
                "rgb": "0,116,109"
            },
            {
                "nr": "PANTONE 18-5425 TPG",
                "name": "Bear Grass",
                "book": 2,
                "page": 90,
                "row": 6,
                "hexCode": "#007265",
                "rgb": "0,114,101"
            },
            {
                "nr": "PANTONE 19-5421 TPG",
                "name": "Aventurine",
                "book": 2,
                "page": 90,
                "row": 7,
                "hexCode": "#0E5E55",
                "rgb": "14,94,85"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5421 TPG",
                "name": "Sea Green",
                "book": 2,
                "page": 91,
                "row": 1,
                "hexCode": "#2BA08B",
                "rgb": "42,159,139"
            },
            {
                "nr": "PANTONE 17-5633 TPG",
                "name": "Deep Green",
                "book": 2,
                "page": 91,
                "row": 2,
                "hexCode": "#00947C",
                "rgb": "0,148,124"
            },
            {
                "nr": "PANTONE 17-5638 TPG",
                "name": "Vivid Green",
                "book": 2,
                "page": 91,
                "row": 3,
                "hexCode": "#00A385",
                "rgb": "0,163,133"
            },
            {
                "nr": "PANTONE 17-5641 TPG",
                "name": "Emerald",
                "book": 2,
                "page": 91,
                "row": 4,
                "hexCode": "#00997B",
                "rgb": "0,153,123"
            },
            {
                "nr": "PANTONE 18-5841 TPG",
                "name": "Pepper Green",
                "book": 2,
                "page": 91,
                "row": 5,
                "hexCode": "#00856A",
                "rgb": "0,133,106"
            },
            {
                "nr": "PANTONE 17-5735 TPG",
                "name": "Parakeet",
                "book": 2,
                "page": 91,
                "row": 6,
                "hexCode": "#009270",
                "rgb": "0,146,112"
            },
            {
                "nr": "PANTONE 18-5642 TPG",
                "name": "Golf Green",
                "book": 2,
                "page": 91,
                "row": 7,
                "hexCode": "#00916F",
                "rgb": "0,145,111"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5932 TPG",
                "name": "Holly Green",
                "book": 2,
                "page": 92,
                "row": 1,
                "hexCode": "#14A07D",
                "rgb": "20,160,124"
            },
            {
                "nr": "PANTONE 17-5936 TPG",
                "name": "Simply Green",
                "book": 2,
                "page": 92,
                "row": 2,
                "hexCode": "#139C7A",
                "rgb": "19,155,122"
            },
            {
                "nr": "PANTONE 16-5938 TPG",
                "name": "Mint",
                "book": 2,
                "page": 92,
                "row": 3,
                "hexCode": "#00A778",
                "rgb": "0,167,120"
            },
            {
                "nr": "PANTONE 17-5937 TPG",
                "name": "Deep Mint",
                "book": 2,
                "page": 92,
                "row": 4,
                "hexCode": "#00A376",
                "rgb": "0,163,118"
            },
            {
                "nr": "PANTONE 18-5633 TPG",
                "name": "Bosphorus",
                "book": 2,
                "page": 92,
                "row": 5,
                "hexCode": "#0A8066",
                "rgb": "10,128,102"
            },
            {
                "nr": "PANTONE 18-5845 TPG",
                "name": "Lush Meadow",
                "book": 2,
                "page": 92,
                "row": 6,
                "hexCode": "#00755C",
                "rgb": "0,117,92"
            },
            {
                "nr": "PANTONE 18-5338 TPG",
                "name": "Ultramarine Green",
                "book": 2,
                "page": 92,
                "row": 7,
                "hexCode": "#007661",
                "rgb": "0,118,97"
            }
        ],
        [
            {
                "nr": "PANTONE 14-6017 TPG",
                "name": "Neptune Green",
                "book": 2,
                "page": 93,
                "row": 1,
                "hexCode": "#81BBA1",
                "rgb": "129,187,161"
            },
            {
                "nr": "PANTONE 16-6030 TPG",
                "name": "Katydid",
                "book": 2,
                "page": 93,
                "row": 2,
                "hexCode": "#69B794",
                "rgb": "104,183,148"
            },
            {
                "nr": "PANTONE 14-6330 TPG",
                "name": "Spring Bud",
                "book": 2,
                "page": 93,
                "row": 3,
                "hexCode": "#70D4A5",
                "rgb": "112,211,164"
            },
            {
                "nr": "PANTONE 15-6123 TPG",
                "name": "Jade Cream",
                "book": 2,
                "page": 93,
                "row": 4,
                "hexCode": "#65BA96",
                "rgb": "100,185,149"
            },
            {
                "nr": "PANTONE 16-5930 TPG",
                "name": "Ming Green",
                "book": 2,
                "page": 93,
                "row": 5,
                "hexCode": "#40A37B",
                "rgb": "63,163,122"
            },
            {
                "nr": "PANTONE 16-5942 TPG",
                "name": "Blarney",
                "book": 2,
                "page": 93,
                "row": 6,
                "hexCode": "#00A87C",
                "rgb": "0,168,124"
            },
            {
                "nr": "PANTONE 16-5825 TPG",
                "name": "Gumdrop Green",
                "book": 2,
                "page": 93,
                "row": 7,
                "hexCode": "#41A98D",
                "rgb": "64,169,140"
            }
        ],
        [
            {
                "nr": "PANTONE 15-5812 TPG",
                "name": "Lichen",
                "book": 2,
                "page": 94,
                "row": 1,
                "hexCode": "#99C2B0",
                "rgb": "153,193,175"
            },
            {
                "nr": "PANTONE 13-5907 TPG",
                "name": "Gossamer Green",
                "book": 2,
                "page": 94,
                "row": 2,
                "hexCode": "#AECEBE",
                "rgb": "173,206,189"
            },
            {
                "nr": "PANTONE 13-5911 TPG",
                "name": "Bird's Egg Green",
                "book": 2,
                "page": 94,
                "row": 3,
                "hexCode": "#A8D0BD",
                "rgb": "168,208,188"
            },
            {
                "nr": "PANTONE 13-6110 TPG",
                "name": "Mist Green",
                "book": 2,
                "page": 94,
                "row": 4,
                "hexCode": "#A7CEB9",
                "rgb": "167,206,184"
            },
            {
                "nr": "PANTONE 14-6011 TPG",
                "name": "Grayed Jade",
                "book": 2,
                "page": 94,
                "row": 5,
                "hexCode": "#9AC0AC",
                "rgb": "154,191,171"
            },
            {
                "nr": "PANTONE 16-5820 TPG",
                "name": "Green Spruce",
                "book": 2,
                "page": 94,
                "row": 6,
                "hexCode": "#5C9F85",
                "rgb": "92,159,133"
            },
            {
                "nr": "PANTONE 16-5924 TPG",
                "name": "Winter Green",
                "book": 2,
                "page": 94,
                "row": 7,
                "hexCode": "#59A085",
                "rgb": "88,160,133"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0304 TPG",
                "name": "Water Lily",
                "book": 2,
                "page": 95,
                "row": 1,
                "hexCode": "#DEE3D7",
                "rgb": "221,226,214"
            },
            {
                "nr": "PANTONE 12-6208 TPG",
                "name": "Phantom Green",
                "book": 2,
                "page": 95,
                "row": 2,
                "hexCode": "#DCE2D6",
                "rgb": "219,225,214"
            },
            {
                "nr": "PANTONE 12-6206 TPG",
                "name": "Fairest Jade",
                "book": 2,
                "page": 95,
                "row": 3,
                "hexCode": "#DBE3D6",
                "rgb": "219,226,213"
            },
            {
                "nr": "PANTONE 12-5504 TPG",
                "name": "Clearly Aqua",
                "book": 2,
                "page": 95,
                "row": 4,
                "hexCode": "#CFE3D8",
                "rgb": "206,227,215"
            },
            {
                "nr": "PANTONE 13-6008 TPG",
                "name": "Misty Jade",
                "book": 2,
                "page": 95,
                "row": 5,
                "hexCode": "#BDDACA",
                "rgb": "189,218,201"
            },
            {
                "nr": "PANTONE 12-5506 TPG",
                "name": "Dusty Aqua",
                "book": 2,
                "page": 95,
                "row": 6,
                "hexCode": "#C2DCD2",
                "rgb": "193,219,209"
            },
            {
                "nr": "PANTONE 14-6312 TPG",
                "name": "Cameo Green",
                "book": 2,
                "page": 95,
                "row": 7,
                "hexCode": "#A6C2AE",
                "rgb": "165,193,173"
            }
        ],
        [
            {
                "nr": "PANTONE 11-4303 TPG",
                "name": "Mystic Blue",
                "book": 2,
                "page": 96,
                "row": 1,
                "hexCode": "#E4E7E0",
                "rgb": "228,231,224"
            },
            {
                "nr": "PANTONE 12-4304 TPG",
                "name": "Bluewash",
                "book": 2,
                "page": 96,
                "row": 2,
                "hexCode": "#DFE5E0",
                "rgb": "223,229,224"
            },
            {
                "nr": "PANTONE 11-4301 TPG",
                "name": "Lily White",
                "book": 2,
                "page": 96,
                "row": 3,
                "hexCode": "#E3E3DC",
                "rgb": "227,227,220"
            },
            {
                "nr": "PANTONE 11-4802 TPG",
                "name": "Summer Shower",
                "book": 2,
                "page": 96,
                "row": 4,
                "hexCode": "#E3E8E1",
                "rgb": "227,232,225"
            },
            {
                "nr": "PANTONE 11-4804 TPG",
                "name": "Lightest Sky",
                "book": 2,
                "page": 96,
                "row": 5,
                "hexCode": "#E6EBE0",
                "rgb": "230,235,224"
            },
            {
                "nr": "PANTONE 12-5203 TPG",
                "name": "Murmur",
                "book": 2,
                "page": 96,
                "row": 6,
                "hexCode": "#D3DCD6",
                "rgb": "211,220,214"
            },
            {
                "nr": "PANTONE 12-5603 TPG",
                "name": "Zephyr Blue",
                "book": 2,
                "page": 96,
                "row": 7,
                "hexCode": "#D5DDD4",
                "rgb": "213,221,212"
            }
        ],
        [
            {
                "nr": "PANTONE 12-5404 TPG",
                "name": "Whisper Green",
                "book": 2,
                "page": 97,
                "row": 1,
                "hexCode": "#E2E6D9",
                "rgb": "226,229,216"
            },
            {
                "nr": "PANTONE 12-6205 TPG",
                "name": "Milky Green",
                "book": 2,
                "page": 97,
                "row": 2,
                "hexCode": "#CFDAD0",
                "rgb": "206,217,208"
            },
            {
                "nr": "PANTONE 13-0107 TPG",
                "name": "Dewkist",
                "book": 2,
                "page": 97,
                "row": 3,
                "hexCode": "#C1D0BE",
                "rgb": "192,207,190"
            },
            {
                "nr": "PANTONE 13-6106 TPG",
                "name": "Green Tint",
                "book": 2,
                "page": 97,
                "row": 4,
                "hexCode": "#C5CDC0",
                "rgb": "197,205,191"
            },
            {
                "nr": "PANTONE 13-6107 TPG",
                "name": "Green Lily",
                "book": 2,
                "page": 97,
                "row": 5,
                "hexCode": "#BDCEBD",
                "rgb": "188,205,189"
            },
            {
                "nr": "PANTONE 13-6108 TPG",
                "name": "Celadon",
                "book": 2,
                "page": 97,
                "row": 6,
                "hexCode": "#B4CAB7",
                "rgb": "179,201,183"
            },
            {
                "nr": "PANTONE 14-6008 TPG",
                "name": "Subtle Green",
                "book": 2,
                "page": 97,
                "row": 7,
                "hexCode": "#AFC9BA",
                "rgb": "174,200,186"
            }
        ],
        [
            {
                "nr": "PANTONE 14-4505 TPG",
                "name": "Smoke",
                "book": 2,
                "page": 98,
                "row": 1,
                "hexCode": "#BCC6C2",
                "rgb": "187,197,193"
            },
            {
                "nr": "PANTONE 13-5305 TPG",
                "name": "Pale Aqua",
                "book": 2,
                "page": 98,
                "row": 2,
                "hexCode": "#C0CDC4",
                "rgb": "192,204,196"
            },
            {
                "nr": "PANTONE 14-5707 TPG",
                "name": "Aqua Foam",
                "book": 2,
                "page": 98,
                "row": 3,
                "hexCode": "#ABC4B6",
                "rgb": "170,196,182"
            },
            {
                "nr": "PANTONE 14-5706 TPG",
                "name": "Silt Green",
                "book": 2,
                "page": 98,
                "row": 4,
                "hexCode": "#ABC0B5",
                "rgb": "170,192,180"
            },
            {
                "nr": "PANTONE 15-5706 TPG",
                "name": "Frosty Green",
                "book": 2,
                "page": 98,
                "row": 5,
                "hexCode": "#A1B4A7",
                "rgb": "160,180,167"
            },
            {
                "nr": "PANTONE 16-5907 TPG",
                "name": "Granite Green",
                "book": 2,
                "page": 98,
                "row": 6,
                "hexCode": "#84A295",
                "rgb": "132,162,148"
            },
            {
                "nr": "PANTONE 16-5810 TPG",
                "name": "Green Bay",
                "book": 2,
                "page": 98,
                "row": 7,
                "hexCode": "#7A8E81",
                "rgb": "121,142,129"
            }
        ],
        [
            {
                "nr": "PANTONE 12-5403 TPG",
                "name": "Blue Flower",
                "book": 2,
                "page": 99,
                "row": 1,
                "hexCode": "#D1DBD6",
                "rgb": "209,219,214"
            },
            {
                "nr": "PANTONE 14-4502 TPG",
                "name": "Mercury",
                "book": 2,
                "page": 99,
                "row": 2,
                "hexCode": "#BBC3BE",
                "rgb": "186,194,189"
            },
            {
                "nr": "PANTONE 14-4503 TPG",
                "name": "Metal",
                "book": 2,
                "page": 99,
                "row": 3,
                "hexCode": "#B2BCB8",
                "rgb": "178,188,184"
            },
            {
                "nr": "PANTONE 15-4702 TPG",
                "name": "Puritan Gray",
                "book": 2,
                "page": 99,
                "row": 4,
                "hexCode": "#9FADA9",
                "rgb": "159,173,169"
            },
            {
                "nr": "PANTONE 15-4704 TPG",
                "name": "Pigeon",
                "book": 2,
                "page": 99,
                "row": 5,
                "hexCode": "#A5B0AB",
                "rgb": "165,176,171"
            },
            {
                "nr": "PANTONE 15-5205 TPG",
                "name": "Aqua Gray",
                "book": 2,
                "page": 99,
                "row": 6,
                "hexCode": "#A2AEA8",
                "rgb": "161,174,167"
            },
            {
                "nr": "PANTONE 16-5808 TPG",
                "name": "Iceberg Green",
                "book": 2,
                "page": 99,
                "row": 7,
                "hexCode": "#899C92",
                "rgb": "136,156,145"
            }
        ],
        [
            {
                "nr": "PANTONE 16-5807 TPG",
                "name": "Lily Pad",
                "book": 2,
                "page": 100,
                "row": 1,
                "hexCode": "#7E8F84",
                "rgb": "126,142,132"
            },
            {
                "nr": "PANTONE 17-6009 TPG",
                "name": "Laurel Wreath",
                "book": 2,
                "page": 100,
                "row": 2,
                "hexCode": "#627169",
                "rgb": "98,113,105"
            },
            {
                "nr": "PANTONE 18-5611 TPG",
                "name": "Dark Forest",
                "book": 2,
                "page": 100,
                "row": 3,
                "hexCode": "#586864",
                "rgb": "88,104,99"
            },
            {
                "nr": "PANTONE 18-6011 TPG",
                "name": "Duck Green",
                "book": 2,
                "page": 100,
                "row": 4,
                "hexCode": "#5A6F65",
                "rgb": "89,110,100"
            },
            {
                "nr": "PANTONE 18-5913 TPG",
                "name": "Garden Topiary",
                "book": 2,
                "page": 100,
                "row": 5,
                "hexCode": "#425751",
                "rgb": "65,86,80"
            },
            {
                "nr": "PANTONE 19-5411 TPG",
                "name": "Trekking Green",
                "book": 2,
                "page": 100,
                "row": 6,
                "hexCode": "#465B57",
                "rgb": "69,90,86"
            },
            {
                "nr": "PANTONE 19-5220 TPG",
                "name": "Botanical Garden",
                "book": 2,
                "page": 100,
                "row": 7,
                "hexCode": "#30524E",
                "rgb": "47,81,78"
            }
        ],
        [
            {
                "nr": "PANTONE 19-5004 TPG",
                "name": "Urban Chic",
                "book": 2,
                "page": 101,
                "row": 1,
                "hexCode": "#525A5B",
                "rgb": "81,90,91"
            },
            {
                "nr": "PANTONE 19-4906 TPG",
                "name": "Green Gables",
                "book": 2,
                "page": 101,
                "row": 2,
                "hexCode": "#445252",
                "rgb": "67,81,82"
            },
            {
                "nr": "PANTONE 19-5212 TPG",
                "name": "Darkest Spruce",
                "book": 2,
                "page": 101,
                "row": 3,
                "hexCode": "#424F50",
                "rgb": "66,79,80"
            },
            {
                "nr": "PANTONE 19-5406 TPG",
                "name": "Pine Grove",
                "book": 2,
                "page": 101,
                "row": 4,
                "hexCode": "#384A46",
                "rgb": "56,74,70"
            },
            {
                "nr": "PANTONE 19-5918 TPG",
                "name": "Mountain View",
                "book": 2,
                "page": 101,
                "row": 5,
                "hexCode": "#3B4B40",
                "rgb": "58,74,63"
            },
            {
                "nr": "PANTONE 19-6110 TPG",
                "name": "Deep Forest",
                "book": 2,
                "page": 101,
                "row": 6,
                "hexCode": "#444C48",
                "rgb": "67,75,71"
            },
            {
                "nr": "PANTONE 19-5350 TPG",
                "name": "Scarab",
                "book": 2,
                "page": 101,
                "row": 7,
                "hexCode": "#3B4443",
                "rgb": "58,67,66"
            }
        ],
        [
            {
                "nr": "PANTONE 17-6212 TPG",
                "name": "Sea Spray",
                "book": 2,
                "page": 102,
                "row": 1,
                "hexCode": "#6E7F71",
                "rgb": "109,127,112"
            },
            {
                "nr": "PANTONE 18-5806 TPG",
                "name": "Agave Green",
                "book": 2,
                "page": 102,
                "row": 2,
                "hexCode": "#6E746E",
                "rgb": "109,115,109"
            },
            {
                "nr": "PANTONE 19-0309 TPG",
                "name": "Thyme",
                "book": 2,
                "page": 102,
                "row": 3,
                "hexCode": "#596258",
                "rgb": "88,97,88"
            },
            {
                "nr": "PANTONE 19-5621 TPG",
                "name": "Cilantro",
                "book": 2,
                "page": 102,
                "row": 4,
                "hexCode": "#4F5D56",
                "rgb": "78,92,85"
            },
            {
                "nr": "PANTONE 19-5914 TPG",
                "name": "Jungle Green",
                "book": 2,
                "page": 102,
                "row": 5,
                "hexCode": "#4B5B58",
                "rgb": "75,90,87"
            },
            {
                "nr": "PANTONE 19-5920 TPG",
                "name": "Pineneedle",
                "book": 2,
                "page": 102,
                "row": 6,
                "hexCode": "#455851",
                "rgb": "69,88,81"
            },
            {
                "nr": "PANTONE 19-5917 TPG",
                "name": "Sycamore",
                "book": 2,
                "page": 102,
                "row": 7,
                "hexCode": "#3B4D47",
                "rgb": "58,77,70"
            }
        ],
        [
            {
                "nr": "PANTONE 18-0515 TPG",
                "name": "Dusty Olive",
                "book": 2,
                "page": 103,
                "row": 1,
                "hexCode": "#6C6B61",
                "rgb": "107,106,96"
            },
            {
                "nr": "PANTONE 19-0312 TPG",
                "name": "Beetle",
                "book": 2,
                "page": 103,
                "row": 2,
                "hexCode": "#5F645A",
                "rgb": "95,99,89"
            },
            {
                "nr": "PANTONE 19-0307 TPG",
                "name": "Climbing Ivy",
                "book": 2,
                "page": 103,
                "row": 3,
                "hexCode": "#50544E",
                "rgb": "80,84,77"
            },
            {
                "nr": "PANTONE 19-0417 TPG",
                "name": "Kombu Green",
                "book": 2,
                "page": 103,
                "row": 4,
                "hexCode": "#434D40",
                "rgb": "66,76,64"
            },
            {
                "nr": "PANTONE 19-0315 TPG",
                "name": "Black Forest",
                "book": 2,
                "page": 103,
                "row": 5,
                "hexCode": "#4B5A4C",
                "rgb": "75,90,76"
            },
            {
                "nr": "PANTONE 19-0415 TPG",
                "name": "Duffel Bag",
                "book": 2,
                "page": 103,
                "row": 6,
                "hexCode": "#464C46",
                "rgb": "70,76,69"
            },
            {
                "nr": "PANTONE 19-0509 TPG",
                "name": "Rosin",
                "book": 2,
                "page": 103,
                "row": 7,
                "hexCode": "#444841",
                "rgb": "67,71,65"
            }
        ],
        [
            {
                "nr": "PANTONE 17-0610 TPG",
                "name": "Laurel Oak",
                "book": 2,
                "page": 104,
                "row": 1,
                "hexCode": "#8B8A7B",
                "rgb": "139,138,123"
            },
            {
                "nr": "PANTONE 17-0613 TPG",
                "name": "Vetiver",
                "book": 2,
                "page": 104,
                "row": 2,
                "hexCode": "#808174",
                "rgb": "127,128,115"
            },
            {
                "nr": "PANTONE 18-0312 TPG",
                "name": "Deep Lichen Green",
                "book": 2,
                "page": 104,
                "row": 3,
                "hexCode": "#6C7160",
                "rgb": "107,112,95"
            },
            {
                "nr": "PANTONE 18-0517 TPG",
                "name": "Tea Leaf",
                "book": 2,
                "page": 104,
                "row": 4,
                "hexCode": "#6A6960",
                "rgb": "106,105,96"
            },
            {
                "nr": "PANTONE 18-0514 TPG",
                "name": "Mulled Basil",
                "book": 2,
                "page": 104,
                "row": 5,
                "hexCode": "#6D6F67",
                "rgb": "109,111,103"
            },
            {
                "nr": "PANTONE 19-0404 TPG",
                "name": "Kambaba",
                "book": 2,
                "page": 104,
                "row": 6,
                "hexCode": "#54574F",
                "rgb": "84,87,79"
            },
            {
                "nr": "PANTONE 19-0406 TPG",
                "name": "Chimera",
                "book": 2,
                "page": 104,
                "row": 7,
                "hexCode": "#555551",
                "rgb": "85,85,81"
            }
        ],
        [
            {
                "nr": "PANTONE 15-6304 TPG",
                "name": "Pussywillow Gray",
                "book": 2,
                "page": 105,
                "row": 1,
                "hexCode": "#ADAEA3",
                "rgb": "173,174,163"
            },
            {
                "nr": "PANTONE 16-0207 TPG",
                "name": "London Fog",
                "book": 2,
                "page": 105,
                "row": 2,
                "hexCode": "#9F9E91",
                "rgb": "159,158,145"
            },
            {
                "nr": "PANTONE 15-4306 TPG",
                "name": "Belgian Block",
                "book": 2,
                "page": 105,
                "row": 3,
                "hexCode": "#A5AAA6",
                "rgb": "165,170,166"
            },
            {
                "nr": "PANTONE 16-5904 TPG",
                "name": "Wrought Iron",
                "book": 2,
                "page": 105,
                "row": 4,
                "hexCode": "#939F9A",
                "rgb": "147,159,154"
            },
            {
                "nr": "PANTONE 17-0209 TPG",
                "name": "Forest Fog",
                "book": 2,
                "page": 105,
                "row": 5,
                "hexCode": "#91938D",
                "rgb": "145,147,141"
            },
            {
                "nr": "PANTONE 17-0208 TPG",
                "name": "Dried Sage",
                "book": 2,
                "page": 105,
                "row": 6,
                "hexCode": "#8E8E84",
                "rgb": "142,142,132"
            },
            {
                "nr": "PANTONE 18-0516 TPG",
                "name": "Smokey Olive",
                "book": 2,
                "page": 105,
                "row": 7,
                "hexCode": "#6E6D63",
                "rgb": "110,109,99"
            }
        ],
        [
            {
                "nr": "PANTONE 12-5703 TPG",
                "name": "Frosted Mint",
                "book": 2,
                "page": 106,
                "row": 1,
                "hexCode": "#D1D8CE",
                "rgb": "209,216,206"
            },
            {
                "nr": "PANTONE 13-6105 TPG",
                "name": "Celadon Tint",
                "book": 2,
                "page": 106,
                "row": 2,
                "hexCode": "#CDD0C0",
                "rgb": "204,208,192"
            },
            {
                "nr": "PANTONE 15-5704 TPG",
                "name": "Mineral Gray",
                "book": 2,
                "page": 106,
                "row": 3,
                "hexCode": "#B0B6AE",
                "rgb": "175,181,174"
            },
            {
                "nr": "PANTONE 16-0110 TPG",
                "name": "Desert Sage",
                "book": 2,
                "page": 106,
                "row": 4,
                "hexCode": "#A3AC9B",
                "rgb": "163,171,155"
            },
            {
                "nr": "PANTONE 16-6008 TPG",
                "name": "Seagrass",
                "book": 2,
                "page": 106,
                "row": 5,
                "hexCode": "#92998C",
                "rgb": "145,152,139"
            },
            {
                "nr": "PANTONE 16-0213 TPG",
                "name": "Tea",
                "book": 2,
                "page": 106,
                "row": 6,
                "hexCode": "#999E88",
                "rgb": "153,158,135"
            },
            {
                "nr": "PANTONE 17-6206 TPG",
                "name": "Shadow",
                "book": 2,
                "page": 106,
                "row": 7,
                "hexCode": "#899087",
                "rgb": "136,144,134"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0210 TPG",
                "name": "Fog Green",
                "book": 2,
                "page": 107,
                "row": 1,
                "hexCode": "#BFCAB4",
                "rgb": "191,202,179"
            },
            {
                "nr": "PANTONE 13-0212 TPG",
                "name": "Tender Greens",
                "book": 2,
                "page": 107,
                "row": 2,
                "hexCode": "#C5CFB4",
                "rgb": "197,207,179"
            },
            {
                "nr": "PANTONE 14-0114 TPG",
                "name": "Celadon Green",
                "book": 2,
                "page": 107,
                "row": 3,
                "hexCode": "#B1C1A2",
                "rgb": "177,192,162"
            },
            {
                "nr": "PANTONE 15-6313 TPG",
                "name": "Laurel Green",
                "book": 2,
                "page": 107,
                "row": 4,
                "hexCode": "#A8B89E",
                "rgb": "168,184,158"
            },
            {
                "nr": "PANTONE 15-6310 TPG",
                "name": "Swamp",
                "book": 2,
                "page": 107,
                "row": 5,
                "hexCode": "#ABB49C",
                "rgb": "170,180,155"
            },
            {
                "nr": "PANTONE 15-6414 TPG",
                "name": "Reseda",
                "book": 2,
                "page": 107,
                "row": 6,
                "hexCode": "#A1B194",
                "rgb": "160,176,148"
            },
            {
                "nr": "PANTONE 17-6323 TPG",
                "name": "Hedge Green",
                "book": 2,
                "page": 107,
                "row": 7,
                "hexCode": "#718775",
                "rgb": "112,135,116"
            }
        ],
        [
            {
                "nr": "PANTONE 14-6007 TPG",
                "name": "Sea Foam",
                "book": 2,
                "page": 108,
                "row": 1,
                "hexCode": "#B6C1B4",
                "rgb": "181,193,179"
            },
            {
                "nr": "PANTONE 15-6315 TPG",
                "name": "Smoke Green",
                "book": 2,
                "page": 108,
                "row": 2,
                "hexCode": "#A3BCA6",
                "rgb": "163,188,165"
            },
            {
                "nr": "PANTONE 16-0220 TPG",
                "name": "Mistletoe",
                "book": 2,
                "page": 108,
                "row": 3,
                "hexCode": "#859E7F",
                "rgb": "133,158,127"
            },
            {
                "nr": "PANTONE 16-0224 TPG",
                "name": "Green Eyes",
                "book": 2,
                "page": 108,
                "row": 4,
                "hexCode": "#7C9670",
                "rgb": "124,150,112"
            },
            {
                "nr": "PANTONE 17-0119 TPG",
                "name": "Turf Green",
                "book": 2,
                "page": 108,
                "row": 5,
                "hexCode": "#6E8E6E",
                "rgb": "110,142,110"
            },
            {
                "nr": "PANTONE 17-0220 TPG",
                "name": "Watercress",
                "book": 2,
                "page": 108,
                "row": 6,
                "hexCode": "#728D6C",
                "rgb": "113,140,108"
            },
            {
                "nr": "PANTONE 18-0121 TPG",
                "name": "Elm Green",
                "book": 2,
                "page": 108,
                "row": 7,
                "hexCode": "#5C795E",
                "rgb": "92,120,94"
            }
        ],
        [
            {
                "nr": "PANTONE 13-6006 TPG",
                "name": "Almost Aqua",
                "book": 2,
                "page": 109,
                "row": 1,
                "hexCode": "#CFD6C5",
                "rgb": "207,214,197"
            },
            {
                "nr": "PANTONE 13-6208 TPG",
                "name": "Bok Choy",
                "book": 2,
                "page": 109,
                "row": 2,
                "hexCode": "#B7C9B1",
                "rgb": "182,200,177"
            },
            {
                "nr": "PANTONE 16-6318 TPG",
                "name": "Mineral Green",
                "book": 2,
                "page": 109,
                "row": 3,
                "hexCode": "#7B9F7F",
                "rgb": "123,159,126"
            },
            {
                "nr": "PANTONE 18-6216 TPG",
                "name": "Comfrey",
                "book": 2,
                "page": 109,
                "row": 4,
                "hexCode": "#617E6B",
                "rgb": "97,126,107"
            },
            {
                "nr": "PANTONE 17-5912 TPG",
                "name": "Dark Ivy",
                "book": 2,
                "page": 109,
                "row": 5,
                "hexCode": "#5F7C6B",
                "rgb": "95,123,106"
            },
            {
                "nr": "PANTONE 18-6114 TPG",
                "name": "Myrtle",
                "book": 2,
                "page": 109,
                "row": 6,
                "hexCode": "#577263",
                "rgb": "86,113,98"
            },
            {
                "nr": "PANTONE 18-6018 TPG",
                "name": "Foliage Green",
                "book": 2,
                "page": 109,
                "row": 7,
                "hexCode": "#457462",
                "rgb": "68,116,98"
            }
        ],
        [
            {
                "nr": "PANTONE 13-6007 TPG",
                "name": "Spray",
                "book": 2,
                "page": 110,
                "row": 1,
                "hexCode": "#BDD5BC",
                "rgb": "189,212,188"
            },
            {
                "nr": "PANTONE 13-0116 TPG",
                "name": "Pastel Green",
                "book": 2,
                "page": 110,
                "row": 2,
                "hexCode": "#B2D4B2",
                "rgb": "177,212,177"
            },
            {
                "nr": "PANTONE 14-6316 TPG",
                "name": "Sprucestone",
                "book": 2,
                "page": 110,
                "row": 3,
                "hexCode": "#9CBF9D",
                "rgb": "155,191,156"
            },
            {
                "nr": "PANTONE 14-6324 TPG",
                "name": "Peapod",
                "book": 2,
                "page": 110,
                "row": 4,
                "hexCode": "#7FB185",
                "rgb": "127,176,132"
            },
            {
                "nr": "PANTONE 14-6327 TPG",
                "name": "Zephyr Green",
                "book": 2,
                "page": 110,
                "row": 5,
                "hexCode": "#7CB284",
                "rgb": "124,178,132"
            },
            {
                "nr": "PANTONE 16-6324 TPG",
                "name": "Jadesheen",
                "book": 2,
                "page": 110,
                "row": 6,
                "hexCode": "#72A078",
                "rgb": "114,160,119"
            },
            {
                "nr": "PANTONE 15-6432 TPG",
                "name": "Shamrock",
                "book": 2,
                "page": 110,
                "row": 7,
                "hexCode": "#77A670",
                "rgb": "118,165,111"
            }
        ],
        [
            {
                "nr": "PANTONE 15-6114 TPG",
                "name": "Hemlock",
                "book": 2,
                "page": 111,
                "row": 1,
                "hexCode": "#96C1A4",
                "rgb": "149,192,163"
            },
            {
                "nr": "PANTONE 14-6319 TPG",
                "name": "Meadow",
                "book": 2,
                "page": 111,
                "row": 2,
                "hexCode": "#8DBB96",
                "rgb": "141,187,150"
            },
            {
                "nr": "PANTONE 15-6120 TPG",
                "name": "Ming",
                "book": 2,
                "page": 111,
                "row": 3,
                "hexCode": "#80B58F",
                "rgb": "127,180,143"
            },
            {
                "nr": "PANTONE 15-6322 TPG",
                "name": "Light Grass Green",
                "book": 2,
                "page": 111,
                "row": 4,
                "hexCode": "#7DB289",
                "rgb": "125,177,136"
            },
            {
                "nr": "PANTONE 14-6329 TPG",
                "name": "Absinthe Green",
                "book": 2,
                "page": 111,
                "row": 5,
                "hexCode": "#72B584",
                "rgb": "114,181,131"
            },
            {
                "nr": "PANTONE 16-6329 TPG",
                "name": "Peppermint",
                "book": 2,
                "page": 111,
                "row": 6,
                "hexCode": "#719F72",
                "rgb": "112,158,113"
            },
            {
                "nr": "PANTONE 18-0110 TPG",
                "name": "English Ivy",
                "book": 2,
                "page": 111,
                "row": 7,
                "hexCode": "#688863",
                "rgb": "103,136,99"
            }
        ],
        [
            {
                "nr": "PANTONE 14-0127 TPG",
                "name": "Greengage",
                "book": 2,
                "page": 112,
                "row": 1,
                "hexCode": "#89BF8D",
                "rgb": "137,190,140"
            },
            {
                "nr": "PANTONE 16-6127 TPG",
                "name": "Greenbriar",
                "book": 2,
                "page": 112,
                "row": 2,
                "hexCode": "#4E9D73",
                "rgb": "78,156,115"
            },
            {
                "nr": "PANTONE 18-6022 TPG",
                "name": "Leprechaun",
                "book": 2,
                "page": 112,
                "row": 3,
                "hexCode": "#438A68",
                "rgb": "66,137,104"
            },
            {
                "nr": "PANTONE 17-6030 TPG",
                "name": "Jelly Bean",
                "book": 2,
                "page": 112,
                "row": 4,
                "hexCode": "#008959",
                "rgb": "0,137,89"
            },
            {
                "nr": "PANTONE 18-6030 TPG",
                "name": "Jolly Green",
                "book": 2,
                "page": 112,
                "row": 5,
                "hexCode": "#00804F",
                "rgb": "0,128,79"
            },
            {
                "nr": "PANTONE 19-6026 TPG",
                "name": "Verdant Green",
                "book": 2,
                "page": 112,
                "row": 6,
                "hexCode": "#376C59",
                "rgb": "55,108,89"
            },
            {
                "nr": "PANTONE 18-6024 TPG",
                "name": "Amazon",
                "book": 2,
                "page": 112,
                "row": 7,
                "hexCode": "#347757",
                "rgb": "52,119,86"
            }
        ],
        [
            {
                "nr": "PANTONE 17-6154 TPG",
                "name": "Green Bee",
                "book": 2,
                "page": 113,
                "row": 1,
                "hexCode": "#189255",
                "rgb": "24,146,85"
            },
            {
                "nr": "PANTONE 18-6028 TPG",
                "name": "Rolling Hills",
                "book": 2,
                "page": 113,
                "row": 2,
                "hexCode": "#318051",
                "rgb": "49,128,81"
            },
            {
                "nr": "PANTONE 18-6031 TPG",
                "name": "First Tee",
                "book": 2,
                "page": 113,
                "row": 3,
                "hexCode": "#007C4C",
                "rgb": "0,124,76"
            },
            {
                "nr": "PANTONE 18-6033 TPG",
                "name": "Green Tambourine",
                "book": 2,
                "page": 113,
                "row": 4,
                "hexCode": "#007E57",
                "rgb": "0,126,87"
            },
            {
                "nr": "PANTONE 18-6026 TPG",
                "name": "Abundant Green",
                "book": 2,
                "page": 113,
                "row": 5,
                "hexCode": "#236E48",
                "rgb": "35,110,72"
            },
            {
                "nr": "PANTONE 19-6350 TPG",
                "name": "Formal Garden",
                "book": 2,
                "page": 113,
                "row": 6,
                "hexCode": "#355B3E",
                "rgb": "53,91,62"
            },
            {
                "nr": "PANTONE 19-6027 TPG",
                "name": "Green Jacket",
                "book": 2,
                "page": 113,
                "row": 7,
                "hexCode": "#22674E",
                "rgb": "34,103,78"
            }
        ],
        [
            {
                "nr": "PANTONE 14-0121 TPG",
                "name": "Nile Green",
                "book": 2,
                "page": 114,
                "row": 1,
                "hexCode": "#A8C798",
                "rgb": "168,199,152"
            },
            {
                "nr": "PANTONE 13-0221 TPG",
                "name": "Pistachio Green",
                "book": 2,
                "page": 114,
                "row": 2,
                "hexCode": "#ABD79E",
                "rgb": "170,214,157"
            },
            {
                "nr": "PANTONE 13-0117 TPG",
                "name": "Green Ash",
                "book": 2,
                "page": 114,
                "row": 3,
                "hexCode": "#9FDBAD",
                "rgb": "159,219,173"
            },
            {
                "nr": "PANTONE 14-6340 TPG",
                "name": "Spring Bouquet",
                "book": 2,
                "page": 114,
                "row": 4,
                "hexCode": "#6FCF90",
                "rgb": "111,207,143"
            },
            {
                "nr": "PANTONE 15-6340 TPG",
                "name": "Irish Green",
                "book": 2,
                "page": 114,
                "row": 5,
                "hexCode": "#4BC37D",
                "rgb": "75,194,124"
            },
            {
                "nr": "PANTONE 16-6240 TPG",
                "name": "Island Green",
                "book": 2,
                "page": 114,
                "row": 6,
                "hexCode": "#34B36E",
                "rgb": "52,179,110"
            },
            {
                "nr": "PANTONE 15-5534 TPG",
                "name": "Bright Green",
                "book": 2,
                "page": 114,
                "row": 7,
                "hexCode": "#00A366",
                "rgb": "0,163,102"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0225 TPG",
                "name": "Patina Green",
                "book": 2,
                "page": 115,
                "row": 1,
                "hexCode": "#AFE6AE",
                "rgb": "175,230,174"
            },
            {
                "nr": "PANTONE 14-0156 TPG",
                "name": "Summer Green",
                "book": 2,
                "page": 115,
                "row": 2,
                "hexCode": "#79D381",
                "rgb": "120,210,128"
            },
            {
                "nr": "PANTONE 16-6444 TPG",
                "name": "Poison Green",
                "book": 2,
                "page": 115,
                "row": 3,
                "hexCode": "#52B86A",
                "rgb": "82,183,106"
            },
            {
                "nr": "PANTONE 16-6340 TPG",
                "name": "Classic Green",
                "book": 2,
                "page": 115,
                "row": 4,
                "hexCode": "#3DB24E",
                "rgb": "60,177,77"
            },
            {
                "nr": "PANTONE 16-6339 TPG",
                "name": "Vibrant Green",
                "book": 2,
                "page": 115,
                "row": 5,
                "hexCode": "#5DAB63",
                "rgb": "92,170,99"
            },
            {
                "nr": "PANTONE 16-6138 TPG",
                "name": "Kelly Green",
                "book": 2,
                "page": 115,
                "row": 6,
                "hexCode": "#339E66",
                "rgb": "50,158,102"
            },
            {
                "nr": "PANTONE 17-6153 TPG",
                "name": "Fern Green",
                "book": 2,
                "page": 115,
                "row": 7,
                "hexCode": "#009252",
                "rgb": "0,146,82"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0220 TPG",
                "name": "Paradise Green",
                "book": 2,
                "page": 116,
                "row": 1,
                "hexCode": "#B4E7A0",
                "rgb": "179,230,159"
            },
            {
                "nr": "PANTONE 15-0146 TPG",
                "name": "Green Flash",
                "book": 2,
                "page": 116,
                "row": 2,
                "hexCode": "#75CB5D",
                "rgb": "116,202,92"
            },
            {
                "nr": "PANTONE 15-0545 TPG",
                "name": "Jasmine Green",
                "book": 2,
                "page": 116,
                "row": 3,
                "hexCode": "#83CB53",
                "rgb": "131,202,82"
            },
            {
                "nr": "PANTONE 16-0237 TPG",
                "name": "Foliage",
                "book": 2,
                "page": 116,
                "row": 4,
                "hexCode": "#78A059",
                "rgb": "120,159,89"
            },
            {
                "nr": "PANTONE 17-0145 TPG",
                "name": "Online Lime",
                "book": 2,
                "page": 116,
                "row": 5,
                "hexCode": "#548A4D",
                "rgb": "84,138,77"
            },
            {
                "nr": "PANTONE 18-0135 TPG",
                "name": "Treetop",
                "book": 2,
                "page": 116,
                "row": 6,
                "hexCode": "#547144",
                "rgb": "83,112,67"
            },
            {
                "nr": "PANTONE 19-0230 TPG",
                "name": "Garden Green",
                "book": 2,
                "page": 116,
                "row": 7,
                "hexCode": "#526447",
                "rgb": "81,100,71"
            }
        ],
        [
            {
                "nr": "PANTONE 14-0232 TPG",
                "name": "Jade Lime",
                "book": 2,
                "page": 117,
                "row": 1,
                "hexCode": "#A3C77C",
                "rgb": "163,199,124"
            },
            {
                "nr": "PANTONE 14-0244 TPG",
                "name": "Bright Lime Green",
                "book": 2,
                "page": 117,
                "row": 2,
                "hexCode": "#A2C66C",
                "rgb": "162,198,107"
            },
            {
                "nr": "PANTONE 15-0343 TPG",
                "name": "Greenery",
                "book": 2,
                "page": 117,
                "row": 3,
                "hexCode": "#92B558",
                "rgb": "146,180,87"
            },
            {
                "nr": "PANTONE 17-0235 TPG",
                "name": "Piquant Green",
                "book": 2,
                "page": 117,
                "row": 4,
                "hexCode": "#73945B",
                "rgb": "114,148,91"
            },
            {
                "nr": "PANTONE 18-0119 TPG",
                "name": "Willow Bough",
                "book": 2,
                "page": 117,
                "row": 5,
                "hexCode": "#59744D",
                "rgb": "89,116,76"
            },
            {
                "nr": "PANTONE 18-0107 TPG",
                "name": "Kale",
                "book": 2,
                "page": 117,
                "row": 6,
                "hexCode": "#60774F",
                "rgb": "96,118,79"
            },
            {
                "nr": "PANTONE 18-0330 TPG",
                "name": "Twist Of Lime",
                "book": 2,
                "page": 117,
                "row": 7,
                "hexCode": "#5C6C44",
                "rgb": "91,108,67"
            }
        ],
        [
            {
                "nr": "PANTONE 17-0232 TPG",
                "name": "Salted Lime",
                "book": 2,
                "page": 118,
                "row": 1,
                "hexCode": "#658946",
                "rgb": "101,137,70"
            },
            {
                "nr": "PANTONE 17-0340 TPG",
                "name": "Kelp Forest",
                "book": 2,
                "page": 118,
                "row": 2,
                "hexCode": "#758C3E",
                "rgb": "117,140,62"
            },
            {
                "nr": "PANTONE 17-0345 TPG",
                "name": "Minced Herb",
                "book": 2,
                "page": 118,
                "row": 3,
                "hexCode": "#6B8845",
                "rgb": "107,136,69"
            },
            {
                "nr": "PANTONE 18-0230 TPG",
                "name": "Banana Palm",
                "book": 2,
                "page": 118,
                "row": 4,
                "hexCode": "#577340",
                "rgb": "87,115,64"
            },
            {
                "nr": "PANTONE 18-0226 TPG",
                "name": "Courtyard",
                "book": 2,
                "page": 118,
                "row": 5,
                "hexCode": "#4A673E",
                "rgb": "74,103,62"
            },
            {
                "nr": "PANTONE 19-0231 TPG",
                "name": "Forest Elf",
                "book": 2,
                "page": 118,
                "row": 6,
                "hexCode": "#48603F",
                "rgb": "72,96,63"
            },
            {
                "nr": "PANTONE 19-0220 TPG",
                "name": "Douglas Fir",
                "book": 2,
                "page": 118,
                "row": 7,
                "hexCode": "#445540",
                "rgb": "68,85,64"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0331 TPG",
                "name": "Sap Green",
                "book": 2,
                "page": 119,
                "row": 1,
                "hexCode": "#B1CE86",
                "rgb": "177,206,133"
            },
            {
                "nr": "PANTONE 15-0341 TPG",
                "name": "Parrot Green",
                "book": 2,
                "page": 119,
                "row": 2,
                "hexCode": "#8FB258",
                "rgb": "143,177,87"
            },
            {
                "nr": "PANTONE 16-0235 TPG",
                "name": "Kiwi",
                "book": 2,
                "page": 119,
                "row": 3,
                "hexCode": "#7BAC5F",
                "rgb": "123,172,94"
            },
            {
                "nr": "PANTONE 17-0133 TPG",
                "name": "Fluorite Green",
                "book": 2,
                "page": 119,
                "row": 4,
                "hexCode": "#679058",
                "rgb": "103,143,87"
            },
            {
                "nr": "PANTONE 17-0230 TPG",
                "name": "Forest Green",
                "book": 2,
                "page": 119,
                "row": 5,
                "hexCode": "#6A8E58",
                "rgb": "105,142,87"
            },
            {
                "nr": "PANTONE 18-0130 TPG",
                "name": "Cactus",
                "book": 2,
                "page": 119,
                "row": 6,
                "hexCode": "#5C7547",
                "rgb": "92,116,70"
            },
            {
                "nr": "PANTONE 18-0125 TPG",
                "name": "Artichoke Green",
                "book": 2,
                "page": 119,
                "row": 7,
                "hexCode": "#557451",
                "rgb": "85,115,81"
            }
        ],
        [
            {
                "nr": "PANTONE 14-0123 TPG",
                "name": "Arcadian Green",
                "book": 2,
                "page": 120,
                "row": 1,
                "hexCode": "#9DC68F",
                "rgb": "156,198,143"
            },
            {
                "nr": "PANTONE 15-6437 TPG",
                "name": "Grass Green",
                "book": 2,
                "page": 120,
                "row": 2,
                "hexCode": "#7DB46C",
                "rgb": "124,180,108"
            },
            {
                "nr": "PANTONE 15-6442 TPG",
                "name": "Bud Green",
                "book": 2,
                "page": 120,
                "row": 3,
                "hexCode": "#7CB469",
                "rgb": "123,180,104"
            },
            {
                "nr": "PANTONE 15-6428 TPG",
                "name": "Green Tea",
                "book": 2,
                "page": 120,
                "row": 4,
                "hexCode": "#81A76D",
                "rgb": "128,167,109"
            },
            {
                "nr": "PANTONE 16-0123 TPG",
                "name": "Tendril",
                "book": 2,
                "page": 120,
                "row": 5,
                "hexCode": "#8BA475",
                "rgb": "139,163,117"
            },
            {
                "nr": "PANTONE 17-0215 TPG",
                "name": "Aspen Green",
                "book": 2,
                "page": 120,
                "row": 6,
                "hexCode": "#7F9B7A",
                "rgb": "127,154,122"
            },
            {
                "nr": "PANTONE 16-0228 TPG",
                "name": "Jade Green",
                "book": 2,
                "page": 120,
                "row": 7,
                "hexCode": "#779768",
                "rgb": "118,151,104"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0324 TPG",
                "name": "Lettuce Green",
                "book": 2,
                "page": 121,
                "row": 1,
                "hexCode": "#BED390",
                "rgb": "189,210,144"
            },
            {
                "nr": "PANTONE 14-0226 TPG",
                "name": "Opaline Green",
                "book": 2,
                "page": 121,
                "row": 2,
                "hexCode": "#A2C583",
                "rgb": "162,196,131"
            },
            {
                "nr": "PANTONE 16-0233 TPG",
                "name": "Meadow Green",
                "book": 2,
                "page": 121,
                "row": 3,
                "hexCode": "#72975E",
                "rgb": "113,150,93"
            },
            {
                "nr": "PANTONE 17-6229 TPG",
                "name": "Medium Green",
                "book": 2,
                "page": 121,
                "row": 4,
                "hexCode": "#55875F",
                "rgb": "84,135,95"
            },
            {
                "nr": "PANTONE 18-6330 TPG",
                "name": "Juniper",
                "book": 2,
                "page": 121,
                "row": 5,
                "hexCode": "#4D7653",
                "rgb": "76,118,82"
            },
            {
                "nr": "PANTONE 18-6320 TPG",
                "name": "Fairway",
                "book": 2,
                "page": 121,
                "row": 6,
                "hexCode": "#4E7459",
                "rgb": "78,115,88"
            },
            {
                "nr": "PANTONE 17-6333 TPG",
                "name": "Mint Green",
                "book": 2,
                "page": 121,
                "row": 7,
                "hexCode": "#598459",
                "rgb": "88,132,88"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0215 TPG",
                "name": "Reed",
                "book": 2,
                "page": 122,
                "row": 1,
                "hexCode": "#C1D3A5",
                "rgb": "192,211,165"
            },
            {
                "nr": "PANTONE 12-0317 TPG",
                "name": "Gleam",
                "book": 2,
                "page": 122,
                "row": 2,
                "hexCode": "#C1D2B1",
                "rgb": "192,210,177"
            },
            {
                "nr": "PANTONE 14-0115 TPG",
                "name": "Foam Green",
                "book": 2,
                "page": 122,
                "row": 3,
                "hexCode": "#B1C5A0",
                "rgb": "177,197,159"
            },
            {
                "nr": "PANTONE 15-6423 TPG",
                "name": "Forest Shade",
                "book": 2,
                "page": 122,
                "row": 4,
                "hexCode": "#8FAC7F",
                "rgb": "142,171,127"
            },
            {
                "nr": "PANTONE 16-6116 TPG",
                "name": "Shale Green",
                "book": 2,
                "page": 122,
                "row": 5,
                "hexCode": "#739279",
                "rgb": "115,146,120"
            },
            {
                "nr": "PANTONE 17-6319 TPG",
                "name": "Kashmir",
                "book": 2,
                "page": 122,
                "row": 6,
                "hexCode": "#6D8D71",
                "rgb": "109,141,112"
            },
            {
                "nr": "PANTONE 17-6219 TPG",
                "name": "Deep Grass Green",
                "book": 2,
                "page": 122,
                "row": 7,
                "hexCode": "#5A876E",
                "rgb": "89,135,110"
            }
        ],
        [
            {
                "nr": "PANTONE 16-6216 TPG",
                "name": "Basil",
                "book": 2,
                "page": 123,
                "row": 1,
                "hexCode": "#829E86",
                "rgb": "130,158,133"
            },
            {
                "nr": "PANTONE 17-0210 TPG",
                "name": "Loden Frost",
                "book": 2,
                "page": 123,
                "row": 2,
                "hexCode": "#758E77",
                "rgb": "116,141,119"
            },
            {
                "nr": "PANTONE 18-0108 TPG",
                "name": "Dill",
                "book": 2,
                "page": 123,
                "row": 3,
                "hexCode": "#5D7050",
                "rgb": "93,112,80"
            },
            {
                "nr": "PANTONE 18-0117 TPG",
                "name": "Vineyard Green",
                "book": 2,
                "page": 123,
                "row": 4,
                "hexCode": "#5E7558",
                "rgb": "94,116,88"
            },
            {
                "nr": "PANTONE 19-6311 TPG",
                "name": "Greener Pastures",
                "book": 2,
                "page": 123,
                "row": 5,
                "hexCode": "#3C5542",
                "rgb": "60,85,65"
            },
            {
                "nr": "PANTONE 19-6050 TPG",
                "name": "Eden",
                "book": 2,
                "page": 123,
                "row": 6,
                "hexCode": "#355743",
                "rgb": "52,86,67"
            },
            {
                "nr": "PANTONE 19-5513 TPG",
                "name": "Dark Green",
                "book": 2,
                "page": 123,
                "row": 7,
                "hexCode": "#425951",
                "rgb": "65,89,80"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0106 TPG",
                "name": "Meadow Mist",
                "book": 2,
                "page": 124,
                "row": 1,
                "hexCode": "#D4DDC4",
                "rgb": "212,220,195"
            },
            {
                "nr": "PANTONE 12-0109 TPG",
                "name": "Ambrosia",
                "book": 2,
                "page": 124,
                "row": 2,
                "hexCode": "#D7E7CA",
                "rgb": "214,230,202"
            },
            {
                "nr": "PANTONE 13-0111 TPG",
                "name": "Seacrest",
                "book": 2,
                "page": 124,
                "row": 3,
                "hexCode": "#BFCFB1",
                "rgb": "191,207,176"
            },
            {
                "nr": "PANTONE 15-6317 TPG",
                "name": "Quiet Green",
                "book": 2,
                "page": 124,
                "row": 4,
                "hexCode": "#9FC09A",
                "rgb": "159,191,154"
            },
            {
                "nr": "PANTONE 15-6316 TPG",
                "name": "Fair Green",
                "book": 2,
                "page": 124,
                "row": 5,
                "hexCode": "#8FAF8A",
                "rgb": "143,175,137"
            },
            {
                "nr": "PANTONE 17-0123 TPG",
                "name": "Stone Green",
                "book": 2,
                "page": 124,
                "row": 6,
                "hexCode": "#68926D",
                "rgb": "103,146,109"
            },
            {
                "nr": "PANTONE 17-5923 TPG",
                "name": "Pine Green",
                "book": 2,
                "page": 124,
                "row": 7,
                "hexCode": "#4D826C",
                "rgb": "76,129,108"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0313 TPG",
                "name": "Seafoam Green",
                "book": 2,
                "page": 125,
                "row": 1,
                "hexCode": "#C9D9B3",
                "rgb": "201,216,178"
            },
            {
                "nr": "PANTONE 12-0312 TPG",
                "name": "Lime Cream",
                "book": 2,
                "page": 125,
                "row": 2,
                "hexCode": "#DCE9BE",
                "rgb": "219,232,190"
            },
            {
                "nr": "PANTONE 13-0319 TPG",
                "name": "Shadow Lime",
                "book": 2,
                "page": 125,
                "row": 3,
                "hexCode": "#D3E09C",
                "rgb": "210,223,155"
            },
            {
                "nr": "PANTONE 12-0322 TPG",
                "name": "Butterfly",
                "book": 2,
                "page": 125,
                "row": 4,
                "hexCode": "#C9DDA2",
                "rgb": "201,221,161"
            },
            {
                "nr": "PANTONE 13-0317 TPG",
                "name": "Lily Green",
                "book": 2,
                "page": 125,
                "row": 5,
                "hexCode": "#C4D19A",
                "rgb": "195,209,153"
            },
            {
                "nr": "PANTONE 14-0223 TPG",
                "name": "Nile",
                "book": 2,
                "page": 125,
                "row": 6,
                "hexCode": "#B2BB88",
                "rgb": "177,187,135"
            },
            {
                "nr": "PANTONE 15-0531 TPG",
                "name": "Sweet Pea",
                "book": 2,
                "page": 125,
                "row": 7,
                "hexCode": "#A5AD70",
                "rgb": "165,172,111"
            }
        ],
        [
            {
                "nr": "PANTONE 12-6207 TPG",
                "name": "Frost",
                "book": 2,
                "page": 126,
                "row": 1,
                "hexCode": "#DDE4D9",
                "rgb": "221,228,217"
            },
            {
                "nr": "PANTONE 12-0315 TPG",
                "name": "White Jade",
                "book": 2,
                "page": 126,
                "row": 2,
                "hexCode": "#D4DBB3",
                "rgb": "211,219,178"
            },
            {
                "nr": "PANTONE 14-0217 TPG",
                "name": "Seedling",
                "book": 2,
                "page": 126,
                "row": 3,
                "hexCode": "#BDC9A1",
                "rgb": "188,200,160"
            },
            {
                "nr": "PANTONE 14-0116 TPG",
                "name": "Margarita",
                "book": 2,
                "page": 126,
                "row": 4,
                "hexCode": "#B7C791",
                "rgb": "183,198,145"
            },
            {
                "nr": "PANTONE 15-0628 TPG",
                "name": "Leek Green",
                "book": 2,
                "page": 126,
                "row": 5,
                "hexCode": "#BBB17A",
                "rgb": "186,177,121"
            },
            {
                "nr": "PANTONE 15-0525 TPG",
                "name": "Weeping Willow",
                "book": 2,
                "page": 126,
                "row": 6,
                "hexCode": "#B2AD7F",
                "rgb": "177,172,127"
            },
            {
                "nr": "PANTONE 16-0430 TPG",
                "name": "Fern",
                "book": 2,
                "page": 126,
                "row": 7,
                "hexCode": "#989E68",
                "rgb": "151,158,104"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0435 TPG",
                "name": "Daiquiri Green",
                "book": 2,
                "page": 127,
                "row": 1,
                "hexCode": "#CCD77F",
                "rgb": "204,215,127"
            },
            {
                "nr": "PANTONE 13-0442 TPG",
                "name": "Green Glow",
                "book": 2,
                "page": 127,
                "row": 2,
                "hexCode": "#B3CD69",
                "rgb": "178,205,105"
            },
            {
                "nr": "PANTONE 14-0445 TPG",
                "name": "Bright Chartreuse",
                "book": 2,
                "page": 127,
                "row": 3,
                "hexCode": "#B1BC55",
                "rgb": "176,187,85"
            },
            {
                "nr": "PANTONE 16-0435 TPG",
                "name": "Dark Citron",
                "book": 2,
                "page": 127,
                "row": 4,
                "hexCode": "#9CA954",
                "rgb": "155,169,84"
            },
            {
                "nr": "PANTONE 15-0332 TPG",
                "name": "Leaf Green",
                "book": 2,
                "page": 127,
                "row": 5,
                "hexCode": "#A6B172",
                "rgb": "165,176,114"
            },
            {
                "nr": "PANTONE 15-0336 TPG",
                "name": "Herbal Garden",
                "book": 2,
                "page": 127,
                "row": 6,
                "hexCode": "#93AA63",
                "rgb": "146,170,99"
            },
            {
                "nr": "PANTONE 16-0439 TPG",
                "name": "Spinach Green",
                "book": 2,
                "page": 127,
                "row": 7,
                "hexCode": "#909E56",
                "rgb": "144,158,86"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0535 TPG",
                "name": "Sharp Green",
                "book": 2,
                "page": 128,
                "row": 1,
                "hexCode": "#C9E881",
                "rgb": "201,232,128"
            },
            {
                "nr": "PANTONE 13-0443 TPG",
                "name": "Love Bird",
                "book": 2,
                "page": 128,
                "row": 2,
                "hexCode": "#C5DE48",
                "rgb": "197,222,72"
            },
            {
                "nr": "PANTONE 14-0340 TPG",
                "name": "Acid Lime",
                "book": 2,
                "page": 128,
                "row": 3,
                "hexCode": "#BDE040",
                "rgb": "189,224,64"
            },
            {
                "nr": "PANTONE 13-0550 TPG",
                "name": "Lime Punch",
                "book": 2,
                "page": 128,
                "row": 4,
                "hexCode": "#BFD641",
                "rgb": "190,213,65"
            },
            {
                "nr": "PANTONE 14-0446 TPG",
                "name": "Tender Shoots",
                "book": 2,
                "page": 128,
                "row": 5,
                "hexCode": "#BBCF51",
                "rgb": "187,206,80"
            },
            {
                "nr": "PANTONE 16-0230 TPG",
                "name": "Macaw Green",
                "book": 2,
                "page": 128,
                "row": 6,
                "hexCode": "#9EB749",
                "rgb": "158,182,73"
            },
            {
                "nr": "PANTONE 17-0336 TPG",
                "name": "Peridot",
                "book": 2,
                "page": 128,
                "row": 7,
                "hexCode": "#7D944C",
                "rgb": "125,148,75"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0643 TPG",
                "name": "Yellow Plum",
                "book": 2,
                "page": 129,
                "row": 1,
                "hexCode": "#D7DA58",
                "rgb": "215,218,88"
            },
            {
                "nr": "PANTONE 15-0549 TPG",
                "name": "Fragile Sprout",
                "book": 2,
                "page": 129,
                "row": 2,
                "hexCode": "#BEC338",
                "rgb": "190,195,56"
            },
            {
                "nr": "PANTONE 14-0443 TPG",
                "name": "Kiwi Colada",
                "book": 2,
                "page": 129,
                "row": 3,
                "hexCode": "#C1D236",
                "rgb": "193,210,54"
            },
            {
                "nr": "PANTONE 14-0442 TPG",
                "name": "White Grape",
                "book": 2,
                "page": 129,
                "row": 4,
                "hexCode": "#ACC453",
                "rgb": "172,196,83"
            },
            {
                "nr": "PANTONE 16-0229 TPG",
                "name": "Titanite",
                "book": 2,
                "page": 129,
                "row": 5,
                "hexCode": "#90AD3B",
                "rgb": "144,173,59"
            },
            {
                "nr": "PANTONE 17-0332 TPG",
                "name": "Spindle Tree",
                "book": 2,
                "page": 129,
                "row": 6,
                "hexCode": "#6D9140",
                "rgb": "109,145,64"
            },
            {
                "nr": "PANTONE 18-0323 TPG",
                "name": "Campsite",
                "book": 2,
                "page": 129,
                "row": 7,
                "hexCode": "#627B4F",
                "rgb": "98,123,79"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0741 TPG",
                "name": "Sunny Lime",
                "book": 2,
                "page": 130,
                "row": 1,
                "hexCode": "#DEE884",
                "rgb": "221,231,132"
            },
            {
                "nr": "PANTONE 12-0530 TPG",
                "name": "Charlock",
                "book": 2,
                "page": 130,
                "row": 2,
                "hexCode": "#E4E58F",
                "rgb": "228,228,143"
            },
            {
                "nr": "PANTONE 13-0645 TPG",
                "name": "Limeade",
                "book": 2,
                "page": 130,
                "row": 3,
                "hexCode": "#D2D864",
                "rgb": "210,215,99"
            },
            {
                "nr": "PANTONE 13-0650 TPG",
                "name": "Sulphur Spring",
                "book": 2,
                "page": 130,
                "row": 4,
                "hexCode": "#D2D431",
                "rgb": "209,212,48"
            },
            {
                "nr": "PANTONE 13-0651 TPG",
                "name": "Evening Primrose",
                "book": 2,
                "page": 130,
                "row": 5,
                "hexCode": "#D3E031",
                "rgb": "210,224,48"
            },
            {
                "nr": "PANTONE 13-0540 TPG",
                "name": "Wild Lime",
                "book": 2,
                "page": 130,
                "row": 6,
                "hexCode": "#CAD66A",
                "rgb": "201,213,106"
            },
            {
                "nr": "PANTONE 14-0452 TPG",
                "name": "Lime Green",
                "book": 2,
                "page": 130,
                "row": 7,
                "hexCode": "#A0C23D",
                "rgb": "160,194,60"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0710 TPG",
                "name": "Tender Yellow",
                "book": 2,
                "page": 131,
                "row": 1,
                "hexCode": "#ECEBB1",
                "rgb": "236,235,177"
            },
            {
                "nr": "PANTONE 11-0618 TPG",
                "name": "Wax Yellow",
                "book": 2,
                "page": 131,
                "row": 2,
                "hexCode": "#EEECB0",
                "rgb": "238,236,176"
            },
            {
                "nr": "PANTONE 12-0426 TPG",
                "name": "Mellow Green",
                "book": 2,
                "page": 131,
                "row": 3,
                "hexCode": "#D2D591",
                "rgb": "210,213,144"
            },
            {
                "nr": "PANTONE 12-0525 TPG",
                "name": "Luminary Green",
                "book": 2,
                "page": 131,
                "row": 4,
                "hexCode": "#E2E9A6",
                "rgb": "226,233,165"
            },
            {
                "nr": "PANTONE 12-0520 TPG",
                "name": "Pale Lime Yellow",
                "book": 2,
                "page": 131,
                "row": 5,
                "hexCode": "#E7E9A5",
                "rgb": "231,233,165"
            },
            {
                "nr": "PANTONE 13-0530 TPG",
                "name": "Lime Sherbet",
                "book": 2,
                "page": 131,
                "row": 6,
                "hexCode": "#CED48C",
                "rgb": "206,211,139"
            },
            {
                "nr": "PANTONE 13-0532 TPG",
                "name": "Celery Green",
                "book": 2,
                "page": 131,
                "row": 7,
                "hexCode": "#C5CA79",
                "rgb": "197,202,121"
            }
        ],
        [
            {
                "nr": "PANTONE 11-4803 TPG",
                "name": "Ice",
                "book": 2,
                "page": 132,
                "row": 1,
                "hexCode": "#E2E7DD",
                "rgb": "226,231,221"
            },
            {
                "nr": "PANTONE 12-0108 TPG",
                "name": "Canary Green",
                "book": 2,
                "page": 132,
                "row": 2,
                "hexCode": "#D8E0CC",
                "rgb": "216,223,203"
            },
            {
                "nr": "PANTONE 11-0410 TPG",
                "name": "Sylvan Green",
                "book": 2,
                "page": 132,
                "row": 3,
                "hexCode": "#E6E7CB",
                "rgb": "230,231,202"
            },
            {
                "nr": "PANTONE 12-0607 TPG",
                "name": "Green Essence",
                "book": 2,
                "page": 132,
                "row": 4,
                "hexCode": "#E6E6C7",
                "rgb": "230,230,198"
            },
            {
                "nr": "PANTONE 12-0521 TPG",
                "name": "Young Wheat",
                "book": 2,
                "page": 132,
                "row": 5,
                "hexCode": "#E0E0A7",
                "rgb": "224,223,166"
            },
            {
                "nr": "PANTONE 12-0524 TPG",
                "name": "Citron",
                "book": 2,
                "page": 132,
                "row": 6,
                "hexCode": "#DEDE9D",
                "rgb": "222,221,156"
            },
            {
                "nr": "PANTONE 11-0623 TPG",
                "name": "Yellow Pear",
                "book": 2,
                "page": 132,
                "row": 7,
                "hexCode": "#EFEC9C",
                "rgb": "239,235,155"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0205 TPG",
                "name": "Glass Green",
                "book": 2,
                "page": 133,
                "row": 1,
                "hexCode": "#EEEDD1",
                "rgb": "238,236,209"
            },
            {
                "nr": "PANTONE 13-0614 TPG",
                "name": "Garden Glade",
                "book": 2,
                "page": 133,
                "row": 2,
                "hexCode": "#DFD8A8",
                "rgb": "222,216,167"
            },
            {
                "nr": "PANTONE 12-0418 TPG",
                "name": "Hay",
                "book": 2,
                "page": 133,
                "row": 3,
                "hexCode": "#D6CEA5",
                "rgb": "213,205,164"
            },
            {
                "nr": "PANTONE 13-0720 TPG",
                "name": "Custard",
                "book": 2,
                "page": 133,
                "row": 4,
                "hexCode": "#E8D78D",
                "rgb": "232,215,141"
            },
            {
                "nr": "PANTONE 13-0333 TPG",
                "name": "Lima Bean",
                "book": 2,
                "page": 133,
                "row": 5,
                "hexCode": "#DDD38E",
                "rgb": "221,210,141"
            },
            {
                "nr": "PANTONE 14-0827 TPG",
                "name": "Dusky Citron",
                "book": 2,
                "page": 133,
                "row": 6,
                "hexCode": "#E3CC81",
                "rgb": "227,204,129"
            },
            {
                "nr": "PANTONE 13-0739 TPG",
                "name": "Cream Gold",
                "book": 2,
                "page": 133,
                "row": 7,
                "hexCode": "#DFC468",
                "rgb": "223,196,104"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0609 TPG",
                "name": "Ethereal Green",
                "book": 2,
                "page": 134,
                "row": 1,
                "hexCode": "#EDECCC",
                "rgb": "236,235,203"
            },
            {
                "nr": "PANTONE 11-0617 TPG",
                "name": "Transparent Yellow",
                "book": 2,
                "page": 134,
                "row": 2,
                "hexCode": "#F6EDC3",
                "rgb": "246,237,195"
            },
            {
                "nr": "PANTONE 13-0624 TPG",
                "name": "Golden Mist",
                "book": 2,
                "page": 134,
                "row": 3,
                "hexCode": "#D5CF91",
                "rgb": "213,207,145"
            },
            {
                "nr": "PANTONE 12-0619 TPG",
                "name": "Dusty Yellow",
                "book": 2,
                "page": 134,
                "row": 4,
                "hexCode": "#D5D09D",
                "rgb": "213,208,157"
            },
            {
                "nr": "PANTONE 14-0627 TPG",
                "name": "Shadow Green",
                "book": 2,
                "page": 134,
                "row": 5,
                "hexCode": "#CFC385",
                "rgb": "206,195,133"
            },
            {
                "nr": "PANTONE 14-0826 TPG",
                "name": "Pampas",
                "book": 2,
                "page": 134,
                "row": 6,
                "hexCode": "#D1BC80",
                "rgb": "209,188,128"
            },
            {
                "nr": "PANTONE 16-0737 TPG",
                "name": "Burnished Gold",
                "book": 2,
                "page": 134,
                "row": 7,
                "hexCode": "#A89B59",
                "rgb": "168,155,89"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0633 TPG",
                "name": "Chardonnay",
                "book": 2,
                "page": 135,
                "row": 1,
                "hexCode": "#E1D791",
                "rgb": "224,215,145"
            },
            {
                "nr": "PANTONE 14-0636 TPG",
                "name": "Muted Lime",
                "book": 2,
                "page": 135,
                "row": 2,
                "hexCode": "#D4CC7D",
                "rgb": "212,204,125"
            },
            {
                "nr": "PANTONE 13-0632 TPG",
                "name": "Endive",
                "book": 2,
                "page": 135,
                "row": 3,
                "hexCode": "#D7D181",
                "rgb": "215,209,129"
            },
            {
                "nr": "PANTONE 15-0636 TPG",
                "name": "Golden Green",
                "book": 2,
                "page": 135,
                "row": 4,
                "hexCode": "#BAB066",
                "rgb": "186,176,102"
            },
            {
                "nr": "PANTONE 16-0632 TPG",
                "name": "Willow",
                "book": 2,
                "page": 135,
                "row": 5,
                "hexCode": "#9D905A",
                "rgb": "157,144,90"
            },
            {
                "nr": "PANTONE 17-0636 TPG",
                "name": "Green Moss",
                "book": 2,
                "page": 135,
                "row": 6,
                "hexCode": "#8A7F53",
                "rgb": "137,127,82"
            },
            {
                "nr": "PANTONE 18-0627 TPG",
                "name": "Fir Green",
                "book": 2,
                "page": 135,
                "row": 7,
                "hexCode": "#6B5F3E",
                "rgb": "107,95,61"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0626 TPG",
                "name": "Lemon Grass",
                "book": 2,
                "page": 136,
                "row": 1,
                "hexCode": "#D9D390",
                "rgb": "217,211,144"
            },
            {
                "nr": "PANTONE 12-0633 TPG",
                "name": "Canary Yellow",
                "book": 2,
                "page": 136,
                "row": 2,
                "hexCode": "#DEDB7F",
                "rgb": "222,219,127"
            },
            {
                "nr": "PANTONE 14-0647 TPG",
                "name": "Celery",
                "book": 2,
                "page": 136,
                "row": 3,
                "hexCode": "#D0C456",
                "rgb": "208,196,86"
            },
            {
                "nr": "PANTONE 15-0646 TPG",
                "name": "Warm Olive",
                "book": 2,
                "page": 136,
                "row": 4,
                "hexCode": "#C8B643",
                "rgb": "199,182,67"
            },
            {
                "nr": "PANTONE 15-0643 TPG",
                "name": "Cress Green",
                "book": 2,
                "page": 136,
                "row": 5,
                "hexCode": "#BBAD47",
                "rgb": "187,173,71"
            },
            {
                "nr": "PANTONE 16-0840 TPG",
                "name": "Antique Moss",
                "book": 2,
                "page": 136,
                "row": 6,
                "hexCode": "#BEA437",
                "rgb": "189,163,55"
            },
            {
                "nr": "PANTONE 16-0639 TPG",
                "name": "Golden Olive",
                "book": 2,
                "page": 136,
                "row": 7,
                "hexCode": "#AC994C",
                "rgb": "172,152,76"
            }
        ],
        [
            {
                "nr": "PANTONE 15-0533 TPG",
                "name": "Linden Green",
                "book": 2,
                "page": 137,
                "row": 1,
                "hexCode": "#C5BF73",
                "rgb": "197,190,115"
            },
            {
                "nr": "PANTONE 15-0543 TPG",
                "name": "Apple Green",
                "book": 2,
                "page": 137,
                "row": 2,
                "hexCode": "#C4BA46",
                "rgb": "195,186,69"
            },
            {
                "nr": "PANTONE 15-0544 TPG",
                "name": "Grenoble Green",
                "book": 2,
                "page": 137,
                "row": 3,
                "hexCode": "#A5A728",
                "rgb": "165,167,40"
            },
            {
                "nr": "PANTONE 16-0540 TPG",
                "name": "Oasis",
                "book": 2,
                "page": 137,
                "row": 4,
                "hexCode": "#A5A451",
                "rgb": "164,164,80"
            },
            {
                "nr": "PANTONE 16-0543 TPG",
                "name": "Golden Lime",
                "book": 2,
                "page": 137,
                "row": 5,
                "hexCode": "#9C9A40",
                "rgb": "156,153,64"
            },
            {
                "nr": "PANTONE 16-0545 TPG",
                "name": "Split Pea",
                "book": 2,
                "page": 137,
                "row": 6,
                "hexCode": "#A29F4B",
                "rgb": "161,159,75"
            },
            {
                "nr": "PANTONE 16-0550 TPG",
                "name": "Lentil Sprout",
                "book": 2,
                "page": 137,
                "row": 7,
                "hexCode": "#AEA84E",
                "rgb": "174,167,77"
            }
        ],
        [
            {
                "nr": "PANTONE 15-0548 TPG",
                "name": "Citronelle",
                "book": 2,
                "page": 138,
                "row": 1,
                "hexCode": "#B6AC38",
                "rgb": "181,172,55"
            },
            {
                "nr": "PANTONE 15-0542 TPG",
                "name": "Pear Liqueur",
                "book": 2,
                "page": 138,
                "row": 2,
                "hexCode": "#A4A02A",
                "rgb": "164,160,42"
            },
            {
                "nr": "PANTONE 16-0541 TPG",
                "name": "Green Envy",
                "book": 2,
                "page": 138,
                "row": 3,
                "hexCode": "#A4963A",
                "rgb": "164,150,58"
            },
            {
                "nr": "PANTONE 16-0640 TPG",
                "name": "Avocado Oil",
                "book": 2,
                "page": 138,
                "row": 4,
                "hexCode": "#A09040",
                "rgb": "160,144,64"
            },
            {
                "nr": "PANTONE 16-0544 TPG",
                "name": "Camping Gear",
                "book": 2,
                "page": 138,
                "row": 5,
                "hexCode": "#9A933C",
                "rgb": "154,147,60"
            },
            {
                "nr": "PANTONE 16-0634 TPG",
                "name": "Evergreen Sprig",
                "book": 2,
                "page": 138,
                "row": 6,
                "hexCode": "#90894C",
                "rgb": "144,137,76"
            },
            {
                "nr": "PANTONE 18-0537 TPG",
                "name": "Golden Cypress",
                "book": 2,
                "page": 138,
                "row": 7,
                "hexCode": "#82853A",
                "rgb": "130,133,58"
            }
        ],
        [
            {
                "nr": "PANTONE 15-0538 TPG",
                "name": "Green Oasis",
                "book": 2,
                "page": 139,
                "row": 1,
                "hexCode": "#ADB25B",
                "rgb": "172,177,90"
            },
            {
                "nr": "PANTONE 16-0436 TPG",
                "name": "Pickled Pepper",
                "book": 2,
                "page": 139,
                "row": 2,
                "hexCode": "#A0A653",
                "rgb": "160,166,83"
            },
            {
                "nr": "PANTONE 16-0532 TPG",
                "name": "Moss",
                "book": 2,
                "page": 139,
                "row": 3,
                "hexCode": "#9B9B5B",
                "rgb": "154,154,91"
            },
            {
                "nr": "PANTONE 18-0540 TPG",
                "name": "Lima Bean Green",
                "book": 2,
                "page": 139,
                "row": 4,
                "hexCode": "#89923C",
                "rgb": "137,146,60"
            },
            {
                "nr": "PANTONE 18-0530 TPG",
                "name": "Going Green",
                "book": 2,
                "page": 139,
                "row": 5,
                "hexCode": "#7F8545",
                "rgb": "127,133,69"
            },
            {
                "nr": "PANTONE 17-0530 TPG",
                "name": "Guacamole",
                "book": 2,
                "page": 139,
                "row": 6,
                "hexCode": "#7D7F44",
                "rgb": "125,126,67"
            },
            {
                "nr": "PANTONE 18-0538 TPG",
                "name": "Woodbine",
                "book": 2,
                "page": 139,
                "row": 7,
                "hexCode": "#828245",
                "rgb": "129,129,68"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0522 TPG",
                "name": "Pale Green",
                "book": 2,
                "page": 140,
                "row": 1,
                "hexCode": "#D0CF96",
                "rgb": "208,207,149"
            },
            {
                "nr": "PANTONE 14-0434 TPG",
                "name": "Green Banana",
                "book": 2,
                "page": 140,
                "row": 2,
                "hexCode": "#B6BB71",
                "rgb": "181,186,113"
            },
            {
                "nr": "PANTONE 15-0535 TPG",
                "name": "Palm",
                "book": 2,
                "page": 140,
                "row": 3,
                "hexCode": "#B1B160",
                "rgb": "176,177,96"
            },
            {
                "nr": "PANTONE 16-0531 TPG",
                "name": "Perfect Pear",
                "book": 2,
                "page": 140,
                "row": 4,
                "hexCode": "#949049",
                "rgb": "148,144,73"
            },
            {
                "nr": "PANTONE 17-0529 TPG",
                "name": "Cardamom Seed",
                "book": 2,
                "page": 140,
                "row": 5,
                "hexCode": "#7A7A40",
                "rgb": "122,122,64"
            },
            {
                "nr": "PANTONE 18-0428 TPG",
                "name": "Peat Moss",
                "book": 2,
                "page": 140,
                "row": 6,
                "hexCode": "#717244",
                "rgb": "113,114,68"
            },
            {
                "nr": "PANTONE 18-0529 TPG",
                "name": "Sphagnum",
                "book": 2,
                "page": 140,
                "row": 7,
                "hexCode": "#6C6F4E",
                "rgb": "108,111,78"
            }
        ],
        [
            {
                "nr": "PANTONE 14-0425 TPG",
                "name": "Beechnut",
                "book": 2,
                "page": 141,
                "row": 1,
                "hexCode": "#BDBE88",
                "rgb": "189,189,135"
            },
            {
                "nr": "PANTONE 15-0326 TPG",
                "name": "Tarragon",
                "book": 2,
                "page": 141,
                "row": 2,
                "hexCode": "#A3B07D",
                "rgb": "163,176,124"
            },
            {
                "nr": "PANTONE 17-0324 TPG",
                "name": "Epsom",
                "book": 2,
                "page": 141,
                "row": 3,
                "hexCode": "#829367",
                "rgb": "129,146,103"
            },
            {
                "nr": "PANTONE 17-0330 TPG",
                "name": "Turtle Green",
                "book": 2,
                "page": 141,
                "row": 4,
                "hexCode": "#808956",
                "rgb": "127,137,85"
            },
            {
                "nr": "PANTONE 18-0324 TPG",
                "name": "Calliste Green",
                "book": 2,
                "page": 141,
                "row": 5,
                "hexCode": "#7B8059",
                "rgb": "122,127,89"
            },
            {
                "nr": "PANTONE 18-0332 TPG",
                "name": "Grasshopper",
                "book": 2,
                "page": 141,
                "row": 6,
                "hexCode": "#717F4F",
                "rgb": "113,127,79"
            },
            {
                "nr": "PANTONE 18-0435 TPG",
                "name": "Calla Green",
                "book": 2,
                "page": 141,
                "row": 7,
                "hexCode": "#707647",
                "rgb": "111,117,70"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0608 TPG",
                "name": "Aloe Wash",
                "book": 2,
                "page": 142,
                "row": 1,
                "hexCode": "#CFD2B2",
                "rgb": "206,209,178"
            },
            {
                "nr": "PANTONE 14-0216 TPG",
                "name": "Lint",
                "book": 2,
                "page": 142,
                "row": 2,
                "hexCode": "#B7BE9A",
                "rgb": "183,189,154"
            },
            {
                "nr": "PANTONE 16-0421 TPG",
                "name": "Sage",
                "book": 2,
                "page": 142,
                "row": 3,
                "hexCode": "#8F936F",
                "rgb": "143,147,111"
            },
            {
                "nr": "PANTONE 17-0525 TPG",
                "name": "Mosstone",
                "book": 2,
                "page": 142,
                "row": 4,
                "hexCode": "#8B8D68",
                "rgb": "138,140,104"
            },
            {
                "nr": "PANTONE 18-0525 TPG",
                "name": "Iguana",
                "book": 2,
                "page": 142,
                "row": 5,
                "hexCode": "#80865E",
                "rgb": "128,133,93"
            },
            {
                "nr": "PANTONE 18-0228 TPG",
                "name": "Pesto",
                "book": 2,
                "page": 142,
                "row": 6,
                "hexCode": "#5F6945",
                "rgb": "94,104,69"
            },
            {
                "nr": "PANTONE 18-0328 TPG",
                "name": "Cedar Green",
                "book": 2,
                "page": 142,
                "row": 7,
                "hexCode": "#677249",
                "rgb": "103,114,73"
            }
        ],
        [
            {
                "nr": "PANTONE 15-0523 TPG",
                "name": "Winter Pear",
                "book": 2,
                "page": 143,
                "row": 1,
                "hexCode": "#AEB385",
                "rgb": "174,179,132"
            },
            {
                "nr": "PANTONE 16-0526 TPG",
                "name": "Cedar",
                "book": 2,
                "page": 143,
                "row": 2,
                "hexCode": "#979269",
                "rgb": "151,145,105"
            },
            {
                "nr": "PANTONE 17-0535 TPG",
                "name": "Green Olive",
                "book": 2,
                "page": 143,
                "row": 3,
                "hexCode": "#8E8B5B",
                "rgb": "141,139,91"
            },
            {
                "nr": "PANTONE 18-0430 TPG",
                "name": "Avocado",
                "book": 2,
                "page": 143,
                "row": 4,
                "hexCode": "#6B6947",
                "rgb": "106,105,70"
            },
            {
                "nr": "PANTONE 18-0426 TPG",
                "name": "Capulet Olive",
                "book": 2,
                "page": 143,
                "row": 5,
                "hexCode": "#6D7057",
                "rgb": "109,111,86"
            },
            {
                "nr": "PANTONE 18-0220 TPG",
                "name": "Mayfly",
                "book": 2,
                "page": 143,
                "row": 6,
                "hexCode": "#6B6D50",
                "rgb": "107,109,80"
            },
            {
                "nr": "PANTONE 18-0527 TPG",
                "name": "Olive Branch",
                "book": 2,
                "page": 143,
                "row": 7,
                "hexCode": "#73714E",
                "rgb": "115,112,78"
            }
        ],
        [
            {
                "nr": "PANTONE 17-0115 TPG",
                "name": "Oil Green",
                "book": 2,
                "page": 144,
                "row": 1,
                "hexCode": "#808A73",
                "rgb": "128,137,115"
            },
            {
                "nr": "PANTONE 18-0316 TPG",
                "name": "Olivine",
                "book": 2,
                "page": 144,
                "row": 2,
                "hexCode": "#69725F",
                "rgb": "104,114,94"
            },
            {
                "nr": "PANTONE 18-0317 TPG",
                "name": "Bronze Green",
                "book": 2,
                "page": 144,
                "row": 3,
                "hexCode": "#58624E",
                "rgb": "88,97,78"
            },
            {
                "nr": "PANTONE 19-0323 TPG",
                "name": "Chive",
                "book": 2,
                "page": 144,
                "row": 4,
                "hexCode": "#565D47",
                "rgb": "86,92,70"
            },
            {
                "nr": "PANTONE 18-0322 TPG",
                "name": "Cypress",
                "book": 2,
                "page": 144,
                "row": 5,
                "hexCode": "#5B624C",
                "rgb": "91,97,75"
            },
            {
                "nr": "PANTONE 18-0523 TPG",
                "name": "Winter Moss",
                "book": 2,
                "page": 144,
                "row": 6,
                "hexCode": "#60614C",
                "rgb": "95,96,76"
            },
            {
                "nr": "PANTONE 19-0419 TPG",
                "name": "Rifle Green",
                "book": 2,
                "page": 144,
                "row": 7,
                "hexCode": "#515848",
                "rgb": "80,87,72"
            }
        ],
        [
            {
                "nr": "PANTONE 18-0422 TPG",
                "name": "Loden Green",
                "book": 2,
                "page": 145,
                "row": 1,
                "hexCode": "#70775D",
                "rgb": "112,119,93"
            },
            {
                "nr": "PANTONE 18-0420 TPG",
                "name": "Four Leaf Clover",
                "book": 2,
                "page": 145,
                "row": 2,
                "hexCode": "#626959",
                "rgb": "97,105,88"
            },
            {
                "nr": "PANTONE 18-0521 TPG",
                "name": "Burnt Olive",
                "book": 2,
                "page": 145,
                "row": 3,
                "hexCode": "#706E5C",
                "rgb": "112,109,91"
            },
            {
                "nr": "PANTONE 19-0512 TPG",
                "name": "Ivy Green",
                "book": 2,
                "page": 145,
                "row": 4,
                "hexCode": "#605E51",
                "rgb": "96,94,81"
            },
            {
                "nr": "PANTONE 19-0510 TPG",
                "name": "Kalamata",
                "book": 2,
                "page": 145,
                "row": 5,
                "hexCode": "#67645A",
                "rgb": "103,100,89"
            },
            {
                "nr": "PANTONE 19-0515 TPG",
                "name": "Olive Night",
                "book": 2,
                "page": 145,
                "row": 6,
                "hexCode": "#5E5C50",
                "rgb": "94,91,80"
            },
            {
                "nr": "PANTONE 19-0413 TPG",
                "name": "Deep Depths",
                "book": 2,
                "page": 145,
                "row": 7,
                "hexCode": "#53554C",
                "rgb": "83,84,76"
            }
        ],
        [
            {
                "nr": "PANTONE 18-0617 TPG",
                "name": "Covert Green",
                "book": 2,
                "page": 146,
                "row": 1,
                "hexCode": "#7E7764",
                "rgb": "126,119,100"
            },
            {
                "nr": "PANTONE 17-0517 TPG",
                "name": "Dusky Green",
                "book": 2,
                "page": 146,
                "row": 2,
                "hexCode": "#7D7465",
                "rgb": "124,115,100"
            },
            {
                "nr": "PANTONE 19-0622 TPG",
                "name": "Military Olive",
                "book": 2,
                "page": 146,
                "row": 3,
                "hexCode": "#69614C",
                "rgb": "104,96,75"
            },
            {
                "nr": "PANTONE 19-0516 TPG",
                "name": "Dark Olive",
                "book": 2,
                "page": 146,
                "row": 4,
                "hexCode": "#605A49",
                "rgb": "95,90,72"
            },
            {
                "nr": "PANTONE 19-0403 TPG",
                "name": "Sea Turtle",
                "book": 2,
                "page": 146,
                "row": 5,
                "hexCode": "#645F55",
                "rgb": "100,94,84"
            },
            {
                "nr": "PANTONE 19-0511 TPG",
                "name": "Grape Leaf",
                "book": 2,
                "page": 146,
                "row": 6,
                "hexCode": "#5A5B4F",
                "rgb": "89,90,78"
            },
            {
                "nr": "PANTONE 19-0414 TPG",
                "name": "Forest Night",
                "book": 2,
                "page": 146,
                "row": 7,
                "hexCode": "#4C4F47",
                "rgb": "76,78,71"
            }
        ],
        [
            {
                "nr": "PANTONE 16-0518 TPG",
                "name": "Gray Green",
                "book": 2,
                "page": 147,
                "row": 1,
                "hexCode": "#A29D7D",
                "rgb": "162,156,124"
            },
            {
                "nr": "PANTONE 17-0625 TPG",
                "name": "Boa",
                "book": 2,
                "page": 147,
                "row": 2,
                "hexCode": "#948B6A",
                "rgb": "147,138,105"
            },
            {
                "nr": "PANTONE 17-0627 TPG",
                "name": "Dried Herb",
                "book": 2,
                "page": 147,
                "row": 3,
                "hexCode": "#848163",
                "rgb": "131,129,99"
            },
            {
                "nr": "PANTONE 18-0622 TPG",
                "name": "Olive Drab",
                "book": 2,
                "page": 147,
                "row": 4,
                "hexCode": "#777352",
                "rgb": "118,115,81"
            },
            {
                "nr": "PANTONE 18-0825 TPG",
                "name": "Nutria",
                "book": 2,
                "page": 147,
                "row": 5,
                "hexCode": "#75694C",
                "rgb": "116,105,76"
            },
            {
                "nr": "PANTONE 18-0629 TPG",
                "name": "Lizard",
                "book": 2,
                "page": 147,
                "row": 6,
                "hexCode": "#7C7354",
                "rgb": "123,114,83"
            },
            {
                "nr": "PANTONE 18-0625 TPG",
                "name": "Martini Olive",
                "book": 2,
                "page": 147,
                "row": 7,
                "hexCode": "#766F57",
                "rgb": "118,111,86"
            }
        ],
        [
            {
                "nr": "PANTONE 14-0418 TPG",
                "name": "Bog",
                "book": 2,
                "page": 148,
                "row": 1,
                "hexCode": "#B7B996",
                "rgb": "183,185,149"
            },
            {
                "nr": "PANTONE 15-0318 TPG",
                "name": "Sage Green",
                "book": 2,
                "page": 148,
                "row": 2,
                "hexCode": "#AFAC87",
                "rgb": "174,171,134"
            },
            {
                "nr": "PANTONE 16-0713 TPG",
                "name": "Slate Green",
                "book": 2,
                "page": 148,
                "row": 3,
                "hexCode": "#A1977D",
                "rgb": "161,150,124"
            },
            {
                "nr": "PANTONE 17-0619 TPG",
                "name": "Overland Trek",
                "book": 2,
                "page": 148,
                "row": 4,
                "hexCode": "#8C8575",
                "rgb": "140,133,117"
            },
            {
                "nr": "PANTONE 16-0613 TPG",
                "name": "Elm",
                "book": 2,
                "page": 148,
                "row": 5,
                "hexCode": "#A5A088",
                "rgb": "164,160,136"
            },
            {
                "nr": "PANTONE 17-0620 TPG",
                "name": "Aloe",
                "book": 2,
                "page": 148,
                "row": 6,
                "hexCode": "#857D65",
                "rgb": "133,124,101"
            },
            {
                "nr": "PANTONE 17-0618 TPG",
                "name": "Mermaid",
                "book": 2,
                "page": 148,
                "row": 7,
                "hexCode": "#817D6A",
                "rgb": "128,125,106"
            }
        ],
        [
            {
                "nr": "PANTONE 15-0522 TPG",
                "name": "Pale Olive Green",
                "book": 2,
                "page": 149,
                "row": 1,
                "hexCode": "#B7AC8A",
                "rgb": "182,171,138"
            },
            {
                "nr": "PANTONE 16-1110 TPG",
                "name": "Olive Gray",
                "book": 2,
                "page": 149,
                "row": 2,
                "hexCode": "#A3997A",
                "rgb": "163,153,122"
            },
            {
                "nr": "PANTONE 16-1108 TPG",
                "name": "Twill",
                "book": 2,
                "page": 149,
                "row": 3,
                "hexCode": "#A79E83",
                "rgb": "167,158,131"
            },
            {
                "nr": "PANTONE 16-1118 TPG",
                "name": "Sponge",
                "book": 2,
                "page": 149,
                "row": 4,
                "hexCode": "#A79C7D",
                "rgb": "167,155,125"
            },
            {
                "nr": "PANTONE 17-0630 TPG",
                "name": "Tree House",
                "book": 2,
                "page": 149,
                "row": 5,
                "hexCode": "#9B8F77",
                "rgb": "155,143,119"
            },
            {
                "nr": "PANTONE 17-1019 TPG",
                "name": "Elmwood",
                "book": 2,
                "page": 149,
                "row": 6,
                "hexCode": "#887E65",
                "rgb": "135,126,100"
            },
            {
                "nr": "PANTONE 18-0724 TPG",
                "name": "Gothic Olive",
                "book": 2,
                "page": 149,
                "row": 7,
                "hexCode": "#81755B",
                "rgb": "128,117,90"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0715 TPG",
                "name": "Sea Mist",
                "book": 2,
                "page": 150,
                "row": 1,
                "hexCode": "#DACCA6",
                "rgb": "218,204,166"
            },
            {
                "nr": "PANTONE 13-0613 TPG",
                "name": "Chino Green",
                "book": 2,
                "page": 150,
                "row": 2,
                "hexCode": "#DBCFAB",
                "rgb": "219,207,171"
            },
            {
                "nr": "PANTONE 13-0919 TPG",
                "name": "Soybean",
                "book": 2,
                "page": 150,
                "row": 3,
                "hexCode": "#D7C49E",
                "rgb": "215,196,157"
            },
            {
                "nr": "PANTONE 13-0915 TPG",
                "name": "Reed Yellow",
                "book": 2,
                "page": 150,
                "row": 4,
                "hexCode": "#DCCCA2",
                "rgb": "220,204,162"
            },
            {
                "nr": "PANTONE 14-1113 TPG",
                "name": "Marzipan",
                "book": 2,
                "page": 150,
                "row": 5,
                "hexCode": "#D9C19B",
                "rgb": "217,193,155"
            },
            {
                "nr": "PANTONE 14-0925 TPG",
                "name": "Parsnip",
                "book": 2,
                "page": 150,
                "row": 6,
                "hexCode": "#D6C693",
                "rgb": "214,198,147"
            },
            {
                "nr": "PANTONE 14-1026 TPG",
                "name": "Moonstone",
                "book": 2,
                "page": 150,
                "row": 7,
                "hexCode": "#CFB686",
                "rgb": "207,182,134"
            }
        ],
        [
            {
                "nr": "PANTONE 14-0626 TPG",
                "name": "Dried Moss",
                "book": 2,
                "page": 151,
                "row": 1,
                "hexCode": "#CABB7F",
                "rgb": "202,187,127"
            },
            {
                "nr": "PANTONE 14-1025 TPG",
                "name": "Cocoon",
                "book": 2,
                "page": 151,
                "row": 2,
                "hexCode": "#CCB87E",
                "rgb": "204,184,126"
            },
            {
                "nr": "PANTONE 14-0721 TPG",
                "name": "Hemp",
                "book": 2,
                "page": 151,
                "row": 3,
                "hexCode": "#C4B481",
                "rgb": "196,180,129"
            },
            {
                "nr": "PANTONE 15-0719 TPG",
                "name": "Silver Fern",
                "book": 2,
                "page": 151,
                "row": 4,
                "hexCode": "#BBAC81",
                "rgb": "187,172,129"
            },
            {
                "nr": "PANTONE 16-0726 TPG",
                "name": "Khaki",
                "book": 2,
                "page": 151,
                "row": 5,
                "hexCode": "#A09165",
                "rgb": "160,145,100"
            },
            {
                "nr": "PANTONE 17-0929 TPG",
                "name": "Fennel Seed",
                "book": 2,
                "page": 151,
                "row": 6,
                "hexCode": "#9B855B",
                "rgb": "154,132,91"
            },
            {
                "nr": "PANTONE 18-0820 TPG",
                "name": "Capers",
                "book": 2,
                "page": 151,
                "row": 7,
                "hexCode": "#6B644E",
                "rgb": "107,100,78"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1326 TPG",
                "name": "Prairie Sand",
                "book": 2,
                "page": 152,
                "row": 1,
                "hexCode": "#B39967",
                "rgb": "179,153,103"
            },
            {
                "nr": "PANTONE 16-1126 TPG",
                "name": "Antelope",
                "book": 2,
                "page": 152,
                "row": 2,
                "hexCode": "#A8915F",
                "rgb": "168,145,95"
            },
            {
                "nr": "PANTONE 16-1133 TPG",
                "name": "Mustard Gold",
                "book": 2,
                "page": 152,
                "row": 3,
                "hexCode": "#B19258",
                "rgb": "177,146,88"
            },
            {
                "nr": "PANTONE 17-1028 TPG",
                "name": "Antique Bronze",
                "book": 2,
                "page": 152,
                "row": 4,
                "hexCode": "#93825F",
                "rgb": "147,130,95"
            },
            {
                "nr": "PANTONE 17-0935 TPG",
                "name": "Dull Gold",
                "book": 2,
                "page": 152,
                "row": 5,
                "hexCode": "#86714A",
                "rgb": "134,113,74"
            },
            {
                "nr": "PANTONE 18-0830 TPG",
                "name": "Butternut",
                "book": 2,
                "page": 152,
                "row": 6,
                "hexCode": "#7D6C4E",
                "rgb": "124,107,78"
            },
            {
                "nr": "PANTONE 19-0617 TPG",
                "name": "Teak",
                "book": 2,
                "page": 152,
                "row": 7,
                "hexCode": "#645849",
                "rgb": "100,88,73"
            }
        ],
        [
            {
                "nr": "PANTONE 16-0730 TPG",
                "name": "Antique Gold",
                "book": 2,
                "page": 153,
                "row": 1,
                "hexCode": "#B5A065",
                "rgb": "181,160,101"
            },
            {
                "nr": "PANTONE 17-0836 TPG",
                "name": "Ecru Olive",
                "book": 2,
                "page": 153,
                "row": 2,
                "hexCode": "#98814C",
                "rgb": "152,129,75"
            },
            {
                "nr": "PANTONE 17-0840 TPG",
                "name": "Amber Green",
                "book": 2,
                "page": 153,
                "row": 3,
                "hexCode": "#9D884A",
                "rgb": "157,136,73"
            },
            {
                "nr": "PANTONE 17-0843 TPG",
                "name": "Bronze Mist",
                "book": 2,
                "page": 153,
                "row": 4,
                "hexCode": "#A0824D",
                "rgb": "160,130,77"
            },
            {
                "nr": "PANTONE 19-1034 TPG",
                "name": "Breen",
                "book": 2,
                "page": 153,
                "row": 5,
                "hexCode": "#776044",
                "rgb": "119,96,68"
            },
            {
                "nr": "PANTONE 18-0920 TPG",
                "name": "Kangaroo",
                "book": 2,
                "page": 153,
                "row": 6,
                "hexCode": "#73644F",
                "rgb": "115,100,79"
            },
            {
                "nr": "PANTONE 19-0618 TPG",
                "name": "Beech",
                "book": 2,
                "page": 153,
                "row": 7,
                "hexCode": "#5B543F",
                "rgb": "91,84,63"
            }
        ],
        [
            {
                "nr": "PANTONE 15-0730 TPG",
                "name": "Southern Moss",
                "book": 2,
                "page": 154,
                "row": 1,
                "hexCode": "#BEAA6E",
                "rgb": "190,170,110"
            },
            {
                "nr": "PANTONE 15-0732 TPG",
                "name": "Olivenite",
                "book": 2,
                "page": 154,
                "row": 2,
                "hexCode": "#C2AD68",
                "rgb": "194,173,104"
            },
            {
                "nr": "PANTONE 16-0742 TPG",
                "name": "Green Sulphur",
                "book": 2,
                "page": 154,
                "row": 3,
                "hexCode": "#B0913F",
                "rgb": "175,145,63"
            },
            {
                "nr": "PANTONE 17-0839 TPG",
                "name": "Golden Palm",
                "book": 2,
                "page": 154,
                "row": 4,
                "hexCode": "#AF9130",
                "rgb": "175,145,48"
            },
            {
                "nr": "PANTONE 16-0847 TPG",
                "name": "Olive Oil",
                "book": 2,
                "page": 154,
                "row": 5,
                "hexCode": "#AB8F40",
                "rgb": "171,143,64"
            },
            {
                "nr": "PANTONE 18-0835 TPG",
                "name": "Dried Tobacco",
                "book": 2,
                "page": 154,
                "row": 6,
                "hexCode": "#987E43",
                "rgb": "152,126,66"
            },
            {
                "nr": "PANTONE 18-0832 TPG",
                "name": "Plantation",
                "book": 2,
                "page": 154,
                "row": 7,
                "hexCode": "#7D6A45",
                "rgb": "124,105,69"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0725 TPG",
                "name": "Raffia",
                "book": 2,
                "page": 155,
                "row": 1,
                "hexCode": "#DBC885",
                "rgb": "219,200,133"
            },
            {
                "nr": "PANTONE 15-0743 TPG",
                "name": "Oil Yellow",
                "book": 2,
                "page": 155,
                "row": 2,
                "hexCode": "#C3A74F",
                "rgb": "195,167,79"
            },
            {
                "nr": "PANTONE 14-0740 TPG",
                "name": "Bamboo",
                "book": 2,
                "page": 155,
                "row": 3,
                "hexCode": "#D2B457",
                "rgb": "210,180,87"
            },
            {
                "nr": "PANTONE 15-0850 TPG",
                "name": "Ceylon Yellow",
                "book": 2,
                "page": 155,
                "row": 4,
                "hexCode": "#D5AD40",
                "rgb": "213,173,64"
            },
            {
                "nr": "PANTONE 15-0751 TPG",
                "name": "Lemon Curry",
                "book": 2,
                "page": 155,
                "row": 5,
                "hexCode": "#CDA332",
                "rgb": "205,163,50"
            },
            {
                "nr": "PANTONE 16-0946 TPG",
                "name": "Honey",
                "book": 2,
                "page": 155,
                "row": 6,
                "hexCode": "#BB9545",
                "rgb": "187,149,69"
            },
            {
                "nr": "PANTONE 18-0840 TPG",
                "name": "Tapenade",
                "book": 2,
                "page": 155,
                "row": 7,
                "hexCode": "#83683E",
                "rgb": "130,103,62"
            }
        ],
        [
            {
                "nr": "PANTONE 14-0837 TPG",
                "name": "Misted Yellow",
                "book": 2,
                "page": 156,
                "row": 1,
                "hexCode": "#D8B864",
                "rgb": "216,184,100"
            },
            {
                "nr": "PANTONE 14-0952 TPG",
                "name": "Spicy Mustard",
                "book": 2,
                "page": 156,
                "row": 2,
                "hexCode": "#DBAE49",
                "rgb": "219,174,73"
            },
            {
                "nr": "PANTONE 16-0954 TPG",
                "name": "Arrowwood",
                "book": 2,
                "page": 156,
                "row": 3,
                "hexCode": "#BD8F31",
                "rgb": "189,143,49"
            },
            {
                "nr": "PANTONE 16-0952 TPG",
                "name": "Nugget Gold",
                "book": 2,
                "page": 156,
                "row": 4,
                "hexCode": "#CB9C30",
                "rgb": "203,156,48"
            },
            {
                "nr": "PANTONE 15-0948 TPG",
                "name": "Golden Spice",
                "book": 2,
                "page": 156,
                "row": 5,
                "hexCode": "#C59841",
                "rgb": "197,152,65"
            },
            {
                "nr": "PANTONE 16-0953 TPG",
                "name": "Tawny Olive",
                "book": 2,
                "page": 156,
                "row": 6,
                "hexCode": "#C49532",
                "rgb": "196,149,50"
            },
            {
                "nr": "PANTONE 17-0949 TPG",
                "name": "Chai Tea",
                "book": 2,
                "page": 156,
                "row": 7,
                "hexCode": "#B9893D",
                "rgb": "184,137,60"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1031 TPG",
                "name": "Rattan",
                "book": 2,
                "page": 157,
                "row": 1,
                "hexCode": "#D3B272",
                "rgb": "211,178,114"
            },
            {
                "nr": "PANTONE 14-1036 TPG",
                "name": "Ochre",
                "book": 2,
                "page": 157,
                "row": 2,
                "hexCode": "#D8AE5F",
                "rgb": "216,174,95"
            },
            {
                "nr": "PANTONE 15-1132 TPG",
                "name": "Fall Leaf",
                "book": 2,
                "page": 157,
                "row": 3,
                "hexCode": "#C8A96D",
                "rgb": "200,169,109"
            },
            {
                "nr": "PANTONE 15-0942 TPG",
                "name": "Sauterne",
                "book": 2,
                "page": 157,
                "row": 4,
                "hexCode": "#C1A155",
                "rgb": "193,161,85"
            },
            {
                "nr": "PANTONE 16-0945 TPG",
                "name": "Tinsel",
                "book": 2,
                "page": 157,
                "row": 5,
                "hexCode": "#C39B52",
                "rgb": "195,155,82"
            },
            {
                "nr": "PANTONE 16-0948 TPG",
                "name": "Harvest Gold",
                "book": 2,
                "page": 157,
                "row": 6,
                "hexCode": "#B38B40",
                "rgb": "179,139,64"
            },
            {
                "nr": "PANTONE 18-0939 TPG",
                "name": "Cumin",
                "book": 2,
                "page": 157,
                "row": 7,
                "hexCode": "#907344",
                "rgb": "144,115,68"
            }
        ],
        [
            {
                "nr": "PANTONE 15-1142 TPG",
                "name": "Honey Gold",
                "book": 2,
                "page": 158,
                "row": 1,
                "hexCode": "#D4A351",
                "rgb": "212,163,81"
            },
            {
                "nr": "PANTONE 16-0947 TPG",
                "name": "Bright Gold",
                "book": 2,
                "page": 158,
                "row": 2,
                "hexCode": "#D0A351",
                "rgb": "208,163,81"
            },
            {
                "nr": "PANTONE 16-0950 TPG",
                "name": "Narcissus",
                "book": 2,
                "page": 158,
                "row": 3,
                "hexCode": "#C69549",
                "rgb": "198,149,73"
            },
            {
                "nr": "PANTONE 16-1139 TPG",
                "name": "Amber Gold",
                "book": 2,
                "page": 158,
                "row": 4,
                "hexCode": "#BB9150",
                "rgb": "187,145,80"
            },
            {
                "nr": "PANTONE 15-1046 TPG",
                "name": "Mineral Yellow",
                "book": 2,
                "page": 158,
                "row": 5,
                "hexCode": "#D6A146",
                "rgb": "214,161,70"
            },
            {
                "nr": "PANTONE 15-0960 TPG",
                "name": "Mango Mojito",
                "book": 2,
                "page": 158,
                "row": 6,
                "hexCode": "#DAA03D",
                "rgb": "217,159,60"
            },
            {
                "nr": "PANTONE 15-0953 TPG",
                "name": "Golden Yellow",
                "book": 2,
                "page": 158,
                "row": 7,
                "hexCode": "#CE932D",
                "rgb": "206,147,45"
            }
        ],
        [
            {
                "nr": "PANTONE 16-0940 TPG",
                "name": "Taffy",
                "book": 2,
                "page": 159,
                "row": 1,
                "hexCode": "#C39C68",
                "rgb": "195,156,104"
            },
            {
                "nr": "PANTONE 17-1047 TPG",
                "name": "Honey Mustard",
                "book": 2,
                "page": 159,
                "row": 2,
                "hexCode": "#B79153",
                "rgb": "183,145,83"
            },
            {
                "nr": "PANTONE 17-1129 TPG",
                "name": "Wood Thrush",
                "book": 2,
                "page": 159,
                "row": 3,
                "hexCode": "#A58046",
                "rgb": "165,128,70"
            },
            {
                "nr": "PANTONE 18-0940 TPG",
                "name": "Golden Brown",
                "book": 2,
                "page": 159,
                "row": 4,
                "hexCode": "#916B39",
                "rgb": "145,107,57"
            },
            {
                "nr": "PANTONE 18-0937 TPG",
                "name": "Bronze Brown",
                "book": 2,
                "page": 159,
                "row": 5,
                "hexCode": "#81633D",
                "rgb": "129,99,61"
            },
            {
                "nr": "PANTONE 18-0933 TPG",
                "name": "Rubber",
                "book": 2,
                "page": 159,
                "row": 6,
                "hexCode": "#85674C",
                "rgb": "133,103,76"
            },
            {
                "nr": "PANTONE 18-1048 TPG",
                "name": "Monk's Robe",
                "book": 2,
                "page": 159,
                "row": 7,
                "hexCode": "#76553D",
                "rgb": "118,85,61"
            }
        ],
        [
            {
                "nr": "PANTONE 17-1036 TPG",
                "name": "Bistre",
                "book": 2,
                "page": 160,
                "row": 1,
                "hexCode": "#927448",
                "rgb": "146,116,72"
            },
            {
                "nr": "PANTONE 18-1022 TPG",
                "name": "Ermine",
                "book": 2,
                "page": 160,
                "row": 2,
                "hexCode": "#816C51",
                "rgb": "129,108,81"
            },
            {
                "nr": "PANTONE 18-1018 TPG",
                "name": "Otter",
                "book": 2,
                "page": 160,
                "row": 3,
                "hexCode": "#7F6954",
                "rgb": "127,105,84"
            },
            {
                "nr": "PANTONE 18-0930 TPG",
                "name": "Coffee Liqueúr",
                "book": 2,
                "page": 160,
                "row": 4,
                "hexCode": "#6D5A4A",
                "rgb": "109,90,74"
            },
            {
                "nr": "PANTONE 18-0928 TPG",
                "name": "Sepia",
                "book": 2,
                "page": 160,
                "row": 5,
                "hexCode": "#6F5D49",
                "rgb": "111,93,73"
            },
            {
                "nr": "PANTONE 19-0815 TPG",
                "name": "Desert Palm",
                "book": 2,
                "page": 160,
                "row": 6,
                "hexCode": "#5D4D3E",
                "rgb": "93,77,62"
            },
            {
                "nr": "PANTONE 19-1020 TPG",
                "name": "Dark Earth",
                "book": 2,
                "page": 160,
                "row": 7,
                "hexCode": "#5F4D40",
                "rgb": "95,77,64"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1334 TPG",
                "name": "Tan",
                "book": 2,
                "page": 161,
                "row": 1,
                "hexCode": "#BA9B76",
                "rgb": "186,155,118"
            },
            {
                "nr": "PANTONE 17-1325 TPG",
                "name": "Cartouche",
                "book": 2,
                "page": 161,
                "row": 2,
                "hexCode": "#AC8E6F",
                "rgb": "172,142,111"
            },
            {
                "nr": "PANTONE 17-1038 TPG",
                "name": "Tiger's Eye",
                "book": 2,
                "page": 161,
                "row": 3,
                "hexCode": "#9D8068",
                "rgb": "157,128,104"
            },
            {
                "nr": "PANTONE 18-1014 TPG",
                "name": "Malt Ball",
                "book": 2,
                "page": 161,
                "row": 4,
                "hexCode": "#856C58",
                "rgb": "133,108,88"
            },
            {
                "nr": "PANTONE 18-1020 TPG",
                "name": "Cocoa Crème",
                "book": 2,
                "page": 161,
                "row": 5,
                "hexCode": "#8B7360",
                "rgb": "139,115,96"
            },
            {
                "nr": "PANTONE 18-1029 TPG",
                "name": "Toasted Coconut",
                "book": 2,
                "page": 161,
                "row": 6,
                "hexCode": "#8F725C",
                "rgb": "143,114,92"
            },
            {
                "nr": "PANTONE 18-1025 TPG",
                "name": "Foxtrot",
                "book": 2,
                "page": 161,
                "row": 7,
                "hexCode": "#8F6C50",
                "rgb": "143,108,80"
            }
        ],
        [
            {
                "nr": "PANTONE 14-1038 TPG",
                "name": "New Wheat",
                "book": 2,
                "page": 162,
                "row": 1,
                "hexCode": "#D8B780",
                "rgb": "216,183,128"
            },
            {
                "nr": "PANTONE 16-0928 TPG",
                "name": "Curry",
                "book": 2,
                "page": 162,
                "row": 2,
                "hexCode": "#BC9D6D",
                "rgb": "188,157,109"
            },
            {
                "nr": "PANTONE 15-1040 TPG",
                "name": "Iced Coffee",
                "book": 2,
                "page": 162,
                "row": 3,
                "hexCode": "#B69573",
                "rgb": "181,148,114"
            },
            {
                "nr": "PANTONE 17-1045 TPG",
                "name": "Apple Cinnamon",
                "book": 2,
                "page": 162,
                "row": 4,
                "hexCode": "#AE895C",
                "rgb": "174,137,92"
            },
            {
                "nr": "PANTONE 17-1128 TPG",
                "name": "Bone Brown",
                "book": 2,
                "page": 162,
                "row": 5,
                "hexCode": "#9D784C",
                "rgb": "157,120,76"
            },
            {
                "nr": "PANTONE 17-1125 TPG",
                "name": "Dijon",
                "book": 2,
                "page": 162,
                "row": 6,
                "hexCode": "#997C52",
                "rgb": "153,124,82"
            },
            {
                "nr": "PANTONE 17-0942 TPG",
                "name": "Medal Bronze",
                "book": 2,
                "page": 162,
                "row": 7,
                "hexCode": "#96784C",
                "rgb": "150,120,76"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1031 TPG",
                "name": "Apricot Sherbet",
                "book": 2,
                "page": 163,
                "row": 1,
                "hexCode": "#FDD19B",
                "rgb": "253,209,155"
            },
            {
                "nr": "PANTONE 13-1030 TPG",
                "name": "Sunburst",
                "book": 2,
                "page": 163,
                "row": 2,
                "hexCode": "#FAC48C",
                "rgb": "250,196,140"
            },
            {
                "nr": "PANTONE 16-1144 TPG",
                "name": "Oak Buff",
                "book": 2,
                "page": 163,
                "row": 3,
                "hexCode": "#CF9F63",
                "rgb": "207,159,99"
            },
            {
                "nr": "PANTONE 16-1143 TPG",
                "name": "Honey Yellow",
                "book": 2,
                "page": 163,
                "row": 4,
                "hexCode": "#CB9557",
                "rgb": "203,149,87"
            },
            {
                "nr": "PANTONE 17-1040 TPG",
                "name": "Spruce Yellow",
                "book": 2,
                "page": 163,
                "row": 5,
                "hexCode": "#BE8D4D",
                "rgb": "190,141,77"
            },
            {
                "nr": "PANTONE 17-1048 TPG",
                "name": "Inca Gold",
                "book": 2,
                "page": 163,
                "row": 6,
                "hexCode": "#BC7F37",
                "rgb": "188,127,55"
            },
            {
                "nr": "PANTONE 18-0935 TPG",
                "name": "Buckthorn Brown",
                "book": 2,
                "page": 163,
                "row": 7,
                "hexCode": "#AC7835",
                "rgb": "172,120,53"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1027 TPG",
                "name": "Apricot Cream",
                "book": 2,
                "page": 164,
                "row": 1,
                "hexCode": "#F3BE85",
                "rgb": "243,190,133"
            },
            {
                "nr": "PANTONE 14-1128 TPG",
                "name": "Buff Orange",
                "book": 2,
                "page": 164,
                "row": 2,
                "hexCode": "#FEBD7A",
                "rgb": "254,189,122"
            },
            {
                "nr": "PANTONE 15-1145 TPG",
                "name": "Chamois",
                "book": 2,
                "page": 164,
                "row": 3,
                "hexCode": "#F5AF65",
                "rgb": "245,175,101"
            },
            {
                "nr": "PANTONE 14-1051 TPG",
                "name": "Warm Apricot",
                "book": 2,
                "page": 164,
                "row": 4,
                "hexCode": "#FEB761",
                "rgb": "254,183,97"
            },
            {
                "nr": "PANTONE 16-1142 TPG",
                "name": "Golden Nugget",
                "book": 2,
                "page": 164,
                "row": 5,
                "hexCode": "#E09E59",
                "rgb": "224,158,89"
            },
            {
                "nr": "PANTONE 16-1342 TPG",
                "name": "Buckskin",
                "book": 2,
                "page": 164,
                "row": 6,
                "hexCode": "#D19056",
                "rgb": "209,144,86"
            },
            {
                "nr": "PANTONE 16-1148 TPG",
                "name": "Nugget",
                "book": 2,
                "page": 164,
                "row": 7,
                "hexCode": "#CD8C4D",
                "rgb": "205,140,77"
            }
        ],
        [
            {
                "nr": "PANTONE 16-1149 TPG",
                "name": "Desert Sun",
                "book": 2,
                "page": 165,
                "row": 1,
                "hexCode": "#CA7B3C",
                "rgb": "202,123,60"
            },
            {
                "nr": "PANTONE 17-1046 TPG",
                "name": "Golden Oak",
                "book": 2,
                "page": 165,
                "row": 2,
                "hexCode": "#C3793A",
                "rgb": "195,121,58"
            },
            {
                "nr": "PANTONE 18-1160 TPG",
                "name": "Sudan Brown",
                "book": 2,
                "page": 165,
                "row": 3,
                "hexCode": "#A97140",
                "rgb": "169,113,64"
            },
            {
                "nr": "PANTONE 18-1050 TPG",
                "name": "Honey Ginger",
                "book": 2,
                "page": 165,
                "row": 4,
                "hexCode": "#B36E36",
                "rgb": "179,110,53"
            },
            {
                "nr": "PANTONE 18-1049 TPG",
                "name": "Thai Curry",
                "book": 2,
                "page": 165,
                "row": 5,
                "hexCode": "#AF6F34",
                "rgb": "174,111,52"
            },
            {
                "nr": "PANTONE 18-1163 TPG",
                "name": "Pumpkin Spice",
                "book": 2,
                "page": 165,
                "row": 6,
                "hexCode": "#A96838",
                "rgb": "169,104,56"
            },
            {
                "nr": "PANTONE 18-0950 TPG",
                "name": "Cathay Spice",
                "book": 2,
                "page": 165,
                "row": 7,
                "hexCode": "#9A6B3E",
                "rgb": "154,107,62"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0945 TPG",
                "name": "Pale Marigold",
                "book": 2,
                "page": 166,
                "row": 1,
                "hexCode": "#FEC766",
                "rgb": "254,199,102"
            },
            {
                "nr": "PANTONE 14-1050 TPG",
                "name": "Marigold",
                "book": 2,
                "page": 166,
                "row": 2,
                "hexCode": "#FDAD50",
                "rgb": "253,173,80"
            },
            {
                "nr": "PANTONE 15-1058 TPG",
                "name": "Radiant Yellow",
                "book": 2,
                "page": 166,
                "row": 3,
                "hexCode": "#F9A02E",
                "rgb": "249,160,46"
            },
            {
                "nr": "PANTONE 15-1147 TPG",
                "name": "Butterscotch",
                "book": 2,
                "page": 166,
                "row": 4,
                "hexCode": "#DD964A",
                "rgb": "221,150,74"
            },
            {
                "nr": "PANTONE 15-1054 TPG",
                "name": "Cadmium Yellow",
                "book": 2,
                "page": 166,
                "row": 5,
                "hexCode": "#EE9D32",
                "rgb": "238,157,50"
            },
            {
                "nr": "PANTONE 16-1140 TPG",
                "name": "Yam",
                "book": 2,
                "page": 166,
                "row": 6,
                "hexCode": "#D18D46",
                "rgb": "209,141,70"
            },
            {
                "nr": "PANTONE 16-1150 TPG",
                "name": "Topaz",
                "book": 2,
                "page": 166,
                "row": 7,
                "hexCode": "#D18547",
                "rgb": "209,133,71"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0942 TPG",
                "name": "Amber Yellow",
                "book": 2,
                "page": 167,
                "row": 1,
                "hexCode": "#F9B85B",
                "rgb": "249,184,91"
            },
            {
                "nr": "PANTONE 13-0947 TPG",
                "name": "Banana",
                "book": 2,
                "page": 167,
                "row": 2,
                "hexCode": "#FCBD55",
                "rgb": "252,189,85"
            },
            {
                "nr": "PANTONE 15-0955 TPG",
                "name": "Old Gold",
                "book": 2,
                "page": 167,
                "row": 3,
                "hexCode": "#EDB027",
                "rgb": "237,176,39"
            },
            {
                "nr": "PANTONE 14-0955 TPG",
                "name": "Citrus",
                "book": 2,
                "page": 167,
                "row": 4,
                "hexCode": "#FEB236",
                "rgb": "254,178,54"
            },
            {
                "nr": "PANTONE 15-1062 TPG",
                "name": "Gold Fusion",
                "book": 2,
                "page": 167,
                "row": 5,
                "hexCode": "#FEB100",
                "rgb": "254,177,0"
            },
            {
                "nr": "PANTONE 14-1064 TPG",
                "name": "Saffron",
                "book": 2,
                "page": 167,
                "row": 6,
                "hexCode": "#FEAF12",
                "rgb": "254,175,18"
            },
            {
                "nr": "PANTONE 14-1159 TPG",
                "name": "Zinnia",
                "book": 2,
                "page": 167,
                "row": 7,
                "hexCode": "#FEA537",
                "rgb": "254,165,55"
            }
        ],
        [
            {
                "nr": "PANTONE 14-0936 TPG",
                "name": "Sahara Sun",
                "book": 2,
                "page": 168,
                "row": 1,
                "hexCode": "#E0C188",
                "rgb": "224,193,136"
            },
            {
                "nr": "PANTONE 13-1025 TPG",
                "name": "Impala",
                "book": 2,
                "page": 168,
                "row": 2,
                "hexCode": "#FDD497",
                "rgb": "253,212,151"
            },
            {
                "nr": "PANTONE 13-0935 TPG",
                "name": "Flax",
                "book": 2,
                "page": 168,
                "row": 3,
                "hexCode": "#FEC97E",
                "rgb": "254,201,126"
            },
            {
                "nr": "PANTONE 14-1045 TPG",
                "name": "Amber",
                "book": 2,
                "page": 168,
                "row": 4,
                "hexCode": "#EFAF57",
                "rgb": "239,175,87"
            },
            {
                "nr": "PANTONE 15-1049 TPG",
                "name": "Artisan's Gold",
                "book": 2,
                "page": 168,
                "row": 5,
                "hexCode": "#E8A445",
                "rgb": "232,164,69"
            },
            {
                "nr": "PANTONE 14-0941 TPG",
                "name": "Beeswax",
                "book": 2,
                "page": 168,
                "row": 6,
                "hexCode": "#EFAB51",
                "rgb": "239,171,81"
            },
            {
                "nr": "PANTONE 15-1045 TPG",
                "name": "Autumn Blaze",
                "book": 2,
                "page": 168,
                "row": 7,
                "hexCode": "#DB9332",
                "rgb": "218,147,49"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0939 TPG",
                "name": "Golden Cream",
                "book": 2,
                "page": 169,
                "row": 1,
                "hexCode": "#F0BB69",
                "rgb": "240,187,105"
            },
            {
                "nr": "PANTONE 13-0946 TPG",
                "name": "Jurassic Gold",
                "book": 2,
                "page": 169,
                "row": 2,
                "hexCode": "#E8AA54",
                "rgb": "232,169,84"
            },
            {
                "nr": "PANTONE 14-1052 TPG",
                "name": "Kumquat",
                "book": 2,
                "page": 169,
                "row": 3,
                "hexCode": "#FCAF5A",
                "rgb": "251,175,90"
            },
            {
                "nr": "PANTONE 16-1054 TPG",
                "name": "Sunflower",
                "book": 2,
                "page": 169,
                "row": 4,
                "hexCode": "#D49437",
                "rgb": "212,148,55"
            },
            {
                "nr": "PANTONE 15-1050 TPG",
                "name": "Golden Glow",
                "book": 2,
                "page": 169,
                "row": 5,
                "hexCode": "#DCA042",
                "rgb": "220,160,66"
            },
            {
                "nr": "PANTONE 14-1041 TPG",
                "name": "Golden Apricot",
                "book": 2,
                "page": 169,
                "row": 6,
                "hexCode": "#DFAC60",
                "rgb": "223,172,96"
            },
            {
                "nr": "PANTONE 15-1051 TPG",
                "name": "Golden Orange",
                "book": 2,
                "page": 169,
                "row": 7,
                "hexCode": "#D79232",
                "rgb": "215,146,50"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0941 TPG",
                "name": "Banana Cream",
                "book": 2,
                "page": 170,
                "row": 1,
                "hexCode": "#FED074",
                "rgb": "254,208,116"
            },
            {
                "nr": "PANTONE 13-0932 TPG",
                "name": "Cornsilk",
                "book": 2,
                "page": 170,
                "row": 2,
                "hexCode": "#ECC478",
                "rgb": "236,196,120"
            },
            {
                "nr": "PANTONE 13-0940 TPG",
                "name": "Sunset Gold",
                "book": 2,
                "page": 170,
                "row": 3,
                "hexCode": "#F8C66D",
                "rgb": "248,198,109"
            },
            {
                "nr": "PANTONE 14-0851 TPG",
                "name": "Samoan Sun",
                "book": 2,
                "page": 170,
                "row": 4,
                "hexCode": "#F5CC71",
                "rgb": "245,204,113"
            },
            {
                "nr": "PANTONE 14-0847 TPG",
                "name": "Buff Yellow",
                "book": 2,
                "page": 170,
                "row": 5,
                "hexCode": "#F1C471",
                "rgb": "241,196,113"
            },
            {
                "nr": "PANTONE 14-0850 TPG",
                "name": "Daffodil",
                "book": 2,
                "page": 170,
                "row": 6,
                "hexCode": "#FEC751",
                "rgb": "254,199,81"
            },
            {
                "nr": "PANTONE 14-0846 TPG",
                "name": "Yolk Yellow",
                "book": 2,
                "page": 170,
                "row": 7,
                "hexCode": "#E2B153",
                "rgb": "226,177,83"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0826 TPG",
                "name": "Golden Haze",
                "book": 2,
                "page": 171,
                "row": 1,
                "hexCode": "#FAD994",
                "rgb": "250,217,148"
            },
            {
                "nr": "PANTONE 12-0758 TPG",
                "name": "Yarrow",
                "book": 2,
                "page": 171,
                "row": 2,
                "hexCode": "#F0CB75",
                "rgb": "240,203,117"
            },
            {
                "nr": "PANTONE 14-0848 TPG",
                "name": "Mimosa",
                "book": 2,
                "page": 171,
                "row": 3,
                "hexCode": "#EABF56",
                "rgb": "234,191,86"
            },
            {
                "nr": "PANTONE 13-0859 TPG",
                "name": "Lemon Chrome",
                "book": 2,
                "page": 171,
                "row": 4,
                "hexCode": "#FEC600",
                "rgb": "254,198,0"
            },
            {
                "nr": "PANTONE 14-0957 TPG",
                "name": "Spectra Yellow",
                "book": 2,
                "page": 171,
                "row": 5,
                "hexCode": "#FEC328",
                "rgb": "254,195,40"
            },
            {
                "nr": "PANTONE 13-0759 TPG",
                "name": "Solar Power",
                "book": 2,
                "page": 171,
                "row": 6,
                "hexCode": "#F2C34D",
                "rgb": "242,195,77"
            },
            {
                "nr": "PANTONE 14-0951 TPG",
                "name": "Golden Rod",
                "book": 2,
                "page": 171,
                "row": 7,
                "hexCode": "#E7AB31",
                "rgb": "231,171,49"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0840 TPG",
                "name": "Snapdragon",
                "book": 2,
                "page": 172,
                "row": 1,
                "hexCode": "#FED572",
                "rgb": "254,213,114"
            },
            {
                "nr": "PANTONE 13-0851 TPG",
                "name": "Minion YellowTM",
                "book": 2,
                "page": 172,
                "row": 2,
                "hexCode": "#FFD763",
                "rgb": "254,214,98"
            },
            {
                "nr": "PANTONE 13-0850 TPG",
                "name": "Aspen Gold",
                "book": 2,
                "page": 172,
                "row": 3,
                "hexCode": "#FDD65E",
                "rgb": "253,214,94"
            },
            {
                "nr": "PANTONE 13-0849 TPG",
                "name": "Haba?ero Gold",
                "book": 2,
                "page": 172,
                "row": 4,
                "hexCode": "#FFD653",
                "rgb": "254,214,82"
            },
            {
                "nr": "PANTONE 13-0758 TPG",
                "name": "Dandelion",
                "book": 2,
                "page": 172,
                "row": 5,
                "hexCode": "#FED31A",
                "rgb": "254,211,26"
            },
            {
                "nr": "PANTONE 13-0752 TPG",
                "name": "Lemon",
                "book": 2,
                "page": 172,
                "row": 6,
                "hexCode": "#F0C52C",
                "rgb": "240,197,44"
            },
            {
                "nr": "PANTONE 14-0852 TPG",
                "name": "Freesia",
                "book": 2,
                "page": 172,
                "row": 7,
                "hexCode": "#F3C236",
                "rgb": "243,194,54"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0754 TPG",
                "name": "Sunstruck",
                "book": 2,
                "page": 173,
                "row": 1,
                "hexCode": "#F5D051",
                "rgb": "245,208,81"
            },
            {
                "nr": "PANTONE 14-0849 TPG",
                "name": "Beach Ball",
                "book": 2,
                "page": 173,
                "row": 2,
                "hexCode": "#F4C863",
                "rgb": "244,200,99"
            },
            {
                "nr": "PANTONE 14-0751 TPG",
                "name": "Daisy Daze",
                "book": 2,
                "page": 173,
                "row": 3,
                "hexCode": "#EFC326",
                "rgb": "239,195,38"
            },
            {
                "nr": "PANTONE 13-0757 TPG",
                "name": "Calendula",
                "book": 2,
                "page": 173,
                "row": 4,
                "hexCode": "#E0B13A",
                "rgb": "224,177,58"
            },
            {
                "nr": "PANTONE 14-0958 TPG",
                "name": "Bumblebee",
                "book": 2,
                "page": 173,
                "row": 5,
                "hexCode": "#F0B903",
                "rgb": "240,185,3"
            },
            {
                "nr": "PANTONE 15-0956 TPG",
                "name": "Daylily",
                "book": 2,
                "page": 173,
                "row": 6,
                "hexCode": "#EFAF0D",
                "rgb": "239,175,13"
            },
            {
                "nr": "PANTONE 15-0954 TPG",
                "name": "Symphonic Sunset",
                "book": 2,
                "page": 173,
                "row": 7,
                "hexCode": "#DFA51D",
                "rgb": "223,165,29"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0737 TPG",
                "name": "Goldfinch",
                "book": 2,
                "page": 174,
                "row": 1,
                "hexCode": "#F3DA73",
                "rgb": "243,218,115"
            },
            {
                "nr": "PANTONE 12-0736 TPG",
                "name": "Lemon Drop",
                "book": 2,
                "page": 174,
                "row": 2,
                "hexCode": "#F4D776",
                "rgb": "244,215,118"
            },
            {
                "nr": "PANTONE 13-0755 TPG",
                "name": "Primrose Yellow",
                "book": 2,
                "page": 174,
                "row": 3,
                "hexCode": "#EFCD5B",
                "rgb": "239,205,91"
            },
            {
                "nr": "PANTONE 13-0756 TPG",
                "name": "Lemon Zest",
                "book": 2,
                "page": 174,
                "row": 4,
                "hexCode": "#F0D762",
                "rgb": "240,215,98"
            },
            {
                "nr": "PANTONE 14-0754 TPG",
                "name": "Super Lemon",
                "book": 2,
                "page": 174,
                "row": 5,
                "hexCode": "#E4BF48",
                "rgb": "228,191,72"
            },
            {
                "nr": "PANTONE 13-0746 TPG",
                "name": "Maize",
                "book": 2,
                "page": 174,
                "row": 6,
                "hexCode": "#F0D049",
                "rgb": "240,208,73"
            },
            {
                "nr": "PANTONE 14-0755 TPG",
                "name": "Sulphur",
                "book": 2,
                "page": 174,
                "row": 7,
                "hexCode": "#E0BA35",
                "rgb": "224,186,53"
            }
        ],
        [
            {
                "nr": "PANTONE 13-0760 TPG",
                "name": "Yellow Balloon",
                "book": 2,
                "page": 175,
                "row": 1,
                "hexCode": "#F4D04F",
                "rgb": "244,208,79"
            },
            {
                "nr": "PANTONE 13-0749 TPG",
                "name": "Slicker",
                "book": 2,
                "page": 175,
                "row": 2,
                "hexCode": "#F3D143",
                "rgb": "243,209,67"
            },
            {
                "nr": "PANTONE 13-0751 TPG",
                "name": "High Visibility",
                "book": 2,
                "page": 175,
                "row": 3,
                "hexCode": "#F5CB00",
                "rgb": "245,203,0"
            },
            {
                "nr": "PANTONE 14-0750 TPG",
                "name": "Passion Fruit",
                "book": 2,
                "page": 175,
                "row": 4,
                "hexCode": "#E5C634",
                "rgb": "229,198,52"
            },
            {
                "nr": "PANTONE 14-0757 TPG",
                "name": "Misted Marigold",
                "book": 2,
                "page": 175,
                "row": 5,
                "hexCode": "#E8C338",
                "rgb": "232,195,56"
            },
            {
                "nr": "PANTONE 14-0758 TPG",
                "name": "Hot Spot",
                "book": 2,
                "page": 175,
                "row": 6,
                "hexCode": "#D9B02A",
                "rgb": "217,176,42"
            },
            {
                "nr": "PANTONE 16-0748 TPG",
                "name": "Gold Flake",
                "book": 2,
                "page": 175,
                "row": 7,
                "hexCode": "#C29926",
                "rgb": "194,153,38"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0738 TPG",
                "name": "Yellow Cream",
                "book": 2,
                "page": 176,
                "row": 1,
                "hexCode": "#EED971",
                "rgb": "238,216,113"
            },
            {
                "nr": "PANTONE 13-0644 TPG",
                "name": "Golden Kiwi",
                "book": 2,
                "page": 176,
                "row": 2,
                "hexCode": "#F7E14A",
                "rgb": "246,224,74"
            },
            {
                "nr": "PANTONE 12-0752 TPG",
                "name": "Buttercup",
                "book": 2,
                "page": 176,
                "row": 3,
                "hexCode": "#F9E144",
                "rgb": "249,225,68"
            },
            {
                "nr": "PANTONE 12-0643 TPG",
                "name": "Blazing Yellow",
                "book": 2,
                "page": 176,
                "row": 4,
                "hexCode": "#F6E53D",
                "rgb": "246,229,61"
            },
            {
                "nr": "PANTONE 14-0756 TPG",
                "name": "Empire Yellow",
                "book": 2,
                "page": 176,
                "row": 5,
                "hexCode": "#F3D220",
                "rgb": "243,210,32"
            },
            {
                "nr": "PANTONE 14-0760 TPG",
                "name": "Cyber Yellow",
                "book": 2,
                "page": 176,
                "row": 6,
                "hexCode": "#FDD10D",
                "rgb": "253,209,13"
            },
            {
                "nr": "PANTONE 13-0858 TPG",
                "name": "Vibrant Yellow",
                "book": 2,
                "page": 176,
                "row": 7,
                "hexCode": "#FDDB26",
                "rgb": "253,219,38"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0735 TPG",
                "name": "Yellowtail",
                "book": 2,
                "page": 177,
                "row": 1,
                "hexCode": "#EDDC6B",
                "rgb": "237,220,107"
            },
            {
                "nr": "PANTONE 13-0753 TPG",
                "name": "Quince",
                "book": 2,
                "page": 177,
                "row": 2,
                "hexCode": "#EDD732",
                "rgb": "237,215,50"
            },
            {
                "nr": "PANTONE 13-0647 TPG",
                "name": "Illuminating",
                "book": 2,
                "page": 177,
                "row": 3,
                "hexCode": "#F3E04E",
                "rgb": "243,224,78"
            },
            {
                "nr": "PANTONE 14-0745 TPG",
                "name": "Incaberry",
                "book": 2,
                "page": 177,
                "row": 4,
                "hexCode": "#E6C823",
                "rgb": "230,200,35"
            },
            {
                "nr": "PANTONE 15-0635 TPG",
                "name": "Snake Eye",
                "book": 2,
                "page": 177,
                "row": 5,
                "hexCode": "#CDBC38",
                "rgb": "205,188,56"
            },
            {
                "nr": "PANTONE 15-0647 TPG",
                "name": "Yellow Jasmine",
                "book": 2,
                "page": 177,
                "row": 6,
                "hexCode": "#D1B425",
                "rgb": "209,180,37"
            },
            {
                "nr": "PANTONE 16-0838 TPG",
                "name": "Mango Mint",
                "book": 2,
                "page": 177,
                "row": 7,
                "hexCode": "#CFB52B",
                "rgb": "207,181,43"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0740 TPG",
                "name": "Limelight",
                "book": 2,
                "page": 178,
                "row": 1,
                "hexCode": "#F0EA7F",
                "rgb": "240,234,127"
            },
            {
                "nr": "PANTONE 12-0742 TPG",
                "name": "Lemon Verbena",
                "book": 2,
                "page": 178,
                "row": 2,
                "hexCode": "#F6EA7B",
                "rgb": "245,233,122"
            },
            {
                "nr": "PANTONE 12-0646 TPG",
                "name": "Celandine",
                "book": 2,
                "page": 178,
                "row": 3,
                "hexCode": "#F0E366",
                "rgb": "239,227,102"
            },
            {
                "nr": "PANTONE 12-0642 TPG",
                "name": "Aurora",
                "book": 2,
                "page": 178,
                "row": 4,
                "hexCode": "#F1DD60",
                "rgb": "241,221,96"
            },
            {
                "nr": "PANTONE 13-0640 TPG",
                "name": "Acacia",
                "book": 2,
                "page": 178,
                "row": 5,
                "hexCode": "#DCD067",
                "rgb": "220,208,103"
            },
            {
                "nr": "PANTONE 13-0648 TPG",
                "name": "Green Sheen",
                "book": 2,
                "page": 178,
                "row": 6,
                "hexCode": "#DCD157",
                "rgb": "220,209,87"
            },
            {
                "nr": "PANTONE 13-0646 TPG",
                "name": "Meadowlark",
                "book": 2,
                "page": 178,
                "row": 7,
                "hexCode": "#ECDB54",
                "rgb": "236,219,83"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0615 TPG",
                "name": "Pear Sorbet",
                "book": 2,
                "page": 179,
                "row": 1,
                "hexCode": "#EEE9C7",
                "rgb": "238,233,198"
            },
            {
                "nr": "PANTONE 11-0616 TPG",
                "name": "Pastel Yellow",
                "book": 2,
                "page": 179,
                "row": 2,
                "hexCode": "#F1E3AC",
                "rgb": "241,227,172"
            },
            {
                "nr": "PANTONE 12-0715 TPG",
                "name": "Double Cream",
                "book": 2,
                "page": 179,
                "row": 3,
                "hexCode": "#F4DFAC",
                "rgb": "244,223,172"
            },
            {
                "nr": "PANTONE 12-0722 TPG",
                "name": "French Vanilla",
                "book": 2,
                "page": 179,
                "row": 4,
                "hexCode": "#EDDEA3",
                "rgb": "237,222,163"
            },
            {
                "nr": "PANTONE 12-0721 TPG",
                "name": "Lemonade",
                "book": 2,
                "page": 179,
                "row": 5,
                "hexCode": "#EFE59A",
                "rgb": "239,229,154"
            },
            {
                "nr": "PANTONE 11-0622 TPG",
                "name": "Yellow Iris",
                "book": 2,
                "page": 179,
                "row": 6,
                "hexCode": "#EDE892",
                "rgb": "237,232,146"
            },
            {
                "nr": "PANTONE 11-0620 TPG",
                "name": "Elfin Yellow",
                "book": 2,
                "page": 179,
                "row": 7,
                "hexCode": "#EEEB9A",
                "rgb": "238,235,154"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0720 TPG",
                "name": "Mellow Yellow",
                "book": 2,
                "page": 180,
                "row": 1,
                "hexCode": "#EEDB9B",
                "rgb": "238,219,155"
            },
            {
                "nr": "PANTONE 12-0824 TPG",
                "name": "Pale Banana",
                "book": 2,
                "page": 180,
                "row": 2,
                "hexCode": "#F5E096",
                "rgb": "245,224,150"
            },
            {
                "nr": "PANTONE 12-0711 TPG",
                "name": "Lemon Meringue",
                "book": 2,
                "page": 180,
                "row": 3,
                "hexCode": "#EEE29C",
                "rgb": "238,226,156"
            },
            {
                "nr": "PANTONE 12-0825 TPG",
                "name": "Popcorn",
                "book": 2,
                "page": 180,
                "row": 4,
                "hexCode": "#F7E08E",
                "rgb": "247,224,142"
            },
            {
                "nr": "PANTONE 12-0718 TPG",
                "name": "Pineapple Slice",
                "book": 2,
                "page": 180,
                "row": 5,
                "hexCode": "#E5D494",
                "rgb": "229,212,148"
            },
            {
                "nr": "PANTONE 12-0729 TPG",
                "name": "Sundress",
                "book": 2,
                "page": 180,
                "row": 6,
                "hexCode": "#E7CF8E",
                "rgb": "231,207,142"
            },
            {
                "nr": "PANTONE 12-0727 TPG",
                "name": "Sunshine",
                "book": 2,
                "page": 180,
                "row": 7,
                "hexCode": "#F8DE82",
                "rgb": "248,222,130"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0510 TPG",
                "name": "Afterglow",
                "book": 2,
                "page": 181,
                "row": 1,
                "hexCode": "#F4EBD0",
                "rgb": "244,235,208"
            },
            {
                "nr": "PANTONE 11-0619 TPG",
                "name": "Flan",
                "book": 2,
                "page": 181,
                "row": 2,
                "hexCode": "#EADFB7",
                "rgb": "234,223,183"
            },
            {
                "nr": "PANTONE 12-0717 TPG",
                "name": "Anise Flower",
                "book": 2,
                "page": 181,
                "row": 3,
                "hexCode": "#EBE3BA",
                "rgb": "235,227,186"
            },
            {
                "nr": "PANTONE 13-0822 TPG",
                "name": "Sunlight",
                "book": 2,
                "page": 181,
                "row": 4,
                "hexCode": "#EFDAA4",
                "rgb": "239,218,164"
            },
            {
                "nr": "PANTONE 13-0917 TPG",
                "name": "Italian Straw",
                "book": 2,
                "page": 181,
                "row": 5,
                "hexCode": "#EAD3A1",
                "rgb": "234,211,161"
            },
            {
                "nr": "PANTONE 13-0922 TPG",
                "name": "Straw",
                "book": 2,
                "page": 181,
                "row": 6,
                "hexCode": "#E2CA92",
                "rgb": "226,202,146"
            },
            {
                "nr": "PANTONE 14-0935 TPG",
                "name": "Jojoba",
                "book": 2,
                "page": 181,
                "row": 7,
                "hexCode": "#DDC283",
                "rgb": "221,194,131"
            }
        ],
        [
            {
                "nr": "PANTONE 12-0812 TPG",
                "name": "Alabaster Gleam",
                "book": 2,
                "page": 182,
                "row": 1,
                "hexCode": "#EEDFBC",
                "rgb": "238,223,188"
            },
            {
                "nr": "PANTONE 12-0806 TPG",
                "name": "Rutabaga",
                "book": 2,
                "page": 182,
                "row": 2,
                "hexCode": "#EBE0BD",
                "rgb": "235,224,189"
            },
            {
                "nr": "PANTONE 13-0815 TPG",
                "name": "Banana Crepe",
                "book": 2,
                "page": 182,
                "row": 3,
                "hexCode": "#E6D2A5",
                "rgb": "230,210,165"
            },
            {
                "nr": "PANTONE 12-0910 TPG",
                "name": "Lamb's Wool",
                "book": 2,
                "page": 182,
                "row": 4,
                "hexCode": "#E8D7B1",
                "rgb": "232,215,177"
            },
            {
                "nr": "PANTONE 13-0814 TPG",
                "name": "Summer Melon",
                "book": 2,
                "page": 182,
                "row": 5,
                "hexCode": "#EAD6AE",
                "rgb": "234,214,174"
            },
            {
                "nr": "PANTONE 12-0714 TPG",
                "name": "Cornhusk",
                "book": 2,
                "page": 182,
                "row": 6,
                "hexCode": "#F3DAB1",
                "rgb": "243,218,177"
            },
            {
                "nr": "PANTONE 12-0822 TPG",
                "name": "Golden Fleece",
                "book": 2,
                "page": 182,
                "row": 7,
                "hexCode": "#F5D09C",
                "rgb": "245,208,156"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0515 TPG",
                "name": "Lemon Icing",
                "book": 2,
                "page": 183,
                "row": 1,
                "hexCode": "#F4EBC8",
                "rgb": "244,235,200"
            },
            {
                "nr": "PANTONE 12-0804 TPG",
                "name": "Cloud Cream",
                "book": 2,
                "page": 183,
                "row": 2,
                "hexCode": "#E6DDC3",
                "rgb": "230,221,195"
            },
            {
                "nr": "PANTONE 12-0713 TPG",
                "name": "Almond Oil",
                "book": 2,
                "page": 183,
                "row": 3,
                "hexCode": "#F1E4BE",
                "rgb": "241,228,190"
            },
            {
                "nr": "PANTONE 12-0815 TPG",
                "name": "Vanilla Custard",
                "book": 2,
                "page": 183,
                "row": 4,
                "hexCode": "#F0E1B9",
                "rgb": "240,225,185"
            },
            {
                "nr": "PANTONE 12-0712 TPG",
                "name": "Vanilla",
                "book": 2,
                "page": 183,
                "row": 5,
                "hexCode": "#F3E4C3",
                "rgb": "243,228,195"
            },
            {
                "nr": "PANTONE 13-0916 TPG",
                "name": "Chamomile",
                "book": 2,
                "page": 183,
                "row": 6,
                "hexCode": "#E9D3A5",
                "rgb": "233,211,165"
            },
            {
                "nr": "PANTONE 12-0817 TPG",
                "name": "Apricot Gelato",
                "book": 2,
                "page": 183,
                "row": 7,
                "hexCode": "#F0D4A3",
                "rgb": "240,212,163"
            }
        ],
        [
            {
                "nr": "PANTONE 11-0108 TPG",
                "name": "Solitary Star",
                "book": 2,
                "page": 184,
                "row": 1,
                "hexCode": "#F0EBDB",
                "rgb": "240,235,219"
            },
            {
                "nr": "PANTONE 11-0202 TPG",
                "name": "Baby's Breath",
                "book": 2,
                "page": 184,
                "row": 2,
                "hexCode": "#EBE6D5",
                "rgb": "235,230,213"
            },
            {
                "nr": "PANTONE 11-0507 TPG",
                "name": "Winter White",
                "book": 2,
                "page": 184,
                "row": 3,
                "hexCode": "#F0EAD3",
                "rgb": "240,234,211"
            },
            {
                "nr": "PANTONE 11-0107 TPG",
                "name": "Papyrus",
                "book": 2,
                "page": 184,
                "row": 4,
                "hexCode": "#F1ECD7",
                "rgb": "241,236,215"
            },
            {
                "nr": "PANTONE 11-0106 TPG",
                "name": "Sweet Corn",
                "book": 2,
                "page": 184,
                "row": 5,
                "hexCode": "#F2EDD7",
                "rgb": "242,237,215"
            },
            {
                "nr": "PANTONE 12-0602 TPG",
                "name": "Arctic Wolf",
                "book": 2,
                "page": 184,
                "row": 6,
                "hexCode": "#E7E0D1",
                "rgb": "231,224,209"
            },
            {
                "nr": "PANTONE 12-0908 TPG",
                "name": "Summer Sand",
                "book": 2,
                "page": 184,
                "row": 7,
                "hexCode": "#D9CEBB",
                "rgb": "217,206,187"
            }
        ],
        [
            {
                "nr": "PANTONE 13-1012 TPG",
                "name": "Frosted Almond",
                "book": 2,
                "page": 185,
                "row": 1,
                "hexCode": "#D9C4AB",
                "rgb": "217,195,170"
            },
            {
                "nr": "PANTONE 14-1012 TPG",
                "name": "Gilded Beige",
                "book": 2,
                "page": 185,
                "row": 2,
                "hexCode": "#C3A98B",
                "rgb": "195,168,138"
            },
            {
                "nr": "PANTONE 15-0927 TPG",
                "name": "Pale Gold",
                "book": 2,
                "page": 185,
                "row": 3,
                "hexCode": "#C89B69",
                "rgb": "199,155,104"
            },
            {
                "nr": "PANTONE 16-0836 TPG",
                "name": "Rich Gold",
                "book": 2,
                "page": 185,
                "row": 4,
                "hexCode": "#BCA360",
                "rgb": "188,162,95"
            },
            {
                "nr": "PANTONE 16-1325 TPG",
                "name": "Copper",
                "book": 2,
                "page": 185,
                "row": 5,
                "hexCode": "#BF7D5C",
                "rgb": "191,124,91"
            },
            {
                "nr": "PANTONE 18-1537 TPG",
                "name": "Copper Coin",
                "book": 2,
                "page": 185,
                "row": 6,
                "hexCode": "#B1624E",
                "rgb": "176,98,77"
            },
            {
                "nr": "PANTONE 14-5002 TPG",
                "name": "Silver",
                "book": 2,
                "page": 185,
                "row": 7,
                "hexCode": "#AAABA7",
                "rgb": "169,170,166"
            }
        ]
    ]
}