<template>
  <div class="panTongse">
    <div class="panTongseMask" @click.stop="colorResult(false)"></div>
    <div class="con">
      <!-- 顶部导航 -->
      <div class="conTitle rightConTitle">
        <div class="left">
          <span class="txt">PANTONE</span>
          <img src="@/newImg/trademark.png" alt="" class="trademark" />
          <el-select class="select" v-model="colorTypeNum" @change="getPtsList" placeholder="请选择">
            <el-option v-for="item in colorType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <img src="@/newImg/hidden.png" alt="" @click="colorResult(false)" class="hidden" />
      </div>
      <!-- 潘通色选择 -->
      <div class="colorList">
        <div class="left">
          <div v-for="(item, index) in colorList[rightData.firstIndex]" :key="index"
            :class="checkColorindex == index ? 'itemBorder item' : 'item'" @click="setpts(index)">
            <div class="colorBlock" :style="`background:${item.hexCode}`"></div>
            <span> {{ item.nr }} </span>
          </div>
        </div>

        <!-- 公共纱线颜色滑块 -->
        <div class="middleModel">
          <div class="sxjt" @click="setColorList(1)">
            <img src="@/images/sfan@2x.png" alt="" />
          </div>
          <div class="colorAggregate">
            <el-slider vertical class="sliderStyle rightStyle" :step="1" :min="0"
              :max="colorList.length == 0 ? 184 : colorList.length - 1" :show-tooltip="false" v-model="sliderNum"
              @input="setslideBlock(1)"></el-slider>
            <el-slider vertical class="sliderStyle leftStyle" :step="1" :min="0"
              :max="colorList.length == 0 ? 184 : colorList.length - 1" :show-tooltip="false" v-model="sliderNum">
            </el-slider>
            <div class="item" v-for="(item, index) in colorList" :key="index" @click="setpts(index, 1)" :style="`border-color:${sliderNum == index ? '#1976D2' : '#fff'
              };background:${item[0].hexCode};`"></div>
          </div>
          <div class="sxjt" @click="setColorList(2)">
            <img src="@/images/xfan@2x.png" alt="" />
          </div>
        </div>

        <div class="right">
          <div class="color" :style="`background:${rightData.color != '' ? rightData.color : 'FFFFFF'
            }`"></div>
          <div class="inputModel">
            <span>编号:</span>
            <div class="codeNum">
              <input type="text" :maxlength="2" v-model="rightData.leftCode" @keyup.enter="serach(1)" class="input" />
              <div></div>
              <input type="text" :maxlength="4" v-model="rightData.rightCode" @keyup.enter="serach(1)"
                class="input Rightinput" />
            </div>
          </div>

          <div class="inputModel hex">
            <span>Hex:</span>
            <div class="Hexinput">
              #
              <input type="text" v-model="rightData.inputColor" :maxlength="6" @keyup.enter="serach(2)" />
            </div>
          </div>
          <div class="rgbNum">
            <div class="item">
              <span class="ZMBlock">R:</span>
              <span class="numBox">{{ rightData.rgb.length != 0 ? rightData.rgb[0] : "255" }}
              </span>
            </div>
            <div class="item">
              <span class="ZMBlock">G:</span>
              <span class="numBox">
                {{ rightData.rgb.length != 0 ? rightData.rgb[1] : "255" }}
              </span>
            </div>
            <div class="item">
              <span class="ZMBlock">B:</span>
              <span class="numBox">
                {{ rightData.rgb.length != 0 ? rightData.rgb[2] : "255" }}
              </span>
            </div>
          </div>
          <div class="useColor">
            <div class="useColorTxt">{{ $t('machine.txt95') }}</div>
            <div class="useList">
              <div v-for="(item, index) in useList" :key="index">
                <div class="item" @click="adduseColor(item, index)" :style="`background-color:${item.hexCode};`">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arr from "./pts";
export default {
  props: ["ptsData"],
  data() {
    return {
      pageType: 1, //判断是个人还是公共
      colorList: [], //14潘通色集合
      listIndex: 0, //当前14潘通色集合索引位置
      sliderNum: 190, //当前所点击的潘通色集合索引
      checkColorindex: "a", //当前选择的颜色
      tipsTxt: false, //控制弹框提示显示
      numberCode: "", //编号
      tipsTime: "", //tipsTxt
      userYarnColor: [], //个人颜色
      yarnNo: "",
      pageBtnType: true,
      rightData: {
        leftCode: "",
        rightCode: "",
        color: "",
        rgb: [],
        firstIndex: 0,
        secondIndex: 0,
        inputColor: "",
      },
      colorType: [
        {
          value: "1",
          label: "Cool colour",
        },
        {
          value: "2",
          label: "Warm colour",
        },
      ],
      colorTypeNum: "1",
      useList: [],//最近使用的颜色
    };
  },
  watch: {
    numberCode(newVal) {
      this.numberCode = newVal.trim().replace(/[^0-9]/g, "");
    },
    ptsData(newVal) {
      if (newVal.type == undefined) {
        return;
      }
      this.useList = JSON.parse(localStorage.getItem('useListColor'))
      this.rightData = {
        leftCode: "",
        rightCode: "",
        color: "",
        rgb: [],
        firstIndex: 0,
        secondIndex: 0,
        inputColor: "",
      };
      if (newVal.type == 1) {
        this.sliderNum = this.colorList.length - 1;
        this.setslideBlock();
        this.createData(1)
      } else {
        this.colorTypeNum = newVal.colorItem.colorType;
        this.getPtsList();
        this.sliderNum =
          this.colorList.length - newVal.colorItem.firstIndex - 1;
        this.checkColorindex = newVal.colorItem.secondIndex;
        this.createData(2, newVal.colorItem.secondIndex);
      }
    },
    rightData: {
      deep: true,
      handler(newVal) {
        let str1 = newVal.leftCode.replace(/[^0-9]/g, "");
        let str2 = newVal.rightCode.replace(/[^0-9]/g, "");
        let str3 = newVal.inputColor.replace(/[^0-9a-zA-Z]/g, "");
        this.$set(this.rightData, "leftCode", str1);
        this.$set(this.rightData, "rightCode", str2);
        this.$set(this.rightData, "inputColor", str3);
      },
    },
  },
  mounted() {
    this.colorList = arr.cold;
    setTimeout(() => {
      this.sliderNum = this.colorList.length;
    }, 100);
  },
  methods: {
    //滑动滑块
    setslideBlock(type) {
      this.checkColorindex = "a";
      if (type == 1) {
        let item;
        item = this.colorList[this.colorList.length - 1 - this.sliderNum][0];
        let data = {
          leftCode: item.nr.substring(8, 10),
          rightCode: item.nr.substring(11, 15),
          color: item.hexCode,
          rgb: item.rgb.split(","),
          firstIndex: this.colorList.length - 1 - this.sliderNum,
          secondIndex: 0,
          inputColor: item.hexCode.substring(1, 7),
        };
        this.rightData = data;
      }
    },

    //右侧数据
    createData(type, secondIndex) {
      setTimeout(() => {
        this.checkColorindex = secondIndex;
        let item;
        item = this.colorList[this.colorList.length - 1 - this.sliderNum][type == 2 ? secondIndex : 0];
        let data = {
          leftCode: item.nr.substring(8, 10),
          rightCode: item.nr.substring(11, 15),
          color: item.hexCode,
          rgb: item.rgb.split(","),
          firstIndex: this.colorList.length - 1 - this.sliderNum,
          secondIndex: secondIndex,
          inputColor: item.hexCode.substring(1, 7),
        };
        this.rightData = data;
      }, 10);
    },

    //点击颜色模块
    setpts(index, type) {
      this.checkColorindex = "a";
      if (this.colorList.length == 0) {
        return;
      }
      let item;
      if (type == 1) {
        item = this.colorList[index][0];
        this.sliderNum = this.colorList.length - index;
      } else {
        item = this.colorList[this.colorList.length - this.sliderNum - 1][index];
      }
      let data = {
        leftCode: item.nr.substring(8, 10),
        rightCode: item.nr.substring(11, 15),
        color: item.hexCode,
        rgb: item.rgb.split(","),
        firstIndex:
          type == 1 ? index : this.colorList.length - this.sliderNum - 1,
        secondIndex: type == 1 ? 0 : index,
        inputColor: item.hexCode.substring(1, 7),
        colorType: this.colorTypeNum,
      };
      this.rightData = data;
      if (type != 1) {
        this.colorResult(true);
      }
    },

    //使用最近使用颜色
    adduseColor(item, index) {
      this.rightData = item;
      this.colorResult(true, 1);
    },

    //搜索
    serach(type) {
      this.checkColorindex = "a";
      let key = "";
      let searchTxt = "";
      let arrType = 1
      if (type == 1) {
        key = "nr";
        searchTxt = `${this.rightData.leftCode}${this.rightData.rightCode}`;
      } else {
        key = "hexCode";
        searchTxt = `#${this.rightData.inputColor}`;
      }

      let firstIndex = "";
      let secondIndex = "";
      for (var listName in arr) {
        arr[listName].forEach((item, index) => {
          item.forEach((items, indexs) => {
            if (key == "nr") {
              if (
                `${items.nr.substring(8, 10)}${items.nr.substring(11, 15)}` ==
                searchTxt
              ) {
                firstIndex = index;
                secondIndex = indexs;
                if (listName == 'warm') {
                  this.colorTypeNum = '2'
                } else {
                  this.colorTypeNum = '1'
                }
              }
            } else {
              if (items.hexCode == searchTxt) {
                firstIndex = index;
                secondIndex = indexs;
                if (listName == 'warm') {
                  this.colorTypeNum = '2'
                } else {
                  this.colorTypeNum = '1'
                }
              }
            }
          });
        });
      }
      if (firstIndex != "a") {
        this.getPtsList()
        this.checkColorindex = secondIndex;
        this.sliderNum = this.colorList.length - firstIndex - 1;
        this.createData(2, secondIndex);
      }
    },

    //切换冷暖色
    getPtsList() {
      this.checkColorindex = "a";
      if (this.colorTypeNum == 1) {
        this.colorList = arr.cold;
      } else {
        this.colorList = arr.warm;
      }
      this.rightData = {
        leftCode: "",
        rightCode: "",
        color: "",
        rgb: [],
        firstIndex: 0,
        secondIndex: 0,
        inputColor: "",
      };
      this.sliderNum = this.colorList.length - 1;
      this.setslideBlock();
    },

    //切换潘通色索引(上下箭头按钮)
    setColorList(type) {
      this.checkColorindex = "a";
      if (type == 1) {
        if (this.sliderNum != this.colorList.length - 1) {
          this.sliderNum++;
        }
      } else {
        if (this.sliderNum != 0) {
          this.sliderNum--;
        }
      }
      this.setslideBlock();
    },

    //弹框确认与关闭
    colorResult(type, arrType) {
      this.checkColorindex = "a";
      if (type) {
        let colorItem
        if (arrType == 1) {
          colorItem = this.rightData
        } else {
          colorItem = {
            ...this.colorList[this.rightData.firstIndex][
            this.rightData.secondIndex
            ],
            firstIndex: this.rightData.firstIndex,
            secondIndex: this.rightData.secondIndex,
            colorType: this.rightData.colorType,
          };
        }
        this.$emit("colorResult", {
          type: true,
          data: {
            pupData: this.ptsData,
            color: colorItem,
          },
        });
      } else {
        this.$emit("colorResult", {
          type: false,
          color: "",
        });
      }

      this.rightData = {
        leftCode: "",
        rightCode: "",
        color: "",
        rgb: [],
        firstIndex: 0,
        secondIndex: 0,
        inputColor: "",
      };
      this.sliderNum = this.colorList.length - 1;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./panTongse.less");

.el-select-dropdown__item {
  font-size: 14px !important;
  color: #333;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0 14px;
}

.el-select-dropdown__item span {
  font-size: 14px !important;
}
</style>