<template>
  <div>
    <!-- 花高花宽入口页 -->
    <JacquardWeaveinlet v-if="JacquardWeaveinletHidden" @jacquardWeaveinletSend="jacquardWeaveinletSend" />

    <!-- 导入图片页面 -->
    <GetImgcolor :hg="hg" :hk="hk" :sockbodyPosition="sockbodyPosition" :imgPupType="imgPupType"
      :machineNumber="machineNumber" :yarnListLength="yarnList.length" ref="GetImgcolor"
      @sendStructureImgData="sendStructureImgData" @hiddenPup="hiddenPup" @sendImgData="getImgData"
      v-if="GetImgcolorHidden" />

    <!-- 提示弹框 -->
    <Pup v-show="dialogTableVisible" @sendRes="getPupres" :pupData="pupData" />

    <!-- 潘通色组件 -->
    <PanTongse @colorResult="getNewYarn" ref="panTongse" v-show="PanTongseHidden" :ptsData="ptsData" />

    <!-- 主题画布页面 -->
    <div class="oneSizeindex" @click="monitoringCilck($event)" :style="`opacity: ${!JacquardWeaveinletHidden ? 1 : 0};`">
      <!-- 页面顶部导航 -->
      <div class="pageHead">
        <!-- 顶部左侧导航按钮 -->
        <div class="leftIconBtnBox">
          <!-- 按钮循环标签 -->
          <div v-for="(item, index) in headIcon" :key="index" style="display: flex; align-items: center">
            <div :id="item.calssName" :class="rasterizing
              ? 'item'
              : index == 1 || index == 3 || index == 5
                ? 'itemOpacity'
                : 'item'
              " @click="clickBtntype(index)" :style="index == 1 && ListenerslideType
    ? 'background-color: #f5f5f5;'
    : ''
    " v-show="index == 2 ? false : true">
              <div class="imgBox" :id="item.calssName">
                <img :id="item.calssName" :class="item.calssName" :src="item.img" alt="" />
              </div>
              <div :id="item.calssName" class="iconTxt">{{ item.name }}</div>
            </div>
            <div class="conBorder" v-if="index == 2"></div>
            <div class="conBorder" v-if="index == 6"></div>
          </div>
        </div>
        <!-- 顶部右侧按钮 -->
        <div class="rightBtn" @click="saveShow = true">
          {{ $t("machine.txt20") }}
        </div>
        <!-- @click="isPreviewPup = 3" -->
      </div>
      <!-- 页面画布与右侧控制器 -->
      <div class="oneSizeindexCon">
        <div class="leftCon">
          <div :style="dragType ? 'overflow: hidden;' : 'overflow: auto;'" class="box testBox"
            @scroll="getScrollRendercanvas">
            <div class="canvasBox" :style="`top:${canvasTop}px;left:${canvasLeft}px`">
              <div class="leftTopbox" v-show="tableCanvas == '' ? false : true">
                0
              </div>
              <canvas id="tableCanvas" @mousedown.left.stop="mouseLeftDown" @mouseup.left.stop="mouseLeftUp"></canvas>
              <div class="mouseMask" @mouseup.left="stopousemoveImgcnavas" v-show="imgCanvasmaskType"></div>
              <div class="ImgBox" v-show="imgCanvasmaskType">
                <canvas id="imgCanvas" class="img" :style="`left:${l}px;top:${t}px`" draggable="false"></canvas>
              </div>
            </div>
            <div :style="`width:${canvasBoxSize.w + 10}px;height:${canvasBoxSize.h + 10
              }px`"></div>
          </div>
        </div>
        <div class="rightCon">
          <div class="title">{{ $t("machine.txt21") }}</div>
          <div class="canvasData">
            <div class="item">
              <span class="itemTitle">{{ $t("machine.txt22") }}</span>
              <div class="itemRight">
                <span class="modelTitle">{{ $t("machine.txt24") }}</span>
                <div :class="$store.state.index.languageType == 'en'
                  ? 'sizeBox'
                  : 'sizeBox sizeBoxzh'
                  ">
                  <span></span><span class="sizeNum">{{ hk }}</span><span class="unit"></span>
                </div>
                <span class="modelTitle hg">{{ $t("machine.txt25") }}</span>
                <div :class="$store.state.index.languageType == 'en'
                  ? 'sizeBox'
                  : 'sizeBox sizeBoxzh'
                  ">
                  <span></span><span class="sizeNum">{{ hg }}</span><span class="unit"></span>
                </div>
              </div>
            </div>
            <div class="item">
              <span class="itemTitle">{{ $t("machine.txt23") }}</span>
              <div class="itemRight">
                <div class="scaleBox">
                  <img src="@/images/canvasNarrow@2x.png" alt="" @click="setZoomnum(1)" />
                  <div class="Number">
                    <span>{{ zoomNum }}</span> %
                  </div>
                  <img src="@/images/canvasEnlarge@2x.png" alt="" @click="setZoomnum(2)" />
                </div>
              </div>
            </div>
            <!-- <div class="item" v-show="pageType == 2">
              <span class="itemTitle">背景：</span>
              <div class="itemRight">
                <span class="modelTitle">宽</span>
                <div :class="$store.state.index.languageType=='en'?'sizeBox':'sizeBox'">
                  <span></span><span class="sizeNum">{{ hk }}</span
                  ><span class="unit">cm</span>
                </div>
                <span class="modelTitle hg">高</span>
                <div :class="$store.state.index.languageType=='en'?'sizeBox':'sizeBox'">
                  <span></span><span class="sizeNum">{{ hg }}</span
                  ><span class="unit">cm</span>
                </div>
              </div>
            </div> -->
            <div class="item" :style="index == layerList.length - 1 ? 'margin-bottom:0' : ''"
              v-for="(item, index) in layerList" :key="index" v-show="rasterizing">
              <span class="itemTitle">{{ $t("machine.txt51") }}{{ item.label }}:</span>
              <div class="itemRight">
                <span class="modelTitle">{{ $t("machine.txt24") }}</span>
                <div :class="$store.state.index.languageType == 'en'
                  ? 'sizeBox'
                  : 'sizeBox sizeBoxzh'
                  ">
                  <span></span><span class="sizeNum">{{ item.size.w }}</span><span class="unit"></span>
                </div>
                <span class="modelTitle hg">{{ $t("machine.txt25") }}</span>
                <div :class="$store.state.index.languageType == 'en'
                  ? 'sizeBox'
                  : 'sizeBox sizeBoxzh'
                  ">
                  <span></span><span class="sizeNum">{{ item.size.h }}</span><span class="unit"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="rightTopbtn">
            <div :class="$store.state.index.languageType == 'zh'
              ? 'itemBtn leftBtnzh'
              : 'itemBtn leftBtnen'
              " @click="openStructureorcolor(1)">
              <span :style="itemBtn == 2 ? `color:#999;` : ''">{{
                $t("machine.txt26")
              }}</span>
              <span class="BorderBlock" :style="itemBtn == 2 ? `background:#fff;` : ''"></span>
            </div>
            <div class="itemBtn" @click="openStructureorcolor(2)">
              <span :style="itemBtn == 1 ? `color:#999;` : ''">{{
                $t("machine.txt27")
              }}</span>
              <span class="BorderBlock" :style="itemBtn == 1 ? `background:#fff;` : ''"></span>
            </div>
            <div class="itemBtn"></div>
          </div>
          <!-- 右侧控制颜色 -->
          <div class="colorBox" v-show="itemBtn == 1">
            <div class="colorList">
              <div class="Socks">
                <div class="positionName firstTxt">
                  {{ $t("machine.txt86") }}
                </div>
                <div v-if="yarnList.length < 8" class="addBox" id="addBox">
                  <img src="@/newImg/addbtnImg.png" alt="" class="addBtn" id="addBox" @click="openPTSpup(1)" />
                  <div class="tips">{{ $t("machine.txt29") }}</div>
                </div>
                <div class="item" id="addBox" v-for="(item, index) in yarnList" :key="index">
                  <div class="colorItem" id="addBox" @click="openPTSpup(2, index)">
                    <img src="@/newImg/convert.png" alt="" id="setColor" class="convertImg" />
                    <div class="color" :style="`background:${item.hexCode};`" id="addBox"></div>
                  </div>
                  <span class="nr" id="addBox">{{ item.nr }}</span>
                </div>
              </div>

              <div v-for="(item, index) in positionColorList" :key="index + 10000">
                <div class="positionName">
                  {{ $t(`machine.${positionColorListName[index]}`) }}
                </div>
                <div class="item" @click="openPTSpupsetposition(index)">
                  <div class="colorItem" id="addBox">
                    <img src="@/newImg/convert.png" alt="" class="convertImg" />
                    <div class="color" :style="`background:${item.hexCode};`"></div>
                  </div>
                  <span class="nr">{{ item.nr }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 右侧控制结构 -->
          <div v-show="itemBtn == 2">
            <div class="StructureImg">
              <div class="topTilte">
                <span></span>
                <p>{{ $t("machine.txt96") }}</p>
              </div>
              <div class="upStructureImg" @click="getStructureImg">
                <div class="img" v-show="upStructureImg == ''">
                  <img src="@/newImg/upimg.png" alt="" />
                  <span>{{ $t("machine.txt97") }}</span>
                  <span>{{ $t("machine.txt98")
                  }} {{ sockbodyPosition.rightBottom != undefined ? sockbodyPosition.rightBottom.x -
  sockbodyPosition.leftTop.x + 1 : 0 }} *
                    {{ sockbodyPosition.rightBottom != undefined ? sockbodyPosition.rightBottom.y -
                      sockbodyPosition.leftTop.y + 1 : 0 }}</span>
                </div>
                <div class="upStructureImgBox" v-show="upStructureImg != ''">
                  <el-image style="width: 100%; height: 100%" :src="upStructureImg" fit="contain"></el-image>
                  <img src="@/newImg/hidden.png" @click.stop="delStructureImg" alt="" class="hiddenImg" />
                </div>
              </div>
            </div>
            <div class="colorSwitch">
              <div class="topTilte">
                <span></span>
                <p>{{ $t("machine.txt99") }}</p>
              </div>
              <div class="colorSwitchBox">
                <div :class="$store.state.index.languageType == 'en'
                  ? 'coloritem rightMaren'
                  : 'coloritem rightMarzh'
                  ">
                  <span class="checkBox" @click="setPagecolorTable(1)">
                    <span v-show="pagecolorType == 1"></span>
                  </span>
                  <div>{{ $t("machine.txt100") }}</div>
                </div>
                <div class="coloritem">
                  <span class="checkBox" @click="setPagecolorTable(2)">
                    <span v-show="pagecolorType == 2"></span>
                  </span>
                  <div>{{ $t("machine.txt101") }}</div>
                </div>
              </div>
            </div>
            <div class="StructureBox">
              <div class="topTilte">
                <span></span>
                <p>{{ $t("machine.txt30") }}</p>
              </div>
              <div class="Structurethumbnail" :style="`${StructureStyleW ? 'align-items: start;' : ''}${StructureStyleH ? ' justify-content: start;' : ''
                }`">
                <div :class="i == 0 ? 'fristItem fristBorder' : 'fristItem'" v-for="(item, i) in Structure" :key="i">
                  <div class="items StructureItem" v-for="(items, j) in item" :key="j"
                    :style="`width: ${FullDataStructureW}px;height: ${FullDataStructureH}px;`">
                    <img :src="StructureKey[items - 1]" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 右侧控制自定义结构 -->
            <div class="StructureBox">
              <div class="topTilte">
                <span></span>
                <p>{{ $t("machine.txt31") }}</p>
              </div>
              <div class="CustomStructure">
                <div class="top">
                  <div class="topLeft">
                    <!-- <span></span> -->
                    <p>{{ $t("machine.txt68") }}</p>
                  </div>
                  <div @click="openStructurePup(1)">
                    <span>{{ $t("machine.txt32") }}</span>
                    <img src="@/newImg/Custom.png" alt="" />
                  </div>
                </div>

                <div class="useStructure">
                  <div class="item" v-for="(item, index) in addStructureList" :key="index"
                    @click="setCheckStructureIndex(index)" :style="index == checkStructureIndex ? 'border-color:#999999' : ''
                      ">
                    <div class="identifying" v-show="item.type">
                      {{ $t("machine.txt69") }}
                    </div>
                    <div class="StructureName">
                      {{ $t("machine.txt103") }}{{ item.index }}
                    </div>
                    <div class="itemRightbtn">
                      <div @click.stop="eidtOrdel(1, index)" class="eidt">
                        {{ $t("machine.txt70") }}
                      </div>
                      <div :style="addStructureList.length == 1 ? 'color: #D0D1D1;' : ''
                        " @click.stop="eidtOrdel(2, index)" class="del">
                        {{ $t("machine.txt71") }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="topLeft defaultStructuretop">
                  <!-- <span></span> -->
                  <p>{{ $t("machine.txt72") }}</p>
                </div>
                <div class="defaultStructure">
                  <div class="item" @click="openStructurePup(2, index)" v-for="(item, index) in StructureLibrary"
                    :key="index" :style="(index + 1) % 3 == 0 ? 'margin-right: 0;' : ''">
                    <img :src="require(`@/newImg/StructureLibrary/clearness${item.imgName}.png`)
                      " alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右侧图层(title) -->
          <div class="title" v-show="itemBtn == 1">
            {{ $t("machine.txt28") }}
          </div>
          <!-- 右侧图层(内容) -->
          <div class="layer" v-show="itemBtn == 1">
            <div class="layerItem" v-for="(item, index) in layerList" :key="index" @click="checkImgindex = item.imgIndex"
              :style="checkImgindex == item.imgIndex
                ? 'background:rgba(216, 216, 216, 0.2)'
                : ''
                " v-show="rasterizing">
              <div class="left">
                <img src="@/newImg/show@2x.png" alt="" class="show" v-show="item.isShow"
                  :style="rasterizing ? '' : 'opacity: 0.6;'" @click.stop="showlayer(index, 1)" />
                <img src="@/newImg/hide@2x.png" alt="" class="hide" @click.stop="showlayer(index, 2)"
                  :style="rasterizing ? '' : 'opacity: 0.6;'" v-show="!item.isShow" />
                <div class="layerImg">
                  <el-image style="width: 100%; height: 100%" :src="item.img" fit="contain"></el-image>
                </div>
                <span class="layerName">{{ $t("machine.txt51") }}{{ item.label }}</span>
              </div>
              <img :src="require('@/newImg/delImg@2x.png')" class="delBnt" @click.stop="showlayer(index, 3)" alt=""
                :style="rasterizing ? '' : 'opacity: 0.6;'" />
            </div>
            <div class="layerItem" :style="checkImgindex == 0 ? 'background:rgba(216, 216, 216, 0.2)' : ''
              " @click="checkImgindex = 0" v-show="pageType == 1 || !rasterizing">
              <div class="left">
                <div class="show"></div>
                <div class="layerImg">
                  <el-image style="width: 100%; height: 100%" :src="require(`@/newImg/socks${checksheetIndex + 1}.png`)"
                    fit="contain"></el-image>
                </div>
                <span class="layerName">{{ $t("machine.txt67") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 图片循环和预览弹框 -->
    <div class="PreviewPup" v-show="isPreviewPup != 0">
      <!-- 循环图层弹框 -->
      <div class="imgForPup" v-show="isPreviewPup == 1">
        <div class="pupHead">
          <span>{{ $t("machine.txt34") }}</span>
          <img src="@/newImg/hidden.png" @click="hideSlTpup" alt="" />
        </div>
        <div class="Pupitem">
          <div class="itemTitle">{{ $t("machine.txt35") }}</div>
          <div class="selectBox">
            <div class="errTips" v-show="errTipstype">
              {{ $t("machine.txt36") }}
            </div>
            <el-select :class="errTipstype ? 'select errSelect' : 'select'" v-model="layerIndex"
              :placeholder="$t('machine.txt9')" @focus="errTipstype = false" @change="renderlayerThumbnail">
              <el-option v-for="item in layerList" :key="item.value" :label="`${$t('machine.txt51')}${item.label}`"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="imgForPupCon">
          <div class="leftBox">
            <div class="itemTitle">{{ $t("machine.txt37") }}</div>
            <div :class="$store.state.index.languageType == 'zh' ? 'type' : 'typeen'
              ">
              <div class="l_item">
                <img src="@/newImg/for1img@2x.png" alt="" />
                <div class="checkItem">
                  <span @click="renderForcanvas(1)">
                    <div v-show="forImgIndex == 1"></div>
                  </span>
                  <div class="txt txt1">{{ $t("machine.txt39") }}</div>
                </div>
              </div>
              <div class="l_item l_itemCenter">
                <img src="@/newImg/for2img@2x.png" alt="" />
                <div class="checkItem">
                  <span @click="renderForcanvas(2)">
                    <div v-show="forImgIndex == 2"></div>
                  </span>
                  <div class="txt txt2">{{ $t("machine.txt40") }}</div>
                </div>
              </div>
              <div class="l_item">
                <img src="@/newImg/for3img@2x.png" alt="" />
                <div class="checkItem">
                  <span @click="renderForcanvas(3)">
                    <div v-show="forImgIndex == 3"></div>
                  </span>
                  <div class="txt txt3">{{ $t("machine.txt41") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="rigthBox">
            <div class="itemTitle" style="padding: 0">
              {{ $t("machine.txt38") }}
            </div>
            <div class="thumbnailBox">
              <el-image v-show="thumbnailImgurl" style="width:98%;height:98%" :src="thumbnailImgurl"
                fit="contain"></el-image>
            </div>
            <div class="bottomBox">
              <span></span>
              <div class="btnBox">
                <div class="btn" @click="hideSlTpup">
                  {{ $t("machine.txt42") }}
                </div>
                <div class="btn rigthBtn" @click="createForimgdata">
                  {{ $t("machine.txt43") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 预览画布弹框 -->
      <div class="PreviewCanvas" v-show="isPreviewPup == 2">
        <div class="pupHead">
          <span>{{ $t("machine.txt44") }}</span>
          <img src="@/newImg/hidden.png" @click="hiddenPreviewCanvas" alt="" />
        </div>
        <div class="con">
          <!-- 左侧缩略图 -->
          <div class="left">
            <el-image style="width:98%;height:98%" :src="thumbnailImgurl" fit="contain"></el-image>
          </div>
          <!-- 右侧信息 图层 纱线 -->
          <div class="right">
            <div class="title">{{ $t("machine.txt45") }}</div>
            <div class="dataBox">
              <div class="item">
                <div class="leftTxt">{{ $t("machine.txt46") }}</div>
                <div class="rightTxt">{{ $t("machine.txt47") }}</div>
              </div>
              <div class="item">
                <div class="leftTxt">{{ $t("machine.txt48") }}</div>
                <div class="rightTxt">E{{ machineNumber }}</div>
              </div>
              <div class="item">
                <div class="leftTxt">{{ $t("machine.txt49") }}</div>
                <div class="rightTxt">{{ hk }}*{{ hg }}</div>
              </div>
              <div class="item" v-for="(item, index) in layerList" :key="index">
                <div class="leftTxt">
                  {{ $t("machine.txt51") }}{{ item.label }}
                  {{ $t("machine.txt52") }}
                </div>
                <div class="rightTxt">{{ item.size.w }}*{{ item.size.h }}</div>
              </div>
            </div>
            <div class="title">{{ $t("machine.txt50") }}</div>
            <div class="yarnListTable">
              <div class="tableTilte">
                <div :class="`item item${index + 1}`" v-for="(item, index) in HeadList" :key="index">
                  <span>{{ item }}</span>
                </div>
              </div>
              <div class="tableList">
                <!-- 袜身颜色 -->
                <div class="SocksleftBox">
                  <span>{{ $t("machine.txt91") }}</span>
                </div>
                <div style="width: 100%;">
                  <div v-for="(item, index) in yarnList" :key="index" class="tableCon">
                    <div class="items item2">
                      <span class="spanBox" :style="`background:${item.hexCode};`"></span>
                    </div>
                    <div class="items item3">
                      <span>{{ item.nr }}</span>
                    </div>
                    <div class="items item4" style="cursor: pointer">
                      <span v-for="(items, indexs) in item.component.data" :key="indexs + 1000"
                        v-show="item.component.data.length == 0 ? false : true">
                        {{ items.proportion }}%{{
                          materialQuality[items.componentNum].label
                        }}
                      </span>
                      <span style="color: #cccccc" v-show="item.component.data.length != 0 ? false : true">—</span>
                    </div>
                    <div class="items item5" style="cursor: pointer">
                      <span v-show="item.YarnCount.num == '' ? false : true">{{ item.YarnCount.num }}
                        {{
                          item.YarnCount.num != ""
                          ? item.YarnCount.unit == 1
                            ? "D"
                            : "S"
                          : ""
                        }}</span>
                      <span style="color: #cccccc" v-show="item.YarnCount.num != '' ? false : true">—</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 袜子特殊部位 -->
              <div>
                <div v-for="(item, index) in 3" :key="index" class="tableCon">
                  <div class="items item1 leftBorder">
                    {{ $t(`machine.txt${92 + index}`) }}
                  </div>
                  <div class="items item2">
                    <span class="spanBox" :style="`background:${positionColorList[index].hexCode};`"></span>
                  </div>
                  <div class="items item3">
                    <span>{{ positionColorList[index].nr }}</span>
                  </div>
                  <div class="items item4" style="cursor: pointer">
                    <span v-for="(items, indexs) in positionColorList[index].component.data" :key="indexs + 1000"
                      v-show="positionColorList[index].component.data.length == 0 ? false : true">
                      {{ items.proportion }}%{{
                        materialQuality[items.componentNum].label
                      }}
                    </span>
                    <span style="color: #cccccc"
                      v-show="positionColorList[index].component.data.length != 0 ? false : true">—</span>
                  </div>
                  <div class="items item5" style="cursor: pointer">
                    <span v-show="positionColorList[index].YarnCount.num == '' ? false : true">{{
                      positionColorList[index].YarnCount.num }}
                      {{
                        positionColorList[index].YarnCount.num != ""
                        ? positionColorList[index].YarnCount.unit == 1
                          ? "D"
                          : "S"
                        : ""
                      }}</span>
                    <span style="color: #cccccc"
                      v-show="positionColorList[index].YarnCount.num != '' ? false : true">—</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 提交选中渲染形式弹框 -->
      <div class="subimt" v-show="isPreviewPup == 3">
        <div class="pupHead">
          <span>画布展示设置</span>
          <img src="@/newImg/hidden.png" @click="isPreviewPup = 0" alt="" />
        </div>
        <div class="subimtCon">
          <div class="leftBox">
            <div class="itemTitle">选择面料展示方式：</div>
            <div class="type">
              <div class="l_item">
                <div class="checkItem">
                  <span @click="subimtType = 1">
                    <div v-show="subimtType == 1"></div>
                  </span>
                  <div class="txt">循环平铺</div>
                </div>
              </div>
              <div class="l_item l_itemCenter">
                <div class="checkItem">
                  <span @click="subimtType = 2">
                    <div v-show="subimtType == 2"></div>
                  </span>
                  <div class="txt">全幅平铺</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomBox">
          <span></span>
          <div class="btnBox">
            <div class="btn" @click="isPreviewPup = 0">关闭</div>
            <div class="btn rigthBtn" @click="saveShow = true">确定</div>
          </div>
        </div>
      </div>
    </div>
    <!-- canvas外的遮罩层判断是否在cnavas外抬起鼠标 -->
    <div class="outmask" @mouseup.left.stop="outCanvasmouseup" v-show="iSmousemove"></div>
    <!-- 画笔整体遮罩层 -->
    <!-- <div class="paintingbrushMask" v-show="ISpaintingbrushMask" @click.left.stop="clearmouseTable"></div> -->
    <!-- 保存弹框组件 -->
    <NewsavePup @saveSend="getSaveData" v-if="saveShow" />
    <!-- 提交成功弹框 -->
    <div class="saveSuccessPup" v-show="submitSuccess">
      <div class="saveSuccess">
        <div class="content">
          <div class="successContent">
            <svg-icon icon-class="successIcon" class="warnIcon" />
            <p>{{ $t("machine.txt53") }}</p>
          </div>
          <div class="TipsTxt">{{ $t("machine.txt78") }}</div>
          <div class="btnBox">
            <div @click="backWeaving()" class="btn">
              {{ $t("machine.txt54") }}
            </div>
            <div @click="toList()" class="cancelButton btn">
              {{ $t("machine.txt55") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公共弹框组件 -->
    <CommonDialog :dialogVisible="isShowDialog" :content="setContent" :confirmContent="setConfirmContent"
      @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></CommonDialog>
  </div>
</template>

<script>
//花高花宽入口页
import JacquardWeaveinlet from "../component/jacquardWeaveinlet/jacquardWeaveinlet.vue";

//潘通色组件
import PanTongse from "../component/panTongse/panTongse.vue";

//提示弹框
import Pup from "../component/Pup/Pup.vue";

//导入多线程计算底层数据结构
import tabledataWorker from "./worker/tabledata.worker.js";

//导入图片页面
import GetImgcolor from "../component/getImgcolor/getImgcolor.vue";

//保存弹框组件
import NewsavePup from "@/components/newSavePup/newSavePup";

//公共弹框组件
import CommonDialog from "@/components/commonDialog/CommonDialog.vue";

//接口（生成id，复用）
import {
  getresult,
  resultThsave,
  uplaodImage,
  saveThCloth,
} from "@/api/index/index";

//结构库数据
import { StructureLibrary } from "./data";

var tableArr = []; //底层数据结构
var imgCanvasList = []; //当前导入的图片数组
var saveData = [];
var checktableArr = []; //底层画布画笔修改的位置
var dataObj = ""; //保留页面初始化数据
var ReuseData = ""; //复用数据
export default {
  components: {
    JacquardWeaveinlet,
    GetImgcolor,
    Pup,
    PanTongse,
    NewsavePup,
    CommonDialog,
  },
  data() {
    return {
      pageType: 0, //判断是自由绘画还是导入图片
      // 花型页面参数
      hg: 20, //花型高度
      hk: 20, //花型宽度
      machineNumber: 20, //花型针数
      // 组件控制和转递传参
      JacquardWeaveinletHidden: true, //控制花高花宽入口页显示或隐藏
      GetImgcolorHidden: false, //控制导入图片页面显示或隐藏
      dialogTableVisible: false, //控制弹框组件显示与隐藏
      PanTongseHidden: false, //控制潘通色组件显示与隐藏
      saveShow: false, //控制保存弹框组件显示与隐藏
      headIcon: [
        //顶部导航按钮文案与图片
        {
          img: require("@/newImg/newBuild@2x.png"),
          name: this.$t("machine.txt13"),
          calssName: "img1",
        },
        {
          img: require("@/newImg/paintingbrush@2x.png"),
          name: this.$t("machine.txt14"),
          calssName: "img2",
        },
        {
          img: require("@/newImg/retreat@2x.png"),
          name: this.$t("machine.txt15"),
          calssName: "img3",
        },
        {
          img: require("@/newImg/import@2x.png"),
          name: this.$t("machine.txt16"),
          calssName: "img4",
        },
        {
          img: require("@/newImg/yarn@2x.png"),
          name: this.$t("machine.txt17"),
          calssName: "img5",
        },
        {
          img: require("@/newImg/circulate@2x.png"),
          name: this.$t("machine.txt18"),
          calssName: "img6",
        },
        {
          img: require("@/newImg/Preview@2x.png"),
          name: this.$t("machine.txt19"),
          calssName: "img7",
        },
        {
          img: require("@/newImg/xz.png"),
          name: this.$t("machine.txt102"),
          calssName: "img8",
        },
      ],
      canvasBoxSize: {
        //canvas真实渲染大小（撑起滚动条）
        w: 0,
        h: 0,
      },
      tableCanvas: "", //显示canvas
      context: "", //canvas的2d
      tableItemsize: 20, //网格大小
      VisualwindowSize: {
        //可是窗口显示格子数量
        w: 0,
        h: 0,
      },
      pupData: {}, //弹框组件使用数据和判断弹框类型
      dataObj: "", //存储初始数据页面新建使用
      ListenerslideType: false, //是否使用选框功能
      checkListenerslideArr: [], //选框选中的网格
      cilckListenerslideArr: [], //点击时获取当前滚动条数据
      longPressTime: "", //清除长按延时器
      l: 0, //图片左侧定位
      t: 0, //图片顶部定位
      imgCanvasmaskType: false, //图片遮罩层防止滑动时图片闪烁
      iScreateImgCanvas: false, //是否生成新的图片canvas
      newFristindex: 0, //鼠标拖拽位置记录x轴
      newSecondindex: 0, //鼠标拖拽位置记录y轴
      imgIndex: 0, //记录拖拽图片索引
      yarnList: [
        {
          nr: "PANTONE 16-3916 TPG",
          name: "Sleet",
          book: 2,
          page: 36,
          row: 1,
          hexCode: "#9C9EA4",
          rgb: "156,157,164",
          firstIndex: 35,
          secondIndex: 0,
          colorType: "1",
          component: {
            type: 1,
            data: [
              {
                componentNum: 0,
                proportion: 100,
              },
            ],
          },
          YarnCount: {
            num: 40,
            unit: 1,
          },
          bottomColor: true,
        },
        //纱线列表
      ],
      positionColorList: [
        {
          nr: "PANTONE 13-4302 TPG",
          name: "Skywriting",
          book: 2,
          page: 21,
          row: 2,
          hexCode: "#C8D3DF",
          rgb: "200,211,223",
          firstIndex: 20,
          secondIndex: 1,
          colorType: "1",
          component: {
            type: 1,
            data: [
              {
                componentNum: 0,
                proportion: 100,
              },
            ],
          },
          YarnCount: {
            num: 40,
            unit: 1,
          },
        },
        {
          nr: "PANTONE 14-4106 TPG",
          name: "Gray Dawn",
          book: 2,
          page: 42,
          row: 1,
          hexCode: "#B7BECB",
          rgb: "183,190,202",
          firstIndex: 41,
          secondIndex: 0,
          colorType: "1",
          component: {
            type: 1,
            data: [
              {
                componentNum: 0,
                proportion: 100,
              },
            ],
          },
          YarnCount: {
            num: 40,
            unit: 1,
          },
        },
        {
          nr: "PANTONE 13-4110 TPG",
          name: "Arctic Ice",
          book: 2,
          page: 23,
          row: 2,
          hexCode: "#C2C9D6",
          rgb: "194,201,213",
          firstIndex: 22,
          secondIndex: 1,
          colorType: "1",
          component: {
            type: 1,
            data: [
              {
                componentNum: 0,
                proportion: 100,
              },
            ],
          },
          YarnCount: {
            num: 40,
            unit: 1,
          },
        },
        {
          nr: "PANTONE 11-4001 TPG",
          name: "Brilliant White",
          book: 1,
          page: 1,
          row: 1,
          hexCode: "#EFF0F1",
          rgb: "239,240,241",
          bottom: true,
          component: {
            type: 1,
            data: [{ componentNum: 0, proportion: 100 }],
          },
          YarnCount: {
            num: 40,
            unit: 2,
          },
          firstIndex: 0,
          secondIndex: 0,
        },
      ], //特殊位置颜色
      positionColorListName: ["txt87", "txt88", "txt89", "txt90"], //特殊位置名称
      dragType: false, //是否开始拖拽
      iSmousemove: false, //是否操作canvas功能
      isPreviewPup: 0, //判断是否显示图片预览或图样循环弹框
      errTipstype: false, //循环弹框错误提示判断
      layerList: [
        //图层选择数据
      ],
      layerListNum: 0, //图层数量
      layerIndex: "", //当前选择的图层
      forImgIndex: 0, //选择循环图片方式
      zoomNum: 100, //当前缩放百分比
      ptsData: {}, //潘通色组件传参
      ISpaintingbrushMask: false, //画笔整体遮罩层
      thumbnailImgurl: "", //缩略图地址
      HeadList: [
        '',
        this.$t("pup.txt19"),
        this.$t("pup.txt20"),
        this.$t("pup.txt21"),
        this.$t("pup.txt22"),
      ],
      materialQuality: [
        {
          value: 0,
          label: this.$t("pup.txt24"),
        },
        {
          value: 1,
          label: this.$t("pup.txt25"),
        },
        {
          value: 2,
          label: this.$t("pup.txt26"),
        },
        {
          value: 3,
          label: this.$t("pup.txt27"),
        },
        {
          value: 4,
          label: this.$t("pup.txt28"),
        },
        {
          value: 5,
          label: this.$t("pup.txt29"),
        },
        {
          value: 6,
          label: this.$t("pup.txt30"),
        },
        {
          value: 7,
          label: this.$t("pup.txt31"),
        },
        {
          value: 8,
          label: this.$t("pup.txt32"),
        },
        {
          value: 9,
          label: this.$t("pup.txt33"),
        },
        {
          value: 10,
          label: this.$t("pup.txt34"),
        },
        {
          value: 11,
          label: this.$t("pup.txt35"),
        },
        {
          value: 12,
          label: this.$t("pup.txt36"),
        },
        {
          value: 13,
          label: this.$t("pup.txt37"),
        },
        {
          value: 14,
          label: this.$t("pup.txt38"),
        },
      ], //成分数据
      subimtType: 2, //选择提交渲染形式
      checkImgindex: 10000, //选择当前图层
      canvasTop: 0, //画布滚动位置y
      canvasLeft: 0, //画布滚动位置x
      itemBtn: 1, //控制右侧组件显示颜色或结构模块
      Structure: [
        //默认结构模块

      ],
      rasterizing: true, //栅格化状态
      FullDataStructureW: 0, //满屏状态结构模块宽度
      FullDataStructureH: 0, //满屏状态结构模块高度
      StructureStyleW: false, //结构模块宽度样式判断
      StructureStyleH: false, //结构模块高度样式判断
      StructureKey: [
        require("../../../newImg/cq.png"),
        require("../../../newImg/jq.png"),
        require("../../../newImg/fx.png"),
      ], //结构图片img
      checkStructuredataModel: ["", "", ""], //结构标识的imgData
      StructureLibrary, //结构库数据
      backImg: "", //背景图片
      designId: "", //保存时使用的id
      submitSuccess: false, //提交成功弹框
      StructureNum: 1, //生成结构数量
      checkStructureIndex: 10000, //当前选中的自定义结构索引
      StructureTipsType: true, //弹出一次提示结构弹框
      firstImgshow: true,
      addStructureList: [
        {
          index: "01",
          Structure: [
            [1, 1],
            [1, 1],
          ],
          type: false,
        },
      ],
      isShowDialog: false,
      setContent: this.$t("machine.txt76"),
      setConfirmContent: this.$t("machine.txt77"),
      delIndex: 0,
      delType: true, //图层删除
      renderIndex: 10000, //结构渲染索引
      renderIndexArr: [],
      checksheetIndex: 1, //当前选中的版单
      upStructureImg: "", //结构版单图
      sheetList: [
        //版单数据弹框
        { w: 236, h: 325, img: '01' },
        { w: 236, h: 411, img: '02' },
        { w: 236, h: 683, img: '03' },
        { w: 236, h: 251, img: '04' },
      ],
      imgPupType: 1, //导入结构或图样
      pagecolorType: 2, //结构页颜色和结构颜色转换
      StructureImgColorList: [], //结构颜色
      sockbodyPosition: [],//袜身大小
    };
  },
  watch: {
    //监听图层数量 (可不可删除)
    layerList: {
      deep: true,
      handler() {
        if (this.pageType == 2) {
          if (this.layerList.length == 1) {
            this.delType = false;
          } else {
            this.delType = true;
          }
          let layerListNum = 0;

          this.layerList.forEach((item, i) => {
            if (item.isShow) {
              layerListNum++;
            }
          });

          if (layerListNum < 2) {
            this.delType = false;
          } else {
            this.delType = true;
          }
        }
      },
    },
    //监听中英文切换
    "$store.state.index.languageType"(newVal, Val) {
      this.headIcon = [
        //顶部导航按钮文案与图片
        {
          img: require("@/newImg/newBuild@2x.png"),
          name: this.$t("machine.txt13"),
          calssName: "img1",
        },
        {
          img: require("@/newImg/paintingbrush@2x.png"),
          name: this.$t("machine.txt14"),
          calssName: "img2",
        },
        {
          img: require("@/newImg/retreat@2x.png"),
          name: this.$t("machine.txt15"),
          calssName: "img3",
        },
        {
          img: require("@/newImg/import@2x.png"),
          name: this.$t("machine.txt16"),
          calssName: "img4",
        },
        {
          img: require("@/newImg/yarn@2x.png"),
          name: this.$t("machine.txt17"),
          calssName: "img5",
        },
        {
          img: require("@/newImg/circulate@2x.png"),
          name: this.$t("machine.txt18"),
          calssName: "img6",
        },
        {
          img: require("@/newImg/Preview@2x.png"),
          name: this.$t("machine.txt19"),
          calssName: "img7",
        },
        {
          img: require("@/newImg/xz.png"),
          name: this.$t("machine.txt102"),
          calssName: "img8",
        },
      ];
    },
  },
  //根据id生成页面
  activated() {
    //是否复用
    if (this.$route.query.id != undefined) {
      localStorage.setItem("emultiplexID", this.$route.query.id);
      localStorage.setItem("clearType", 0);
      this.newlyBuild(1);
    } else {
      if (localStorage.getItem("clearType") == 1) {
        this.newlyBuild();
        localStorage.setItem("clearType", 0);
      }
      if (tableArr.length != 0) {
        this.getScrollRendercanvas();
      }
    }
    if (localStorage.getItem("emultiplexID") != null) {
      this.getPageData(localStorage.getItem("emultiplexID"), 1);
      localStorage.removeItem("emultiplexID");
      this.JacquardWeaveinletHidden = false;
    }
  },
  // 默认状态生成页面
  mounted() {
    if (dataObj == "") {
      dataObj = this.deepClone(this.$data);
    }
    this.StructureKey.forEach((item, index) => {
      let img = new Image();
      img.src = item;
      img.onload = () => {
        this.$set(this.checkStructuredataModel, index, img);
      };
    });
    return;
    this.$nextTick(() => {
      this.countPageTableSize();
      this.createTable();
      this.checkImgindex = 0;
      this.yarnList = [
        //纱线列表
        {
          nr: "PANTONE 11-4001 TPG",
          name: "Brilliant White",
          book: 1,
          page: 1,
          row: 1,
          hexCode: "#EFF0F1",
          rgb: "239,240,241",
          bottom: true,
          component: {
            type: 1,
            data: [{ componentNum: 0, proportion: 100 }],
          },
          YarnCount: {
            num: 40,
            unit: 2,
          },
        },
      ];
    });
  },
  methods: {
    //全局事件委托
    monitoringCilck(e) {
      let id = e.srcElement.id
      console.log(e.srcElement)
      if (id == 'setColor') {
        this.ISpaintingbrushMask = false
      } else {
        if (id != 'tableCanvas' && id != 'img2' && id != 'addBox') {
          this.ListenerslideType = false
          this.cilckListenerslideArr = [];
          this.checkListenerslideArr = []
          this.clearCanvasmouseMove(2)
        }
      }
    },
    //取消按钮回调
    cancelDialog() {
      this.isShowDialog = false;
    },
    //确认按钮回调
    confirmDialog() {
      let colorArr = [];
      this.StructureImgColorList.forEach((item, i) => {
        if (item.index != undefined) {
          if (item.index == this.addStructureList[this.delIndex].index) {
            colorArr.push(`rgb(${item.rgb})`);
          }
        }
      });
      tableArr.forEach((item, index) => {
        item.forEach((items, indexs) => {
          colorArr.forEach((el) => {
            if (items.structureColor.color == el) {
              tableArr[index][indexs].structureColor = {
                color: el,
                type: false,
              };
            }
          });
        });
      });
      this.addStructureList.splice(this.delIndex, 1);
      this.getScrollRendercanvas();
      this.isShowDialog = false;
    },
    // canvas 生成
    //输入花高花宽入口页传参
    jacquardWeaveinletSend(index, colorData) {
      let canvasSrc = document.createElement("canvas");
      const ctx = canvasSrc.getContext("2d", {
        willReadFrequently: true,
      });
      let img = new Image();
      // 当前选中版单图片
      this.checksheetIndex = index;
      img.src = require(`@/newImg/socks${index + 1}.png`);
      img.onload = () => {
        canvasSrc.width = img.width;
        canvasSrc.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const imageData = ctx.getImageData(
          0,
          0,
          canvasSrc.width,
          canvasSrc.height
        );
        // 颜色部位
        // 1.袜身:9c9ea4 rgb:156,158,164
        // 2.罗口:C8D3DF rgb:200,211,223
        // 3.袜头:b7becb rgb:183,190,203
        // 4.袜跟:c2c9d6 rgb:194,201,214
        let colorarr = [
          { rgb: "156,158,164", clolor: "9c9ea4" },
          { rgb: "200,211,223", clolor: "C8D3DF" },
          { rgb: "183,190,203", clolor: "b7becb" },
          { rgb: "194,201,214", clolor: "C2C9D6" },
        ];
        let arr = [];
        for (var i = 0; i < imageData.height; i++) {
          //把图层imageData转为rgb数据
          arr.push([]);
          for (
            var j = i * imageData.width * 4;
            j < imageData.width * 4 + i * imageData.width * 4;
            j += 4
          ) {
            var a = `${imageData.data[j]},${imageData.data[j + 1]},${imageData.data[j + 2]
              }`;
            let type = true;
            colorarr.forEach((item, index) => {
              if (item.rgb == a) {
                type = false;
                arr[i].push({
                  positionType: index + 1,
                });
              }
            });

            if (type) {
              arr[i].push({
                positionType: 0,
              });
            }
          }
        }
        this.hg = arr.length;
        this.hk = arr[0].length;
        this.countPageTableSize();
        this.createTable(arr, colorData);
      };
    },

    //获取结构图片
    getStructureImg(e) {
      this.imgPupType = 2;
      this.GetImgcolorHidden = true;
    },

    //删除结构图片
    delStructureImg() {
      this.StructureImgColorList = [];
      this.upStructureImg = "";
      this.addStructureList.forEach((element, i) => {
        this.$set(this.addStructureList[i], "type", false);
      });
      tableArr.forEach((item, index) => {
        item.forEach((items, indexs) => {
          tableArr[index][indexs].structureColor = "";
          tableArr[index][indexs].active = 1;
        });
      });
      this.pagecolorType = 2;
      this.getScrollRendercanvas();
    },

    //复用生成页面
    getPageData(id, type) {
      getresult({ designId: id }).then((res) => {
        if (res.code == 0) {
          if (this.$route.name != "index") {
            return;
          }
          //根据数据生成花高和花宽
          let data = res.data.designResult.designConfig;
          this.yarnList = data.copyYarnList; // 纱线
          this.layerListNum = data.layerListNum; //图层数量
          this.machineNumber = data.machineNumber; //每2.5内的针数
          this.hk = data.meanWork.width;
          this.hg = data.meanWork.height;
          this.addStructureList = data.addStructureList;
          this.StructureImgColorList = data.StructureImgColorList;
          this.checksheetIndex = data.StructureIndex;
          this.positionColorList = data.positionColorList;
          this.countPageTableSize(); //生成滚动区域与视图大小
          let colorarr = [
            { rgb: "156,157,164", clolor: "9c9ea4" },
            { rgb: "200,211,223", clolor: "C8D3DF" },
            { rgb: "183,190,202", clolor: "b7becb" },
            { rgb: "194,201,214", clolor: "C2C9D6" },
          ];
          this.yarnList.forEach((item, index) => {
            if (item.bottomColor) {
              colorarr[0].rgb = item.rgb;
            }
          });
          this.positionColorList.forEach((item, i) => {
            if (i < 3) {
              colorarr[i + 1].rgb = item.rgb;
            }
          });
          let colorData = {
            colorarr,
            outColor: this.positionColorList[3].rgb,
          };
          ReuseData = data;
          this.jacquardWeaveinletSend(this.checksheetIndex, colorData);
        }
      });
    },

    //生成复用真实数据
    CreatemultiplexData(data) {
      this.pageType = data.pageType;
      if (this.pageType == 1) {
        this.checkImgindex = 0;
      } else {
        tableArr.forEach((el) => {
          el.forEach((list) => {
            list.imageArr = [];
          });
        });
      }

      if (data.StructureImg != "") {
        this.getLayerDaTa(
          data.StructureImg,
          this.sockbodyPosition.rightBottom.y - this.sockbodyPosition.leftTop.y + 1,
          this.sockbodyPosition.rightBottom.x - this.sockbodyPosition.leftTop.x + 1,
          1,
          1
        ).then((el) => {
          const ctx = el.getContext("2d");
          const imageData = ctx.getImageData(0, 0, el.width, el.height);
          let arr = [];
          this.upStructureImg = el.toDataURL('image/png');

          for (var i = 0; i < imageData.height; i++) {
            //把图层imageData转为rgb数据
            arr.push([]);
            for (
              var j = i * imageData.width * 4;
              j < imageData.width * 4 + i * imageData.width * 4;
              j += 4
            ) {
              arr[i].push({
                rgb: `${imageData.data[j]},${imageData.data[j + 1]},${imageData.data[j + 2]
                  }`,
              });
            }
          }
          let StructureType = false;
          data.meanWork.data = data.meanWork.data.reverse();
          arr.forEach((item, index) => {
            item.forEach((items, i) => {
              StructureType = false;
              data.StructureImgColorList.forEach((o) => {
                if (o.rgb == items.rgb) {
                  if (o.index != undefined) {
                    StructureType = true;
                  }
                }
              });
              tableArr[this.sockbodyPosition.leftTop.y + index][this.sockbodyPosition.leftTop.x + i].structureColor = {
                color: `rgb(${items.rgb})`,
                type: StructureType,
              };
              tableArr[this.sockbodyPosition.leftTop.y + index][this.sockbodyPosition.leftTop.x + i].active =
                data.meanWork.data[this.sockbodyPosition.leftTop.y + index][this.sockbodyPosition.leftTop.x + i] > 10
                  ? data.meanWork.data[this.sockbodyPosition.leftTop.y + index][this.sockbodyPosition.leftTop.x + i] % 10
                  : data.meanWork.data[this.sockbodyPosition.leftTop.y + index][this.sockbodyPosition.leftTop.x + i];
            });
          });
        });
      }

      if (data.layerArr.length != 0) {
        let createImgCanvasList = [];
        let canvasArr = [];
        let num = 0;
        checktableArr = data.checktableArr;
        data.checktableArr.forEach((item, index) => {
          let colorItem = this.yarnList[item.index];
          tableArr[item.i][item.j].imageArr[0].color = `rgb(${colorItem.rgb})`;
        });

        data.layerArr.forEach((item, index) => {
          canvasArr.push(""); //保存图层canvas
          //图层双向绑定数据
          this.layerList.push({
            color: item.color,
            img: item.img,
            imgIndex: item.imgIndex,
            isShow: item.isShow,
            size: item.size,
            value: item.value,
            label: item.label,
          });
          //图层真实数据
          createImgCanvasList.push({
            canvasLfet: item.canvasLfet,
            canvasTop: item.canvasTop,
            h: item.size.h,
            w: item.size.w,
            imgIndex: item.imgIndex,
            isShow: item.isShow,
            name: item.label,
            img: item.img,
            data: [],
          });

          this.getLayerDaTa(
            //使用Promise处理图层生成顺序
            item.img,
            item.h,
            item.w,
            index
          ).then((el) => {
            num++;
            canvasArr[el.index] = el.canvasReduce;
            if (num == data.layerArr.length) {
              canvasArr.forEach((el, p) => {
                const ctx = el.getContext("2d");
                const imageData = ctx.getImageData(0, 0, el.width, el.height);
                let arr = [];

                for (var i = 0; i < imageData.height; i++) {
                  //把图层imageData转为rgb数据
                  arr.push([]);
                  for (
                    var j = i * imageData.width * 4;
                    j < imageData.width * 4 + i * imageData.width * 4;
                    j += 4
                  ) {
                    arr[i].push({
                      r: imageData.data[j],
                      g: imageData.data[j + 1],
                      b: imageData.data[j + 2],
                    });
                  }
                }
                arr.forEach((l, i) => {
                  //把图层rgb数据生成出图层并放入tableArr生成页面
                  l.forEach((list, j) => {
                    let a = data.layerArr[p].canvasTop + i;
                    let b = data.layerArr[p].canvasLfet + j;
                    arr[i][j].i = a;
                    arr[i][j].j = b;
                    arr[i][j].imgIndex = data.layerArr[p].imgIndex;
                    if (data.layerArr[p].imgIndex == 1) {
                      tableArr[a][b].imageArr.push({
                        color: `rgb(${list.r},${list.g},${list.b})`,
                        hiddenColor: `rgb(${list.r},${list.g},${list.b})`,
                        i,
                        j,
                        imgIndex: data.layerArr[p].imgIndex,
                        type: false,
                      });
                    } else {
                      tableArr[a][b].imageArr.push({
                        color: `rgb(${list.r},${list.g},${list.b})`,
                        i,
                        j,
                        imgIndex: data.layerArr[p].imgIndex,
                        type: false,
                      });
                    }

                    tableArr[a][b].imageArr = this.filteColor(
                      tableArr[a][b].imageArr
                    );
                  });
                });
                createImgCanvasList[p].data = arr;
                if (p == canvasArr.length - 1) {
                  imgCanvasList = createImgCanvasList;
                  this.renderCanvas();
                  this.getScrollRendercanvas();
                }
              });
            }
          });
        });
      } else {
        checktableArr = data.checktableArr;
        data.checktableArr.forEach((item, index) => {
          let colorItem = this.yarnList[item.index];
          tableArr[item.i][item.j].imageArr[0].color = `rgb(${colorItem.rgb})`;
        });
        this.renderCanvas();
        this.getScrollRendercanvas();
      }
    },

    //复用获取图层数据
    getLayerDaTa(imgUrl, h, w, index, type) {
      return new Promise((resolve, reject) => {
        var canvasReduce = document.createElement("canvas");
        const ctx = canvasReduce.getContext("2d");
        canvasReduce.width = w;
        canvasReduce.height = h;
        ctx.fillStyle = "rgba(255,255,255,1)";
        ctx.fillRect(0, 0, canvasReduce.width, canvasReduce.height);
        let img = new Image();
        img.setAttribute("crossOrigin", "Anonymous");
        img.src = imgUrl;
        img.onload = (e) => {
          ctx.drawImage(img, 0, 0, w, h);
          if (type == 1) {
            resolve(canvasReduce);
          } else {
            resolve({ canvasReduce, index });
          }
        };
      });
    },

    //计算当前视图大小
    countPageTableSize() {
      this.canvasBoxSize = {
        w: this.hk * this.tableItemsize,
        h: this.hg * this.tableItemsize,
      };
      this.VisualwindowSize = {
        w:
          Math.floor(
            (document.querySelector(".box").offsetWidth - 20) /
            this.tableItemsize
          ) + 10,
        h:
          Math.floor(
            (document.querySelector(".box").offsetHeight - 20) /
            this.tableItemsize
          ) + 10,
      };
    },

    //初始化生成网格底层数据
    createTable(arr, colorData) {
      let type = this.Isworker();
      if (type) {
        let data = {
          canvasBoxSize: this.canvasBoxSize,
          tableItemsize: this.tableItemsize,
          type: 1,
          arr,
          colorData,
        };
        let worker = new tabledataWorker();
        worker.postMessage(data);
        worker.onmessage = (e) => {
          tableArr = e.data.tableData;
          this.sockbodyPosition = e.data.sockbodyPosition
          if (colorData != undefined) {
            this.CreatemultiplexData(ReuseData);
          }
          this.renderCanvas(0, 0);

          this.JacquardWeaveinletHidden = false;
          this.pageType = 1;
          this.checkImgindex = 0;
          worker.terminate();
          worker = undefined;
        };
      } else {
        this.createTablearr(2, 2, arr);
        // if (initType == 1) {
        //   this.initImg(canvasData, res);
        // }
      }
    },

    //不支持webWorker生成底层数据
    createTablearr(type, data, arr) {
      let colorarr = [
        { rgb: "156,157,164", clolor: "9c9ea4" },
        { rgb: "200,211,223", clolor: "C8D3DF" },
        { rgb: "183,190,202", clolor: "b7becb" },
        { rgb: "194,201,214", clolor: "C2C9D6" },
      ];
      for (var i = 0; i < this.canvasBoxSize.h / this.tableItemsize; i++) {
        tableArr.push([]);
        for (var j = 0; j < this.canvasBoxSize.w / this.tableItemsize; j++) {
          let color = "";
          if (e.bottomColor != undefined) {
            color = `rgb(${e.bottomColor})`;
          } else {
            if (arr[i][j].positionType > 0) {
              color = `rgb(${colorarr[arr[i][j].positionType - 1].rgb})`;
            } else {
              color = "rgb(239,240,241)";
            }
          }
          tableArr[i].push({
            imageArr: [
              {
                imgIndex: 0,
                color: "rgb(239,240,241)",
                type: true,
              },
            ],
            size: 20,
            active:
              type == 1
                ? data[i][j] < 10
                  ? data[i][j]
                  : JSON.stringify(data[i][j]).split("").reverse()[0]
                : 1,
            i,
            j,
            positionType: arr[i][j].positionType,
            structureColor: "",
          });
        }
      }
      // this.setsaveData();
      this.renderCanvas(0, 0);
    },

    //初始化canvas
    renderCanvas(x, y) {
      this.tableCanvas = document.querySelector("#tableCanvas");
      this.tableCanvas.width = this.VisualwindowSize.w * this.tableItemsize;
      this.tableCanvas.height = this.VisualwindowSize.h * this.tableItemsize;
      this.context = this.tableCanvas.getContext("2d", {
        willReadFrequently: true,
      });
      //根据当前滚动条位置获取要显示的表格内容
      let { minxIndex, maxxIndex, minyIndex, maxyIndex } = this.getshowTable(
        x,
        y
      );
      let hNum = 0;
      let zNum = 0;
      //生成新的表格
      for (var i = minyIndex; i <= maxyIndex; i++) {
        if (i <= tableArr.length) {
          //纵向表格刻度与尺寸文字
          if (i % 10 == 0) {
            //纵向表格刻度
            this.renderLine(
              "#979797",
              20,
              (i - minyIndex) * this.tableItemsize +
              20 +
              (i == tableArr.length ? 1.5 : 0.5),
              0,
              (i - minyIndex) * this.tableItemsize +
              20 +
              (i == tableArr.length ? 1.5 : 0.5)
            );

            //纵向表格尺寸文字
            // var str = JSON.stringify((i / this.machineNumber) * 2.5).split("");
            // str.forEach((element, index) => {
            //   this.renderCanvastxt(
            //     element,
            //     3,
            //     (i - minyIndex) * this.tableItemsize -
            //       this.canvasTxtPosition(str.length, 2) +
            //       (element == "."
            //         ? (index + 1) * (str.length > 2 ? 11 : 0)
            //         : (index + 1) * 12)
            //   );
            // });
          } else {
            // 小标尺
            this.renderLine(
              "rgba(151,151,151,0.6)",
              20,
              (i - minyIndex) * this.tableItemsize +
              20 +
              (i == tableArr.length ? 1.5 : 0.5),
              i == tableArr.length && i % this.machineNumber != 0 ? 0 : 10,
              (i - minyIndex) * this.tableItemsize +
              20 +
              (i == tableArr.length ? 1.5 : 0.5)
            );
            // 结尾尺寸
            if (i == tableArr.length && i % this.machineNumber != 0) {
              // var str = this.hg.split("");
              // str.forEach((element, index) => {
              //   this.renderCanvastxt(
              //     element,
              //     3,
              //     (i - minyIndex) * this.tableItemsize -
              //       this.canvasTxtPosition(str.length, 2) +
              //       (element == "."
              //         ? (index + 1) * (str.length > 2 ? 11 : 0)
              //         : (index + 1) * 12)
              //   );
              // });
            }
          }
        }
        for (var j = minxIndex; j < maxxIndex; j++) {
          if (j <= tableArr[0].length) {
            //横向表格刻度与尺寸文字
            if (i == minyIndex) {
              if (j % 10 == 0) {
                //横向表格刻度
                this.renderLine(
                  "#979797",
                  (j - minxIndex) * this.tableItemsize +
                  20 +
                  (j == tableArr[0].length ? 1.5 : 0.5),
                  20,
                  (j - minxIndex) * this.tableItemsize +
                  20 +
                  (j == tableArr[0].length ? 1.5 : 0.5),
                  0
                );

                //横向表格尺寸文字
                // this.renderCanvastxt(
                //   JSON.stringify((j / this.machineNumber) * 2.5),
                //   (j - minxIndex) * this.tableItemsize -
                //     this.canvasTxtPosition(
                //       JSON.stringify((j / this.tableItemsize) * 2.5).split("")
                //         .length,
                //       1
                //     ),
                //   10
                // );
              } else {
                //横向表格刻度
                this.renderLine(
                  "rgba(151,151,151,0.6)",
                  (j - minxIndex) * this.tableItemsize +
                  20 +
                  (j == tableArr[0].length ? 1.5 : 0.5),
                  20,
                  (j - minxIndex) * this.tableItemsize +
                  20 +
                  (j == tableArr[0].length ? 1.5 : 0.5),
                  j == tableArr[0].length && j % this.machineNumber != 0
                    ? 0
                    : 10
                );
                // if (j == tableArr[0].length && j % this.machineNumber != 0) {
                //   this.renderCanvastxt(
                //     this.hk,
                //     (j - minxIndex) * this.tableItemsize -
                //       this.canvasTxtPosition(this.hk, 1),
                //     10
                //   );
                // }
              }
            }
          }
          //渲染新的表格
          if (i < tableArr.length && j < tableArr[0].length) {
            if (i == minyIndex) {
              hNum += this.tableItemsize;
            }
            if (j == minxIndex) {
              zNum += this.tableItemsize;
            }
            let items = tableArr[i][j];
            this.context.beginPath();
            if (this.itemBtn == 2 && this.pagecolorType == 1 && tableArr[i][j].positionType == 1) {
              this.context.fillStyle = tableArr[i][j].structureColor.color;
            } else {
              switch (items.positionType) {
                case 0:
                  break
                case 1:
                  break
                case 0:
                  break;
              }
              if (items.positionType == 1) {
                items.imageArr.forEach((el) => {
                  if (el.type) {
                    this.context.fillStyle = el.color;
                  }
                });
              } else {
                if (items.positionType == 0) {
                  this.context.fillStyle = `rgb(${this.positionColorList[3].rgb})`;
                } else {
                  this.context.fillStyle = `rgb(${this.positionColorList[items.positionType - 2].rgb})`;
                }
              }
            }
            this.context.lineWidth = 2;
            this.context.strokeStyle = "#979797";
            this.context.rect(
              (j - minxIndex) * this.tableItemsize + 21,
              (i - minyIndex) * this.tableItemsize + 21,
              this.tableItemsize,
              this.tableItemsize
            );
            this.context.stroke();
            this.context.closePath();
            this.context.fill();
            if (this.itemBtn == 2 && items.structureColor.type && items.positionType == 1) {
              this.context.beginPath();
              this.context.fillStyle = "rgba(255,255,255,0.1)";
              this.context.lineWidth = 2;
              this.context.strokeStyle = "rgba(255,255,255,0)";
              this.context.rect(
                (j - minxIndex) * this.tableItemsize + 21,
                (i - minyIndex) * this.tableItemsize + 21,
                this.tableItemsize,
                this.tableItemsize
              );
              this.context.stroke();
              this.context.closePath();
              this.context.fill();

              this.context.drawImage(
                this.checkStructuredataModel[items.active - 1],
                (j - minxIndex) * this.tableItemsize + 21,
                (i - minyIndex) * this.tableItemsize + 21,
                this.tableItemsize,
                this.tableItemsize
              );
            }
          }
        }
      }


      if (this.checkListenerslideArr.length != 0) {
        this.checkListenerslideArr.forEach(num => {
          if (num.i >= minyIndex && num.i <= maxyIndex && num.j >= minxIndex && num.j <= maxxIndex) {
            let items = tableArr[num.i][num.j];
            this.context.beginPath();
            if (this.itemBtn == 2 && this.pagecolorType == 1 && items.positionType == 1) {
              this.context.fillStyle = items.structureColor.color;
            } else {
              switch (items.positionType) {
                case 0:
                  break
                case 1:
                  break
                case 0:
                  break;
              }
              if (items.positionType == 1) {
                items.imageArr.forEach((el) => {
                  if (el.type) {
                    this.context.fillStyle = el.color;
                  }
                });
              } else {
                if (items.positionType == 0) {
                  this.context.fillStyle = `rgb(${this.positionColorList[3].rgb})`;
                } else {
                  this.context.fillStyle = `rgb(${this.positionColorList[items.positionType - 2].rgb})`;
                }
              }
            }
            this.context.lineWidth = 2;
            this.context.strokeStyle = "#1890FF";
            this.context.rect(
              (num.j - minxIndex) * this.tableItemsize + 21,
              (num.i - minyIndex) * this.tableItemsize + 21,
              this.tableItemsize,
              this.tableItemsize
            );
            this.context.stroke();
            this.context.closePath();
            this.context.fill();
          }
        })
      }
      //横线顶栏边框
      // this.renderLine("#282828", 0, 0, hNum + 20 + 1, 0);
      //纵向表格顶栏
      this.renderLine("#282828", 0, 0, 0, zNum + 20 + 1);
      this.pageLoadType;
    },

    //渲染canvas标尺线条
    renderLine(color, x, y, endx, endy) {
      this.context.beginPath();
      this.context.lineWidth = 1;
      this.context.moveTo(x, y);
      this.context.lineTo(endx, endy);
      this.context.strokeStyle = color;
      this.context.stroke();
    },

    //渲染canvas标尺文案
    renderCanvastxt(txt, x, y) {
      this.context.beginPath();
      this.context.strokeStyle = "#000";
      this.context.fillStyle = "black";
      this.context.font = "12px Consolas";
      this.context.fillText(txt, x, y);
      this.context.stroke();
    },

    //canvas文案位置
    canvasTxtPosition(lengthNum, type) {
      let PositionNum = 0;
      switch (lengthNum) {
        case 1:
          PositionNum = type == 2 ? 0 : 0;
          break;
        case 2:
          PositionNum = type == 2 ? 10 : 15;
          break;
        case 3:
          PositionNum = type == 2 ? 27 : 20;
          break;
        case 4:
          PositionNum = type == 2 ? 38 : 30;
          break;
        case 5:
          PositionNum = type == 2 ? 50 : 33;
          break;
        case 6:
          PositionNum = type == 2 ? 62 : 33;
          break;
        case 7:
          PositionNum = type == 2 ? 75 : 33;
          break;
      }
      return PositionNum;
    },

    //计算当前应显示的表格内容位置
    getshowTable(x, y) {
      let data = {
        minxIndex: Math.ceil(x / this.tableItemsize),
        maxxIndex: Math.ceil(x / this.tableItemsize) + this.VisualwindowSize.w,
        minyIndex: Math.ceil(y / this.tableItemsize),
        maxyIndex: Math.ceil(y / this.tableItemsize) + this.VisualwindowSize.h,
      };
      return data;
    },

    //获取当前滚动条具位置
    getScroll() {
      let data = {
        left:
          document.querySelector(".box").scrollLeft == 0
            ? 0
            : document.querySelector(".box").scrollLeft + 20,
        top:
          document.querySelector(".box").scrollTop == 0
            ? 0
            : document.querySelector(".box").scrollTop + 20,
      };
      return data;
    },

    //根据滚动条位置渲染页面
    getScrollRendercanvas(type) {
      if (this.context == "") {
        return;
      }
      // type为1 表示canvas上显示结构
      this.context.clearRect(
        0,
        0,
        this.VisualwindowSize.w * this.tableItemsize + 20,
        this.VisualwindowSize.h * this.tableItemsize + 20
      );
      let data = this.getScroll();
      this.canvasLeft = data.left == 0 ? 0 : data.left - 20;
      this.canvasTop = data.top == 0 ? 0 : data.top - 20;

      this.renderCanvas(data.left, data.top, type);
    },

    //判读当前浏览器是否支持worker
    Isworker() {
      let type = false;
      if (typeof Worker !== "undefined") {
        // Web worker 支持!
        // this.WorkerReduce(opts, canvasSrc, canvasReduce);
        type = true;
      } else {
        //Web Worker 不支持!
        // NonWorkerReduce(opts, canvasSrc, canvasReduce);
        type = false;
      }
      return type;
    },


    //获取导入结构图片参数
    sendStructureImgData(res) {
      this.addStructureList.forEach((element, i) => {
        this.$set(this.addStructureList[i], "type", false);
      });
      this.GetImgcolorHidden = false;
      this.upStructureImg = res.img;
      this.StructureImgColorList = res.colorList;
      res.data.forEach((item, i) => {
        item.forEach((items, j) => {
          tableArr[this.sockbodyPosition.leftTop.y + i][this.sockbodyPosition.leftTop.x + j].structureColor = {
            color: `rgb(${res.data[i][j].r},${res.data[i][j].g},${res.data[i][j].b})`,
            type: false,
          };
        })
      })
      // tableArr.forEach((item, i) => {
      //   item.forEach((items, j) => {
      //     tableArr[i][j].structureColor = {
      //       color: `rgb(${res.data[i][j].r},${res.data[i][j].g},${res.data[i][j].b})`,
      //       type: false,
      //     };
      //   });
      // });
    },

    //获取导入图片参数
    getImgData(res) {
      this.layerListNum++;
      let canvasData = {
        data: [],
        imgIndex: 0,
        canvasLfet: this.sockbodyPosition.leftTop.x,
        canvasTop: this.sockbodyPosition.leftTop.y,
        w: res.size.w,
        h: res.size.h,
        zhname: `图样${this.layerListNum}`,
        enname: `Pattern${this.layerListNum}`,
        isShow: true,
        img: res.img,
      };

      let leftIndex = this.sockbodyPosition.leftTop.x
      let topIndex = this.sockbodyPosition.leftTop.y

      let data = res.data;
      for (var i = 0; i < data.length; i++) {
        for (var j = 0; j < data[0].length; j++) {
          data[i][j].i = i + topIndex;
          data[i][j].j = j + leftIndex;
          let items = data[i][j];
          tableArr[i + topIndex][j + leftIndex].imageArr.forEach((item, index) => {
            tableArr[i + topIndex][j + leftIndex].imageArr[index].type = false;
          });
          tableArr[i + topIndex][j + leftIndex].imageArr.push({
            color: `rgb(${items.r},${items.g},${items.b})`,
            imgIndex: this.layerListNum,
            type: true,
            i,
            j,
          });
        }
      }
      let addtype = true;
      res.color.forEach((item, index) => {
        addtype = true;
        item.colorType = item.book == 1 ? "2" : "1";
        item.firstIndex = item.page - 1;
        item.secondIndex = item.row - 1;
        this.yarnList.forEach((el, i) => {
          if (el.rgb == item.rgb) {
            addtype = false;
          }
          if (i == this.yarnList.length - 1 && addtype) {
            this.yarnList.push({
              ...item,
              component: {
                type: 1,
                data: [
                  {
                    componentNum: 0,
                    proportion: 100,
                  },
                ],
              },
              YarnCount: {
                num: 40,
                unit: 1,
              },
            });
          }
        });
      });

      this.layerList.push({
        value: this.layerListNum,
        label: `${this.layerListNum}`,
        size: res.size,
        img: res.img,
        isShow: true,
        imgIndex: this.layerListNum,
        color: res.color,
      });
      canvasData.data = data;
      canvasData.imgIndex = this.layerListNum;
      imgCanvasList.push(canvasData);
      this.JacquardWeaveinletHidden = false;
      this.getScrollRendercanvas();
      // this.setsaveData();
      this.GetImgcolorHidden = false;
    },

    //canvas 操作
    // 顶部操作按钮综合
    clickBtntype(type) {
      if (type != 1) {
        this.ListenerslideType = false; //取消对应的画笔功能
      }
      switch (type) {
        case 0:
          this.dialogTableVisible = true;
          this.pupData = {
            title: this.$t("machine.txt1"),
            pupType: 1,
            pupConType: 1,
          };

          break;
        case 1:
          if (this.rasterizing && this.itemBtn == 1) {
            if (this.ListenerslideType) {
              this.ListenerslideType = false
            } else {
              this.ListenerslideType = true
            }
          }
          break;
        case 2:
          if (saveData.length > 1) {
            saveData.splice(saveData.length - 1, 1);
            this.yarnList = saveData[saveData.length - 1].yarnList;
            this.layerListNum = saveData[saveData.length - 1].layerListNum;
            this.layerList = saveData[saveData.length - 1].layerList;
            imgCanvasList = saveData[saveData.length - 1].imgCanvasList;
            tableArr = saveData[saveData.length - 1].tableArr;
          }
          this.getScrollRendercanvas();
          break;
        case 3:
          if (!this.rasterizing || this.itemBtn == 2) {
            return;
          }
          this.imgPupType = 1;
          if (this.yarnList.length < 10) {
            this.GetImgcolorHidden = true;
          } else {
            this.dialogTableVisible = true;
            this.pupData = {
              title: this.$t("machine.txt16"),
              pupType: 1,
              pupConType: 2,
            };
          }
          break;
        case 4:
          this.dialogTableVisible = true;
          this.pupData = {
            title: this.$t("machine.txt17"),
            pupType: 2,
            pupConType: 1,
            data: this.yarnList,
            positionColorList: this.positionColorList,
          };
          break;
        case 5:
          if (!this.rasterizing || this.itemBtn == 2) {
            return;
          }
          this.isPreviewPup = 1;
          // this.renderThumbnail();
          break;
        case 6:
          this.isPreviewPup = 2;
          this.HeadList = [
            '',
            this.$t("pup.txt19"),
            this.$t("pup.txt20"),
            this.$t("pup.txt21"),
            this.$t("pup.txt22"),
          ];
          this.materialQuality.forEach((item, index) => {
            this.$set(
              this.materialQuality[index],
              "label",
              this.$t(`pup.txt${index + 24}`)
            );
          });
          this.previewrenderThumbnail();
          break;
        case 7:
          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d");
          canvas.width = this.sockbodyPosition.rightBottom.x - this.sockbodyPosition.leftTop.x + 1;
          canvas.height = this.sockbodyPosition.rightBottom.y - this.sockbodyPosition.leftTop.y + 1;
          let color = ''
          this.yarnList.forEach((item) => {
            if (item.bottomColor) {
              color = item.hexCode
            }
          })
          context.fillStyle = color;
          context.fillRect(0, 0, canvas.width, canvas.height);
          let a = document.createElement("a");
          a.download = "版单";
          a.href = canvas.toDataURL('image/png');
          a.click();
          break;
      }
    },

    // 预览画布缩略图
    previewrenderThumbnail() {
      this.forImgIndex = 0;
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      canvas.width = tableArr[0].length;
      canvas.height = tableArr.length;
      let img_index = 0;
      var newImgData = context.createImageData(canvas.width, canvas.height);
      tableArr.forEach((item, i) => {
        item.forEach((items, j) => {
          if (items.positionType == 1) {
            items.imageArr.forEach((el) => {
              if (el.type) {
                let str = el.color
                  .substring(
                    el.color.indexOf("(") + 1,
                    el.color.indexOf(")")
                  )
                  .split(",");
                newImgData.data[img_index + 0] = str[0];
                newImgData.data[img_index + 1] = str[1];
                newImgData.data[img_index + 2] = str[2];
                newImgData.data[img_index + 3] = 255;
                img_index += 4;
              }
            });
          } else {
            let str = items.imageArr[0].color
              .substring(
                items.imageArr[0].color.indexOf("(") + 1,
                items.imageArr[0].color.indexOf(")")
              )
              .split(",");
            newImgData.data[img_index + 0] = str[0];
            newImgData.data[img_index + 1] = str[1];
            newImgData.data[img_index + 2] = str[2];
            newImgData.data[img_index + 3] = 255;
            img_index += 4;
          }
        })
      })
      context.putImageData(
        newImgData,
        0,
        0
      );
      this.thumbnailImgurl = canvas.toDataURL("image/png");
    },

    //生成缩略图
    renderThumbnail(type) {
      this.forImgIndex = 0;
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      canvas.width = tableArr[0].length;
      canvas.height = tableArr.length;
      context.putImageData(this.createImg(tableArr, 1), 0, 0);
      this.layerList.forEach((item, i) => {
        if (item.isShow) {
          imgCanvasList.forEach((items) => {
            if (items.imgIndex == item.imgIndex) {
              context.putImageData(
                this.createImg(items.data, 3),
                items.canvasLfet,
                items.canvasTop
              );
            }
          });
        }
      });
      if (type == 1) {
        this.backImg = canvas.toDataURL("image/png");
      } else {
        this.thumbnailImgurl = canvas.toDataURL("image/png");
      }
    },

    //生成图样缩略图
    renderlayerThumbnail() {
      this.forImgIndex = 0;
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      canvas.width = tableArr[0].length;
      canvas.height = tableArr.length;
      this.layerList.forEach((item, index) => {
        if (item.imgIndex == this.layerIndex) {
          if (item.isShow) {
            imgCanvasList.forEach((items) => {
              if (items.imgIndex == item.imgIndex) {
                context.putImageData(
                  this.createImg(items.data, 2),
                  items.canvasLfet,
                  items.canvasTop
                );
              }
            });
          }
        }
      });
      this.thumbnailImgurl = canvas.toDataURL("image/png");
    },

    //循环样图放入到canvas演示
    renderForcanvas(index) {
      this.forImgIndex = index;
      if (this.layerIndex != "") {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = tableArr[0].length;
        canvas.height = tableArr.length;
        this.layerList.forEach((item) => {
          if (item.imgIndex == this.layerIndex) {
            if (item.isShow) {
              imgCanvasList.forEach((items) => {
                if (item.imgIndex == items.imgIndex) {
                  switch (index) {
                    case 1:
                      let num = Math.ceil(
                        (tableArr.length - (tableArr.length - this.sockbodyPosition.rightBottom.y)) / items.data.length
                      );
                      for (var i = 0; i < num; i++) {
                        this.TileImg(
                          items.data,
                          items.data[0].length,
                          this.sockbodyPosition.leftTop.x,
                          this.sockbodyPosition.leftTop.y + i * items.data.length,
                          tableArr[0].length - (tableArr[0].length - this.sockbodyPosition.rightBottom.x),
                          context,
                          1
                        );
                      }
                      break;
                    case 2:
                      this.TileImg(
                        items.data,
                        items.data[0].length,
                        this.sockbodyPosition.leftTop.x,
                        items.canvasTop,
                        tableArr[0].length - (tableArr[0].length - this.sockbodyPosition.rightBottom.x),
                        context,
                        1
                      );
                      break;
                    case 3:
                      this.TileImg(
                        items.data,
                        items.data.length,
                        items.canvasLfet,
                        this.sockbodyPosition.leftTop.y,
                        tableArr.length - (tableArr.length - this.sockbodyPosition.rightBottom.y),
                        context,
                        2
                      );
                      break;
                  }
                } else {
                  // context.putImageData(
                  //   this.createImg(items.data, 2),
                  //   items.canvasLfet,
                  //   items.canvasTop
                  // );
                }
              });
            }
          }
        });
        this.thumbnailImgurl = canvas.toDataURL("image/png");
      }
    },

    //关闭循环图片弹框
    hideSlTpup() {
      this.thumbnailImgurl = "";
      this.layerIndex = "";
      this.forImgIndex = 0;
      this.isPreviewPup = 0;
    },

    //关闭画布预览弹框
    hiddenPreviewCanvas() {
      this.isPreviewPup = 0;
      this.thumbnailImgurl = "";
    },

    //生成canvas imgData数据
    createImg(item, type) {
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d", {
        willReadFrequently: true,
      });
      canvas.width = item[0].length;
      canvas.height = item.length;
      var newImgData = context.createImageData(canvas.width, canvas.height);
      let img_index = 0;
      item.forEach((el, i) => {
        el.forEach((items, j) => {
          if (type == 1) {
            let str = items.imageArr[0].color
              .substring(
                items.imageArr[0].color.indexOf("(") + 1,
                items.imageArr[0].color.indexOf(")")
              )
              .split(",");
            newImgData.data[img_index + 0] = str[0];
            newImgData.data[img_index + 1] = str[1];
            newImgData.data[img_index + 2] = str[2];
            newImgData.data[img_index + 3] = 255;
            img_index += 4;
          } else {
            newImgData.data[img_index + 0] = items.r;
            newImgData.data[img_index + 1] = items.g;
            newImgData.data[img_index + 2] = items.b;
            newImgData.data[img_index + 3] = 255;
            img_index += 4;
          }
        });
      });
      return newImgData;
    },

    //平铺图层
    TileImg(data, imgLength, left, top, total, context, type) {
      let num = Math.ceil((total - (type == 1 ? left : top)) / imgLength);
      for (var i = 0; i < num; i++) {
        context.putImageData(
          this.createImg(data, 2),
          type == 1 ? left + i * imgLength : left,
          type == 2 ? top + i * imgLength : top
        );
      }
    },

    //点击确认生成循环图片
    createForimgdata() {
      if (this.layerIndex == "") {
        this.errTipstype = true;
        return;
      }
      if (this.forImgIndex == 0) {
        // this.errTipstype = true;
        return;
      }
      imgCanvasList.forEach((item, o) => {
        if (this.layerIndex == item.imgIndex) {
          switch (this.forImgIndex) {
            case 1:
              let num = Math.ceil(
                (tableArr.length - (tableArr.length - this.sockbodyPosition.rightBottom.y)) / item.data.length
              );
              let arr = [];
              let Addarr = [];
              for (var i = 0; i < num; i++) {
                arr = this.setForimgData(
                  imgCanvasList[o],
                  this.sockbodyPosition.leftTop.y + (i + 1) * item.data.length >= this.sockbodyPosition.rightBottom.y + 1
                    ? this.sockbodyPosition.rightBottom.y + 1
                    : this.sockbodyPosition.leftTop.y + (i + 1) * item.data.length,
                  this.sockbodyPosition.rightBottom.x + 1,
                  this.sockbodyPosition.leftTop.y + i * item.data.length,
                  this.sockbodyPosition.leftTop.x,
                );
                Addarr.push(...arr);
              }
              imgCanvasList[o].data = Addarr;
              imgCanvasList[o].canvasLfet = this.sockbodyPosition.leftTop.x
              imgCanvasList[o].canvasTop = this.sockbodyPosition.leftTop.y

              break;
            case 2:
              imgCanvasList[o].data = this.setForimgData(
                imgCanvasList[o],
                item.data.length + item.canvasTop,
                this.sockbodyPosition.rightBottom.x + 1,
                item.canvasTop,
                this.sockbodyPosition.leftTop.x
              );
              imgCanvasList[o].canvasLfet = this.sockbodyPosition.leftTop.x
              break;
            case 3:
              imgCanvasList[o].data = this.setForimgData(
                imgCanvasList[o],
                this.sockbodyPosition.rightBottom.y + 1,
                item.data[0].length + item.canvasLfet,
                this.sockbodyPosition.leftTop.y,
                item.canvasLfet
              );
              imgCanvasList[o].canvasTop = this.sockbodyPosition.leftTop.y
              break;
          }

          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d");
          canvas.width = item.data[0].length;
          canvas.height = item.data.length;
          context.putImageData(
            this.createImg(item.data, 2),
            0,
            0
          );
          imgCanvasList[o].w = imgCanvasList[o].data[0].length
          imgCanvasList[o].h = imgCanvasList[o].data.length
          imgCanvasList[o].img = canvas.toDataURL("image/png")
          this.$set(this.layerList[o], 'img', canvas.toDataURL("image/png"))
          this.$set(this.layerList[o], 'w', imgCanvasList[o].data[0].length)
          this.$set(this.layerList[o], 'h', imgCanvasList[o].data.length)
        }
      });
      this.getScrollRendercanvas();

      // this.isPreviewPup = 0;
      // this.forImgIndex = 0;
      // this.layerIndex = "";
      this.hideSlTpup();
    },

    //生成循环图层的数据格式
    setForimgData(item, forLength1, forLength2, topIndex, leftIndex) {
      let arr = [];
      let a = -1;
      let b = -1;
      let initY = topIndex - this.sockbodyPosition.leftTop.y - 1;
      let initX = leftIndex - this.sockbodyPosition.leftTop.x - 1;
      for (var i = topIndex; i < forLength1; i++) {
        initY++
        a++;
        arr.push([]);
        for (var j = leftIndex; j < forLength2; j++) {
          initX++
          b++;
          arr[i - topIndex].push({
            r: item.data[a][b].r,
            g: item.data[a][b].g,
            b: item.data[a][b].b,
            i,
            j,
          });
          let addtype = true;
          tableArr[i][j].imageArr.forEach((el, a) => {
            if (el.imgIndex == item.imgIndex) {
              tableArr[i][j].imageArr.splice(a, 1)
            }
          });
          if (addtype) {
            tableArr[i][j].imageArr.splice(item.imgIndex, 0, {
              color: `rgb(${item.data[a][b].r},${item.data[a][b].g},${item.data[a][b].b})`,
              imgIndex: item.imgIndex,
              type: false,
              i: initY,
              j: initX,
            });
            tableArr[i][j].imageArr = this.filteColor(tableArr[i][j].imageArr);
          }
          if (b == item.data[0].length - 1 || j == forLength2 - 1) {
            b = -1;
          }
          if (j == forLength2 - 1) {
            initX = leftIndex - this.sockbodyPosition.leftTop.x - 1;
          }
        }
        if (a == item.data.length - 1 || i == forLength1 - 1) {
          a = -1;
        }
      }
      return arr;
    },

    //接收弹框点击回调
    getPupres(data) {
      //pupType 判断弹框样式类型
      //pupConType 判断弹框内容
      if (data.pupData.pupType == 1) {
        //新建页面弹框
        if (data.pupData.pupConType == 1) {
          if (data.btnType) {
            this.newlyBuild();
          } else {
            this.dialogTableVisible = false;
          }
        } else {
          this.dialogTableVisible = false;
        }
      } else if (data.pupData.pupType == 2) {
        //纱线弹框
        if (data.btnType) {
          //处理本页面纱线列表
          this.yarnList = data.yarnList;
          this.positionColorList.forEach((item, index) => {
            if (index < 3) {
              this.positionColorList.splice(
                index,
                1,
                data.positionColorList[index]
              );
            }
          });
        }
        this.dialogTableVisible = false;
      } else if (data.pupData.pupType == 3) {
        //结构弹框
        if (data.btnType) {
          if (data.pupData.type == 1) {
            // 编辑结构
            let colorArr = [];
            this.StructureImgColorList.forEach((item, i) => {
              if (item.index != undefined) {
                if (item.index == data.pupData.StructureList.index) {
                  colorArr.push(`rgb(${item.rgb})`);
                }
              }
            });
            colorArr.forEach((item) => {
              this.newsettableArrstructure(item, data.Structure);
            });
            this.addStructureList.forEach((item, index) => {
              if (item.index == data.pupData.StructureList.index) {
                this.$set(
                  this.addStructureList[index],
                  "Structure",
                  data.Structure
                );
                if (index == this.checkStructureIndex) {
                  this.Structure = data.Structure;
                }
              }
            });
          } else {
            // 添加结构
            if (this.checkStructureIndex != 10000) {
              this.checkStructureIndex = 0;
              this.Structure = data.Structure;
            }
            this.StructureNum++;
            this.addStructureList.unshift({
              index:
                this.StructureNum >= 10
                  ? this.StructureNum
                  : "0" + this.StructureNum,
              Structure: data.Structure,
              type: false,
            });
          }
          // this.createStructureorImg();
        }
        this.dialogTableVisible = false;
      }
    },

    //编辑或修改添加的结构
    eidtOrdel(type, index) {
      if (type == 1) {
        this.dialogTableVisible = true;

        this.pupData = {
          title: this.$t("machine.txt73"),
          pupType: 3,
          pupConType: 1,
          StructureList: JSON.parse(
            JSON.stringify(this.addStructureList[index])
          ),
          type: 1,
        };
      } else {
        if (this.addStructureList.length != 1) {
          this.isShowDialog = true;
          this.setContent = this.$t("machine.txt76");
          this.setConfirmContent = this.$t("machine.txt77");
          this.delIndex = index;
        }
      }
    },

    //选中结构
    setCheckStructureIndex(index) {
      this.checkStructureIndex = index;
      this.Structure = this.addStructureList[index].Structure;
      this.createStructureorImg();
    },

    //鼠标按下
    mouseLeftDown(e) {
      e.preventDefault();
      if (this.itemBtn == 1) {
        if (this.ListenerslideType) {
          //画笔
          // this.cilckListenerslideArr = [];
          let Scrolldata = this.getScroll();
          let { minxIndex, maxxIndex, minyIndex, maxyIndex } =
            this.getshowTable(Scrolldata.left, Scrolldata.top);
          let num = 0;
          for (var i = minyIndex; i <= maxyIndex; i++) {
            this.cilckListenerslideArr.push([]);
            if (i != minyIndex) {
              num++;
            }
            for (var j = minxIndex; j < maxxIndex; j++) {
              if (i < tableArr.length && j < tableArr[0].length) {
                let items = tableArr[i][j];
                this.cilckListenerslideArr[num].push(items);
              }
            }
          }
          this.Listenerslide(minxIndex, minyIndex);
          this.iSmousemove = true;
        } else {
          if (!this.rasterizing) {
            return;
          }
          // 图片拖拽
          let Scrolldata = this.getScroll();
          let { minxIndex, maxxIndex, minyIndex, maxyIndex } =
            this.getshowTable(Scrolldata.left, Scrolldata.top);
          let nextType = false;
          let fristindex = this.getPos(e, 1);
          let secondindex = this.getPos(e, 2);
          if (
            fristindex >= 0 &&
            secondindex >= 0 &&
            fristindex + minyIndex < tableArr.length &&
            secondindex + minxIndex < tableArr[0].length
          ) {
            nextType = true;
          }

          if (!nextType) {
            return;
          }
          let imgIndex = 0;
          let type = false;
          let imgLeftTableNum = 0;
          let imgTopTableNum = 0;
          tableArr[fristindex + minyIndex][
            secondindex + minxIndex
          ].imageArr.forEach((item) => {
            if (item.imgIndex != "" && item.type) {
              imgIndex = item.imgIndex;
              imgLeftTableNum = item.j;
              imgTopTableNum = item.i;
              type = true;
              this.imgIndex = imgIndex;
            }
          });
          if (this.imgIndex == 1 && this.pageType == 2) {
            return;
          }
          if (type) {
            this.imgCanvasmaskType = true;
            clearTimeout(this.longPressTime);
            this.longPressTime = setTimeout(() => {
              this.dragType = true;
              this.iSmousemove = true;
              this.creatrImgCanvas(
                fristindex,
                secondindex,
                imgIndex,
                imgLeftTableNum,
                imgTopTableNum
              );
              this.iScreateImgCanvas = true;
            }, 500);
          }
        }
      } else {
        if (this.checkStructureIndex == 10000) {
          return;
        }
        if (this.pagecolorType == 2) {
          return;
        }
        // if (this.StructureTipsType) {
        //   if (this.rasterizing) {
        //     this.dialogTableVisible = true;
        //     this.pupData = {
        //       title: this.$t("machine.txt56"),
        //       pupType: 1,
        //       pupConType: 3,
        //     };
        //   }
        //   this.StructureTipsType = false;
        //   return;
        // }
        let Scrolldata = this.getScroll();
        let { minxIndex, maxxIndex, minyIndex, maxyIndex } = this.getshowTable(
          Scrolldata.left,
          Scrolldata.top
        );
        let fristindex = this.getPos(e, 1) + minyIndex;
        let secondindex = this.getPos(e, 2) + minxIndex;
        let nextType = false;
        if (
          fristindex >= 0 &&
          secondindex >= 0 &&
          fristindex < tableArr.length &&
          secondindex < tableArr[0].length
        ) {
          nextType = true;
        }
        if (!nextType) {
          return;
        }
        if (tableArr[fristindex][secondindex].positionType == 1) {
          let color = tableArr[fristindex][secondindex].structureColor.color;
          this.newsettableArrstructure(color);
        }
      }
    },

    //图片拖拽功能
    creatrImgCanvas(
      fristindex,
      secondindex,
      imgIndex,
      imgLeftTableNum,
      imgTopTableNum
    ) {
      let Scrolldata = this.getScroll();
      let { minxIndex, maxxIndex, minyIndex, maxyIndex } = this.getshowTable(
        Scrolldata.left,
        Scrolldata.top
      );
      let imgCanvasListItem = [];
      imgCanvasList.forEach((item) => {
        if (item.imgIndex == imgIndex) {
          imgCanvasListItem = item;
        }
      });

      var getCanvas = document.querySelector("#imgCanvas");
      let context = getCanvas.getContext("2d", {
        willReadFrequently: true,
      });

      this.setImgtableCanvas(
        getCanvas,
        context,
        minxIndex,
        maxxIndex,
        minyIndex,
        maxyIndex,
        imgCanvasListItem,
        0,
        0
      );
      document.querySelector(".mouseMask").onmousemove = (e) => {
        let newFristindex =
          newFristindex > fristindex
            ? this.getPosscroll(e, 1, fristindex, secondindex)
            : this.getPosscroll(e, 1, fristindex, secondindex);

        let newSecondindex =
          newSecondindex > secondindex
            ? this.getPosscroll(e, 2, fristindex, secondindex)
            : this.getPosscroll(e, 2, fristindex, secondindex);
        //上下边界
        if (0 >= newFristindex + minyIndex - imgTopTableNum - this.sockbodyPosition.leftTop.y) {
          newFristindex =
            newFristindex +
            Math.abs(newFristindex + minyIndex - imgTopTableNum - this.sockbodyPosition.leftTop.y);
          this.t = 0;
        } else if (
          (tableArr.length - (tableArr.length - this.sockbodyPosition.rightBottom.y)) <=
          (minyIndex +
            newFristindex +
            imgCanvasListItem.data.length -
            imgTopTableNum)
        ) {
          let a =
            minyIndex +
            newFristindex +
            imgCanvasListItem.data.length -
            imgTopTableNum;
          let b = tableArr.length - (tableArr.length - this.sockbodyPosition.rightBottom.y);
          newFristindex = newFristindex - (a - b) + 1;
          this.t = 0;
        } else {
          this.t = e.layerY - (newFristindex + 1) * this.tableItemsize;
        }
        //左右边界
        if (0 >= newSecondindex + minxIndex - imgLeftTableNum - this.sockbodyPosition.leftTop.x) {
          if (0 == newSecondindex + minxIndex - imgLeftTableNum - this.sockbodyPosition.leftTop.x) {
          }
          newSecondindex =
            newSecondindex +
            Math.abs(newSecondindex + minxIndex - imgLeftTableNum - this.sockbodyPosition.leftTop.x);
          this.l = 0;
        } else if (
          (tableArr[0].length - (tableArr[0].length - this.sockbodyPosition.rightBottom.x)) <=
          (minxIndex +
            newSecondindex +
            imgCanvasListItem.data[0].length -
            imgLeftTableNum)
        ) {
          let a =
            minxIndex +
            newSecondindex +
            imgCanvasListItem.data[0].length -
            imgLeftTableNum;
          let b = (tableArr[0].length - (tableArr[0].length - this.sockbodyPosition.rightBottom.x));
          newSecondindex = newSecondindex - (a - b) + 1;
          this.l = 0;
        } else {
          this.l = e.layerX - (newSecondindex + 1) * this.tableItemsize;
        }
        this.newFristindex = newFristindex - fristindex;
        this.newSecondindex = newSecondindex - secondindex;
        this.setImgtableCanvas(
          getCanvas,
          context,
          minxIndex,
          maxxIndex,
          minyIndex,
          maxyIndex,
          imgCanvasListItem,
          newFristindex - fristindex,
          newSecondindex - secondindex
        );
      };
    },

    //动态渲染拖动的图片
    setImgtableCanvas(
      getCanvas,
      context,
      minxIndex,
      maxxIndex,
      minyIndex,
      maxyIndex,
      imgCanvasListItem,
      newFristindex,
      newSecondindex
    ) {
      let xLine = maxxIndex - minxIndex - 7;
      let yLine = maxyIndex - minyIndex - 7;
      getCanvas.width = xLine * this.tableItemsize;
      getCanvas.height = yLine * this.tableItemsize;
      for (
        var i = minyIndex - imgCanvasListItem.canvasTop - newFristindex;
        i < maxyIndex + 3;
        i++
      ) {
        for (
          var j = minxIndex - imgCanvasListItem.canvasLfet - newSecondindex;
          j < maxxIndex + 3;
          j++
        ) {
          if (
            i >= 0 &&
            j >= 0 &&
            i < imgCanvasListItem.data.length &&
            j < imgCanvasListItem.data[0].length
          ) {
            let items = imgCanvasListItem.data[i][j];
            context.beginPath();
            context.fillStyle = `rgba(${items.r},${items.g},${items.b})`;
            context.lineWidth = 0;
            context.strokeStyle = "#979797";
            context.rect(
              (j - minxIndex + imgCanvasListItem.canvasLfet + newSecondindex) *
              this.tableItemsize,
              (i - minyIndex + imgCanvasListItem.canvasTop + newFristindex) *
              this.tableItemsize,
              this.tableItemsize,
              this.tableItemsize
            );
            context.stroke();
            context.closePath();
            context.fill();
          }
        }
      }
      this.imgCanvasmaskType = true;
    },

    //停止拖拽图片
    stopousemoveImgcnavas() {
      if (this.iScreateImgCanvas) {
        this.setImgpos();
      } else {
        this.clearImgcanvasData();
      }
    },

    //清除拖拽图片canvas数据
    clearImgcanvasData() {
      clearTimeout(this.longPressTime);
      document.querySelector(".mouseMask").onmousemove = null;
      this.imgCanvasmaskType = false;
      this.iScreateImgCanvas = false;
      this.t = 0;
      this.l = 0;
      this.dragType = false;
      this.iSmousemove = false;
      var getCanvas = document.querySelector("#imgCanvas");
      let context = getCanvas.getContext("2d", {
        willReadFrequently: true,
      });
      context.clearRect(0, 0, getCanvas.width, getCanvas.height);
      this.newFristindex = 0;
      this.newSecondindex = 0;
      this.imgIndex = 0;
    },

    //切换拖拽结束的图片位置
    setImgpos() {
      let index = 0;
      imgCanvasList.forEach((item, p) => {
        if (item.imgIndex == this.imgIndex) {
          index = p;
          imgCanvasList[p].canvasTop =
            imgCanvasList[p].canvasTop + this.newFristindex;
          imgCanvasList[p].canvasLfet =
            imgCanvasList[p].canvasLfet + this.newSecondindex;

          item.data.forEach((items, i) => {
            items.forEach((el, j) => {
              let a =
                JSON.parse(JSON.stringify(imgCanvasList[p].data[i][j].i)) +
                this.newFristindex;
              let b =
                JSON.parse(JSON.stringify(imgCanvasList[p].data[i][j].j)) +
                this.newSecondindex;
              tableArr[el.i][el.j].imageArr.forEach((imageArrItem, o) => {
                if (imageArrItem.imgIndex == this.imgIndex) {
                  if (tableArr[el.i][el.j].imageArr[o].type) {
                    // tableArr[a][b].active = JSON.parse(
                    //   JSON.stringify(tableArr[el.i][el.j].active)
                    // );
                    // tableArr[el.i][el.j].active = 1;
                  }
                  tableArr[el.i][el.j].imageArr.splice(o, 1);
                }
              });
              tableArr[el.i][el.j].imageArr = this.filteColor(
                tableArr[el.i][el.j].imageArr
              );
              tableArr[el.i][el.j].imageArr.forEach((o) => {
                if (o.type) {
                  this.yarnList.forEach((p) => {
                    if (o.color == `rgb(${p.rgb})`) {
                      if (p.structure != undefined) {
                        let a =
                          p.structure.num >= 10
                            ? p.structure.num
                            : "0" + p.structure.num;
                        this.addStructureList.forEach((u) => {
                          if (u.index == a) {
                            let y = 0;
                            let x = 0;
                            if (el.i < u.Structure.length) {
                              y = el.i;
                            } else {
                              y = el.i % u.Structure.length;
                            }
                            if (el.j < u.Structure[0].length) {
                              x = el.j;
                            } else {
                              x = el.j % u.Structure[0].length;
                            }
                            tableArr[el.i][el.j].active = u.Structure[y][x];
                          }
                        });
                      }
                    }
                  });
                }
              });
              imgCanvasList[p].data[i][j].i = a;
              imgCanvasList[p].data[i][j].j = b;
            });
          });
        }
      });
      imgCanvasList[index].data.forEach((item, i) => {
        item.forEach((el, j) => {
          tableArr[el.i][el.j].imageArr.forEach((l, j) => {
            tableArr[el.i][el.j].imageArr[j].type = false;
          });
          tableArr[el.i][el.j].imageArr.splice(this.imgIndex, 0, {
            color: `rgb(${el.r},${el.g},${el.b})`,
            imgIndex: this.imgIndex,
            type: false,
            i,
            j,
          });
          tableArr[el.i][el.j].imageArr = this.filteColor(
            tableArr[el.i][el.j].imageArr
          );
        });
      });
      this.clearImgcanvasData();
      this.getScrollRendercanvas();
      // this.setsaveData();
    },

    //选框功能
    Listenerslide(minxIndex, minyIndex) {
      this.ISpaintingbrushMask = true;
      this.tableCanvas.onmousemove = (e) => {
        var fristindex = this.getPos(e, 1);
        var secondindex = this.getPos(e, 2);
        if (
          fristindex >= 0 &&
          secondindex >= 0 &&
          fristindex < this.cilckListenerslideArr.length &&
          secondindex < this.cilckListenerslideArr[0].length
        ) {
          let type = true;
          if (this.checkListenerslideArr.length != 0) {
            //去重
            for (var i = 0; i < this.checkListenerslideArr.length; i++) {
              if (
                this.checkListenerslideArr[i].fristindex == fristindex &&
                this.checkListenerslideArr[i].secondindex == secondindex
              ) {
                type = false;
                return;
              }
            }
          }
          if (!type) {
            return;
          }
          this.checkListenerslideArr.push({
            fristindex,
            secondindex,
            i: this.cilckListenerslideArr[fristindex][secondindex].i,
            j: this.cilckListenerslideArr[fristindex][secondindex].j
          });
          this.context.beginPath();
          this.cilckListenerslideArr[fristindex][secondindex].imageArr.forEach(
            (el) => {
              if (el.type) {
                this.context.fillStyle = el.color;
              }
            }
          );
          this.context.lineWidth = 2;
          this.context.strokeStyle = "#1890FF";
          this.context.rect(
            secondindex * this.tableItemsize + 1 + 20,
            fristindex * this.tableItemsize + 1 + 20,
            this.tableItemsize - 1,
            this.tableItemsize - 1
          );
          this.context.stroke();
          this.context.closePath();
          this.context.fill();
        }
      };
    },

    //超出canvas抬起鼠标监听
    outCanvasmouseup() {
      if (this.ListenerslideType) {
        this.clearCanvasmouseMove(1);
      } else {
        this.setImgpos();
      }
    },

    //鼠标抬起
    mouseLeftUp() {
      if (this.ListenerslideType) {
        this.clearCanvasmouseMove(1);
      }
    },

    //清除画笔选中的网格
    clearmouseTable() {
      this.checkListenerslideArr.forEach((item, index) => {
        let conItem =
          this.cilckListenerslideArr[item.fristindex][item.secondindex];
        this.context.beginPath();
        tableArr[conItem.i][conItem.j].imageArr.forEach((el) => {
          if (el.type) {
            this.context.fillStyle = el.color;
          }
        });
        this.context.lineWidth = 2;
        this.context.strokeStyle = "#979797";
        this.context.rect(
          item.secondindex * this.tableItemsize + 1 + 20,
          item.fristindex * this.tableItemsize + 1 + 20,
          this.tableItemsize - 1,
          this.tableItemsize - 1
        );
        this.context.stroke();
        this.context.closePath();
        this.context.fill();
      });
      this.checkListenerslideArr = [];
      this.cilckListenerslideArr = [];
      this.ISpaintingbrushMask = false;
    },

    //清除canvas选框鼠标事件
    clearCanvasmouseMove(type) {
      this.tableCanvas.onmousemove = null;
      this.iSmousemove = false;
      // this.ISpaintingbrushMask = false;
      // this.ListenerslideType = false;
      if (type == 2) {
        this.getScrollRendercanvas();
      }
    },

    //获取点击坐标
    getPos(e, type) {
      var fristindex = 0;
      var secondindex = 0;
      fristindex = Math.floor((e.offsetY - 20) / this.tableItemsize);
      secondindex = Math.floor((e.offsetX - 20) / this.tableItemsize);
      if (type == 1) {
        return fristindex;
      } else {
        return secondindex;
      }
    },

    //获取点击坐标
    getPosscroll(e, type) {
      var fristindex = 0;
      var secondindex = 0;
      fristindex = Math.floor(e.layerY / this.tableItemsize);
      secondindex = Math.floor(e.layerX / this.tableItemsize);
      if (type == 1) {
        return fristindex;
      } else {
        return secondindex;
      }
    },

    //新建页面重置页面数据
    newlyBuild(type) {
      tableArr = [];
      imgCanvasList = [];
      saveData = [];
      checktableArr = [];
      if (this.context != "") {
        this.context.clearRect(
          0,
          0,
          this.VisualwindowSize.w * this.tableItemsize + 20,
          this.VisualwindowSize.h * this.tableItemsize + 20
        );
      }
      for (var key in dataObj) {
        if (
          key != "dataObj" &&
          key != "checkStructuredataModel" &&
          key != "headIcon"
        ) {
          this.$data[key] = JSON.parse(JSON.stringify(dataObj[key]));
        }
      }
      setTimeout(() => {
        if (type == 1) {
          this.$router.push("/oneIndex");
        } else if (type == 2) {
          this.$router.push("/modelSwitching");
        }
      }, 100);
    },

    //打开纱线弹框修改特殊位置
    openPTSpupsetposition(index) {
      // 0:罗口 1:袜头 2:袜跟 3:背景色 (index)
      // 2:罗口 3:袜头 4:袜跟 0:背景色 (table内对应标识)
      let positionIndex = 0;
      if (index != 3) {
        positionIndex = index + 2;
      } else {
        positionIndex = 0;
      }
      this.ptsData = {
        type: "2",
        index,
        colorItem: this.positionColorList[index],
        position: positionIndex,
      };
      this.PanTongseHidden = true;
    },

    //打开纱线弹框
    openPTSpup(type, index) {
      if (type == 1) {
        this.ptsData = {
          type: "1",
        };
        this.PanTongseHidden = true;
      } else {
        console.log(this.ISpaintingbrushMask)
        if (this.ISpaintingbrushMask) {
          this.checkListenerslideArr.forEach((item) => {
            let conItem =
              this.cilckListenerslideArr[item.fristindex][item.secondindex];
            tableArr[conItem.i][conItem.j].imageArr.forEach((items, i) => {
              if (items.type) {
                if (tableArr[conItem.i][conItem.j].positionType == 1) {
                  if (items.imgIndex == this.checkImgindex) {
                    tableArr[conItem.i][conItem.j].imageArr[
                      i
                    ].color = `rgb(${this.yarnList[index].rgb})`;
                    imgCanvasList.forEach((el, j) => {
                      if (el.imgIndex == items.imgIndex) {
                        let rgbArr = {
                          r: this.yarnList[index].rgb.split(",")[0],
                          g: this.yarnList[index].rgb.split(",")[1],
                          b: this.yarnList[index].rgb.split(",")[2],
                          a: 255,
                          i: imgCanvasList[j].data[items.i][items.j].i,
                          j: imgCanvasList[j].data[items.i][items.j].j,
                        };
                        imgCanvasList[j].data[items.i][items.j] = rgbArr;
                      }
                    });
                    checktableArr.push({ i: conItem.i, j: conItem.j, index });
                  }
                }
              }
            });
            this.context.beginPath();
            tableArr[conItem.i][conItem.j].imageArr.forEach((el) => {
              if (el.type) {
                this.context.fillStyle = el.color;
              }
            });
            this.context.lineWidth = 2;
            this.context.strokeStyle = "#979797";
            this.context.rect(
              item.secondindex * this.tableItemsize + 1 + 20,
              item.fristindex * this.tableItemsize + 1 + 20,
              this.tableItemsize - 1,
              this.tableItemsize - 1
            );
            this.context.stroke();
            this.context.closePath();
            this.context.fill();
          });
          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d", {
            willReadFrequently: true,
          });
          imgCanvasList.forEach((item, index) => {
            if (this.checkImgindex == item.imgIndex) {
              canvas.width = item.data[0].length;
              canvas.height = item.data.length;
              context.putImageData(this.createImg(item.data, 2), 0, 0);
              imgCanvasList[index].img = canvas.toDataURL("image/png");
              this.$set(this.layerList[index], "img", imgCanvasList[index].img);
            }
          });
          this.checkListenerslideArr = [];
          this.cilckListenerslideArr = [];
          // this.ISpaintingbrushMask = false;
          let useListColor = JSON.parse(localStorage.getItem("useListColor"));
          if (useListColor != null) {
            let addType = true;
            useListColor.forEach((el) => {
              if (el.nr == this.yarnList[index].nr) {
                addType = false;
              }
            });
            if (addType) {
              if (useListColor.length == 4) {
                useListColor.splice(3, 1);
                useListColor.unshift(this.yarnList[index]);
              } else {
                useListColor.unshift(this.yarnList[index]);
              }
              localStorage.setItem(
                "useListColor",
                JSON.stringify(useListColor)
              );
            }
          } else {
            let arr = [];
            arr.push(this.yarnList[index]);
            localStorage.setItem("useListColor", JSON.stringify(arr));
          }
          // this.useList = JSON.parse(localStorage.getItem("useListColor"));
          // this.setsaveData();
        } else {
          console.log(32113)
          this.ptsData = {
            type: "2",
            index,
            colorItem: this.yarnList[index],
          };
          this.PanTongseHidden = true;
        }
      }
    },

    //更改纱线回调
    getNewYarn(data) {
      if (data.type) {
        //特殊部位添加
        if (data.data.pupData.position != undefined) {
          this.positionColorList.splice(data.data.pupData.index, 1, {
            ...data.data.color,
            component: {
              type: 1,
              data: [
                {
                  componentNum: 0,
                  proportion: 100,
                },
              ],
            },
            YarnCount: {
              num: 40,
              unit: 1,
            },
          });
          tableArr.forEach((item, index) => {
            item.forEach((items, indexs) => {
              if (items.positionType == data.data.pupData.position) {
                tableArr[index][
                  indexs
                ].imageArr[0].color = `rgb(${data.data.color.rgb})`;
              }
            });
          });
          this.getScrollRendercanvas();
        } else {
          //判断是否重复添加
          let type = true;
          let setYarnIndex = "";
          let newNr = `${data.data.color.nr.substring(
            8,
            10
          )}${data.data.color.nr.substring(11, 15)}`;
          this.yarnList.forEach((item, index) => {
            if (
              `${item.nr.substring(8, 10)}${item.nr.substring(11, 15)}` == newNr
            ) {
              type = false;
              setYarnIndex = index;
            }
          });
          if (data.data.pupData.type == 1) {
            if (type) {
              //新增纱线
              this.yarnList.unshift({
                ...data.data.color,
                component: {
                  type: 1,
                  data: [
                    {
                      componentNum: 0,
                      proportion: 100,
                    },
                  ],
                },
                YarnCount: {
                  num: 40,
                  unit: 1,
                },
              });
              let useListColor = JSON.parse(localStorage.getItem("useListColor"));
              if (useListColor != null) {
                if (useListColor.length == 4) {
                  useListColor.splice(3, 1);
                  useListColor.unshift(this.yarnList[0]);
                } else {
                  useListColor.unshift(this.yarnList[0]);
                }
                localStorage.setItem(
                  "useListColor",
                  JSON.stringify(useListColor)
                );
              } else {
                let arr = [];
                arr.push(this.yarnList[0]);
                localStorage.setItem("useListColor", JSON.stringify(arr));
              }
            }
          } else {
            //修改纱线
            let newData = {
              YarnCount: this.yarnList[data.data.pupData.index].YarnCount,
              component: this.yarnList[data.data.pupData.index].component,
              ...data.data.color,
            };
            tableArr.forEach((item, index) => {
              item.forEach((items, indexs) => {
                if (items.positionType == 1) {
                  if (
                    items.imageArr[0].color ==
                    `rgb(${this.yarnList[data.data.pupData.index].rgb})`
                  ) {
                    tableArr[index][
                      indexs
                    ].imageArr[0].color = `rgb(${newData.rgb})`;

                    if (items.imageArr[0].type) {
                      tableArr[index][indexs].active = 1;
                    }
                  }
                }
              });
            });
            imgCanvasList.forEach((item, index) => {
              item.data.forEach((items, indexs) => {
                items.forEach((el, i) => {
                  if (tableArr[el.i][el.j].positionType == 1) {
                    if (
                      `${el.r},${el.g},${el.b}` ==
                      `${this.yarnList[data.data.pupData.index].rgb}`
                    ) {
                      tableArr[el.i][el.j].imageArr.forEach((list, j) => {
                        if (list.imgIndex == item.imgIndex) {
                          tableArr[el.i][el.j].imageArr[
                            j
                          ].color = `rgb(${newData.rgb})`;

                          if (tableArr[el.i][el.j].imageArr[j].type) {
                            tableArr[el.i][el.j].active = 1;
                          }
                        }
                      });
                      imgCanvasList[index].data[indexs][i].r =
                        newData.rgb.split(",")[0] * 1;
                      imgCanvasList[index].data[indexs][i].g =
                        newData.rgb.split(",")[1] * 1;
                      imgCanvasList[index].data[indexs][i].b =
                        newData.rgb.split(",")[2] * 1;
                    }
                  }
                });
              });
            });
            let useListColor = JSON.parse(localStorage.getItem("useListColor"));
            if (useListColor != null) {
              if (useListColor.length == 4) {
                useListColor.splice(3, 1);
                useListColor.unshift(newData);
              } else {
                useListColor.unshift(newData);
              }
              localStorage.setItem(
                "useListColor",
                JSON.stringify(useListColor)
              );
            } else {
              let arr = [];
              arr.push(newData);
              localStorage.setItem("useListColor", JSON.stringify(arr));
            }
            let canvas = document.createElement("canvas");
            let context = canvas.getContext("2d", {
              willReadFrequently: true,
            });
            imgCanvasList.forEach((item, index) => {
              canvas.width = item.data[0].length;
              canvas.height = item.data.length;
              context.putImageData(this.createImg(item.data, 2), 0, 0);
              imgCanvasList[index].img = canvas.toDataURL("image/png");
              this.$set(this.layerList[index], "img", imgCanvasList[index].img);
            });
            if (type) {
              if (data.data.pupData.colorItem.bottomColor) {
                newData.bottomColor = true;
              }
              this.yarnList.splice(data.data.pupData.index, 1, newData);
            } else {
              if (setYarnIndex != data.data.pupData.index) {
                if (data.data.pupData.colorItem.bottomColor) {
                  newData.bottomColor = true;
                  this.$set(this.yarnList[setYarnIndex], "bottomColor", true);
                }
                this.yarnList.splice(data.data.pupData.index, 1);
                checktableArr.forEach((item, i) => {
                  if (item.index == data.data.pupData.index) {
                    checktableArr[i].index = setYarnIndex;
                  }
                });
              }
            }
            // this.setsaveData();
            this.checkListenerslideArr = [];
            this.cilckListenerslideArr = [];
            this.getScrollRendercanvas();
          }
        }
      }

      this.PanTongseHidden = false;
    },

    //隐藏或显示或删除图层
    showlayer(index, type) {
      let nextType = true;
      if (!this.rasterizing) {
        return;
      }
      // if (this.pageType == 2) {
      //   if (this.layerList.length == 1) {
      //     nextType = false;
      //   }

      //   if (this.layerList[index].isShow) {
      //     let num = 0;
      //     this.layerList.forEach((item, i) => {
      //       if (item.isShow) {
      //         num++;
      //       }
      //       if (i == this.layerList.length - 1) {
      //         if (num < 2) {
      //           nextType = false;
      //         }
      //       }
      //     });
      //   }
      // }
      // if (!nextType) {
      //   return;
      // }
      // // 图层导入第一张图片显示或隐藏 或 删除
      // if (this.layerList[index].imgIndex == 1 && this.pageType == 2) {
      //   this.firstImgshow = !this.firstImgshow;
      //   this.setFirstImg(type);
      //   return;
      // }
      this.$set(this.layerList[index], "isShow", !this.layerList[index].isShow);
      imgCanvasList.forEach((item) => {
        if (item.imgIndex == this.layerList[index].imgIndex) {
          item.data.forEach((el) => {
            el.forEach((list) => {
              if (type == 3) {
                for (
                  var i = 0;
                  i < tableArr[list.i][list.j].imageArr.length;
                  i++
                ) {
                  if (
                    tableArr[list.i][list.j].imageArr[i].imgIndex ==
                    this.layerList[index].imgIndex
                  ) {
                    tableArr[list.i][list.j].imageArr.splice(i, 1);
                    i--;
                  }
                }
              }
              tableArr[list.i][list.j].imageArr = this.filteColor(
                tableArr[list.i][list.j].imageArr
              );
            });
          });
        }
      });
      if (type == 3) {
        let arr = [];
        tableArr.forEach((item) => {
          item.forEach((items) => {
            items.imageArr.forEach((el) => {
              if (arr.length == 0) {
                arr.push(el.color);
              } else {
                //去重
                let colorType = true;
                for (var i = 0; i < arr.length; i++) {
                  if (arr[i] == el.color) {
                    colorType = false;
                    return;
                  }
                }
                if (colorType) {
                  arr.push(el.color);
                }
              }
            });
          });
        });
        let delType = true;
        let colorArr = [];
        this.layerList[index].color.forEach((item) => {
          delType = true;
          arr.forEach((el, j) => {
            if (`rgb(${item.rgb})` == el) {
              delType = false;
            }
            if (j == arr.length - 1) {
              if (delType) {
                for (var i = 0; i < this.yarnList.length; i++) {
                  if (
                    item.nr.substring(8, 10) ==
                    this.yarnList[i].nr.substring(8, 10) &&
                    item.nr.substring(11, 15) ==
                    this.yarnList[i].nr.substring(11, 15)
                  ) {
                    colorArr.push(JSON.parse(JSON.stringify(this.yarnList[i])));
                    this.yarnList.splice(i, 1);
                    i--;
                  }
                }
              }
            }
          });
        });
        colorArr.forEach((item, index) => {
          if (item.structure != undefined) {
            let num = 0;
            this.yarnList.forEach((el, i) => {
              if (el.structure != undefined) {
                if (item.structure.num == el.structure.num) {
                  num++;
                }
              }

              if (i == this.yarnList.length - 1) {
                if (num < 1) {
                  let a =
                    item.structure.num >= 10
                      ? item.structure.num
                      : "0" + item.structure.num;
                  this.addStructureList.forEach((o, j) => {
                    if (o.index == a) {
                      this.$set(this.addStructureList[j], "type", false);
                    }
                  });
                }
              }
            });
          }
        });

        this.layerList.splice(index, 1);
        imgCanvasList.splice(index, 1);
        if (this.layerList.length == 0) {
          this.layerListNum = 0;
        }
      }

      this.getScrollRendercanvas();
    },

    //图层导入第一张图片显示或隐藏 或 删除
    setFirstImg(type) {
      if (type == 1 || type == 2) {
        tableArr.forEach((item) => {
          item.forEach((items) => {
            items.imageArr.forEach((el) => {
              if (el.imgIndex == 1) {
                if (this.firstImgshow) {
                  el.color = el.hiddenColor;
                } else {
                  el.color = `rgb(${this.yarnList[0].rgb})`;
                }
              }
            });
          });
        });
        this.getScrollRendercanvas();
      } else if (type == 3) {
        let arr = [];
        tableArr.forEach((item) => {
          item.forEach((items) => {
            items.imageArr.forEach((el, i) => {
              if (el.imgIndex != 1) {
                if (arr.length == 0) {
                  arr.push(el.color);
                } else {
                  //去重
                  let colorType = true;
                  for (var i = 0; i < arr.length; i++) {
                    if (arr[i] == el.color) {
                      colorType = false;
                      return;
                    }
                  }
                  if (colorType) {
                    arr.push(el.color);
                  }
                }
              }
            });
          });
        });
        this.layerList.forEach((items) => {
          if (items.imgIndex == 1) {
            let delType = true;
            items.color.forEach((item) => {
              arr.forEach((el, j) => {
                if (`rgb(${item.rgb})` == el) {
                  delType = false;
                }
                if (j == arr.length - 1) {
                  if (delType) {
                    for (var i = 0; i < this.yarnList.length; i++) {
                      if (
                        item.nr.substring(8, 10) ==
                        this.yarnList[i].nr.substring(8, 10) &&
                        item.nr.substring(11, 15) ==
                        this.yarnList[i].nr.substring(11, 15)
                      ) {
                        this.yarnList.splice(i, 1);
                        i--;
                      }
                    }
                  }
                }
              });
            });
          }
        });
        tableArr.forEach((item) => {
          item.forEach((items) => {
            items.imageArr.forEach((el, i) => {
              if (i == 0) {
                el.color = `rgb(${this.yarnList[0].rgb})`;
                el.imgIndex = 0;
              }
            });
          });
        });
        this.layerList.splice(0, 1);
        imgCanvasList.splice(0, 1);
        // this.createTable()
        // this.layerList.forEach((item, index) => {
        //   if (item.imgIndex == 1) {
        //     this.$set(this.layerList[index], "imgIndex", 0);
        //     this.$set(this.layerList[index], "label", 0);

        //     imgCanvasList[index].imgIndex = 0;
        //     imgCanvasList[index].label = 0;
        //   }
        // });
        if (this.layerList.length == 0) {
          this.layerListNum = 0;
        }
      }
      this.getScrollRendercanvas();
    },

    //判断当前网格显示那个图层颜色
    filteColor(arr) {
      let hideArr = []; //隐藏的图层
      let showArr = []; //未隐藏的图层
      this.layerList.forEach((item) => {
        if (!item.isShow) {
          hideArr.push(item.imgIndex);
        }
      });
      arr.forEach((item, index) => {
        item.type = false;
        if (hideArr.length != 0) {
          for (var i = 0; i < hideArr.length; i++) {
            if (item.imgIndex == hideArr[i]) {
              return;
            }
            if (i == hideArr.length - 1) {
              showArr.push(index);
            }
          }
        } else {
          showArr.push(index);
        }
      });
      arr[showArr[showArr.length - 1]].type = true;
      return arr;
    },

    //放大缩小画布
    setZoomnum(type) {
      if (type == 1) {
        if (this.zoomNum > 10) {
          if (this.zoomNum <= 100) {
            if (this.zoomNum <= 30) {
              this.zoomNum -= 5;
              this.tableItemsize -= 1;
              console.log(this.tableItemsize)
            } else {
              this.zoomNum -= 10;
              this.tableItemsize -= 2;
            }
          } else {
            this.zoomNum -= 25
            this.tableItemsize -= 5;
          }
        }
      } else {
        if (this.zoomNum < 400) {
          if (this.zoomNum >= 100) {
            this.zoomNum += 25
            this.tableItemsize += 5;
          } else {
            if (this.zoomNum >= 30) {
              this.zoomNum += 10
              this.tableItemsize += 2;
            } else {
              this.zoomNum += 5
              this.tableItemsize += 1;
            }
          }
        }
      }
      this.countPageTableSize();
      this.getScrollRendercanvas();
    },

    //深度拷贝
    deepClone(obj) {
      let objClone = Array.isArray(obj) ? [] : {};
      if (obj && typeof obj === "object") {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
            //判断ojb子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === "object") {
              objClone[key] = this.deepClone(obj[key]);
            } else {
              //如果不是，简单复制
              objClone[key] = obj[key];
            }
          }
        }
      }
      return objClone;
    },

    //撤回
    setsaveData() {
      let data = {
        yarnList: this.yarnList,
        layerListNum: this.layerListNum,
        layerList: this.layerList,
        imgCanvasList: imgCanvasList,
        tableArr: tableArr,
      };
      let newData = this.deepClone(data);
      saveData.push(newData);
    },

    // 结构处理

    //切换页面展示颜色
    setPagecolorTable(type) {
      if (type == 1 && this.upStructureImg == '') {
        this.$message({
          showClose: true,
          message: this.$t('machine.txt104'),
          type: "warning",
        });
      }
      if (this.upStructureImg != "") {
        this.pagecolorType = type;
        this.getScrollRendercanvas();
      }
    },

    //显示结构或纱线
    openStructureorcolor(num) {
      this.itemBtn = num;
      if (num == 2) {
        this.createStructureorImg();
      }
      this.getScrollRendercanvas();
    },

    //生成结构缩略图
    createStructureorImg() {
      if (this.Structure.length == 0) {
        return;
      }
      this.$nextTick(() => {
        let Hbfb = 100 / this.Structure.length;
        let Wbfb = 100 / this.Structure[0].length;
        let h = document.querySelector(".Structurethumbnail").offsetHeight;
        let w = document.querySelector(".Structurethumbnail").offsetWidth;
        if (w >= h) {
          // this.FullDataStructureH = (w * Wbfb) / 100;
          // this.FullDataStructureW = (w * Wbfb) / 100;
          this.FullDataStructureH = 20;
          this.FullDataStructureW = 20;
        } else {
          // this.FullDataStructureH = (h * Hbfb) / 100;
          // this.FullDataStructureW = (h * Hbfb) / 100;
          this.FullDataStructureH = 20;
          this.FullDataStructureW = 20;
        }
        if (this.FullDataStructureW * this.Structure[0].length > w) {
          this.StructureStyleW = true;
        } else {
          this.StructureStyleW = false;
        }
        if (this.FullDataStructureH * this.Structure.length > h) {
          this.StructureStyleH = true;
        } else {
          this.StructureStyleH = false;
        }
      });
    },

    //结构添加
    newsettableArrstructure(color, copyStructure) {
      let useStructure =
        copyStructure == undefined ? this.Structure : copyStructure;
      let type = this.Isworker();
      if (type) {
        let resData = {
          //生成初始化网格数据
          tableArr,
          structure: useStructure,
          color,
          type: 2,
        };
        let worker = new tabledataWorker();
        worker.postMessage(resData);
        worker.onmessage = (e) => {
          tableArr = e.data;
          this.addStructureList.forEach((element, i) => {
            this.$set(this.addStructureList[i], "type", false);
          });
          this.StructureImgColorList.forEach((item, index) => {
            if (`rgb(${item.rgb})` == color) {
              this.$set(
                this.StructureImgColorList[index],
                "index",
                this.addStructureList[this.checkStructureIndex].index
              );
            }
            if (item.index != undefined) {
              this.addStructureList.forEach((element, i) => {
                if (element.index == item.index) {
                  this.$set(this.addStructureList[i], "type", true);
                }
              });
            }
          });
          this.getScrollRendercanvas();
          worker.terminate();
          worker = undefined;
        };
      } else {
        let i = -1;
        let j = -1;
        tableArr.forEach((item, index) => {
          i++;
          item.forEach((items, indexs) => {
            j++;
            if (items.structureColor.color == data.color && items.positionType == 1) {
              if (items.positionType == 1) {
                items.active = data.structure[i][j]
                items.structureColor.type = true
              }
            }
            if (j == useStructure[0].length - 1) {
              j = -1;
            }
            if (indexs == item.length - 1) {
              j = -1;
            }
          });

          if (i == useStructure.length - 1) {
            i = -1;
            j = -1;
          }
        });
        this.addStructureList.forEach((element, i) => {
          this.$set(this.addStructureList[i], "type", false);
        });
        this.StructureImgColorList.forEach((item, index) => {
          if (`rgb(${item.rgb})` == color) {
            this.$set(
              this.StructureImgColorList[index],
              "index",
              this.addStructureList[this.checkStructureIndex].index
            );
          }
          if (item.index != undefined) {
            this.addStructureList.forEach((element, i) => {
              if (element.index == item.index) {
                this.$set(this.addStructureList[i], "type", true);
              }
            });
          }
        });
        this.getScrollRendercanvas();
      }
    },

    //结构添加到底层数据
    settableArrstructure(color, copyStructure) {
      if (copyStructure == undefined) {
        let num = 0;
        let StructureKey = "";
        let StructureIndex;
        this.yarnList.forEach((item, index) => {
          if (`rgb(${item.rgb})` == color) {
            StructureIndex = index;
            if (item.structure != undefined) {
              StructureKey = item.structure.num;
            }
          }
        });
        this.yarnList.forEach((item, index) => {
          if (item.structure != undefined) {
            if (item.structure.num == StructureKey) {
              num++;
            }
          }
          if (index == this.yarnList.length - 1) {
            let a = StructureKey >= 10 ? StructureKey : "0" + StructureKey;
            if (num < 2 && num != 0) {
              this.addStructureList.forEach((o, i) => {
                if (o.index == a) {
                  this.$set(this.addStructureList[i], "type", false);
                }
              });
            }
          }
        });
        this.$set(this.yarnList[StructureIndex], "structure", {
          structure: this.Structure,
          num: this.addStructureList.length - this.checkStructureIndex,
        });
        this.$set(
          this.addStructureList[this.checkStructureIndex],
          "type",
          true
        );
        this.renderThumbnail(1);
        // this.rasterizing = false;
      }
      let useStructure =
        copyStructure == undefined ? this.Structure : copyStructure;
      let type = this.Isworker();
      if (type) {
        let resData = {
          //生成初始化网格数据
          tableArr,
          structure: useStructure,
          color,
          type: 2,
        };
        let worker = new tabledataWorker();
        worker.postMessage(resData);
        worker.onmessage = (e) => {
          tableArr = e.data;
          this.getScrollRendercanvas();
          worker.terminate();
          worker = undefined;
        };
      } else {
        let i = -1;
        let j = -1;
        tableArr.forEach((item, index) => {
          i++;
          item.forEach((items, indexs) => {
            j++;
            items.imageArr.forEach((el, o) => {
              if (el.type) {
                if (el.color == color) {
                  items.active = useStructure[i][j];
                }
              }
            });
            if (j == useStructure[0].length - 1) {
              j = -1;
            }
            if (indexs == item.length - 1) {
              j = -1;
            }
          });

          if (i == useStructure.length - 1) {
            i = -1;
            j = -1;
          }
        });
        this.getScrollRendercanvas();
      }
    },

    //打开结构弹框
    openStructurePup(type, index) {
      this.dialogTableVisible = true;
      if (type == 1) {
        this.pupData = {
          title: this.$t("machine.txt32"),
          pupType: 3,
          pupConType: 1,
        };
      } else {
        this.pupData = {
          title: this.$t("machine.txt33"),
          pupType: 3,
          pupConType: 2,
          data: {
            arr: this.StructureLibrary[index].arr,
            img: this.StructureLibrary[index].imgName,
          },
        };
      }
    },

    //点击继续织布
    backWeaving() {
      localStorage.setItem('leftNavtype', 1)
      this.newlyBuild(2);
    },
    //点击织物列表
    toList() {
      localStorage.setItem('leftNavtype', 1)
      this.newlyBuild();
      setTimeout(() => {
        this.$router.push("/history");
      }, 100);
    },
    //关闭导入图片弹框
    hiddenPup() {
      this.GetImgcolorHidden = false;
    },

    //提交数据生成织物、
    subimtData(sendsaveData) {
      var data = {
        designConfig: {
          meanWork: {
            width: tableArr[0].length,
            height: tableArr.length,
            data: [],
          },
          checktableArr,
          gauzeList: [],
          subimtType: this.subimtType,
          layerListNum: this.layerListNum,
          layerArr: [],
          copyYarnList: this.yarnList,
          positionColorList: this.positionColorList,
          machineNumber: this.machineNumber,
          needlebed: 1,
          pageType: this.pageType,
          addStructureList: this.addStructureList,
          StructureImgColorList: this.StructureImgColorList,
          StructureImg: this.upStructureImg,
          StructureIndex: this.checksheetIndex,
        },
        designType: 30,
        machineId: JSON.parse(localStorage.getItem("machineType")).machineId,
      };

      this.yarnList.forEach((item, index) => {
        data.designConfig.gauzeList.push({});
        data.designConfig.gauzeList[index].id = item.nr;
        data.designConfig.gauzeList[index].yarnColor = item.hexCode;
        data.designConfig.gauzeList[index].rawMaterials = "";
        item.component.data.forEach((el) => {
          data.designConfig.gauzeList[index].rawMaterials += `${el.proportion
            }%${this.materialQuality[el.componentNum].label}`;
        });
        data.designConfig.gauzeList[index].rgb = item.rgb;
        data.designConfig.gauzeList[index].yarnCount =
          item.YarnCount.num + (item.YarnCount.unit == 1 ? "D" : "S");
        data.designConfig.gauzeList[index].singleFiberFineness = "200%";
      });

      this.positionColorList.forEach((item, index) => {
        data.designConfig.gauzeList.push({});
        data.designConfig.gauzeList[index + this.yarnList.length].id = item.nr;
        data.designConfig.gauzeList[index + this.yarnList.length].yarnColor =
          item.hexCode;
        data.designConfig.gauzeList[index + this.yarnList.length].rawMaterials =
          "";
        item.component.data.forEach((el) => {
          data.designConfig.gauzeList[
            index + this.yarnList.length
          ].rawMaterials += `${el.proportion}%${this.materialQuality[el.componentNum].label
          }`;
        });
        data.designConfig.gauzeList[index + this.yarnList.length].rgb =
          item.rgb;
        data.designConfig.gauzeList[index + this.yarnList.length].yarnCount =
          item.YarnCount.num + (item.YarnCount.unit == 1 ? "D" : "S");
        data.designConfig.gauzeList[
          index + this.yarnList.length
        ].singleFiberFineness = "200%";
      });

      // 图层数据
      let imgArr = [];
      let imgArrIndex = [];
      this.layerList.forEach((item, i) => {
        imgCanvasList.forEach((el) => {
          if (item.imgIndex == el.imgIndex) {
            if (item.img.indexOf("data:image/") > -1) {
              imgArr.push(item.img.substring(22));
              imgArrIndex.push(i);
            }
            data.designConfig.layerArr.push({
              canvasLfet: el.canvasLfet,
              canvasTop: el.canvasTop,
              h: el.h,
              w: el.w,
              ...item,
            });
          }
        });
      });

      if (this.upStructureImg != "") {
        imgArr.push(this.upStructureImg.substring(22));
        imgArrIndex.push(imgArr.length);
      }
      // 网格数据
      tableArr.forEach((item, i) => {
        data.designConfig.meanWork.data.push([]);
        item.forEach((items, j) => {
          if (items.positionType == 1) {
            items.imageArr.forEach((el, aa) => {
              if (el.type == true) {
                for (var o = 0; o < data.designConfig.gauzeList.length - 4; o++) {
                  if (
                    el.color == `rgb(${data.designConfig.gauzeList[o].rgb})`
                  ) {
                    data.designConfig.meanWork.data[i].push(
                      o * 10 + items.active * 1
                    );
                  }
                }
              }
            });
          } else {
            if (items.positionType == 0) {
              data.designConfig.meanWork.data[i].push(
                (data.designConfig.gauzeList.length - 1) * 10 + items.active * 1
              );
            } else {
              data.designConfig.meanWork.data[i].push(
                (this.yarnList.length + (items.positionType - 2)) * 10 +
                items.active * 1
              );
            }
          }
        });
      });
      data.designConfig.meanWork.data =
        data.designConfig.meanWork.data.reverse();
      if (imgArr.length != 0) {
        uplaodImage({ baseImgArray: imgArr }).then((res) => {
          if (res.code == 0) {
            res.data.forEach((item, index) => {
              if (this.upStructureImg != "") {
                if (index == res.data.length - 1) {
                  data.designConfig.StructureImg = item;
                } else {
                  data.designConfig.layerArr[imgArrIndex[index]].img = item;
                }
              } else {
                data.designConfig.layerArr[imgArrIndex[index]].img = item;
              }
            });
            resultThsave({ data: JSON.stringify(data) }).then((res) => {
              if (res.code == 0) {
                this.isPreviewPup = 0;
                // this.$router.push({
                //   path: "/loading",
                //   query: { id: res.data.designId },
                // });
                this.designId = res.data.designId;
                this.saveShow = true;
                let saveData = {
                  ...sendsaveData.data,
                  machineId: JSON.parse(localStorage.getItem("machineType")).machineId,
                  designId: this.designId,
                  designResultId: 1001,
                  needleBed: 1,
                  machineNumber: this.machineNumber,
                };
                saveThCloth(saveData).then((res) => {
                  if (res.code == 0) {
                    this.saveShow = false;
                    this.submitSuccess = true;
                  } else {
                    this.$message({
                      showClose: true,
                      message: res.msg,
                      type: "warning",
                    });
                  }
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "warning",
                });
              }
            });
          }
        });
      } else {
        resultThsave({ data: JSON.stringify(data) }).then((res) => {
          if (res.code == 0) {
            this.isPreviewPup = 0;
            // this.$router.push({
            //   path: "/loading",
            //   query: { id: res.data.designId },
            // });
            this.designId = res.data.designId;
            this.saveShow = true;
            let saveData = {
              ...sendsaveData.data,
              machineId: JSON.parse(localStorage.getItem("machineType")).machineId,
              designId: this.designId,
              designResultId: 1001,
              needleBed: 1,
              machineNumber: this.machineNumber,
            };
            saveThCloth(saveData).then((res) => {
              if (res.code == 0) {
                this.saveShow = false;
                this.submitSuccess = true;
              } else {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "warning",
                });
              }
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "warning",
            });
          }
        });
      }
    },

    //保存
    getSaveData(data) {
      if (data.type) {
        this.subimtData(data)
      } else {
        this.saveShow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./oneIndex.less");

.el-select-dropdown__item {
  font-size: 18px !important;
  color: #333;
  height: 47px;
  display: flex;
  align-items: center;
  padding: 0 14px;
}

.el-select-dropdown__item span {
  font-size: 18px !important;
}
</style>