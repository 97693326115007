export const StructureLibrary = [
    {
        id: '1',
        arr: [
            [1, 1, 1, 1],
            [1, 2, 1, 2],
            [1, 1, 1, 1],
            [2, 1, 2, 1],

        ],
        imgName: '1'
    },
    {
        id: '2',
        arr: [
            [1, 2, 1, 2],
            [1, 2, 1, 2],
            [2, 1, 2, 1],
            [2, 1, 2, 1],
        ],
        imgName: '2'
    },
    {
        id: '3',
        arr: [
            [1, 1, 1, 1],
            [1, 2, 1, 2],
            [1, 1, 1, 1],
            [2, 1, 2, 1],
        ],
        imgName: '3'
    },
    {
        id: '4',
        arr: [
            [1, 1],
            [1, 2],
            [1, 2],
            [1, 1],
            [2, 1],
            [2, 1],
        ],
        imgName: '4'
    },
    {
        id: '5',
        arr: [
            [1, 1],
            [1, 1],
            [1, 2],
            [1, 2],
            [1, 1],
            [1, 1],
            [2, 1],
            [2, 1],
        ],
        imgName: '5'
    },
    {
        id: '6',
        arr: [
            [1, 1, 1, 2],
            [1, 1, 2, 1],
            [1, 2, 1, 1],
            [2, 1, 2, 1],
        ],
        imgName: '6'
    },
    {
        id: '7',
        arr: [
            [1, 3, 3, 2],
            [1, 3, 3, 2],
            [2, 1, 3, 3],
            [2, 1, 3, 3],
            [3, 2, 1, 3],
            [3, 2, 1, 3],
            [3, 3, 2, 1],
            [3, 3, 2, 1],
        ],
        imgName: '7'
    },
    {
        id: '8',
        arr: [
            [1, 1],
            [1, 1],
            [1, 2],
            [2, 1],
        ],
        imgName: '8'
    },
    {
        id: '9',
        arr: [
            [2, 2, 1, 1, 1, 2, 2, 1],
            [1, 1, 2, 2, 2, 1, 1, 1],
            [2, 2, 1, 1, 1, 2, 2, 1],
            [1, 1, 2, 2, 2, 1, 1, 1],
            [2, 2, 1, 1, 1, 2, 2, 1],
            [1, 1, 2, 2, 2, 1, 1, 1],
        ],
        imgName: '9'
    },
    {
        id: '10',
        arr: [
            [2, 2, 1, 1, 1, 1, 1, 1],
            [1, 1, 2, 2, 1, 1, 1, 1],
            [1, 1, 1, 1, 2, 2, 1, 1],
            [1, 1, 1, 1, 1, 1, 2, 2],
            [1, 1, 1, 1, 1, 1, 2, 2],
            [1, 1, 1, 1, 2, 2, 1, 1],
            [1, 1, 2, 2, 1, 1, 1, 1],
            [2, 2, 1, 1, 1, 1, 1, 1],
        ],
        imgName: '10'
    },
    {
        id: '11',
        arr: [
            [1, 1, 1, 1, 1, 1, 1],
            [1, 1, 1, 1, 1, 1, 1],
            [1, 1, 1, 1, 1, 1, 1],
            [1, 1, 1, 1, 1, 1, 1],
            [2, 1, 1, 2, 2, 1, 1],
            [1, 1, 1, 1, 1, 1, 1],
            [1, 2, 2, 1, 1, 2, 2],
            [1, 1, 1, 1, 1, 1, 1],
            [2, 1, 1, 2, 2, 1, 1],
            [1, 1, 1, 1, 1, 1, 1],
            [1, 2, 2, 1, 1, 2, 2],
            [1, 1, 1, 1, 1, 1, 1],
        ],
        imgName: '11'
    },

    {
        id: '12',
        arr: [
            [1, 1, 2, 2, 1, 1, 1, 1, 1],
            [1, 1, 2, 2, 1, 1, 1, 1, 1],
            [1, 1, 1, 1, 2, 2, 1, 1, 1],
            [1, 1, 1, 1, 2, 2, 1, 1, 1],
            [1, 1, 2, 2, 1, 1, 1, 1, 1],
            [1, 1, 2, 2, 1, 1, 1, 1, 1],
            [2, 2, 1, 1, 1, 1, 1, 1, 1],
            [2, 2, 1, 1, 1, 1, 1, 1, 1],
        ],
        imgName: '12'
    },
]